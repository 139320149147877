import React from 'react';


const CustomTableWithoutSorting = ({ children, headerCells}) => {



    return (
        <div className="table-responsive noSwipe my-2">
            <table className="table table-striped table-hover mb-0">
            <thead>
            <tr>
                {
                    headerCells.map(cell => {
                        return (
                            cell.isHidden === true
                                ?
                                <th hidden colSpan={cell.colSpan} style={cell.style} key={cell.id}>{cell.name}</th>
                                :
                                cell.isSortable === false
                                    ?
                                    cell.hasChild
                                        ?
                                        <th colSpan={cell.colSpan} style={cell.style} key={cell.id}>{cell.child}</th>
                                        :
                                        <th colSpan={cell.colSpan} style={cell.style} key={cell.id}>{cell.name}</th>
                                    :
                                    <th colSpan={cell.colSpan} style={cell.style} key={cell.id}>
                                        {cell.name}
                                    </th>
                        );
                    })
                }
            </tr>
        </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );

};

export default CustomTableWithoutSorting;