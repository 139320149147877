import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const AssignedVC = ({ toggler, verifier ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [vcUserList, setVCUserList] = useState([]);
    const [vcUser, setVcUser] = useState("");



    {/*Validation*/}
    const validateForm = (vcUser) => {
        const errors = {};
        if(!vcUser){
            errors.reason = 'Please select a value.';
        }
        return errors;
    }

    const aasignedVC = async () => {

        const requestBody = {
            verifierID : verifier.verifierDetailID,
            vcid :  vcUser,
            userID : userID,
            actionType : "Assign"

        };
        const validationErrors = validateForm(vcUser);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.assignRemoveVC}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'VC assigned successfully.')
            {
                toast.success('VC assigned successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };


      useEffect(() => {
        function getVCList() {
            fetch(`${config.apiUrl}${apiconfig.getVCUsers}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setVCUserList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getVCList();
    }, []);


      

    return (
        <div>
    <form>
                    <div className="form-row">
                        <label htmlFor="AssignedVC">Assigned VC :</label>
                        <select id='AssignedVC'  className="form-control form-control-sm" value={vcUser} onChange={(e)=> setVcUser(e.target.value)}>
                            {vcUserList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                    </select>
                    <p style={{color:'red'}}>{formErrors.reason}</p>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={aasignedVC}>Submit</button>
            </div>
            </div>
    );
};

export default AssignedVC;