import React from 'react';


const Role = ({ role }) => {

        return (
            <>
                <tr className="user-avatar">
                    <td>
                        {/* <Link to={`/RoleDetails/${role.roleID}`} state={{ name: role.name}}> */}
                        {role.name}
                    {/* </Link> */}
                    </td>
                    {/* <td>{role.hierarchy}</td> */}
                </tr>
            </>
        );

};

export default Role;