import { useState } from "react";
import { Label } from 'reactstrap';
import IsAccessForAccreditationCombination from "../../Common/IsAccessForAccreditationCombination";


const AirportApplicationsList = ({ application}) => {
    const isFullAccess = IsAccessForAccreditationCombination();

return (

        <tr>
            {isFullAccess ? 
            <td><a className="text-info" href={`/ApplicationStage/ApplicationID/${application.airportApplicationID}/AirportID/${application.airportID}`}>{application.airportApplicationID}</a></td>
            :
            <td>{application.airportApplicationID}</td>
            }
            <td>{application.reportingPeriod}</td>
            <td>{application.region}</td>       
            <td>{application.airport}</td>
            <td>{application.airportGroup}</td>
            <td>{application.applicationType}</td>
            <td>{application.accreditationLevel}</td>
            <td>{application.status}</td>
            <td>{application.accreditedDate}</td>       
            <td>{application.verifierAssigned}</td>
            <td>{application.adminAssigned}</td>
            <td>{application.lastUpdated}</td>
        </tr>

);

};

export default AirportApplicationsList;
