import React from 'react';
import { Link,useParams } from 'react-router-dom';

const ApiNotWorking = () => {
    const { id } = useParams();
    console.log(id)
if(id==undefined )
{
    return (
        <h3 style={{ fontsize: '11pt',textAlign:'center' }}>      
            <p>ACI Api is not working.</p>
            <p><Link style={{ color: "blue" }} to={`/login`}>Click here</Link> to login again.</p>
        </h3>
    );
}
else
{
    return (
        <h3 style={{ fontsize: '11pt',textAlign:'center' }}>
            <p>ERROR 500:</p>
            <p>{id}</p>
        </h3>
    );
}
};

export default ApiNotWorking;