import React from 'react';
import { BiTimeFive } from 'react-icons/bi';
import ConvertToRelativeDateString from '../../Helper/ConvertToRelativeDateString';

const RecentActivity = ({ activity }) => {

    let activityTime = ConvertToRelativeDateString(activity.eventDate);

    return (
        <div className="alt-row" style={{ marginRight: 15 }}>
            <img style={{ borderRadius: 50, maxBlockSize: 40 }} src={require('../../../img/avatar2.png')} alt="Avatar" />
            <span style={{ marginLeft: 10 }}>
                {activity.name}
                <div style={{ marginLeft: 50, color: 'purple' }}>
                    {activity.description}
                </div>
                <div style={{ textAlign: 'end', color: 'green' }}>
                    <BiTimeFive />&nbsp;{activity.eventDate}
                </div>
            </span>
        </div>
    );

};

export default RecentActivity;