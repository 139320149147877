import React from 'react';
import { TbArrowsSort } from 'react-icons/tb';

export const TableHeaderCells = [
    {
        id: 1,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        style: {},
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 2,
        isHidden: false,
        name: 'First Name',
        colSpan: 1,
        style: { cursor: 'pointer', width: '15%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'FirstName'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Last Name',
        colSpan: 1,
        style: { cursor: 'pointer', width: '15%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'LastName'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Email Address',
        colSpan: 1,
        style: { cursor: 'pointer', width: '15%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'email'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Category',
        colSpan: 1,
        style: { cursor: 'pointer', textAlign: 'center', width: '7%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'category'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        style: { cursor: 'pointer', textAlign: 'center', width: '8%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'status'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Created Date/Time',
        colSpan: 1,
        style: { cursor: 'pointer', textAlign: 'center' , width: '15%'},
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'CreatedDate'
    },
    {
        id: 8,
        isHidden: false,
        name: 'Last Update Date/Time',
        colSpan: 1,
        style: { cursor: 'pointer', textAlign: 'center' , width: '15%'},
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'LastUpdated'
    },
    {
        id: 9,
        isHidden: false,
        name: 'Actions',
        colSpan: 3,
        style: { textAlign: 'center', width: '5%' },
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null
    }
];