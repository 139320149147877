import React, { useState } from 'react'
import Question from "./Question"
import ReviewComments from "./ReviewComments"
import UploadDocuments from "./UploadDocuments"
import { htmlConverterReact } from 'html-converter-react'
import { Slide, toast } from 'react-toastify'

const SubSection = ({ index, data, sectionIndex, state, setState, activeRightPane, setActiveRightPane, leftNavigationMenu, saveSubSection, userType, questionRefs, calculateQuestionRefIndex, visibilityBasedOnType, commentsVisibility, setAlertDialogBoxProperties, reCalculateTotalAtRunTime, setReCalculateTotalAtRunTime }) => {

    const [matrixChangeDetector, setMatrixChangeDetector] = useState(false)
    // the below state `temporarilyDisallowSubSectionSubmission` can be utilized whenever there's any specific conditional blocking for submission of sub-section is required
    const [temporarilyDisallowSubSectionSubmission, setTemporarilyDisallowSubSectionSubmission] = useState(false)
    // if `doesTemporaryyDisallowanceForSubSectionSubmissionHasAlert` is true, then `setAlertDialogBoxProperties` can be utilized to show the appropriate alert
    const [doesTemporaryDisallowanceForSubSectionSubmissionHasAlert, setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert] = useState(false)

    const move = async (direction) => {
        const updatedState = {...state}
        updatedState.sections[sectionIndex].subsections[index].isSubmitButtonClicked = true
        
        // if `temporarilyDisallowSubSectionSubmission` is true, then don't allow to submit the subsection
        if(temporarilyDisallowSubSectionSubmission){
            // if `doesTemporaryDisallowanceForSubSectionSubmissionHasAlert` is true, then configure (turn on the visibility as other properties would already be present) `alertDialogBoxProperties` via `setAlertDialogBoxProperties`
            if(doesTemporaryDisallowanceForSubSectionSubmissionHasAlert)
                setAlertDialogBoxProperties(prevState => ({ ...prevState, visibility: true }))
        }
        // if `temporarilyDisallowSubSectionSubmission` is false, then only allow to proceed further
        else{
            const completionStatus = checkCompletionStatus()
            if(completionStatus && !updatedState.sections[sectionIndex].subsections[index].completed)
                updatedState.sections[sectionIndex].subsections[index].completed = true
            if(!completionStatus && updatedState.sections[sectionIndex].subsections[index].completed){
                if(!toast.isActive('submitting-subsection-completed-previously'))
                    toast.info('In order to update this section please provide responses against all mandatory questions', { toastId: 'submitting-subsection-completed-previously', position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
                else
                    toast.update('submitting-subsection-completed-previously', { autoClose: 5000 })
            }
            
            if(completionStatus){
                switch(direction){
                    case 'forward':
                        if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1)
                            setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1])
                        break
                    case 'backward':
                        if(leftNavigationMenu.indexOf(activeRightPane) !== 0) 
                            setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1])
                        break
                    default:
                        break
                }
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) 
                // call api endpoint here to save this subsection into the database
                await saveSubSection(sectionIndex, index)
                handleMatrixChange()
            }
        }
        
        setState(updatedState)
    }

    const checkCompletionStatus = () => {
        /* for scenarios where conditional return to call is required, avoid using `map()` as it iterates over all elements ignoring the conditional return statements (it's basically how `map()` behaves)
           instead use `for()`, `some()` or `every()` as they work perfectly in such scenarios  */
        for(const question of data.content.questions){
            if(question.isVisible && question.required && question.responseType === 'other'){
                if(!checkCompletionStatusForResponseTypeOther(question.other))
                    return false
            }
            else if(question.isVisible && question.required && question.responseType === 'matrix'){
                if(!checkCompletionStatusForResponseTypeMatrix(question.matrix))
                    return false
            }
            else{
                if(question.isVisible && question.required && question.response.length === 0)
                    return false
            }
            for(const subquestion of question.subquestions){
                if(!checkCompletionStatusForSubQuestions(subquestion)) 
                    return false
            }
        }
        return true
    }

    const checkCompletionStatusForSubQuestions = (question) => {
        if(question.isVisible && question.required && question.responseType === 'other'){
            if(!checkCompletionStatusForResponseTypeOther(question.other))
                return false
        }
        else if(question.isVisible && question.required && question.responseType === 'matrix'){
            if(!checkCompletionStatusForResponseTypeMatrix(question.matrix))
                return false
        }
        else{
            if(question.isVisible && question.required && question.response.length === 0)
                return false
        }
        for(const subquestion of question.subquestions){
            if(!checkCompletionStatusForSubQuestions(subquestion))
                return false 
        }
        return true
    }

    const checkCompletionStatusForResponseTypeOther = (other) => {
        if(other.responses.length === 0)
            return false
        if(other.leastNumberOfCheckboxesToBeChecked > 0 && !checkForLeastNumberOfCheckboxesToBeChecked(other.responses, other.leastNumberOfCheckboxesToBeChecked))
            return false
        if(other.minimumOneResponseRequired)
            return checkForSpecialCaseWhenMinimumOneCompleteResponseIsRequired(other.responses)
        else{
            for(const response of other.responses){
                for(const input of response.inputs){
                    if(input.value.length === 0)
                        return false
                    if(input.type === 'dropdown' && input.value.length !== 0 && input.value !== '0' && input.options.find(option => option.value === input.value).hasSecondaryInput && input.options.find(option => option.value === input.value).secondaryInputValue.length === 0)
                        return false
                }
            }
        }
        return true
    }

    const checkForLeastNumberOfCheckboxesToBeChecked = (responses, leastNumberOfCheckboxesRequiredToBeChecked) => {
        for(const response of responses){
            if(response.inputs.filter(input => input.type === 'checkbox').length !== 0){
                let numberOfCheckboxesChecked = 0
                for(const input of response.inputs.filter(input => input.type === 'checkbox'))
                    numberOfCheckboxesChecked = input.value === 'Yes' ? numberOfCheckboxesChecked + 1 : numberOfCheckboxesChecked
                if(numberOfCheckboxesChecked < leastNumberOfCheckboxesRequiredToBeChecked)
                    return false
            }
        }
        return true
    }

    const checkForSpecialCaseWhenMinimumOneCompleteResponseIsRequired = (responses) => {
        for(const response of responses){
            let isResponseComplete = true
            for(const input of response.inputs){
                if(input.value.length === 0){
                    isResponseComplete = false
                    break
                } 
            }
            if(isResponseComplete)
                return true
        }
        return false
    }

    const checkCompletionStatusForResponseTypeMatrix = (matrix) => {
        if(matrix.rows.length === 0)
            return false
        for(const row of matrix.rows){
            for(const attribute of row.attributes){
                if(attribute.type !== 'empty' && attribute.required){
                    if(attribute.value.length === 0)
                        return false
                }
            }
        }
        return true
    }

    const handleMatrixChange = () => {
        setMatrixChangeDetector(!matrixChangeDetector)
    }

    /* synchronous method to call when moving freely just to navigate forward or backward */
    const navigate = (direction) => {
        switch(direction){
            case 'forward':
                if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1)
                    setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1])
                break
            case 'backward':
                if(leftNavigationMenu.indexOf(activeRightPane) !== 0)
                    setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1])
                break
            default:
                break
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    return(
        <React.Fragment>
            <div className="card card-border card-contrast" style={activeRightPane === data.title ? {} : {display: 'none'}}>
                <div className="card-header card-header-contrast card-header-divider">
                    {data.title}
                </div>
                <div className="card-body px-2">
                    {
                        data.description.length === 0 
                            ? 
                            <React.Fragment></React.Fragment>
                            :
                            <p>{htmlConverterReact(data.description)}</p>
                    }
                    {
                        data.content.questions.map((question, question_index) => {
                            return <Question key={question_index} index={question_index} subSectionIndex={index} sectionIndex={sectionIndex} data={question} state={state} setState={setState} userType={userType} questionRefs={questionRefs} calculateQuestionRefIndex={calculateQuestionRefIndex} visibilityBasedOnType={visibilityBasedOnType} setAlertDialogBoxProperties={setAlertDialogBoxProperties} matrixChangeDetector={matrixChangeDetector} handleMatrixChange={handleMatrixChange} setTemporarilyDisallowSubSectionSubmission={setTemporarilyDisallowSubSectionSubmission} setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert={setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert} reCalculateTotalAtRunTime={reCalculateTotalAtRunTime} setReCalculateTotalAtRunTime={setReCalculateTotalAtRunTime} />
                        })
                    }
                    {
                        data.isEnable
                        ?
                        <UploadDocuments data={data.content.documents} subSectionIndex={index} sectionIndex={sectionIndex} state={state} setState={setState} userType={userType} visibilityBasedOnType={visibilityBasedOnType} />
                        :
                        <React.Fragment></React.Fragment>
                    }
                    <ReviewComments data={data.content.comments} subSectionIndex={index} sectionIndex={sectionIndex} state={state} setState={setState} userType={userType} commentsVisibility={commentsVisibility} />
                </div>
            </div>
            <div className="" style={activeRightPane === data.title ? {display: 'flex', justifyContent: 'flex-end'} : {display: 'none'}}>
                {
                    visibilityBasedOnType(state.sections[sectionIndex].subsections[index].type, false) || (userType !== state.sections[sectionIndex].subsections[index].type && ((userType === 'verifier' && (state.applicationStatus === 'In Verification' || state.applicationStatus === 'Airport resubmitted - In Verification' || state.applicationStatus === 'Admin comments - Verifier to address')) || (userType === 'admin' && (state.applicationStatus === 'In Admin Verification' || state.applicationStatus === 'Airport resubmitted - In Admin Verification' || state.applicationStatus === 'Verifier resubmitted - In Admin Verification'))))  
                        ?
                        <React.Fragment>
                            <button className="btn btn-space btn-primary btn-xl" disabled={!data.isEnable} onClick={() => { move('backward') }}>
                                <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Save and go Back
                            </button>
                            <button id="validation-btn" className="btn btn-space btn-success btn-xl" disabled={!data.isEnable} onClick={() => { move('forward') }}>Save and go Next&nbsp;
                                <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                            </button>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <button className="btn btn-space btn-primary btn-xl" onClick={() => { navigate('backward') }}>
                                <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Back
                            </button>
                            <button id="validation-btn" className="btn btn-space btn-success btn-xl" onClick={() => { navigate('forward') }}>Next&nbsp;
                                <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                            </button>
                        </React.Fragment>
                }
            </div>
        </React.Fragment>
    )
}

export default SubSection