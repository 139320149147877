import { useState } from "react";
import CommonModalWithImage from "../../Common/CommonModalWithImage";
import config from "../../../api/config";
import apiconfig from "../../../api/apiconfig";
import { Label } from 'reactstrap';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const AirportList = ({ airportgpID, airport, refetchs, setRefetchs}) => {

  const [removeAirportModal, setRemoveAirportModal] = useState(false);
  const userID = localStorage.getItem("userID");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");


  const toggleForRemoveAirportModal = () => {
    setRemoveAirportModal(!removeAirportModal);
  };


  const deleteAirport= async () => {

    const requestBody = {
        "airportID": airport.airportID,
        "airportGroupID" : airportgpID,
        "userID": userID,
    };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.removeAirport}`,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    body: JSON.stringify(requestBody),
                });
            if (response.ok) {
                var result = await response.json();
                if(result.saveErrorMessage === 'Airport group deleted successfully.')
                {
                    toast.success('Airport group deleted successfully.', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Slide
                    }); 
                setRefetchs(!refetchs);
                }
                // else{
                //     setErrorMessage(result.saveErrorMessage);
                // }
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.removeAirport}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
        toggleForRemoveAirportModal();

};

return (
    <>
        <tr>
        <td>{airport.iatacode}</td>
        <td>{airport.airportName_English}</td>
        <td>{airport.airportName_Local}</td>
        <td>{airport.region}</td>
        <td>{airport.country}</td>
        <td>{airport.city}</td>
        <td className="text-right actions">
        {/* <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
        <i className="mdi mdi-more"></i>
        <span className="sr-only">Menu</span>
        </a> */}
        <div className="row">            
            <a   style={{cursor:'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={ () => { toggleForRemoveAirportModal(); }} ><span className="icon mdi mdi-delete"></span></a>                          
        </div>
        </td>
        </tr>

<CommonModalWithImage modal={removeAirportModal} title='' toggler={toggleForRemoveAirportModal} heading='Remove airport from airport group' triggerMethodOnSaveButtonClick={deleteAirport}   buttonText='Yes'>
  <Label className="col-form-label">{'Are you sure you want to remove airport from Airport group '}{'?'} <br/> {'Airport Group will be changed to No and will be considered as an individual airport record.'}</Label>
</CommonModalWithImage>

</>
);

};

export default AirportList;
