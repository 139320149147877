import React, { useEffect } from 'react'
import SubQuestion from './SubQuestion'
import { htmlConverterReact } from 'html-converter-react'
import Other from './Other'
import Matrix from './Matrix'

const Question = ({ index, subSectionIndex, sectionIndex, data, state, setState, userType, questionRefs, calculateQuestionRefIndex, visibilityBasedOnType, setAlertDialogBoxProperties, matrixChangeDetector, handleMatrixChange, setTemporarilyDisallowSubSectionSubmission, setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert, reCalculateTotalAtRunTime, setReCalculateTotalAtRunTime }) => {

    /* Methods */
    const updateState = (value) => {
        const updatedState = {...state}
        
        if(data.responseType === 'dropdown' && value === '0')
            updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].response = ''
        else
            updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].response = value
        
        /* if response type is `dropdown` then check if `hasAlert` is true. If true, then utilizing `alertProperties` set the `alertDialogBoxProperties` state via setAlertDialogBoxProperties */
        if(data.responseType === 'dropdown' && data.hasAlert && data.alertProperties !== null){
            for(const alertProperty of data.alertProperties){
                if(alertProperty.alertValue === value)
                    setAlertDialogBoxProperties(prevState => ({ ...prevState, visibility: true, heading: alertProperty.alertHeading, alertText: alertProperty.alertText, buttonText: alertProperty.buttonText }))
            }
        }

        /* Logic for cross-question toggling: : when current question is a toggle sender */
        if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].crossQuestionToggling !== null){
            if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].crossQuestionToggling.length !== 0){
                for(const item of updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].crossQuestionToggling){
                    if((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].responseType === 'dropdown' && item.visibleOnValues.includes(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].response)) || ((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].responseType === 'text' || updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].responseType === 'textarea') && updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].response.length !== 0)){
                        for(const section of updatedState.sections){
                            if(section.id === item.sectionID){
                                for(const subsection of section.subsections){
                                    if(subsection.id === item.subSectionID){
                                        for(const question of subsection.content.questions){
                                            if(question.id === item.questionID)
                                                question.isVisible = true
                                        }
                                    }
                                }
                            }
                        }
                    }
                    else if((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].responseType === 'dropdown' && !item.visibleOnValues.includes(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].response)) || ((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].responseType === 'text' || updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].responseType === 'textarea') && updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].response.length === 0)){
                        for(const section of updatedState.sections){
                            if(section.id === item.sectionID){
                                for(const subsection of section.subsections){
                                    if(subsection.id === item.subSectionID){
                                        for(const question of subsection.content.questions){
                                            if(question.id === item.questionID)
                                                question.isVisible = false
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* 
            Logic to calculate target question/input value based on current question/input value aided with possibly multiple different question/input value 
            This logic is not currently applicable at sub-question level
            This logic can only be utilized for response type 'text' and 'textarea'
        */
        if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].calculateTargets !== null){
            if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].calculateTargets.length !== 0){
                for(const calculateTarget of data.calculateTargets){
                    // this variable will keep track if calculation of target question's/input's value is possible 
                    let isCalculationValid = false
                    const orderValueHashMap = new Map()
                    /*
                        check whether the current question belongs to the same subsection as the target question or not
                        if it indeed belongs to the same subsection then use the local value for calculation 
                        if it does not, then utilize the lastSuccessfullySavedResponse's value for calculation
                    */
                    if(updatedState.sections[sectionIndex].subsections[subSectionIndex].id === calculateTarget.target.subsectionID){
                        // insert current question's order & local response in hashmap 
                        orderValueHashMap.set(parseInt(calculateTarget.order), parseInt(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].response))
                    }
                    else{
                        // insert current question's order & lastSuccessfullySavedResponse in hashmap making sure that lastSuccessfullySavedResponse exists (it should not be empty or null)
                        if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].lastSuccessfullySavedResponse !== null){
                            if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].lastSuccessfullySavedResponse.length !== 0)
                                orderValueHashMap.set(parseInt(calculateTarget.order), parseInt(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].lastSuccessfullySavedResponse))
                        }
                    }
                    // iterate through peer dependencies over target via 'calculateWith' array of objects
                    for(const peer of calculateTarget.calculateWith){
                        // extract peer question inside `peerQuestion` via navigating through the json
                        const peerQuestion = state.sections.find(section => section.id === peer.sectionID).subsections.find(subsection => subsection.id === peer.subsectionID).content.questions.find(question => question.id === peer.questionID)
                        /*
                            check whether the peer belongs to the same subsection as the target question or not
                            if it indeed belongs to the same subsection then use the local value for calculation 
                            if it does not, then utilize the lastSuccessfullySavedResponse's/lastSuccessfullySavedResponseOther's value for calculation
                        */
                        if(peer.subsectionID === calculateTarget.target.subsectionID){
                            // insert peer question's order & local response in hashmap based on 'responseType' 
                            if(peerQuestion.responseType === 'other')
                                orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.other.responses.find(response => response.id === peer.responseID).inputs.find((input, input_index) => input_index === parseInt(peer.inputIndex)).value))
                            else
                                orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.response))
                        }
                        else{
                            // insert peer question's order & lastSuccessfullySavedResponse/lastSuccessfullySavedResponseOther in hashmap based on 'responseType' making sure that the response exists (it should not be empty or null)
                            if(peerQuestion.responseType === 'other'){
                                if(peerQuestion.lastSuccessfullySavedResponseOther !== null){
                                    if(peerQuestion.lastSuccessfullySavedResponseOther.length !== 0){
                                        if(peerQuestion.lastSuccessfullySavedResponseOther.responses !== null){
                                            if(peerQuestion.lastSuccessfullySavedResponseOther.responses.length !== 0)
                                                orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.lastSuccessfullySavedResponseOther.responses.find(response => response.id === peer.responseID).inputs.find((input, input_index) => input_index === parseInt(peer.inputIndex)).value))
                                        }
                                    }  
                                }
                            } 
                            else{
                                if(peerQuestion.lastSuccessfullySavedResponse !== null){
                                    if(peerQuestion.lastSuccessfullySavedResponse.length !== 0)
                                        orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.response))
                                }
                            } 
                        }
                    }
                    // pass 'operation' and the values & size of 'orderValueHashMap' as parameters into the `checkIfCalculationOfTargetIsPossible` method which will return either 'true' or 'false' on the basis of some internal logic 
                    isCalculationValid = checkIfCalculationOfTargetIsPossible(calculateTarget.operation, orderValueHashMap.values(), orderValueHashMap.size)
                    // if `isCalculationValid` is true, then perform the calculation of target question's/input's value via `calculateTargetValue` method 
                    if(isCalculationValid){
                        const calculatedTargetValue = calculateTargetValue(calculateTarget.operation, orderValueHashMap)
                        const targetQuestion = state.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID)
                        if(targetQuestion.responseType === 'other'){
                            if(calculatedTargetValue === 'NaN' || calculatedTargetValue === 'NaN %')
                                updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).other.responses.find(response => response.id === calculateTarget.target.responseID).inputs.find((input, input_index) => input_index === parseInt(calculateTarget.target.inputIndex)).value = ''
                            else
                                updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).other.responses.find(response => response.id === calculateTarget.target.responseID).inputs.find((input, input_index) => input_index === parseInt(calculateTarget.target.inputIndex)).value = calculatedTargetValue
                            // set the 'isUpdated' of target response input to 'true' 
                            updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).other.responses.find(response => response.id === calculateTarget.target.responseID).inputs.find((input, input_index) => input_index === parseInt(calculateTarget.target.inputIndex)).isUpdated = true
                        }
                        else{
                            if(calculatedTargetValue === 'NaN' || calculatedTargetValue === 'NaN %')
                                updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).response = ''
                            else
                                updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).response = calculatedTargetValue
                        }   
                    }
                }
            }
        }

        /* carbon footprint special case: Year 0 Emission (id - 1005) | Target Carbon Footprint (id - 1004) | Can you demonstrate...? (id - 1003) | Scope 3 Emissions - Total */
        const carbonFootprintSpecialCase = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].carbonFootprintSpecialCase
        
        if(carbonFootprintSpecialCase !== null){
            
            const scope3EmissionSum = carbonFootprintSpecialCase.scope3EmissionSum
            const target = carbonFootprintSpecialCase.target
            
            if(scope3EmissionSum !== null && scope3EmissionSum.length !== 0 && target !== null){
                
                const sectionID = carbonFootprintSpecialCase.target.sectionID
                const subSectionID = carbonFootprintSpecialCase.target.subSectionID
                const questionID = carbonFootprintSpecialCase.target.questionID
                
                if(sectionID !== null && subSectionID !== null && questionID !== null){
                    if(sectionID.length !== 0 && subSectionID.length !== 0 && questionID.length !== 0){
                        let currentQuestionValue = value.length === 0 ? 0 : parseInt(value)
                        updatedState.sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions.find(question => question.id === questionID).response = (parseInt(scope3EmissionSum) + currentQuestionValue).toString()
                    }
                }
            }
        }
    
        setState(updatedState)
    }
    const checkIfCalculationOfTargetIsPossible = (operation, values, size) => { 
        if(size === 0)
            return false
        for(const value of values){
            if((operation === 'division' || operation === 'percentage' || operation === 'difference-based-percentage') && value < 0)
                return false
            if((operation === 'difference' || operation === 'division' || operation === 'percentage' || operation === 'difference-based-percentage') && size !== 2)
                return false
        }
        return true
    }
    const calculateTargetValue = (operation, map) => {
        // Convert map to array of key-value pairs and sort by keys
        const sortedEntries = [...map.entries()].sort((a, b) => a[0] - b[0])
        // Create a new Map from the sorted array of key-value pairs
        const sortedMap = new Map(sortedEntries)
        switch(operation){
            case 'sum':
                let sum = 0
                for(const value of sortedMap.values())
                    sum += value
                return sum.toString()
            case 'difference':
                return ([...sortedMap.values()][0] - [...sortedMap.values()][1]).toString()
            case 'multiplication':
                let result = 1
                for(const value of sortedMap.values())
                    result *= value
                return result.toString()
            case 'division':
                return ([...sortedMap.values()][0] / [...sortedMap.values()][1]).toString()
            case 'average':
                let average = 0
                for(const value of sortedMap.values())
                    average += value
                average /= [...sortedMap.values()].length
                return average.toString()
            case 'percentage':
                let percentage = (([...sortedMap.values()][0] / [...sortedMap.values()][1]) * 100).toString() + ' %'
                if([...sortedMap.values()][0] < [...sortedMap.values()][1])
                    percentage = '- ' + percentage
                return percentage
            case 'difference-based-percentage':
                let difference_based_percentage = Number((parseFloat(([...sortedMap.values()][0] - [...sortedMap.values()][1]) / [...sortedMap.values()][0]) * 100).toFixed(2)).toString() + ' %'
                //let difference_based_percentage = parseInt((([...sortedMap.values()][0] - [...sortedMap.values()][1]) / [...sortedMap.values()][0]) * 100).toString() + ' %'
                // if([...sortedMap.values()][0] < [...sortedMap.values()][1])
                //     difference_based_percentage = '- ' + difference_based_percentage
                return difference_based_percentage
            default:
                return null
        }
    }
    const checkCompletionStatusForResponseTypeOther = (other) => {
        if(other.responses.length === 0)
            return false
        if(other.leastNumberOfCheckboxesToBeChecked > 0 && !checkForLeastNumberOfCheckboxesToBeChecked(other.responses, other.leastNumberOfCheckboxesToBeChecked))
            return false
        if(other.minimumOneResponseRequired)
            return checkForSpecialCaseWhenMinimumOneCompleteResponseIsRequired(other.responses)
        else{
            for(const response of other.responses){
                for(const input of response.inputs){
                    if(input.value.length === 0)
                        return false
                    if(input.type === 'dropdown' && input.value.length !== 0 && input.value !== '0' && input.options.find(option => option.value === input.value).hasSecondaryInput && input.options.find(option => option.value === input.value).secondaryInputValue.length === 0)
                        return false
                }
            }
        }
        return true
    }
    const checkForLeastNumberOfCheckboxesToBeChecked = (responses, leastNumberOfCheckboxesRequiredToBeChecked) => {
        for(const response of responses){
            if(response.inputs.filter(input => input.type === 'checkbox').length !== 0){
                let numberOfCheckboxesChecked = 0
                for(const input of response.inputs.filter(input => input.type === 'checkbox'))
                    numberOfCheckboxesChecked = input.value === 'Yes' ? numberOfCheckboxesChecked + 1 : numberOfCheckboxesChecked
                if(numberOfCheckboxesChecked < leastNumberOfCheckboxesRequiredToBeChecked)
                    return false
            }
        }
        return true
    }
    const checkForSpecialCaseWhenMinimumOneCompleteResponseIsRequired = (responses) => {
        for(const response of responses){
            let isResponseComplete = true
            for(const input of response.inputs){
                if(input.value.length === 0){
                    isResponseComplete = false
                    break
                } 
            }
            if(isResponseComplete)
                return true
        }
        return false
    }
    const checkCompletionStatusForResponseTypeMatrix = (matrix) => {
        if(matrix.rows.length === 0)
            return false
        for(const row of matrix.rows){
            for(const attribute of row.attributes){
                if(attribute.type !== 'empty' && attribute.required){
                    if(attribute.value.length === 0)
                        return false
                }
            }
        }
        return true
    }
    const intraSubSectionCrossQuestionPropertyManipulation = (properties, question, hasByPass) => {
        
        // create a shallow copy of `question`
        const updatedQuestion = {...question}

        // iterate through each `property` of `properties`
        for(const property of properties){

            // code block that will take care of by-passing scenarios
            if(hasByPass){
                // only update those properties that has `allowByPass` as `true`
                if(property.allowByPass){
                    // update the question's properties as per appropriate case defined within the switch statement
                    switch(property.propertyName){
                        case 'help-text':
                            updatedQuestion.helpText = property.propertyValue
                            break
                        case 'description':
                            updatedQuestion.description = property.propertyValue
                            break
                        case 'response':
                            updatedQuestion.response = property.propertyValue
                            break
                        case 'isCalculated':
                            updatedQuestion.isCalculated = property.propertyValue === 'true' ? true : false
                            break
                        case 'isVisible':
                            updatedQuestion.isVisible = property.propertyValue === 'true' ? true : false
                            break
                        default:
                            // nothing to do here
                            break
                    }
                }
            }
            else{
                // update the question's properties as per appropriate case defined within the switch statement
                switch(property.propertyName){
                    case 'help-text':
                        updatedQuestion.helpText = property.propertyValue
                        break
                    case 'description':
                        updatedQuestion.description = property.propertyValue
                        break
                    case 'response':
                        updatedQuestion.response = property.propertyValue
                        break
                    case 'isCalculated':
                        updatedQuestion.isCalculated = property.propertyValue === 'true' ? true : false
                        break
                    case 'isVisible':
                        updatedQuestion.isVisible = property.propertyValue === 'true' ? true : false
                        break
                    default:
                        // nothing to do here
                        break
                }
            }
            
        }
        return updatedQuestion
    }

    /* Effects */
    useEffect(() => {
        /* Logic for cross-question toggling (matrix row cell acting as a sender): when current question is a toggle receiver */
        if(data.crossQuestionTogglingAsReceiver !== null){
            if(data.crossQuestionTogglingAsReceiver.length !== 0){
                let visibleTogglers = []
                for(const item of data.crossQuestionTogglingAsReceiver){
                    if(state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).isVisible)
                        visibleTogglers.push(item)
                }
                let visibility = visibleTogglers.length !== 0
                for(const item of visibleTogglers){
                    if(visibleTogglers.indexOf(item) === 0){
                        if(item.mandatory)
                            visibility = visibility && state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).matrix.rows.find(row => row.id === item.rowID).attributes.find(attribute => attribute.id === item.attributeID).value === item.visibleOnValue
                        else
                            visibility = visibility || state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).matrix.rows.find(row => row.id === item.rowID).attributes.find(attribute => attribute.id === item.attributeID).value === item.visibleOnValue
                    }
                    else{
                        if(item.mandatory)
                            visibility = visibility && state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).matrix.rows.find(row => row.id === item.rowID).attributes.find(attribute => attribute.id === item.attributeID).value === item.visibleOnValue
                        else
                            visibility = visibility || state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).matrix.rows.find(row => row.id === item.rowID).attributes.find(attribute => attribute.id === item.attributeID).value === item.visibleOnValue
                    }
                }
                /* update the visibility in the state */
                const updatedState = {...state}
                updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].isVisible = visibility
                setState(updatedState)
            }
        }
    }, [matrixChangeDetector])
    useEffect(() => {
        // current question must be of response type `dropdown` for further rendering
        if(data.responseType === 'dropdown'){
            
            // check whether `data.intraSubSectionCrossQuestionPropertyManipulation` is valid, if valid thenallow execution of code block within it
            if(data.intraSubSectionCrossQuestionPropertyManipulation !== null && data.intraSubSectionCrossQuestionPropertyManipulation.length !== 0){
                
                // extract `option` post matching into `option`
                const option = data.options.find(option => option.value === data.response)

                // check whether the extracted `option` is valid or not
                if(option !== undefined && typeof(option) !== undefined && option !== null){

                    // create a shallow copy of `state`
                    const updatedState = {...state}

                    // initially, set the below variable as `false`
                    let isStateUpdateRequired = false

                    // iterate through each item of `data.intraSubSectionCrossQuestionPropertyManipulation`
                    for(const item of data.intraSubSectionCrossQuestionPropertyManipulation){    

                        // extract option's label into `response`
                        const response = option.label
                    
                        // when `item.triggerWhenValueIs` is the same as `response`, then allow the execution of code block within it
                        if(item.triggerWhenValueIs === response){
                    
                            // this condition is most crucial as it controls the execution of code block within it
                            if(item.previousValue !== response || item.hasByPass){
                                
                                // extract the question into `question`
                                let question = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions.find(question => question.id === item.questionID)

                                // extract the question index into `questionIndex`
                                let questionIndex = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions.findIndex(question => question.id === item.questionID)
                                

                                // if `item.propertiesToBeManipulated` is valid then update the state by calling `intraSubSectionCrossQuestionPropertyManipulation` method with appropriate parameters 
                                if(item.propertiesToBeManipulated !== null && item.propertiesToBeManipulated.length !== 0)
                                    updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex] = intraSubSectionCrossQuestionPropertyManipulation(item.propertiesToBeManipulated, question, item.hasByPass)

                                // here set the `isStateUpdateRequired` as true, since state update is required now as one or more changes have been made to one or more property
                                isStateUpdateRequired = true
                            }
                        }

                        // update the `item.previousValue` to `response`
                        item.previousValue = response
                    }

                    for(const item of updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].intraSubSectionCrossQuestionPropertyManipulation){
                        item.hasByPass = false
                        if(item.propertiesToBeManipulated !== null && item.propertiesToBeManipulated.length !== 0){
                            for(const property of item.propertiesToBeManipulated){
                                property.allowByPass = false
                            }
                        }
                    }

                    // finally, update the `state` via `setState` if `isStateUpdateRequired` is true
                    if(isStateUpdateRequired)
                        setState(updatedState)
                }
                else{
                    /* 
                        since option is not valid, thus, all the properties within `intraSubSectionCrossQuestionPropertyManipulation` should be set to empty against the question 
                        in order to revert any changes made to these properties
                        iterate through all the properties within `intraSubSectionCrossQuestionPropertyManipulation`
                        initialize an empty array each time
                        add each property in the array with property value as `` (empty)
                        finally, conditionally update the state 
                    */ 

                    // create a shallow copy of `state`
                    const updatedState = {...state}

                    let propertiesCount = 0

                    // iterate through each item of `data.intraSubSectionCrossQuestionPropertyManipulation`
                    for(const item of data.intraSubSectionCrossQuestionPropertyManipulation){    

                        if(item.previousValue !== ''){
                        
                            // extract the question into `question`
                            let question = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions.find(question => question.id === item.questionID)

                            // extract the question index into `questionIndex`
                            let questionIndex = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions.findIndex(question => question.id === item.questionID)
                            
                            // check whether `item.propertiesToBeManipulated` is valid or not 
                            if(item.propertiesToBeManipulated !== null && item.propertiesToBeManipulated.length !== 0){

                                // initialize an empty array
                                let propertiesToBeManipulated = []
                                
                                // iterate through each property of `item` and insert into `propertiesToBeManipulated` array with empty corresponding property value
                                for(const property of item.propertiesToBeManipulated)
                                    propertiesToBeManipulated.push({ propertyName: property.propertyName, propertyValue: '' })

                                // update `propertiesCount`
                                propertiesCount += propertiesToBeManipulated.length
                                
                                // update the state by calling `intraSubSectionCrossQuestionPropertyManipulation` method with appropriate parameters (including local `propertiesToBeManipulated`)
                                updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex] = intraSubSectionCrossQuestionPropertyManipulation(propertiesToBeManipulated, question)
                            }
                        }
                    }

                    if(propertiesCount > 0){

                        // extract `intraSubSectionCrossQuestionPropertyManipulation` into `items` below
                        const items = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].intraSubSectionCrossQuestionPropertyManipulation
                                
                        // for each item in items, update the `item.previousValue` to `response`
                        for(const item of items)
                            item.previousValue = ''

                        // finally, update the `state` via `setState`
                        setState(updatedState)
                    }
                }
            }
        }
    }, [state])

    return(
        <React.Fragment>
            {
                data.responseType === 'other' && data.other !== null
                    ? 
                    <React.Fragment>
                        <Other key={index} index={index} subSectionIndex={subSectionIndex} sectionIndex={sectionIndex} data={data} state={state} setState={setState} userType={userType} questionRefs={questionRefs} calculateQuestionRefIndex={calculateQuestionRefIndex} visibilityBasedOnType={visibilityBasedOnType} setAlertDialogBoxProperties={setAlertDialogBoxProperties} setTemporarilyDisallowSubSectionSubmission={setTemporarilyDisallowSubSectionSubmission} setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert={setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert} reCalculateTotalAtRunTime={reCalculateTotalAtRunTime} setReCalculateTotalAtRunTime={setReCalculateTotalAtRunTime} />
                        { 
                            data.required && data.isVisible && !checkCompletionStatusForResponseTypeOther(data.other) && state.sections[sectionIndex].subsections[subSectionIndex].isSubmitButtonClicked
                                ?
                                <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger" style={{marginBottom: 10, marginTop: -10}}></i>&nbsp;{data.validationMessage}</span>
                                :
                                <label style={data.subquestions.length === 0 ? {} : {marginBottom: 10}}></label>
                        }
                    </React.Fragment>
                    :
                    data.responseType === 'h5' 
                        ?
                        <React.Fragment>
                            {
                                data.isVisible 
                                    ?
                                    data.helpText.length === 0 
                                        ?
                                        <label htmlFor="question" id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} style={{ display: 'block' }}>
                                            <h4>{htmlConverterReact(data.question)}</h4>
                                        </label>
                                        :
                                        <label htmlFor="question" id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} style={{ display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between', marginBottom: -20 }}>
                                            <h4>{htmlConverterReact(data.question)}</h4>
                                            <span className='tool' data-tip={htmlConverterReact(data.helpText)}>
                                                <span className="icon mdi mdi-1x mdi-help-outline">
                                            </span>
                                            </span>
                                        </label>
                                    :
                                    <React.Fragment></React.Fragment>
                                
                            }
                            {
                                data.isVisible 
                                    ?
                                    data.subText.length === 0
                                        ?
                                        <React.Fragment></React.Fragment>
                                        :
                                        <React.Fragment>
                                            <br/>
                                            <label htmlFor="question">{htmlConverterReact(data.subText)}</label>
                                        </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                            {
                                data.isVisible 
                                    ?
                                    data.description.length === 0
                                        ?
                                        <React.Fragment></React.Fragment>
                                        :
                                        <React.Fragment>
                                            <br/>
                                            <label htmlFor="question">
                                                <i>{htmlConverterReact(data.description)}</i>
                                            </label>
                                        </React.Fragment>
                                    :
                                    <React.Fragment></React.Fragment>
                            }
                        </React.Fragment>
                        :
                        <div className="form-group" style={data.isVisible ? {} : {display: 'none'}}>
                            {
                                data.responseType === 'none' 
                                    ?
                                    data.helpText.length === 0 
                                        ?
                                        <label htmlFor="question" id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]}>
                                            <b>{htmlConverterReact(data.question)}</b>
                                        </label>
                                        :
                                        <label htmlFor="question" id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} style={data.subText.length === 0 && data.description.length === 0 ? { display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between' } : { display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between', marginBottom: -10 }}>
                                            <b>{htmlConverterReact(data.question)}</b>
                                            <span className='tool' data-tip={htmlConverterReact(data.helpText)}>
                                                <span className="icon mdi mdi-1x mdi-help-outline">
                                            </span>
                                            </span>
                                        </label>
                                    :
                                    data.helpText.length === 0 
                                        ?
                                        <label htmlFor="question">
                                            <b>{htmlConverterReact(data.question)}</b>
                                        </label>
                                        :
                                        <label htmlFor="question" style={data.subText.length === 0 && data.description.length === 0 ? { display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between' } : { display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between', marginBottom: -10 }}>
                                            <b>{htmlConverterReact(data.question)}</b>
                                            <span className='tool' data-tip={htmlConverterReact(data.helpText)}>
                                                <span className="icon mdi mdi-1x mdi-help-outline">
                                            </span>
                                            </span>
                                        </label>
                            }
                            {
                                data.subText.length === 0
                                    ?
                                    <React.Fragment></React.Fragment>
                                    :
                                    <React.Fragment>
                                        <br/>
                                        <label htmlFor="question">{htmlConverterReact(data.subText)}</label>
                                    </React.Fragment>
                            }
                            {
                                data.description.length === 0
                                    ?
                                    <React.Fragment></React.Fragment>
                                    :
                                    <React.Fragment>
                                        <br/>
                                        <label htmlFor="question">
                                            <i>{htmlConverterReact(data.description)}</i>
                                        </label>
                                    </React.Fragment>
                            }
                            {
                                data.responseType === 'matrix' && data.matrix !== null
                                    ? 
                                    <Matrix key={index} index={index} subSectionIndex={subSectionIndex} sectionIndex={sectionIndex} data={data} state={state} setState={setState} userType={userType} questionRefs={questionRefs} calculateQuestionRefIndex={calculateQuestionRefIndex} visibilityBasedOnType={visibilityBasedOnType} handleMatrixChange={handleMatrixChange} />
                                    :
                                    data.responseType === 'dropdown' 
                                        ?
                                        <select id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} name="question" className="form-control form-control-sm col-xl-4" required={data.required} value={data.response} onChange={(e) => { updateState(e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? (data.readOnly ? true : false) : true}>
                                            {
                                                data.options.map((option, index) => {
                                                    return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                })
                                            }
                                        </select>
                                        :
                                        data.responseType === 'textarea' 
                                            ?
                                            <textarea id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} className="form-control form-control-sm col-xl-6" placeholder={data.placeholder} value={data.response} onChange={(e) => { if(data.regex === null || (data.regex.length !== 0 && new RegExp(data.regex).test(e.target.value)) || data.regex.length === 0){ updateState(e.target.value) } }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} readOnly={data.isCalculated || data.readOnly} />
                                            :
                                            data.responseType === 'text' 
                                                ?
                                                <div className="input-group input-group-sm">
                                                    <input id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} className="form-control form-control-sm col-xl-5" type="text" placeholder={data.placeholder} value={data.response} onChange={(e) => { if(data.regex === null || (data.regex.length !== 0 && new RegExp(data.regex).test(e.target.value)) || data.regex.length === 0){ updateState(e.target.value) } }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} readOnly={data.isCalculated || data.readOnly} />
                                                    {
                                                        data.append.length === 0 
                                                            ?
                                                            <React.Fragment></React.Fragment>
                                                            :
                                                            <div className="input-group-append">
                                                                <span className="input-group-text">{data.append}</span>
                                                            </div>
                                                    }
                                                </div>
                                                :
                                                <React.Fragment></React.Fragment>
                            }
                            {
                                data.responseType === 'matrix'
                                    ? 
                                    data.required && !checkCompletionStatusForResponseTypeMatrix(data.matrix) && state.sections[sectionIndex].subsections[subSectionIndex].isSubmitButtonClicked
                                        ?
                                        <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger" style={data.subquestions.length === 0 ? {} : {marginBottom: 10}}></i>&nbsp;{data.validationMessage}</span>
                                        :
                                        <label style={data.subquestions.length === 0 ? {} : {marginBottom: 10}}></label>
                                    :
                                    data.required && data.response.length === 0 && state.sections[sectionIndex].subsections[subSectionIndex].isSubmitButtonClicked
                                        ?
                                        <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger" style={data.subquestions.length === 0 ? {} : {marginBottom: 10}}></i>&nbsp;{data.validationMessage}</span>
                                        :
                                        <label style={data.subquestions.length === 0 ? {} : {marginBottom: 10}}></label>
                            }
                            {
                                data.subquestions.map((subquestion, subquestion_index) => {
                                    return <SubQuestion key={subquestion_index} index={subquestion_index} data={subquestion} subquestionIndexes={[]} questionIndex={index} subSectionIndex={subSectionIndex} sectionIndex={sectionIndex} state={state} setState={setState} hasValueBasedToggling={data.hasValueBasedToggling} parentValue={data.response} userType={userType} questionRefs={questionRefs} calculateQuestionRefIndex={calculateQuestionRefIndex} visibilityBasedOnType={visibilityBasedOnType} setAlertDialogBoxProperties={setAlertDialogBoxProperties} />
                                })
                            }
                        </div>
            }
        </React.Fragment>
    )
}

export default Question