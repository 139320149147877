import React from 'react'

const ApplicationCard = ({ title, applicationPending }) => {

    return (
        <div className="card card-border">
            <div className="card-body text-center" style={{position: 'relative'}}>
                <h5>{title}</h5>
                <h1 className="text-center text-danger">{applicationPending}</h1>
            </div>
        </div>
    )
}

export default ApplicationCard