import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const EditComment = ({ toggler, verifier ,notes ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [comments, setcomments] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");




     //get DetailsByID
 useEffect(() => {
    fetch(`${config.apiUrl}${apiconfig.getVerifierNotesByID}${notes.id}`,{
      headers: {

        Authorization: `Bearer ${localStorage.getItem("token")}`

    }
    })
        .then((response) => response.json())
        .then((data) => {

            setcomments(data.description);

        }).catch((err) => {
          console.log("Error: ", err);
          navigate("/error-page");
        });
}, [notes.id])

    {/*Validation*/}
    const validateForm = (comments) => {
        const errors = {};
        if(!comments){
            errors.comments = 'Please enter the notes.';
        }else if(comments.length > 1000){
            errors.comments = 'Maximum of 1000 characters allowed.';
        }
        return errors;
    }

    const editNotes = async () => {
        const requestBody = {
            id : notes.id,
            verifierID : verifier,
            reference : 'Notes',
            description : comments.trim(),
            userID : userID,


        };
        const validationErrors = validateForm(comments.trim());
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.editVerifierNotes}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Notes updated successfully.')
            {
                toast.success('Notes updated successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

    return (
        <div>
    <form>
                    <div className="form-row">
                        <label htmlFor="input_comments">Notes</label>
                        <textarea type="text" value={comments} placeholder='Please enter the notes...' className="form-control form-control-xs" id="input_comments" onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={editNotes}>Submit</button>
            </div>
            </div>
    );
};

export default EditComment;