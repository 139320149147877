import { useState } from "react";
import CommonModalWithImage from "../../Common/CommonModalWithImage";
import CommonCustomModal from "../../Common/CommonCustomModal";
import { Label } from 'reactstrap';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Navigate, useNavigate } from "react-router-dom";
import EditCommunication from "./EditCommunication";
import ViewCommunication from "./ViewCommunication";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";

const CommunicationList = ({ verifier, communication, refetch, setRefetch,isExternalVerifierUser }) => {


    const [editCommentModal, setEditCommentModal] = useState(false);
    const [deleteNotesModal, setDeleteNotesModal] = useState(false);
    const [viewNotesModal, setViewNotesModal] = useState(false);
    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    
    
const toggleForEditCommentModal = () => {
    setEditCommentModal(!editCommentModal);
}  
const toggleForDeleteNotesModal = () => {
    setDeleteNotesModal(!deleteNotesModal);
}
const toggleForViewNotesModal = () => {
    setViewNotesModal(!viewNotesModal);
  }

const deleteCommunication = async () => {

    const requestBody = {
        "iD": communication.id,
        "userID": userID,
    };
    try {
        const response = await fetch(`${config.apiUrl}${apiconfig.deleteCommunication}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        }).then((response) => {
            return response.json();
        })
        if(response.saveErrorMessage === 'Communication deleted successfully.')
        {
            toast.success('Communication deleted successfully.', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide
            }); 
        setRefetch(!refetch);      
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
    toggleForDeleteNotesModal();
};

return (
    <>
        <tr>
        <td>{communication.description}</td>
        <td>{communication.createdBy}</td>
        <td>{communication.createdDate}</td>
        { isReadOnlyAccess1  === "FullAccess" || isExternalVerifierUser  ?
        <td className="text-right actions">
        <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
        <i className="mdi mdi-more"></i>
        <span className="sr-only">Menu</span>
        </a>
        <div className="dropdown-menu">
        {isReadOnlyAccess1  === "FullAccess" ? 
        <>  
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditCommentModal(); }} ><span className="icon mdi mdi-edit"></span>Edit</a>    
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForDeleteNotesModal(); }} ><span className="icon mdi mdi-delete"></span>Delete</a>   
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForViewNotesModal(); }} ><span className="icon mdi mdi-eye"></span>View</a>   
        </>  
        :
        isExternalVerifierUser ?
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForViewNotesModal(); }} ><span className="icon mdi mdi-eye"></span>View</a>  
        :
        <></>
        }                      
        </div>
        </td>
        :
        <td></td>
        }
        </tr>

<CommonCustomModal modal={editCommentModal}  title='' toggler={toggleForEditCommentModal} heading='Edit Communication for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
    <EditCommunication toggler={toggleForEditCommentModal} verifier = {verifier} communication= {communication} refetchs={refetch} setRefetchs={setRefetch}/>
</CommonCustomModal>
<CommonModalWithImage modal={deleteNotesModal} title='Delete Communication' toggler={toggleForDeleteNotesModal} heading='Delete Communication' triggerMethodOnSaveButtonClick={deleteCommunication}  buttonText='Yes'>
    <Label className="col-form-label">{'Are you sure you want to delete communication '}{'?'}</Label>
</CommonModalWithImage>
<CommonCustomModal modal={viewNotesModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForViewNotesModal} heading='View Communication for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
    <ViewCommunication toggler={toggleForViewNotesModal}  communication= {communication} />
</CommonCustomModal>
</>
);

};

export default CommunicationList;
