import { htmlConverterReact } from "html-converter-react"

const ConfirmationDialogBox = ({ data }) => {
    return (
        <div>
            <form>
                <div className="form-row">               
                    <label style={{ paddingLeft: 10 }}>{htmlConverterReact(data.confirmationText)}</label>
                </div>  
            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="100" alt='ACA Logo'></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={() => { data.confirmation('negative') }}>{data.confirmationNegativeButtonText}</button>
                <button className="btn btn-success mt-auto" type="button" onClick={() => { data.confirmation('positive') }}>{data.confirmationPositiveButtonText}</button>
            </div>
        </div>
    )
}

export default ConfirmationDialogBox