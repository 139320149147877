import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';




const VerifierExamFee = ({ fees}) => {

    
        return (
            <>
                <tr>
                    <td>{fees.examLevel}</td>
                    <td>{fees.examType}</td>
                    <td>{fees.resit}</td>
                    <td>{fees.examFee}</td>
                    <td>{fees.currency}</td>
                    
                    {/* <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu">

                          </div>
                    </td> */}
                </tr>

            </>
        );
};

export default VerifierExamFee;