import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const ViewCommunication = ({ toggler, communication}) => {

    const navigate = useNavigate();
    const [comments, setcomments] = useState("");

//get DetailsByID
const getCommunicationDetailsByID = async () => {

  try{
  const response = await fetch(`${config.apiUrl}${apiconfig.getVerifierCommunicationByID}${communication.id}`,{
      method: "GET",
      headers: { 
          Authorization: `Bearer ${localStorage.getItem("token")}`  
      }
  });
  if (response.ok) {
      const result = await response.json();
      setcomments(result.description);

  } else {
      const errorText = await response.text();
      const apiname = `${apiconfig.getVerifierCommunicationByID}`;
      navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
  }
}
catch (error) {
  console.log("Error: ", error);
  navigate("/error-page");
}
}; 

useEffect(() => {
  getCommunicationDetailsByID();
}, [communication.id]);





return (
    <div>
      <form>
        <div>
          <label htmlFor="comments" className="text-break">{comments}</label>
        </div>
      </form>
      {/* <div className="modal-footer">
      <img className="mr-auto" src="dist/img/ACA-logo.png" width="150"></img>
      <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
      <button className="btn btn-success mt-auto" type="button" onClick={editCommunication}>Submit</button>
      </div> */}
    </div>
);
};

export default ViewCommunication;