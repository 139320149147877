import { useState } from "react";
import CommonModalWithImage from "../../Common/CommonModalWithImage";
import CommonCustomModal from "../../Common/CommonCustomModal";
import { Label } from 'reactstrap';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Navigate, useNavigate } from "react-router-dom";
import EditVerifierExam from "./EditVerifierExam";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";
import IsAccessForVerifierCombination2 from "../../Common/IsAccessForAccreditationCombination";

const VerifiersExamList = ({ verifier, exam, refetch, setRefetch,refetchs,setRefetchs ,isExternalVerifierUser}) => {
    console.log('ex',exam)
    const [editExamModal, setExamModal] = useState(false);
    const [deleteExamModal, setDeleteExamModal] = useState(false);
    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    const isReadOnlyAccess2 = IsAccessForVerifierCombination2();
    
  const toggleForEditExamModal = () => {
    setExamModal(!editExamModal);
}  
const toggleForDeleteExamModal = () => {
    setDeleteExamModal(!deleteExamModal);
}

const deleteExam = async () => {

    const requestBody = {
        "id": exam.examID,
        "userID": userID,
        "actionType" : 'Exam'
    };
    try {
        const response = await fetch(`${config.apiUrl}${apiconfig.deleteExamInvoice}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        }).then((response) => {
            return response.json();
        })

        if(response.saveErrorMessage === 'Exam deleted successfully.')
        {
            toast.success('Exam deleted successfully.', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide
            }); 
        setRefetch(!refetch);   
        setRefetchs(!refetchs);   
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
    toggleForDeleteExamModal();
};

return (
    <>
        <tr>
        {!isExternalVerifierUser?
        <>
        {isReadOnlyAccess1  === "FullAccess" ? 
        <td><a className="text-danger" href={`/viewexam/${exam.examID}`}>{exam.examID}</a></td>
        :
        <td>{exam.examID}</td>
        }
        <td>{exam.externalExamRef}</td>
        <td>{exam.examType}</td>
        <td>{exam.examLevel}</td>
        <td>{exam.examStatus}</td>
        <td>{exam.resit}</td>
        {isReadOnlyAccess1  === "FullAccess" ? 
        <>
        <td><a className="text-danger" href={`/viewsponsor/${exam.sponsorID}`}>{exam.sponsorName}</a></td>
        <td><a className="text-danger" href={`/viewinvoice/${exam.invoiceID}`}>{exam.invoiceID}</a></td>
        </>
        :
        <>
        <td>{exam.sponsorName}</td>
        <td>{exam.invoiceID}</td>
        </>
        }
        <td>{exam.createdDateTime}</td>
        { isReadOnlyAccess1  === "FullAccess" ? 
        <td className="text-right actions">
        {((exam.invoiceStatus ==='Invoice Paid' && exam.examStatus !== 'Exam Failed' && exam.examStatus!== 'Exam Passed' && exam.examStatus!== 'Verifier Removed' && exam.isActive === 1) ||
        (exam.examStatus === 'Payment Pending' && exam.invoiceStatus ==='Invoice Raised' && exam.isActive === 1)) && (   
        <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
        <i className="mdi mdi-more"></i>
        <span className="sr-only">Menu</span>
        </a>
        )}
        <div className="dropdown-menu"> 
        {(exam.invoiceStatus ==='Invoice Paid' && exam.examStatus !== 'Exam Failed' && exam.examStatus!== 'Exam Passed') && (         
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditExamModal(); }} ><span className="icon mdi mdi-edit"></span>Edit</a> 
        )} 
        {exam.examStatus === 'Payment Pending' && exam.invoiceStatus ==='Invoice Raised' && (     
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForDeleteExamModal(); }} ><span className="icon mdi mdi-delete"></span>Delete</a>   
        )}                       
        </div>
        </td>
        :
        <td></td>
        }
        </>
        :
        <>
        <td>{exam.examID}</td>
        <td>{exam.externalExamRef}</td>
        <td>{exam.examType}</td>
        <td>{exam.examLevel}</td>
        <td>{exam.examStatus}</td>
        <td>{exam.resit}</td>
        <td>{exam.sponsorName}</td>
        <td>{exam.invoiceID}</td>
        <td>{exam.createdDateTime}</td>
        </>
        }
        </tr>

<CommonCustomModal modal={editExamModal}  title='' toggler={toggleForEditExamModal} heading='Edit Exam for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
<EditVerifierExam toggler={toggleForEditExamModal} verifier = {verifier} exam= {exam} refetchs={refetch} setRefetchs={setRefetch} refetchd={refetchs} setRefetchd={setRefetchs} />
</CommonCustomModal>
<CommonModalWithImage modal={deleteExamModal} title='' toggler={toggleForDeleteExamModal} heading='Delete Exam' triggerMethodOnSaveButtonClick={deleteExam} buttonText='Yes'>
<Label className="col-form-label">{'Are you sure you want to delete exam '}{'?'}</Label>
</CommonModalWithImage>

</>
);

};

export default VerifiersExamList;
