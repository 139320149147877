import React from 'react';

export const AppTableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Reporting Period',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Reportingperiod'
    },
    {
        id: 2,
        isHidden: false,
        name: 'Application ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '5%' },
        isSortable: true,
        sortByColumnName: 'ApplicationID'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Application Type',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'ApplicationType',

    },
    {
        id: 4,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '5%' },
        isSortable: true,
        sortByColumnName: 'Status',

    },
    {
        id: 5,
        isHidden: false,
        name: 'Airport Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'AirportName',

    },
    {
        id: 6,
        isHidden: false,
        name: 'Assignee',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Assignee',

    },
    {
        id: 7,
        isHidden: false,
        name: 'Accreditation Level',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AccreditationLevel',

    },
    {
        id: 8,
        isHidden: false,
        name: 'Accredited Date',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AccreditedDate',

    },
    {
        id: 9,
        isHidden: false,
        name: 'Next Renewal Due Date',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'NextRenewalDueDate',

    },
    {
        id: 10,
        isHidden: false,
        name: '3 - Yearly Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '5%' },
        isSortable: true,
        sortByColumnName: 'Year3Status',

    },
    {
        id: 11,
        isHidden: false,
        name: '3 - Yearly Year',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '5%' },
        isSortable: true,
        sortByColumnName: 'Year3Year',

    },
    // {
    //     id: 12,
    //     isHidden: false,
    //     name: 'Eligible for 3-Year Renewal',
    //     colSpan: 1,
    //     hasChild: false,
    //     child: null,
    //     style:  { cursor: 'pointer', width: '5%' },
    //     isSortable: true,
    //     sortByColumnName: 'EligibleForThreeYearRenewal',

    // },
   
    {
        id: 13,
        isHidden: false,
        name: 'Overall Progress',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '20%' },
        isSortable: false,
        sortByColumnName: 'OverallProgress',

    },
    {
        id: 14,
        isHidden: false,
        name: 'Actions',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { width: '5%' },
        isSortable: false,
        sortByColumnName: null
    }
];