import React from 'react';
import { TbArrowsSort } from 'react-icons/tb';

export const TableHeaderCells = [
    {
        id: 1,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        style: {},
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 2,
        isHidden: false,
        name: 'Permission Name',
        colSpan: 1,
        style: { cursor: 'pointer' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'name'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Description',
        colSpan: 1,
        style: { cursor: 'pointer' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'description'
    }
];