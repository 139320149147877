import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import { useNavigate } from "react-router-dom";
import config from "../../api/config";
import apiconfig from "../../api/apiconfig";

const ProgrammeDocuments = () => {
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const userID = localStorage.getItem("userID");

  let redirectPath = "/";
  const userRoles = JSON.parse(localStorage.getItem("roles"));

  if (userRoles.length === 1) {
    userRoles.map((role) => {
      if (role.name === "Verifier User")
        redirectPath = "/VerifierDashboard";
      if (role.name === "Airport User") {
        if (role.returnType === "Single User")
          redirectPath = "/AirportGroupDashboard";
        if (role.returnType === "Group User")
          redirectPath = "/AirportGroupDashboard";
      }
    });
  }

  const getGuidanceDocumentsList = async () => {
    try {
      const response = await fetch(
        `${config.apiUrl}${apiconfig.getGuidanceDocumentList}${userID}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.ok) {
        var result = await response.json();
        setDocumentList(result);
      } else {
        const errorText = await response.text();
        const apiname = `${apiconfig.getGuidanceDocumentList}`;
        navigate(`/error-page/${errorText} on ${apiname.split("/")[2]}`);
      }
    } catch (error) {
      console.log(error.message);
      navigate(`/error-page`);
    }
  };

  useEffect(() => {
    getGuidanceDocumentsList();
  }, []);

  return (
    <Layout>
      <div className="be-content">
        <div className="main-content container-fluid p-0">
          <div className="page-header ph-orange mb-4">
            <div className="container-header">
              <div className="row">
                <div className="col">
                  <img src={require("../../img/ACA-logo.png")} alt="ACALogo" />
                </div>
                <div className="col-6 d-flex align-items-center justify-content-center">
                  <h3 className="page-header-title">Programme Documents</h3>
                </div>
                <div className="col"></div>
              </div>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb mb-0 mt-4">
                  <li className="breadcrumb-item">
                    <a href={redirectPath}>
                      <span className="icon mdi mdi-home"></span>
                    </a>
                  </li>
                  <li className="breadcrumb-item active">
                    Programme Documents
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="container-header">
            {documentList.reduce((rowsNum, category, index) => {
                    const rowIndex = Math.floor(index / 3);
                    if (!rowsNum[rowIndex]) {
                        rowsNum[rowIndex] = [];
                    }
                    rowsNum[rowIndex].push(category);
                    return rowsNum;
                }, []).map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                        {row.map((category, colIndex) => (
                            <div className="col-md-4 d-flex align-items-stretch" key={colIndex}>
                                <div className="card card-border w-100">
                                    <div className="card-header" style={{ textAlign: 'center' }}>{category.documentCategory}</div>
                                    <div className="card-body">
                                        <ul>
                                            {category.guidenceDocumentList.map((document, docIndex) => (
                                                <li key={docIndex}>
                                                    <a href={`/${document.documentFile}`} target="_blank" style={{ color: 'blue', cursor: 'pointer' }} rel="noopener noreferrer">
                                                        {document.documentTitle}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProgrammeDocuments;
