import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import FormRow from "./FormRow"
import { Slide, ToastContainer, toast } from 'react-toastify'
import Loader from "../../Common/Loader"

const VerifierRegistrationForm = () => {

    const [loading, setLoading] = useState(false);

    /* HELPERS */
    const navigate = useNavigate()

    /* STATES */
    const [form, setForm] = useState([
        [
            { label: 'Verifier Name', value: '', isRequired: true, isValid: false, inputType: 'text', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: 1, maxLimit: 256, allowSpecialCharacters: false, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:false, customErrorMessage:''  },
      
        ],
        [
            { label: 'Address Line 1', value: '', isRequired: true, isValid: false, inputType: 'text', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: 1, maxLimit: 256, allowSpecialCharacters: false, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:true, customErrorMessage:''   },
            { label: 'Address Line 2 (Optional)', value: '', isRequired: false, isValid: true, inputType: 'text', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: 0, maxLimit: 256, allowSpecialCharacters: false, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:false, customErrorMessage:''   }
        ],
        [
            { label: 'Town/City', value: '', isRequired: true, isValid: false, inputType: 'text', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: 1, maxLimit: 256, allowSpecialCharacters: false, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:false, customErrorMessage:''   },
            { label: 'Post Code/ZIP', value: '', isRequired: true, isValid: false, inputType: 'text', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: 1, maxLimit: 256, allowSpecialCharacters: false, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:false, customErrorMessage:''   }
        ],
        [
            { label: 'Region', value: '', isRequired: true, isValid: false, inputType: 'dropdown', defaultValue: 'default', options: [], disabled: false, fileName: null, minLimit: null, maxLimit: null, allowSpecialCharacters: null, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:false, customErrorMessage:''   },
            { label: 'Country', value: '', isRequired: true, isValid: false, inputType: 'dropdown', defaultValue: 'default', options: [], disabled: true, fileName: null, minLimit: null, maxLimit: null, allowSpecialCharacters: null, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:false, customErrorMessage:''   }
        ],
        [   
            { label: 'Email Address', value: '', isRequired: true, isValid: false, inputType: 'email', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: null, maxLimit: null, allowSpecialCharacters: null, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:true, customErrorMessage:'' },
            { label: 'Phone Number', value: '', isRequired: true, isValid: false, inputType: 'phone', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: null, maxLimit: null, allowSpecialCharacters: null, hasInvalidFileExtension: false, fileSizeLimitExceeded: false, isToolTipRequired:true, customErrorMessage:''   }
        ],
        [
            { label: 'Verifier Evidence', value: '', isRequired: true, isValid: false, inputType: 'file', defaultValue: null, options: null, disabled: false, fileName: null, minLimit: null, maxLimit: null, allowSpecialCharacters: null, hasInvalidFileExtension: false, fileSizeLimitExceeded: false,files:[],collectivefileSizeLimitExceeded:false,isDuplicate:false, isToolTipRequired:false, customErrorMessage:''   },
        ]
    ])
    const [confirmationText, setConfirmationText] = useState('')
    const [confirmationCheckBox, setConfirmationCheckBox] = useState(false)
    const [hasRegionChanged, setHasRegionChanged] = useState(false)
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false)
    const [isEvidenceCheck, setIsEvidenceCheck]= useState(false)

    /* METHODS */
    const handleChangeForConfirmationCheckBox = () => {       
        setConfirmationCheckBox(!confirmationCheckBox)
    }

    /* API GET CALLS */
    const getConfirmationText = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getConfirmationText}${'ExternalVerifierRegistartion'}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                const result = await response.json()
                setConfirmationText(result[0].narration)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getConfirmationText}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            //console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const getRegions = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getRegionList}${0}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                const result = await response.json();
                const updatedForm = [...form]
                const regionField = updatedForm[3][0]
                regionField.options = result
                setForm(updatedForm)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getRegionList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            //console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const getCountries = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryList}${form[3][0].value}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                var result = await response.json()
                const updatedForm = [...form];
                updatedForm[3][1].options = result;
                setForm(updatedForm);
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getCountryList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            //console.log(error.message);
            navigate(`/error-page`); 
        }
    }
    
    /* API POST CALL(S) */
    const submitForm = async () => {
        let isFormValid = true;
        setSubmitButtonClicked(true)
        setIsEvidenceCheck(true)
        const updatedForm = [...form];

        if(updatedForm[5][0].files.length > 0){
            updatedForm[5][0].hasInvalidFileExtension = false;
            updatedForm[5][0].fileSizeLimitExceeded = false;
            updatedForm[5][0].collectivefileSizeLimitExceeded = false;
            updatedForm[5][0].isDuplicate = false;   
            updatedForm[5][0].isValid  = true;         
        }
        setForm(updatedForm);

        updatedForm.map((row) => {
            row.map((attribute) => {
                if(attribute.isRequired && !attribute.isValid){
                    // console.log(attribute.label)
                    isFormValid = false
                }
            })
        })
        // console.log(isFormValid)
        if(isFormValid){
            setLoading(true);

            const requestBody = {
   
                "verifierName": form[0][0].value.trim(),
                "countryID": form[3][1].value.trim(),
                "regionID": form[3][0].value.trim(),
                "email": form[4][0].value.trim(),
                "phoneNumber": form[4][1].value.trim(),
                "addressLine1": form[1][0].value.trim(),
                "addressLine2": form[1][1].value.trim(),
                "city": form[2][0].value.trim(),
                "zipcode": form[2][1].value.trim(),
                "imageName": '',
                "docContent": '',
                "description": 'External Evidence',
            }
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.addExternalVerifier}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    body: JSON.stringify(requestBody),
                })
                if(response.ok)
                {
                    var result1 = await response.json();

                    if(result1.saveErrorMessage === 'Verifier already exists.'){
                        toast.error(result1.saveErrorMessage, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide }); 
                    }
                    else if(result1.saveErrorMessage === 'This email address is already linked with an existing user account, please contact the ACA Admin Team (aca@wsp.com).'){
                        setForm(prevForm => {
                            const newForm = [...prevForm]; 
                            newForm[4][0].customErrorMessage = 'This email address is already linked with an existing user account, please contact the ACA Admin Team (aca@wsp.com).';
                            return newForm;
                          });
                        // toast.error(result1.saveErrorMessage, { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide }); 
                    }
                    else {
                        var verifierID = result1.saveErrorMessage
                        for (let i = 0; i < form[5][0].files.length; i++) {
                            const requestBody = {
                                "id": 0,
                                "verifierID": verifierID,
                                "documentTypeID": 4,
                                "imageName":form[5][0].files[i].name,
                                "docContent":form[5][0].files[i].content,
                                "description": 'External Evidence',
                                "userID": 0
                            };
                    
                            try {
                                const response = await fetch(`${config.apiUrl}${apiconfig.addDocument}`, {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                                    },
                                    body: JSON.stringify(requestBody),
                                })
                                if(response.ok)
                                {
                                    var result = await response.json();
                    
                                    if(result.saveErrorMessage === 'Document saved successfully.'){
                                       
                                        if (i === form[5][0].files.length - 1) {
                                            setLoading(false);
                                            toast.success('New Prospective Verifier registration request has been created successfully.', {
                                            position: "top-right",
                                            autoClose: 10000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                            theme: "light",
                                            transition: Slide
                                            });
                                        setTimeout(function(){
                                            window.location.href = "/login";
                                        }, 4000); 
                                        }
                                    }
                                }else {
                                    const errorText = await response.text();
                                    const apiname = `${apiconfig.addDocument}`;
                                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                                }
                            } 
                            catch (error) {
                                //console.log("Error: ", error)
                                navigate("/error-page")
                            }
                        }

                    } 
                }else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.addExternalVerifier}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
            } 
            catch (error) {
                //console.log("Error: ", error)
                navigate("/error-page")
            }finally {
                setLoading(false);
            }
        }
        // else{
        //     setIsSubmitCheck(false)
        // }
    }

    /* HOOKS */
    useEffect(() => {
        getConfirmationText()
        getRegions()
    }, [])

    useEffect(() => {
        if(form[3][0].value !== '' && hasRegionChanged){
            setHasRegionChanged(false)
            getCountries()
        }
    }, [form])


    return(
        <>
        {loading && <Loader />}
        <div className="be-content">
            <div className="main-content container-fluid p-0">
                <div className="page-header ph-orange mb-4">
                    <div className="container-header">
                        <div className="row" style={{padding : '15px'}}>
                            <div className="col-2">
                                <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-8 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">Verifier Registration</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">
                                <a href="/login"><button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }}><i className="icon icon-left mdi mdi-arrow-left"></i>Back to Login</button></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-header" style={{padding : '15px'}}>
                    <h3 className="page-header-title">Registration Form</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-8">
                            <p className="mb-2 font-weight-normal">Only <strong>‘New/Prospecting’</strong> Verifiers should register, existing Verifiers (Active/Removed) should contact the ACA Admin Team or if any issues are encountered (<a style={{color:'blue'}} href="mailto:ACA@wsp.com">ACA@wsp.com</a>).</p>
                        </div>
                    </div>
                    <div className="modal-body">
                        <form>
                            {
                                form.map((row, index) => {
                                    return <FormRow key={index} formRow={row} state={form} setState={setForm} setHasRegionChanged={setHasRegionChanged} submitButtonClicked={submitButtonClicked} isEvidenceCheck={isEvidenceCheck} setIsEvidenceCheck={setIsEvidenceCheck}/>
                                })    
                            }
                        </form>
                        <div style={{ marginTop: 20, marginBottom: 10 }}>
                            <div className="custom-control custom-checkbox custom-control-inline m-0" style={{  }}>
                                <input id="confirmationCheckbox" className="custom-control-input" type="checkbox" onChange={handleChangeForConfirmationCheckBox} checked={confirmationCheckBox} />
                                <label htmlFor="confirmationCheckbox" className="custom-control-label">{confirmationText}</label>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn-secondary mt-auto" type="button" onClick={() => { navigate("/login") }}>Cancel</button>
                        <button className="btn btn-success mt-auto" type="button" onClick={() => { submitForm() }} disabled={!confirmationCheckBox}>Submit</button>
                    </div>
                </div>
            </div>
            <ToastContainer />  
        </div>
        </>
    )
}

export default VerifierRegistrationForm