import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';
import CommonCustomModal from '../../Common/CommonCustomModal';
import ApproveAirport from './ApproveAirport';
import RejectAirport from './RejectAirport';
import AddUser from '../ManageAccount/AddUser';
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination';







const AccreditationAirport = ({ accAirport, searchName, refetch, setRefetch,refetchDash,setRefetchDash}) => {

    const navigate = useNavigate();
    const userID = localStorage.getItem("userID");
    const [rejectModal, setRejectModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const isFullAccess = IsAccessForAccreditationCombination();


    const toggleForAddUserModal = () => {
      setAddUserModal(!addUserModal);
  }  
    const toggleForRejectModal = () => {
        setRejectModal(!rejectModal);
      } 

      const toggleForApproveModal = () => {
        setApproveModal(!approveModal);
      } 


        return (
            <>
                <tr>
                    <td>{accAirport.airportType}</td>
                    <td>{accAirport.airportGroup}</td>
                    <td title={accAirport.airportNameTootltip}>{accAirport.airport}</td>
                    <td>{accAirport.createdDate}</td>
                    <td>{accAirport.createdBy}</td>
                    {
                    accAirport.airportStatus === 'Approved' 
                    ? 
                    <td><span className="badge badge-success">{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'Rejected'
                        ?
                        <td><span className="badge badge-danger">{accAirport.airportStatus}</span></td>   
                        :
                        <td><span className="badge badge-warning">{accAirport.airportStatus}</span></td> 
                    }
                    {isFullAccess === 'AccFullAccess' ?
                    <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu">  
                            <a className="dropdown-item"  style={{cursor:'pointer'}} href={`/ViewAccreditationAirportDetails/${accAirport.airportApplicationID}`}><span className="icon mdi mdi-eye"></span>View</a> 
                            {accAirport.airportStatus ==='Pending' && (
                            <>
                            <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForApproveModal();}}><span className="icon mdi mdi-check-circle-u"></span>Approve</a> 
                            <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForRejectModal();}}><span className="icon mdi mdi-close-circle-o"></span>Reject</a> 
                            </>
                            )}
                            {/* <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForAddUserModal();}}><span className="icon mdi mdi-plus"></span>Add User</a>                       */}
                          </div>
                    </td>
                    :
                    <td></td>
                    }
                </tr>
             <CommonCustomModal modal={rejectModal} title='' toggler={toggleForRejectModal} heading='Reject Airport'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <RejectAirport toggler={toggleForRejectModal} accAirport = {accAirport} refetchs={refetch} setRefetchs={setRefetch} refetchDash={refetchDash} setRefetchDash={setRefetchDash}/>
            </CommonCustomModal>
            <CommonCustomModal modal={approveModal} title='' toggler={toggleForApproveModal} heading='Approve Airport'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <ApproveAirport toggler={toggleForApproveModal} accAirport = {accAirport}  refetchs={refetch} setRefetchs={setRefetch} refetchDash={refetchDash} setRefetchDash={setRefetchDash}/>
            </CommonCustomModal>
            <CommonCustomModal modal={addUserModal} title='' toggler={toggleForAddUserModal} heading='Add User'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddUser toggler={toggleForAddUserModal} accAirport = {accAirport}  refetchs={refetch} setRefetchs={setRefetch} refetchDash={refetchDash} setRefetchDash={setRefetchDash}/>
            </CommonCustomModal>
            </>
        );
};

export default AccreditationAirport;