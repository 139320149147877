import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';




const Invoice = ({ invoice, searchName, refetch, setRefetch }) => {
console.log(invoice);
        return (
            <>
                <tr>
                    <td>
                    <a className="text-danger" href={`/viewinvoice/${invoice.invoiceID}`}>{invoice.invoiceID}</a>    
                    </td>
                    <td>{invoice.invoiceNo}</td>
                    <td>{invoice.invoiceStatus}</td>
                    <td><a className="text-danger" href={`/viewexam/${invoice.examID}`}>{invoice.examID}</a></td>
                    <td>{invoice.externalExamRef}</td>
                    <td>{invoice.examStatus}</td>
                    <td><a className="text-danger" href={`/viewverifier/${invoice.verifierID}`}>{invoice.verifierName}</a></td>
                    <td><a className="text-danger" href={`/viewsponsor/${invoice.sponsorID}`}>{invoice.sponsorName}</a></td>
                    <td>{invoice.modifiedDateTime}</td>

                    
                    <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu">
                            <a className="dropdown-item"  style={{cursor:'pointer'}} href={`/viewinvoice/${invoice.invoiceID}`}><span className="icon mdi mdi-eye"></span>View</a>                            
                              
                          </div>
                        </td>
                </tr>

            </>
        );
};

export default Invoice;