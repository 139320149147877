import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import axios from '../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import CommonModalWithImageAndButton from '../Common/CommonModalWithImageAndButton';
import { Label } from 'reactstrap';
import CommonCustomModal from '../Common/CommonCustomModal';
import RenewDetails from './RenewDetails';

const RenewVerifier = ({ toggler,toggler1, verifier ,refetchs, setRefetchs, isRenewalApplicableFor2Attempt, renewalLevel, renewalLevelText, renewalReasonText, comments}) => {

    const navigate = useNavigate();
    const [examType, setExamType] = useState("");
    const [examTypeID, setExamTypeID] = useState("");
    const [fees, setFees] = useState("");
    const [resit, setResit] = useState("1st attempt");
    const [renewalExtensionDate, setRenewalExtensionDate] = useState("");
    const [formErrors, setFormErrors] = useState({});  
    const userID = localStorage.getItem("userID");

    const [isExtension, setIsExtension] = useState(false);
    const [isRenew, setIsRenew] = useState(false);
    const [isRepeat, setIsRepeat] = useState(false);
    const [isConfirmModal, setisConfirmModal] = useState(false);
    const [renewalDate,setRenewalDate] = useState("");
    const [upGradeModal, setUpGradeModal] = useState(false);
    const [isClicked, setIsClicked] = useState(true);
    const toggleForConfirmModal = () => {
      setisConfirmModal(!isConfirmModal);
    } 
    const toggleForUpGradeModal = () => {
      setUpGradeModal(!upGradeModal);
  }  



    {/*Validation*/}
    const validateForm = (renewalExtensionDate) => {
        const errors = {};
        if(!renewalExtensionDate){
            errors.renewalExtensionDate = 'Please select a date.';
        }
        return errors;
    }
    useEffect(() => {
      var requestType = 'Renewal'
      if(isRenewalApplicableFor2Attempt === 'Yes'){
        requestType = 'Renewal 2nd Attempt'
      }

        fetch(`${config.apiUrl}${apiconfig.getUpgradeDetails}${verifier.verifierDetailID}&LevelID=${renewalLevel}&RequestType=${requestType}`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
        })
            .then((response) => response.json())
            .then((data) => {
                //setExamType(data.examType);
                //setResit(data.isResit);
                //setFees(data.examFee);
                //setExamTypeID(data.examTypeID);
                setRenewalDate(data.renewalDate);

            }).catch((err) => {
                navigate("/error-page");
            });
    }, [verifier.verifierDetailID])

    const upgrade = async () => {

        const requestBody = {
            verifierID : verifier.verifierDetailID,
            sponsorID : verifier.sponsorID ,
            exampTypeID : examTypeID,
            amount : fees,
            resist : resit,
            userID : userID,
            requestType : 'Renewal'


        };
        // const validationErrors = validateForm();
        //     setFormErrors(validationErrors);
        //     if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.upgradeVerifier}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier has been renewed successfully.')
            {
                toast.success('Verifier has been renewed successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        //}
      };

    const downgradeVerifier = async () => {
      setIsClicked(!isClicked);
      const requestBody = {
        verifierID : verifier.verifierDetailID,
        comments :  'Not applicable to renew.',
        userID : userID,
        actionType : 'Case 1',
        levelID : renewalLevel


    };
        try {
          const response = await fetch(
            `${config.apiUrl}${apiconfig.downgradeVerifier}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
              body: JSON.stringify(requestBody),
            }
          ).then((response) => {
            return response.json();
          });
          if(response.saveErrorMessage === 'Verifier has been downgraded successfully.')
          {
              toast.success(response.saveErrorMessage, {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Slide
              }); 
          setRefetchs(!refetchs);
          toggler();
          }
          // else{
          //     console.log(response.saveErrorMessage);
          //     setErrorMessage(response.saveErrorMessage);
          // }
        } catch (error) {
          console.log("Error: ", error);
          navigate("/error-page");
        }
        setIsClicked(true);
    };  

      const saveExtension = async () => {

        const requestBody = {
            verifierID : verifier.verifierDetailID,
            userID : userID,
            renewalExtensionDate : renewalExtensionDate,
            actionType : 'Renewal'

        };
        const validationErrors = validateForm(renewalExtensionDate);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.saveRenewalExtension}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier has been set a renewal extension date.')
            {
                toast.success('Verifier has been set a renewal extension date.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

      const removedVerifier = async () => {
        setIsClicked(!isClicked);
          const requestBody = {
              verifierID : verifier.verifierDetailID,
              noteDescription :  'Renewal not performed',
              userID : userID,
              actionType : "Removed"
  
  
          };
        //   const validationErrors = validateForm();
        //       setFormErrors(validationErrors);
        //       if(Object.keys(validationErrors).length === 0){
            try {
              const response = await fetch(
                `${config.apiUrl}${apiconfig.updateVerifierStatus}`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                  },
                  body: JSON.stringify(requestBody),
                }
              ).then((response) => {
                return response.json();
              });
              if(response.saveErrorMessage === 'Verifier Status has been updated successfully to Removed.')
              {
                  toast.success('Verifier has been removed successfully', {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      transition: Slide
                  }); 
              setRefetchs(!refetchs);
              toggler();
              }
              // else{
              //     console.log(response.saveErrorMessage);
              //     setErrorMessage(response.saveErrorMessage);
              // }
            } catch (error) {
              console.log("Error: ", error);
              navigate("/error-page");
            }
            setIsClicked(true);
          //}
        };
  

    const handleYes = ()=>{
        setIsExtension(true);
    }
    const handleNo = ()=>{      
        setIsRenew(true);
        setIsRepeat(true);
        setFormErrors({});
    }
    const handleCancel = ()=>{
        setFormErrors({});
        setIsExtension(false);
        setIsRenew(false);
        setIsRepeat(false);
    }

    let oneMonthAfterRenewal = ''
    let oneMonthLaterString = ''
    if(renewalDate){
      const dateParts = renewalDate.split('-');
      const renewalDateObject = new Date(
      parseInt(dateParts[2], 10),
      parseInt(dateParts[1], 10) - 1, 
      parseInt(dateParts[0], 10)
      );
      const oneMonthLater = new Date(renewalDateObject);
      let date = oneMonthLater.getDate();
      oneMonthLater.setMonth(renewalDateObject.getMonth() + 1);
      if (oneMonthLater.getDate() !== date) {
        oneMonthLater.setDate(1);
      }
      oneMonthAfterRenewal = oneMonthLater.toISOString().split('T')[0]
  }
    useEffect(()=>{
    if(renewalDate){
        const dateParts = renewalDate.split('-');
        const renewalDateObject = new Date(
        parseInt(dateParts[2], 10),
        parseInt(dateParts[1], 10) - 1, 
        parseInt(dateParts[0], 10)
        );
        const oneMonthLater = new Date(renewalDateObject);
        let date = oneMonthLater.getDate();
        oneMonthLater.setMonth(renewalDateObject.getMonth() + 1);
        if (oneMonthLater.getDate() !== date) {
          oneMonthLater.setDate(1);
        }
        oneMonthLaterString = oneMonthLater.toISOString().split('T')[0]
        setRenewalExtensionDate(oneMonthLaterString);
    }
  },[renewalDate])

    return (
      <>
        
        <div>
            <form>
                {(isExtension===false && isRenew=== false) &&(<div className="form-row">                 
                            <label htmlFor="accreditation_level">Does the Verifier have any Planned {renewalLevelText} Verifications?</label>
                        </div>
                )}
               {isExtension &&(
               <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                            <label htmlFor="resit">{renewalLevelText} Renewal Extension Date :</label>   
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <input type="date" className="form-control form-control-xs" value={renewalExtensionDate}   min={oneMonthAfterRenewal}   name ='dojp' onChange={(e)=>setRenewalExtensionDate(e.target.value)}/>
                    </div>
                    <p style={{color:'red'}}>{formErrors.renewalExtensionDate}</p>
                </div>
                )}
                {isRenew &&(
                <div className="form-row">                 
                   <label htmlFor="accreditation_level">Do you wish to Renew the Verifier at {renewalLevelText} ?</label>
               </div>
                )}
                  
            </form>

            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            {isExtension===false && (
            <>
            <button className="btn btn-success mt-auto" type="button" onClick={isRepeat ? toggleForUpGradeModal : handleYes }>Yes</button>
            <button className="btn btn-secondary mt-auto" type="button" onClick={isRepeat ? toggleForConfirmModal : handleNo}>No</button>
            <button className="btn btn-secondary mt-auto" type="button" onClick={isRenew ? handleCancel : toggler1}>Cancel</button>
            </>
            )}
            {isExtension &&(
            <>
            <button className="btn btn-success mt-auto" type="button" onClick={saveExtension}>Save</button>
            <button className="btn btn-secondary mt-auto" type="button" onClick={handleCancel}>Cancel</button>
            </>
            )}
            </div>
        </div>

          {verifier.accreditationLevel === 'Level 4-4+'  && (
            renewalLevelText === 'Level 4-4+' ?
            <CommonModalWithImageAndButton modal={isConfirmModal} title='' toggler={toggleForConfirmModal} heading='' triggerMethodOnSaveButtonClick={downgradeVerifier} buttonText='Yes' isClicked = {isClicked}>
                <Label className="col-form-label">{'Are you sure that you DO NOT wish to Renew the Verifier?'} <br /> {'Please note that progressing with this action will Downgrade the Verifier meaning that they will have to go back through the Upgrade process as a 1-3+ Verifier if they want to become Approved at 4-4+ again.'}</Label>
            </CommonModalWithImageAndButton>
            :
            <CommonModalWithImageAndButton modal={isConfirmModal} title='' toggler={toggleForConfirmModal} heading='' triggerMethodOnSaveButtonClick={downgradeVerifier} buttonText='Yes' isClicked = {isClicked}>
            <Label className="col-form-label">{'Are you sure? Please note that progressing with this action will remove the Verifier and mean they have to go back through the Upgrade process as a Prospecting Verifier if they want to re-join the programme at a later date.'}</Label>
            </CommonModalWithImageAndButton>
          )}

          {verifier.accreditationLevel === 'Level 1-3+'  && (
            <CommonModalWithImageAndButton modal={isConfirmModal} title='' toggler={toggleForConfirmModal} heading='' triggerMethodOnSaveButtonClick={removedVerifier} buttonText='Yes' isClicked = {isClicked}>
                <Label className="col-form-label">{'Are you sure? Please note that progressing with this action will remove the Verifier and mean they have to go back through the Upgrade process as a Prospecting Verifier if they want to re-join the programme at a later date.'}</Label>
            </CommonModalWithImageAndButton>
          )}

          <CommonCustomModal modal={upGradeModal} title='' toggler={toggleForUpGradeModal} heading='Renew Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <RenewDetails verifier={verifier} refetchs={refetchs} setRefetchs={setRefetchs} toggler={toggler} isRenewalApplicableFor2Attempt={isRenewalApplicableFor2Attempt} toggler1={toggler1} toggler2={toggleForUpGradeModal} renewalLevel={renewalLevel} renewalReasonText={renewalReasonText} comments={comments}/>
          </CommonCustomModal>
          </>
    );
};

export default RenewVerifier;