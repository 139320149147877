import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const AddUser = ({ toggler,ReferenceID,ReferenceType, refetch, setRefetch}) => {

  
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [formErrors, setFormErrors] = useState({});
    const [userList, setUserList] = useState([]);
    const [airportUser, setAirportUser] = useState("");

    const getAllUserList = async () => {
  
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getAllUserList}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                const option = [ { text: 'Please select User...', value: '' },
                ...result.map((user) => ({
                text: user.name,
                value: user.id,
                }))
            ];
                setUserList(option);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAllUserList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }

        useEffect(()=>{
            getAllUserList();
        },[])    

    const addAirportUser = async () => {
      const requestBody = {
        "referenceID": ReferenceID,
        "referenceType": ReferenceType,
        "actionType": 'AddUser',
        "userID": airportUser,
        "loggedInUserID": userID
      };
      const validationErrors = validateForm(airportUser);
          setFormErrors(validationErrors);
          if(Object.keys(validationErrors).length === 0){
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.addRemoveUserWithAirport}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                    if (response.ok) {
                        var result = await response.json();
                        if(result.saveErrorMessage === 'User added.')
                        {
                            toast.success('User added successfully.', {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Slide
                            }); 
                        setRefetch(!refetch);
                        toggler();
                        }else{
                            setErrorMessage(result.saveErrorMessage);
                        }
                    } else {
                        const errorText = await response.text();
                        const apiname = `${apiconfig.addRemoveUserWithAirport}`;
                        navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                    }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
          }
  }

    {/*Validation*/}
    //Add User form validation
    const validateForm = (airportUser) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

      if(!airportUser){
        errors.airportUser = 'Please select a value.';
      } 

      return errors;
  }
      

    return (
        <div>
              <form>
              <div className="form-row">
                <div className="form-group col-md-8 mb-1">
                    <label htmlFor="iataCode">Select a user from the list to add to the project</label>
                </div>
              </div>
              <div className="form-row">
                  <div className="form-group col-md-8 mb-1">
                        <select id="airportGroup" className="form-control form-control-sm" value={airportUser}  onChange={(e) => { setAirportUser(e.target.value) }} >
                            {userList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.airportUser}</p>
                    </div>
              </div>
              {errorMessage ? (
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  ) : (
                    <span></span>
                  )}      
              </form>

              <div className="modal-footer">
              <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
              <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
              <button className="btn btn-success mt-auto" type="button" onClick={addAirportUser}>Submit</button>
              </div>
        </div>
    );
};

export default AddUser;