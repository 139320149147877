import React, { useState, useEffect } from "react";
import config from "../../../api/config";
import apiconfig from "../../../api/apiconfig";
import { useNavigate} from "react-router-dom";
import TabContainer from "../ExpandedListViewComponents/TabContainer";
import TabContent from "../ExpandedListViewComponents/TabContent";
import TabPanel from "../ExpandedListViewComponents/TabPanel";
import Loader from "../../Common/Loader";
import SponsorTabList from "../ExpandedListViewComponents/SponsorTabList";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";


const SponsorDetailsTab = ({ sponsorID, refetchs, setRefetchs }) => {

    const [eventList, setEventList] = useState([]);
    const navigate = useNavigate();
    const [verifiers, setVerifiers] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [activeTab, setActiveTab] = useState('Verifiers');
    const [loading, setLoading] = useState(true);
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    

    const getEventList = () => {
      fetch(`${config.apiUrl}${apiconfig.getSponsorEventsID}${sponsorID}`, {
        method: "GET",
        headers: {

            Authorization: `Bearer ${localStorage.getItem("token")}`

        }
      })
        .then((res) => res.json())
        .then((data) => {
          setEventList(data);
        })
        .catch((err) => {
          console.log("Error: ", err);
          navigate("/error-page");
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.getVerifierBySponsor}${sponsorID}`, {
                    method: 'GET',
                    headers: {

                        Authorization: `Bearer ${localStorage.getItem("token")}`

                    }
                }).then((response) => {
                    return response.json();
                })
                let fetchedVerifiers = [];
                for (let i = 0; i < response.length; i++) {
                    fetchedVerifiers.push(response[i]);
                }
                setVerifiers(fetchedVerifiers);
            }
            catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            } finally {
                setLoading(false);
            }
        };
        fetchData();

    }, [sponsorID]);

    useEffect(() => {

      getEventList();

    }, [refetch, refetchs]);


    return (
        <>
        {loading && <Loader />}
            <TabContainer>
                <SponsorTabList activeTab={activeTab} setActiveTab={setActiveTab} />  
                <TabContent>
                    <TabPanel activeTab={activeTab} tabName='Verifiers'>
                        <tr>
                        <th style={{ width: '20%' }}>Verifier Name</th>
                        <th style={{ width: '10%' }}>Verifier Status</th>
                        <th style={{ width: '15%' }}>Accreditation Level</th>
                        <th style={{ width: '15%' }}>Date Approved</th>
                        <th style={{ width: '10%' }}>Region</th>
                        <th style={{ width: '15%' }}>Country</th>
                        <th style={{ width: '15%' }}>Last Updated</th>
                        </tr> 
                        {
                            !verifiers.length  
                                ?
                                <tr className="alt-row">
                                    <td colSpan="9">&nbsp; Verifier does not exist</td>
                                </tr>
                                :
                                (verifiers.map((verifier, index) => (
                                    <tr>
                                        {isReadOnlyAccess1 === 'FullAccess' ?
                                        <td className="text-break"><a className="text-danger" href={`/viewverifier/${verifier.verifierDetailID}`}>{verifier.verifierName}</a></td>
                                        :
                                        <td>{verifier.verifierName}</td>
                                        }
                                        {
                                            verifier.verifierStatus === 'Removed'
                                                ?
                                                <td><span className="badge badge-danger">{verifier.verifierStatus}</span></td>
                                                :
                                                verifier.verifierStatus === 'Expired'
                                                    ?
                                                    <td><span className="badge badge-primary">{verifier.verifierStatus}</span></td>
                                                    :
                                                    <td><span className="badge badge-success">{verifier.verifierStatus}</span></td>
                                        }
                                        <td>{verifier.accreditationLevel}</td>
                                        <td>{verifier.dateApproved}</td>
                                        <td>{verifier.region}</td>
                                        <td>{verifier.country}</td>
                                        <td>{verifier.lastUpdatedDate}</td>
                                    </tr>
                                )))}
                    </TabPanel>
                    {isReadOnlyAccess1 === 'FullAccess' ?
                    <TabPanel activeTab={activeTab} tabName='Events'>
                        <tr>
                            <th style={{width:'40%'}}>Event Description</th>
                            <th style={{width:'20%'}}>Event Type</th>
                            <th style={{width:'20%'}}>Created By</th>
                            <th style={{width:'20%'}}>Date Created</th>
                        </tr>
                        {
                            eventList.map((event, index) => (
                                <tr key={index}>
                                    <td className="text-break">{event.description}</td>
                                    <td>{event.eventType}</td>
                                    <td>{event.createdUser}</td>
                                    <td>{event.createdDate}</td>
                                </tr>
                            ))
                        }
                    </TabPanel>
                    :
                    <></>
                    }
                </TabContent>
            </TabContainer>
        </>
    );
};

export default SponsorDetailsTab;