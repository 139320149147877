import { useState } from "react";
import HoverableProgressBar from "./Progressbar";
import CommonCustomModal from "../../Common/CommonCustomModal";
import RenewAccreditationApplication from "../Renew/RenewAccreditationApplication";
import Renew3YearApplication from "../Renew/Renew3YearApplication";




const DashboardApplicationList = ({ appList,isLoading,setIsLoading}) => {

  const [renewAccModal, setRenewAccModal] = useState(false);
  const [renewAccModalPopup, setRenewAccModalPopup] = useState(false);
  const [actionType, setActionType] = useState('');
console.log(appList)
  const toggleForRenewAccModal = (action) => {
    setRenewAccModal(!renewAccModal);
    if(action === 'Renew' || action === 'Upgrade'|| action === 'Downgrade'){
    setActionType(action);
    }

}
const toggleForRenewAccModalPopup = (action) => {
  setRenewAccModalPopup(!renewAccModalPopup);
  if(action === 'Renew' || action === 'Upgrade'|| action === 'Downgrade'){
    setActionType(action);
    }
  }

  return (
    <>
      <tr>
      <td>{appList.reportingPeriod}</td>
      <td>{appList.airportApplicationID}</td>
      <td>{appList.applicationType}</td>
      {
          appList.applicationStatus === 'Approved' 
          ? 
          <td><span className="badge badge-success">{appList.applicationStatus}</span></td>    
              :
          <td><span className="badge badge-warning">{appList.applicationStatus}</span></td> 
      }
      <td>
        <div className="media align-items-center">
          <span className="avtar text-purple-2 bg-purple-1 avtar-xs mr-3"><span
              className="mdi mdi-4x mdi-airplane"></span></span>
          <div className="media-body">
            <h6 className="mb-0 mt-0 font-weight-normal">{appList.airport}</h6>
          </div>
        </div>
      </td>
      <td>{appList.assignee}
        {/* <span className="avtar text-blue-2 bg-blue-1 avtar-xs mr-2" data-toggle="tooltip"
          data-placement="top" title={appList.assignee} data-original-title=""></span> */}
      </td>
      <td>{appList.accreditationLevel}</td>
      <td>{appList.accreditedDate}</td>
      <td>{appList.nextRenewalDueDate}</td>
      <td>{appList.year3Status}</td>
      <td>{appList.year3Year}</td>
      {/* <td>{appList.eligibleForThreeYearRenewal==='Yes - UD' && appList.applicationType==='Downgrade' && appList.accreditationLevel==='Level 3+ (Neutrality)'? 'Yes'
      :appList.eligibleForThreeYearRenewal==='Yes - UD' && appList.applicationType==='Upgrade' && appList.accreditationLevel==='Level 3 (Optimisation)'?'Yes'
      :appList.eligibleForThreeYearRenewal==='Yes - UD'?'No':appList.eligibleForThreeYearRenewal}</td> */}
    
      <td className="popover-item">
        <HoverableProgressBar OverAllPercentage={appList.overallProgress} FootprintPercentage={appList.footprintProgress} ManagementPercentage={appList.managementProgress} id={appList.airportApplicationID} />
      </td>
      <td className="text-right actions">
        <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
            <i className="mdi mdi-more"></i>
            <span className="sr-only">Menu</span>
        </a>
        <div className="dropdown-menu">
            {appList.applicationStatus === 'Draft' || appList.applicationStatus === 'Verifier comments - Airport to address' || appList.applicationStatus === 'Admin comments - Airport to address' ?
            <>
            <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${appList.airportApplicationID}/AirportID/${appList.airportID}`}><span className="icon mdi mdi-edit"></span>Edit</a>
            </>
            :
            <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${appList.airportApplicationID}/AirportID/${appList.airportID}`}><span className="icon mdi mdi-eye"></span>View</a>
            }
            {   (appList.isViewOnly===0 && appList.applicationStatus === 'Approved' && (appList.accreditationLevel==='Level 3 (Optimisation)' || appList.accreditationLevel==='Level 3+ (Neutrality)') && (appList.eligibleForThreeYearRenewal==='Yes' || appList.eligibleForThreeYearRenewal==='YesRU' || appList.eligibleForThreeYearRenewal==='YesRD')) ?
                <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => { toggleForRenewAccModalPopup('Renew'); }} ><span className="icon mdi mdi-refresh"></span>Renew</a>
                :
                (appList.isViewOnly===0 && appList.applicationStatus === 'Approved') ?
                <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => { toggleForRenewAccModal('Renew'); }} ><span className="icon mdi mdi-refresh"></span>Renew</a>
                :
                <></>
            }
            {   appList.isViewOnly===0 && appList.applicationStatus === 'Approved' &&(
               appList.accreditationLevel==='Level 3 (Optimisation)' && (appList.eligibleForThreeYearRenewal==='YesU' || appList.eligibleForThreeYearRenewal==='YesRU')?
               <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => { toggleForRenewAccModalPopup('Upgrade'); }} ><span className="icon mdi mdi-refresh"></span>Upgrade</a>
               :
               appList.accreditationLevel !=='Level 4+ (Transition)' ?
                <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => { toggleForRenewAccModal('Upgrade'); }} ><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                :
                <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Further upgrades are not allowed."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
            )}
            {
              appList.isViewOnly===0 && appList.applicationStatus === 'Approved' &&(  
                appList.accreditationLevel==='Level 3+ (Neutrality)' && (appList.eligibleForThreeYearRenewal==='YesD'|| appList.eligibleForThreeYearRenewal==='YesRD')?
                <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => { toggleForRenewAccModalPopup('Downgrade'); }} ><span className="icon mdi mdi-refresh"></span>Downgrade</a>
                :
              appList.accreditationLevel !=='Level 1 (Mapping)'    ?                                          
              <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRenewAccModal('Downgrade'); }} ><span className="icon mdi mdi-trending-down"></span>Downgrade</a> 
              :
              <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Further downgrades are not allowed."><span className="icon mdi mdi-trending-down"></span>Downgrade</a> 
            )}
        </div>
    </td>
    </tr>


    <CommonCustomModal modal={renewAccModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForRenewAccModal} heading = {actionType} buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
        <RenewAccreditationApplication airportApplicationID={appList.airportApplicationID} actionType={actionType} isLoading = {isLoading} setIsLoading={setIsLoading} />
    </CommonCustomModal>
    <CommonCustomModal modal={renewAccModalPopup} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForRenewAccModalPopup} heading = {actionType} buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
        <Renew3YearApplication airportApplicationID={appList.airportApplicationID} actionType={actionType}  isLoading = {isLoading} setIsLoading={setIsLoading} setActionType={setActionType} />
    </CommonCustomModal>
    </>
  );
};

export default DashboardApplicationList;
