import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import axios from '../../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import CommentList from './CommunicationList';

const AddCommunication = ({ toggler, verifier ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [comments, setcomments] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [isSentMail, setIsSentMail] = useState(true);
    const userRoles = JSON.parse(localStorage.getItem("roles"));
    const isExternalVerifierUser = userRoles.length === 1 && userRoles[0].name === "Verifier User";


    {/*Validation*/}
    const validateForm = (comments) => {
        const errors = {};
        if(!comments){
            errors.comments = 'Please enter the description.';
        }else if(comments.length > 2500){
            errors.comments = 'Maximum of 2500 characters allowed.';
        }
        return errors;
    }

    const createCommunication = async () => {
        const requestBody = {
            id : 0,
            verifierID : verifier.verifierDetailID,
            reference : '',
            description : comments.trim(),
            userID : userID,
            isSentMail : isSentMail === true ? 1 : 0,
            roleName : isExternalVerifierUser === true ? "Verifier User" : ''


        };
        const validationErrors = validateForm(comments.trim());
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.addVerifierCommunication}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Communication saved successfully.')
            {
                toast.success('Communication saved successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

    return (
        <div>
    <form>
                    <div className="form-row">
                        <label htmlFor="Description">Description</label>
                        <textarea id="Description" type="text" placeholder='Please enter the description...' className="form-control form-control-xs" onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
                    <div className="form-row mt-2">
                    <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox" id="isPrimary" defaultChecked onChange={(e) => { setIsSentMail(e.target.checked) }}/>
                        <label className="custom-control-label" htmlFor="isPrimary">Send Mail</label>
                    </div>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt="ACA Logo"></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={createCommunication}>Submit</button>
            </div>
            </div>
    );
};

export default AddCommunication;