import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import Email from '../Emails/Email'
import { TableHeaderCells } from '../Emails/TableHeaderCells'
import axios from '../../../api/AxiosConfig'
import CommonModal from '../../Common/CommonModal'
import SearchBar from '../../Common/SearchBar'
import Card from '../../Common/Card'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import Table from '../../Common/Table'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { AiOutlineUserAdd } from "react-icons/ai"
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import SortArray from "../../Helper/SortArray"
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { Slide, ToastContainer, toast } from 'react-toastify'
import AdvancedSearch from '../../Common/AdvancedSearch'
import CustomTable from '../../Common/CustomTable'
import { useNavigate } from "react-router-dom"
import Loader from '../../Common/Loader'

const Emails = () => {

    /* States & Constants */
    const [searchName, setSearchName] = useState('')
    const [users, setUsers] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState("Desc")
    const [sortColumn, setSortColumn] = useState('')
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const [selectedOption, setSelectedOption] = useState("")
    const [searchFilter, setSearchFilter] = useState('Email Type')
    const [searchType, setSearchType] = useState('dropdown')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('default')
    const [dropdownOptions, setDropdownOptions] = useState([                
        {text: 'Accreditation', value: 'Accreditation'},
        {text: 'General', value: 'General'},
        {text: 'Verifier', value: 'Verifier'},
    ])
    const [sortByColumnName, setSortByColumnName] = useState('SentDate')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const navigate = useNavigate()
    const searchOptions = [
        {
            value: 'Email Type', 
            text: 'Email Type', 
            type: 'dropdown',
            query: '&EmailType=',
            dropdownOptions:[
                {text: 'Accreditation', value: 'Accreditation'},
                {text: 'General', value: 'General'},
                {text: 'Verifier', value: 'Verifier'},
            ],
        },
        {
            value: 'Sent Date', 
            text: 'Sent Date', 
            type: 'date',
            query: '&SentDate='
        },
        {
            value: 'Email Recipient(s)', 
            text: 'Email Recipient(s)', 
            type: 'text',
            query: '&Recipient='
        },
        {
            value: 'Email Subject', 
            text: 'Email Subject', 
            type: 'text',
            query: '&Subject='
        },
        {
            value: 'Email Sent Status', 
            text: 'Email Sent Status', 
            type: 'text',
            query: '&Status='
        },

    ]
    const [isLoading, setIsLoading] = useState(false)

    /* Methods */
    const fetchData = async () => {
        try 
        {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            setIsLoading(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getEmailDeliveredList}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                const result = await response.json()
                let fetchedusers = []
                for (let i = 0; i < result.length; i++) {
                    fetchedusers.push(result[i])
                }
                setUsers(fetchedusers)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getUsers}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoading(false)
        }
        catch (error) {
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria])
    useEffect(() => {
        if(users.length > 0){
            setPageCount(Math.ceil(users[0].totalCount / itemsPerPage))
        }
    }, [users])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(users.length !== 0){
            if(users[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])
    

    return (
        <Layout>
            {
                isLoading
                    ?
                    <Loader />
                    :
                    <React.Fragment></React.Fragment>  
            }
            <div className="main-content container-fluid p-0">
                <div className="page-header blue mb-4">
                    <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">Automated Email Log</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">
                               
                            </div>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb mb-0 mt-4">
                                <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                <li className="breadcrumb-item active">Automated Email Log</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="container-header">
                        <h3 className="page-header-title">Search Emails</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            <p className="mb-2 font-weight-normal">
                            
                                   Use the filter categories and search field below to find emails, expand the preview to see more information.
                                </p>
                            <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                            {
                                users.length > 0
                                    ?
                                    <div className="align-self-center font-weight-bold">{users[0].totalCount} items found</div>
                                    :
                                    <div className="align-self-center font-weight-bold">No items found</div>
                            }
                        </div>
                        <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                            <nav>
                                <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                            </nav>
                        </div>
                    </div>
                    <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                        {users.map(user => <Email key={user.id} user={user} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                    </CustomTable>
                    <div className="d-flex justify-content-end">
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Emails