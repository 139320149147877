import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import AdvancedSearch from '../../Common/AdvancedSearchByID'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import FeeDetail from './FeeDetail'
import {ToastContainer} from 'react-toastify'
import { TableHeaderCells } from './TableHeaderCells'
import Loader from '../../Common/Loader'

const FeeDetails = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [feeList, setFeeList] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [sortColumn, setSortColumn] = useState('')
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('Currency')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Currency')
    const [searchType, setSearchType] = useState('dropdownbyID')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('default')
    const [searchText, setSearchText] = useState('')
    const [currencyList, setCurrencyList] = useState([])
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [typeList, setTypeList] = useState([])
    const [levelList, setLevelList] = useState([])
    const [bandList, setBandList] = useState([])
    const [threeYearlyList, setThreeYearlyList] = useState([])
    const [generalAviationList, setGeneralAviationList] = useState([])
    const [groupFeeList, setGroupFeeList] = useState([])
    const [allfeeList, setAllFeeList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const searchOptions = [
        {
            value: 'Currency', 
            text: 'Currency',
            type: 'dropdownbyID',
            query: '&CurrencyID=',
            dropdownOptions: currencyList
        },
        {
            value: 'Type Name', 
            text: 'Type Name', 
            type: 'dropdownbyID',
            query: '&ApplicationTypeID=',
            dropdownOptions: typeList
        },
        {
            value: 'Level Name',
             text: 'Level Name', 
            type: 'dropdownbyID',
            query: '&LevelID=',
            dropdownOptions: levelList
        },
        {
            value: 'Band Name', 
            text: 'Band Name', 
            type: 'dropdownbyID',
            query: '&BandID=',
            dropdownOptions: bandList
        },
        {
            value: 'Three Yearly Year', 
            text: 'Three Yearly Year', 
            type: 'dropdownbyID',
            query: '&ThreeYearlyID=',
            dropdownOptions: threeYearlyList
        },
        {
            value: 'General Aviation', 
            text: 'General Aviation', 
            type: 'dropdownbyID',
            query: '&AviationTypeID=',
            dropdownOptions: generalAviationList
        },   
        {
            value: 'Group Fee',
             text: 'Group Fee', 
            type: 'dropdownbyID',
            query: '&GroupDiscountID=',
            dropdownOptions: groupFeeList
        },
        {
            value: 'Certification Fee',
             text: 'Certification Fee', 
            type: 'dropdownbyID',
            query: '&IsCertification=',
            dropdownOptions: [
                {text: 'No', value: 0},
                {text: 'Yes', value: 1},
            ]
        },
        {
            value: 'Fee',
             text: 'Fee', 
            type: 'dropdownbyID',
            query: '&Fee=',
            dropdownOptions: allfeeList
        },
    ]

    /* Methods */
    const fetchData = async () => {
        try {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            console.log('executing getAirportFeeList()...')
            setIsLoading(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportFeeList}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getAirportFeeList()')
                const result = await response.json()
                let fetchedSponsors = []
                for (let i = 0; i < result.length; i++) {
                    fetchedSponsors.push(result[i])
                }
                setFeeList(fetchedSponsors)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAirportFeeList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoading(false)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria])
    useEffect(() => {
        if(feeList.length > 0){
            setPageCount(Math.ceil(feeList[0].totalCount / itemsPerPage))
        }
    }, [feeList])
    useEffect(() => {
        const getLevelList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getLevelList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setLevelList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getLevelList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getLevelList()
    }, [refetch])
    useEffect(() => {
        const getCurrencyList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getCurrencyList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setCurrencyList(result)
                    setDropdownOptions(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getCurrencyList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getCurrencyList()
    }, [refetch])
    useEffect(() => {
        const getTypeList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getTypeList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setTypeList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getTypeList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getTypeList()
    }, [refetch])
    useEffect(() => {
        const getBandList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getBandList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setBandList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getBandList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getBandList()
    }, [refetch])
    useEffect(() => {
        const getThreeYearlyYearList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getThreeYearlyYearList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setThreeYearlyList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getThreeYearlyYearList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getThreeYearlyYearList()
    }, [refetch])
    useEffect(() => {
        const getGeneralAviationList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getGeneralAviationList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setGeneralAviationList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getGeneralAviationList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getGeneralAviationList()
    }, [refetch])
    useEffect(() => {
        const getGroupFeeList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getGroupFeeList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setGroupFeeList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getGroupFeeList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getGroupFeeList()
    }, [refetch])
    useEffect(() => {
        const getFeeList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getFeeList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setAllFeeList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getFeeList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getFeeList()
    }, [refetch])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(feeList.length !== 0){
            if(feeList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])

    
    return (
        <Layout>
            {
                isLoading
                ?
                <Loader />
                :
                <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-lightgreen mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Fee Details</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                    {/* <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddSponsorModal(); }}>Sponsors <i className="icon icon-left mdi mdi-plus"></i></button> */}
                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                    <li className="breadcrumb-item active">Fee Details</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                   
                    <div className="container-header">
                        <h3 className="page-header-title">Search Fee Details</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    {/* <strong>Search</strong>
                                    <br /> */}
                                    Use the filter categories and search field below to find fee, expand the preview to see more information.
                                </p>
                                <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} searchText={searchText} setSearchText={setSearchText} setPageNumber={setPageNumber} />
                                {
                                    feeList.length > 0
                                        ?
                                        <div className="align-self-center font-weight-bold">{feeList[0].totalCount} items found</div>
                                        :
                                        <div className="align-self-center font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                        <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                        <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                                    </div> */}
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                        <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                            {feeList.map(fee => <FeeDetail key={feeList.indexOf(fee)} fee={fee} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                        </CustomTable>
                        <div className="d-flex justify-content-end">
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </div>
                        </div>
                    </div>
            </div>
            <ToastContainer/>
        </Layout>
    )
}

export default FeeDetails