import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Components/Home/Home'
import Users from './Components/Admin/Users/Users'
import Verifiers from './Components/Verifiers/Verifiers'
import Permissions from '../src/Components/Admin/Permissions/Permissions'
import UsersEvents from '../src/Components/Admin/UserEvent/UsersEvents'
import Login from './Components/Login/Login'
import Roles from '../src/Components/Admin/Roles/Roles'
import { RequireAuth } from 'react-auth-kit'
import UsersCategory from '../src/Components/Admin/UserCategory/UsersCategory'
import APIError from "./Components/Error/ApiNotWorking"
import AcceptanceUse from '../src/Components/Login/AcceptanceUse'
import PasswordReset from '../src/Components/Admin/Users/PasswordReset'
import ChangePassword from '../src/Components/Admin/Users/ChangePassword'
import AuthChecker from './Components/Error/AuthChecker'
import UnAuthorizedUser from './Components/Error/UnAuthorizedUser'
import Sponsors from './Components/Verifiers/Sponsors/Sponsors'
import ViewVerifier from './Components/Verifiers/ViewVerifier'
import ViewSponsor from './Components/Verifiers/Sponsors/ViewSponsor'
import ViewExam from './Components/Verifiers/Exam/ViewExam'
import Exams from './Components/Verifiers/Exam/Exams'
import Invoices from './Components/Verifiers/Invoice/Invoices'
import ViewInvoice from './Components/Verifiers/Invoice/ViewInvoice'
import VerifierExamFees from './Components/Verifiers/VerifierExamFee/VerifierExamFees'
import ViewVerifierExamFee from './Components/Verifiers/VerifierExamFee/ViewVerifierExamFee'
import ProgrammeDocuments from './Components/Verifiers/GuidanceDocument'
import FeeDetails from './Components/Airport/FeeDetail/FeeDetails'
import ReportingPeriods from './Components/Airport/ReportingPeriod/ReportingPeriods'
import AirportGroups from './Components//Airport/AirportGroup/AirportGroups'
import Airports from './Components/Airport/ManageAirport/Airports'
import AccreditationAirportRegistration from './Components/Accreditation/Register/AccreditationAirportRegistration'
import AccreditationDashboard from './Components/Accreditation/Register/AccreditationDashboard'
import ViewAccreditationAirportDetails from './Components/Accreditation/Register/ViewAccreditationAirportDetails'
import ApplicationStage from './Components/Accreditation/Accreditation Process/ApplicationStage'
import AuthCheckerAirport from './Components/Error/AuthCheckerAirport'
import CustomAuthChecker from './Components/Error/CustomAuthChecker'
import AirportGroupDashboard from './Components/Accreditation/Register/AirportGroupDashboard'
import AirportSingleDashboard from './Components/Accreditation/Register/AirportSingleDashboard'
import VerifierRegistration from './Components/Verifiers/Register/VerfierRegistration'
import VerifierDashboard from './Components/Home/VerifierDashboard'
import AddAirportInvoice from './Components/Accreditation/ManageAccount/AddAirportInvoice'
import SessionTimeOut from './Components/Error/SessionTimeOut'
import AirportUsers from './Components/Accreditation/ManageAccount/AirportUsers'
import NotificationDetails from './Components/Notification/NotificationDetails'
import RoleDetails from './Components/Admin/Roles/RoleDetails'
import UserDetails from './Components/Admin/Users/UserDetails'
import HistoricalApplicationLists from './Components/Accreditation/ApplicationHistoricalList/HistoricalApplicationLists'
import AirportDetails from './Components/Airport/ManageAirport/AirportDetails'
import AirportGroupDetail from './Components/Airport/AirportGroup/AirportGroupDetail'
import Emails from '../src/Components/Admin/Emails/Emails'
import AirportUser from './Components/Airport/AirportUser/AirportUsers'

const RouteList = () => {
    return (
        <Routes>
            <Route 
                path="/login" 
                element={
                    <Login />
                } 
            />
            <Route
                path="/"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Verifier Coordinator","Account Manager", "Senior Account Manager", "ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <Home />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            {/* <Route
                path="/users"
                element={
                    <RequireAuth loginPath="/login">
                        <Users />
                    </RequireAuth>
                }
            /> */}
            <Route
                path="/verifiers"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Verifier Coordinator", "ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <Verifiers />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/usersCategory"
                element={
                    <RequireAuth loginPath="/login">
                        <UsersCategory />
                    </RequireAuth>
                }
            />
            <Route
                path="/usersEvents"
                element={
                    <RequireAuth loginPath="/login">
                        <UsersEvents />
                    </RequireAuth>
                }
            />
            <Route
                path="/error-page/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <APIError />
                    </RequireAuth>
                }
            />
            <Route
                path="/error-page"
                element={
                    <RequireAuth loginPath="/login">
                        <APIError />
                    </RequireAuth>
                }
            />
            <Route
                path="/TermsAndConditionsAcceptableUsePolicy"
                element={ 
                    <AcceptanceUse />
                }
            />
            <Route
                path="/PasswordReset"
                element={ 
                    <PasswordReset />
                }
            />
            <Route
                path="/ChangePassword"
                element={ 
                    <RequireAuth loginPath="/login">
                    <ChangePassword />
                    </RequireAuth>
                }
            />
            <Route 
                path="/RoleDetails/:roleid" 
                element={ 
                <RequireAuth loginPath="/login">
                <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager", "Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                    <RoleDetails />
                </AuthChecker>
                </RequireAuth>
                }                               
                />  
            <Route 
                path="/UserDetails/:id" 
                element={
                <RequireAuth loginPath="/login">
                <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager", "Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                    <UserDetails />
                </AuthChecker>
                </RequireAuth>
                }
                />                              
            <Route
                path="/users"
                element={
                    <RequireAuth loginPath="/login">
                    <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager", "Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                        <Users />
                    </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/roles"
                element={
                    <RequireAuth loginPath="/login">
                    <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager", "Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                        <Roles />
                    </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/permissions"
                element={
                    <RequireAuth loginPath="/login">
                    <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager", "Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                        <Permissions />
                    </AuthChecker>
                    </RequireAuth>
                }
            />
              <Route
                path="/emails"
                element={
                    <RequireAuth loginPath="/login">
                    <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager", "Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                        <Emails />
                    </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route 
                path="/UnAuthorizedUser" 
                element={
                    <UnAuthorizedUser />
                } 
            />
            <Route
                path="/sponsors"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                            <Sponsors />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/viewverifier/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator", "Verifier User","ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <ViewVerifier />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
             <Route
                path="/viewsponsor/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator"]} redirectPath="/UnAuthorizedUser">
                            <ViewSponsor />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/viewexam/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator"]} redirectPath="/UnAuthorizedUser">
                            <ViewExam />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
             <Route
                path="/exams"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator"]} redirectPath="/UnAuthorizedUser">
                            <Exams />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/invoices"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator"]} redirectPath="/UnAuthorizedUser">
                            <Invoices />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/viewinvoice/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator"]} redirectPath="/UnAuthorizedUser">
                            <ViewInvoice />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/verifierexamfees"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator"]} redirectPath="/UnAuthorizedUser">
                            <VerifierExamFees />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/viewverifierexamfee"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator"]} redirectPath="/UnAuthorizedUser">
                            <ViewVerifierExamFee />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/programmeDocuments"
                element={
                    <RequireAuth loginPath="/login">
                        <ProgrammeDocuments />
                    </RequireAuth>
                }
            />
            <Route 
                path="/ReportingPeriod" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager"]} redirectPath="/UnAuthorizedUser">
                            <ReportingPeriods />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route 
                path="/AirportGroups" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager","ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <AirportGroups />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route 
                path="/FeeDetails" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager"]} redirectPath="/UnAuthorizedUser">
                            <FeeDetails />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route 
                path="/Airport" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager","ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <Airports />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route 
                path="/AccreditationAirportRegistration" 
                element={
                    <AccreditationAirportRegistration />
                }
            />
            <Route 
                path="/AccreditationDashboard" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager", "ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <AccreditationDashboard />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/ViewAccreditationAirportDetails/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager"]} redirectPath="/UnAuthorizedUser">
                            <ViewAccreditationAirportDetails />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route 
               path="/ApplicationStage/ApplicationID/:applicationID/AirportID/:airportID" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Verifier Coordinator", "Administrator", "Airport User","Verifier User","Account Manager", "Senior Account Manager"]} redirectPath="/UnAuthorizedUser">
                            <ApplicationStage />
                        </AuthChecker>
                    </RequireAuth>
                }
            /> 
            <Route 
                path="/AirportGroupDashboard" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthCheckerAirport allowedRoles={["Airport User"]} redirectPath="/UnAuthorizedUser">
                            <AirportGroupDashboard />
                        </AuthCheckerAirport>
                    </RequireAuth>
                }
            />
            <Route 
                path="/AirportSingleDashboard" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthCheckerAirport allowedRoles={["Airport User"]} redirectPath="/UnAuthorizedUser">
                            <AirportSingleDashboard />
                        </AuthCheckerAirport>
                    </RequireAuth>
                }
            />
            <Route 
                path="/AirportUserHistoricalApplication/:airportID" 
                element={
                    <RequireAuth loginPath="/login">
                        <AuthCheckerAirport allowedRoles={["Airport User"]} redirectPath="/UnAuthorizedUser">
                            <HistoricalApplicationLists />
                        </AuthCheckerAirport>
                    </RequireAuth>
                }
            />
            <Route
                path="/VerifierRegistration"
                element={ 
                    <VerifierRegistration /> 
                }
            />
            <Route 
                path="/VerifierDashboard" 
                element={
                    <RequireAuth loginPath="/login">
                        <CustomAuthChecker allowedRoles={["Verifier User"]} exemptedRoles={["Verifier Coordinator", "Administrator", "Airport User"]} redirectPath="/UnAuthorizedUser">
                            <VerifierDashboard />
                        </CustomAuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/AddAirportInvoice"
                element={ 
                    <RequireAuth loginPath="/login">
                    <CustomAuthChecker allowedRoles={["Airport User","Administrator"]} exemptedRoles={["Account Manager", "Client User", "Verifier User", "Verifier Coordinator"]} redirectPath="/UnAuthorizedUser">
                    <AddAirportInvoice /> 
                    </CustomAuthChecker>
                </RequireAuth>
                }
            />
            <Route
                path="/SessionTimeOut"
                element={ 
                    <SessionTimeOut /> 
                }
            />
            <Route
                path="/AirportUsers"
                element={ 
                    <RequireAuth loginPath="/login">
                    <CustomAuthChecker allowedRoles={["Airport User"]} exemptedRoles={["Account Manager", "Client User", "Verifier User", "Verifier Coordinator","Administrator"]} redirectPath="/UnAuthorizedUser">
                    <AirportUsers /> 
                    </CustomAuthChecker>
                </RequireAuth>
                }
                
            />
            <Route
                path="/NotificationDetails"
                element={ 
                    <RequireAuth loginPath="/login">
                    <NotificationDetails /> 
                    </RequireAuth>
                }
            />
            <Route
                path="/AirportDetails/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager","Airport User","ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <AirportDetails />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
                        <Route
                path="/AirportGroupDetail/:id"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager","Airport User","ACI","ACI-E"]} redirectPath="/UnAuthorizedUser">
                            <AirportGroupDetail />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
            <Route
                path="/AirportUser/RefID/:refID/RefType/:refType"
                element={
                    <RequireAuth loginPath="/login">
                        <AuthChecker allowedRoles={["Administrator","Account Manager", "Senior Account Manager"]} redirectPath="/UnAuthorizedUser">
                            <AirportUser />
                        </AuthChecker>
                    </RequireAuth>
                }
            />
        </Routes>
    )
}

export default RouteList