import { useState } from "react"
import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom";

const DashboardAirportList = ({ airportList}) => {

    const userID = localStorage.getItem("userID")
    const navigate = useNavigate()

    const handleRedirectToHistoricalAppList = () => {
        localStorage.setItem("AirportNameOnHistoricalList", airportList.airportName);
        window.location.href = `/AirportUserHistoricalApplication/${airportList.airportID}`;
      };
 
    return (
        <tr>
            <td>
                <div className="media align-items-center">
                    <span className="avtar text-purple-2 bg-purple-1 avtar-xs mr-3">
                        <span className="mdi mdi-4x mdi-airplane"></span>
                    </span>
                    <div className="media-body">
                        <a className="text-info" style={{cursor:'pointer'}} href={`/AirportDetails/${airportList.airportID}`}>{airportList.airportName}</a> 
                    </div>
                </div>
            </td>
            <td><a className="text-info" style={{cursor:'pointer'}} onClick={handleRedirectToHistoricalAppList}>View</a></td>
            <td>{airportList.airportIataCode}</td>
            <td>
            {airportList.applicationStatus === 'In Progress' && airportList.leftProgramme===0 ?  
           
                <a className="btn btn-space btn-primary" href={`/ApplicationStage/ApplicationID/${airportList.id}/AirportID/${airportList.airportID}`}> <span className="icon mdi mdi-play-circle-outline"></span> Start Application</a>
                :
            airportList.applicationStatus === 'In Progress' && airportList.leftProgramme===1 ?  

                <a className="btn btn-space btn-primary disabled"   title="Airport User to contact Admin to resolve." > <span className="icon mdi mdi-play-circle-outline"></span> Start Application</a>
                :
                <></>
            }  
            {/* <a className="btn btn-space btn-primary" href="/ApplicationStage"><span className="icon mdi mdi-play-circle-outline"></span> Start Application</a> */}
            </td>
        </tr>
    )
}

export default DashboardAirportList