
const apiconfig = {
    loginUrl: '/api/token',

    getUsers: '/user/GetAllUser',
    updateUser: '/user/UpdateUserDetails',
    archiveUser: '/user/ArchiveUser',
    activateUser: '/user/ActivateUser',
    deleteUser: '/user/InactiveUsers',
    createUser: '/user/CreateUser',

    getRoleList: '/role/GetAllRole',
    createRole: '/role/CreateRole',
    updateRole: '/Role/UpdateRole',
    getUsersEvents: '/user/GetUserEventList',
    getRoleListByUser: '/Role/GetRoleListByUser?UserID=',
    //User Category
    getUserCategory: '/userCategory/GetAllUserCategory',
    createUserCategory: '/userCategory/CreateUserCategory',
    getPermissions: '/Permission/GetAllPermission',
    createPermission: '/Permission/CreatePermission',
    getUserEvents: '/user/GetUserEventByUser?UserID=',
    changeUserPassword: '/user/ChangePassword',
    resetUserPassword: '/user/ResetPassword',
    getUserList: '/User/GetSearchedUsers?SearchTerm=',
    getUserDetailsByID: '/User/GetUserDetailByID?ID=',
    getUserListFromCsvFile: '/User/GetUserListFromCsvFile',
    createBulkUsers: '/User/CreateBulkUsers',
    getPermissionListByRole: '/Permission/GetPermissionListByRole?RoleID=',
    getAllPermission: '/Permission/GetAllPermission',
    updatePermission: '/Role/UpdatePermission',
    
    acceptableUsePolicy: '/User/GetAcceptableUsePolicy',
    editUserRoles: '/Role/UpdateUserRole',

    //Verifiers
    getVerifiers: '/Verifiers/GetVerifierList',
    getCountry: '/Verifiers/GetCountryList?RegionID=',
    getCountryList: '/Verifiers/GetCountryList?RegionID=',
    getRegionList: '/Verifiers/GetRegionList?CountryID=',
    getCountryL: '/Verifiers/GetCountryList',
    getRegion: '/Verifiers/GetRegionList',
    getVerifierStatus:'/Verifiers/GetVerifierStatus',
    addVerifier:'/Verifiers/AddVerifier',
    editVerifier:'/Verifiers/EditVerifier',
    getAccreditationLevelList: '/Verifiers/GetAccreditationLevelList',
    upGrade:'/Verifiers/UpGrade',
    getVerifierDetailsByID : '/Verifiers/Details?VerifierDetailID=',
    getAllSponsor: '/Verifiers/GetAllSponsor',
    getConfirmationText: '/Verifiers/GetMasterNarration?Type=',
    addExternalVerifier: '/Verifiers/AddExternalVerifier',
    IsRenewalApplicable : '/Verifiers/GetUpgradeDetails?VerifierID=',

    //Sponsors
    getSponsors: '/Verifiers/GetSponsorList',
    addSponsor:'/Verifiers/AddSponsor',
    editSponsor:'/Verifiers/EditSponsor',
    getSponsorDetailsByID : '/Verifiers/SponsorDetails?SponsorID=',
    getVerifierEventsID : '/Verifiers/GetAllVerifierEvents?VerifierID=',
    archiveUnarchiveSponsor : '/Verifiers/ArchiveUnarchiveSponsor',
    addSponsortoVerifier : '/Verifiers/AddSponsorToVerifier',
    removeSponsorFromVerifier : '/Verifiers/ArchiveUnarchiveSponsor',
    getSponsorByVerifier : '/Verifiers/GetSponsorListByVerifierID?VeriferID=',
    getVerifierBySponsor : '/Verifiers/GetVerifierListBySponsorID?SponsorID=',
    removeSponsor : '/Verifiers/RemoveSponsorToVerifier',
    getSponsorEventsID : '/Verifiers/GetSponsorEvents?SponsorID=',

    //Notes
    getVerifierNotesList : '/Verifiers/GetVerifierNotesList?VerifierID=',
    addVerifierNotes : '/Verifiers/AddVerifierNotes',
    editVerifierNotes : '/Verifiers/EditVerifierNotes',
    getVerifierNotesByID : '/Verifiers/GetVerifierNotes?ID=',
    deleteNotes : '/Verifiers/DeleteVerifierNotes',

    //Documents
    getDocumentTypes : '/Verifiers/GetDocumentTypes',
    getDocumentList : '/Verifiers/GetDocumentsList?VerifierID=',
    addDocument : '/Verifiers/AddDocuments',
    editDocument : '/Verifiers/EditDocuments',
    getDocumentsByID : '/Verifiers/GetDocuments?ID=',
    deleteDocument : '/Verifiers/DeleteDocuments',

    updateVerifierStatus : '/Verifiers/UpdateVerifierStatus',
    getExamTypes : '/Verifiers/GetExamTypes?ID=',
    upgradeVerifier : '/Verifiers/UpgradeVerifier',
    getVerifierExamList : '/Verifiers/GetExamList',
    getVerifierInvoiceList : '/Verifiers/GetInvoiceList',

    getInvoiceStatus : '/Verifiers/GetInvoiceStatus',
    getExamStatus : '/Verifiers/GetExamStatus',
    getInvoiceDetailsByID : '/Verifiers/GetInvoiceDetailByID?InvoiceID=',
    getExamDetailsByID : '/Verifiers/GetExamDetailByID?ExamID=',
    updateVerifierInvoice : '/Verifiers/UpdateInvoice',
    updateVerifierExam : '/Verifiers/UpdateExam',
    deleteExamInvoice : '/Verifiers/DeleteVerifierInvoiceExam',

    getreasonCode : '/Verifiers/GetReasonCode',
    getUpgradeDetails : '/Verifiers/GetUpgradeDetails?VerifierID=',
    getVCUsers : '/Verifiers/GetVCUsers',
    assignRemoveVC : '/Verifiers/AssignRemoveVC',
    getApprovedVerifier : '/Verifiers/GetApprovedVerifiers?UserID=',


    getGuidanceDocumentList : '/Dashboard/GetGuidenceDocumentList?UserID=',
    saveRenewalExtension : '/Verifiers/SaveRenewalExtension',
    downgradeVerifier : '/Verifiers/DowngradeVerifier',

    //Exams
    getExams: '/Verifiers/GetVExamList',
    getExamDetailsExamByID : '/Verifiers/GetExamDetails?ExamID=',

    //Invoices
    getInvoices: '/Verifiers/GetVInvoiceList',
    getInvoiceDetailsInvoiceByID : '/Verifiers/GetInvoiceDetails?InvoiceID=',

    //Fees
    getFees: '/Verifiers/GetVerifierFeeList',
    getVerifierAmountFeeList:'/Verifiers/GetVerifierFeeAmountList',


    addRemovePrimarySponsor : '/Verifiers/AddRemovePrimarySponsor',


    // Airport 
    getAirportFeeList: '/ParticipationRegister/GetFeeList',

    getCurrencyList:'/ParticipationRegister/GetCurrencyList',
    getTypeList:'/ParticipationRegister/GetTypeList',
    getLevelList:'/ParticipationRegister/GetLevelList',
    getBandList:'/ParticipationRegister/GetBandList',
    getThreeYearlyYearList:'/ParticipationRegister/GetThreeYearlyYearList',
    getGeneralAviationList:'/ParticipationRegister/GetGeneralAviationList',
    getGroupFeeList:'/ParticipationRegister/GetGroupDiscountList',
    getFeeList:'/ParticipationRegister/GetAllFeeList',

    getReportingPeriodList:'/ParticipationRegister/GetReportingPeriodList',
    getAirportGroupList:'/Airport/GetAirportGroupList',
    getIataCode:'/ParticipationRegister/GetIataCode',
    addAirportGroup:'/Airport/AddEditAirportGroup',
    editAirportGroup:'/Airport/AddEditAirportGroup',
    getAirportGroupDetails : '/Airport/GetAirportGroupDetails?ID=',

    getAirportListAirportGroup : '/Airport/GetLinkedAirportList?AirportGroupID=',
    getAirportDetailsByIata : '/Airport/GetLinkedAirportDetails?AirportID=',
    getAirportApplicationList : '/Airport/GetAirportApplicationLists?AirportID=',
    getAirportApplicationUserList : '/Airport/GetAirportUserLists?AirportID=',
    getAirportEventList : '/Airport/GetAirportEventLists?ReferenceID=',

    addAirport:'/Airport/AddAirportWithAirportGroup',
    removeAirport:'/Airport/DeleteAirportGroup',
    getAirportList:'/Airport/GetAirportList',
    getCityList: '/Airport/GetCityList',
    getCityListByCountry: '/Airport/GetCityList?CountryID=',
    getAirportGpList: '/Airport/GetAirportGroups?SearchTerm=',
    getAllUserList:'/Airport/GetAllUserLists',
    addRemoveUserWithAirport:'/Airport/AddRemoveUserWithAirport',

    addAirportInformation:'/Airport/AddEditAirport',
    getAviationList: '/ParticipationRegister/GetGeneralAviationList',
    getAirportDetailsByID : '/Airport/GetAirportDetails?AirportID=',
    getAirportByRegion: '/Accreditation/GetAirportList?RegionID=',
    getAirportByCity: '/Accreditation/GetAirportList?CityID=',
    getIataCodeByAirport:'/ParticipationRegister/GetIataCode?AirportID=',

    addAccreditationAirport:'/Accreditation/AddSingleGroupAirportApplication',
    accreditationAirportDashBoardData:'/Accreditation/GetAirportAccreditationDashboardData',
    getAccreditationAirportList:'/Accreditation/GetAirportAccreditationRegistrationList',
    getSAccreditationAirportDetailsByID : '/Accreditation/GetAirportAccreditationApplicationDetail?AirportApplicationID=',
    approveRejectAirport:'/Accreditation/ApproveRejectAirportApplication',
    getAirportUserApplicationList:'/Accreditation/GetAirportUserApplicationList',
    getAirportGroupApplicationList:'/Accreditation/GetAirportGroupApplicationList?LoggedInUserID=',
    getAccreditationApplicationList:'/Accreditation/GetAirportAccreditationApplicationList',
    getApplicationTypeList:'/Accreditation/GetApplicationTypeList',
    getApplicationLevelList:'/Accreditation/GetApplicationLevelList',
    getAccreditationViewAirportGroupList:'/Accreditation/GetAccreditationGroupAirportList?ApplicationID=',
    getRegionReferenceUrlList: '/Accreditation/GetRegionReferenceUrlList?RegionID=',
    checkAirportAlreadyExists: '/Accreditation/CheckAirportAlreadyExists?AirportID=',
    getAccreditationAirportsList:'/Accreditation/GetAccreditationAirportsList',
    getAirportDetailsByIdWithApplicationDetails : '/Airport/GetAirportDetailsWithApplicationDetails?AirportID=',
    getAirportGroupDetailsByIdWithApplicationDetails : '/Airport/GetAirportGroupDetailsWithApplicationDetails?AirportGroupID=',
    getAirportGroupAirportList : '/Airport/GetAirportGroupAirportLists?AirportGroupID=',
    getAirportGroupAirportApplicationList : '/Airport/GetAirportGroupApplicationLists?AirportGroupID=',
    checkAirportGroupAlreadyExists: '/Accreditation/CheckAirportGroupAlreadyExists?AirportGroup=',

    //Communication
    getVerifierCommunicationList : '/Verifiers/GetVerifierCommunicationList?VerifierID=',
    addVerifierCommunication : '/Verifiers/AddVerifierCommunication',
    editVerifierCommunication : '/Verifiers/EditVerifierCommunication',
    getVerifierCommunicationByID : '/Verifiers/GetVerifierCommunication?ID=',
    deleteCommunication : '/Verifiers/DeleteVerifierCommunication',
    //Verifier Accreditation
    getVerifierAccreditationsList : '/Verifiers/GetAccreditationsList?VerifierID=',
    CheckSuspensionExists : '/Verifiers/CheckSuspensionExists?VerifierID=',
    addAirportInvoice:'/Accreditation/AddEditAirportInvoiceData',
    getAirportInvoiceDetails : '/Accreditation/GetAirportInvoiceData?AirportApplicationID=',
    createAirportUser: '/Accreditation/CreateAirportUserData',
    getNotificationDetails: '/Accreditation/GetAirportNotifications',
    getAirportUserList: '/Accreditation/GetAirportUserData',
    getAirportUserDetails : '/Accreditation/GetAirportUserDetailByID',
    UpdateAirportUserDetails :'/Accreditation/UpdateAirportUserData',
    getAirportUserHistoricalApplicationList:'/Accreditation/GetAirportUserHistoricalApplicationList',

    // Verifier Dashboard
    getAirportAccreditationListForVerifier: '/Accreditation/GetAirportAccreditationListForVerifier',

    // Application Stage
    getAccreditationApplicationOverview : '/Accreditation/GetAccreditationApplicationOverview?ApplicationID=',
    getApplicationSecSubSecQuesDetails : '/Accreditation/GetApplicationSecSubSecQuesDetails?ApplicationID=',
    saveAccreditationLevelToAirport : '/Accreditation/SaveAccreditationLevelToAirport',
    saveOtherDetails : '/Accreditation/SaveSubmitApplicationSummary',
    saveApplicationSummary : '/Accreditation/SaveSubmitApplicationSummary',
    saveSectionResponse : '/Accreditation/SaveSectionResponse',
    getSummaries: '/Accreditation/GetApplicationSummaryUserbased?ApplicationID=',
    getVerificationSummary: '/Accreditation/GetVerificationSummary?ApplicationID=',
    getSubSectionDetailsByID: '/Accreditation/GetApplicationSubSecDetails?ApplicationID=',
    sendBackApplicationToAirportVerifierAdmin: '/Accreditation/ApplicationSendBackToAirportVerifierAdmin',
    getAdminUserList: '/Accreditation/GetAdminUserList',
    approveAirportApplication: '/Accreditation/ApproveAirportApplication',
    getApprovedApplicationDetails: '/Accreditation/GetApproveApplicationDetails',
    checkAirportACAYearAlreadyExists: '/Accreditation/CheckAirportACAYearAlreadyExists?ApplicationID=',
    checkIfVerificationIsRequired: '/Accreditation/Check3YearRenewalApplicable?ApplicationID=',
    getAirportUserBasedOnAirport: '/Accreditation/GetAirportUserBasedOnAirport',

    // Accreditation Renew
    getAirportApplicationListToRenewUpgradeDowngrade: '/Accreditation/GetAirportApplicationListToRenewUpgradeDowngrade',
    copyOrFreshAirportApplication: '/Accreditation/CopyOrFreshAirportApplication',

    //Email APIs
    getEmailDeliveredList: '/Email/GetEmailDeliveredList',

     //AirportDocuments
     getAirportDocumentTypes : '/Airport/GetAirportDocumentTypes',
     getAirportDocumentList : '/Airport/GetAirportDocumentsList?AirportID=',
     addEditAirportDocument : '/Airport/AddEditAirportDocuments',
     getAirportDocumentsByID : '/Airport/GetAirportDocumentDetails?ID=',
     deleteAirportDocument : '/Airport/DeleteAirportDocuments',
};
export default apiconfig;