import React from "react";
import { Navigate } from "react-router-dom";

const AuthCheckerAirport = ({ allowedRoles, redirectPath, children }) => {

  const userRoles = JSON.parse(localStorage.getItem("roles"));
  const isAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User';
  if(userRoles === null){
    return <Navigate to={redirectPath} replace />;
  }
 
  const hasRequiredRole = allowedRoles.some((role) =>
  userRoles.some((userRole) => userRole.name === role)
);
//const hasRequiredRole = true;
  if (hasRequiredRole && isAirportUser) {
    return children;
  } else {
    return <Navigate to={redirectPath} replace />;
  }
};

export default AuthCheckerAirport;
