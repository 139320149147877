import React, { useEffect, useState } from 'react'
import ConfirmationDialogBox from '../../Common/ConfirmationDialogBox'
import CommonCustomModal from '../../Common/CommonCustomModal'
import { Slide, toast } from 'react-toastify'
import { useNavigate} from "react-router-dom"
import { htmlConverterReact } from 'html-converter-react'
import ConsolidatedErrorList from './ConsolidatedErrorList'
import UploadAssessmentDocuments from './UploadAssessmentDocuments'

const ApplicationSummary = ({ data, state, setState, activeRightPane, setActiveRightPane, leftNavigationMenu, userType, checkIfVerificationIsRequired, isVerificationRequired, submitApplication, sendBackApplication, approveApplication, scrollToQuestionViaQuestionRef, visibilityBasedOnType, setAlertDialogBoxProperties }) => {

    /* States, Variables & Constants */
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false)
    const [isVerificationRequested, setIsVerificationRequested] = useState('0')
    const [confirmationDialogBoxProperties, setConfirmationDialogBoxProperties] = useState({ visibility: false, text: '', negativeButtonText: '', positiveButtonText: '', operationType: '' })
    const isVerificationDropdownOptions = [{label: '---------------', value: '0'}, {label: 'Yes', value: '1'}, {label: 'No', value: '2'}]
    const navigate = useNavigate()

    /* Methods */
    const toggleConfirmationDialogBox = () => { 
        setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, visibility: !confirmationDialogBoxProperties.visibility})
    } 
    const updateState = (type, value) => {
        const updatedState = {...state}
        switch(type){
            case 'select-verifier':
                if(value === '0')
                    updatedState.summaries[1].summary.selectVerifier.value = ''
                else
                    updatedState.summaries[1].summary.selectVerifier.value = value
                break
            case 'airport-group':
                if(value === '0'){
                    updatedState.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value = ''
                    updatedState.summaries[1].summary.participationCosts.groupDiscount = '0'
                }
                else{
                    updatedState.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value = value
                    updatedState.summaries[1].summary.participationCosts.groupDiscount = updatedState.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.optionsObject.find(option => option.value === value).label
                }
                break
            case 'feedback':
                updatedState.summaries[1].summary.feedback = value
                break
            case 'approved-by-r1':
                updatedState.summaries[1].adminSummary.r1Approved = value
                if(!value)
                    updatedState.summaries[1].adminSummary.finalApproved = false
                break
            case 'r1':
                /* recycle any options that have been removed previously from r2 options */
                if(updatedState.summaries[1].adminSummary.r2.recycleOptions.length !== 0){
                    console.log(updatedState.summaries[1].adminSummary.r2.recycleOptions)
                    for(const item of updatedState.summaries[1].adminSummary.r2.recycleOptions){
                        /*
                            The first argument `item.optionIndex` is the index at which we're inserting the option
                            The second argument `0` specifies how many elements should be removed
                            The third argument `item.option` is the value to be inserted at the specified index
                        */
                        updatedState.summaries[1].adminSummary.r2.options.splice(item.optionIndex, 0, item.option)
                    }
                    updatedState.summaries[1].adminSummary.r2.recycleOptions = []
                }

                if(value === '0'){
                    updatedState.summaries[1].adminSummary.r1.value = ''
                    updatedState.summaries[1].adminSummary.finalApproved = false
                }
                else{
                    updatedState.summaries[1].adminSummary.r1.value = value
                    /* manipulate r2 options based on current selected r1 value */
                    const indexOfR2OptionToBeRemoved = updatedState.summaries[1].adminSummary.r2.options.findIndex(option => option.value === value)
                    if(indexOfR2OptionToBeRemoved !== -1){
                        /* accesses the first (and only) element in the array returned by `splice` */
                        const option = updatedState.summaries[1].adminSummary.r2.options.splice(indexOfR2OptionToBeRemoved, 1)[0]
                        /* using hashing of type `int (implies index) -> object (implies the actual option)` insert the option that has been removed from options of r2 */
                        updatedState.summaries[1].adminSummary.r2.recycleOptions.push({ optionIndex: indexOfR2OptionToBeRemoved, option: option }) 
                    }
                }
                break
            case 'approved-by-r2':
                updatedState.summaries[1].adminSummary.r2Approved = value
                if(!value)
                    updatedState.summaries[1].adminSummary.finalApproved = false
                break
            case 'r2':
                /* recycle any options that have been removed previously from r1 options */
                if(updatedState.summaries[1].adminSummary.r1.recycleOptions.length !== 0){
                    for(const item of updatedState.summaries[1].adminSummary.r1.recycleOptions){
                        /*
                            The first argument `item.optionIndex` is the index at which we're inserting the option
                            The second argument `0` specifies how many elements should be removed
                            The third argument `item.option` is the value to be inserted at the specified index
                        */
                        updatedState.summaries[1].adminSummary.r1.options.splice(item.optionIndex, 0, item.option)
                    }
                    updatedState.summaries[1].adminSummary.r1.recycleOptions = []
                }

                if(value === '0'){
                    updatedState.summaries[1].adminSummary.r2.value = ''
                    updatedState.summaries[1].adminSummary.finalApproved = false
                }
                else{
                    updatedState.summaries[1].adminSummary.r2.value = value
                    /* manipulate r1 options based on current selected r2 value */
                    const indexOfR1OptionToBeRemoved = updatedState.summaries[1].adminSummary.r1.options.findIndex(option => option.value === value)
                    if(indexOfR1OptionToBeRemoved !== -1){
                        /* accesses the first (and only) element in the array returned by `splice` */
                        const option = updatedState.summaries[1].adminSummary.r1.options.splice(indexOfR1OptionToBeRemoved, 1)[0]
                        /* using hashing of type `int (implies index) -> object (implies the actual option)` insert the option that has been removed from options of r1 */
                        updatedState.summaries[1].adminSummary.r1.recycleOptions.push({ optionIndex: indexOfR1OptionToBeRemoved, option: option }) 
                    }
                }
                break
            case 'final-approved':
                updatedState.summaries[1].adminSummary.finalApproved = value
                break
            case 'final-approver-comments':
                updatedState.summaries[1].adminSummary.comment = value
                break
            default:
                break
        }
        setState(updatedState)
    }
    const confirmation = async (response) => {
        switch(response){
            case 'positive':
                switch(confirmationDialogBoxProperties.operationType){
                    /* airport operations */
                    case 'airport-submit-to-verifier':
                        setIsSubmitButtonClicked(true)
                        if(checkApplicationCompletionStatus()){
                            // if true, call the api end-point
                            if(checkCompletionStatus())
                                await submitApplication(state.summaries[1].summary.selectVerifier.value, state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value, state.summaries[1].summary.feedback, true, true,'airport-submit-to-verifier')
                            else
                                showToastIfAllErrorsAreNotResolved()
                        }
                        else
                            showToastIfAllErrorsAreNotResolved()
                        break
                    case 'airport-submit-to-admin':
                        setIsSubmitButtonClicked(true)
                        if(checkApplicationCompletionStatus()){
                            /* call api endpoint here */
                            if(checkCompletionStatus() && (isVerificationRequired === 'No' || (isVerificationRequired === 'Optional' && isVerificationRequested === '2'))){
                                await submitApplication('0', state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value, state.summaries[1].summary.feedback, false, false,'')
                                await sendBackApplication('In Admin Verification', 'Airport User')
                            }
                            else
                                showToastIfAllErrorsAreNotResolved()
                        }
                        else
                            showToastIfAllErrorsAreNotResolved()
                        break
                    case 'airport-re-submit-to-verifier':
                        setIsSubmitButtonClicked(true)
                        if(checkApplicationCompletionStatus()){
                            // if true, call the api end-point
                            if(checkCompletionStatus()){
                                await submitApplication(state.summaries[1].summary.selectVerifier.value, state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value, state.summaries[1].summary.feedback, false, false,'')
                                await sendBackApplication('Airport resubmitted - In Verification', 'Airport User')
                            }
                            else
                                showToastIfAllErrorsAreNotResolved()
                        }
                        else
                            showToastIfAllErrorsAreNotResolved()
                        break
                    case 'airport-re-submit-to-admin':
                        setIsSubmitButtonClicked(true)
                        if(checkApplicationCompletionStatus()){
                            // if true, call the api end-point
                            //if(checkCompletionStatus()){
                                await submitApplication(state.summaries[1].summary.selectVerifier.value, state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value, state.summaries[1].summary.feedback, false, false,'')
                                await sendBackApplication('Airport resubmitted - In Admin Verification', 'Airport User')
                            //}
                        }
                        else
                            showToastIfAllErrorsAreNotResolved()
                        break
                    /* verifier operations */
                    case 'verifier-submit-to-admin':
                        setIsSubmitButtonClicked(true)
                        if(checkApplicationCompletionStatus())
                            await sendBackApplication('In Admin Verification', 'Verifier')
                        else
                            showToastIfAllErrorsAreNotResolved()
                        break
                    case 'verifier-re-submit-to-admin':
                        setIsSubmitButtonClicked(true)
                        if(checkApplicationCompletionStatus())
                            await sendBackApplication('Verifier resubmitted - In Admin Verification', 'Verifier')
                        else
                            showToastIfAllErrorsAreNotResolved()
                        break
                    case 'verifier-send-back-to-airport':
                        /* call api endpoint here */
                        await sendBackApplication('Verifier comments - Airport to address', 'Verifier')
                        break
                    /* admin operations */
                    case 'admin-send-back-to-airport':
                        /* call api endpoint here */
                        await sendBackApplication('Admin comments - Airport to address', 'Admin')
                        break
                    case 'admin-send-back-to-verifier':
                        /* call api endpoint here */
                        await sendBackApplication('Admin comments - Verifier to address', 'Admin')
                        break
                    case 'admin-approves-application':
                        await approveApplication(state.summaries[1].adminSummary.r1.value, state.summaries[1].adminSummary.r2.value, state.summaries[1].adminSummary.comment, state.summaries[1].adminSummary.documents)
                        break
                    default:
                        break
                }
                break
            case 'negative':
                // nothing to do here
                break
            default:
                break
        }
        toggleConfirmationDialogBox()
    }
    const checkApplicationCompletionStatus = () => {
        /* 
            update the 'state' by setting 'isSubmitButtonClicked' as true for 'Other Details' and each subsection on the basis of the type of user who's performing the operation
            This would trigger validations for the subsections (and for each required question within that subsection) 
        */
        const updatedState = {...state}
        if(updatedState.default[2].type === userType)
            updatedState.default[2].isSubmitButtonClicked = true
        updatedState.sections.map((section) => {
            section.subsections.map((subsection) => {
                if(subsection.type === userType)
                    subsection.isSubmitButtonClicked = true
            })
        })
        setState(updatedState)

        /* 
            iterate through each question (and subquestions) within each subsection and 'Other Details' 
            return 'false' only if any of the subsections (and Other Details) have any mandatory question(s) against which there's been no response captured yet  
        */
        if(checkIfOtherDetailsIsIncomplete())
            return false  

        for(const section of state.sections){
            for(const subsection of section.subsections){
                if(subsection.type === userType){
                    if(!subsection.completed)
                        return false
                    // for(const question of subsection.content.questions){
                    //     switch(question.responseType){
                    //         case 'text': 
                    //         case 'textarea': 
                    //         case 'dropdown':
                    //             if(question.required && question.response.length === 0)
                    //                 return false
                    //             break
                    //         case 'other':
                    //             if(question.required && question.other.responses.length === 0)
                    //                 return false
                    //             break
                    //         case 'matrix':
                    //             if(question.required){
                    //                 for(const row of question.matrix.rows){
                    //                     for(const attribute of row.attributes){
                    //                         if(attribute.type !== 'empty'){
                    //                             if(attribute.value.length === 0)
                    //                                 return false
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //             break
                    //         default:
                    //             break
                    //     }
                    //     for(const subquestion of question.subquestions){
                    //         if(!checkSubQuestionCompletionStatus(subquestion))
                    //             return false
                    //     }
                    // }
                }
            }
        }
        return true
    }
    const checkSubQuestionCompletionStatus = (question) => {
        switch(question.responseType){
            case 'text': 
            case 'textarea': 
            case 'dropdown':
                if(question.required && question.response.length === 0)
                    return false
                break
            case 'other':
                if(question.required && question.other.responses.length === 0)
                    return false
                break
            case 'matrix':
                if(question.required){
                    for(const row of question.matrix.rows){
                        for(const attribute of row.attributes){
                            if(attribute.type !== 'empty'){
                                if(attribute.value.length === 0)
                                    return false
                            }
                        }
                    }
                }
                break
            default:
                break
        }
        for(const subquestion of question.subquestions){
            if(!checkSubQuestionCompletionStatus(subquestion))
                return false
        }
        return true
    }
    const checkCompletionStatus = () => {
        return state.applicationType === 'Renew' && isVerificationRequired === 'No' 
                    ? 
                    true 
                    : 
                    state.applicationType === 'Renew' && isVerificationRequested === '2' 
                        ?
                        state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value.length !== 0 && state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value !== '0'
                        :
                        state.summaries[1].summary.selectVerifier.value.length !== 0 && state.summaries[1].summary.selectVerifier.value !== '0' && state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value.length !== 0 && state.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value !== '0'
    }
    const checkIfAnySubSectionIsIncomplete = () => {
        for(const section of state.sections){
            for(const subsection of section.subsections){
                if(subsection.type === userType && !subsection.completed)
                    return true
            }
        }
        return false
    }
    const checkIfAnySubSectionOfSectionIsIncomplete = (sectionIndex) => {
        for(const subsection of state.sections[sectionIndex].subsections){
            if(subsection.type === userType && !subsection.completed)
                return true
        }
        return false
    }
    const checkIfSubSectionIsIncomplete = (sectionIndex, subsectionIndex) => {
        if(state.sections[sectionIndex].subsections[subsectionIndex].type === userType)
            return !state.sections[sectionIndex].subsections[subsectionIndex].completed
    }
    const checkIfOtherDetailsIsIncomplete = () => {
        if(state.default[2].type === userType){
            if(!state.default[2].completed)
                return true
            if(!state.default[2].completed && (state.default[2].acaYear.required && (state.default[2].acaYear.value.length === 0 || state.default[2].acaYear.value === '0')))
                return true
            if(!state.default[2].completed && (state.default[2].airportBand.required && (state.default[2].airportBand.value.length === 0 || state.default[2].airportBand.value === '0')))
                return true
            if(!state.default[2].completed && (state.default[2].timePeriodOfCarbonFootprint.required && (state.default[2].timePeriodOfCarbonFootprint.value.length === 0 || state.default[2].timePeriodOfCarbonFootprint.value === '0')))
                return true
            if(!state.default[2].completed && (state.default[2].verificationType.required && (state.default[2].verificationType.value.length === 0 || state.default[2].verificationType.value === '0'))){
                // return 'false' when the case is of Interim Year where verification is not present
                if(state.applicationType === 'Renew' && isVerificationRequired == 'No')
                    return false
                return true
            }
        }
        return false
    }
    const showToastIfAllErrorsAreNotResolved = () => {
        toast.info('You cannot submit this application until all errors are resolved.', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
    }
    const checkIncompletenessIfQuestionOfResponseTypeOtherHasPartiallyPreFilledResponses = (question) => {
        if(question.beingResponseTypeOtherDoesItHasPartiallyPreFilledResponses){
            if(question.lastSuccessfullySavedResponseOther !== null && question.lastSuccessfullySavedResponseOther.length !== 0){
                if(question.other.minimumOneResponseRequired){
                    let hasMinimumOneResponseRequiredConditionMet = false
                    let responseIsCompletelyFilled = true
                    for(const response of question.lastSuccessfullySavedResponseOther){
                        responseIsCompletelyFilled = true
                        for(const input of response.inputs){
                            if(input.value.length === 0){
                                responseIsCompletelyFilled = false
                                break
                            }
                        }
                        if(responseIsCompletelyFilled)
                            hasMinimumOneResponseRequiredConditionMet = true
                    }
                    return !hasMinimumOneResponseRequiredConditionMet
                }
                else{
                    for(const response of question.lastSuccessfullySavedResponseOther){
                        for(const input of response.inputs){
                            if(input.value.length === 0)
                                return true
                        }
                    }
                }
                return false
            }
        }
        return false
    }
    const checkWhetherAllRequiredAttributesWithinEachRowOfMatrixHasValue = (question) => {
        if(question.lastSuccessfullySavedResponseMatrix !== null && question.lastSuccessfullySavedResponseMatrix.length !== 0){
            for(const row of question.lastSuccessfullySavedResponseMatrix){
                for(const attribute of row.attributes){
                    if(attribute.required && attribute.value.length === 0)
                        return false
                }
            }
        }
        return true 
    }

    /* Effects */
    useEffect(() => {
        if(state.summaries.length > 0 && state.applicationType === 'Renew' && state.applicationStatus !== 'In Progress' && isVerificationRequired.length === 0)
            checkIfVerificationIsRequired()
    }, [state])

    useEffect(()=>{
        if(userType !== 'verifier' && isVerificationRequired.length !== 0 && isVerificationRequested === '0'){
            if(isVerificationRequired !== 'Yes' && (state.summaries[1].summary.selectVerifier.value === '0' || state.summaries[1].summary.selectVerifier.value === ''))
                setIsVerificationRequested('2')
            else
                setIsVerificationRequested('1')
        }
    }, [isVerificationRequired])

    return(
        <React.Fragment>
            <div className="user-info-list card card-border card-contrast card-table" style={data.summaries.length !== 0 && activeRightPane === data.summaries[1].name ? {} : {display: 'none'}}>
                <div className="card-header card-header-contrast card-header-divider">
                    Submit Application
                </div>
                <div className="card-body">
                    <label className='text-info' style={ userType === 'admin' ? { paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 0 } : { display: 'none' }}>You are now ready to submit your application for the Airport Carbon Accreditation programme.</label>
                    <ConsolidatedErrorList state={state} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} userType={userType} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} checkIfOtherDetailsIsIncomplete={checkIfOtherDetailsIsIncomplete} checkIfSubSectionIsIncomplete={checkIfSubSectionIsIncomplete} checkIfAnySubSectionOfSectionIsIncomplete={checkIfAnySubSectionOfSectionIsIncomplete} checkIfAnySubSectionIsIncomplete={checkIfAnySubSectionIsIncomplete} isSubmitButtonClicked={isSubmitButtonClicked} isVerificationRequired={isVerificationRequired} checkIncompletenessIfQuestionOfResponseTypeOtherHasPartiallyPreFilledResponses={checkIncompletenessIfQuestionOfResponseTypeOtherHasPartiallyPreFilledResponses} checkWhetherAllRequiredAttributesWithinEachRowOfMatrixHasValue={checkWhetherAllRequiredAttributesWithinEachRowOfMatrixHasValue} />
                    {
                        data.summaries.length !== 0 && data.summaries[1].type === 'draft' 
                            ?
                            <React.Fragment>
                                {/* <div className="p-2 bg-teal-1">
                                    <h5 className="m-0">Print and Review Application</h5>
                                </div>
                                <table className="no-border no-strip skills">
                                    <tbody className="no-border-x no-border-y">
                                        <tr>
                                            <td>
                                                <div className="media align-items-center">
                                                    <span className="mdi mdi-3x mdi-file-text text-danger" />
                                                    <div className="media-body ml-3">
                                                        <p className="mb-0">Download PDF</p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <button className="btn btn-space btn-rounded btn-secondary"><i className="icon icon-left mdi mdi-download"/>Download</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table> */}
                                <div className="p-2 bg-teal-1">
                                    <h5 className="m-0">Application Verification</h5>
                                </div>
                                <table className="no-border no-strip skills">
                                    <tbody className="no-border-x no-border-y">
                                        <tr>
                                            <td colSpan={2}>
                                            <p>Airports are required to submit a verified application on their initial year of application, and every <b>second</b> year subsequently if they remain at the same level of accreditation.</p>
                                            <p>Airports participating at Level 3, 3+, 4 or 4+ on a three-year renewal cycle are required to submit a verified application on the first year of the three year cycle.</p>
                                            <p>If an airport upgrades to a higher level, it must submit a verified application, regardless of whether or not the previous year’s application was verified.</p>
                                            <p>Airports can also choose to get their application voluntarily verified during those years when a verification is not required.</p>
                                            </td>
                                        </tr>
                                        {
                                            state.applicationType === 'Renew' && isVerificationRequired === 'Optional' 
                                                ?
                                                <tr>
                                                    <td>
                                                        <div className="media align-items-center">
                                                            <div className="media-body">
                                                                <p className="mb-0">Submit application for verification?</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select id="is-verification-requested" name="is-verification-requested" className="form-control form-control-sm" value={isVerificationDropdownOptions.find(option => option.value === isVerificationRequested).value} onChange={(e) => { setIsVerificationRequested(e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(data.summaries[1].type, false) && state.applicationStatus === 'Draft' ? false : true}>
                                                            {
                                                                isVerificationDropdownOptions.map((option, index) => {
                                                                    return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                        {
                                                            isSubmitButtonClicked && isVerificationRequested === '0'
                                                                ? 
                                                                <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;This response is mandatory!</span>
                                                                :
                                                                <React.Fragment></React.Fragment> 
                                                        }                                          
                                                    </td>
                                                </tr>
                                                :
                                                <React.Fragment></React.Fragment>
                                        }
                                        {
                                            state.applicationType === 'Entry' || state.applicationType === 'Upgrade' || state.applicationType === 'Downgrade' || isVerificationRequired === 'Yes' || isVerificationRequested === '1'
                                                ?
                                                <tr>
                                                    <td>
                                                        <div className="media align-items-center">
                                                            <span className="mdi mdi-3x mdi-accounts text-danger"/>
                                                            <div className="media-body ml-3">
                                                                <p className="mb-0">Select Verifier</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <select id="id_verifier" name="verifier" className="form-control form-control-sm" defaultValue={(data.summaries[1].summary.selectVerifier.value.length === 0 ? data.summaries[1].summary.selectVerifier.defaultValue : data.summaries[1].summary.selectVerifier.value)} onChange={(e) => { updateState('select-verifier', e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(data.summaries[1].type, false) && state.applicationStatus === 'Draft' ? false : true}>
                                                            { 
                                                                data.summaries[1].summary.selectVerifier.options.map((option, index) => {
                                                                    return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                        Please make sure that the chosen verifier is already in contract with your organisation.<br/>
                                                        {
                                                            isSubmitButtonClicked && data.summaries[1].summary.selectVerifier.required && (data.summaries[1].summary.selectVerifier.value === '0' || data.summaries[1].summary.selectVerifier.value === '')
                                                                ? 
                                                                <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;{data.summaries[1].summary.selectVerifier.validationMessage}</span>
                                                                :
                                                                <React.Fragment></React.Fragment>
                                                        }
                                                    </td>
                                                </tr>
                                                :
                                                <React.Fragment></React.Fragment>
                                        }                            
                                    </tbody>
                                </table>
                                {
                                    state.applicationType === 'Renew' && isVerificationRequired === 'No'
                                        ?
                                        <React.Fragment></React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="p-2">
                                                <h4 className="m-0">Participation Costs</h4>
                                                <p>Fees are based on the following information:</p>
                                            </div>
                                            <table className="no-border no-strip skills">
                                                <tbody className="no-border-x no-border-y">
                                                    <tr>
                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-view-dashboard mr-2"/>Application Accreditation Level</td>
                                                        <td><span className="badge badge-secondary">{data.summaries[1].summary.participationCosts.accreditationLevel}</span></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Renewal?</td>
                                                        <td>{data.summaries[1].summary.participationCosts.applicationRenewal}</td>
                                                    </tr>
                                                    {
                                                        state.applicationType === 'Renew'
                                                            ?
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Three Year renewal?</td>
                                                                    <td>{data.summaries[1].summary.participationCosts.application3YearRenewal}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                            :
                                                        state.applicationType === 'Upgrade'
                                                            ?
                                                            <React.Fragment>
                                                                <tr>
                                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Three Year renewal?</td>
                                                                    <td>{data.summaries[1].summary.participationCosts.application3YearRenewal}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-trending-up mr-2"/>Application Upgrading?</td>
                                                                    <td>{data.summaries[1].summary.participationCosts.prevLevel} {'>'} {data.summaries[1].summary.participationCosts.nextLevel}</td>
                                                                </tr>
                                                            </React.Fragment>
                                                            :
                                                        state.applicationType === 'Downgrade' 
                                                            ?
                                                            <React.Fragment>
                                                            <tr>
                                                                <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Three Year renewal?</td>
                                                                <td>{data.summaries[1].summary.participationCosts.application3YearRenewal}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="item icon font-weight-bold"><span className="mdi mdi-trending-up mr-2"/>Application Downgrading?</td>
                                                                <td>{data.summaries[1].summary.participationCosts.prevLevel} {'>'} {data.summaries[1].summary.participationCosts.nextLevel}</td>
                                                            </tr>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>                                                                                                      
                                                            </React.Fragment>
                                                    }
                                                    <tr>
                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-shield-security mr-2"/>Number of ACA certified airports</td>
                                                        <td>
                                                            <select id="id_apt_group_size" name="apt_group_size" className="form-control form-control-sm" value={data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value.length === 0 ? data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.defaultValue : data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value} onChange={(e) => { updateState('airport-group', e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(data.summaries[1].type, false) ? false : true}>
                                                                {
                                                                    data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.options.map((option, index) => {
                                                                        return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                                    })
                                                                }
                                                            </select>
                                                            {
                                                                isSubmitButtonClicked && data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.required && data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value === '0' 
                                                                    ? 
                                                                    <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;{data.summaries[1].summary.selectVerifier.validationMessage}</span>
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-money-box mr-2"/>Group Discount</td>
                                                        <td>{data.summaries[1].summary.participationCosts.groupDiscount} %</td>  
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="p-2 bg-teal-1">
                                                <table className="no-border no-strip skills">
                                                    <tbody className="no-border-x no-border-y">
                                                        <tr>
                                                            <td className="item icon font-weight-bold"> <h5 className="m-0">Total Amount Due</h5></td>
                                                            <td>
                                                                <h5 className="m-0">
                                                                { 
                                                                    data.summaries[1].summary.totalAmountDue !== ''
                                                                        ?
                                                                        <>
                                                                        {data.summaries[1].summary.totalAmountDue.split(' ')[0]}{' '}
                                                                        {data.summaries[1].summary.totalAmountDue.split(' ')[1] -((data.summaries[1].summary.totalAmountDue.split(' ')[1]*data.summaries[1].summary.participationCosts.groupDiscount)/100)}
                                                                        </>
                                                                        :
                                                                        0
                                                                }
                                                                </h5>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>   
                                            </div>
                                        </React.Fragment>
                                }
                                <div className="px-2 pt-4">
                                    {
                                        state.applicationType === 'Renew' && isVerificationRequired !== 'No'
                                            ?
                                            <p>The total fee must be paid before the application is reviewed by the administrator and a certificate can be issued. The above fee is provisional and will be confirmed by the Administrator.</p>
                                            :
                                            // <p>Once the application has been submitted and approved the total fee will need to be paid before a certificate will be issued.</p>
                                            <React.Fragment></React.Fragment>
                                    }
                                    <p>It is a requirement of the ACA Programme that accreditation fees need to be paid prior to the Administrator’s review of the application.</p>
                                    <p>
                                        <b>Please make sure all invoice details are up to date. You can update the invoice details under&nbsp;
                                            <a href="" className="text-info" onClick={(e) => { e.preventDefault(); navigate('/AddAirportInvoice') }}><i>Manage Accounts</i></a>
                                        </b>
                                    </p>
                                    {
                                        state.applicationType === 'Renew' 
                                            ?
                                            <p>One year renewal has been selected for this application</p>
                                            :
                                            <p>Please note that this is provisional and may be subject to change by the Administrator.</p>
                                    }
                                    <div className="form-group">
                                        <label htmlFor="inputTextarea1">
                                            <b>Please leave any feedback on how we can improve this online application site (Optional)</b>
                                        </label>
                                        <textarea className="form-control" id="feedback" placeholder="Enter Feedback..." defaultValue={state.summaries[1].summary.feedback} onChange={(e) => { updateState('feedback', e.target.value) }} disabled={visibilityBasedOnType(data.summaries[1].type, false) ? false : true} />
                                    </div>
                                </div>
                            </React.Fragment>
                            :
                            data.summaries.length !== 0 && (data.summaries[1].type === 'verifier' || (data.summaries[1].type === 'admin' && data.summaries[1].summary.selectVerifier.value.length !== 0))  
                                ?
                                <React.Fragment>
                                    {
                                        state.applicationType === 'Renew' && userType !== 'verifier' && (isVerificationRequired === 'No' || (isVerificationRequired === 'Optional' && state.summaries[1].summary !== null && state.summaries[1].summary.selectVerifier !== null && (state.summaries[1].summary.selectVerifier.value.length === 0 || state.summaries[1].summary.selectVerifier.value === '0') && state.applicationStatus !== 'Draft')) 
                                            ?
                                            <React.Fragment></React.Fragment> 
                                            :
                                            <React.Fragment>
                                                <table className="no-border no-strip skills">
                                                    <tbody className="no-border-x no-border-y">
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-account-circle mr-2"></span>Verifier</td>
                                                            {
                                                                data.default.length !== 0 && data.default[0] !== null && data.default[0].content !== null && data.default[0].content.applicationDetails !== null 
                                                                    ?
                                                                    <td>{data.default[0].content.applicationDetails.verifier}</td>
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                            }
                                                        </tr>
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-accounts-alt mr-2"></span>Verification Type</td>
                                                            {
                                                                data.default.length !== 0 && data.default[0] !== null && data.default[0].content !== null && data.default[0].content.applicationDetails !== null 
                                                                    ?
                                                                    <td>{data.default[0].content.applicationDetails.verificationType === '1' ? 'Verification completed on-site' : (data.default[0].content.applicationDetails.verificationType === '2' ? 'Verification completed off-site' : '')}</td>
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                            }
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="p-2">
                                                    <h4 className="m-0">Verification Review</h4>
                                                </div>
                                                <table className="no-border no-strip skills">
                                                    <tbody className="no-border-x no-border-y">
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"></span>Verifier Review Overall</td>
                                                            <td>{data.summaries[1].verificationReview.overall}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-money-box mr-2"></span>Verifier Review Footprint</td>
                                                            <td>{data.summaries[1].verificationReview.footprint}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-money-box mr-2"></span>Verifier Review Management</td>
                                                            <td>{data.summaries[1].verificationReview.management}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </React.Fragment>
                                    }
                                    {/* <div className="p-2 bg-teal-1">
                                        <h5 className="m-0">Print and Review Application</h5>
                                    </div>
                                    <table className="no-border no-strip skills">
                                        <tbody className="no-border-x no-border-y">
                                            <tr>
                                                <td>
                                                    <div className="media align-items-center">
                                                        <span className="mdi mdi-3x mdi-file-text text-danger"></span>
                                                        <div className="media-body ml-3">
                                                            <p className="mb-0">Download PDF</p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><button className="btn btn-space btn-rounded btn-secondary"><i className="icon icon-left mdi mdi-download"></i>Download</button></td>
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    {
                                        data.summaries[1].type === 'admin' 
                                            ? 
                                            <React.Fragment>
                                                {
                                                    state.applicationType === 'Renew' && isVerificationRequired === 'No' 
                                                        ?
                                                        <React.Fragment></React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <div className="p-2">
                                                                <h4 className="m-0">Participation Costs</h4>
                                                                <p>Fees are based on the following information:</p>
                                                            </div>
                                                            <table className="no-border no-strip skills">
                                                                <tbody className="no-border-x no-border-y">
                                                                    <tr>
                                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-view-dashboard mr-2"/>Application Accreditation Level</td>
                                                                        <td><span className="badge badge-secondary">{data.summaries[1].summary.participationCosts.accreditationLevel}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Renewal?</td>
                                                                        <td>{data.summaries[1].summary.participationCosts.applicationRenewal}</td>
                                                                    </tr>
                                                                    {
                                                                        state.applicationType === 'Renew'
                                                                            ?
                                                                            <React.Fragment>
                                                                                <tr>
                                                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Three Year renewal?</td>
                                                                                    <td>{data.summaries[1].summary.participationCosts.application3YearRenewal}</td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                            :
                                                                        state.applicationType === 'Upgrade'
                                                                            ?
                                                                            <React.Fragment>
                                                                                <tr>
                                                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Three Year renewal?</td>
                                                                                    <td>{data.summaries[1].summary.participationCosts.application3YearRenewal}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-trending-up mr-2"/>Application Upgrading?</td>
                                                                                    <td>{data.summaries[1].summary.participationCosts.prevLevel} {'>'} {data.summaries[1].summary.participationCosts.nextLevel}</td>
                                                                                </tr>
                                                                            </React.Fragment>
                                                                            :
                                                                        state.applicationType === 'Downgrade' 
                                                                            ?
                                                                            <React.Fragment>
                                                                            <tr>
                                                                                <td className="item icon font-weight-bold"><span className="mdi mdi-rotate-right mr-2"/>Application Three Year renewal?</td>
                                                                                <td>{data.summaries[1].summary.participationCosts.application3YearRenewal}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="item icon font-weight-bold"><span className="mdi mdi-trending-up mr-2"/>Application Downgrading?</td>
                                                                                <td>{data.summaries[1].summary.participationCosts.prevLevel} {'>'} {data.summaries[1].summary.participationCosts.nextLevel}</td>
                                                                            </tr>
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment>                                                                                                      
                                                                            </React.Fragment>
                                                                    }
                                                                    <tr>
                                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-shield-security mr-2"/>Number of ACA certified airports</td>                                                           
                                                                        {
                                                                            data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.options.length !== 0 &&
                                                                            data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.options.map((aca, aca_index) => (
                                                                                data.summaries[1].summary.participationCosts.numberOfAcaCertifiedAirports.value === aca.value &&
                                                                                    <td key={aca_index}>{aca.label}</td>                                                                
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="item icon font-weight-bold"><span className="mdi mdi-money-box mr-2"/>Group Discount</td>
                                                                        <td>{data.summaries[1].summary.participationCosts.groupDiscount} %</td>  
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="p-2 bg-teal-1">                                                  
                                                                <table className="no-border no-strip skills">
                                                                    <tbody className="no-border-x no-border-y">
                                                                        <tr>
                                                                            <td className="item icon font-weight-bold"> <h5 className="m-0">Total Amount Due</h5></td>
                                                                            <td>
                                                                                <h5 className="m-0">
                                                                                { 
                                                                                    data.summaries[1].summary.totalAmountDue !== ''
                                                                                        ?
                                                                                        <>
                                                                                        {data.summaries[1].summary.totalAmountDue.split(' ')[0]}{' '}
                                                                                        {data.summaries[1].summary.totalAmountDue.split(' ')[1] -((data.summaries[1].summary.totalAmountDue.split(' ')[1]*data.summaries[1].summary.participationCosts.groupDiscount)/100)}
                                                                                        </>
                                                                                        :
                                                                                        0
                                                                                }
                                                                                </h5>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table> 
                                                            </div>
                                                        </React.Fragment>
                                                }
                                                <div className="px-2 pt-4">
                                                    {
                                                        state.applicationType === 'Renew' && isVerificationRequired !== 'No' 
                                                            ?
                                                            <p>The total fee must be paid before the application is reviewed by the administrator and a certificate can be issued. The above fee is provisional and will be confirmed by the Administrator.</p>
                                                            :
                                                            // <p>Once the application has been submitted and approved the total fee will need to be paid before a certificate will be issued.</p>
                                                            <React.Fragment></React.Fragment>
                                                    }
                                                    <p>It is a requirement of the ACA Programme that accreditation fees need to be paid prior to the Administrator’s review of the application.</p>
                                                    <p>
                                                        <b>Please make sure all invoice details are up to date. You can update the invoice details under&nbsp;
                                                            <a href="" className="text-info" onClick={(e) => { e.preventDefault(); navigate('/AddAirportInvoice') }}><i>Manage Accounts</i></a>
                                                        </b>
                                                    </p>
                                                    {
                                                        state.applicationType === 'Renew' 
                                                            ?
                                                            <p>One year renewal has been selected for this application</p>
                                                            :
                                                            <p>Please note that this is provisional and may be subject to change by the Administrator.</p>
                                                    }
                                                    <div className="form-group">
                                                        <label htmlFor="inputTextarea1">
                                                            <b>Feedbacks from airport user</b>
                                                        </label>
                                                        <textarea className="form-control" id="feedback" placeholder="Enter Feedback..." defaultValue={state.summaries[1].summary.feedback} onChange={(e) => { updateState('feedback', e.target.value) }} disabled={true} />
                                                    </div>
                                                </div>
                                                <div className="p-2">
                                                    <h4 className="m-0">Approve Airport</h4>
                                                    <label className='mt-1' for="final" style={{display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between'}}>Final Approver Form:
                                                    <span className="tool" data-tip="'Approved' button will only be enabled once final approver confirms that consent has been provided by all designated reviewers (R1, R2, and the final reviewer) and the necessary approvers have been selected.">
                                                    <span className="icon mdi mdi-1x mdi-help-outline"></span>
                                                    </span>
                                                    </label>
                                                </div>
                                                <table className="no-border no-strip skills">
                                                    <tbody className="no-border-x no-border-y">
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-3x mdi-check-circle mr-2"/>R1 Approved?</td>
                                                            <td>
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input id="r1-approved-yes" className="custom-control-input" type="checkbox" disabled={!visibilityBasedOnType(state.summaries[1].type, false)} checked={state.summaries[1].adminSummary.r1Approved} onChange={(e) => { if(e.target.checked){ updateState('approved-by-r1', true) } }} />
                                                                    <label className="custom-control-label" htmlFor="r1-approved-yes">Yes</label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input id="r1-approved-no" className="custom-control-input" type="checkbox" disabled={!visibilityBasedOnType(state.summaries[1].type, false)} checked={!state.summaries[1].adminSummary.r1Approved} onChange={(e) => { if(e.target.checked){ updateState('approved-by-r1', false) } }} />
                                                                    <label className="custom-control-label" htmlFor="r1-approved-no">No</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-3x mdi mdi-3x mdi-account mr-2"/>Approved by?</td>
                                                            <td>
                                                                <select id="r1" name="r1" className="form-control form-control-sm" value={data.summaries[1].adminSummary.r1.value} onChange={(e) => { updateState('r1', e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.summaries[1].type, false) && state.summaries[1].adminSummary.r1Approved ? false : true}>
                                                                    {
                                                                        data.summaries[1].adminSummary !== null && data.summaries[1].adminSummary.r1.options !== null && data.summaries[1].adminSummary.r1.options.map((option, index) => {
                                                                            return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-3x mdi-check-circle mr-2"/>R2 Approved?</td>
                                                            <td>
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input id="r2-approved-yes" className="custom-control-input" type="checkbox" disabled={!visibilityBasedOnType(state.summaries[1].type, false)} checked={state.summaries[1].adminSummary.r2Approved} onChange={(e) => { if(e.target.checked){ updateState('approved-by-r2', true) }}} />
                                                                    <label className="custom-control-label" htmlFor="r2-approved-yes">Yes</label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input id="r2-approved-no" className="custom-control-input" type="checkbox" disabled={!visibilityBasedOnType(state.summaries[1].type, false)} checked={!state.summaries[1].adminSummary.r2Approved} onChange={(e) => { if(e.target.checked){ updateState('approved-by-r2', false) }}} />
                                                                    <label className="custom-control-label" htmlFor="r2-approved-no">No</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-3x mdi mdi-3x mdi-account mr-2"/>Approved by?</td>
                                                            <td>
                                                                <select id="r2" name="r2" className="form-control form-control-sm" value={data.summaries[1].adminSummary.r2.value} onChange={(e) => { updateState('r2', e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.summaries[1].type, false) && state.summaries[1].adminSummary.r2Approved ? false : true}>
                                                                    {
                                                                        data.summaries[1].adminSummary !== null && data.summaries[1].adminSummary.r2.options !== null && data.summaries[1].adminSummary.r2.options.map((option, index) => {
                                                                            return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </td>  
                                                        </tr>
                                                        <tr>
                                                            <td className="item icon font-weight-bold"><span className="mdi mdi-3x mdi-check mr-2"/>Final Approved?</td>
                                                            <td>
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input id="final-approved-yes" className="custom-control-input" type="checkbox" disabled={visibilityBasedOnType(state.summaries[1].type, false) && data.summaries[1].adminSummary.r1Approved && data.summaries[1].adminSummary.r1.value.length !== 0 && data.summaries[1].adminSummary.r2Approved && data.summaries[1].adminSummary.r2.value.length !== 0 ? false : true} checked={state.summaries[1].adminSummary.finalApproved} onChange={(e) => { if(e.target.checked){ updateState('final-approved', true) } }} />
                                                                    <label className="custom-control-label" htmlFor="final-approved-yes">Yes</label>
                                                                </div>
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input id="final-approved-no" className="custom-control-input" type="checkbox" disabled={visibilityBasedOnType(state.summaries[1].type, false) && data.summaries[1].adminSummary.r1Approved && data.summaries[1].adminSummary.r1.value.length !== 0 && data.summaries[1].adminSummary.r2Approved && data.summaries[1].adminSummary.r2.value.length !== 0 ? false : true} checked={!state.summaries[1].adminSummary.finalApproved} onChange={(e) => { if(e.target.checked){ updateState('final-approved', false) } }} />
                                                                    <label className="custom-control-label" htmlFor="final-approved-no">No</label>
                                                                </div>
                                                            </td>  
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="px-2 pt-4">
                                                    <div className="form-group">
                                                        <label htmlFor="final-approver-comments">
                                                            <p>Final approver comments:</p>
                                                        </label>
                                                        <textarea className="form-control" id="final-approver-comments" placeholder="Enter comments here..." disabled={!visibilityBasedOnType(state.summaries[1].type, false)} defaultValue={data.summaries[1].adminSummary.comment} onChange={(e) => { updateState('final-approver-comments', e.target.value) }} />
                                                    </div>
                                                </div>
                                                <UploadAssessmentDocuments state={state} setState={setState} visibilityBasedOnType={visibilityBasedOnType} />
                                            </React.Fragment>
                                            :
                                            <React.Fragment></React.Fragment>
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                    }
                </div>
            </div>
            <div className="" style={data.summaries.length !== 0 && activeRightPane === data.summaries[1].name ? {display: 'flex', justifyContent: 'flex-end'} : {display: 'none'}}>
                {/* Common Buttons */}
                <button id="back-btn" className="btn btn-space btn-primary btn-xl" onClick={() => { if(leftNavigationMenu.indexOf(activeRightPane) !== 0){ setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1]); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) } }}>
                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Back
                </button>
                {/* Buttons for Airport User */}
                <button id="airport-submit-btn" className="btn btn-space btn-success btn-xl" style={data.summaries.length !== 0 && userType === 'draft' && state.applicationStatus === 'Draft' && isVerificationRequested !== '2'  && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to submit this application?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'airport-submit-to-verifier' }) }} onClick={() => { toggleConfirmationDialogBox() }}>Submit to Verifier&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
                <button id="airport-re-submit-to-verifier-btn" className="btn btn-space btn-success btn-xl" style={data.summaries.length !== 0 && userType === 'draft' && (state.applicationStatus === 'Verifier comments - Airport to address' || state.applicationStatus === 'Admin comments - Airport to address') && visibilityBasedOnType(data.summaries[1].type, false) && isVerificationRequested !== '2' ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to re-submit this application?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'airport-re-submit-to-verifier' }) }} onClick={() => { toggleConfirmationDialogBox() }}>Re-submit to Verifier&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
                <button id="airport-re-submit-to-admin-btn" className="btn btn-space btn-success btn-xl" style={data.summaries.length !== 0 && userType === 'draft' && state.applicationStatus === 'Admin comments - Airport to address' && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to re-submit this application?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'airport-re-submit-to-admin' }) }} onClick={() => { toggleConfirmationDialogBox() }}>Re-submit to Admin&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
                <button id="airport-submit-to-admin-btn" className="btn btn-space btn-success btn-xl" style={data.summaries.length !== 0 && userType === 'draft' && state.applicationStatus === 'Draft' && state.applicationType === 'Renew' && isVerificationRequested === '2' && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to submit this application?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'airport-submit-to-admin' }) }} onClick={() => { toggleConfirmationDialogBox() }}>Submit to Admin&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} /> 
                </button>
                {/* Buttons for Verifier */}
                <button id="verifier-send-back-btn-to-airport" className="btn btn-space btn-warning btn-xl" style={data.summaries.length !== 0 && userType === 'verifier' && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to send this application back to the Airport?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'verifier-send-back-to-airport' }) }} onClick={() => { toggleConfirmationDialogBox() }}>
                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Send Back to Airport
                </button>
                <button id="verifier-submit-btn" className="btn btn-space btn-success btn-xl" style={data.summaries.length !== 0 && userType === 'verifier' && (state.applicationStatus === 'In Verification' || state.applicationStatus === 'Airport resubmitted - In Verification') && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to submit this application?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'verifier-submit-to-admin' }) }} onClick={() => { toggleConfirmationDialogBox() }}>Submit to Admin&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
                <button id="verifier-re-submit-btn" className="btn btn-space btn-success btn-xl" style={data.summaries.length !== 0 && userType === 'verifier' && state.applicationStatus === 'Admin comments - Verifier to address' && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to re-submit this application?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'verifier-re-submit-to-admin' }) }} onClick={() => { toggleConfirmationDialogBox() }}>Re-submit to Admin&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
                {/* Buttons for Admin */}
                <button id="admin-send-back-btn-to-airport" className="btn btn-space btn-warning btn-xl" style={data.summaries.length !== 0 && userType === 'admin' && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to send this application back to the Airport?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'admin-send-back-to-airport' }) }} onClick={() => { toggleConfirmationDialogBox() }}>
                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Send Back to Airport
                </button>
                <button id="admin-send-back-btn-to-verifier" className="btn btn-space btn-warning btn-xl" style={data.summaries.length !== 0 && userType === 'admin'  && data.summaries[1].summary.selectVerifier.value !== '0'  && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to send this application back to the Verifier?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'admin-send-back-to-verifier' }) }} onClick={() => { toggleConfirmationDialogBox() }}>
                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Send Back to Verifier
                </button>
                <button id="admin-submit-btn" className="btn btn-space btn-success btn-xl" disabled={userType === 'admin' && state.summaries.length !== 0 && !state.summaries[1].adminSummary.finalApproved} style={data.summaries.length !== 0 && userType === 'admin' && visibilityBasedOnType(data.summaries[1].type, false) ? {} : {display: 'none'}} onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, text: 'Are you sure you want to approve this application?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Proceed', operationType: 'admin-approves-application' }) }} onClick={() => { if(state.summaries[1].adminSummary.documents.length === 0){ setAlertDialogBoxProperties(prevState => ({ ...prevState, visibility: true, heading: 'Alert', alertText: 'You must upload an assessment form.', buttonText: 'OK' })) } else{ toggleConfirmationDialogBox() } }}>Approve&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
            </div>
            {/* Helper Components */}
            <CommonCustomModal modal={confirmationDialogBoxProperties.visibility} title='' toggler={toggleConfirmationDialogBox} heading='Confirmation Required' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <ConfirmationDialogBox data={{confirmationText: confirmationDialogBoxProperties.text, confirmationNegativeButtonText: confirmationDialogBoxProperties.negativeButtonText, confirmationPositiveButtonText: confirmationDialogBoxProperties.positiveButtonText, confirmation}} />
            </CommonCustomModal> 
        </React.Fragment>
    )
}

export default ApplicationSummary