import React, { useState} from 'react';
import { useNavigate } from "react-router-dom";


const AirportGroupExists = ({ toggler,setAirportGroupName}) => {

  const handleGroupName = () =>{
    setAirportGroupName('');
    toggler();
  }



    return (
      <div>
            <form>
              <div className="form-row">
                <label>{'The airport group name you entered already exists in our system. If you continue, your airports will be mapped to the existing group.'}<br/>{'Alternatively, you can change the group name for a new registration.'}<br/>{'Are you sure you want to continue?'}</label>     
              </div>
            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={handleGroupName}>No</button>
                <button className="btn btn-success mt-auto" type="button" onClick={toggler}>Yes</button>
            </div>
      </div>
    );
};

export default AirportGroupExists;