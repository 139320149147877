import React from 'react';
import { Link } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';


const UnAuthorizedUser = ({ apiurl }) => {

  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("roles");
    localStorage.removeItem("userID");
    signOut();
    navigate('/login');
  };
  return (

    <React.Fragment>
      <div className="page-header blue mb-4">
        <div className="container-header">
          <div className="row">
            <div className="col">
              <img src={require('../../img/ACA-logo.png')} alt="ACALogo" />
            </div>
            <div className="col-6 d-flex align-items-center justify-content-center">
              <span class="mdi mdi-minus-circle denied"></span>
              <h3 className="page-header-title"> Access Denied</h3>
            </div>
            <div className="col d-flex align-items-center justify-content-end">

            </div>
          </div>
        </div>
      </div>
      <div className="splash-container" style={{ maxWidth: "500px" }}>
        <div className="card">
          <div className="card-body text-center  p-1">
            <h4 style={{ color: "#184086",}}>It Appears that you don't have access to this page.
              Please Contact an administator</h4>
            <button
              className="btn btn-lg  btn-success mt-5"
              type="button"
              onClick={() => { handleLogOut(); }}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>

    // <h3  className ='mt-5' style={{ fontsize: '12pt', textAlign:'center' }}>
    //   <p>You do not have access to this page. Please contact the administrator.</p>
    //   <a onClick={() => { handleLogOut(); }} ><p><Link style={{ color: "blue" }} >Click here</Link> to login again.</p></a>
    // </h3>
  );
};

export default UnAuthorizedUser;