import React, { useState, useEffect } from 'react';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';


const EditSponsor = ({ sponsor, toggler, refetchs, setRefetchs}) => {

    const navigate = useNavigate();
    {/* STATES */ }
    const [refetch, setRefetch] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [clientId, setClientId] = useState("");
    const [sponsorName, setSponsorName] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [country, setcountry] = useState("");
    const [region, setregion] = useState("");
    const [formErrors, setFormErrors] = useState({});

    const userID = localStorage.getItem("userID");




    const getCountryList = async (region) => {
 
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryList}${region}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                var result = await response.json();
                setCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountryList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }

        const getRegionList = async (country) => {

            try{
              const response =  await fetch(`${config.apiUrl}${apiconfig.getRegionList}${country}`, {
                    method: 'GET',
                    headers: {
    
                        Authorization: `Bearer ${localStorage.getItem("token")}`
    
                    }
                });
                if (response.ok) {
                    var result = await response.json();
                    if(result.length===2){
                        setregion(result[1].value);
                    }
                    //setRegionList(result);
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.getRegionList}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
                }catch(error){
                    console.log(error.message);
                    navigate(`/error-page`); 
                }
            }
    
            const getAllRegionList = async () => {
    
                try{
                  const response =  await fetch(`${config.apiUrl}${apiconfig.getRegionList}${0}`, {
                        method: 'GET',
                        headers: {
        
                            Authorization: `Bearer ${localStorage.getItem("token")}`
        
                        }
                    });
                    if (response.ok) {
                        var result = await response.json();
                        setRegionList(result);
                    } else {
                        const errorText = await response.text();
                        const apiname = `${apiconfig.getRegionList}`;
                        navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                    }
                    }catch(error){
                        console.log(error.message);
                        navigate(`/error-page`); 
                    }
                }    
    
        useEffect(() => {
            if(country===''){
            getAllRegionList();
            getRegionList(0);
            }else{
            getAllRegionList();
            getRegionList(country);
            }
    
    
        }, [refetch,country]);
    
        useEffect(() => {
            if(region===''){
                getCountryList(0);
            }else{
                getCountryList(region);
            }
        }, [refetch,region]);

    {/*Validation*/}
    const validateForm = (sponsorName, address1, address2, city,zipcode,email,phone,region,country,clientId) => {

        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(clientId){
        if(clientId.length > 10){
            errors.clientId = 'Maximum of 10 characters allowed.';
        }
        }
        if(!sponsorName){
            errors.sponsorName = 'Please enter a value.';
        } else if(sponsorName.length > 256){
            errors.sponsorName = 'Maximum of 256 characters allowed.';
        }

        if(!address1){
            errors.address1 = 'Please enter a value.';
        }else if(address1.length > 256){
            errors.address1 = 'Maximum of 256 characters allowed.';
        }
        // else if (!/^[a-zA-Z0-9\s]+$/.test(address1)) {
        //     errors.address1 = 'Special characters are not allowed.';
        //   }

        if(address2.length > 0){
        if(address2.length > 256){
            errors.address2 = 'Maximum of 256 characters allowed.';
        }
        }

        if(!city){
            errors.city = 'Please enter a value.';
        }else if(city.length > 256){
            errors.city = 'Maximum of 256 characters allowed.';
        }

        if(!zipcode){
            errors.zipcode = 'Please enter a value.';
        }else if(zipcode.length > 256){
            errors.zipcode = 'Maximum of 256 characters allowed.';
        }

        if(!email){
            errors.email = 'Please enter a value.';
        }
        else if(!emailRegex.test(email)){
            errors.email = 'Please enter a valid email address.';
        }

        if(!phone){
            errors.phone = 'Please enter a value.';
        }else {
            const phoneRegex = /^\+[0-9]+([\s-]?[0-9]+)*$/;
            if (!phoneRegex.test(phone)) {
              errors.phone = 'Please enter a valid phone number.';
            }
          }
        if(!country){
            errors.country = 'Please select a value.';
        }

        if(!region){
            errors.region = 'Please select a value.';
        }

        // if(!dojp){
        //     errors.dojp = 'Please select a date.';
        // }
        // if(!verifierStatus){
        //     errors.verifierStatus = 'Please select verifier status!';
        // }
        // if(!dolv){
        //     errors.dolv = 'Date of Last Verification/Date of Approval is required!';
        // }
        return errors;
    }


    const updateSponsor = async () => {

       var tempClientID = clientId
        if(clientId === "" || clientId === null){
            tempClientID  = 0;
        }
        const requestBody = {
            id : sponsor.id,
            sponsorName: sponsorName.trim(),
            clientID : tempClientID,
            countryID:country,
            regionID:region,
            email:email.trim(),
            phoneNumber:phone.trim(),
            addressLine1: address1.trim(),
            addressLine2: address2.trim(),
            city: city.trim(),
            zipcode:zipcode.trim(),
            userID : userID,
        };
        const validationErrors = validateForm(sponsorName.trim(), address1.trim(), address2.trim(), city.trim(),zipcode.trim(),email.trim(),phone.trim(),region,country,clientId);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.editSponsor}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                  
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Sponsor updated successfully.')
            {
                toast.success('Sponsor updated successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                });   
            setRefetchs(!refetchs);
            toggler();
            }else{
                setErrorMessage(response.saveErrorMessage);
            }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };




 //get DetailsByID
 useEffect(() => {
    fetch(`${config.apiUrl}${apiconfig.getSponsorDetailsByID}${sponsor.id}`,{
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
    })
        .then((response) => response.json())
        .then((data) => {
            setClientId(data.clientID);
            setSponsorName(data.sponsorName);
            setaddress1(data.addressLine1);
            setaddress2(data.addressLine2);
            setcity(data.city);
            setzipcode(data.zipcode);
            setphone(data.phoneNumber);
            setemail(data.email);
            setcountry(data.countryID);
            setregion(data.regionID);

        }).catch((err) => {
            navigate("/error-page");
        });
}, [sponsor.id])


  const handleRegionChange = (e) => {
    setregion(e.target.value);
    if(e.target.value ===''){
        setcountry('');
    }
  };

  const handleCountryChange = (e) => {
    setcountry(e.target.value);
    if(e.target.value ===''){
        setregion('');
    }
  };


  const isNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 9) {
      event.preventDefault();
    }
  };
    return (
        <div>
    <form>
                    
    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="ClientId">Client ID </label>
                        <input type="text" onKeyPress={isNumber} value={clientId} placeholder='Please Enter Client ID...'  className="form-control form-control-xs" id="ClientId" onChange={(e) => { setClientId(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.clientId}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_VerifierName">Sponsor Name <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" value={sponsorName} placeholder='Please Enter Sponsor Name...' className="form-control form-control-xs" id="input_VerifierName" onChange={(e) => { setSponsorName(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.sponsorName}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address1">Address Line 1 <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" value={address1} placeholder='Please Enter Address Line 1...' className="form-control form-control-xs" id="input_Address1" onChange={(e) => { setaddress1(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.address1}</p>
                    </div>

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address2">Address Line 2(Opt)</label>
                        <input type="text" value={address2} placeholder='Please Enter Address Line 2...' className="form-control form-control-xs" id="input_Address2" onChange={(e) => { setaddress2(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.address2}</p>
                    </div>
                    </div>
                    <div className="form-row">

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_City">Town/City <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" value={city} placeholder='Please Enter Town/City...' className="form-control form-control-xs" id="input_City" onChange={(e) => { setcity(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.city}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Zip">Post Code/ZIP <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" value={zipcode} placeholder='Please Enter PostCode/ZIP...' className="form-control form-control-xs" id="input_Zip" onChange={(e) => { setzipcode(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.zipcode}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_PhoneNumber">Phone Number <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" value={phone} placeholder='Please Enter Phone Number...' className="form-control form-control-xs" id="input_PhoneNumber" onChange={(e) => { setphone(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.phone}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Email">Email Address <span className="text-danger font-weight-bold">*</span></label>
                        <input type="email" value={email} placeholder='Please Enter Email Address...' className="form-control form-control-xs" id="input_Email" onChange={(e) => { setemail(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.email}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                    <label htmlFor="input_Region">Region <span className="text-danger font-weight-bold">*</span></label>
                        <select id="input_Region" className="form-control form-control-sm" value={region} onChange={handleRegionChange}>
                            {regionList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.region}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Country">Country <span className="text-danger font-weight-bold">*</span></label>
                        <select id="input_Country" className="form-control form-control-sm" value={country} onChange={handleCountryChange}>
                            {countryList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.country}</p>
                    </div>
                    </div>
                    {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )}

                   
                    
                    </form>
                    <div className="modal-footer">
                    <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                    <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                    <button className="btn btn-success mt-auto" type="button" onClick={updateSponsor}>Submit</button>
                  </div>
                  </div>
    );
};

export default EditSponsor;