import React, { useState, useEffect } from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Select from "react-select";

const AddAirport = ({ toggler, airportgpID, refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [iataCode, setIataCode] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [airportNameLocal, setAirportNameLocal] = useState("");
    const [airportNameEnglish, setAirportNameEnglish] = useState("");
    const [generalAviationAirport, setGeneralAviationAirport] = useState("");
    const [yearJoinedProgram, setYearJoinedProgram] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [iataCodeList, setIataCodeList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");




    const addAirport = async () => {

        const requestBody = {
            "airportID": iataCode,
            "airportGroupID" : airportgpID,
            "userID": userID,
            "RequestType":'ADD',

        };
        const validationErrors = validateForm(iataCode);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.addAirport}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                    if (response.ok) {

                        var result = await response.json();
                        if(result.saveErrorMessage === 'Airport added with AirportGroup.')
                        {
                            toast.success('Airport added successfully with AirportGroup.', {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Slide
                            }); 
                        setRefetchs(!refetchs);
                        toggler();
                        }else{
                            setErrorMessage(result.saveErrorMessage);
                        }
                    } else {
                        const errorText = await response.text();
                        const apiname = `${apiconfig.addAirport}`;
                        navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                    }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
        }
    };

    const getAirportDetailsByIATA = async (airportID) => {
        try{
        const response = await fetch(`${config.apiUrl}${apiconfig.getAirportDetailsByIata}${airportID}`, {
            method: "GET",
            headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`  
            }
        });
        if (response.ok) {
            const result = await response.json();
            setRegion(result[0].region);
            setCountry(result[0].country);
            setCity(result[0].city);
            setAirportNameEnglish(result[0].airportName_English);
            setAirportNameLocal(result[0].airportName_Local);
            setGeneralAviationAirport(result[0].isGeneral_AviationAirport);
            setYearJoinedProgram(result[0].yearJoinedProgram);

        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getAirportDetailsByIata}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
        }; 
        
    const handleIataCodeChange = (e) => {
        setIataCode(e.target.value);
        getAirportDetailsByIATA(e.target.value);
    };    

    {/*Validation*/ }
    const validateForm = (iataCode) => {
        const errors = {};
        if (!iataCode) {
            errors.iataCode = 'Please select IATA code.';
        }
        return errors;
    }

    useEffect(() => {
        const getIataCodeList = async () => { 
            try {
            const response = await  fetch(`${config.apiUrl}${apiconfig.getIataCode}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setIataCodeList(result);

            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getIataCode}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
        getIataCodeList();
    }, []);


    return (
        <div>
            <form>
                <div className="form-row">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="iataCode">IATA Code :</label>
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="iataCode" className="form-control form-control-sm"   onChange={handleIataCodeChange} >
                            {iataCodeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        </div>
                        <p style={{color:'red'}}>{formErrors.iataCode}</p>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="region">Region :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={region} disabled/>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="country">Country :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={country}  disabled/>
                        </div>
                </div>
                {/* <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="country_Code">Country Code :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={countryCode}  disabled/>
                        </div>
                </div> */}
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="city">City :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={city}  disabled/>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="airportnameLocal">Airport Name (Official Local) :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={airportNameLocal}  disabled/>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="airportnameEnglish">Airport Name (Official English) :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={airportNameEnglish}  disabled/>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="generakAviation">General Aviation Airport? :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={generalAviationAirport}  disabled/>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="yearJoined">Year Joined Program :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" type="text" value={yearJoinedProgram}  disabled/>
                        </div>
                </div>
                {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )} 
            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="150"></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                <button className="btn btn-success mt-auto" type="button"  onClick={addAirport} >Save</button>
            </div>
        </div>
    );
};

export default AddAirport;