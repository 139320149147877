import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ children, title, hasButton, toggler, icon, buttonText,title1,hasLinkButton1,toggler1,buttonText1 }) => {

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        {
                            hasButton === true
                                ?
                                <>
                                {title}<br></br>
                                <span style={{ fontsize: '11pt', lineHeight : 2 }}>{title1}</span>
                                    <div className="tools">
                                        <button className="btn mr-1 btn-primary btn-sm" type="button" onClick={toggler}>{icon}&nbsp;{buttonText}
                                        </button>
                                    </div>
                                </>
                                :
                                <>
                                    {title}
                                </>
                        }
                        {
                            hasLinkButton1 === true
                                ?
                                <>
                                    <div className="tools">
                                    <Link to={toggler1}> <button className="btn mr-1 btn-primary btn-sm" type="button" >{buttonText1}
                                        </button></Link>
                                    </div>
                                </>
                                :
                                <>
                                </>
                        }
                    </div>
                    <div className="card-body" style={{maxHeight:'400px', overflow:'scroll'}}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Card;