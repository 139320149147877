import React, { useState, useEffect } from 'react'
import { htmlConverterReact } from 'html-converter-react'
import CommonCustomModal from '../../Common/CommonCustomModal'
import ConfirmationDialogBox from '../../Common/ConfirmationDialogBox'
import { Slide, ToastContainer, toast } from 'react-toastify'
import { Tooltip } from 'react-tooltip'

const Other = ({ index, subSectionIndex, sectionIndex, data, state, setState, userType, questionRefs, calculateQuestionRefIndex, visibilityBasedOnType, setAlertDialogBoxProperties, setTemporarilyDisallowSubSectionSubmission, setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert, reCalculateTotalAtRunTime, setReCalculateTotalAtRunTime }) => {

    /* states */
    const [confirmationDialogBoxProperties, setConfirmationDialogBoxProperties] = useState({ visibility: false, heading: '', text: '', negativeButtonText: '', positiveButtonText: '' })
    const [inputProperties, setInputProperties] = useState({ action: '', value: '', item_index: 0, response_index: 0 })
    // the state `hasImpactorsImpactRemovedFromTarget` plays a crucial role in the `calculateTargetViaEventListener` approach
    const [hasImpactorsImpactRemovedFromTarget, setHasImpactorsImpactRemovedFromTarget] = useState(false)
    // the below state `haveNumberOfResponsesChanged` can be utilized wherever there needs to be done something if there's a change in the no. of responses (add or delete)
    const [haveNumberOfResponsesChanged, setHaveNumberOfResponsesChanged] = useState(false)
    // trigger state to calculate total: part of `calculateTotal` approach
    const [reCalculateTotal, setReCalculateTotal] = useState(false)
    /* 
        the below state is a trigger state, used to trigger an useEffect defined below somewhere
        allows to re-configure recorded response's dropdown field's options
        part of `crossQuestionSelectiveReplication` approach but not limited to this approach
        can be utilized in scenarios where `crossQuestionSelectiveReplication` is not being employed 
    */
    const [reconfigureDropdownOptionsOfRecordedResponses, setReconfigureDropdownOptionsOfRecordedResponses] = useState(false)
    // the below state `reconfigureDropdownOptionsOfRecordedResponsesAtRunTime` is a run time counter-part of the above defined `reconfigureDropdownOptionsOfRecordedResponses` state and it employs the same mechanisms
    const [reconfigureDropdownOptionsOfRecordedResponsesAtRunTime, setReconfigureDropdownOptionsOfRecordedResponsesAtRunTime] = useState(true)

    /* methods */
    const confirmation = async (response) => {
        switch(response){
            case 'positive':
                updateState(inputProperties.action, inputProperties.value, inputProperties.item_index, inputProperties.response_index)
                break
            case 'negative':
                // nothing to do here
                break
            default:
                break
        }
        toggleConfirmationDialogBox()
    }
    const toggleConfirmationDialogBox = () => { 
        setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, visibility: !confirmationDialogBoxProperties.visibility})
    } 
    const updateState = (action, value, item_index, response_index) => {
        const updatedState = {...state}
        let responses = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.responses
        switch(action){
            case 'add':
                // configure `calculateTargetViaEventListener` here
                let schema = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.schema
                for(const input of schema){
                    if(input.isCalculated && input.calculateTargetViaEventListener !== null && input.calculateTargetViaEventListener.length !== 0){
                        if(input.calculateTargetViaEventListener.calculationBasedOn !== null && input.calculateTargetViaEventListener.calculationBasedOn.length !== 0){
                            for(const item of input.calculateTargetViaEventListener.calculationBasedOn){
                                if(item.questionID === data.id)
                                    item.responseIndex = responses.length.toString()
                            }
                        }
                        if(input.calculateTargetViaEventListener.impactBasedOn !== null && input.calculateTargetViaEventListener.impactBasedOn.length !== 0){
                            for(const impactor of input.calculateTargetViaEventListener.impactBasedOn){
                                if(impactor.questionID === data.id)
                                    impactor.responseIndex = responses.length.toString()
                            }
                        }
                    }
                }
                responses.push({ 
                    id: (responses.length + 1).toString(),
                    inputs: JSON.parse(JSON.stringify(schema))
                })
                break
            case 'remove':
                responses.splice(response_index, 1)
                
                // re-configure `calculateTargetViaEventListener` here
                for(const response of responses){
                    for(const input of response.inputs){
                        if(input.isCalculated && input.calculateTargetViaEventListener !== null && input.calculateTargetViaEventListener.length !== 0){
                            if(input.calculateTargetViaEventListener.calculationBasedOn !== null && input.calculateTargetViaEventListener.calculationBasedOn.length !== 0){
                                for(const item of input.calculateTargetViaEventListener.calculationBasedOn){
                                    if(item.questionID === data.id)
                                        item.responseIndex = responses.indexOf(response).toString()
                                }
                            }
                            if(input.calculateTargetViaEventListener.impactBasedOn !== null && input.calculateTargetViaEventListener.impactBasedOn.length !== 0){
                                for(const impactor of input.calculateTargetViaEventListener.impactBasedOn){
                                    if(impactor.questionID === data.id)
                                        impactor.responseIndex = responses.indexOf(response).toString()
                                }
                            }
                        }
                    }
                }

                // set `haveNumberOfResponsesChanged` as true since a response was deleted & set `reCalculateTotal` as true as well to trigger re-calculation
                setHaveNumberOfResponsesChanged(true)
                setReCalculateTotal(true)
                
                // re-assign id(s)
                responses = responses.map((response, response_index) => {
                    return {
                        ...response,
                        id: (response_index + 1).toString()
                    }
                })
                break
            case 'update':
                // only if input is of type `dropdown`
                if(responses[response_index].inputs[item_index].type === 'dropdown'){
                    if(value === '0')
                        responses[response_index].inputs[item_index].value = ''
                    else{
                        if(responses[response_index].inputs[item_index].uniqueOptionSelectionRestriction){ // if this parameter is `true` then the logic (unique option selection restriction) within would apply
                            if(checkIfSelectedOptionIsValid(responses, item_index, value)) // check whether the selected option is valid
                                responses[response_index].inputs[item_index].value = value
                            else{
                                if(!toast.isActive('invalid-option')) // if toast is inactive then show the toast
                                    toast.info('This option has already been selected. Please select a different option.', { toastId: 'invalid-option', position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
                                else                                  // if toast is active then update the toast with timer
                                    toast.update('invalid-option', { autoClose: 5000 })
                            }
                        }
                        else
                            responses[response_index].inputs[item_index].value = value  
                    }
                }
                else    
                    responses[response_index].inputs[item_index].value = value
                break
            case 'update-secondary-input':
                if(responses[response_index].inputs[item_index].options.find(option => option.value === responses[response_index].inputs[item_index].value).secondaryInputType === 'dropdown'){
                    if(value === '0')
                        responses[response_index].inputs[item_index].options.find(option => option.value === responses[response_index].inputs[item_index].value).secondaryInputValue = ''
                    else
                        responses[response_index].inputs[item_index].options.find(option => option.value === responses[response_index].inputs[item_index].value).secondaryInputValue = value
                }
                else
                    responses[response_index].inputs[item_index].options.find(option => option.value === responses[response_index].inputs[item_index].value).secondaryInputValue = value
                break
            default:
                break
        }
        updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.responses = responses

        if(action === 'update'){
            /* 
                Logic to calculate target question/input value based on current input value aided with possibly multiple different question/input value 
                This logic is not currently applicable at sub-question level
                This logic can only be utilized for response type 'text' and 'textarea'
            */
            if(responses[response_index].inputs[item_index].calculateTargets !== null){
                if(responses[response_index].inputs[item_index].calculateTargets.length !== 0){
                    for(const calculateTarget of responses[response_index].inputs[item_index].calculateTargets){
                        // this variable will keep track if calculation of target question's/input's value is possible 
                        let isCalculationValid = false
                        const orderValueHashMap = new Map()
                        /*
                            check whether the current (responses's input) question belongs to the same subsection as the target question or not
                            if it indeed belongs to the same subsection then use the local value for calculation 
                            if it does not, then utilize the lastSuccessfullySavedResponseOther's value for calculation
                        */
                        if(updatedState.sections[sectionIndex].subsections[subSectionIndex].id === calculateTarget.target.subsectionID){
                            // insert current question's order & local response in hashmap 
                            orderValueHashMap.set(parseInt(calculateTarget.order), parseInt(responses[response_index].inputs[item_index].value))
                        }
                        else{
                            // insert current question's order & lastSuccessfullySavedResponseOther in hashmap making sure that lastSuccessfullySavedResponseOther exists (it should not be empty or null)
                            if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].lastSuccessfullySavedResponseOther !== null){
                                if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].lastSuccessfullySavedResponseOther.length !== 0)
                                    orderValueHashMap.set(parseInt(calculateTarget.order), parseInt(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].lastSuccessfullySavedResponseOther.responses[response_index].inputs[item_index].value))
                            }
                        }
                        // iterate through peer dependencies over target via 'calculateWith' array of objects
                        for(const peer of calculateTarget.calculateWith){
                            // extract peer question inside `peerQuestion` via navigating through the json
                            const peerQuestion = state.sections.find(section => section.id === peer.sectionID).subsections.find(subsection => subsection.id === peer.subsectionID).content.questions.find(question => question.id === peer.questionID)
                            /*
                                check whether the peer belongs to the same subsection as the target question or not
                                if it indeed belongs to the same subsection then use the local value for calculation 
                                if it does not, then utilize the lastSuccessfullySavedResponse's/lastSuccessfullySavedResponseOther's value for calculation
                            */
                            if(peer.subsectionID === calculateTarget.target.subsectionID){
                                // insert peer question's order & local response in hashmap based on 'responseType' 
                                if(peerQuestion.responseType === 'other')
                                    orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.other.responses.find(response => response.id === peer.responseID).inputs.find((input, input_index) => input_index === parseInt(peer.inputIndex)).value))
                                else
                                    orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.response))
                            }
                            else{
                                // insert peer question's order & lastSuccessfullySavedResponse/lastSuccessfullySavedResponseOther in hashmap based on 'responseType' making sure that the response exists (it should not be empty or null)
                                if(peerQuestion.responseType === 'other'){
                                    if(peerQuestion.lastSuccessfullySavedResponseOther !== null){
                                        if(peerQuestion.lastSuccessfullySavedResponseOther.length !== 0){
                                            if(peerQuestion.lastSuccessfullySavedResponseOther.responses !== null){
                                                if(peerQuestion.lastSuccessfullySavedResponseOther.responses.length !== 0)
                                                    orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.lastSuccessfullySavedResponseOther.responses.find(response => response.id === peer.responseID).inputs.find((input, input_index) => input_index === parseInt(peer.inputIndex)).value))
                                            }
                                        }  
                                    }
                                } 
                                else{
                                    if(peerQuestion.lastSuccessfullySavedResponse !== null){
                                        if(peerQuestion.lastSuccessfullySavedResponse.length !== 0)
                                            orderValueHashMap.set(parseInt(peer.order), parseInt(peerQuestion.response))
                                    }
                                } 
                            }
                        }
                        // pass 'operation' and the values & size of 'orderValueHashMap' along with 'calculateTarget.calculateWith.length' as parameters into the `checkIfCalculationOfTargetIsPossible` method which will return either 'true' or 'false' on the basis of some internal logic 
                        isCalculationValid = checkIfCalculationOfTargetIsPossible(calculateTarget.operation, orderValueHashMap.values(), orderValueHashMap.size, calculateTarget.calculateWith.length)
                        // if `isCalculationValid` is true, then perform the calculation of target question's/input's value via `calculateTargetValue` method 
                        if(isCalculationValid){
                            const calculatedTargetValue = calculateTargetValue(calculateTarget.operation, orderValueHashMap)
                            const targetQuestion = state.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID)
                            if(targetQuestion.responseType === 'other'){
                                if(calculatedTargetValue === 'NaN' || calculatedTargetValue === 'NaN %')
                                    updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).other.responses.find(response => response.id === calculateTarget.target.responseID).inputs.find((input, input_index) => input_index === parseInt(calculateTarget.target.inputIndex)).value = ''
                                else
                                    updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).other.responses.find(response => response.id === calculateTarget.target.responseID).inputs.find((input, input_index) => input_index === parseInt(calculateTarget.target.inputIndex)).value = calculatedTargetValue
                                // set the 'isUpdated' of target response input to 'true' 
                                updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).other.responses.find(response => response.id === calculateTarget.target.responseID).inputs.find((input, input_index) => input_index === parseInt(calculateTarget.target.inputIndex)).isUpdated = true
                            }
                            else{
                                if(calculatedTargetValue === 'NaN' || calculatedTargetValue === 'NaN %')
                                    updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).response = ''
                                else
                                    updatedState.sections.find(section => section.id === calculateTarget.target.sectionID).subsections.find(subsection => subsection.id === calculateTarget.target.subsectionID).content.questions.find(question => question.id === calculateTarget.target.questionID).response = calculatedTargetValue
                            }   
                        }
                    }
                }
            }
        }

        /*
            this block of code ensures that for actions such as 'add' or 'remove' or 'update' the `reconfigureDropdownOptionsOfRecordedResponses` approach is usable
            and that it is not tightly coupled with the `crossQuestionSelectiveReplication` 
        */
        if(action === 'add' || action === 'remove' || action === 'update'){
            if(data.other.reconfigureDropdownOptionsOfRecordedResponses !== null){
                if(data.other.reconfigureDropdownOptionsOfRecordedResponses.isApplicable)
                    setReconfigureDropdownOptionsOfRecordedResponses(true)
            }
        }

        setState(updatedState)
    }
    const checkIfCalculationOfTargetIsPossible = (operation, values, size, totalPeers) => { 
        if(size === 0)
            return false
        if(size !== totalPeers + 1)
            return false
        for(const value of values){
            if((operation === 'division' || operation === 'percentage' || operation === 'difference-based-percentage') && value < 1)
                return false
            if((operation === 'difference' || operation === 'division' || operation === 'percentage' || operation === 'difference-based-percentage') && size !== 2)
                return false
        }
        return true
    }
    const calculateTargetValue = (operation, map) => {
        // Convert map to array of key-value pairs and sort by keys
        const sortedEntries = [...map.entries()].sort((a, b) => a[0] - b[0])
        // Create a new Map from the sorted array of key-value pairs
        const sortedMap = new Map(sortedEntries)
        switch(operation){
            case 'sum':
                let sum = 0
                for(const value of sortedMap.values())
                    sum += value
                return sum.toString()
            case 'difference':
                return ([...sortedMap.values()][0] - [...sortedMap.values()][1]).toString()
            case 'multiplication':
                let result = 1
                for(const value of sortedMap.values())
                    result *= value
                return result.toString()
            case 'division':
                return ([...sortedMap.values()][0] / [...sortedMap.values()][1]).toString()
            case 'average':
                let average = 0
                for(const value of sortedMap.values())
                    average += value
                average /= [...sortedMap.values()].length
                return average.toString()
            case 'percentage':
                let percentage = (([...sortedMap.values()][0] / [...sortedMap.values()][1]) * 100).toString() + ' %'
                if([...sortedMap.values()][0] < [...sortedMap.values()][1])
                    percentage = '- ' + percentage
                return percentage
            case 'difference-based-percentage':
                let difference_based_percentage = parseInt((([...sortedMap.values()][0] - [...sortedMap.values()][1]) / [...sortedMap.values()][0]) * 100).toString() + ' %'
                return difference_based_percentage
            case 'custom-percentage':
                let custom_percentage = parseFloat([...sortedMap.values()][0] / ([...sortedMap.values()][1] - ([...sortedMap.values()][2] + [...sortedMap.values()][3])))
                // round off upto two decimal places
                custom_percentage = (Number(custom_percentage.toFixed(2)) * 100).toString() + ' %'
                return custom_percentage
            default:
                return null
        }
    }
    const calculateTotalBasedOnSameAndCrossSubSectionalQuestions = (summationColumnIndex, inputIndexToBeCheckedForNA, items, expression, operation) => {
        // variable to represent the sum of all input values at a particular index of all responses
        let summation = 0
        // iterate through each input of all responses (at a particular index) 
        for(const response of data.other.responses){
            for(const input of response.inputs){
                if(response.inputs.indexOf(input) === parseInt(summationColumnIndex)){
                    if(input.value.length !== 0 && response.inputs[inputIndexToBeCheckedForNA].value !== 'N/A'){
                        // The parseInt() function parses a string and returns an integer. It stops parsing when it encounters a character that is not a valid part of a number.
                        summation += parseInt(input.value)
                    }
                }
            }
        }
        // final value post calculation will be stored in this variable
        let calculatedValue = ''
        // variable to check for validation before performing crucial logic
        let isValid = true
        // initialize an empty array that would be used to store values of valid items, before performing calculation
        let itemValues = []
        // iterate over items
        for(const item of items){
            if(state.sections[sectionIndex].subsections[subSectionIndex].id === state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).id){
                // item belongs to same sub-section
                if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                    // response type `other`
                    let value = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).other.responses.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex)).value
                    if(value !== null && value.length !== 0)
                        itemValues.push(parseInt(value))
                }
                else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                    // response type `text` or `textarea` or `dropdown`
                    let value = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).response
                    if(value !== null && value.length !== 0)
                    itemValues.push(parseInt(value))
                }
            }
            else{
                // item belongs to different sub-section (cross-sub-section)
                if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                    // response type `other`
                    // utilize `lastSuccessfullySavedResponseOther`
                    let lastSuccessfullySavedResponseOther = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponseOther
                    if(lastSuccessfullySavedResponseOther !== null && lastSuccessfullySavedResponseOther.length !== 0){
                        let value = lastSuccessfullySavedResponseOther.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex)).value
                        if(value !== null && value.length !== 0)
                            itemValues.push(parseInt(value))
                    }
                }
                else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                    // response type `text` or `textarea` or `dropdown`
                    // utilize `lastSuccessfullySavedResponse`
                    let value = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponse
                    if(value !== null && value.length !== 0)
                        itemValues.push(parseInt(value))
                }
            }
        }
        // insert summation as an entry in `itemValues`
        itemValues.push(summation)
        // check for validity here
        if(itemValues.length !== items.length + 1)
            isValid = false
        // if `isValid` is true, then proceed futher otherwise return default value of `calculatedValue`
        if(isValid){
            // perform calculations...
            calculatedValue = calculateTargetValueBasedOnIndexMappedValueArrayAndCalculationExpressionArray(itemValues, expression)
        }
        // return empty value i.e. '' when `isValid` is false
        else
            return ''
        if(operation === 'percentage')
            calculatedValue = Math.round(parseFloat(calculatedValue) * 100)
        return calculatedValue.toString()
    }
    const checkIfAnyInputHasValueAtRunTime = () => {
        for(const response of data.other.responses){
            for(const input of response.inputs){
                if(response.inputs.indexOf(input) === parseInt(data.other.calculateTotal.columnIndex)){
                    if(input.value.length !== 0)
                        return true
                }
            }
        }
        return false
    }
    const checkboxValidation = (responseIndex, leastNumberOfCheckboxesRequiredToBeChecked) => {
        /*  
            Using this method we can determine if the validation message needs to be shown on the UI based on the logic defined within
            Logic:
               -> for a particular row of responses (can be accessed via `responseIndex`) again this question
               -> if input(s) of type `checkbox` exists with an additional value in `leastNumberOfCheckboxesRequiredToBeChecked`
               -> then validation message should be visible if the condition is not met
            As a result of this, the whole question should be considered as not answered with the usual display of validation message 'This response is Mandatory!'      
        */
        if(leastNumberOfCheckboxesRequiredToBeChecked > 0){
            let numberOfCheckboxesChecked = 0
            for(const input of data.other.responses[responseIndex].inputs)
                numberOfCheckboxesChecked = (input.type === 'checkbox' && input.value === 'Yes') ? numberOfCheckboxesChecked + 1 : numberOfCheckboxesChecked
            if(numberOfCheckboxesChecked < leastNumberOfCheckboxesRequiredToBeChecked)
                return true
        }
        return false
    }
    const checkIfSelectedOptionIsValid = (responses, inputIndex, value) => {
        for(const response of responses){
            if(response.inputs[inputIndex].value === value)
                return false
        }
        return true
    }
    const hasTheTargetValueImpactedByAnyImpactor = (targetValue, impactors) => {
        for(const impactor of impactors){
            if(targetValue === impactor.setTargetValueAs)
                return true
        }
        return false
    }
    const calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions = (items, expression, operation) => {
        // final value post calculation will be stored in this variable
        let calculatedValue = ''
        // variable to check for validation before performing crucial logic
        let isValid = true
        // initialize an empty array that would be used to store values of valid items, before performing calculation
        let itemValues = []
        // iterate over items
        for(const item of items){
            if(state.sections[sectionIndex].subsections[subSectionIndex].id === state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).id){
                // item belongs to same sub-section
                if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                    // response type `other`
                    let value = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).other.responses.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex)).value
                    if(value !== null && value.length !== 0)
                        itemValues.push(parseInt(value))
                }
                else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                    // response type `text` or `textarea` or `dropdown`
                    let value = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).response
                    if(value !== null && value.length !== 0)
                    itemValues.push(parseInt(value))
                }
            }
            else{
                // item belongs to different sub-section (cross-sub-section)
                if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                    // response type `other`
                    // utilize `lastSuccessfullySavedResponseOther`
                    let lastSuccessfullySavedResponseOther = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponseOther
                    if(lastSuccessfullySavedResponseOther !== null && lastSuccessfullySavedResponseOther.length !== 0){
                        let value = lastSuccessfullySavedResponseOther.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex)).value
                        if(value !== null && value.length !== 0)
                            itemValues.push(parseInt(value))
                    }
                }
                else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                    // response type `text` or `textarea` or `dropdown`
                    // utilize `lastSuccessfullySavedResponse`
                    let value = state.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponse
                    if(value !== null && value.length !== 0)
                        itemValues.push(parseInt(value))
                }
            }
        }
        //console.log('item-values: ', itemValues)
        // check for validity here
        if(itemValues.length !== items.length)
            isValid = false
        // if `isValid` is true, then proceed futher otherwise return default value of `calculatedValue`
        if(isValid){
            // perform calculations...
            console.log('calculation-expression-nested-array: ', expression)
            calculatedValue = calculateTargetValueBasedOnIndexMappedValueArrayAndCalculationExpressionArray(itemValues, expression)
        }
        // return empty value i.e. '' when `isValid` is false
        else
            return ''
        if(operation === 'percentage')
            calculatedValue = Number((parseFloat(calculatedValue) * 100).toFixed(2)) + ' %'
        console.log('calculated-value: ', calculatedValue)
        return calculatedValue
    }
    const calculateTargetValueBasedOnIndexMappedValueArrayAndCalculationExpressionArray = (indexMappedValues, calculationExpression) => {
        // declaring operands
        let leftOperand = 0
        let rightOperand = 0

        // calculate left operand - recursively
        if(typeof(calculationExpression[0]) === 'object')
            leftOperand = calculateTargetValueBasedOnIndexMappedValueArrayAndCalculationExpressionArray(indexMappedValues, calculationExpression[0])
        else if(typeof(calculationExpression[0]) === 'string')
            leftOperand = indexMappedValues[parseInt(calculationExpression[0])]
        
        // calculate right operand - recursively
        if(typeof(calculationExpression[2]) === 'object')
            rightOperand = calculateTargetValueBasedOnIndexMappedValueArrayAndCalculationExpressionArray(indexMappedValues, calculationExpression[2])
        else if(typeof(calculationExpression[2]) === 'string')
            rightOperand = indexMappedValues[parseInt(calculationExpression[2])]

        switch(calculationExpression[1]){
            case '+':
                return parseFloat(leftOperand + rightOperand).toString()
            case '-':
                return parseFloat(leftOperand - rightOperand).toString()
            case '*':
                return parseFloat(leftOperand * rightOperand).toString()
            case '/':
                return parseFloat(leftOperand / rightOperand).toString()
            default:
                break
        }
    }
    const replicate = (replicationValue) => {

        // extract `data.other.responses` into mutable variable `responses`
        let responses = data.other.responses

        // make sure that `replicationValue` is not the same as no. of responses
        if(replicationValue !== responses.length){
            
            // when `replicationValue` is less than the existing no. of responses
            if(replicationValue < responses.length){
                // in this case: (responses.length - replicationValue) no. of responses would be deleted starting from last index
                let updatedResponses = []
                let index = 0
                while(index <= replicationValue - 1){
                    updatedResponses.push(responses[index])
                    index++
                }
                responses = updatedResponses

                // re-configure `calculateTargetViaEventListener` here if applicable
                for(const response of responses){
                    for(const input of response.inputs){
                        if(input.isCalculated && input.calculateTargetViaEventListener !== null && input.calculateTargetViaEventListener.length !== 0){
                            if(input.calculateTargetViaEventListener.calculationBasedOn !== null && input.calculateTargetViaEventListener.calculationBasedOn.length !== 0){
                                for(const item of input.calculateTargetViaEventListener.calculationBasedOn){
                                    if(item.questionID === data.id)
                                        item.responseIndex = responses.indexOf(response).toString()
                                }
                            }
                            if(input.calculateTargetViaEventListener.impactBasedOn !== null && input.calculateTargetViaEventListener.impactBasedOn.length !== 0){
                                for(const impactor of input.calculateTargetViaEventListener.impactBasedOn){
                                    if(impactor.questionID === data.id)
                                        impactor.responseIndex = responses.indexOf(response).toString()
                                }
                            }
                        }
                    }
                }

                // set `haveNumberOfResponsesChanged` as true since response(s) was(were) deleted & set `reCalculateTotal` as true as well to trigger re-calculation
                setHaveNumberOfResponsesChanged(true)
                setReCalculateTotal(true)
                
                // this block of code will ensure that the `reconfigureDropdownOptionsOfRecordedResponses` functionality works as expected if it is required as part of the `crossQuestionSelectiveReplication` approach
                if(data.other.reconfigureDropdownOptionsOfRecordedResponses !== null){
                    if(data.other.reconfigureDropdownOptionsOfRecordedResponses.isApplicable)
                        setReconfigureDropdownOptionsOfRecordedResponses(true)
                }
            }
            // when `replicationValue` is more than the existing no. of responses
            else{
                // in this case: (replicationValue - responses.length) no. of responses would be added at the last
                let schema = state.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.schema
                
                // addition logic comes here...
                let counter = replicationValue - responses.length
                while(counter > 0){
                    responses.push({ 
                        id: (responses.length + 1).toString(),
                        inputs: JSON.parse(JSON.stringify(schema))
                    })
                    if(data.other.title === "Interim Milestones")
                        responses[responses.length - 1].inputs[0].value = (responses.length).toString()
                    counter--
                }

                // re-configure `calculateTargetViaEventListener` here if applicable
                for(const response of responses){
                    for(const input of response.inputs){
                        if(input.isCalculated && input.calculateTargetViaEventListener !== null && input.calculateTargetViaEventListener.length !== 0){
                            if(input.calculateTargetViaEventListener.calculationBasedOn !== null && input.calculateTargetViaEventListener.calculationBasedOn.length !== 0){
                                for(const item of input.calculateTargetViaEventListener.calculationBasedOn){
                                    if(item.questionID === data.id)
                                        item.responseIndex = responses.indexOf(response).toString()
                                }
                            }
                            if(input.calculateTargetViaEventListener.impactBasedOn !== null && input.calculateTargetViaEventListener.impactBasedOn.length !== 0){
                                for(const impactor of input.calculateTargetViaEventListener.impactBasedOn){
                                    if(impactor.questionID === data.id)
                                        impactor.responseIndex = responses.indexOf(response).toString()
                                }
                            }
                        }
                    }
                }

                // this block of code will ensure that the `reconfigureDropdownOptionsOfRecordedResponses` functionality works as expected if it is required as part of the `crossQuestionSelectiveReplication` approach
                if(data.other.reconfigureDropdownOptionsOfRecordedResponses !== null){
                    if(data.other.reconfigureDropdownOptionsOfRecordedResponses.isApplicable)
                        setReconfigureDropdownOptionsOfRecordedResponses(true)
                }
            }
        }

        // return updated responses
        return responses
    }
    const reconfigureDropdownOptions = (schema, responses, inputIndex) => {
        
        // initialize a new array namely `updatedResponses` that will store updated responses as per further processing
        let updatedResponses = []
        
        // initialize a `Map` object namely `responseIndexToOptionIndexMapping` to keep a mapping between response's index and their dropdown's selected option's index
        let responseIndexToOptionIndexMapping = new Map()

        // iterate through `responses` via indexing
        for(let index = 0; index < responses.length; index++){
            
            // insert each reponse into `updatedResponses`
            updatedResponses.push(responses[index])
            
            // add response index as key and dropdown's selected option's index as value in `responseIndexToOptionIndexMapping` based on below condition
            if(responses[index].inputs[inputIndex].value.length !== 0)
                responseIndexToOptionIndexMapping.set(index, schema[inputIndex].options.findIndex(option => option.value === responses[index].inputs[inputIndex].value))
        }
        
        // for each response in `updatedResponses`, update the response's drodown's options by calling `configureOptionsByRange` with appropriate parameters
        for(const response of updatedResponses)
            response.inputs[inputIndex].options = configureOptionsByRange(schema[inputIndex].options, updatedResponses.length, updatedResponses.indexOf(response), responseIndexToOptionIndexMapping)

        return updatedResponses
    }
    const configureOptionsByRange = (options, responseCount, currentResponseIndex, responseIndexToOptionIndexMapping) => {
        
        // initially, calculate the normal range for `currentResponseIndex` without considering any selection scenarios
        let range = options.length - (responseCount - (currentResponseIndex + 1))
        
        // initialize below variables that will aid in conditional insertion of applicable options against current response's dropdown
        let leftRangeBalancer = 0
        let rightRangeBalancer = 0

        // check if the size of the `Map` is not 0
        if(responseIndexToOptionIndexMapping.size !== 0){
            
            // initialize below variables as `null` for left and right nearest response index respectively 
            let leftNearestResponse = null
            let rightNearestResponse = null

            // iterate through the `Map`
            for(const [response_index, option_index] of responseIndexToOptionIndexMapping) {
                /*
                    find the nearest response via `key` in both directions (i.e. left and right) which has a mapping in this `Map` i.e. that response's dropdown has an option selected
                    since the `Map` object in JavaScript maintains the order in which (key, value) pairs were added to it
                    thus we don't need to explicitly arrange or sort them in a particular order
                */

                // by updating `leftNearestResponse` for every `response_index` that is less than `currentResponseIndex` we can find the left nearest response that exists in the `Map` object
                if(response_index < currentResponseIndex)
                    leftNearestResponse = { responseIndex: response_index, optionIndex: option_index }
                
                // `rightNearestResponse` needs to be set only one time unlike `leftNearestResponse` where we were required to update it's value to find the left nearest response
                if(rightNearestResponse === null && (currentResponseIndex < response_index))
                    rightNearestResponse = { responseIndex: response_index, optionIndex: option_index }
                
                //console.log(`Response Index: ${response_index}, Option Index: ${option_index}`)
            }

            if(leftNearestResponse !== null){
                // when the left nearest response exists in `Map` object
                // the `range` needs to be trimmed from the left end
                // trimming of range from the left end solely depends on the fact whether the left nearest response's mapped option index lies within the range of current response
                if(leftNearestResponse.optionIndex >= currentResponseIndex + 1)
                    leftRangeBalancer = (leftNearestResponse.optionIndex - (currentResponseIndex + 1)) + 1
            }
            if(rightNearestResponse !== null){
                // when the right nearest response exists in `Map` object
                // the `range` needs to be trimmed from the right end
                // trimming of range from the right end solely depends on the fact whether the right nearest response's mapped option index lies within the range of current response
                if(rightNearestResponse.optionIndex <= range - 1)
                    rightRangeBalancer = ((range - 1) - rightNearestResponse.optionIndex) + 1  
            }

        }

        // initialize a new array namely `updatedOptions` within which only the applicable options would be added to ensure the functionality works as expected
        let updatedOptions = []
        
        // insert the option with label `--------` as it is needed in each dropdown
        updatedOptions.push(options[0])

        //console.log('currentResponseIndex: ', currentResponseIndex)
        //console.log('leftRangeBalancer: ', leftRangeBalancer)
        //console.log('rightRangeBalancer: ', rightRangeBalancer)
        //console.log('index: ', currentResponseIndex + 1 + leftRangeBalancer)
        //console.log('---------------------')

        // iterate through the original `options` and based on the `currentResponseIndex` and `range` add applicable options into `updatedOptions`
        for(let index = currentResponseIndex + 1 + leftRangeBalancer; index < range - rightRangeBalancer; index++)
            updatedOptions.push(options[index])

        // to compensate if somehow the option which was previously selected for current response index was not added due to change in range
        // this will ensure data consistency & avoidance of any error related to indexing
        if(responseIndexToOptionIndexMapping.has(currentResponseIndex)){
            if(!updatedOptions.find(option => option.value === options[responseIndexToOptionIndexMapping.get(currentResponseIndex)].value))
                updatedOptions.push(options[responseIndexToOptionIndexMapping.get(currentResponseIndex)])
        }

        return updatedOptions
    }

    /* effects */
    useEffect(() => {
        /* 
            this effect works in accordance with the `calculateTotal` approach 
        */
        
        // check for data consistency
        if(state.sections.length !== 0 && data.other !== null && data.other.calculateTotal !== null){
            // this effect should only be further rendered if `isVisible` is true
            if(data.isVisible && (reCalculateTotal || reCalculateTotalAtRunTime)){
                
                // variable to keep track whether the total has been changed or updated. At the end, if it's true, then only update the state.
                let isTotalUpdated = false

                // create a shallow copy of `state` and extract it to `updatedState`
                const updatedState = {...state}

                // check whther `responses` exists or not
                if(data.other.responses !== null && data.other.responses.length !== 0){
                    // re-calculate total in case a row was deleted
                    if(haveNumberOfResponsesChanged){
                        updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.calculateTotal.total = calculateTotalBasedOnSameAndCrossSubSectionalQuestions(data.other.calculateTotal.columnIndex, parseInt(data.other.calculateTotal.inputIndexToBeCheckedForNA), data.other.calculateTotal.calculationBasedOn, data.other.calculateTotal.indexBasedCalculationExpression, data.other.calculateTotal.operationType)
                        isTotalUpdated = true
                    }

                    // based on the condition: whether any dependent input value is updated or not
                    for(const response of data.other.responses){
                        for(const input of response.inputs){
                            if(response.inputs.indexOf(input) === parseInt(data.other.calculateTotal.columnIndex)){
                                if(input.isUpdated){
                                    updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.calculateTotal.total = calculateTotalBasedOnSameAndCrossSubSectionalQuestions(data.other.calculateTotal.columnIndex, parseInt(data.other.calculateTotal.inputIndexToBeCheckedForNA), data.other.calculateTotal.calculationBasedOn, data.other.calculateTotal.indexBasedCalculationExpression, data.other.calculateTotal.operationType)
                                    isTotalUpdated = true
                                }
                                else{
                                    if(data.other.total.length === 0 && checkIfAnyInputHasValueAtRunTime()){
                                        updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.calculateTotal.total = calculateTotalBasedOnSameAndCrossSubSectionalQuestions(data.other.calculateTotal.columnIndex, parseInt(data.other.calculateTotal.inputIndexToBeCheckedForNA), data.other.calculateTotal.calculationBasedOn, data.other.calculateTotal.indexBasedCalculationExpression, data.other.calculateTotal.operationType)
                                        isTotalUpdated = true
                                    }
                                }
                            }
                        }
                    }

                    // if 'isTotalUpdated' is true
                    if(isTotalUpdated){
                        // set 'isUpdated' of all dependent input to false via iterating over them
                        for(const response of updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.responses){
                            for(const input of response.inputs){
                                if(response.inputs.indexOf(input) === parseInt(data.other.calculateTotal.columnIndex))
                                    input.isUpdated = false
                            }
                        }
                        
                        // if currently `haveNumberOfResponsesChanged` is true then set it to false
                        if(haveNumberOfResponsesChanged)
                            setHaveNumberOfResponsesChanged(false)
                        
                        // check if there's a minimum required value
                        if(data.other.calculateTotal.minimumRequiredValue !== null && data.other.calculateTotal.minimumRequiredValue.length !== 0){
                            // check whether the condition is met or not
                            if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.calculateTotal.total === 'NaN' || updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.calculateTotal.total === 'Infinity' || (parseInt(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.calculateTotal.total) < parseInt(data.other.calculateTotal.minimumRequiredValue))){
                                // since the minimum required value criteria is not met thus disallow current subsection submission via `setTemporarilyDisallowSubSectionSubmission`
                                setTemporarilyDisallowSubSectionSubmission(true)
                                // check if there's an alert as well
                                if(data.other.calculateTotal.hasAlert){
                                    // set `doesTemporaryDisallowanceForSubSectionSubmissionHasAlert` as true
                                    setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert(true)
                                    // configure the alert via `alertProperties` & `setAlertDialogBoxProperties`
                                    // make sure to keep the `visibility` false for now as it'll be turned on within subsection while submission
                                    setAlertDialogBoxProperties(prevState => ({ ...prevState, visibility: false, heading: data.other.calculateTotal.alertProperties.heading, alertText: data.other.calculateTotal.alertProperties.text, buttonText: data.other.calculateTotal.alertProperties.buttonText }))
                                }
                            } 
                            // if the condition is met then set the following states as false: `temporarilyDisallowSubSectionSubmission` & `doesTemporaryDisallowanceForSubSectionSubmissionHasAlert`
                            else{
                                setTemporarilyDisallowSubSectionSubmission(false)
                                setDoesTemporaryDisallowanceForSubSectionSubmissionHasAlert(false)
                            }
                        }

                        // finally, update the state via `setState`
                        setState(updatedState)
                    }
                }
                else{
                    if(haveNumberOfResponsesChanged){
                        // update total
                        updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.calculateTotal.total = ''
                        
                        // if currently `haveNumberOfResponsesChanged` is true then set it to false
                        if(haveNumberOfResponsesChanged)
                            setHaveNumberOfResponsesChanged(false)
                        
                        setState(updatedState)
                    }
                }

                if(reCalculateTotal)
                    setReCalculateTotal(false)

                if(reCalculateTotalAtRunTime)
                    setReCalculateTotalAtRunTime(false)
            }
        }
        
    }, [state, reCalculateTotal, reCalculateTotalAtRunTime])
    useEffect(() => {
        /* 
            this effect works in accordance with the `calculateTargetViaEventListener` approach 
            it works by mimicing the nature of an event(s) listener
            it monitors for changes in the values of dependencies
            if detected, it performs it's operation and updates the state accordingly
        */

        // this effect should only be further rendered if `isVisible` is true
        if(data.isVisible){

            // variable to keep track whether the target value has been changed or updated. At the end, if it's true, then only update the state.
            let targetValueChanged = false

            // create a shallow copy of `state` and extract it to `updatedState`
            const updatedState = {...state}
            
            // extract the responses for the current question by navigating using the `find()` method from the `updatedState`
            let responses = updatedState.sections.find((section, section_index) => section_index === sectionIndex).subsections.find((subsection, subsection_index) => subsection_index === subSectionIndex).content.questions.find(question => question.id === data.id).other.responses

            // check if response(s) exists or not
            if(responses !== null && responses.length !== 0){
                // iterate through each response
                for(const response of responses){
                    // iterate through each input of `response`
                    for(const input of response.inputs){
                        // check whether the current input needs to be calculated
                        if(input.isCalculated){
                            // check if the calculation needs to be done via the event listener approach
                            if(input.calculateTargetViaEventListener !== null && input.calculateTargetViaEventListener.length !== 0){
                                // check if impactor(s) is(are) present: `impactor` is a term used to refer to a question who's response once validated can directly impact or change the value of target itself
                                if(input.calculateTargetViaEventListener.impactBasedOn !== null && input.calculateTargetViaEventListener.impactBasedOn.length !== 0){
                                    // an impactor's precedence is the highest
                                    for(const impactor of input.calculateTargetViaEventListener.impactBasedOn){
                                        // in case there are multiple impactors present, then the target would be influenced by the impactor who's value was changed recently
                                        // variable to indicate whether the impactor belongs to the same sub-section as of target or not 
                                        let doesImpactorBelongsToSameSubSection = false
                                        // check if impactor's properties are valid
                                        if(impactor.sectionID !== null && impactor.sectionID.length !== 0 && impactor.subSectionID !== null && impactor.subSectionID.length !== 0){
                                            // check whether this impactor belongs to same sub-section or not
                                            if(state.sections.find((section, section_index) => section_index === sectionIndex).subsections.find((subsection, subsection_index) => subsection_index === subSectionIndex).id === impactor.subSectionID)
                                                doesImpactorBelongsToSameSubSection = true
                                            // if impactor is of same sub-section then utilize the question's response for further logic
                                            if(doesImpactorBelongsToSameSubSection){
                                                if(impactor.responseIndex !== null && impactor.responseIndex.length !== 0 && impactor.inputIndex !== null && impactor.inputIndex.length !== 0){
                                                    // impactor is of response type `other`
                                                    // coverage is only upto question level i.e. sub-question level is not considered for this. If required in future, please use a recursive call
                                                    // extract updatedState -> section -> subsection -> content -> question -> other -> response -> input into below variable
                                                    let inputForImpactor = updatedState.sections.find(section => section.id === impactor.sectionID).subsections.find(subsection => subsection.id === impactor.subSectionID).content.questions.find(question => question.id === impactor.questionID).other.responses.find((response, response_index) => response_index === parseInt(impactor.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(impactor.inputIndex))
                                                    // if the value of the impactor has been changed and the changed value corresponds to any of `setTargetValueWhenImpactorValueIs` array items then update the `previousValue` to changed value and apply/remove the impact on/from the target
                                                    if(impactor.previousValue !== inputForImpactor.value){
                                                        // we're currently only considering input of type 'dropdown' in this case. If needed for types 'text' or 'textarea' or 'checkbox', that can be done in future as the current system doesn't have such requirement
                                                        if(inputForImpactor.value.length !== 0 && impactor.setTargetValueWhenImpactorValueIs.includes(inputForImpactor.options.find(option => option.value === inputForImpactor.value).label)){
                                                            // update "impactor.previousValue"
                                                            impactor.previousValue = inputForImpactor.value
                                                            // apply impact on target value
                                                            input.value = impactor.setTargetValueAs
                                                        }
                                                        else{
                                                            // update "impactor.previousValue"
                                                            impactor.previousValue = inputForImpactor.value
                                                            // remove impact from target value
                                                            input.value = ''
                                                            // set `hasImpactorsImpactRemovedFromTarget` state as true since the impact of the impactor has been removed now from target
                                                            setHasImpactorsImpactRemovedFromTarget(true)
                                                        }
                                                        targetValueChanged = true
                                                        input.isUpdated = true
                                                        if(!reCalculateTotal)
                                                            setReCalculateTotal(true)
                                                    }
                                                }
                                                else if((impactor.responseIndex === null || (impactor.responseIndex !== null && impactor.responseIndex.length === 0)) && (impactor.inputIndex === null || (impactor.inputIndex !== null && impactor.inputIndex.length === 0))){
                                                    // additional checks were added just to make sure the data is consistent
                                                    // extract updatedState -> section -> subsection -> content -> question
                                                    let question = updatedState.sections.find(section => section.id === impactor.sectionID).subsections.find(subsection => subsection.id === impactor.subSectionID).content.questions.find(question => question.id === impactor.questionID)
                                                    // if the value of the impactor has been changed and the changed value corresponds to any of `setTargetValueWhenImpactorValueIs` array items then update the `previousValue` to changed value and apply/remove the impact on/from the target
                                                    if(impactor.previousValue !== question.response){
                                                        if(question.response.length !== 0){
                                                            // if question's response type is "dropdown"
                                                            if(question.responseType === 'dropdown'){
                                                                if(impactor.setTargetValueWhenImpactorValueIs.includes(question.options.find(option => option.value === question.response).label)){
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = question.response
                                                                    // apply impact on target value
                                                                    input.value = impactor.setTargetValueAs
                                                                }
                                                                else{
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = question.response
                                                                    // remove impact from target value
                                                                    input.value = ''
                                                                    // set `hasImpactorsImpactRemovedFromTarget` state as true since the impact of the impactor has been removed now from target
                                                                    setHasImpactorsImpactRemovedFromTarget(true)
                                                                }
                                                            }
                                                            // if question's response type is NOT "dropdown" i.e. "text" or "textarea" or "checkbox"
                                                            else{
                                                                if(impactor.setTargetValueWhenImpactorValueIs.includes(question.response)){
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = question.response
                                                                    // apply impact on target value
                                                                    input.value = impactor.setTargetValueAs
                                                                }
                                                                else{
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = question.response
                                                                    // remove impact from target value
                                                                    input.value = ''
                                                                    // set `hasImpactorsImpactRemovedFromTarget` state as true since the impact of the impactor has been removed now from target
                                                                    setHasImpactorsImpactRemovedFromTarget(true)
                                                                }
                                                            }
                                                        }
                                                        else{
                                                            // update "impactor.previousValue"
                                                            impactor.previousValue = question.response
                                                            // remove impact from target value
                                                            input.value = ''
                                                            // set `hasImpactorsImpactRemovedFromTarget` state as true since the impact of the impactor has been removed now from target
                                                            setHasImpactorsImpactRemovedFromTarget(true)
                                                        }
                                                        targetValueChanged = true
                                                        input.isUpdated = true
                                                        if(!reCalculateTotal)
                                                            setReCalculateTotal(true)
                                                    }
                                                }
                                            }
                                            // if impactor is of different sub-section then utilize the question's lastSuccessfullySavedResponse/lastSuccessfullySavedResponseOther for further logic
                                            // in the current system this scenario has not yet occured but if it does in future, remember that since we're dealing with cross-sub-sectional question response(s)/value(s), we would need to add the current question's sub-section as a dependent subsection via `dependentSubSectionID` to all those subsection(s) belonging to the cross-sub-sectional depedency that exists in this scenario
                                            else{
                                                if(impactor.responseIndex !== null && impactor.responseIndex.length !== 0 && impactor.inputIndex !== null && impactor.inputIndex.length !== 0){
                                                    // impactor is of response type `other`
                                                    // since impactor lies in a different sub-section thus utilize `lastSuccessfullySavedResponseOther` for this scenario
                                                    // coverage is only upto question level i.e. sub-question level is not considered for this. If required in future, please use a recursive call
                                                    // extract updatedState -> section -> subsection -> content -> question -> lastSuccessfullySavedResponseOther into below variable
                                                    let lastSuccessfullySavedResponseOtherForImpactor = updatedState.sections.find(section => section.id === impactor.sectionID).subsections.find(subsection => subsection.id === impactor.subSectionID).content.questions.find(question => question.id === impactor.questionID).lastSuccessfullySavedResponseOther
                                                    // check to make sure it's neither empty nor `null`, if it's either then nothing would happen
                                                    if(lastSuccessfullySavedResponseOtherForImpactor !== null && lastSuccessfullySavedResponseOtherForImpactor.length !== 0){
                                                        // extract lastSuccessfullySavedResponseOther -> response -> input into below variable
                                                        let inputForImpactor = lastSuccessfullySavedResponseOtherForImpactor.find((response, response_index) => response_index === parseInt(impactor.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(impactor.inputIndex))
                                                        // if the value of the impactor has been changed and the changed value corresponds to any of `setTargetValueWhenImpactorValueIs` array items then update the `previousValue` to changed value and apply/remove the impact on/from the target
                                                        if(impactor.previousValue !== inputForImpactor.value){
                                                            // we're currently only considering input of type 'dropdown' in this case. If needed for types 'text' or 'textarea' or 'checkbox', that can be done in future as the current system doesn't have such requirement
                                                            if(inputForImpactor.value.length !== 0 && impactor.setTargetValueWhenImpactorValueIs.includes(inputForImpactor.options.find(option => option.value === inputForImpactor.value).label)){
                                                                // update "impactor.previousValue"
                                                                impactor.previousValue = inputForImpactor.value
                                                                // apply impact on target value
                                                                input.value = impactor.setTargetValueAs
                                                            }
                                                            else{
                                                                // update "impactor.previousValue"
                                                                impactor.previousValue = inputForImpactor.value
                                                                // remove impact from target value
                                                                input.value = ''
                                                                // set `hasImpactorsImpactRemovedFromTarget` state as true since the impact of the impactor has been removed now from target
                                                                setHasImpactorsImpactRemovedFromTarget(true)
                                                            }
                                                            targetValueChanged = true
                                                            input.isUpdated = true
                                                            if(!reCalculateTotal)
                                                                setReCalculateTotal(true)
                                                        }
                                                    }
                                                }
                                                else if((impactor.responseIndex === null || (impactor.responseIndex !== null && impactor.responseIndex.length === 0)) && (impactor.inputIndex === null || (impactor.inputIndex !== null && impactor.inputIndex.length === 0))){
                                                    // utilize `lastSuccessfullySavedResponse` for this scenario
                                                    // additional checks were added just to make sure the data is consistent
                                                    // extract updatedState -> section -> subsection -> content -> question -> lastSuccessfullySavedResponse into below variable
                                                    let lastSuccessfullySavedResponseForImpactor = updatedState.sections.find(section => section.id === impactor.sectionID).subsections.find(subsection => subsection.id === impactor.subSectionID).content.questions.find(question => question.id === impactor.questionID).lastSuccessfullySavedResponse
                                                    // check to make sure it's neither empty nor `null`, if it's either then nothing would happen
                                                    if(lastSuccessfullySavedResponseForImpactor !== null && lastSuccessfullySavedResponseForImpactor.length !== 0){
                                                        // extract updatedState -> section -> subsection -> content -> question into below variable
                                                        let question = updatedState.sections.find(section => section.id === impactor.sectionID).subsections.find(subsection => subsection.id === impactor.subSectionID).content.questions.find(question => question.id === impactor.questionID)
                                                        // if the value of the impactor has been changed and the changed value corresponds to any of `setTargetValueWhenImpactorValueIs` array items then update the `previousValue` to changed value and apply/remove the impact on/from the target
                                                        if(impactor.previousValue !== lastSuccessfullySavedResponseForImpactor){
                                                            // if question's response type is "dropdown"
                                                            if(question.responseType === 'dropdown'){
                                                                if(impactor.setTargetValueWhenImpactorValueIs.includes(question.options.find(option => option.value === lastSuccessfullySavedResponseForImpactor).label)){
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = lastSuccessfullySavedResponseForImpactor
                                                                    // apply impact on target value
                                                                    input.value = impactor.setTargetValueAs
                                                                }
                                                                else{
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = lastSuccessfullySavedResponseForImpactor
                                                                    // remove impact from target value
                                                                    input.value = ''
                                                                    // set `hasImpactorsImpactRemovedFromTarget` state as true since the impact of the impactor has been removed now from target
                                                                    setHasImpactorsImpactRemovedFromTarget(true)
                                                                }
                                                            }
                                                            // if question's response type is NOT "dropdown" i.e. "text" or "textarea" or "checkbox"
                                                            else{
                                                                if(impactor.setTargetValueWhenImpactorValueIs.includes(lastSuccessfullySavedResponseForImpactor)){
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = lastSuccessfullySavedResponseForImpactor
                                                                    // apply impact on target value
                                                                    input.value = impactor.setTargetValueAs
                                                                }
                                                                else{
                                                                    // update "impactor.previousValue"
                                                                    impactor.previousValue = lastSuccessfullySavedResponseForImpactor
                                                                    // remove impact from target value
                                                                    input.value = ''
                                                                    // set `hasImpactorsImpactRemovedFromTarget` state as true since the impact of the impactor has been removed now from target
                                                                    setHasImpactorsImpactRemovedFromTarget(true)
                                                                }
                                                            }
                                                            targetValueChanged = true
                                                            input.isUpdated = true
                                                            if(!reCalculateTotal)
                                                                setReCalculateTotal(true)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    // in this case there is(are) impactor(s) to influence the target value
                                    if(!hasTheTargetValueImpactedByAnyImpactor(input.value, input.calculateTargetViaEventListener.impactBasedOn)){
                                        // code for calculation...
                                        // check if `calculationBasedOn` has items
                                        if(input.calculateTargetViaEventListener.calculationBasedOn !== null && input.calculateTargetViaEventListener.calculationBasedOn.length !== 0){
                                            // iterate through each item
                                            for(const item of input.calculateTargetViaEventListener.calculationBasedOn){
                                                // variable to indicate whether the item belongs to the same sub-section as of target or not 
                                                let doesItemBelongsToSameSubSection = false
                                                // check if items's properties are valid
                                                if(item.sectionID !== null && item.sectionID.length !== 0 && item.subSectionID !== null && item.subSectionID.length !== 0){
                                                    // check whether `item` belongs to the same sub-section as of current question or not
                                                    if(state.sections.find((section, section_index) => section_index === sectionIndex).subsections.find((subsection, subsection_index) => subsection_index === subSectionIndex).id === item.subSectionID)
                                                        doesItemBelongsToSameSubSection = true
                                                    // if `item` does indeed belongs to the same sub-section as of current question
                                                    if(doesItemBelongsToSameSubSection){
                                                        // check response type: "text" or "other (text)", only these two response types are allowed for calculation
                                                        if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                                                            // item is of response type `other`
                                                            // coverage is only upto question level i.e. sub-question level is not considered for this. If required in future, please use a recursive call
                                                            // extract updatedState -> section -> subsection -> content -> question -> other -> response -> input into below variable
                                                            let inputForItem = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).other.responses.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex))
                                                            // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                            // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                            if(item.previousValue !== inputForItem.value || hasImpactorsImpactRemovedFromTarget){
                                                                // update the `previousValue`
                                                                item.previousValue = inputForItem.value
                                                                // we're currently only considering input of type 'text' in this case. If needed for types 'dropdwon' or 'textarea' or 'checkbox', that can be done in future as the current system doesn't have such requirement
                                                                // calculate here...
                                                                input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                                targetValueChanged = true
                                                                input.isUpdated = true
                                                                if(!reCalculateTotal)
                                                                    setReCalculateTotal(true)
                                                            }
                                                        }
                                                        else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                                                            // additional checks were added just to make sure the data is consistent
                                                            // extract updatedState -> section -> subsection -> content -> question
                                                            let question = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID)
                                                            // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                            // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                            if(item.previousValue !== question.response || hasImpactorsImpactRemovedFromTarget){
                                                                // update the `previousValue`
                                                                item.previousValue = question.response
                                                                // if question's response type is "text" (no other response types are allowed)
                                                                if(question.responseType === 'text'){
                                                                    // calculate here...
                                                                    input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                                    targetValueChanged = true
                                                                    input.isUpdated = true
                                                                    if(!reCalculateTotal)
                                                                        setReCalculateTotal(true)
                                                                }
                                                            }
                                                        }
                                                    }
                                                    // if `item` belongs to a different sub-section as of current question 
                                                    // utilize the question's lastSuccessfullySavedResponse/lastSuccessfullySavedResponseOther for further logic
                                                    // in the current system this scenario has not yet occured but if it does in future, remember that since we're dealing with cross-sub-sectional question response(s)/value(s), we would need to add the current question's sub-section as a dependent subsection via `dependentSubSectionID` to all those subsection(s) belonging to the cross-sub-sectional depedency that exists in this scenario
                                                    else{
                                                        // check response type: "text" or "other (text)", only these two response types are allowed for calculation
                                                        if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                                                            // utilize `lastSuccessfullySavedResponseOther` for this scenario
                                                            // coverage is only upto question level i.e. sub-question level is not considered for this. If required in future, please use a recursive call
                                                            // extract updatedState -> section -> subsection -> content -> question -> lastSuccessfullySavedResponseOther into below variable
                                                            let lastSuccessfullySavedResponseOtherForItem = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponseOther
                                                            // check to make sure it's neither empty nor `null`, if it's either then nothing would happen
                                                            if(lastSuccessfullySavedResponseOtherForItem !== null && lastSuccessfullySavedResponseOtherForItem.length !== 0){
                                                                // extract lastSuccessfullySavedResponseOther -> response -> input into below variable
                                                                let inputForItem = lastSuccessfullySavedResponseOtherForItem.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex))
                                                                // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                                // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                                if(item.previousValue !== inputForItem.value || hasImpactorsImpactRemovedFromTarget){
                                                                    // update the `previousValue`
                                                                    item.previousValue = inputForItem.value
                                                                    // we're currently only considering input of type 'text' in this case. If needed for types 'dropdwon' or 'textarea' or 'checkbox', that can be done in future as the current system doesn't have such requirement
                                                                    // calculate here...
                                                                    input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                                    targetValueChanged = true
                                                                    input.isUpdated = true
                                                                    if(!reCalculateTotal)
                                                                        setReCalculateTotal(true)
                                                                }
                                                            }
                                                        }
                                                        else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                                                            // utilize `lastSuccessfullySavedResponse` for this scenario
                                                            // additional checks were added just to make sure the data is consistent
                                                            // extract updatedState -> section -> subsection -> content -> question -> lastSuccessfullySavedResponse into below variable
                                                            let lastSuccessfullySavedResponseForItem = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponse
                                                            // check to make sure it's neither empty nor `null`, if it's either then nothing would happen
                                                            if(lastSuccessfullySavedResponseForItem !== null && lastSuccessfullySavedResponseForItem.length !== 0){
                                                                // extract updatedState -> section -> subsection -> content -> question into below variable
                                                                let question = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID)
                                                                // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                                // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                                if(item.previousValue !== lastSuccessfullySavedResponseForItem || hasImpactorsImpactRemovedFromTarget){
                                                                    // update the `previousValue`
                                                                    item.previousValue = lastSuccessfullySavedResponseForItem
                                                                    // if question's response type is "text" (no other response types are allowed) & response is not empty (this check already exists for `lastSuccessfullySavedResponseForItem` above, so need to check it again)
                                                                    if(question.responseType === 'text'){
                                                                        // calculate here...
                                                                        input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                                        targetValueChanged = true
                                                                        input.isUpdated = true
                                                                        if(!reCalculateTotal)
                                                                            setReCalculateTotal(true)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                else{
                                    // in this case there is(are) no impactor(s) to influence the target value
                                    // check if `calculationBasedOn` has items
                                    if(input.calculateTargetViaEventListener.calculationBasedOn !== null && input.calculateTargetViaEventListener.calculationBasedOn.length !== 0){
                                        // iterate through each item
                                        for(const item of input.calculateTargetViaEventListener.calculationBasedOn){
                                            // variable to indicate whether the item belongs to the same sub-section as of target or not 
                                            let doesItemBelongsToSameSubSection = false
                                            // check if items's properties are valid
                                            if(item.sectionID !== null && item.sectionID.length !== 0 && item.subSectionID !== null && item.subSectionID.length !== 0){
                                                // check whether `item` belongs to the same sub-section as of current question or not
                                                if(state.sections.find((section, section_index) => section_index === sectionIndex).subsections.find((subsection, subsection_index) => subsection_index === subSectionIndex).id === item.subSectionID)
                                                    doesItemBelongsToSameSubSection = true
                                                // if `item` does indeed belongs to the same sub-section as of current question
                                                if(doesItemBelongsToSameSubSection){
                                                    // check response type: "text" or "other (text)", only these two response types are allowed for calculation
                                                    if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                                                        // item is of response type `other`
                                                        // coverage is only upto question level i.e. sub-question level is not considered for this. If required in future, please use a recursive call
                                                        // extract updatedState -> section -> subsection -> content -> question -> other -> response -> input into below variable
                                                        let inputForItem = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).other.responses.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex))
                                                        // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                        // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                        if(item.previousValue !== inputForItem.value || hasImpactorsImpactRemovedFromTarget){
                                                            // update the `previousValue`
                                                            item.previousValue = inputForItem.value
                                                            // we're currently only considering input of type 'text' in this case. If needed for types 'dropdwon' or 'textarea' or 'checkbox', that can be done in future as the current system doesn't have such requirement
                                                            // calculate here...
                                                            input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                            targetValueChanged = true
                                                            input.isUpdated = true
                                                            if(!reCalculateTotal)
                                                                setReCalculateTotal(true)
                                                        }
                                                    }
                                                    else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                                                        // additional checks were added just to make sure the data is consistent
                                                        // extract updatedState -> section -> subsection -> content -> question
                                                        let question = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID)
                                                        // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                        // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                        if(item.previousValue !== question.response || hasImpactorsImpactRemovedFromTarget){
                                                            // update the `previousValue`
                                                            item.previousValue = question.response
                                                            // if question's response type is "text" (no other response types are allowed)
                                                            if(question.responseType === 'text'){
                                                                // calculate here...
                                                                input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                                targetValueChanged = true
                                                                input.isUpdated = true
                                                                if(!reCalculateTotal)
                                                                    setReCalculateTotal(true)
                                                            }
                                                        }
                                                    }
                                                }
                                                // if `item` belongs to a different sub-section as of current question 
                                                // utilize the question's lastSuccessfullySavedResponse/lastSuccessfullySavedResponseOther for further logic
                                                // in the current system this scenario has not yet occured but if it does in future, remember that since we're dealing with cross-sub-sectional question response(s)/value(s), we would need to add the current question's sub-section as a dependent subsection via `dependentSubSectionID` to all those subsection(s) belonging to the cross-sub-sectional depedency that exists in this scenario
                                                else{
                                                    // check response type: "text" or "other (text)", only these two response types are allowed for calculation
                                                    if(item.responseIndex !== null && item.responseIndex.length !== 0 && item.inputIndex !== null && item.inputIndex.length !== 0){
                                                        // utilize `lastSuccessfullySavedResponseOther` for this scenario
                                                        // coverage is only upto question level i.e. sub-question level is not considered for this. If required in future, please use a recursive call
                                                        // extract updatedState -> section -> subsection -> content -> question -> lastSuccessfullySavedResponseOther into below variable
                                                        let lastSuccessfullySavedResponseOtherForItem = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponseOther
                                                        // check to make sure it's neither empty nor `null`, if it's either then nothing would happen
                                                        if(lastSuccessfullySavedResponseOtherForItem !== null && lastSuccessfullySavedResponseOtherForItem.length !== 0){
                                                            // extract lastSuccessfullySavedResponseOther -> response -> input into below variable
                                                            let inputForItem = lastSuccessfullySavedResponseOtherForItem.find((response, response_index) => response_index === parseInt(item.responseIndex)).inputs.find((input, input_index) => input_index === parseInt(item.inputIndex))
                                                            // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                            // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                            if(item.previousValue !== inputForItem.value || hasImpactorsImpactRemovedFromTarget){
                                                                // update the `previousValue`
                                                                item.previousValue = inputForItem.value
                                                                // we're currently only considering input of type 'text' in this case. If needed for types 'dropdwon' or 'textarea' or 'checkbox', that can be done in future as the current system doesn't have such requirement
                                                                // calculate here...
                                                                input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                                targetValueChanged = true
                                                                input.isUpdated = true
                                                                if(!reCalculateTotal)
                                                                    setReCalculateTotal(true)
                                                            }
                                                        }
                                                    }
                                                    else if((item.responseIndex === null || (item.responseIndex !== null && item.responseIndex.length === 0)) && (item.inputIndex === null || (item.inputIndex !== null && item.inputIndex.length === 0))){
                                                        // utilize `lastSuccessfullySavedResponse` for this scenario
                                                        // additional checks were added just to make sure the data is consistent
                                                        // extract updatedState -> section -> subsection -> content -> question -> lastSuccessfullySavedResponse into below variable
                                                        let lastSuccessfullySavedResponseForItem = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID).lastSuccessfullySavedResponse
                                                        // check to make sure it's neither empty nor `null`, if it's either then nothing would happen
                                                        if(lastSuccessfullySavedResponseForItem !== null && lastSuccessfullySavedResponseForItem.length !== 0){
                                                            // extract updatedState -> section -> subsection -> content -> question into below variable
                                                            let question = updatedState.sections.find(section => section.id === item.sectionID).subsections.find(subsection => subsection.id === item.subSectionID).content.questions.find(question => question.id === item.questionID)
                                                            // if the value of the `item` has been changed then update the `previousValue` to changed value
                                                            // alternatively, allow to enter below `if` block in case `hasImpactorsImpactRemovedFromTarget` is true
                                                            if(item.previousValue !== lastSuccessfullySavedResponseForItem || hasImpactorsImpactRemovedFromTarget){
                                                                // update the `previousValue`
                                                                item.previousValue = lastSuccessfullySavedResponseForItem
                                                                // if question's response type is "text" (no other response types are allowed) & response is not empty (this check already exists for `lastSuccessfullySavedResponseForItem` above, so need to check it again)
                                                                if(question.responseType === 'text'){
                                                                    // calculate here...
                                                                    input.value = calculateTargetValueBasedOnSameAndCrossSubSectionalQuestions(input.calculateTargetViaEventListener.calculationBasedOn, input.calculateTargetViaEventListener.indexBasedCalculationExpression, input.calculateTargetViaEventListener.operationType)
                                                                    targetValueChanged = true
                                                                    input.isUpdated = true
                                                                    if(!reCalculateTotal)
                                                                        setReCalculateTotal(true)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                // update the state here if `targetValueChanged` is true
                                if(targetValueChanged){
                                    // make sure to set `hasImpactorsImpactRemovedFromTarget` as false if it's true during current execution
                                    if(hasImpactorsImpactRemovedFromTarget)
                                        setHasImpactorsImpactRemovedFromTarget(false)
                                    setState(updatedState)
                                }
                            }
                        }
                    }
                }
            }

        }

    }, [state])
    useEffect(() => {
        /* 
            this effect works in accordance with the `crossQuestionSelectiveReplication` approach 
            it employs the nature of an event(s) listener
            it monitors for changes in the value of dependent question
            if detected, it performs it's operation and updates the state accordingly
        */

        // this effect should only be further rendered if `isVisible` is true
        if(data.isVisible){

            // check whether `crossQuestionSelectiveReplication` is null or not
            if(data.other.crossQuestionSelectiveReplication !== null){
                
                // variable to keep track whether the dependent question's value has been changed or updated. At the end, if it's true, then only update the state.
                let hasQuestionValueChanged = false

                // create a shallow copy of the state itself
                const updatedState = {...state}

                // extract current subsection into `currentSubSection`
                const currentSubSection = updatedState.sections.find((section, section_index) => section_index === sectionIndex).subsections.find((subsection, subsection_index) => subsection_index === subSectionIndex)

                // extract current question into `currentQuestion`
                const currentQuestion = updatedState.sections.find((section, section_index) => section_index === sectionIndex).subsections.find((subsection, subsection_index) => subsection_index === subSectionIndex).content.questions.find(question => question.id === data.id)
                
                // extract `crossQuestionSelectiveReplication` via `currentSubSection` into below constant for better clarity 
                const crossQuestionSelectiveReplication = currentSubSection.content.questions.find(question => question.id === data.id).other.crossQuestionSelectiveReplication

                // extract `replicationBasedOn` into below constant for better clarity
                const replicationBasedOn = crossQuestionSelectiveReplication.replicationBasedOn

                // extract `replicationOptions` into below constant for better clarity
                const replicationOptions = crossQuestionSelectiveReplication.replicationOptions
                
                // check to make sure that `replicationBasedOn` is not null
                if(replicationBasedOn !== null){
                    
                    // access the question by using properties of `replicationBasedOn` and extract it into `question`
                    const question = updatedState.sections.find(section => section.id === replicationBasedOn.sectionID).subsections.find(subsection => subsection.id === replicationBasedOn.subSectionID).content.questions.find(question => question.id === replicationBasedOn.questionID)

                    // the `crossQuestionSelectiveReplication` approach is limited to question's with response type as 'dropdown' and is also further limited at only parent question level
                    if(question.responseType === 'dropdown'){
                        // check whether the question lies within the same subsection or not
                        if(currentSubSection.id === replicationBasedOn.subSectionID){
                            // when the question lies within the same subsection
                            // check if there's a change in the two responses, if there is then, it means the value of the question has been updated
                            if(question.response !== replicationBasedOn.previousValue){
                                // check to make sure the `replicationOptions` is not empty
                                if(replicationOptions.length !== 0){
                                    // iterate over each option of `replicationOptions`
                                    for(const option of replicationOptions){
                                        // if question's response is same as any of the option's optionValue, then perform replication accordingly
                                        if(option.optionValue === question.response)
                                            currentQuestion.other.responses = replicate(parseInt(option.replicationValue))
                                    }
                                }
                                // update the `previousValue` to the latest value of question
                                replicationBasedOn.previousValue = question.response
                                // set `hasQuestionValueChanged` as true
                                hasQuestionValueChanged = true
                            }
                        }
                        else{
                            // when the question lies in a different subsection
                            // make sure `lastSuccessfullySavedResponse` is not null before proceeding further
                            if(question.lastSuccessfullySavedResponse !== null){
                                // check if there's a change in the two responses, if there is then, it means the value of the question has been updated
                                if(question.lastSuccessfullySavedResponse !== replicationBasedOn.previousValue){
                                    // check to make sure the `replicationOptions` is not empty
                                    if(replicationOptions.length !== 0){
                                        // iterate over each option of `replicationOptions`
                                        for(const option of replicationOptions){
                                            // if question's response is same as any of the option's optionValue, then perform replication accordingly
                                            if(option.optionValue === question.lastSuccessfullySavedResponse)
                                                currentQuestion.other.responses = replicate(parseInt(option.replicationValue))
                                        }
                                    }
                                    // update the `previousValue` to the latest value of question
                                    replicationBasedOn.previousValue = question.lastSuccessfullySavedResponse
                                    // set `hasQuestionValueChanged` as true
                                    hasQuestionValueChanged = true
                                }
                            }
                        }
                        // update the state only if the dependent question's value has been changed
                        if(hasQuestionValueChanged)
                            setState(updatedState)
                    }
                }
            }
        }
    }, [state])
    useEffect(() => {
        /* 
            this effect works in accordance with the `reconfigureDropdownOptionsOfRecordedResponses` approach 
            this effect will render when either of `reconfigureDropdownOptionsOfRecordedResponsesAtRunTime` or `reconfigureDropdownOptionsOfRecordedResponses` states gets set as true
            if all conditions within the code blocks are met then it will assign the reference of new memory allocation (post internal methods calls execution) to responses & then updates the state accordingly
        */

        // this effect should only be further rendered if `isVisible` is true
        if(data.isVisible){
            //console.log(data.other.reconfigureDropdownOptionsOfRecordedResponses);
            // make sure the `reconfigureDropdownOptionsOfRecordedResponses` is not null
            if(data.other.reconfigureDropdownOptionsOfRecordedResponses !== null){
                
                // only move further when `reconfigureDropdownOptionsOfRecordedResponses.isApplicable` is true 
                if(data.other.reconfigureDropdownOptionsOfRecordedResponses.isApplicable){
                    
                    // check whether responses exists or not; only move forward if they do
                    if(data.other.responses.length !== 0){
                        
                        // allow to execute the block of code within if either of `reconfigureDropdownOptionsOfRecordedResponses` or `reconfigureDropdownOptionsOfRecordedResponsesAtRunTime` is/are true
                        if(reconfigureDropdownOptionsOfRecordedResponses || reconfigureDropdownOptionsOfRecordedResponsesAtRunTime){
                            
                            // create a shallow copy of `state`
                            const updatedState = {...state}
                            // update the responses by calling the `reconfigureDropdownOptions` method, passing in the required parameters 
                            updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.responses = reconfigureDropdownOptions(data.other.schema, updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].other.responses, parseInt(data.other.reconfigureDropdownOptionsOfRecordedResponses.inputIndex))
                            // update the `state` via `setState`
                            setState(updatedState)
                            
                            // if `reconfigureDropdownOptionsOfRecordedResponses` is true then set it to false via `setReconfigureDropdownOptionsOfRecordedResponses`
                            if(reconfigureDropdownOptionsOfRecordedResponses)
                                setReconfigureDropdownOptionsOfRecordedResponses(false)
                            
                            // if `reconfigureDropdownOptionsOfRecordedResponsesAtRunTime` is true then set it to false via `setReconfigureDropdownOptionsOfRecordedResponsesAtRunTime`
                            if(reconfigureDropdownOptionsOfRecordedResponsesAtRunTime)
                                setReconfigureDropdownOptionsOfRecordedResponsesAtRunTime(false)
                        }
                    }
                }
            }
        }

    }, [state, reconfigureDropdownOptionsOfRecordedResponsesAtRunTime, reconfigureDropdownOptionsOfRecordedResponses])

    return(
        <React.Fragment>
            <div className="card card-table card-border" style={data.isVisible ? {} : {display: 'none'}}>
                <div className="card-header" style={data.other.title.length === 0 && data.other.description.length === 0 ? {display: 'none'} : {}}>
                    <span style={data.helpText === 0 ? { display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between' } : { display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between', marginBottom: 0 }}>
                        {htmlConverterReact(data.other.title)}
                        {
                            data.other.helpText.length === 0 
                                ? 
                                <React.Fragment></React.Fragment>
                                :
                                <span className='tool' data-tip={data.other.helpText}>
                                    <span className="icon mdi mdi-1x mdi-help-outline"></span>
                                </span>
                        }
                    </span>
                    <span className="card-subtitle">{htmlConverterReact(data.other.description)}</span>
                </div>
                <div className="card-body px-2 table-responsive">
                    <table className="table table-striped table-borderless">
                        <thead style={data.other.headerCellsVisibility ? {} : {display: 'none'}}>
                            <tr>
                                {
                                    data.other.columns.map((column, index) => {
                                        return  <th key={index} style={{width: column.width, verticalAlign: 'middle', textAlign: 'center'}}>
                                                    {column.title}
                                                    {
                                                        column.hasHelpText
                                                            ?
                                                            <React.Fragment>
                                                                <span data-tooltip-id={`question#${data.id}-other-column#${index + 1}`} data-tooltip-html={column.helpText} data-tooltip-place='top' style={{ marginLeft: 10, verticalAlign: 'middle', cursor: 'help' }}>
                                                                    <span className="icon mdi mdi-1x mdi-help-outline"></span>
                                                                </span>
                                                                <Tooltip id={`question#${data.id}-other-column#${index + 1}`} arrowColor="#3E474F" opacity={0.95} style={{ color: '#3E474F', fontSize: 12, fontWeight: 700, width: 400, borderWidth: '5px', borderColor: '#3E474F' }} />
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment></React.Fragment>
                                                    }
                                                </th>  
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody className="no-border-x" ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]}>
                            <tr>
                                <td colSpan={data.other.numberOfColumns}><i>{htmlConverterReact(data.question)}</i></td>
                            </tr>
                            {
                                data.other.responses !== null && data.other.responses.length !== 0 && data.other.responses.map((response, response_index) => {
                                    return(
                                        <tr key={response_index}>
                                            {
                                                response.inputs.map((input, input_index) => {
                                                    //console.log(`sectionIndex: ${sectionIndex} | subsectionIndex: ${subSectionIndex} | questionIndex: ${index} | responseIndex: ${response_index} | inputIndex: ${input_index}`)
                                                    return (
                                                        <td key={input_index} style={{width: data.other.columns[input_index].width, textAlign: 'center'}} data-toggle={(input.type === 'text' || input.type === 'textarea' || input.type === 'dropdown') && input.value.length !== 0 ? 'tooltip' : ''}  data-placement="top" title={input.type === 'dropdown' && input.value.length !== 0 && new RegExp(/^\d*$/).test(input.value) ? input.options.find(option => option.value === input.value).label : input.value}>
                                                            {
                                                                input.type === 'text' 
                                                                    ?
                                                                    <div className="input-group input-group-sm">
                                                                        <input id={`other#${data.id}-response#${response.id}-input#${input_index}`} className="form-control form-control-sm" type="text" value={input.value} onMouseEnter={() => { if(input.requireConfirmation){ setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, heading: input.confirmationProperties.heading, text: input.confirmationProperties.text, negativeButtonText: 'No', positiveButtonText: 'Yes' }) } }} onChange={(e) => { if(input.regex === null || (input.regex.length !== 0 && new RegExp(input.regex).test(e.target.value)) || input.regex.length === 0){ if(input.requireConfirmation && input.value.length !== 0){ setInputProperties({ action: 'update', value: e.target.value, item_index: input_index, response_index: response_index }); toggleConfirmationDialogBox() } else{ if(input.append !== null && input.append === '%'){ if(/^\d*(\.\d*)?$/.test(e.target.value)){ updateState('update', e.target.value, input_index, response_index) } } else{ updateState('update', e.target.value, input_index, response_index) } } }  }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} readOnly={input.isCalculated} />
                                                                        {
                                                                            input.append.length === 0 
                                                                                ?
                                                                                <React.Fragment></React.Fragment>
                                                                                :
                                                                                <div className="input-group-append">
                                                                                    <span className="input-group-text">{input.append}</span>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    input.type === 'textarea' 
                                                                        ? 
                                                                        <textarea id={`other#${data.id}-response#${response.id}-input#${input_index + 1}`} className="form-control" placeholder={input.placeholder} value={input.value} onMouseEnter={() => { if(input.requireConfirmation){ setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, heading: input.confirmationProperties.heading, text: input.confirmationProperties.text, negativeButtonText: 'No', positiveButtonText: 'Yes' }) } }} onChange={(e) => { if(input.regex === null || (input.regex.length !== 0 && new RegExp(input.regex).test(e.target.value)) || input.regex.length === 0){ if(input.requireConfirmation && input.value.length !== 0){ setInputProperties({ action: 'update', value: e.target.value, item_index: input_index, response_index: response_index }); toggleConfirmationDialogBox() } else{ updateState('update', e.target.value, input_index, response_index ) } } }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} readOnly={input.isCalculated} />
                                                                        :
                                                                        input.type === 'dropdown' 
                                                                            ?
                                                                            <React.Fragment>
                                                                                <select id={`other#${data.id}-response#${response.id}-input#${input_index + 1}`} className="form-control form-control-sm" value={input.value} onMouseEnter={() => { if(input.requireConfirmation){ setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, heading: input.confirmationProperties.heading, text: input.confirmationProperties.text, negativeButtonText: 'No', positiveButtonText: 'Yes' }) } }} onChange={(e) => { if(input.requireConfirmation){ setInputProperties({ action: 'update', value: e.target.value, item_index: input_index, response_index: response_index }); toggleConfirmationDialogBox() } else{ updateState('update', e.target.value, input_index, response_index ) } }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true}>
                                                                                    {
                                                                                        input.options.map((option, option_index) => {
                                                                                            return <option key={option_index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                                                        })
                                                                                    }
                                                                                </select>
                                                                                {
                                                                                    input.type === 'dropdown' && input.value.length !== 0 && input.value !== '0' && input.options.find(option => option.value === input.value).hasSecondaryInput
                                                                                        ?
                                                                                        input.options.find(option => option.value === input.value).secondaryInputType === 'text'
                                                                                            ?
                                                                                            <div className="input-group input-group-sm" data-toggle={input.options.find(option => option.value === input.value).secondaryInputValue.length !== 0 ? 'tooltip' : ''} data-placement="top" title={input.options.find(option => option.value === input.value).secondaryInputValue}>
                                                                                                <input id={`other#${data.id}-response#${response.id}-input#${input_index + 1}-secondary-input`} className="form-control form-control-sm" type="text" value={input.options.find(option => option.value === input.value).secondaryInputValue} onChange={(e) => { updateState('update-secondary-input', e.target.value, input_index, response_index) }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} />
                                                                                            </div>
                                                                                            :
                                                                                            input.options.find(option => option.value === input.value).secondaryInputType === 'textarea'
                                                                                                ?
                                                                                                <textarea id={`other#${data.id}-response#${response.id}-input#${input_index + 1}-secondary-input`} className="form-control" data-toggle={input.options.find(option => option.value === input.value).secondaryInputValue.length !== 0 ? 'tooltip' : ''} data-placement="top" title={input.options.find(option => option.value === input.value).secondaryInputValue} placeholder={''} value={input.options.find(option => option.value === input.value).secondaryInputValue} onChange={(e) => { updateState('update-secondary-input', e.target.value, input_index, response_index) }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} />
                                                                                                :
                                                                                                input.options.find(option => option.value === input.value).secondaryInputType === 'dropdown'
                                                                                                    ?
                                                                                                    <select id={`other#${data.id}-response#${response.id}-input#${input_index + 1}-secondary-input`} className="form-control form-control-sm" data-toggle={input.options.find(option => option.value === input.value).secondaryInputValue.length !== 0 ? 'tooltip' : ''} data-placement="top" title={input.options.find(option => option.value === input.value).secondaryInputValue.length !== 0 ? input.options.find(option => option.value === input.value).secondaryInputOptions.find(secondaryInputOption => secondaryInputOption.value === input.options.find(option => option.value === input.value).secondaryInputValue).label : ''} value={input.options.find(option => option.value === input.value).secondaryInputValue} onChange={(e) => { updateState('update-secondary-input', e.target.value, input_index, response_index) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true}>
                                                                                                        {
                                                                                                            input.options.find(option => option.value === input.value).secondaryInputOptions.map((secondary_input_option, secondary_input_option_index) => {
                                                                                                                return <option key={secondary_input_option_index} value={secondary_input_option.value} style={{cursor: 'pointer'}}>{secondary_input_option.label}</option>
                                                                                                            })
                                                                                                        }
                                                                                                    </select>
                                                                                                    :
                                                                                                    input.options.find(option => option.value === input.value).secondaryInputType === 'checkbox'
                                                                                                        ?
                                                                                                        <div className="custom-control custom-checkbox custom-control-inline">
                                                                                                            <input id={`other#${data.id}-response#${response.id}-input#${input_index + 1}-secondary-input`} className="custom-control-input" data-toggle={input.options.find(option => option.value === input.value).secondaryInputValue.length !== 0 ? 'tooltip' : ''} data-placement="top" title={input.options.find(option => option.value === input.value).secondaryInputValue} type="checkbox" disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} checked={input.options.find(option => option.value === input.value).secondaryInputValue === 'Yes' ? true : false} onChange={(e) => { updateState('update-secondary-input', e.target.checked ? 'Yes' : 'No'   , input_index, response_index) }} />
                                                                                                            <label className="custom-control-label" htmlFor={`other#${data.id}-response#${response.id}-input#${input_index + 1}-secondary-input`} />
                                                                                                        </div>
                                                                                                        :
                                                                                                        <React.Fragment></React.Fragment>
                                                                                        :
                                                                                        <React.Fragment></React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                            :
                                                                            input.type === 'checkbox'
                                                                                ?
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <input id={`other#${data.id}-response#${response.id}-input#${input_index + 1}`} className="custom-control-input" type="checkbox" disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} checked={input.value === 'Yes' ? true : false} onMouseEnter={() => { if(input.requireConfirmation){ setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, heading: input.confirmationProperties.heading, text: input.confirmationProperties.text, negativeButtonText: 'No', positiveButtonText: 'Yes' }) } }} onChange={(e) => { if(input.requireConfirmation){ setInputProperties({ action: 'update', value: e.target.checked ? 'Yes' : 'No', item_index: input_index, response_index: response_index }); toggleConfirmationDialogBox() } else{ updateState('update', e.target.checked ? 'Yes' : 'No', input_index, response_index) } }} />
                                                                                    <label className="custom-control-label" htmlFor={`other#${data.id}-response#${response.id}-input#${input_index + 1}`} />
                                                                                </div>
                                                                                :
                                                                                <React.Fragment></React.Fragment>
                                                            }
                                                            {
                                                                input.type === 'text' && input.append !== null && input.append === '%' && parseFloat(input.value) >= 1.0
                                                                    ?
                                                                    <p style={{ margin: 0, fontSize: 15 }}>This must be less than 1%</p>
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                            }
                                                            {
                                                                state.sections[sectionIndex].subsections[subSectionIndex].isSubmitButtonClicked && input.type === 'checkbox' && checkboxValidation(response_index, data.other.leastNumberOfCheckboxesToBeChecked)
                                                                    ?
                                                                    <p style={{ margin: 0, fontSize: 13, color: 'red' }}>{data.other.leastNumberOfCheckboxesToBeCheckedValidationMessage}</p>
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                            <td style={data.other.responses.length === 0 || !data.other.hasDeleteAction ? {display: 'none'} : {}}>
                                                <button style={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? {} : {display: 'none'}} type="button" className="btn shadow-none" onMouseEnter={() => { setConfirmationDialogBoxProperties({...confirmationDialogBoxProperties, heading: 'Confirmation Required', text: 'Are you sure you want to remove this row?', negativeButtonText: 'No, Cancel', positiveButtonText: 'Yes, Remove' }) }} onClick={() => { setInputProperties({ action: 'remove', value: '', item_index: -1, response_index: response_index }); toggleConfirmationDialogBox() }}><i className="mdi mdi-3x mdi-delete" /></button>
                                            </td>
                                        </tr>
                                    ) 
                                })
                            }
                        </tbody>
                        {
                            data.other.calculateTotal !== null
                                ?
                                <tfoot>
                                    <tr>
                                        <td colSpan={data.other.numberOfColumns-2} style={{ textAlign: 'right' }}><b><i>Total:</i></b></td>
                                        <td colSpan={2} style={{ textAlign: 'center' }}><b><i>{data.other.calculateTotal.total} {data.other.calculateTotal.append}</i></b></td>
                                    </tr>
                                </tfoot>
                                :
                                <React.Fragment></React.Fragment>
                        }
                    </table>
                </div>
                <div className="card-footer text-right py-2" style={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) && data.other.hasAddAction ? {} : {display: 'none'}}>
                    <button className="btn btn-space btn-primary m-0" onClick={() => { updateState('add', '', -1, -1) }}><i className="icon icon-left mdi mdi-plus" style={{marginBottom: 3}} />{data.other.addActionText}</button>
                </div>
            </div>
            {/* Helper Components */}
            <CommonCustomModal modal={confirmationDialogBoxProperties.visibility} title='' toggler={toggleConfirmationDialogBox} heading={confirmationDialogBoxProperties.heading} buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <ConfirmationDialogBox data={{confirmationText: confirmationDialogBoxProperties.text, confirmationNegativeButtonText: confirmationDialogBoxProperties.negativeButtonText, confirmationPositiveButtonText: confirmationDialogBoxProperties.positiveButtonText, confirmation}} />
            </CommonCustomModal> 
        </React.Fragment>
    )
}

export default Other