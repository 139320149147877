import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from "react-router-dom";
import Layout from '../../Layout';
import CommonCustomModal from '../Common/CommonCustomModal';
import EditVerifier from './EditVerifier';
import UpGradeVerifier from './UpGrade';
import DownGradeVerifier from './DownGrade';
import RenewVerifier from './Renew';
import AddVerifierExam from './Exam/AddVerifierExam';
import AddInvoice from './Invoice/AddInvoice';
import AddComment from './Comment/AddComment';
import AddCommunication from './Communication/AddCommunication';
import AddDocument from './Document/AddDocument';
import EditVerifierExam from './Exam/EditVerifierExam';
import EditVerifierInvoice from './Invoice/EditVerifierInvoice';
import EditCommunication from './Communication/EditCommunication';
import ArchiveUser from './ArchiveVerifier';
import config from '../../api/config';
import apiconfig from '../../api/apiconfig';
import { Slide, ToastContainer, toast } from 'react-toastify';
import AddSponsorsToVerifier from './AddSponsorsToVerifier';
import VerifierDetailsTab from './VerifierDetailsTab';
import RequirementNotMet from './RequirementNotMet';
import RequirementMet from './RequirementMet';
import RemovedVerifier from './RemovedVerifier';
import EditVerifierAndSponsor from './EditVerifierAndSponsor';
import CommonModalWithImage from '../Common/CommonModalWithImage';
import { Label } from 'reactstrap';
import AssignedVC from './AssignedVC';
import RenewManual from './RenewManual';
import ActivateVerifier from './ActivateVerifier';
import UpGradeReqNotMetVerifier from './UpGradeReqNotMet';
import RenewLevelAndReason from './RenewLevelAndReason';
import IsAccessForVerifierCombination1 from '../Common/IsAccessForVerifieCombination1';
import IsAccessForVerifierCombination2 from '../Common/IsAccessForAccreditationCombination';



const ViewVerifier = () => {
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const externalUserID=userRoles.length === 1?userRoles[0].internalUserID:0;
    const isExternalVerifierUser = userRoles.length === 1 && userRoles[0].name === "Verifier User";
    const navigate = useNavigate();
    const [editVerifierModal, setEditVerifierModal] = useState(false);
    const [upGradeModal, setUpGradeModal] = useState(false);
    const [downGradeModal, setDownGradeModal] = useState(false);
    const [renewModal, setRenewModal] = useState(false);
    const [addExamModal, setAddExamModal] = useState(false);
    const [editExamModal, setEditExamModal] = useState(false);
    const [addInvoiceModal, setAddInvoiceModal] = useState(false);
    const [editInvoiceModal, setEditInvoiceModal] = useState(false);
    const [addCommentModal, setAddcommentModal] = useState(false);
    const [addCommunicationModal, setAddCommunicationModal] = useState(false);
    const [editCommunicationModal, setEditCommunicationModal] = useState(false);
    const [addDocumentModal, setAdddocumentModal] = useState(false);
    const [archiveVerifier, setArchiveVerifier] = useState(false);
    const [addSponsorModal, setAddSponsorModal] = useState(false);
    const [requirementMetModal, setRequirementMetModal] = useState(false);
    const [reqNotMet, setReqNotMet] = useState(false);
    const [removedModal, setRemovedModal] = useState(false);
    const [assignedVCModal, setAssignedVCModal] = useState(false);
    const [removedVCModal, setRemovedVCModal] = useState(false);
    const { id } = useParams();

    const [refetch, setRefetch] = useState(false);
    const [clientId, setClientId] = useState(0);
    const [verifierName, setverifierName] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [country, setcountry] = useState("");
    const [region, setregion] = useState("");
    const [dojp, setdojp] = useState("");
    const [dateJoinedP, setdateJoinedP] = useState("");
    const [verifierStatus, setVerifierStatus] = useState("");
    const [sponsorClientID, setSponsorClientID] = useState("");
    const [sponsorName, setSponsorName] = useState("");
    const [sponsorID, setSponsorID] = useState("");
    const [activateModal, setActivateModal] = useState(false);
    const [reasonCode, setReasonCode] = useState("");
    const [accrediationLevel, setAccrediationLevel] = useState("");
    const userID = localStorage.getItem("userID");
    const [assignedVC, setAssignedVC] = useState("");
    const [renewalDate, setRenewalDate] = useState("");
    const [isArchivedVC, setIsArchivedVC] = useState("");
    const [isRenewManual, setIsRenewManual] = useState(false);
    const [sponsorType, setSponsorType] = useState("");
    const [rejoinedDate, setRejoinedDate] = useState("");
    const [isRemoved, setIsRemoved] = useState(false);
    const [isSuspended, setIsSuspended] = useState("");
    const [isUpgrade, setIsUpgrade] = useState("");
    const [isRenewalLapse, setIsRenewalLapse] = useState("");
    const [upGradeReqNotMetModal, setUpGradeReqNotMetModal] = useState(false);
    const [IsRenewalApplicableFor2Attempt, setIsRenewalApplicableFor2Attempt] = useState("");
    const [verifierUserID, setVerifierUserID] = useState("");
    const [renewColor, setRenewColor] = useState("");
    const [isRenew, setIsRenew] = useState("");

    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    const isReadOnlyAccess2 = IsAccessForVerifierCombination2();
console.log(isReadOnlyAccess1);
console.log(isExternalVerifierUser);
    const [isExternalUser, setIsExternalUser] = useState(false);
    const toggleForEditVerifierModal = () => {
        setEditVerifierModal(!editVerifierModal);
    }
    const toggleForUpGradeModal = () => {
        setUpGradeModal(!upGradeModal);
    }

    const toggleForDownGradeModal = () => {
        setDownGradeModal(!downGradeModal);
    }

    const toggleForRenewLevelAndReasonModal = () => {
        setRenewModal(!renewModal);
    }

    const toggleForAddExamModal = () => {
        setAddExamModal(!addExamModal);
    }

    const toggleForEditExamModal = () => {
        setEditExamModal(!editExamModal);
    }

    const toggleForAddInvoiceModal = () => {
        setAddInvoiceModal(!addInvoiceModal);
    }

    const toggleForEditInvoiceModal = () => {
        setEditInvoiceModal(!editInvoiceModal);
    }

    const toggleForAddCommentModal = () => {
        setAddcommentModal(!addCommentModal);
    }


    const toggleForAddCommunicationModal = () => {
        setAddCommunicationModal(!addCommunicationModal);
    }

    const toggleForEditCommunicationModal = () => {
        setEditCommunicationModal(!editCommunicationModal);
    }

    const toggleForAddDocumentModal = () => {
        setAdddocumentModal(!addDocumentModal);
    }

    const toggleForArchiveVerifierModal = () => {
        setArchiveVerifier(!archiveVerifier);
    }

    const toggleForAddSponsorModal = () => {
        setAddSponsorModal(!addSponsorModal);
    }

    const toggleForReqNotmetModal = () => {
        setReqNotMet(!reqNotMet);
    }

    const toggleForRequirementMetModal = () => {
        setRequirementMetModal(!requirementMetModal);
    }
    const toggleForRemovedModal = () => {
        setRemovedModal(!removedModal);
    }

    const toggleForActivateModal = () => {

        setActivateModal(!activateModal);
    }

    const toggleForAssignedVCModal = () => {
        setAssignedVCModal(!assignedVCModal);
    }
    const toggleForRemovedVCModal = () => {
        setRemovedVCModal(!removedVCModal);
      } 
      const toggleForUpGradeReqNotMetModal = () => {
        setUpGradeReqNotMetModal(!upGradeReqNotMetModal);
    } 


if(id!==externalUserID && isExternalVerifierUser)
{
    navigate("/UnAuthorizedUser");
}


    //get Verifier DetailsByID
    useEffect(() => {
        fetch(`${config.apiUrl}${apiconfig.getVerifierDetailsByID}${id}`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                setClientId(data.clientID);
                setverifierName(data.verifierName);
                setaddress1(data.addressLine1);
                setaddress2(data.addressLine2);
                setcity(data.city);
                setzipcode(data.zipcode);
                setphone(data.phoneNumber);
                setemail(data.email);
                setcountry(data.country);
                setregion(data.region);
                setdojp(data.applicationReceiveDate);
                setVerifierStatus(data.verifierStatus);
                setSponsorClientID(data.sponsorClientID);
                setSponsorName(data.sponsorName);
                setSponsorID(data.sponsorID);
                setdateJoinedP(data.dateJoinedProgram);
                setReasonCode(data.reasonCode);
                setAccrediationLevel(data.accredationLevel);
                setAssignedVC(data.assignedVC);
                setIsArchivedVC(data.archivedVC);
                setRenewalDate(data.renewalDate);
                setSponsorType(data.sponsorType);
                setRejoinedDate(data.dateRejoinedProgram);
                setIsRemoved(data.isRemoved);
                setIsSuspended(data.isSuspended);
                setIsUpgrade(data.isUpgrade);
                setIsRenewalLapse(data.isRenewalLapse);
                setIsRenewalApplicableFor2Attempt(data.isRenewalApplicableFor2Attempt);
                setVerifierUserID(data.userID);
                setRenewColor(data.renewalLapseColor);
                setIsRenew(data.isRenew);
            }).catch((err) => {
                navigate("/error-page");
            });
    }, [id, refetch])

    // Activate Verifier
    const activateVerifier = async () => {
        const requestBody = {
            verifierID : verifier.verifierDetailID,
            noteDescription : '',
            userID : userID,
            actionType : "Activate"
  
  
        };
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.updateVerifierStatus}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier has been Activated successfully.')
            {
                toast.success('Verifier has been Activated successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
                setRefetch(!refetch);
            toggleForActivateModal();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
  
      };

      // Remove VC
      const removeVC = async () => {
        const requestBody = {
            verifierID : verifier.verifierDetailID,
            userID : userID,
            actionType : "Remove"
  
        };
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.assignRemoveVC}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'VC removed successfully.')
            {
                toast.success('VC removed successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
                setRefetch(!refetch);
            toggleForRemovedVCModal();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
  
      };


            // Roles List By User
  useEffect(() => {
    if(verifierUserID){
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${config.apiUrl}${apiconfig.getRoleListByUser}${verifierUserID}`,
          {
            method: "GET",
            headers: {

              Authorization: `Bearer ${localStorage.getItem("token")}`

          }
          }
        ).then((response) => {
          return response.json();
        });
        let fetchedUsers = [];
        for (let i = 0; i < response.length; i++) {
          fetchedUsers.push(response[i]);
        }
        if(fetchedUsers.length === 1 && fetchedUsers[0].name === "Verifier User"){
            setIsExternalUser(true);
        }
      } catch (error) {
        navigate("/error-page");
        console.log("Error: ", error);
      }
    };
    fetchData();
}
  }, [verifierUserID]);



  const verifier = {
      verifierDetailID: id,
      verifierName: verifierName,
      verifierStatus: verifierStatus,
      sponsor: sponsorName,
      sponsorClientID: sponsorClientID,
      sponsorID: sponsorID,
      accreditationLevel:accrediationLevel
  }

    const formattedDate = dojp ? dojp.split('T')[0].split('-').reverse().join('-') : '';
    const JoinedFormattedDate = dateJoinedP ? dateJoinedP.split('T')[0].split('-').join('-') : '';
    return (
        <>
            <Layout>


                <div className="be-content">
                    <div className="main-content container-fluid p-0">
                        <div className="page-header ph-orange mb-4">
                            <div className="container-header">
                                <div className="row">
                                    <div className="col">
                                        <img src={require('../../img/ACA-logo.png')} alt="ACALogo" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">Verifier Details</h3>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end">

                                    </div>
                                </div>
                                <nav aria-label="breadcrumb" role="navigation">
                                    <ol className="breadcrumb mb-0 mt-4">
                                       
                                        {
                                            !isExternalVerifierUser ? 
                                            <>
                                             <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                            <li className="breadcrumb-item"><a href="/verifiers">Verifier List</a></li>
                                            <li className="breadcrumb-item active">Verifier Details</li>
                                            </>
                                            :
                                            <>
                                             {/* <li className="breadcrumb-item"><a href="/VerifierDashboard"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li> */}
                                             </>
                                        }
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        {verifierName==null?
                        <>
                         <div className="col d-flex text-center">
                         No Details Found
                                    </div>
                       
                        </>
                        :
                        <div className="container-header">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card card-table">
                                  
                                        <div className="card-header">
                                        {isReadOnlyAccess1 === 'FullAccess'  || isExternalVerifierUser  ?
                                            <div className="tools">
                                                {/* <div className="text-right actions"> */}
                                                <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="mdi mdi-more"></i>
                                                    <span className="sr-only">Menu</span>
                                                </a>
                                                <div className="dropdown-menu">
                                                {isReadOnlyAccess1 === 'FullAccess' ?          
                                                <>  
                                                    {(verifierStatus === 'Prospective' || verifierStatus === 'Pending') ? (
                                                        <>
                                                          {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRequirementMetModal(); }}><span className="icon mdi mdi-check-circle"></span>Requirement Met</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForReqNotmetModal(); }}><span className="icon mdi mdi-close-circle"></span>Requirements Not Met</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                              </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                }
                                                        </>
                                                    ) : verifierStatus === 'Requirements Not Met' ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRequirementMetModal(); }}><span className="icon mdi mdi-check-circle"></span>Requirement Met</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
                                                            {accrediationLevel ==='Level 1-3+' && (
                                                            sponsorName === '' ?
                                                            <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                            :
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForUpGradeReqNotMetModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                            )}
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                        </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                    ) : verifierStatus === 'Requirements Met' ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                            {IsRenewalApplicableFor2Attempt ==='Yes' ? 
                                                            (sponsorName === ''  ?
                                                            <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-refresh"></span>Renew</a>
                                                            :
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRenewLevelAndReasonModal(); }}><span className="icon mdi mdi-refresh"></span>Renew</a>
                                                            )
                                                            :
                                                            (sponsorName === '' ?
                                                            <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                            :
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForUpGradeModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                            
                                                            )
                                                            }
                                                         </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                    ) : (verifierStatus === 'Upgrading - Invoice' || verifierStatus === 'Renewing - Invoice') ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                         </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                    </>
                                                    ) : (verifierStatus === 'Upgrading - Exam' || verifierStatus === 'Renewing - Exam') ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                       </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                    ) : verifierStatus === 'Removed' ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForActivateModal(); }}><span className="icon mdi mdi-check-circle"></span>Activate</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                        </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                    ) : verifierStatus === 'Approved' ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                            {/* {(sponsorName !== '' &&  accrediationLevel !=='Level 4-4+' && isUpgrade === 'Yes') && ( */}
                                                            {( accrediationLevel !=='Level 4-4+') && (
                                                                sponsorName === '' ?
                                                                <a className="dropdown-item" style={{ cursor: 'not-allowed'}}  title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                                :
                                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForUpGradeModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                            )}

                                                            {accrediationLevel ==='Level 4-4+' && (
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }}  onClick={() => { toggleForDownGradeModal(); }}><span className="icon mdi mdi-trending-down"></span>Downgrade</a>
                                                            )} 

                                                            {/* {isRenew === 'Yes'  && */}
                                                            {
                                                                sponsorName === ''  ?
                                                                <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-refresh"></span>Renew</a>
                                                                :
                                                                <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRenewLevelAndReasonModal(); }}><span className="icon mdi mdi-refresh"></span>Renew</a>
                                                            }
                                                         </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                    ) : verifierStatus === 'Due for Renewal' ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                            {sponsorName === '' ?
                                                            <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                            :
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForUpGradeModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
                                                            } 
                                                            
                                                            {sponsorName === ''  ?
                                                            <a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-refresh"></span>Renew</a>
                                                            :
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRenewLevelAndReasonModal(); }}><span className="icon mdi mdi-refresh"></span>Renew</a>
                                                            }
                                                         </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                    ) : verifierStatus === 'Expired' ? (
                                                        <>
                                                         {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                            <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
                                                            {assignedVC==='Not Set' ? 
                                                            (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                            :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                            }
                                                         </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                    ) :
                                                        (
                                                            <>
                                                            {
                                                        !isExternalVerifierUser ? 
                                                        <>
                                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                                <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
                                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                                <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
                                                                {assignedVC==='Not Set' ? 
                                                                (<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
                                                                :(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
                                                                }
                                                           </>
                                                    :
                                                    <>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                    </>
                                                    }
                                                      </>
                                                        )}
                                                </>  
                                                :
                                                isExternalVerifierUser ? 
                                                <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
                                                :
                                                <div></div>
                                                }
                                                </div>
                                                {/* </div> */}
                                            </div>
                                            :
                                            <div></div>
                                        }

                                        </div>
                                      
                                        <div className="row">
                                            <div className="form-row">

                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_SponsorName">Verifier Name :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_SponsorName" className="text-break">{verifierName}</label>
                                                </div>
                                                {/* <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_SponsorName">Client ID :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_SponsorName">{clientId}</label>
                                                </div> */}
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Address1" className="text-break">Address Line 1 :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Address1">{address1}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Address2" className="text-break">Address Line 2(Opt) :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Address2">{address2}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_City">City :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_City" className="text-break">{city}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Zip">Zip/Postcode :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Zip" className="text-break">{zipcode}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_PhoneNumber">Phone Number :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_PhoneNumber" className="text-break">{phone}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Email">Email :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Email">{email}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Region">Region :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_VerifierName">{region}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Country">Country :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_Country">{country}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ">Application Received Date :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ">{formattedDate}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ">Date Joined Programme :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ">{JoinedFormattedDate}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ">Status :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                {
                                                    verifierStatus === 'Removed' ?
                                                    <label className="badge badge-danger" htmlFor="input_DOJ">{verifierStatus}</label>
                                                    :verifierStatus === 'Expired' ?
                                                    <label className="badge badge-primary" htmlFor="input_DOJ">{verifierStatus}</label>
                                                    :verifierStatus === 'Requirements Not Met' ?
                                                    <label className="badge badge-warning" htmlFor="input_DOJ">{verifierStatus}</label>
                                                    :verifierStatus  === 'Requirements Met' ?
                                                    <label className="badge badge-info" htmlFor="input_DOJ">{verifierStatus}</label>
                                                    :
                                                    <label className="badge badge-success" htmlFor="input_DOJ">{verifierStatus}</label>
                                                }
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ">Assigned VC :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    {isArchivedVC ==='Not Archived'?
                                                    <label htmlFor="input_DOJ">{assignedVC}</label>
                                                    :
                                                    <label className="badge bg-secondary">{assignedVC}</label>
                                                    }
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">Accreditation Level :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">{accrediationLevel}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">Sponsor Type :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">{sponsorType}</label>
                                                </div>

                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">Renewal Lapse(s)? :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label className={renewColor}>{isRenewalLapse}</label>           
                                                </div>
                                                {reasonCode !== '' && (
                                                <>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ">Reason Code :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="input_DOJ" className="text-break">{reasonCode}</label>
                                                </div>
                                                </>
                                                )}
                                            </div>
                                            <div className="form-row">
                                            {isRemoved === 1 && (
                                                <>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">Date Re-joined Programme :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">{rejoinedDate}</label>
                                                </div>
                                                </>
                                            )}
                                            <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">Suspension(s)? :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">{isSuspended}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                            
                                            <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">Eligible for Upgrade? :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">                           
                                                    <label htmlFor="acc_Level">{isUpgrade}</label>
                                                </div>
                                            </div>
                                            {isReadOnlyAccess1 === 'FullAccess' ?
                                            <div className="form-row">                                           
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">View User Profile :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level"><a style={{ color: "blue" }} href={`/UserDetails/${verifierUserID}`}>View User Profile</a></label>
                                                </div>
                                            </div>
                                            :
                                            isReadOnlyAccess1 === 'ReadOnlyAccess'  ?
                                            <div className="form-row">                                           
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level">View User Profile :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="acc_Level"><a style={{ color: "grey", pointerEvents:'none'}}   href={`/UserDetails/${verifierUserID}`} >View User Profile</a></label>
                                                </div>
                                            </div>
                                            :
                                            <div>

                                            </div>
                                            }
                                        </div>
                                        
                                        <VerifierDetailsTab verifierID={id} verifierStatus = {verifierStatus} refetchs={refetch} setRefetchs={setRefetch} isExternalVerifierUser={isExternalVerifierUser} />

                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>




                <ToastContainer />
            </Layout>
            <CommonCustomModal modal={editVerifierModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForEditVerifierModal} heading='Edit Verifier' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditVerifierAndSponsor toggler={toggleForEditVerifierModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={upGradeModal} title='' toggler={toggleForUpGradeModal} heading='Upgrade Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <UpGradeVerifier verifier={verifier} toggler={toggleForUpGradeModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={downGradeModal} title='' toggler={toggleForDownGradeModal} heading='Downgrade Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <DownGradeVerifier toggler={toggleForDownGradeModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={renewModal} title='' toggler={toggleForRenewLevelAndReasonModal} heading='' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
            {isRenewManual ?
                <RenewManual toggler={toggleForRenewLevelAndReasonModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} />
            :
                <RenewLevelAndReason toggler={toggleForRenewLevelAndReasonModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} isRenewalApplicableFor2Attempt = {IsRenewalApplicableFor2Attempt} />
            }
            </CommonCustomModal>

            <CommonCustomModal modal={addExamModal} title='' toggler={toggleForAddExamModal} heading='Add Exam for Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddVerifierExam toggler={toggleForAddExamModal} />
            </CommonCustomModal>

            <CommonCustomModal modal={editExamModal} title='' toggler={toggleForEditExamModal} heading='Edit Exam for Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditVerifierExam toggler={toggleForEditExamModal} />
            </CommonCustomModal>

            <CommonCustomModal modal={addInvoiceModal} title='' toggler={toggleForAddInvoiceModal} heading='Add Verifier Exam Invoice' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddInvoice toggler={toggleForAddInvoiceModal} />
            </CommonCustomModal>

            <CommonCustomModal modal={editExamModal} title='' toggler={toggleForEditInvoiceModal} heading='Edit Verifier Exam Invoice' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditVerifierInvoice toggler={toggleForEditInvoiceModal} />
            </CommonCustomModal>

            <CommonCustomModal modal={addCommentModal} title='' toggler={toggleForAddCommentModal} heading='Add Notes for Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddComment verifier={verifier} toggler={toggleForAddCommentModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>


            <CommonCustomModal modal={addCommunicationModal} title='' toggler={toggleForAddCommunicationModal} heading='Add Communication for Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddCommunication verifier={verifier} toggler={toggleForAddCommunicationModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={editCommunicationModal} title='' toggler={toggleForEditCommunicationModal} heading='Edit Communication for Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditCommunication toggler={toggleForEditCommunicationModal} />
            </CommonCustomModal>

            <CommonCustomModal modal={addDocumentModal} title='' toggler={toggleForAddDocumentModal} heading='Add Document for Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddDocument verifier={verifier} toggler={toggleForAddDocumentModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={archiveVerifier} title='' toggler={toggleForArchiveVerifierModal} heading='Archive Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <ArchiveUser toggler={toggleForArchiveVerifierModal} />
            </CommonCustomModal>

            <CommonCustomModal modal={addSponsorModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAddSponsorModal} heading='Add Sponsor' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddSponsorsToVerifier verifier={verifier} toggler={toggleForAddSponsorModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={reqNotMet} title='' toggler={toggleForReqNotmetModal} heading='Requirements Not Met' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <RequirementNotMet verifier={verifier} toggler={toggleForReqNotmetModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={requirementMetModal} title='' toggler={toggleForRequirementMetModal} heading='Requirement Met' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <RequirementMet toggler={toggleForRequirementMetModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <CommonCustomModal modal={removedModal} title='' toggler={toggleForRemovedModal} heading='Remove Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <RemovedVerifier toggler={toggleForRemovedModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <CommonCustomModal modal={activateModal} title='' toggler={toggleForActivateModal} heading='Activate Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                    {/* <Label className="col-form-label">{'Are you sure you want to Activate the Verifier '}{'?'}</Label> */}
                    <ActivateVerifier toggler={toggleForActivateModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <CommonCustomModal modal={assignedVCModal} title='' toggler={toggleForAssignedVCModal} heading='Assign VC to a Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AssignedVC toggler={toggleForAssignedVCModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <CommonModalWithImage modal={removedVCModal} title='' toggler={toggleForRemovedVCModal} heading='Remove VC from a Verifier' triggerMethodOnSaveButtonClick={removeVC} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to Remove VC '}{'?'}</Label>
            </CommonModalWithImage>
            <CommonCustomModal modal={upGradeReqNotMetModal} title='' toggler={toggleForUpGradeReqNotMetModal} heading='Upgrade Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <UpGradeReqNotMetVerifier verifier={verifier} toggler={toggleForUpGradeReqNotMetModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
        </>

    );
};

export default ViewVerifier;