import React from 'react';

export const TableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Currency',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Currency'
    },
    {
        id: 2,
        isHidden: false,
        name: 'Type Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'TypeName'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Level Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'LevelName',
        type:'dropdown'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Band Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'BandName',
        type:'dropdown'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Three Yearly Year',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'ThreeYearlyYear'
    },
    {
        id: 6,
        isHidden: false,
        name: 'General Aviation',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'GeneralAvaition'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Group Fee',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '8%' },
        isSortable: true,
        sortByColumnName: 'GroupDiscount',
        type:'dropdown'
    },
    {
        id: 8,
        isHidden: false,
        name: 'Certification Fee',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'CertificationFee',
        type:'dropdown'
    },
    {
        id: 9,
        isHidden: false,
        name: 'Fee',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '5%' },
        isSortable: true,
        sortByColumnName: 'Fee',
        type:'dropdown'
    },
    // {
    //     id: 8,
    //     isHidden: false,
    //     name: 'Actions',
    //     colSpan: 1,
    //     hasChild: false,
    //     child: null,
    //     style: { width: '1%' },
    //     isSortable: false,
    //     sortByColumnName: null
    // }
];