import React, { useState, useEffect } from "react"
import Layout from "../../../Layout"
import Permission from "./Permission"
import { TableHeaderCells } from "./TableHeaderCells"
import CommonModal from "../../Common/CommonModal"
import SearchBar from "../../Common/SearchBar"
import Card from "../../Common/Card"
import Pagination from "../../Common/Pagination"
import Selector from "../../Common/Selector"
import Table from "../../Common/Table"
import { Form, FormGroup, Input, Label } from "reactstrap"
import { MdOutlineAdminPanelSettings } from "react-icons/md"
import { TiArrowUnsorted } from "react-icons/ti"
import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import SortArray from "../../Helper/SortArray"
import Loader from "../../Common/Loader"

const Permissions = () => {
  
  /* States & Constants */
  const [searchName, setSearchName] = useState("")
  const [permissions, setPermissions] = useState([])
  const [refetch, setRefetch] = useState(false)
  const [addPermissionModal, setAddPermissionModal] = useState(false)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [currentItems, setCurrentItems] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [sortOrder, setSortOrder] = useState(true)
  const [sortColumn, setSortColumn] = useState("")
  const [isSortTriggered, setIsSortTriggered] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const userID = localStorage.getItem("userID")
  const numberOfEntriesPerPageOptions = [
    { id: 1, value: 5 },
    { id: 2, value: 10 },
    { id: 3, value: 20 },
    { id: 4, value: 50 },
    { id: 5, value: 100 },
  ]
  const [isLoading, setIsLoading] = useState(false)

  /* Effects */
  useEffect(() => {
      const fetchData = async () => {
          try {
              setIsLoading(true)
              const response = await fetch(`${config.apiUrl}${apiconfig.getPermissions}`,{
                  method: "GET",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                  }
              }).then((response) => {
                  return response.json()
              })
              let fetchedPermissions = []
              for (let i = 0; i < response.length; i++) {
                  fetchedPermissions.push(response[i])
              }
              setPermissions(fetchedPermissions)
              setIsLoading(false)
          } 
          catch (error) {
              console.log("Error: ", error)
          }
      }
      fetchData()
  }, [refetch])
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage
    let searchedPermissions = []
    for (let i = 0; i < permissions.length; i++) {
      if (
        permissions[i].name
          .toLowerCase()
          .includes(searchName.toLowerCase().trim())
      ) {
        searchedPermissions.push(permissions[i])
      }
    }
    if (sortColumn !== "") {
      let sortedPermissions = []
      if (sortOrder) {
        sortedPermissions = SortArray(searchedPermissions, sortColumn, -1)
      } else if (!sortOrder) {
        sortedPermissions = SortArray(searchedPermissions, sortColumn)
      }
      searchedPermissions = sortedPermissions
    }
    setCurrentItems(searchedPermissions.slice(itemOffset, endOffset))
    setPageCount(Math.ceil(searchedPermissions.length / itemsPerPage))
  }, [itemOffset, itemsPerPage, permissions, searchName, isSortTriggered])

  /* Methods */
  const addPermission = async () => {
    const requestBody = {
      name: name,
      description: description,
      userID: userID,
    }
    const validationErrors = validateForm(name)
    setFormErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch(
          `${config.apiUrl}${apiconfig.createPermission}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody),
          }
        ).then((response) => {
          return response
        })
        setRefetch(!refetch)
      } catch (error) {
        console.log("Error: ", error)
      }
      toggleForAddPermissionModal()
    }
  }
  const toggleForAddPermissionModal = () => {
    formErrors.permission=''
    setAddPermissionModal(!addPermissionModal)
  }
  const onPageClick = (e) => {
    setPageNumber(e.selected + 1)
    const newOffset = (e.selected * itemsPerPage) % permissions.length
    setItemOffset(newOffset)
  }
  const validateForm = (permission) => {
    //Add form validation
    const errors = {}
    if (!permission) {
      errors.permission = "Permission Name is required!"
    }
    return errors;
  }

    return (
        <Layout>
            {
                isLoading
                    ?
                    <Loader />
                    :
                    <React.Fragment></React.Fragment>  
            }
            <div className="page-header blue mb-4">
                <div className="container-header">
                    <div className="row">
                        <div className="col">
                            <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center">
                            <h3 className="page-header-title">Permissions</h3>
                        </div>
                        <div className="col d-flex align-items-center justify-content-end">
                            
                        </div>
                    </div>
                    <nav aria-label="breadcrumb" role="navigation">
                        <ol className="breadcrumb mb-0 mt-4">
                            <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                            <li className="breadcrumb-item active">Permissions</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container-header">
                <h3 className="page-header-title">Search Permissions</h3>
                <Card title="" hasButton={false} toggler={toggleForAddPermissionModal} icon={<MdOutlineAdminPanelSettings />} buttonText="Add Permission">
                <div className="row">
                  <div className="col-md-6 col-lg-7">
                  <SearchBar searchState={searchName} searchStateSetter={setSearchName} />
                  </div>
                  <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                    <nav>
                    <Selector
                    hasPrefixLabel={true}
                    hasSuffixLabel={true}
                    hasIcon={true}
                    prefixLabel="Show"
                    suffixLabel="Entries"
                    icon={<TiArrowUnsorted />}
                    value={itemsPerPage}
                    setter={setItemsPerPage}
                    options={numberOfEntriesPerPageOptions}
                  />
                    </nav>
                  </div>
                  </div>
                  <Table
                    headerCells={TableHeaderCells}
                    setSortColumn={setSortColumn}
                    setIsSortTriggered={setIsSortTriggered}
                    isSortTriggered={isSortTriggered}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                  >
                    {currentItems.map((permission) => (
                      <Permission
                        key={permission.id}
                        permission={permission}
                        searchName={searchName.toLowerCase()}
                      />
                    ))}
                  </Table>
                  <div className="d-flex justify-content-end">
                  <Pagination
                    handlePageClick={onPageClick}
                    pageCount={pageCount}
                    pageRange={3}
                    pageNumber={pageNumber}
                  />
                  </div>
                </Card>
            </div>
            {/* MODAL FOR ADDING PERMISSION */}
            <CommonModal
              modal={addPermissionModal}
              title=""
              toggler={toggleForAddPermissionModal}
              heading="ADD PERMISSION"
              triggerMethodOnSaveButtonClick={addPermission}
              buttonText="Save"
            >
              <Form>
                <FormGroup>
                  <Label>Name:</Label>
                  <Input
                    id="name"
                    type="text"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  <p style={{ color: "red" }}>{formErrors.permission}</p>
                </FormGroup>
                <FormGroup>
                  <Label>Description:</Label>
                  <Input
                    id="description"
                    type="text"
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </FormGroup>
              </Form>
            </CommonModal>
        </Layout>
    )
}

export default Permissions