import React, { useState, useEffect } from 'react';

const InputDropdownID = ({ searchFilter, dropdownOptions, searchValueSetter, searchValue }) => {

    return( 
        <select className="form-control" style={{maxWidth: 600, cursor: 'pointer'}} onChange={(e) => {searchValueSetter(e.target.value)}} value={searchValue}>                        
            <option value='default' disabled>{`-- Please Select ${searchFilter} --`}</option>
            {
                dropdownOptions.map((option, index) => {
                    return (
                        <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.text}</option>
                    );
                })
            }
        </select>
    );    
}

export default InputDropdownID;