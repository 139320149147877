import React from "react";
import { Link } from "react-router-dom";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";

const RoleListByUser = ({ userapp, id, refetch, setRefetch }) => {

  const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
  return (
    <>
    
      <tr className="alt-row">
      {isReadOnlyAccess1 === 'FullAccess' || isReadOnlyAccess1 === 'FullAccessForAMSAMOnly'  ?
        <td className="user-avatar">
          <Link style={{ color: "blue" }} to={`/RoleDetails/${userapp.roleID}`} state={{ name: userapp.name,roleUserID:id}}>
            {userapp.name}
          </Link>
        </td>
        :
        <td>{userapp.name}</td>
      }
        {/* <td>{userapp.hierarchy}</td> */}
      </tr>
    </>
  );
};
export default RoleListByUser;
