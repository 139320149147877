import React from 'react';
import { MdEmail, MdLockReset } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import IsAccessForVerifierCombination1 from './IsAccessForVerifieCombination1';

const UserProfileCard = ({ name, email, category,statusID, hasNavigationBack, navigateBackTo, togglerForPasswordReset, toggleForManageApplications,verifierID,isExternalUser }) => {

    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();

    return (
        <div className="card">
            <div className="user-display card-header p-0 m-0">
                <div className="tools dropdown" style={{ position: 'absolute', right: '10px', top: '10px' }}>
                    {
                        hasNavigationBack
                            ?
                            <Link to={navigateBackTo}>
                                <button className="btn mr-1 btn-primary btn-sm" type="button"><BiArrowBack />&nbsp;Back</button>
                            </Link>
                            :
                            <></>
                    } 
                    {/* { statusID===1  && (
                    <>
                    <button className="btn mr-1 btn-primary btn-sm" type="button" onClick={() => { toggleForManageApplications(); }}><FiEdit />&nbsp;Manage Roles</button>
                    <button className="btn mr-1 btn-primary btn-sm" type="button" onClick={() => { togglerForPasswordReset(); }}><MdLockReset />&nbsp;Reset Password</button>
                    </>
                    )} */}

                </div>
                <div className="user-display-bg" style={{ backgroundColor: '#dde9f2' }}>
                    <img className="w-auto" src={require('../../img/profile-bg.png')} alt="Profile Background" />
                </div>
                <div className="user-display-bottom">
                    <div className="user-display-avatar"><img src={require('../../img/avatar1.png')} alt="Avatar" /></div>
                    <div className="user-display-info">
                        <div className="name">
                            <label style={{ fontSize: '20px' }}>{name}</label>
                            &nbsp;
                            <small>{category}</small>
                        </div>
                        <div style={{ fontSize: '14px' }}><MdEmail />&nbsp;{email}</div>
                        {isReadOnlyAccess1 === 'FullAccess' && isExternalUser && (
                        <div style={{ fontSize: '14px' }}><a style={{ color: "blue" }} href={`/viewverifier/${verifierID}`}>View Verifier Profile</a></div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );

};

export default UserProfileCard;