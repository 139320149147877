import React from 'react';

export const TableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Airport Type',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AirportType',
        type:'dropdown'
    },
    {
        id: 2,
        isHidden: false,
        name: 'Airport Group Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'AirportGroup'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Airport Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '20%' },
        isSortable: true,
        sortByColumnName: 'AirportName',
    },
    {
        id: 4,
        isHidden: false,
        name: 'Created Date Time',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'CreatedDate',
    },
    {
        id: 5,
        isHidden: false,
        name: 'Created By',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'CreatedBy',
    },
    {
        id: 6,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AirportStatus',
        type:'dropdown'
    },
    {
        id: 8,
        isHidden: false,
        name: 'Actions',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    }
];

export const ApplicationTableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Application ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'ApplicationID',
    },
    {
        id: 2,
        isHidden: false,
        name: 'Airport Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'AirportName',
    },
    {
        id: 3,
        isHidden: false,
        name: 'Airport Group',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AirportGroup',
    },
    {
        id: 4,
        isHidden: false,
        name: 'Region',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Region',
    },
    {
        id: 5,
        isHidden: false,
        name: 'Application Type',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AirportType',
    },
    {
        id: 6,
        isHidden: false,
        name: 'Reporting Period',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'ReportingPeriod',
    },
    {
        id: 7,
        isHidden: false,
        name: 'Accreditation Level',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'AccreditationLevel',
    },
    {
        id: 8,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'ApplicationStatus',
    },
    {
        id: 9,
        isHidden: false,
        name: 'Accredited Date',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AccreditedDate',
    },
    {
        id: 10,
        isHidden: false,
        name: 'Verifier Assigned',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'VerifierAssigned',
    },
    {
        id: 11,
        isHidden: false,
        name: 'Last Updated',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'UpdatedDate',
    },
    {
        id: 12,
        isHidden: false,
        name: 'Actions',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    }
];

export const AirportsTableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Airport Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'AirportName',
    },
    {
        id: 2,
        isHidden: false,
        name: 'IATA Code',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'IATACode',
    },
    {
        id: 3,
        isHidden: false,
        name: 'Airport Group',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AirportGroup',
    },
    {
        id: 4,
        isHidden: false,
        name: 'Region',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Region',
    },
    {
        id: 5,
        isHidden: false,
        name: 'Country',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Country',
    },
    {
        id: 5,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Status',
    },
    {
        id: 6,
        isHidden: false,
        name: 'Current Accreditation Level',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'AccreditationLevel',
    },

    {
        id: 7,
        isHidden: false,
        name: 'Next Renewal Date',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'NextRenewalDate',
    },
    {
        id: 8,
        isHidden: false,
        name: 'Eligible for 3 Year?',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'Eligiblefor3Year',
    },
    {
        id: 9,
        isHidden: false,
        name: 'Left Programme?',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'LeftProgramme',
    },
    // {
    //     id: 10,
    //     isHidden: false,
    //     name: 'Actions',
    //     colSpan: 1,
    //     hasChild: false,
    //     child: null,
    //     style: { width: '1%' },
    //     isSortable: false,
    //     sortByColumnName: null
    // }
];