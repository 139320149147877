import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Layout from "../../../Layout";
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import CommonCustomModal from "../../Common/CommonCustomModal";
import { Slide, ToastContainer, toast } from 'react-toastify';
import ApproveAirport from "./ApproveAirport";
import RejectAirport from "./RejectAirport";
import IsAccessForAccreditationCombination from "../../Common/IsAccessForAccreditationCombination";




const ViewAccreditationAirportDetails = () => {
    const navigate = useNavigate();
    const [refetch, setRefetch] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");

    const [airportType, setAirportType] = useState("");
    const [airportGroup, setAirportGroup] = useState("");
    const [status, setStatus] = useState("");
    const [region, setRegion] = useState("");
    const [airportName, setAirportName] = useState("");
    const [iataCode, setIataCode] = useState("");
    const [airportCountry, setAirportCountry] = useState("");
    const [airportState, setAirportState] = useState("");
    const [airportCity, setAirportCity] = useState("");
    const [isSingle, setIsSingle] = useState(true);
    const [viewAirportGroupList, setViewAirportGroupList] = useState([]);
    const [referenceUrl, setReferenceUrl] = useState("");
    const [memberCertificate, setMemberCertificate] = useState("");
    const isFullAccess = IsAccessForAccreditationCombination();
    const [AccDetails, setAccDetails] = useState(true);
    const [generalAviation, SetGeneralAviation] = useState("");
    const [airportGroupID, setAirportGroupID] = useState("");




    const { id } = useParams();
    const userID = localStorage.getItem("userID");
    const [loading, setLoading] = useState(true);
    const [rejectModal, setRejectModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
  
    const toggleForRejectModal = () => {
        setRejectModal(!rejectModal);
      } 

      const toggleForApproveModal = () => {
        setApproveModal(!approveModal);
      } 


    const AccDetailsAPi = async () => {
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.getSAccreditationAirportDetailsByID}${id}`,{
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                  },
            });
            if (response.ok) {
                try {
                   const data = await response.json();
                   setFirstName(data.firstName)
                   setLastName(data.lastName)
                   setEmail(data.email)
                   setAddress1(data.address1)
                   setAddress2(data.address2)
                   setCountry(data.corrCountry)
                   setState(data.corrState)
                   setCity(data.corrCity)
                   setPostCode(data.corrPostalCode)
                   setAirportType(data.airportType)
                   setAirportGroup(data.airportGroup)
                   setStatus(data.airportStatus)
                   setRegion(data.region)
                   setAirportName(data.airport)
                   setIataCode(data.iataCode)
                   setAirportCountry(data.country)
                   setAirportState(data.state)
                   setAirportCity(data.city)
                   setReferenceUrl(data.referenceURL);
                   setMemberCertificate(data.aciMembershipDocument);
                   SetGeneralAviation(data.generalAviationText);
                   setAirportGroupID(data.airportGroupID);
                   if(data.airportType === 'Group'){
                    setIsSingle(false);
                   }else{
                    setIsSingle(true);
                   }
                }catch (error) {
                    setAccDetails(false);
                }

                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.getSAccreditationAirportDetailsByID}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
        } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    };


    const ViewGroupList = async () => {

        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.getAccreditationViewAirportGroupList}${airportGroupID}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                const result = await response.json();
                let fetchedList = [];
                for (let i = 0; i < result.length; i++) {
                    fetchedList.push(result[i]);
                }
                setViewAirportGroupList(fetchedList);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAccreditationViewAirportGroupList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }
        catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    };

    useEffect(()=>{
        AccDetailsAPi();
    },[id, refetch])

    useEffect(()=>{
        if(airportGroupID){
        ViewGroupList();
        }
    },[airportGroupID, refetch])

    const accAirport = {
        airportApplicationID: id,
        airportType: airportType + " Airport"
    }

    return (
        <>
            <Layout>
                {/* {loading && <Loader />} */}
                <div className="be-content">
                    <div className="main-content container-fluid p-0">
                        <div className="page-header ph-orange mb-4">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col">
                                        <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">Airport Registration Details</h3>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end">

                                    </div>
                                </div>
                                <nav aria-label="breadcrumb" role="navigation">
                                    <ol className="breadcrumb mb-0 mt-4">
                                        <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                        <li className="breadcrumb-item"><a href="/AccreditationDashboard">Accreditation Dashboard</a></li>
                                        <li className="breadcrumb-item active">Airport Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        {AccDetails === false ?
                        <>
                         <div className="container-fluid">
                                <div className="row">
                                    <div className="col d-flex align-items-center justify-content-center">
                                        <h4 className="page-header-title">No Details Found</h4>
                                    </div>
                                </div>
                        </div>
                       
                        </>
                        :
                        <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                        <div className="card">
                        {isSingle ? (
                            <div className="card-body">
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Airport Type</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportType}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Airport Group</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportGroup}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Airport Status </label>
                                    <div className="col-sm-4">
                                    {
                                        status === 'Approved' ?
                                        <label className="badge badge-success">{status}</label>
                                        :status === 'Rejected' ?
                                        <label className="badge badge-danger">{status}</label>
                                        :
                                        <label className="badge badge-warning">{status}</label>
                                    }
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">ACI Region</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{region}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Airport Name</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportName}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">IATA Code</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{iataCode}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">General Aviation?</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{generalAviation}</label>
                                    </div>
                                </div>
                                {/* <div className="form-group-sm row">
                                <label className="col-sm-2 col-form-label font-weight-bolder">Country</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportCountry}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">State</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportState}</label>
                                    </div>
                                </div> */}
                                {/* <div className="form-group-sm row">
                                <label className="col-sm-2 col-form-label font-weight-bolder">City</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportCity}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">First Name</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{firstName}</label>
                                    </div>
                                </div> */}
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">First Name</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{firstName}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Last Name</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{lastName}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Email Address</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{email}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Address Line 1</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{address1}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Address Line 2</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{address2}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Country</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{country}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">State</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{state}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">City</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{city}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Postcode</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{postCode}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Reference Url</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext "><a style={{cursor:'pointer', color: "blue"}} href={referenceUrl} target='_blank' rel='noreferrer' >{referenceUrl}</a></label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Membership Certificate</label>
                                    <div className="col-sm-4">
                                    { memberCertificate === 'NA' ? 
                                    <label className="form-control-plaintext ">{memberCertificate}</label>
                                    :
                                    <label className="form-control-plaintext "><a style={{cursor:'pointer', color: "blue"}} href={memberCertificate} target='_blank' rel='noreferrer' >{memberCertificate}</a></label>
                                    }
                                   </div>
                                </div>
                            </div>) : 
                                (<>
                                <div className="card-body">
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Airport Type</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportType}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Airport Group</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{airportGroup}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Airport Status </label>
                                    <div className="col-sm-4">
                                    {
                                        status === 'Approved' ?
                                        <label className="badge badge-success">{status}</label>
                                        :status === 'Rejected' ?
                                        <label className="badge badge-danger">{status}</label>
                                        :
                                        <label className="badge badge-warning">{status}</label>
                                    }
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">First Name</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{firstName}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Last Name</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{lastName}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Email Address</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{email}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Address Line 1</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{address1}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Address Line 2</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{address2}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Country</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{country}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">State</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{state}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                    <label className="col-sm-2 col-form-label font-weight-bolder">City</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{city}</label>
                                    </div>
                                    <label className="col-sm-2 col-form-label font-weight-bolder">Postcode</label>
                                    <div className="col-sm-4">
                                    <label className="form-control-plaintext ">{postCode}</label>
                                    </div>
                                </div>
                                <div className="form-group-sm row">
                                <label className="col-sm-2 col-form-label font-weight-bolder">Membership Certificate</label>
                                    <div className="col-sm-4">
                                { memberCertificate === 'NA' ? 
                                    <label className="form-control-plaintext ">{memberCertificate}</label>
                                    :
                                    <label className="form-control-plaintext "><a style={{cursor:'pointer', color: "blue"}} href={memberCertificate} target='_blank' rel='noreferrer' >{memberCertificate}</a></label>
                                    }
                                    </div>
                                </div>
                                </div>  

                                <div className="card-body table-responsive">
                                <table className="table table-striped table-borderless">
                                    <thead>
                                    <tr>
                                        <th>Airport Name</th>
                                        <th>IATA Code</th>
                                        <th>ACI Region</th>
                                        <th>Country</th>
                                        <th>City</th>
                                        <th>State</th>
                                        <th>General Aviation?</th>
                                        <th>Reference Url</th>
                                    </tr>
                                    </thead>
                                    <tbody className="no-border-x">
                                    {
                                        viewAirportGroupList.map((gpList, index) => (
                                            <tr key={index}>
                                                <td>{gpList.airport}</td>
                                                <td>{gpList.iataCode}</td>
                                                <td>{gpList.region}</td>
                                                <td>{gpList.country}</td>
                                                <td>{gpList.city}</td>
                                                <td>{gpList.state}</td>
                                                <td>{gpList.generalAviationText}</td>
                                                <td><a style={{cursor:'pointer', color: "blue"}} href={gpList.referenceURL} target='_blank' rel='noreferrer' >{gpList.referenceURL}</a></td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                                </div>
                                </>
                                )}
                            <div className="card-footer text-right">
                                    <a className="btn btn-secondary mt-auto mr-1" href="/AccreditationDashboard" type="button">Back</a>
                                    {status==='Pending' && (
                                    isFullAccess === 'AccFullAccess' ?
                                    <>
                                    <button className="btn btn-success mt-auto mr-1" onClick={toggleForApproveModal} type="button">Approve</button>
                                    <button className="btn btn-danger mt-auto mr-1" onClick={toggleForRejectModal} type="button">Reject</button>
                                    </>
                                    :
                                    <></>
                                    
                                    )}
                                </div>
                        </div>
                        </div>
                        </div>
                        </div>
}
                    </div>
                </div>
                <ToastContainer />
            </Layout>;
            <CommonCustomModal modal={rejectModal} title='' toggler={toggleForRejectModal} heading='Reject Airport'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <RejectAirport toggler={toggleForRejectModal} accAirport = {accAirport} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <CommonCustomModal modal={approveModal} title='' toggler={toggleForApproveModal} heading='Approve Airport'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <ApproveAirport toggler={toggleForApproveModal} accAirport = {accAirport}  refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
        </>
    );
};

export default ViewAccreditationAirportDetails;
