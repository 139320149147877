import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import { TableHeaderCells } from './TableHeaderCells';
import AdvancedSearch from '../Common/AdvancedSearch';
import Pagination from '../Common/Pagination';
import Selector from '../Common/Selector';
import CustomTable from '../Common/CustomTable';
import { TiArrowUnsorted } from 'react-icons/ti';
import config from '../../api/config';
import apiconfig from '../../api/apiconfig';
import { numberOfEntriesPerPageOptions } from '../Helper/NumberOfEntriesPerPageOptions';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import NotificationDetail from './NotificationDetail';
import Loader from '../Common/Loader';


const NotificationDetails = () => {

    /* States & Constants */
    const navigate = useNavigate();
    const [searchName, setSearchName] = useState('');
    const [notifications, setNotifications] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [sortOrder, setSortOrder] = useState('DESC');
    const [sortByColumnName, setSortByColumnName] = useState('CreatedDate');
    const [columnClickCounter, setColumnClickCounter] = useState(1);
    const [searchCriteria, setSearchCriteria] = useState([]);
    const userID = localStorage.getItem("userID");
    const [searchFilter, setSearchFilter] = useState('Airport Name');
    const [searchType, setSearchType] = useState('text');
    const [searchValue, setSearchValue] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const [isLoading, setIsLoading] = useState(false)
    const isAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User';
    const isPrimaryAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User';
    const isExternalVerifierUser = userRoles.length === 1 && userRoles[0].name === "Verifier User";
    const isSingleCheck = userRoles.length === 1 && userRoles[0].returnType === 'Single User';
    const isGroupCheck = userRoles.length === 1 && userRoles[0].returnType === 'Group User';
    const searchOptions = [
        {
            value: 'Airport Name', 
            text: 'Airport Name',
            type: 'text',
            query: '&AirportName='
        },
        {
            value: 'Application ID', 
            text: 'Application ID', 
            type: 'text',
            query: '&ApplicationID='
        },
        {
            value: 'Created Date', 
            text: 'Created Date', 
            type: 'date',
            query: '&CreatedDate=',
        },
    ]

    /* Methods */
    const fetchData = async () => {
        setIsLoading(true);
        try {
            let filtersQuery = '';
            if(searchCriteria.length > 0){
                filtersQuery = '';
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`);
            }
            const response = await fetch(`${config.apiUrl}${apiconfig.getNotificationDetails}?UserID=${userID}&RequestType=${''}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                const result = await response.json();
                setNotifications(result);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                const errorText = await response.text();
                const apiname = `${apiconfig.getNotificationDetails}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }
        catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    };
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1);
    };
    const onHomeButonClick = () => {
		navigate(-1);
	}

    /* Effects */
    useEffect(() => {
        fetchData();
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria]);
    useEffect(() => {
        if(notifications.length > 0){
            setPageCount(Math.ceil(notifications[0].totalCount / itemsPerPage));
        }
    }, [notifications]);
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(notifications.length !== 0){
            if(notifications[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])
    

    return (
        <Layout>
            <>{isLoading && <Loader />}
            <div className="be-content">
            <div className="main-content container-fluid p-0">
                <div className="page-header ph-lightgreen mb-4">
                    <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">Notification Details</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">
                                {/* <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddSponsorModal(); }}>Sponsors <i className="icon icon-left mdi mdi-plus"></i></button> */}
                            </div>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb mb-0 mt-4">
                            {isExternalVerifierUser ? 
                                    <li className="breadcrumb-item"><a href='/VerifierDashboard'><i className="icon icon mdi mdi-home mdi-4x"></i><span></span></a></li>
                                    :
                                    isAirportUser && isGroupCheck ?
                                    <li className="breadcrumb-item"><a href='/AirportGroupDashboard'><i className="icon icon mdi mdi-home mdi-4x"></i><span></span></a></li>
                                    : 
                                    isAirportUser && isSingleCheck ?
                                    <li className="breadcrumb-item"><a href='/AirportGroupDashboard'><i className="icon icon mdi mdi-home mdi-4x"></i><span></span></a></li>
                                    :
                                    <li className="breadcrumb-item"><a href='/'><i className="icon icon mdi mdi-home mdi-4x"></i><span></span></a></li>

                                  }
                            <li className="breadcrumb-item active">Notification Details</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            
                <div className="container-header">
                    <h3 className="page-header-title">Search Notifications</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            <p className="mb-2 font-weight-normal">
                                {/* <strong>Search</strong>
                                <br /> */}
                                Use the filter categories and search field below to find Notification Details.
                            </p>
                            <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                            {
                                notifications.length > 0
                                    ?
                                    <div className="align-self-center font-weight-bold">{notifications[0].totalCount} items found</div>
                                    :
                                    <div className="align-self-center font-weight-bold">No items found</div>
                            }
                        </div>
                        <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                            <nav>
                                {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                    <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                    <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                                </div> */}
                                <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                            </nav>
                        </div>
                    </div>
                    <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                            {!notifications.length  ?                              
                                <tr className="alt-row">
                                <td colSpan="3">&nbsp; No new notifications at the moment.</td>
                                </tr>
                                :
                               notifications.map((notification, index) => <NotificationDetail key={index} notifications={notification} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)
                            }
                    </CustomTable>
                    <div className="d-flex justify-content-end">
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
            </>
        </Layout>
    );
};

export default NotificationDetails;