import React, { useState,useEffect } from 'react';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineCheckCircle, AiOutlineStop } from "react-icons/ai";
import CommonModal from '../../Common/CommonModal';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { Link } from 'react-router-dom'
import { Slide, ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";


const Email = ({ user, searchName, refetch, setRefetch }) => {
     
    const isAlphaNumber = (event) => {
        const keyCode = event.keyCode || event.which;
          if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
            event.preventDefault();
          }
      };
        return (
            <>
                <tr className="alt-row">
                    <td>{user.emailType}</td>
                    <td>{user.sentDate}</td>
                    <td>{user.recipient}</td>
                    <td style={{ textAlign: 'center' }}>{user.subject}</td>
                    <td style={{ textAlign: 'center' }}>{user.emailStatus }</td>
                </tr>


            </>
        );

};

export default Email;