import React, { useState, useEffect } from 'react';
import Layout from '../../../Layout';
import { useNavigate } from "react-router-dom";
import apiconfig from '../../../api/apiconfig';
import config from '../../../api/config';
import { Slide, ToastContainer, toast } from 'react-toastify';
import LoginNavBar from '../../Login/LoginNavBar';
import Footer from '../../../Layout/Footer';
import TermAndCondition from './TermAndCondition';
import CommonCustomModal from '../../Common/CommonCustomModal';
import AirportGroupExists from './AirportGroupExists';

const AccreditationAirportRegistration = () => {
    const navigate = useNavigate();

    /* STATES */
    const [groupAirport,setGroupAirport] = useState(false);
    const [alreadyAciMember,setAlreadyAciMember] = useState(true);
    const [selectedAirportType, setSelectedAirportType] = useState('Single');
    const [selectedAciMember, setSelectedAciMember] = useState('yes');
    const [iataCodeList, setIataCodeList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [allCountryList, setAllCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [airportList, setAirportList] = useState([]);
    const [airportListWithOutOthers, setAirportListWithOutOthers] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [airportCityList, setAirportCityList] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [postcode, setPostcode] = useState("");
    const [email, setemail] = useState("");
    const [airportIataCode, setAirportIataCode] = useState("");
    const [airportIataCodeText, setAirportIataCodeText] = useState("");
    const [airportRegion, setAirportRegion] = useState("");
    const [airportRegionText, setAirportRegionText] = useState("");
    const [airportName, setAirportName] = useState("");
    const [airportNameText, setAirportNameText] = useState("");
    const [airportCountry, setAirportCountry] = useState("");
    const [airportCountryText, setAirportCountryText] = useState("");
    const [airportCity, setAirportCity] = useState("");
    const [airportCityText, setAirportCityText] = useState("");
    const [airportState, setAirportState] = useState("");
    const [country, setcountry] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [isConfirm, setIsConfirm] = useState(false);
    const [fileSize, setFileSize] = useState(0);
    const [document, setDocument] = useState("");
    const [image, setimages] = useState("");
    const [airportGroupName, setAirportGroupName] = useState("");
    const userID = localStorage.getItem("userID");
    const [rows, setRows] = useState([{}]);
    const [refetch,setRefetch] = useState(false);
    const [groupStateByDefault, setgroupStateByDefault] = useState([]);
    const [groupState, setgroupState] = useState([]);
    const [regionReferenceUrl, setRegionReferenceUrl] = useState("");
    const [alreadyErrorMessage, setAlreadyErrorMessage] = useState("");
    const [tAndCModal, setTAndCModal] = useState(false);
    const [isOthers, setIsOthers] = useState(false);
    const [checkGroupModal, setCheckGroupModal] = useState(false);
    const [generalAviation, setGeneralAviation] = useState("");
    const [generalAviationList, setGeneralAviationList] = useState([]);
    const [generalAviationText, setGeneralAviationText] = useState("");
    const toggleForCheckGroupModal = () => {
        setCheckGroupModal(!checkGroupModal);
      } 
  
    /* API CALLS */
    const getRegionList = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setRegionList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getRegion}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }
    const getAirportList = async (airportCity) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAirportByCity}${airportCity}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                const option = result.map((iata) => ({
                    text: iata.optionName,
                    value: iata.optionID,
                }));
                setAirportList(option);
                // setAirportListWithOutOthers(option.slice(0, -1));
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAirportByRegion}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    } 
    const getIataCodeList = async (airportName) => { 
        try {
            const response = await  fetch(`${config.apiUrl}${apiconfig.getIataCodeByAirport}${airportName}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setIataCodeList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getIataCode}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }
    const getCountryList = async (airportRegion) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryList}${airportRegion}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountryList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }      
    const getAirportCityList = async (airportCountry) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCityListByCountry}${airportCountry}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setAirportCityList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCityListByCountry}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }
    const getAllCountryList = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryL}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setAllCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountryL}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }
    const getCityList = async (country) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCityListByCountry}${country}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setCityList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCityListByCountry}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    } 
    const getRegionReferenceUrlList = async (airportRegion) => {
        try {
            const response =  await fetch(`${config.apiUrl}${apiconfig.getRegionReferenceUrlList}${airportRegion}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setRegionReferenceUrl(result[0].optionName);

            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getRegionReferenceUrlList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }
    const addAirport = async () => {
        const requestBody = {

            "userID": userID,
            "isACIMembership": 1,
            "aciMembershipDocument": image,
            "airportType": selectedAirportType,
            "regionID": groupAirport === false ? airportRegion : 0,
            "airport": groupAirport === false ?  airportNameText : '',
            "iataCode": groupAirport === false ? airportIataCodeText : '',
            "countryID": airportCountry === '' ? 0 : airportCountry ,
            "cityID": airportCity === '' ? 0 : airportCity ,
            "state": airportState,
            "firstName": firstName,
            "lastName": lastName,
            "email": email,
            "address1": address1,
            "address2": address2,
            "corrCountryID":country === '' ? 0 : country,
            "corrState":state,
            "corrCityID":city === '' ? 0 : city,
            "corrPostalCode":postcode,
            "isTermsAndConditionAccepted": 1,
            "airportGroup": airportGroupName,
            "docContent" : document,
            "generalAviation" : groupAirport === false ? generalAviation : 0,
            "accrRegGroup" : groupState
        };
        
        const validationErrors = validateForm(firstName,lastName, address1, address2,country,city,state,postcode,email,airportRegion,airportName,airportIataCode,airportCountry,airportCity,airportState,isConfirm,document,airportGroupName,generalAviation);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.addAccreditationAirport}`,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    body: JSON.stringify(requestBody),
                });
                if (response.ok) {
                    var result = await response.json();
                    if(result.saveErrorMessage === 'New Prospective Airport has been created successfully.'){
                        toast.success('New Prospective Airport has been created successfully.', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide
                        });
                        setTimeout(function(){
                            window.location.href = "/login";
                        }, 3500);   
                    }else if(result.saveErrorMessage === 'New Prospective Airport Group has been created successfully.'){
                        toast.success('New Prospective Airport Group has been created successfully.', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide
                        }); 
                        setTimeout(function(){
                            window.location.href = "/login";
                        }, 3500);  
                    }else{
                        setErrorMessage(result.saveErrorMessage);
                    }
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.addAccreditationAirport}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
        }
    }; 
    const addGroupDetails = async () => {
        const validationErrors = validateFormGroup(airportRegion,airportName,airportIataCode==='' ? '-1':airportIataCode,airportCountry,airportCity,airportState,airportNameText,airportIataCodeText,generalAviation);
        const isDuplicate = groupState.some( (group) => group.AirportName === airportNameText );
        if (isDuplicate) {
            setFormErrors({
              ...validationErrors,
              duplicateAirportName: 'Airport name already added.',
            });
        }else {
            setFormErrors(validationErrors,);
            if (Object.keys(validationErrors).length === 0) {
                setgroupState(prevState => [...prevState, { id: groupState.length + 1, AirportRegionID: airportRegion,AirportRegion : airportRegionText,AirportNameID: airportName,AirportName : airportNameText,AirportIataCodeID: airportIataCode==='' ? '-1':airportIataCode, AirportIataCode : airportIataCodeText,AirportCountryID:airportCountry,AirportCountry: airportCountryText,AirportCityID: airportCity,AirportCity: airportCityText,AirportState :airportState,ReferenceUrl:regionReferenceUrl,GeneralAviation:generalAviation,GeneralAviationText:generalAviationText}]);
                setRefetch(!refetch);            
                setAirportRegion('');
                setAirportName('');
                setAirportCountry('');
                setAirportCity('');
                setAirportIataCode('');
                setAirportState('');
                setAirportNameText('');
                setAirportIataCodeText('');
                setCountryList([]);
                setAirportCityList([]);
                // setAirportListWithOutOthers([]);
                setAirportList([]);
                setIataCodeList([]);
                setGeneralAviation('');
                setGeneralAviationText('');
            }
        }
    };
    const checkAirportAlreadyExists = async (airportName) => {
        const requestBody = {
            "airportID": airportName,
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.checkAirportAlreadyExists}`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
            });
            if (response.ok) {
                var result = await response.json();
                if(result.saveErrorMessage === 'Airport already exists.')
                {
                    setAlreadyErrorMessage(result.saveErrorMessage);
                }else{
                    setAlreadyErrorMessage('');
                }
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.checkAirportAlreadyExists}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    };

    const checkAirportGroupAlreadyExists = async () => {

        try {
            const response =  await fetch(`${config.apiUrl}${apiconfig.checkAirportGroupAlreadyExists}${airportGroupName}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            })
            if (response.ok) {
                var result = await response.json();
                if(result.saveErrorMessage === 'Airport Group already exists.')
                {

                    toggleForCheckGroupModal();
                }
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.checkAirportGroupAlreadyExists}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    };

    const getAviationList = async () => {

        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAviationList}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setGeneralAviationList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAviationList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        } 

    /* HOOKS */
    useEffect(()=>{
        getRegionList();
        getAllCountryList()
        getAviationList()
    },[])
    useEffect(()=>{
        if(airportCity){
            getAirportList(airportCity);
        }
    },[airportCity])
    useEffect(() => {  
        if(airportName && alreadyErrorMessage !=='Airport already exists.'){  
            getIataCodeList(airportName);
        }else{
            getIataCodeList(0);
        }
    }, [airportName,alreadyErrorMessage!=='']);
    useEffect(()=>{
        // if(airportRegion && groupAirport){
        //     getCountryList(airportRegion);
        // }
        if(airportRegion){
            getCountryList(airportRegion);
        }
    },[airportRegion,groupAirport])
    useEffect(()=>{
        if(airportCountry){
            getAirportCityList(airportCountry);
        }
    },[airportCountry])
    // useEffect(()=>{
    //     if(airportName){
    //         checkAirportAlreadyExists(airportName);
    //     }
    // },[airportName])    
    useEffect(()=>{
        if(airportRegion){
            getRegionReferenceUrlList(airportRegion);
        }
    },[airportRegion])
    useEffect(()=>{
        if(country){
            getCityList(country);
        }
    },[country])
    // useEffect(()=>{
    //     if(airportCity){
    //         getAirportList(airportCity);
    //     }
    // },[airportCity])
    // useEffect(() => {  
    //     if(airportName){  
    //     getIataCodeList(airportName);
    //     }
    // }, [airportName]);

    /* HELPERS */
    function fileToBase64(file, callback) {
        const reader = new FileReader();
        reader.onload = function(event) {
            const base64String = event.target.result.split(',')[1]; // Extract the base64 data
            callback(base64String);
        };
        reader.readAsDataURL(file);
    }

    /* TOGGLERS */
    const toggleForTAndCModal = () => {
        setTAndCModal(!tAndCModal);
    } 

    /* HANDLE ON CHANGE METHODS */
    const handleAirportRadioChange = (e) => {
        setSelectedAirportType(e.target.value);
        setRegionReferenceUrl("");
        setAlreadyErrorMessage('');
        setFormErrors({});
        if(e.target.value === 'Single'){
            setGroupAirport(false);
            setgroupState([]);
            setAirportRegion('');
            setAirportName('');
            setAirportIataCode('');
            setAirportCountry('');
            setAirportCity('');
            setAirportCityList([]);
            setCountryList([]);
            // setAirportListWithOutOthers([]);
            setIataCodeList([]);
            setAirportState('');
            setGeneralAviation('');
        }else{
            setGroupAirport(true);
            setAirportRegion('');
            setAirportName('');
            setAirportIataCode('');
            setAirportCountry('');
            setAirportCity('');
            setAirportCityList([]);
            setCountryList([]);
            // setAirportListWithOutOthers([]);
            setAirportList([]);
            setIataCodeList([]);
            setAirportState('');
            setGeneralAviation('');
        }
    };
    const handleACiMemberRadioChange = (e) => {
        setSelectedAciMember(e.target.value);
        setRegionReferenceUrl("");
        setAlreadyErrorMessage('');
        setAirportRegion('');
        setAirportName('');
        setAirportIataCode('');
        setcountry('');
        setCity('');
        setFirstName('');
        setLastName('');
        setemail('');
        setaddress1('');
        setaddress2('');
        setState('');
        setPostcode('');
        setFormErrors({});
    };
    const handleDelete = (id) => {
        //groupState.filter(a =>a.id !== id)
        setgroupState(groupState.filter(a =>a.id !== id));
    };
    const handleAirportRegionChange = (e) => {
        setAirportRegion(e.target.value);
        setAirportRegionText(e.target.options[e.target.selectedIndex].text);
        setAirportName('');
        setAirportIataCode('');
        setAirportCity('');
        setAlreadyErrorMessage('');
        if(e.target.value ===''){
            setRegionReferenceUrl("");
            setAirportName('');
            setAirportIataCode('');
            setAirportCountry('');
            setAirportCity('');
            setAirportCityList([]);
            setCountryList([]);
            // setAirportListWithOutOthers([]);
            setAirportList([]);
            setIataCodeList([]);
            setGeneralAviationList([]);
        }
    };
    const handleAirportChange = (e) => {
        setAirportNameText('');
        setAirportIataCodeText('');
        checkAirportAlreadyExists(e.target.value);
        setAirportName(e.target.value);
        setAirportNameText(e.target.options[e.target.selectedIndex].text);
        setAirportIataCode('');
        setGeneralAviation('');
        setAlreadyErrorMessage('');
        setFormErrors({
            duplicateAirportName: '',
        });
        if(e.target.value ===''){
            setAirportIataCode('');
            setGeneralAviation('');
            setAlreadyErrorMessage('');
        }
    };
    const handleAirportCountryChange = (e) => {
        
        setAirportCityList([]);
        setIataCodeList([]);
        // setAirportListWithOutOthers([]);
        setAirportList([]);

        setAirportCity('');
        setAirportState('');
        setAirportName('');
        setAirportIataCode('');
        
        if(e.target.value ===''){
            setAirportCountry('');
            setAirportCountryText('');
        }else{
            setAirportCountry(e.target.value);
            setAirportCountryText(e.target.options[e.target.selectedIndex].text);
        }
    };  
    const handleAirportCityChange = (e) => {
        
        setIataCodeList([]);
        // setAirportListWithOutOthers([]);
        
        setAirportState('');
        setAirportName('');
        setAirportIataCode('');

        setAirportCity(e.target.value);
        setAirportCityText(e.target.options[e.target.selectedIndex].text);
    };
    const handleCountryChange = (e) => {
        setcountry(e.target.value);
        if(e.target.value ===''){
            setCity('');
        }
    };  
    const handleCityChange = (e) => {
        setCity(e.target.value);
    }; 
    const handleConfirm = (e)=>{
        setIsConfirm(e.target.checked);
    }
    const handleFileChange = (event) => {
        setFormErrors({
            ...formErrors,
            document: '',
        });
        // setLoadings(true); 
        const selectedFile = event.target.files[0];
        if(selectedFile !== undefined){
            const fileSize = selectedFile.size;
            setFileSize(fileSize);   
            setimages(selectedFile.name);

            if (selectedFile) {
                fileToBase64(selectedFile, function(base64String) {
                    setDocument(base64String);
                    const validationErrors = validateDocument(base64String,selectedFile.name);
                    setFormErrors(validationErrors);
                });
            }
        }
        else{
            setDocument('');
        }
        //   setLoadings(false); 
    };

    /* VALIDATIONS */
    const validateFormGroup = (airportRegion,airportName,airportIataCode,airportCountry,airportCity,airportState,airportNameText,airportIataCodeText,generalAviation) => {
        const errors = {};

        if(groupAirport === true){
            if(!airportRegion){
                errors.airportGRegion = 'Please select a value.';
            }
            if(airportName === "0" || airportName === ""){
                errors.airportGName = 'Please select a value.';
            }
            if((!airportNameText ||airportNameText ==='Others' ) && airportName === "-1"){
                errors.airportcomments = 'Please enter the airport name.';
            }else if(airportNameText.length > 256 && airportName === "-1"){
                errors.airportcomments = 'Maximum of 256 characters allowed.';
            }
             //else if (!/^[a-zA-Z0-9\s]+$/.test(airportNameText)) {
            //     errors.airportcomments = 'Special characters are not allowed.';
            // }
            if((airportIataCode ==="0" || airportIataCode === "-1") && (airportName !== "-1")){
                errors.airportGIataCode = 'Please select a value.';
            }
            if(!airportIataCodeText && airportIataCode === "-1"){
                errors.iatacomments = 'Please enter the iata code.';
            }else if(airportIataCodeText.length > 256 && airportIataCode === "-1"){
                errors.iatacomments = 'Maximum of 256 characters allowed.';
            }else if (!/^[a-zA-Z0-9\s]+$/.test(airportIataCodeText)) {
                errors.iatacomments = 'Special characters are not allowed.';
            }
            if(!airportCountry){
                errors.airportGCountry = 'Please select a value.';
            }
            if(!airportCity){
                errors.airportGCity = 'Please select a value.';
            }
            if(airportState.length > 0){
                if(airportState.length > 256){
                    errors.airportGState = 'Maximum of 256 characters allowed.';
                }else if (!/^[a-zA-Z0-9\s]+$/.test(airportState)) {
                    errors.airportGState = 'Special characters are not allowed.';
                }
            }
            if(!generalAviation){
                errors.generalAviationGroup = 'Please select a value.';
            }
        }
        return errors;
    }
    const validateForm = (firstName,lastName, address1, address2,country,city,state,postcode,email,airportRegion,airportName,airportIataCode,airportCountry,airportCity,airportState,isConfirm,document,airportGroupName,generalAviation) => {

        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!firstName){
            errors.firstName = 'Please enter a value.';
        }else if(firstName.length > 256){
            errors.firstName = 'Maximum of 256 characters allowed.';
        }
        if(!lastName){
            errors.lastName = 'Please enter a value.';
        }else if(lastName.length > 256){
            errors.lastName = 'Maximum of 256 characters allowed.';
        }

        if(address1.length > 0){
            if(address1.length > 256){
                errors.address1 = 'Maximum of 256 characters allowed.';
            }
        }

        // else if (!/^[a-zA-Z0-9\s]+$/.test(address1)) {
        //     errors.address1 = 'Special characters are not allowed.';
        //   }
        
        if(address2.length > 0){
            if(address2.length > 256){
                errors.address2 = 'Maximum of 256 characters allowed.';
            }
        }

        // if(!country){
        //     errors.country = 'Please select a value.';
        // }
        // if(!city){
        //     errors.city = 'Please select a value.';
        // }

        if(state.length > 0){
            if(state.length > 256){
                errors.state = 'Maximum of 256 characters allowed.';
            }
        }
      
        if(postcode.length > 0){
            if(postcode.length > 256){
                errors.postcode = 'Maximum of 256 characters allowed.';
            }
        }

        if(!email){
            errors.email = 'Please enter a value.';
        }
        else if(!emailRegex.test(email)){
            errors.email = 'Please enter a valid email address.';
        }

        if(groupAirport === false){

            if(!airportRegion){
                errors.airportSRegion = 'Please select a value.';
            }
            if(!airportCountry){
                errors.airportSCountry = 'Please select a value.';
            }
            if(!airportCity){
                errors.airportSCity = 'Please select a value.';
            }
            if(!airportName || airportName === "0" || airportName === ""){
                errors.airportSName = 'Please select a value.';
            }
            if((!airportNameText ||airportNameText ==='Others' ) && airportName === "-1"){
                errors.airportNameSingle = 'Please enter the airport name.';
            }else if(airportNameText.length > 256 && airportName === "-1"){
                errors.airportNameSingle = 'Maximum of 256 characters allowed.';
            }
            if((!airportIataCode || airportIataCode ==="0") && (airportName !== "-1")){
                errors.airportSIataCode = 'Please select a value.';
            }
            if((!airportIataCodeText) && airportName === "-1"){
                errors.iataCodeSingle = 'Please enter the iata code.';
            }else if(airportIataCodeText.length > 256){
                errors.iataCodeSingle = 'Maximum of 256 characters allowed.';
            }else if (!/^[a-zA-Z0-9\s]+$/.test(airportIataCodeText)) {
                errors.iataCodeSingle = 'Special characters are not allowed.';
            }

            if(airportState.length > 0){
                if(airportState.length > 256){
                    errors.airportGState = 'Maximum of 256 characters allowed.';
                }else if (!/^[a-zA-Z0-9\s]+$/.test(airportState)) {
                    errors.airportGState = 'Special characters are not allowed.';
                }
            }
            if(!generalAviation){
                errors.generalAviation = 'Please select a value.';
            }
        }else if(groupAirport === true){

            if(!airportGroupName){
                errors.airportGroupName = 'Please enter a value.';
            } else if(airportGroupName.length > 256){
                errors.airportGroupName = 'Maximum of 256 characters allowed.';
            }else if (!/^[a-zA-Z0-9\s]+$/.test(airportGroupName)) {
                errors.airportGroupName = 'Special characters are not allowed.';
            }

            if(groupState.length <= 0){
                if(!airportRegion){
                    errors.airportGRegion = 'Please select a value.';
                }
                if(!airportName){
                    errors.airportGName = 'Please select a value.';
                }
                if(!airportIataCode){
                    errors.airportGIataCode = 'Please select a value.';
                }
                if(!airportCountry){
                    errors.airportGCountry = 'Please select a value.';
                }
                if(!airportCity){
                    errors.airportGCity = 'Please select a value.';
                }
                // if(!airportState){
                //     errors.airportGState = 'Please enter a value.';
                // }
                if(airportState.length > 0){
                    if(airportState.length > 256){
                        errors.airportGState = 'Maximum of 256 characters allowed.';
                    }else if (!/^[a-zA-Z0-9\s]+$/.test(state)) {
                        errors.airportGState = 'Special characters are not allowed.';
                    }
                }
                if(!generalAviation){
                    errors.generalAviationGroup = 'Please select a value.';
                }
            }
        }
        if(!isConfirm){
            errors.isConfirm = 'Please accept the terms and conditions';
        }

        if(document){
            const allowedExtensions = [
                '.bmp', '.csv', '.doc', '.docx', '.htm', '.html', '.jpg',
                '.jpeg', '.msg', '.pdf', '.png', '.ppt', '.pptx', '.rtf',
                '.sig', '.tif', '.tiff', '.txt', '.vsd', '.xls', '.xlsx', '.zip'
            ];

            const fileExtension = image.slice(((image.lastIndexOf(".") - 1) >>> 0) + 2);
            const fileSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes
            if (!allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
                errors.document = 'This file format is not supported.';
            }
            else if (fileSize > fileSizeLimit) {
                errors.document = 'File could not be uploaded as it is exceeding the maximum file size of 20 MB.';
            }
        }
        return errors;
    }
    const validateDocument = (document, image) => {
        const errors = {};
        if(document){
            const allowedExtensions = [
                '.bmp', '.csv', '.doc', '.docx', '.htm', '.html', '.jpg',
                '.jpeg', '.msg', '.pdf', '.png', '.ppt', '.pptx', '.rtf',
                '.sig', '.tif', '.tiff', '.txt', '.vsd', '.xls', '.xlsx', '.zip'
            ];
            const fileExtension = image.slice(((image.lastIndexOf(".") - 1) >>> 0) + 2);
            const fileSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes
            
            if (!allowedExtensions.includes('.' + fileExtension.toLowerCase()))
                errors.document = 'This file format is not supported.';
            else if (fileSize > fileSizeLimit)
                errors.document = 'File could not be uploaded as it is exceeding the maximum file size of 20 MB.';
            else
                errors.document = 'Document has been successfully uploaded.';
        }
        return errors;
    }

    return (
        <>
            <div>
                <div className="be-wrapper be-offcanvas-menu be-fixed-sidebar be-color-header vh-100">
                    <LoginNavBar />
                    <div className="be-content">
                        <div className="main-content container-fluid p-0">
                            <div className="page-header ph-blue mb-4">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col">
                                            <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                        </div>
                                        <div className="col-6 d-flex align-items-center justify-content-center">
                                            <h3 className="page-header-title">Airport Carbon Accreditation Registration</h3>
                                        </div>
                                        <div className="col d-flex align-items-center justify-content-end">
                                            <a href="/login"><button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }}><i className="icon icon-left mdi mdi-arrow-left"></i> Back to Login </button></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="page-header-title">Register Your Interest in the <i>Airport Carbon Accreditation</i> Programme</h3>
                                        <p className="mb-3 font-weight-normal"><i>Airport Carbon Accreditation</i> is the only institutionally-endorsed, carbon management certification standard for airports. 
                                        The programme independently assesses and recognises the efforts of airports to manage and reduce their carbon emissions with seven levels of accreditation: 
                                        'Mapping', 'Reduction', 'Optimisation', 'Neutrality', 'Transformation', 'Transition' and Level 5.
                                        For any support, please contact <a style={{color:'blue'}} href="mailto:ACA@wsp.com"> ACA@wsp.com</a>.
                                        </p>
                                        <p>Please fill out your details in the form below
                                        </p>
                                        <div className="card card-border card-contrast">
                                            <div className="card-header card-header-contrast">ACI Membership
                                            </div>
                                            <div className="card-body">
                                                <p>Are you a member of ACI? Please note that this refers to whether your airport holds a membership with Airports Council International, and not to whether your airport is already certified under Airport Carbon Accreditation.</p>
                                                <p>Please note that in group applications all airports must be a member of ACI in one of its regions.</p>
                                                <fieldset>
                                                    <legend htmlFor="aci-member" className="sr-only">Inline Radio</legend>
                                                    <label className="custom-control custom-radio custom-control-inline">
                                                        <input className="custom-control-input" type="radio" name="aci-member"  value="yes" checked={selectedAciMember === 'yes'}  onChange={handleACiMemberRadioChange}/><span className="custom-control-label">Yes</span>
                                                    </label>
                                                    <label className="custom-control custom-radio custom-control-inline">
                                                        <input className="custom-control-input" type="radio" name="aci-member"  value="no"  checked={selectedAciMember === 'no'}  onChange={handleACiMemberRadioChange} /><span className="custom-control-label">No</span>
                                                    </label>
                                                </fieldset>
                                                {
                                                    selectedAciMember === 'yes' 
                                                        ? 
                                                        <div className="form-group row">
                                                            <label htmlFor="f-upload" className="col-12 col-sm-3 col-form-label">Upload Membership Certificate
                                                                <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title="Allowed File formats - bmp,.csv,.doc,.docx,.htm,.html,.jpg,.jpeg,.msg,.pdf,.png,.ppt,.pptx,.rtf,.sig,.tif,.tiff,.txt,.vsd,.xls,.xlsx,.zip and Maximum File Size - 20 Mb"></span>
                                                            </label>
                                                            <div className="col-12 col-sm-6">                     
                                                                <input id="f-upload" type="file" className="form-control form-control-xs"  onChange={handleFileChange}/>
                                                                {
                                                                    formErrors.document === 'Document has been successfully uploaded.' 
                                                                        ? 
                                                                        <span className='text-info'>{formErrors.document}</span>
                                                                        :
                                                                        <span className='text-danger'>{formErrors.document}</span>}
                                                                {/* <label className="btn-primary" htmlFor="file-2"> <i className="mdi mdi-upload"></i><span>Browse files...</span></label> */}
                                                            </div>
                                                        </div>
                                                        :
                                                        <p className="m-0 mt-4 text-danger">Only Open for ACI Members</p>
                                                }
                                            </div>
                                        </div>
                                        {
                                            selectedAciMember === 'yes' 
                                                ? 
                                                <>
                                                    <div className="card card-border card-contrast">
                                                        <div className="card-header card-header-contrast">
                                                            Applicant Details
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="form-group row pt-1 pb-1">
                                                                <label htmlFor="airport-type" className="col-12 col-sm-2 col-form-label">Airport Type <span className="text-danger font-weight-bold">*</span></label>
                                                                <div className="col-12 col-sm-10 col-lg-6 form-check mt-1">
                                                                    <fieldset>
                                                                        <legend className="sr-only">Airport Type</legend>
                                                                        <label className="custom-control custom-radio custom-control-inline">
                                                                            <input className="custom-control-input" type="radio" name="airport-type"  value="Single" checked={selectedAirportType === 'Single'}  onChange={handleAirportRadioChange}/><span className="custom-control-label">Single</span>
                                                                        </label>
                                                                        <label className="custom-control custom-radio custom-control-inline">
                                                                            <input className="custom-control-input" type="radio" name="airport-type"  value="Group"  checked={selectedAirportType === 'Group'}  onChange={handleAirportRadioChange} /><span className="custom-control-label">Group</span>
                                                                        </label>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
                                                            {
                                                                groupAirport === false 
                                                                    ?
                                                                    <div className="form-row" id="single-form">
                                                                        <div className="form-group col-md-6 col-lg-2">
                                                                            <label htmlFor="ACIRegion">ACI Region <span className="text-danger font-weight-bold">*</span>
                                                                                <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title=" Please validate your ACI membership from the regional website"></span>
                                                                            </label>
                                                                            <select id="ACIRegion" className="form-control form-control-sm" value={airportRegion}  onChange={handleAirportRegionChange} >
                                                                                {
                                                                                    regionList.map((option) => (
                                                                                        <option key={option.value} value={option.value}>
                                                                                            {option.text}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <span className="text-danger">{formErrors.airportSRegion}</span>
                                                                            { 
                                                                                regionReferenceUrl && <p><a style={{cursor:'pointer', color: "blue"}} href={regionReferenceUrl} target='_blank' rel='noreferrer' >{regionReferenceUrl}</a></p>
                                                                            }
                                                                        </div>
                                                                        <div className="form-group col-md-6 col-lg-2">
                                                                            <label htmlFor="country">Country<span className="text-danger font-weight-bold">&nbsp;*</span></label>                    
                                                                            <select id="country" className="form-control form-control-sm" value={airportCountry}  onChange={handleAirportCountryChange} disabled={countryList.length === 0}>
                                                                                {
                                                                                    countryList.map((option) => (
                                                                                        <option key={option.value} value={option.value}>
                                                                                            {option.text}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <span className="text-danger">{formErrors.airportSCountry}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6 col-lg-2">
                                                                            <label htmlFor="City">City<span className="text-danger font-weight-bold">&nbsp;*</span></label>
                                                                            <select id="City" className="form-control form-control-sm" value={airportCity}  onChange={handleAirportCityChange} disabled={airportCityList.length === 0}>
                                                                                {
                                                                                    airportCityList.map((option) => (
                                                                                        <option key={option.value} value={option.value}>
                                                                                            {option.text}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <span className="text-danger">{formErrors.airportSCity}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6 col-lg-1">
                                                                            <label htmlFor="State">State</label>
                                                                            <input type="text" className="form-control form-control-sm" placeholder="Please Enter State..." value={airportState} onChange={(e) => { setAirportState(e.target.value)}}/>
                                                                            <span className="text-danger">{formErrors.airportGState}</span>
                                                                        </div>
                                                                        <div className="form-group col-md-6 col-lg-2">
                                                                            <label htmlFor="AirportName">Airport Name <span className="text-danger font-weight-bold">*</span>
                                                                                <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title="If any airport does not exist then please select “others” dropdown and enter the new airport"></span>
                                                                            </label>
                                                                            <select id="iataCode" className="form-control form-control-sm" value={airportName}  onChange={handleAirportChange} disabled={airportList.length === 0}>
                                                                                {
                                                                                    airportList.map((option) => (
                                                                                        <option key={option.value} value={option.value}>
                                                                                            {option.text}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select> 
                                                                            {
                                                                                airportName === "-1" 
                                                                                &&
                                                                                <div>
                                                                                    <input type="text" className="form-control form-control-sm mt-1"  placeholder="Please Enter Airport Name..." onChange={(e) => { setAirportNameText(e.target.value) }}/>
                                                                                    <span className="text-danger">{formErrors.airportNameSingle}</span>
                                                                                </div>
                                                                            }
                                                                            <span className="text-danger">{formErrors.airportSName}</span>
                                                                            {
                                                                                alreadyErrorMessage === 'Airport already exists.' 
                                                                                    ? 
                                                                                    <span className="text-danger">{alreadyErrorMessage}</span>
                                                                                    : 
                                                                                    <p></p>
                                                                            } 
                                                                        </div>
                                                                        <div className="form-group col-md-6 col-lg-1">
                                                                        <label htmlFor="IATACode">IATA Code <span className="text-danger font-weight-bold">*</span></label>  
                                                                            {
                                                                                airportName === "-1" 
                                                                                    ? 
                                                                                    <div>
                                                                                        <input type="text" className="form-control form-control-sm"  placeholder="Please Enter IATA Code..." onChange={(e) => { setAirportIataCodeText(e.target.value) }}/>
                                                                                        <span className="text-danger">{formErrors.iataCodeSingle}</span>
                                                                                    </div>
                                                                                    : 
                                                                                    <>                                 
                                                                                    <select id="iataCode" className="form-control form-control-sm" onChange={(e) => { setAirportIataCode(e.target.value); setAirportIataCodeText(e.target.options[e.target.selectedIndex].text); }} disabled={iataCodeList.length <= 1}>
                                                                                    {
                                                                                        iataCodeList.map((option) => (
                                                                                            <option key={option.value} value={option.value}>
                                                                                                {option.text}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                                </>
                                                                            }
                                                                            <span className="text-danger">{formErrors.airportSIataCode}</span>

                                                                        </div>
                                                                        <div className="form-group col-md-6 col-lg-2">
                                                                            <label htmlFor="generalAviation">General Aviation?<span className="text-danger font-weight-bold">&nbsp;*</span></label>                    
                                                                            <select id="generalAviation" className="form-control form-control-sm" value={generalAviation} onChange={(e) => { setGeneralAviation(e.target.value)}}>
                                                                                {
                                                                                    generalAviationList.map((option) => (
                                                                                        <option key={option.value} value={option.value}>
                                                                                            {option.text}
                                                                                        </option>
                                                                                    ))
                                                                                }
                                                                            </select>
                                                                            <span className="text-danger">{formErrors.generalAviation}</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <>
                                                                        <div className="form-group row pt-1 pb-1">
                                                                            <label className="col-12 col-sm-2 col-form-label" htmlFor="Airportgn">Airport Group Name <span className="text-danger font-weight-bold">*</span></label>
                                                                            <div className="col-12 col-sm-10 col-lg-6 form-check mt-1">
                                                                                <input id="Airportgn" type="text" className="form-control form-control-sm" value={airportGroupName}  placeholder="Please Enter Airport Group Name..." onBlur={checkAirportGroupAlreadyExists} onChange={(e) => { setAirportGroupName(e.target.value) }}/>
                                                                                <span className="text-danger">{formErrors.airportGroupName}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="table-responsive">
                                                                            <table className="table table-striped table-borderless primaryDetails-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{width:'15%'}}>ACI Region <span className="text-danger font-weight-bold">*</span> <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title=" Please validate your ACI membership from the regional website"></span></th>
                                                                                        <th style={{width:'15%'}}>Country <span className="text-danger font-weight-bold">*</span></th>
                                                                                        <th style={{width:'15%'}}>City <span className="text-danger font-weight-bold">*</span></th>
                                                                                        <th style={{width:'15%'}}>State</th>
                                                                                        <th style={{width:'15%'}}>Airport Name <span className="text-danger font-weight-bold">*</span>  <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title="If any airport does not exist then please select “others” dropdown and enter the new airport"></span></th>
                                                                                        <th style={{width:'15%'}}>IATA Code <span className="text-danger font-weight-bold">*</span></th>
                                                                                        <th style={{width:'15%'}}>General Aviation? <span className="text-danger font-weight-bold">*</span></th>
                                                                                        <th style={{width:'10%'}}></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="no-border-x">
                                                                                    <tr>
                                                                                        <td> 
                                                                                        <label className="sr-only" htmlFor="G-ACIRegion">ACI Region</label> 
                                                                                            <select id="G-ACIRegion"  className="form-control form-control-sm"  value={airportRegion} onChange={handleAirportRegionChange} >
                                                                                                {
                                                                                                    regionList.map((option) => (
                                                                                                        <option key={option.value} value={option.value}>
                                                                                                            {option.text}
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            <span className="text-danger">{formErrors.airportGRegion}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                           <label className="sr-only" htmlFor="G-Country">Country</label> 
                                                                                            <select id="G-Country"  className="form-control form-control-sm" value={airportCountry} onChange={handleAirportCountryChange} disabled={countryList.length === 0}>
                                                                                                {
                                                                                                    countryList.map((option) => (
                                                                                                        <option key={option.value} value={option.value}>
                                                                                                            {option.text}
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            <span className="text-danger">{formErrors.airportGCountry}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                           <label className="sr-only" htmlFor="G-City">City</label> 
                                                                                            <select id="G-City" className="form-control form-control-sm" value={airportCity} onChange={handleAirportCityChange} disabled={airportCityList.length === 0}>
                                                                                                {
                                                                                                    airportCityList.map((option) => (
                                                                                                        <option key={option.value} value={option.value}>
                                                                                                            {option.text}
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            <span className="text-danger">{formErrors.airportGCity}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label className="sr-only" htmlFor="G-State">State</label> 
                                                                                            <input type="text" id="G-State" className="form-control form-control-sm" placeholder="Please Enter State..." value={airportState} onChange={(e) => { setAirportState(e.target.value) }}/>
                                                                                            <span className="text-danger">{formErrors.airportGState}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            <label className="sr-only" htmlFor="G-AirportName">Airport Name</label> 
                                                                                            <select id="G-AirportName"  className="form-control form-control-sm" value={airportName} onChange={handleAirportChange} disabled={airportList.length === 0}>
                                                                                                {
                                                                                                    airportList.map((option) => (
                                                                                                        <option key={option.value} value={option.value}>
                                                                                                            {option.text}
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            {
                                                                                                airportName === "-1" 
                                                                                                &&
                                                                                                <div>
                                                                                                    <label className="sr-only" htmlFor="G-AirportName2">Airport Name</label> 
                                                                                                    <input id="G-AirportName2" type="text" className="form-control form-control-sm mt-1"  placeholder="Please Enter Airport Name..." onChange={(e) => { setAirportNameText(e.target.value) }}/>
                                                                                                    <span className="text-danger">{formErrors.airportcomments}</span>
                                                                                                </div>
                                                                                            }
                                                                                            {
                                                                                                alreadyErrorMessage === 'Airport already exists.' 
                                                                                                    ?
                                                                                                    <span className="text-danger">{alreadyErrorMessage}</span>
                                                                                                    :
                                                                                                    <span></span>
                                                                                            } 
                                                                                            <span className="text-danger">{formErrors.airportGName}</span>
                                                                                            <span className='text-danger'>{formErrors.duplicateAirportName}</span>
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                airportName === "-1" 
                                                                                                    ? 
                                                                                                    <div>
                                                                                                        <label className="sr-only" htmlFor="G-IATACode">IATA Code</label>
                                                                                                        <input id="G-IATACode" type="text" className="form-control form-control-sm"  placeholder="Please Enter IATA Code..." onChange={(e) => { setAirportIataCodeText(e.target.value) }}/>
                                                                                                        <span className="text-danger">{formErrors.iatacomments}</span>
                                                                                                    </div>
                                                                                                    :                                    
                                                                                                    <select  className="form-control form-control-sm" value={airportIataCode} disabled={iataCodeList.length === 0} onChange={(e) =>{setAirportIataCode(e.target.value);setAirportIataCodeText(e.target.options[e.target.selectedIndex].text); }} >
                                                                                                        {
                                                                                                            iataCodeList.map((option) => (
                                                                                                                <option key={option.value} value={option.value}>
                                                                                                                    {option.text}
                                                                                                                </option>
                                                                                                            ))
                                                                                                        }
                                                                                                    </select>
                                                                                            }
                                                                                            <span className="text-danger">{formErrors.airportGIataCode}</span>
                                                                                        </td>
                                                                                        <td> 
                                                                                        <label className="sr-only" htmlFor="G-GeneralAviation">General Aviation?</label> 
                                                                                            <select id="G-GeneralAviation"  className="form-control form-control-sm"  value={generalAviation} onChange={(e) => { setGeneralAviation(e.target.value); setGeneralAviationText(e.target.options[e.target.selectedIndex].text)}} >
                                                                                                {
                                                                                                    generalAviationList.map((option) => (
                                                                                                        <option key={option.value} value={option.value}>
                                                                                                            {option.text}
                                                                                                        </option>
                                                                                                    ))
                                                                                                }
                                                                                            </select>
                                                                                            <span className="text-danger">{formErrors.generalAviationGroup}</span>
                                                                                        </td>
                                                                                        <td className="actions">
                                                                                            <button className="btn btn-primary btn-rounded btn-xs mt-auto" onClick={addGroupDetails}><i className="icon icon-left mdi mdi-plus text-white"></i>Add Airport</button>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        <div className="table-responsive" id="group-form" >
                                                                            <h5> Airport Details</h5>
                                                                            <table className="table table-striped table-borderless primaryDetails-table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{width:'15%'}}>ACI Region</th>
                                                                                        <th style={{width:'15%'}}>Country</th>
                                                                                        <th style={{width:'15%'}}>City</th>
                                                                                        <th style={{width:'15%'}}>State</th>
                                                                                        <th style={{width:'15%'}}>Airport Name</th>
                                                                                        <th style={{width:'10%'}}>IATA Code</th>
                                                                                        <th style={{width:'10%'}}>General Aviation?</th>
                                                                                        <th style={{width:'15%'}}>Reference Url</th>
                                                                                        <th style={{width:'10%'}}></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="no-border-x">
                                                                                    {
                                                                                        groupState.length>0 && groupState.map((item, index) => {
                                                                                            return(                                              
                                                                                                <tr key ={item.id} >
                                                                                                    <td>{item.AirportRegion}</td>
                                                                                                    <td>{item.AirportCountry}</td>
                                                                                                    <td>{item.AirportCity}</td>
                                                                                                    <td>{item.AirportState}</td>
                                                                                                    <td>{item.AirportName}</td>
                                                                                                    <td>{item.AirportIataCode}</td>
                                                                                                    <td>{item.GeneralAviationText}</td>
                                                                                                    <td><a style={{cursor:'pointer', color: "blue"}} href={regionReferenceUrl} target='_blank' rel='noreferrer' >{item.ReferenceUrl}</a></td>
                                                                                                    <td className="actions"><a className="icon" style={{cursor:'pointer'}} ><i className="mdi mdi-delete" onClick={() => handleDelete(item.id)}></i> <span className="sr-only">Delete Row</span> </a></td>
                                                                                                </tr>
                                                                                            );
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="card card-border card-contrast">
                                                        <div className="card-header card-header-contrast">
                                                            Correspondence Details
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="FirstName">First Name <span className="text-danger font-weight-bold">*</span></label>
                                                                    <input id="FirstName" type="text" className="form-control form-control-sm"  placeholder="Please Enter First Name..." onChange={(e) => { setFirstName(e.target.value) }}/>
                                                                    <span className="text-danger">{formErrors.firstName}</span>
                                                                </div>
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="LastName">Last Name <span className="text-danger font-weight-bold">*</span></label>
                                                                    <input id="LastName" type="text" className="form-control form-control-sm"  placeholder="Please Enter Last Name..." onChange={(e) => { setLastName(e.target.value) }}/>
                                                                    <span className="text-danger">{formErrors.lastName}</span>
                                                                </div>
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="email">E-Mail <span className="text-danger font-weight-bold">*</span>
                                                                        <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title="For expediting the approval process please do not use personal email addresses like Gmail, yahoo etc."></span>
                                                                    </label>
                                                                    <input id="email" type="email" className="form-control form-control-sm"  placeholder="Please Enter Email..." onChange={(e) => { setemail(e.target.value) }}/>
                                                                    <span className="text-danger">{formErrors.email}</span>
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="AddressLine1">Address Line 1
                                                                        <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title='Entered address will be printed on Invoice.'></span>
                                                                    </label>
                                                                    <input id="AddressLine1" type="text" className="form-control form-control-sm"  placeholder="Please Enter Address Line 1..." onChange={(e) => { setaddress1(e.target.value) }}/>
                                                                    <span className="text-danger">{formErrors.address1}</span>
                                                                </div>
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="AddressLine2">Address Line 2</label>
                                                                    <input id="AddressLine2" type="text" className="form-control form-control-sm"  placeholder="Please Enter Address Line 2..." onChange={(e) => { setaddress2(e.target.value) }}/>
                                                                    <span className="text-danger">{formErrors.address2}</span>
                                                                </div>
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="C-Country">Country</label>
                                                                    <select id="C-Country" className="form-control form-control-sm" value={country}  onChange={handleCountryChange} >
                                                                        {
                                                                            allCountryList.map((option) => (
                                                                                <option key={option.value} value={option.value}>
                                                                                    {option.text}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <span className="text-danger">{formErrors.country}</span>
                                                                </div>
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="C-City">City</label>
                                                                    <select id="C-City" className="form-control form-control-sm" value={city}  onChange={handleCityChange} >
                                                                        {
                                                                            cityList.map((option) => (
                                                                                <option key={option.value} value={option.value}>
                                                                                    {option.text}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <span className="text-danger">{formErrors.city}</span>
                                                                </div>
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="C-State">State</label>
                                                                    <input id="C-State" type="text" className="form-control form-control-sm"  placeholder="Please Enter State..." onChange={(e) => { setState(e.target.value) }}/>
                                                                    <span className="text-danger">{formErrors.state}</span>
                                                                </div>
                                                                <div className="form-group col-md-6 col-lg-4">
                                                                    <label htmlFor="C-PostCode">Post Code</label>
                                                                    <input id="C-PostCode" type="text" className="form-control form-control-sm"  placeholder="Please Enter Post Code..." onChange={(e) => { setPostcode(e.target.value) }}/>
                                                                    <span className="text-danger">{formErrors.postcode}</span>
                                                                </div>
                                                            </div>
                                                            <p><a className="text-danger" style={{cursor:'pointer'}} onClick={ () => { toggleForTAndCModal();}} title="Click to expand terms and conditions"><u>Show Terms and Conditions</u></a></p>
                                                            <div className="custom-control custom-checkbox">
                                                                <input className="custom-control-input" type="checkbox" id="isConfirm" onChange={handleConfirm}/>
                                                                <label className="custom-control-label" htmlFor="isConfirm">Airports wishing to participate in the programme must accept the Terms and Conditions available to download here. Please note that submitting an application will mean accepting these Terms and Conditions which represent a binding legal contract.</label>
                                                                <span className="text-danger">{formErrors.isConfirm}</span>
                                                            </div>
                                                            {
                                                                errorMessage 
                                                                    ? 
                                                                    <span className="text-danger">{errorMessage}</span>
                                                                    : 
                                                                    <p></p>
                                                            }
                                                        </div>
                                                        <div className="card-footer text-right">
                                                            <button className="btn btn-secondary mt-auto mr-1" type="button"><a href="/login">Cancel</a></button>
                                                            <button className="btn btn-success mt-auto" type="button"  onClick={addAirport}>Submit</button>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
            <CommonCustomModal modal={tAndCModal} title='' toggler={toggleForTAndCModal} heading='Show Terms and Conditions'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <TermAndCondition toggler={toggleForTAndCModal}/>
            </CommonCustomModal>
            {/* <CommonModalWithImage modal={checkGroupModal} title='' toggler={toggleForCheckGroupModal} heading='' triggerMethodOnSaveButtonClick={''} buttonText='Yes'>
                    <Label className="col-form-label">{'The airport group name you entered already exists in our system. If you continue, your airports will be mapped to the existing group. Alternatively, you can change the group name for a new registration.'}</Label>
            </CommonModalWithImage> */}
            <CommonCustomModal modal={checkGroupModal} title='' toggler={toggleForCheckGroupModal} heading=''  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AirportGroupExists toggler={toggleForCheckGroupModal}  setAirportGroupName={setAirportGroupName}/>
            </CommonCustomModal>
            <ToastContainer />
        </>
    );
};

export default AccreditationAirportRegistration;