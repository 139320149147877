import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import axios from '../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";

const ArchiveVerifier = ({ toggler,toggler1 }) => {

    const navigate = useNavigate();

    const [comments, setcomments] = useState("");
    const [formErrors, setFormErrors] = useState({});





    {/*Validation*/}
    const validateForm = (comments) => {
        const errors = {};
        if(!comments){
            errors.comments = 'Comment is required!';
        }
        return errors;
    }

    const upGrade = async () => {   
       
        try {
            const validationErrors = validateForm(comments.trim());
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){ 
                toggler1();      
                try {
                    await axios.post(`${apiconfig.addVerifier}`,
                    {
                        Comments: comments.trim()
            
                    },
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                      }})
                    .then((response) => {

                        // if(response.status === 200){
                        //     fetchData();
                        // }
                    })                           
                }
                catch (error) {
                    console.log("Error: ", error);
                    navigate("/error-page");
                }
                //toggleForAddUserModal();        
            }                                         
        }
        catch (error) {
            console.log("Error: ", error);
        }
    };
    return (
        <div>
    <form>
                    <div className="form-row">
                        <label>{'Are you sure you want to archive '}{'verifier '}{'?'}</label>     
                    </div> 
                    <div className="form-row">                 
                        <label htmlFor="input_comments">Comments</label>
                        <textarea type="text" className="form-control form-control-xs" id="input_comments" onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={upGrade}>Submit</button>
            </div>
            </div>
    );
};

export default ArchiveVerifier;