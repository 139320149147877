import { useState, useEffect } from 'react';

const IsAccessForAccreditationCombination = () => {

  const [isReadOnlyAccess, setIsReadOnlyAccess] = useState("");

  useEffect(() => {
    const userRoles = JSON.parse(localStorage.getItem("roles")) || [];
    if(userRoles.length !== 0){
      if(userRoles.some(role => role.name === "Administrator" || role.name === "Account Manager" || role.name === "Senior Account Manager")){
        setIsReadOnlyAccess("AccFullAccess");
      }else if (userRoles.some(role => role.name === "ACI" || role.name === "ACI-E")){
        setIsReadOnlyAccess("AccReadOnlyAccess");
      }else if(role => role.name === "Airport User" ){
        setIsReadOnlyAccess("AccAirportUserAccess");
      }
      else {
        setIsReadOnlyAccess("AccNoAccess");
      }
    }

  }, []);

  return isReadOnlyAccess;
};

export default IsAccessForAccreditationCombination;

