import React, { useState} from 'react';
import { MdExpandMore } from 'react-icons/md';
import CommonCustomModal from '../../Common/CommonCustomModal';
import EditSponsors from './EditSponsor';
import {Link, useNavigation} from 'react-router-dom';
import AddVerifier from '../AddVerifier';
import CommonModalWithImage from '../../Common/CommonModalWithImage';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';
import CommonModalWithOutButton from '../../Common/CommonModalWithOutButton';
import IsAccessForVerifierCombination1 from '../../Common/IsAccessForVerifieCombination1';



const Sponsor = ({ sponsor, searchName, refetch, setRefetch }) => {
console.log(sponsor);
    const [editSponsorModal, setEditSponsorModal] = useState(false);
    const [addVerifierModal, setAddVerifierModal] = useState(false);
    const [archiveSponsorModal, setArchiveSponsorModal] = useState(false);
    const [activateSponsorModal, setActivateSponsorModal] = useState(false);
    const [primarySponsorModal, setPrimarySponsorModal] = useState(false);
    const navigate = useNavigate();
    const userID = localStorage.getItem("userID");
    const isReadOnlyAccess = IsAccessForVerifierCombination1();

    const toggleForEditSponsorModal = () => {
        setEditSponsorModal(!editSponsorModal);
    }  
    const toggleForAddVerifierModal = () => {
        setAddVerifierModal(!addVerifierModal);
    }  

    const toggleForArchiveSponsorModal = () => {
        setArchiveSponsorModal(!archiveSponsorModal);
    }
    const toggleForActivateSponsorModal = () => {
        setActivateSponsorModal(!activateSponsorModal);
    }

    const toggleForPrimarySponsorModal = () => {
        setPrimarySponsorModal(!primarySponsorModal);
      }

    const activateSponsor = async () => {
        const requestBody = {
            "userID": userID,
            "activeStatus": 1,
            "sponsorID" : sponsor.id
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.archiveUnarchiveSponsor}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response.json();
            })
            if(response.saveErrorMessage === 'Sponsor UnArchive successfully.')
            {
                toast.success('Sponsor UnArchived successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
      
            }
            setRefetch(!refetch);
        }
        catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
        toggleForActivateSponsorModal();
    };

    const archiveSponsor = async () => {
        const requestBody = {
            "userID": userID,
            "activeStatus": 0,
            "sponsorID" : sponsor.id
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.archiveUnarchiveSponsor}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response.json();
            })
            if(response.saveErrorMessage === 'Sponsor Archive successfully.')
            {
                toast.success('Sponsor Archived successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 

            }else if(response.saveErrorMessage === 'You can’t delete the primary sponsor.')
            {
              toggleForPrimarySponsorModal();
  
            }
            setRefetch(!refetch);
        }
        catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
        toggleForArchiveSponsorModal();
    };


        return (
            <>
                <tr>
                    {isReadOnlyAccess  === "FullAccess" ? 
                    <td className="text-break"><a className="text-danger" href={`/viewsponsor/${sponsor.id}`}>{sponsor.sponsorName}</a></td>
                    :
                    <td>{sponsor.sponsorName}</td>
                    }
                    <td className="text-break">{sponsor.clientID}</td>
                    <td>{sponsor.region}</td>
                    <td>{sponsor.country}</td>
                    <td>{sponsor.email}</td>
                    <td>{sponsor.phoneNumber}</td>
                    {sponsor.sponsorStatus === 'Active' ? (
                    <td><span className="badge badge-success">{sponsor.sponsorStatus}</span></td> 
                    ):(
                    <td><span className="badge badge-danger">{sponsor.sponsorStatus}</span></td> 
                    )}
                    {isReadOnlyAccess == 'FullAccess' ?
                    <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu">
                            {isReadOnlyAccess === 'FullAccess' ?   
                            <>
                            <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditSponsorModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                            {sponsor.sponsorStatus ==='Active' ?
                            (<a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForArchiveSponsorModal(); }}><span className="icon mdi mdi-delete"></span>Archive</a>):
                            (<a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForActivateSponsorModal(); }}><span className="icon mdi mdi-plus"></span>UnArchive</a>)}
                             <a className="dropdown-item"  style={{cursor:'pointer'}} href={`/viewsponsor/${sponsor.id}`}><span className="icon mdi mdi-eye"></span>View</a>     
                             </>                  
                             :
                             <></>   
                            }      
                          </div>
                    </td>
                    :
                    <td></td>
                    }
                </tr>

                { /* MODAL FOR ADDING VERIFIER */}
                <CommonCustomModal modal={editSponsorModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForEditSponsorModal} heading='Edit Sponsor'  buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <EditSponsors toggler={toggleForEditSponsorModal} sponsor = {sponsor} refetchs={refetch} setRefetchs={setRefetch} />
                </CommonCustomModal>
                <CommonCustomModal modal={addVerifierModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAddVerifierModal} heading='Add Verifier'  buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  {/* <AddVerifier toggler={toggleForAddVerifierModal}/> */}
                </CommonCustomModal>
                <CommonModalWithImage modal={archiveSponsorModal} title='Archive Sponsor' toggler={toggleForArchiveSponsorModal} heading='Archive Sponsor' triggerMethodOnSaveButtonClick={archiveSponsor} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to archive '}{'?'}</Label>
                </CommonModalWithImage>
                <CommonModalWithImage modal={activateSponsorModal} title='UnArchive Sponsor' toggler={toggleForActivateSponsorModal} heading='UnArchive Sponsor' triggerMethodOnSaveButtonClick={activateSponsor} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to UnArchive '}{'?'}</Label>
                </CommonModalWithImage>
                <CommonModalWithOutButton modal={primarySponsorModal} title='' toggler={toggleForPrimarySponsorModal} heading='Archive Sponsor'>
                <Label className="col-form-label">{'You can’t delete the primary sponsor.'}</Label>
                </CommonModalWithOutButton>

            </>
        );
};

export default Sponsor;