import React, { useState, useEffect } from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Select from "react-select";

const AddAirportInformation = ({ toggler, airportgpID, refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [iataCode, setIataCode] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [airportNameLocal, setAirportNameLocal] = useState("");
    const [airportNameEnglish, setAirportNameEnglish] = useState("");
    const [airportGroup, setAirportGroup] = useState("");
    const [generalAviationAirport, setGeneralAviationAirport] = useState("");
    const [yearJoinedProgram, setYearJoinedProgram] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [errorMessage, setErrorMessage] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [aviationList, setAviationList] = useState([]);
    const [airportGpList, setAirportGpList] = useState([]);
    const [isLeftProgramme, setIsLeftProgramme] = useState("");




    const addAirport = async () => {

        const requestBody = {
            "airportGroupID": airportGroup,
            "countryID": country,
            "regionID": region,
            "userID": userID,
            "cityID": city,
            "generalAviationAirportID":generalAviationAirport,
            "iataCode": iataCode,
            "airportName_Local": airportNameLocal,
            "airportName_English": airportNameEnglish,
            "yearJoinedProgram":yearJoinedProgram,
            "airportID": 0,
            "leftProgramme" : 0

        };
        const validationErrors = validateForm(iataCode,region,country,city,airportNameLocal,airportNameEnglish,airportGroup,generalAviationAirport,yearJoinedProgram);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.addAirportInformation}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                    if (response.ok) {

                        var result = await response.json();
                        if(result.saveErrorMessage === 'Airport data saved successfully.')
                        {
                            toast.success('Airport information saved successfully.', {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Slide
                            }); 
                        setRefetchs(!refetchs);
                        toggler();
                        }else{
                            setErrorMessage(result.saveErrorMessage);
                        }
                    } else {
                        const errorText = await response.text();
                        const apiname = `${apiconfig.addAirportInformation}`;
                        navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                    }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
        }
    };

    const getRegionList = async () => {
  
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setRegionList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getRegion}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
    

    const getCountryList = async (region) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryList}${region}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountryList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
        

    const getCityList = async (country) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCityListByCountry}${country}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setCityList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCityListByCountry}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }

    const getAirportGpList = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAirportGpList}${'Term'}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                const option = [ { text: 'Please select Airport Group...', value: '' },
                    ...result.map((iata) => ({
                    text: iata.name,
                    value: iata.id,
                    }))
                ];
                setAirportGpList(option);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAirportGpList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }    
        
    
    const getAviationList = async () => {

        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAviationList}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setAviationList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAviationList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }    
         

    useEffect(()=>{
        getRegionList();
        getAirportGpList();
        getAviationList();
    },[])

    useEffect(()=>{
        if(region){
        getCountryList(region);
        }
    },[region])

    useEffect(()=>{
        if(country){
        getCityList(country);
        }
    },[country])



    const getAirportDetailsByID = async () => {
        try{
        const response = await fetch(`${config.apiUrl}${apiconfig.getAirportDetailsByID}${2573}`, {
            method: "GET",
            headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`  
            }
        });
        if (response.ok) {
            const result = await response.json();
            setRegion();
            setCountry();
            setCity();
            setAirportNameEnglish();
            setAirportNameLocal();
            setGeneralAviationAirport();
            setYearJoinedProgram();

        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getAirportDetailsByID}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
        }; 

    useEffect(() => {
        // getAirportDetailsByID();
    }, []);
           

    {/*Validation*/ }
    const validateForm = (iataCode,region,country,city,airportNameLocal,airportNameEnglish,airportGroup,generalAviationAirport,yearJoinedProgram) => {

        const errors = {};
        if (!iataCode) {
            errors.iataCode = 'Please select IATA code.';
        }
        if (!region) {
            errors.region = 'Please select Region.';
        }
        if (!country) {
            errors.country = 'Please select Country.';
        }
        if (!city) {
            errors.city = 'Please select City.';
        }
        if (!airportNameLocal) {
            errors.airportNameLocal = 'Please enter a value.';
        }
        if (!airportNameEnglish) {
            errors.airportNameEnglish = 'Please enter a value.';
        }
        if (!airportGroup) {
            errors.airportGroup = 'Please select Airport group.';
        }
        if (!generalAviationAirport) {
            errors.generalAviationAirport = 'Please select General Aviation Airport.';
        }
        if (!yearJoinedProgram) {
            errors.yearJoinedProgram = 'Please enter a value.';
        }
        return errors;
    }

    // useEffect(() => {
    //     const getIataCodeList = async () => { 
    //         try {
    //         const response = await  fetch(`${config.apiUrl}${apiconfig.getIataCode}`, {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("token")}`
    //             }
    //         });
    //         if (response.ok) {
    //             var result = await response.json();
    //             setIataCodeList(result);

    //         } else {
    //             const errorText = await response.text();
    //             const apiname = `${apiconfig.getIataCode}`;
    //             navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
    //         }
    //         }catch(error){
    //             console.log(error.message);
    //             navigate(`/error-page`); 
    //         }
    //     }
    //     getIataCodeList();
    // }, []);

    const handleRegionChange = (e) => {
        setRegion(e.target.value);
        setCountry('');
        setCity('');
        if(e.target.value ===''){
            setCountry('');
            setCity('');
        }
      };

      const handleCountryChange = (e) => {
        setCountry(e.target.value);
        if(e.target.value ===''){
            setCity('');
        }
      };  

      const handleCityChange = (e) => {
        setCity(e.target.value);
      }; 


    return (
        <div>
            <form>
                <div className="form-row">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="iataCode">IATA Code :</label>
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input id="iataCode" className="form-control form-control-xs" type="text" onChange={(e) => { setIataCode(e.target.value) }}/>
                            <p style={{color:'red'}}>{formErrors.iataCode}</p>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="region">Region :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="region" className="form-control form-control-sm" value={region}  onChange={handleRegionChange} >
                            {regionList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.region}</p>
                        </div>

                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="country">Country :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="country" className="form-control form-control-sm" value={country}  onChange={handleCountryChange} >
                            {countryList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.country}</p>
                        </div>

                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="city">City :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="city" className="form-control form-control-sm" value={city}  onChange={handleCityChange} >
                            {cityList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.city}</p>
                        </div>

                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="airportnameLocal">Airport Name (Official Local) :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input id="airportnameLocal" className="form-control form-control-xs" type="text" onChange={(e) => { setAirportNameLocal(e.target.value) }}/>
                            <p style={{color:'red'}}>{formErrors.airportNameLocal}</p>
                        </div>

                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="airportnameEnglish">Airport Name (Official English) :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input id="airportnameEnglish" className="form-control form-control-xs" type="text" onChange={(e) => { setAirportNameEnglish(e.target.value) }}/>
                            <p style={{color:'red'}}>{formErrors.airportNameEnglish}</p>
                        </div>

                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="airportGroup">Airport Group :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="airportGroup" className="form-control form-control-sm" value={airportGroup}  onChange={(e) => { setAirportGroup(e.target.value) }} >
                            {airportGpList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.airportGroup}</p>
                        </div>

                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="generakAviation">General Aviation Airport? :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="generakAviation" className="form-control form-control-sm" value={generalAviationAirport}  onChange={(e) => { setGeneralAviationAirport(e.target.value) }} >
                            {aviationList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.generalAviationAirport}</p>
                        </div>

                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="yearJoined">Year Joined Program :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input id="yearJoined" className="form-control form-control-xs" type="text" onChange={(e) => { setYearJoinedProgram(e.target.value) }}/>
                            <p style={{color:'red'}}>{formErrors.yearJoinedProgram}</p>
                        </div>

                </div>
                {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )} 
            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="150"  alt='ACA Logo'></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                <button className="btn btn-success mt-auto" type="button"onClick={addAirport}  >Save</button>
            </div>
        </div>
    );
};

export default AddAirportInformation;