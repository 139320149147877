import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import { TableHeaderCells } from './TableHeaderCells'
import AdvancedSearch from '../../Common/AdvancedSearch'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import VerifierExamFee from './VerifierExamFee'
import Loader from '../../Common/Loader'

const VerifierExamFees = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [feesList, setFeesList] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('ExamLevel')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Exam Level')
    const [searchType, setSearchType] = useState('dropdown')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('default')
    const [feeAmountList, setFeeAmountList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [processingFeeList, setProcessingFeeList] = useState(false)
    const [feeListProcessed, setFeeListProcessed] = useState(false)
    const [processingVerifierAmountFeeList, setProcessingVerifierAmountFeeList] = useState(false)
    const [verifierAmountFeeListProcessed, setVerifierAmountFeeListProcessed] = useState(false)
    const [dropdownOptions, setDropdownOptions] = useState([
        {text: 'Level 1-3 +', value: 'Level 1-3'},
        {text: 'Level 4-4 +', value: 'Level 4-4'},
    ])
    const searchOptions = [
        {
            value: 'Exam Level', 
            text: 'Exam Level', 
            type: 'dropdown',
            query: '&ExamLevel=',
            dropdownOptions: [
                {text: 'Level 1-3 +', value: 'Level 1-3'},
                {text: 'Level 4-4 +', value: 'Level 4-4'},
            ]
        },
        {
            value: 'Exam Type', 
            text: 'Exam Type',
            type: 'dropdown',
            query: '&ExamType=',
            dropdownOptions: [
                {text: 'Downgrade', value: 'Downgrade'},
                {text: 'Renewal', value: 'Renewal'},
                {text: 'Renewal - Downgrade', value: 'Renewal - Downgrade'},
                {text: 'Renewal - Upgrade', value: 'Renewal - Upgrade'},
                {text: 'Upgrade', value: 'Upgrade'},

            ]
        },
        {
            value: 'Attempt', 
            text: 'Attempt',
            type: 'dropdown',
            query: '&Attempt=',
            dropdownOptions: [
                {text: '1st Attempt', value: '1st Attempt'},
                {text: '2nd Attempt', value: '2nd Attempt'},
            ]
        },
        {
            value: 'Fees', 
            text: 'Fees', 
            type: 'dropdown',
            query: '&Fee=',
            dropdownOptions: feeAmountList
        },
        // {
        //     value: 'Currency', 
        //     text: 'Currency', 
        //     type: 'text',
        //     query: '&Currency=',
        // },

    ]

    /* Methods */
    const fetchData = async () => {
        try {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            console.log('executing getFees()...')
            setProcessingFeeList(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getFees}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getFees()')
                const result = await response.json()
                let fetchedFees = []
                for (let i = 0; i < result.length; i++) {
                    fetchedFees.push(result[i])
                }
                setFeesList(fetchedFees)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getFees}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setFeeListProcessed(true)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria])
    useEffect(() => {
        function getVerifierFeeAmountList() {
            console.log('executing getVerifierFeeAmountList()...')
            setProcessingVerifierAmountFeeList(true)
            fetch(`${config.apiUrl}${apiconfig.getVerifierAmountFeeList}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(res => res.json())
            .then((data) => {
                console.log('fetched getVerifierFeeAmountList()')
                setFeeAmountList(data)
            })
            .catch((err) => {
                //console.log("Error: ", err)
                navigate("/error-page")
            })
            setVerifierAmountFeeListProcessed(true)
        }
        getVerifierFeeAmountList()
    }, [refetch])
    useEffect(() => {
        if(feesList.length > 0){
            setPageCount(Math.ceil(feesList[0].totalCount / itemsPerPage))
        }
    }, [feesList])
    useEffect(() => {
        if(processingFeeList || processingVerifierAmountFeeList){
            if(!isLoading)
                setIsLoading(true)
        }
        if(processingFeeList && processingVerifierAmountFeeList){
            if(feeListProcessed && verifierAmountFeeListProcessed){
                setIsLoading(false)
                setProcessingFeeList(false)
                setFeeListProcessed(false)
                setProcessingVerifierAmountFeeList(false)
                setVerifierAmountFeeListProcessed(false)
            }
        }
        if(processingFeeList && !processingVerifierAmountFeeList){
            if(feeListProcessed){
                setIsLoading(false)
                setProcessingFeeList(false)
                setFeeListProcessed(false)
            }
        }
        if(!processingFeeList && processingVerifierAmountFeeList){
            if(verifierAmountFeeListProcessed){
                setIsLoading(false)
                setProcessingVerifierAmountFeeList(false)
                setVerifierAmountFeeListProcessed(false)
            }
        }
    }, [processingFeeList, feeListProcessed, processingVerifierAmountFeeList, verifierAmountFeeListProcessed])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(feesList.length !== 0){
            if(feesList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])
    
    
    return (
        <Layout>
            {
                isLoading
                ?
                <Loader />
                :
                <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-orange mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Verifier Fees List</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                    <li className="breadcrumb-item active">Verifier Fees List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="container-header">
                        <h3 className="page-header-title">Search Verifier Fees</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    {/* <strong>Search</strong>
                                    <br /> */}
                                    Use the filter categories and search field below to find verifier fees.
                                </p>
                                <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                                {
                                    feesList.length > 0
                                        ?
                                        <div className="align-self-center font-weight-bold">{feesList[0].totalCount} items found</div>
                                        :
                                        <div className="align-self-center font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                        <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                        <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                                    </div> */}
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                        <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                            {feesList.map(fees => <VerifierExamFee key={feesList.indexOf(fees)} fees={fees} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                        </CustomTable>
                        <div className="d-flex justify-content-end">
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default VerifierExamFees