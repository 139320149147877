import React from 'react'

const PermissionListByRole = ({ permission }) => {
    
    return (
        <React.Fragment>
            {
                permission 
                    ? 
                    <tr className="alt-row">
                        <td className="user-avatar">{permission.label}</td>
                    </tr>
                    :
                    <tr className="alt-row">
                        <td>No Records Found.</td>
                    </tr>
            }
        </React.Fragment>
    )
}

export default PermissionListByRole