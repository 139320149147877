import React, { useState, useEffect } from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';



const EditAirportDetails = ({ toggler, airportDetails, refetch, setRefetch}) => {
    
    const navigate = useNavigate();
    const [iataCode, setIataCode] = useState("");
    const [region, setRegion] = useState("");
    const [country, setCountry] = useState("");
    const [city, setCity] = useState("");
    const [airportNameEnglish, setAirportNameEnglish] = useState("");
    const [state, setState] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [errorMessage, setErrorMessage] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [cityList, setCityList] = useState([]);

    const [documents, setDocuments] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [image, setimages] = useState("");
    const [isOldDocumentSelected, setIsOldDocumentSelected] = useState(true);
    const [fileSize, setFileSize] = useState(0);




    const editAirport = async () => {

        const requestBody = {
            "airportGroupID": airportDetails.airportGroupID,
            "countryID": country,
            "regionID": region,
            "userID": userID,
            "cityID": city,
            "generalAviationAirportID":2,
            "iataCode": iataCode,
            "airportName_Local": airportNameEnglish,
            "airportName_English": airportNameEnglish,
            "yearJoinedProgram":'',
            "airportID": airportDetails.airportID,
            "state": state

        };
        const validationErrors = validateForm(airportNameEnglish,iataCode,region,country,city,state);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.addAirportInformation}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                    if (response.ok) {

                        var result = await response.json();
                        if(result.saveErrorMessage === 'Airport data updated successfully.')
                        {
                            toast.success('Airport information updated successfully.', {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Slide
                            }); 
                        setRefetch(!refetch);
                        toggler();
                        }else{
                            setErrorMessage(result.saveErrorMessage);
                        }
                    } else {
                        const errorText = await response.text();
                        const apiname = `${apiconfig.addAirportInformation}`;
                        navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                    }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
        }
    };

    const getRegionList = async () => {
  
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setRegionList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getRegion}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
    

    const getCountryList = async (region) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryList}${region}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountryList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
        

    const getCityList = async (country) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCityListByCountry}${country}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setCityList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCityListByCountry}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
 
         

    useEffect(()=>{
        getRegionList();
    },[])

    useEffect(()=>{
        if(region){
        getCountryList(region);
        }
    },[region])

    useEffect(()=>{
        if(country){
        getCityList(country);
        }
    },[country])



    const getAirportDetailsByID = async () => {

        try{
        const response = await fetch(`${config.apiUrl}${apiconfig.getAirportDetailsByID}${airportDetails.airportID}`, {
            method: "GET",
            headers: { 
                Authorization: `Bearer ${localStorage.getItem("token")}`  
            }
        });
        if (response.ok) {
            const result = await response.json();
            setRegion(result.regionID);
            setCountry(result.countryID);
            setCity(result.cityID);
            setState(result.state);
            setAirportNameEnglish(result.airportName_English);
            setIataCode(result.iataCode);

        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getAirportDetailsByID}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
        }; 

    useEffect(() => {
        getAirportDetailsByID();
    }, [airportDetails.airportID]);
          

    {/*Validation*/ }
    const validateForm = (airportName,iataCode,region,country,city,state) => {
        const errors = {};
        if (!region) {
            errors.region = 'Please select Region.';
        }
        if (!country) {
            errors.country = 'Please select Country.';
        }
        if (!city) {
            errors.city = 'Please select City.';
        }
        if(state.length > 0){
            if(state.length > 256){
                errors.state = 'Maximum of 256 characters allowed.';
            }else if (!/^[a-zA-Z0-9\s]+$/.test(state)) {
                errors.state = 'Special characters are not allowed.';
            }
        }

        if(!airportName){
            errors.airportName = 'Please enter the airport name.';
        }else if(airportName.length > 256){
            errors.airportName = 'Maximum of 256 characters allowed.';
        }
        if(!iataCode){
            errors.iataCode = 'Please enter the iata code.';
        }else if(iataCode.length > 256){
            errors.iataCode = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(iataCode)) {
            errors.iataCode = 'Special characters are not allowed.';
        }

        
        // if(!isOldDocumentSelected && !documents){
        //     errors.documents = 'Please choose a document.';
        // }else {
        //     const allowedExtensions = [
        //         '.bmp', '.csv', '.doc', '.docx', '.htm', '.html', '.jpg',
        //         '.jpeg', '.msg', '.pdf', '.png', '.ppt', '.pptx', '.rtf',
        //         '.sig', '.tif', '.tiff', '.txt', '.vsd', '.xls', '.xlsx', '.zip'
        //     ];
    
        //     const fileExtension = image.slice(((image.lastIndexOf(".") - 1) >>> 0) + 2);
        //     const fileSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes
        //     if (!isOldDocumentSelected && !allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
        //         errors.documents = 'This file format is not supported.';
        //     }
        //     else if (fileSize > fileSizeLimit) {
        //         errors.documents = 'File could not be uploaded as it is exceeding the maximum file size of 20 MB.';
        //     }
        // }
        return errors;
    }


    const handleRegionChange = (e) => {
        setRegion(e.target.value);
        setCountry('');
        setCity('');
        if(e.target.value ===''){
            setCountry('');
            setCity('');
        }
      };

      const handleCountryChange = (e) => {
        setCountry(e.target.value);
        if(e.target.value ===''){
            setCity('');
        }
      };  

      const handleCityChange = (e) => {
        setCity(e.target.value);
      }; 

      const handleDeleteClick = () => {
        setDocumentName('');
        setIsOldDocumentSelected(false);
        setShowInput(true);
      };

      function fileToBase64(file, callback) {
        const reader = new FileReader();
        reader.onload = function(event) {
          const base64String = event.target.result.split(',')[1]; // Extract the base64 data
          callback(base64String);
        };
        reader.readAsDataURL(file);
      }
      const handleFileChange = (event) => {
        setFormErrors({
            ...formErrors,
            documents: '',
        });
        // setLoading(true); 
        const selectedFile = event.target.files[0];
        const fileSize = selectedFile.size;
        setFileSize(fileSize);
        setimages(selectedFile.name);
        if (selectedFile) {
            fileToBase64(selectedFile, function(base64String) {
                setDocuments(base64String);
            });
          }
        //   setLoading(false); 
      };

      const downloadFile = (url, filename) => {
        window.open(url);
    }


    return (
        <div>
            <form>

                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="region">Region :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="iataCode" className="form-control form-control-sm" value={region}  onChange={handleRegionChange} >
                            {regionList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <span style={{color:'red'}}>{formErrors.region}</span>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="country">Country :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="iataCode" className="form-control form-control-sm" value={country}  onChange={handleCountryChange} >
                            {countryList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <span style={{color:'red'}}>{formErrors.country}</span>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="city">City :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                        <select id="iataCode" className="form-control form-control-sm" value={city}  onChange={handleCityChange} >
                            {cityList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <span style={{color:'red'}}>{formErrors.city}</span>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="airportnameLocal">State :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" placeholder="Please Enter State..."  type="text" value={state} onChange={(e) => { setState(e.target.value) }}/>
                            <span style={{color:'red'}}>{formErrors.state}</span>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="airportnameEnglish">Airport Name :</label>         
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" placeholder="Please Enter Airport Name..."  type="text" value={airportNameEnglish} onChange={(e) => { setAirportNameEnglish(e.target.value) }}/>
                            <span style={{color:'red'}}>{formErrors.airportName}</span>
                        </div>
                </div>
                <div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="iataCode">IATA Code :</label>
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input className="form-control form-control-xs" placeholder="Please Enter IATA Code..."  type="text" value={iataCode} onChange={(e) => { setIataCode(e.target.value) }}/>
                            <span style={{color:'red'}}>{formErrors.iataCode}</span>
                        </div>
                </div>
                {/* {showInput ?
                    (<div className="form-row mt-3">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="document">Document :</label>
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <input type="file" className="form-control form-control-xs"   onChange={handleFileChange}/>
                            <span style={{color:'red'}}>{formErrors.documents}</span>
                        </div>
                    </div>
                    ) :
                    (<div className="form-row mt-1 d-flex align-items-center">
                        <div className="form-group col-md-4 mb-1">
                            <label htmlFor="document">Document :</label>
                        </div>
                        <div className="form-group col-md-8 mb-1">
                            <span><a style={{color:'blue',cursor:'pointer'}} onClick={() => { downloadFile(document.filePath,document.name); }}>&nbsp;{documentName}</a></span>
                             &nbsp; <span className="icon mdi mdi-close"  style={{color:'red', cursor:'pointer'}} onClick={handleDeleteClick}></span>
                        </div>
                    </div>
                    )} */}
                {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )} 
            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                <button className="btn btn-success mt-auto" type="button"onClick={editAirport}  >Save</button>
            </div>
        </div>
    );
};

export default EditAirportDetails;