import { useState } from "react";
import CommonModalWithImage from "../../Common/CommonModalWithImage";
import CommonCustomModal from "../../Common/CommonCustomModal";
import EditComment from "./EditComment";
import { Label } from 'reactstrap';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Navigate, useNavigate } from "react-router-dom";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";

const CommentList = ({ verifier, notes, refetch, setRefetch }) => {


    const [editCommentModal, setEditCommentModal] = useState(false);
    const [deleteNotesModal, setDeleteNotesModal] = useState(false);
    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    
  const toggleForEditCommentModal = () => {
    setEditCommentModal(!editCommentModal);
}  
const toggleForDeleteNotesModal = () => {
  setDeleteNotesModal(!deleteNotesModal);
}

const deleteNotes = async () => {

    const requestBody = {
        "iD": notes.id,
        "userID": userID,
    };
    try {
        const response = await fetch(`${config.apiUrl}${apiconfig.deleteNotes}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        }).then((response) => {
            return response.json();
        })
        if(response.saveErrorMessage === 'Notes deleted successfully.')
        {
            toast.success('Notes deleted successfully.', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide
            }); 
        setRefetch(!refetch);      
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
    toggleForDeleteNotesModal();
};

return (
    <>
        <tr>
        <td>{notes.reference}</td>
        <td className="text-break">{notes.description}</td>
        <td>{notes.createdBy}</td>
        <td>{notes.createdDate}</td>
        { isReadOnlyAccess1  === "FullAccess" ?
        <td className="text-right actions">
        <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
        <i className="mdi mdi-more"></i>
        <span className="sr-only">Menu</span>
        </a>
        <div className="dropdown-menu">          
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditCommentModal(); }} ><span className="icon mdi mdi-edit"></span>Edit</a>    
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForDeleteNotesModal(); }} ><span className="icon mdi mdi-delete"></span>Delete</a>                          
        </div>
        </td>
        :
        <td></td>
        }
        </tr>

<CommonCustomModal modal={editCommentModal}  title='' toggler={toggleForEditCommentModal} heading='Edit Notes for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
<EditComment toggler={toggleForEditCommentModal} verifier = {verifier} notes= {notes} refetchs={refetch} setRefetchs={setRefetch}/>
</CommonCustomModal>
<CommonModalWithImage modal={deleteNotesModal} title='Delete Notes' toggler={toggleForDeleteNotesModal} heading='Delete Notes' triggerMethodOnSaveButtonClick={deleteNotes}  buttonText='Yes'>
<Label className="col-form-label">{'Are you sure you want to delete notes '}{'?'}</Label>
</CommonModalWithImage>

</>
);

};

export default CommentList;
