import { useState } from "react";
import CommonModalWithImage from "../../Common/CommonModalWithImage";
import CommonCustomModal from "../../Common/CommonCustomModal";
import { Label } from 'reactstrap';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Navigate, useNavigate } from "react-router-dom";
import EditVerifierInvoice from "./EditVerifierInvoice";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";
import IsAccessForVerifierCombination2 from "../../Common/IsAccessForAccreditationCombination";

const VerifiersInvoiceList = ({ verifier, invoice, refetch, setRefetch,refetchs,setRefetchs,isExternalVerifierUser }) => {
console.log('in',invoice)
    const [editInvoiceModal, setInvoiceModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    const isReadOnlyAccess2 = IsAccessForVerifierCombination2();
    
  const toggleForEditInvoiceModal = () => {
    setInvoiceModal(!editInvoiceModal);
}  
const toggleForDeleteInvoiceModal = () => {
    setDeleteInvoiceModal(!deleteInvoiceModal);
}

const deleteInvoice = async () => {

    const requestBody = {
        "id": invoice.id,
        "userID": userID,
        "actionType" : 'Invoice'
    };
    try {
        const response = await fetch(`${config.apiUrl}${apiconfig.deleteExamInvoice}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        }).then((response) => {
            return response.json();
        })

        if(response.saveErrorMessage === 'Invoice deleted successfully.')
        {
            toast.success('Invoice deleted successfully.', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide
            }); 
        setRefetch(!refetch);   
        setRefetchs(!refetchs);      
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
    toggleForDeleteInvoiceModal();
};

return (
    <>
        <tr>
        {!isExternalVerifierUser?
        <>
        {isReadOnlyAccess1  === "FullAccess" ? 
        <td><a className="text-danger" href={`/viewinvoice/${invoice.invoiceID}`}>{invoice.invoiceID}</a></td>
        :
        <td>{invoice.invoiceID}</td>
        }
        <td>{invoice.invoiceNo}</td>
        <td>{invoice.invoiceStatus}</td>
        {isReadOnlyAccess1  === "FullAccess" ? 
        <>
        <td><a className="text-danger" href={`/viewexam/${invoice.examID}`}>{invoice.examID}</a></td>
        <td><a className="text-danger" href={`/viewsponsor/${invoice.sponsorID}`}>{invoice.sponsorName}</a></td>
        </>
        :
        <>
        <td>{invoice.examID}</td>
        <td>{invoice.sponsorName}</td>
        </>
        }
        <td>{invoice.clientID}</td>
        <td>{invoice.createdDateTime}</td>
        {isReadOnlyAccess1  === "FullAccess" ? 
        <td className="text-right actions">
        {((invoice.invoiceStatus !== 'Invoice Paid' && invoice.invoiceStatus !== 'Verifier Removed' && invoice.isActive === 1) ||
        (invoice.invoiceStatus === 'Invoice Raised' && invoice.examStatus === 'Payment Pending'  && invoice.isActive === 1)) && (    
        <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
        <i className="mdi mdi-more"></i>
        <span className="sr-only">Menu</span>
        </a>
        )}
        <div className="dropdown-menu">
        {invoice.invoiceStatus !== 'Invoice Paid' && (       
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditInvoiceModal(); }} ><span className="icon mdi mdi-edit"></span>Edit</a> 
        )}
        {invoice.invoiceStatus === 'Invoice Raised' && invoice.examStatus === 'Payment Pending' && (  
        <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForDeleteInvoiceModal(); }} ><span className="icon mdi mdi-delete"></span>Delete</a>  
        )}                         
        </div>
        </td>
        :
        <td></td>
    }
        </>
        :
        <>
        <td>{invoice.invoiceID}</td>
        <td>{invoice.invoiceNo}</td>
        <td>{invoice.invoiceStatus}</td>
        <td>{invoice.examID}</td>
        <td>{invoice.sponsorName}</td>
        <td>{invoice.clientID}</td>
        <td>{invoice.createdDateTime}</td>
        </>
        }
        </tr>

<CommonCustomModal modal={editInvoiceModal}  title='' toggler={toggleForEditInvoiceModal} heading='Edit Invoice for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
<EditVerifierInvoice toggler={toggleForEditInvoiceModal} verifier = {verifier} invoice= {invoice} refetchs={refetch} setRefetchs={setRefetch}  refetchd={refetchs} setRefetchd={setRefetchs}/>
</CommonCustomModal>
<CommonModalWithImage modal={deleteInvoiceModal} title='' toggler={toggleForDeleteInvoiceModal} heading='Delete Invoice' triggerMethodOnSaveButtonClick={deleteInvoice}  buttonText='Yes'>
<Label className="col-form-label">{'Are you sure you want to delete invoice '}{'?'}</Label>
</CommonModalWithImage>

</>
);

};

export default VerifiersInvoiceList;
