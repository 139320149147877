import React from 'react';
import {TiArrowSortedUp, TiArrowSortedDown} from 'react-icons/ti';

const CustomTable = ({ children, headerCells, sortByColumnName, sortOrder, columnClickCounter, setSortByColumnName, setSortOrder, setColumnClickCounter,isExternalVerifierUser }) => {

    const manageSorting = (columnName) => {
        if(sortByColumnName !== columnName){
            setColumnClickCounter(1);
            setSortByColumnName(columnName);
            setSortOrder('ASC');
        }else{
            if(columnClickCounter % 2 !== 0)
                setSortOrder('DESC');
            else
                setSortOrder('ASC');
            setColumnClickCounter(columnClickCounter + 1);
        }
      }

    return (
        <div className="table-responsive noSwipe mb-2 pt-4">
            <table className="table table-striped table-hover mb-0">
                <thead>
                    <tr>
                        {
                            headerCells.map(cell => {
                                return (                                    
                                      
                                    cell.isHidden === true
                                        ?
                                        <th hidden colSpan={cell.colSpan} style={cell.style} key={cell.id}>{cell.name}</th>
                                        :
                                        cell.isSortable === false
                                            ?
                                            cell.hasChild
                                                ?
                                                <th colSpan={cell.colSpan} style={cell.style} key={cell.id}>{cell.child}</th>
                                                :
                                                <th colSpan={cell.colSpan} style={cell.style} key={cell.id}>{cell.name}</th>
                                            :
                                            <th
                                                colSpan={cell.colSpan} style={cell.style} key={cell.id} onClick={() => { manageSorting(cell.sortByColumnName) }}>
                                                {cell.name}
                                                    {
                                                    sortByColumnName === cell.sortByColumnName
                                                        ?
                                                        sortOrder === 'ASC'
                                                            ?
                                                            <TiArrowSortedUp style={{ color: 'green' }} />
                                                            :
                                                            <TiArrowSortedDown style={{ color: 'red' }} />
                                                        :
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 34">
                                                        <g id="Group_1" data-name="Group 1" transform="translate(-442 -796.5)">
                                                        <path id="Icon_ionic-md-arrow-dropdown" data-name="Icon ionic-md-arrow-dropdown" d="M9,13.5l10,10,10-10Z" transform="translate(433 807)" fill="#686565"/>
                                                        <path id="Icon_ionic-md-arrow-dropdown-2" data-name="Icon ionic-md-arrow-dropdown" d="M9,13.5l10,10,10-10Z" transform="translate(471 820) rotate(180)" fill="#686565"/>
                                                        </g>
                                                        </svg>
                                                }
                                            </th>
                                                                            
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );

};

export default CustomTable;