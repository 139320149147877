import React from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = ({ handlePageClick, pageCount, pageRange, pageNumber }) => {

    // console.log('rendering pagination...')
    // console.log('pageCount', pageCount)
    // console.log('pageRange', pageRange)
    // console.log('pageNumber', pageNumber)

    return (
        <ReactPaginate
            breakLabel='...'
            nextLabel='next >'
            previousLabel='< prev'
            onPageChange={handlePageClick}
            pageRangeDisplayed={pageRange}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            containerClassName='pagination'
            pageClassName='page-item'
            activeClassName ='active'
            previousLinkClassName='page-link'
            nextLinkClassName='page-link'
            activeLinkClassName='active'
            pageLinkClassName='page-link'
            forcePage={pageNumber-1}
        />
    )

}

export default Pagination