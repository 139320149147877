import React, { useState,useEffect } from 'react';
import EditUserSecondary from './EditUserSecondary';
import EditUserPrimary from './EditUserPrimary';
import CommonCustomModal from '../../Common/CommonCustomModal';
import ViewUserPrimary from './ViewUserPrimary';



const AirportUser = ({ user, searchName, refetch, setRefetch }) => {

    const [editUserPModal,setEditUserPModal] = useState(false);
    const [editUserSModal,setEditUserSModal] = useState(false);
    const [viewUserModal,setViewUserModal] = useState(false);

    const toggleForEditUserSModal = () => {
        setEditUserSModal(!editUserSModal);
      } 
    const toggleForEditUserPModal = () => {
        setEditUserPModal(!editUserPModal);
    } 
    const toggleForViewUserPModal = () => {
        setViewUserModal(!viewUserModal);
    } 


    return (
        <>
            <tr className="alt-row">
                <td>{user.firstName}</td>
                <td>{user.lastName}</td>
                <td>{user.email}</td>
                {user.isPrimary===1 ? <td><span className="badge badge-success">Yes</span></td> : <td><span className="badge badge-danger">No</span></td>}
                <td className="text-center actions">
                    <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-more"></i>
                        <span className="sr-only">Menu</span>
                    </a>
                    <div className="dropdown-menu">
                    {user.isActive === 1 ?
                    user.isPrimary===1 ? 
                    <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditUserPModal(); }} ><span className="icon mdi mdi-edit"></span>Edit</a>
                    :
                    <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditUserSModal(); }} ><span className="icon mdi mdi-edit"></span>Edit</a>
                    :
                    <></>
                    }
                    <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForViewUserPModal(); }} ><span className="icon mdi mdi-eye"></span>View</a>
                    </div>
                </td>
            </tr>

            <CommonCustomModal modal={editUserSModal} title='' toggler={toggleForEditUserSModal} heading='Edit User'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <EditUserSecondary toggler={toggleForEditUserSModal} user={user} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <CommonCustomModal modal={editUserPModal} title='' toggler={toggleForEditUserPModal} heading='Edit User'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <EditUserPrimary toggler={toggleForEditUserPModal} user={user} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <CommonCustomModal modal={viewUserModal} title='' toggler={toggleForViewUserPModal} heading='View User'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <ViewUserPrimary toggler={toggleForViewUserPModal} user={user} isPrimary={user.isPrimary}/>
            </CommonCustomModal>

        </>
    );


};

export default AirportUser;