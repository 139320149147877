import React from 'react';

const TabPanel = ({ children, activeTab, tabName }) => {
    return (
        <div className={activeTab === tabName ? "tab-pane active" : "tab-pane"} role="tabpanel">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card card-contrast card-table mb-0">
                        <div className="card-body card-body-contrast">
                            <table className="table table-striped table-borderless">
                                <tbody className="no-border-x">
                                    {children}   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TabPanel;