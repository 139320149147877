import React, { useState,useEffect} from 'react';



const NotificationDetail = ({ notifications}) => {

    return (
        <>
            <tr>
                { notifications.linkUrl ?
                <td><a className="text-info" href={notifications.linkUrl}>{notifications.notification}</a></td>
                :
                <td>{notifications.notification}</td>

                }  
                <td>{notifications.createdUser}</td>      
                <td>{notifications.createdDate}</td>
            </tr>

        </>
    );
};

export default NotificationDetail;