import React, { useState} from 'react';
import CommonModalWithImage from '../../Common/CommonModalWithImage';
import { Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { Slide, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";




const AirportUser = ({ user, ReferenceID, ReferenceType, refetch, setRefetch }) => {

    console.log(user);
    const [primaryUserModal,setPrimaryUserModal] = useState(false);
    const [removePrimaryUserModal,setRemovePrimaryUserModal] = useState(false);
    const [removeUserModal,setremoveUserModal] = useState(false);
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [actionType,setActionType] = useState("");

    const toggleForSetPrimaryUserModal = () => {
        setPrimaryUserModal(!primaryUserModal);
        setActionType("SetAsPrimary");
    } 
    const toggleForRemovePrimaryUserModal = () => {
        setRemovePrimaryUserModal(!removePrimaryUserModal);
        setActionType("RemoveAsPrimary");
    } 
    const toggleForRemoveUserModal = () => {
        setremoveUserModal(!removeUserModal);
        setActionType("RemoveUser");
    } 

    const addRemoveAirportUser = async () => {
        const requestBody = {
          "referenceID": ReferenceID,
          "referenceType": ReferenceType,
          "actionType": actionType,
          "userID": user.userID,
          "loggedInUserID": userID
        };
              try {
                  const response = await fetch(`${config.apiUrl}${apiconfig.addRemoveUserWithAirport}`,{
                          method: "POST",
                          headers: {
                              "Content-Type": "application/json",
                              'Authorization': `Bearer ${localStorage.getItem("token")}`
                          },
                          body: JSON.stringify(requestBody),
                      });
                      if (response.ok) {
                          var result = await response.json();
                          if(result.saveErrorMessage === 'User set as primary.')
                          {
                              toast.success('User set as Primary Contact for Airport.', {
                                  position: "top-right",
                                  autoClose: 10000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                  transition: Slide
                              }); 
                          setRefetch(!refetch);
                          toggleForSetPrimaryUserModal();
                          }else if(result.saveErrorMessage === 'User removed as primary.')
                          {
                              toast.success('User removed as Primary Contact for Airport.', {
                                  position: "top-right",
                                  autoClose: 10000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                  transition: Slide
                              }); 
                          setRefetch(!refetch);
                          toggleForRemovePrimaryUserModal();
                          }else if(result.saveErrorMessage === 'User removed.')
                          {
                              toast.success('User removed from Airport.', {
                                  position: "top-right",
                                  autoClose: 10000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                  transition: Slide
                              }); 
                          setRefetch(!refetch);
                          toggleForRemoveUserModal();
                          }
                          else{
                              setErrorMessage(result.saveErrorMessage);
                          }
                      } else {
                          const errorText = await response.text();
                          const apiname = `${apiconfig.addRemoveUserWithAirport}`;
                          navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                      }
              } catch (error) {
                  console.log("Error: ", error);
                  navigate("/error-page");
              }
    }


    return (
        <>
            <tr className="alt-row">
                <td>{user.firstName} {user.lastName}</td>
                <td>{user.address1}</td>
                <td>{user.email}</td>
                {user.isPrimary===1 ? <td><span className="badge badge-success">Yes</span></td> : <td><span className="badge badge-danger">No</span></td>}
                <td>{user.state}</td>
                <td className="text-center actions">
                    <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-more"></i>
                        <span className="sr-only">Menu</span>
                    </a>
                    <div className="dropdown-menu">
                    <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {toggleForRemoveUserModal();}}><span className="icon mdi mdi-delete"></span>Remove User</a>
                    {user.isPrimary!==1 && (
                         <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {toggleForSetPrimaryUserModal();}}><span className="icon mdi mdi-collection-plus"></span>Set as Primary</a>
                    ) }
                    {/* : (
                        <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {toggleForRemovePrimaryUserModal();}}><span className="icon mdi mdi-close-circle"></span>Remove as Primary</a>
                    )} */}
                    </div>
                </td>
            </tr>

            <CommonModalWithImage modal={removeUserModal} title='' toggler={toggleForRemoveUserModal} heading='Remove User' triggerMethodOnSaveButtonClick={addRemoveAirportUser} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to Remove User '}{'?'}</Label>
            </CommonModalWithImage>
            <CommonModalWithImage modal={primaryUserModal} title='' toggler={toggleForSetPrimaryUserModal} heading='Set as Primary' triggerMethodOnSaveButtonClick={addRemoveAirportUser} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to set User as Primary '}{'?'}</Label>
            </CommonModalWithImage>
            <CommonModalWithImage modal={removePrimaryUserModal} title='' toggler={toggleForRemovePrimaryUserModal} heading='Remove as Primary' triggerMethodOnSaveButtonClick={addRemoveAirportUser} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to remove User as Primary '}{'?'}</Label>
            </CommonModalWithImage>

        </>
    );


};

export default AirportUser;