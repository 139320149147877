


const HistoricalApplicationList = ({ appList}) => {

    return (
      <>
        <tr>
        <td>{appList.reportingPeriod}</td>
        <td>{appList.airportApplicationID}</td>
        <td>{appList.applicationType}</td>
        <td>{appList.assignee}</td>
        <td>{appList.accreditationLevel}</td>
        <td className="text-right actions">
          <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
              <i className="mdi mdi-more"></i>
              <span className="sr-only">Menu</span>
          </a>
          <div className="dropdown-menu">
              <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${appList.airportApplicationID}/AirportID/${appList.airportID}`}><span className="icon mdi mdi-eye"></span>View</a>
          </div>
      </td>
      </tr>
  
      </>
    );
};

export default HistoricalApplicationList;