import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const RequirementNotMet = ({ toggler, verifier ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [notes, setNotes] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [isConfirm, setIsConfirm] = useState(false);
    const handleConfirm = (e)=>{
      setIsConfirm(e.target.checked);
    }


    const [confirmationText, setConfirmationText] = useState('')

    const getConfirmationText = async () => {
      try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getConfirmationText}${'RequirementNotMet'}`, {
              method: 'GET',
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`
              }
          })
          if (response.ok) {
              const result = await response.json()
              setConfirmationText(result[0].narration)
          } else {
              const errorText = await response.text()
              const apiname = `${apiconfig.getConfirmationText}`
              navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
          }
      }
      catch(error){
          //console.log(error.message)
          navigate(`/error-page`)
      }
  }

  useEffect(() => {
    getConfirmationText()
}, [])


    {/*Validation*/}
    const validateForm = (comments,isConfirm) => {
        const errors = {};
        if(!isConfirm){
          errors.isConfirm = 'In order to proceed, please provide confirmation if the Prospective Verifier does not have the relevant experience.';
        }
        if(!comments){
            errors.comments = 'Please enter the notes.';
        }else if(comments.length > 1000){
            errors.comments = 'Maximum of 1000 characters allowed.';
        }else if(comments.length < 10){
          errors.comments = 'Please enter a minimum of 10 characters.';
      }
        return errors;
    }

    const requireNotMet = async () => {
        const requestBody = {
            verifierID : verifier.verifierDetailID,
            noteDescription :  notes.trim(),
            userID : userID,
            actionType : "Requirements Not Met"


        };
        const validationErrors = validateForm(notes.trim(),isConfirm);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.updateVerifierStatus}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier Status has been updated successfully to Requirements Not Met.')
            {
                toast.success('Verifier Status has been updated successfully to Requirements Not Met.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

    return (
        <div>
    <form>
                    <div className="form-row">
                        <label htmlFor="input_comments">Notes</label>
                        <textarea type="text" placeholder='Please enter the notes...' className="form-control form-control-xs" id="input_comments" onChange={(e) => { setNotes(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
                    <div className="form-row">               
                    <div  className="custom-control custom-checkbox">
                        <input  className="custom-control-input" type="checkbox" id="isConfirm" onChange={handleConfirm}/>
                        <label  className="custom-control-label" htmlFor="isConfirm" >{confirmationText}</label>
                        <p style={{color:'red'}}>{formErrors.isConfirm}</p>
                    </div>  
                    </div>   
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={requireNotMet}>Submit</button>
            </div>
            </div>
    );
};

export default RequirementNotMet;