import React, { useState,useEffect} from 'react';
import { MdExpandMore , MdExpandLess} from 'react-icons/md';
import CommonCustomModal from '../Common/CommonCustomModal';
import EditVerifier from './EditVerifier';
import UpGradeVerifier from './UpGrade';
import DownGradeVerifier from './DownGrade';
import RenewVerifier from './Renew';
import AddVerifierExam from './Exam/AddVerifierExam';
import AddInvoice from './Invoice/AddInvoice';
import AddComment from './Comment/AddComment';
import AddCommunication from './Communication/AddCommunication';
import AddDocument from './Document/AddDocument';
import EditVerifierExam from './Exam/EditVerifierExam';
import EditVerifierInvoice from './Invoice/EditVerifierInvoice';
import EditCommunication from './Communication/EditCommunication';
import ArchiveUser from './ArchiveVerifier';
import { Link } from 'react-router-dom';
import VerifierConfirmation from './VerifierConfirmation';
import CommonModalWithImage from '../Common/CommonModalWithImage';
import AddSponsorsToVerifier from './AddSponsorsToVerifier';
import config from '../../api/config';
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import VerifierDetailsTab from './VerifierDetailsTab';
import RequirementNotMet from './RequirementNotMet';
import RequirementMet from './RequirementMet';
import RemovedVerifier from './RemovedVerifier';
import EditVerifierAndSponsor from './EditVerifierAndSponsor';
import { Label } from 'reactstrap';
import { Slide, ToastContainer, toast } from 'react-toastify';
import AssignedVC from './AssignedVC';
import RenewManual from './RenewManual';
import ActivateVerifier from './ActivateVerifier';
import UpGradeReqNotMetVerifier from './UpGradeReqNotMet';
import RenewLevelAndReason from './RenewLevelAndReason';
import IsAccessForVerifierCombination1 from '../Common/IsAccessForVerifieCombination1';
import IsAccessForVerifierCombination2 from '../Common/IsAccessForAccreditationCombination';

const Verifier = ({ verifier, searchName, refetch, setRefetch, hasAnythingChangedInParentTable }) => {

    const [editVerifierModal, setEditVerifierModal] = useState(false);
    const [upGradeModal, setUpGradeModal] = useState(false);
    const [downGradeModal, setDownGradeModal] = useState(false);
    const [renewModal, setRenewModal] = useState(false);
    const [addExamModal, setAddExamModal] = useState(false);
    const [editExamModal, setEditExamModal] = useState(false);
    const [addInvoiceModal, setAddInvoiceModal] = useState(false);
    const [editInvoiceModal, setEditInvoiceModal] = useState(false);
    const [addCommentModal, setAddcommentModal] = useState(false);
    const [addCommunicationModal, setAddCommunicationModal] = useState(false);
    const [editCommunicationModal, setEditCommunicationModal] = useState(false);
    const [addDocumentModal, setAdddocumentModal] = useState(false);
    const [archiveVerifier,setArchiveVerifier]  = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [addSponsorModal, setAddSponsorModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [reqNotMet,setReqNotMet]  = useState(false);
    const [requirementMetModal, setRequirementMetModal] = useState(false);
    const [removedModal, setRemovedModal] = useState(false);
    const [activateModal, setActivateModal] = useState(false);
    const [assignedVCModal, setAssignedVCModal] = useState(false);
    const [removedVCModal, setRemovedVCModal] = useState(false);
    const [isRenewManual, setIsRenewManual] = useState(false);
    const [upGradeReqNotMetModal, setUpGradeReqNotMetModal] = useState(false);
    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    const isReadOnlyAccess2 = IsAccessForVerifierCombination2();

    const toggleForEditVerifierModal = () => {
        setEditVerifierModal(!editVerifierModal);
    }  
    const toggleForUpGradeModal = () => {
        setUpGradeModal(!upGradeModal);
    }  
    const toggleForDownGradeModal = () => {
        setDownGradeModal(!downGradeModal);
    }  
    const toggleForRenewLevelAndReasonModal = () => {
        setRenewModal(!renewModal);
    }  
    const toggleForAddExamModal = () => {
        setAddExamModal(!addExamModal);
    }  
    const toggleForEditExamModal = () => {
        setEditExamModal(!editExamModal);
    }  
    const toggleForAddInvoiceModal = () => {
        setAddInvoiceModal(!addInvoiceModal);
    }  
    const toggleForEditInvoiceModal = () => {
        setEditInvoiceModal(!editInvoiceModal);
    }  
    const toggleForAddCommentModal = () => {
        setAddcommentModal(!addCommentModal);
    }  
    const toggleForAddCommunicationModal = () => {
        setAddCommunicationModal(!addCommunicationModal);
    }  
    const toggleForEditCommunicationModal = () => {
        setEditCommunicationModal(!editCommunicationModal);
    }  
    const toggleForAddDocumentModal = () => {
        setAdddocumentModal(!addDocumentModal);
    }  
    const toggleForArchiveVerifierModal = () => {
        setArchiveVerifier(!archiveVerifier);
    }
    const toggleForAddSponsorModal = () => {
        setAddSponsorModal(!addSponsorModal);
    } 
    const toggleForConfirmationModal = () => {
        setConfirmModal(!confirmModal);
    }  
    const handleExpandToggle = () => {
        setIsExpanded(!isExpanded);     
    };
    const toggleForReqNotmetModal = () => {
        setReqNotMet(!reqNotMet);
      }
    const toggleForRequirementMetModal = () => {
        setRequirementMetModal(!requirementMetModal);
    }
    const toggleForRemovedModal = () => {
      setRemovedModal(!removedModal);
    } 

    const toggleForActivateModal = () => {
      setActivateModal(!activateModal);
    } 
    const toggleForAssignedVCModal = () => {
      setAssignedVCModal(!assignedVCModal);
  }
  const toggleForRemovedVCModal = () => {
    setRemovedVCModal(!removedVCModal);
  } 
  const toggleForUpGradeReqNotMetModal = () => {
    setUpGradeReqNotMetModal(!upGradeReqNotMetModal);
} 



  useEffect(() => {
    if(isExpanded)
        setIsExpanded(false)
  }, [hasAnythingChangedInParentTable])


    const activateVerifier = async () => {
      const requestBody = {
          verifierID : verifier.verifierDetailID,
          noteDescription : '',
          userID : userID,
          actionType : "Activate"


      };
        try {
          const response = await fetch(
            `${config.apiUrl}${apiconfig.updateVerifierStatus}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
              body: JSON.stringify(requestBody),
            }
          ).then((response) => {
            return response.json();
          });
          if(response.saveErrorMessage === 'Verifier has been Activated successfully.')
          {
              toast.success('Verifier has been Activated successfully.', {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Slide
              }); 
              setRefetch(!refetch);
          toggleForActivateModal();
          }
          // else{
          //     console.log(response.saveErrorMessage);
          //     setErrorMessage(response.saveErrorMessage);
          // }
        } catch (error) {
          console.log("Error: ", error);
          navigate("/error-page");
        }

    };

    const removeVC = async () => {
      const requestBody = {
        verifierID : verifier.verifierDetailID,
        userID : userID,
        actionType : "Remove"

    };
        try {
          const response = await fetch(
            `${config.apiUrl}${apiconfig.assignRemoveVC}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
              body: JSON.stringify(requestBody),
            }
          ).then((response) => {
            return response.json();
          });
          if(response.saveErrorMessage === 'VC removed successfully.')
          {
              toast.success('VC removed successfully.', {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Slide
              }); 
              setRefetch(!refetch);
              toggleForRemovedVCModal();
          }
          // else{
          //     console.log(response.saveErrorMessage);
          //     setErrorMessage(response.saveErrorMessage);
          // }
        } catch (error) {
          console.log("Error: ", error);
          navigate("/error-page");
        }

    };
      

    const isDisabled = verifier.sponsor === 'NA';

    return (
        <>
            <tr>
                <td>
                    <button type="button" className="btn btn-sm btn-link exploder" onClick={handleExpandToggle}>
                        {/* <i className="icon mdi mdi-chevron-down"></i> */}
                        {isExpanded ? <MdExpandLess /> : <MdExpandMore />}
                        <span className="sr-only">icon</span>
                    </button>
                </td>
                {isReadOnlyAccess1  === "FullAccess" || isReadOnlyAccess1  === "ReadOnlyAccess"? 
                <td className="text-break"><a className="text-danger" href={`/viewverifier/${verifier.verifierDetailID}`}>{verifier.verifierName}</a></td>
                :
                <td className="text-break">{verifier.verifierName}</td>
                }
                {isReadOnlyAccess1  === "FullAccess" ? 
                <td className="text-break">
                    {
                        isDisabled 
                            ? 
                            <span>{verifier.sponsor}</span> 
                            :
                            <a className="text-danger" href={`/viewsponsor/${verifier.sponsorID}`}>{verifier.sponsor}</a>         
                    }
                </td>
                :
                <td className="text-break">{verifier.sponsor}</td>
                }
                <td>{verifier.sponsorType}</td>
                {
                    verifier.verifierStatus === 'Removed' 
                    ? 
                    <td><span className="badge badge-danger">{verifier.verifierStatus}</span></td> 
                    :
                        verifier.verifierStatus === 'Expired'
                        ?
                        <td><span className="badge badge-primary">{verifier.verifierStatus}</span></td>   
                        :
                        verifier.verifierStatus === 'Requirements Not Met'
                        ?
                        <td><span className="badge badge-warning">{verifier.verifierStatus}</span></td> 
                        :
                        verifier.verifierStatus === 'Requirements Met'
                        ?
                        <td><span className="badge badge-info">{verifier.verifierStatus}</span></td> 
                        :
                        <td><span className="badge badge-success">{verifier.verifierStatus}</span></td> 
                }
                <td>{verifier.accreditationLevel}</td>
                {
                  verifier.archivedVC ==='Not Archived' ?
                  <td>{verifier.assignedVC}</td> 
                  :
                  <td><span className="badge bg-secondary">{verifier.assignedVC}</span></td> 
                }
                <td>{verifier.dateApproved}</td>
               
                <td>{verifier.region}</td>
                <td>{verifier.country}</td> 
                <td><span className={verifier.renewalLapseColor}>{verifier.renewalLapse}</span></td> 
                <td>{verifier.isSuspended}</td>
                <td>{verifier.isUpgrade}</td>        
                <td>{verifier.lastUpdatedDate}</td>
                {isReadOnlyAccess1 !== 'NoAccess' ?
                <td className="text-center actions">
                    <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                        <i className="mdi mdi-more"></i>
                        <span className="sr-only">Menu</span>
                    </a>
                    <div className="dropdown-menu"> 
{isReadOnlyAccess1 === 'FullAccess' ?          
<>  
{(verifier.verifierStatus === 'Prospective' || verifier.verifierStatus === 'Pending')? (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRequirementMetModal(); }}><span className="icon mdi mdi-check-circle"></span>Requirement Met</a>
<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForReqNotmetModal(); }}><span className="icon mdi mdi-close-circle"></span>Requirements Not Met</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
    </>
  ) : verifier.verifierStatus === 'Requirements Not Met' ? (
    <>
    {/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRequirementMetModal(); }}><span className="icon mdi mdi-check-circle"></span>Requirement Met</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
{verifier.accreditationLevel === 'Level 1-3+' &&
(verifier.sponsor === 'NA' ?
<a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
:
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForUpGradeReqNotMetModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
)}
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
    </>
  ) : verifier.verifierStatus === 'Requirements Met' ? (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
{verifier.isRenewalApplicableFor2Attempt === 'Yes' ? 
(verifier.sponsor === 'NA' ?
<a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-refresh"></span>Renew</a>
:
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRenewLevelAndReasonModal(); }}><span className="icon mdi mdi-refresh"></span>Renew</a>
)
:
(verifier.sponsor === 'NA' ?
<a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
:
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForUpGradeModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
)
} 
    </>
  ) :  (verifier.verifierStatus === 'Upgrading - Invoice' || verifier.verifierStatus === 'Renewing - Invoice') ? (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
    </>
  ):  (verifier.verifierStatus === 'Upgrading - Exam' || verifier.verifierStatus === 'Renewing - Exam') ? (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
    </>
  ):  verifier.verifierStatus === 'Removed' ? (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForActivateModal(); }}><span className="icon mdi mdi-check-circle"></span>Activate</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
    </>
  ):  verifier.verifierStatus === 'Approved' ? (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
{/* {(verifier.sponsor !== 'NA' && verifier.accreditationLevel !=='Level 4-4+' && verifier.isUpgrade === 'Yes') &&( */}
{(verifier.accreditationLevel !=='Level 4-4+') &&(
  verifier.sponsor === 'NA'  ? 
  <a className="dropdown-item" style={{cursor: 'not-allowed'}} title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a> 
  :
  <a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForUpGradeModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a> 
)}
{(verifier.accreditationLevel ==='Level 4-4+') &&
<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={ () => { toggleForDownGradeModal(); }}><span className="icon mdi mdi-trending-down"></span>Downgrade</a>
} 
{/* {verifier.isRenew === 'Yes' &&  */}
{
verifier.sponsor === 'NA' ?
<a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-refresh"></span>Renew</a>
:
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRenewLevelAndReasonModal(); }}><span className="icon mdi mdi-refresh"></span>Renew</a>

} 


    </>
  ):  verifier.verifierStatus === 'Due for Renewal' ? (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
{verifier.sponsor === 'NA' ?
<a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
:
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForUpGradeModal(); }}><span className="icon mdi mdi-trending-up"></span>Upgrade</a>
} 
 
{verifier.sponsor === 'NA' ?
<a className="dropdown-item" style={{ cursor: 'not-allowed' }}  title="Please set Primary Sponsor."><span className="icon mdi mdi-refresh"></span>Renew</a>
:
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRenewLevelAndReasonModal(); }}><span className="icon mdi mdi-refresh"></span>Renew</a>
} 
    </>
  ):  verifier.verifierStatus === 'Expired' ? (
    <>
    {/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForRemovedModal(); }}><span className="icon mdi mdi-delete"></span>Remove</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
    </>
  ):
  (
    <>
{/* <Link className="dropdown-item" to={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</Link> */}
<a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForEditVerifierModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommunicationModal(); }}><span className="icon mdi mdi-comments"></span>Add Communication</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddCommentModal(); }}><span className="icon mdi mdi-comment-alt-text"></span>Add Notes</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
<a className="dropdown-item" style={{cursor:'pointer'}} onClick={ () => { toggleForAddSponsorModal(); }}><span className="icon mdi mdi-plus"></span>Add Sponsor</a>
{verifier.assignedVC==='Not Set' ? 
(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAssignedVCModal(); }}><span className="icon mdi mdi-accounts-add"></span>Assign VC</a>)
:(<a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForRemovedVCModal(); }}><span className="icon mdi mdi-close-circle-o"></span>Remove VC</a>)
}
</>
  )}
  </>   
  :
  isReadOnlyAccess1 === 'ReadOnlyAccess' ?
  <a className="dropdown-item" style={{cursor:'pointer'}} href={`/viewverifier/${verifier.verifierDetailID}`}><span className="icon mdi mdi-eye"></span>View</a>
  :
  <></>

}
                    </div>
                </td>
                :
                <td></td>
                }
            </tr>
            {
                isExpanded 
                &&
                <tr>
                    <td colSpan="15" className={`explode ${isExpanded ? "" : "d-none"}`}>
                        <VerifierDetailsTab verifierID={verifier.verifierDetailID} verifierStatus={verifier.verifierStatus} refetchs={refetch} setRefetchs={setRefetch} />
                    </td>
                </tr>
            }


            { /* MODAL FOR Edit VERIFIER */}
            <CommonCustomModal modal={editVerifierModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForEditVerifierModal} heading='Edit Verifier'  buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <EditVerifierAndSponsor toggler={toggleForEditVerifierModal} verifier={verifier} toggler1={toggleForConfirmationModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>

            <CommonCustomModal modal={upGradeModal} title='' toggler={toggleForUpGradeModal} heading='Upgrade Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <UpGradeVerifier verifier={verifier} toggler={toggleForUpGradeModal} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>

            <CommonCustomModal modal={downGradeModal} title='' toggler={toggleForDownGradeModal} heading='Downgrade Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <DownGradeVerifier toggler={toggleForDownGradeModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>

            <CommonCustomModal modal={renewModal}  title='' toggler={toggleForRenewLevelAndReasonModal} heading=''  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              {isRenewManual ?
              <RenewManual toggler={toggleForRenewLevelAndReasonModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch}/>
                :
              <RenewLevelAndReason toggler={toggleForRenewLevelAndReasonModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} isRenewalApplicableFor2Attempt = {verifier.isRenewalApplicableFor2Attempt}/>
              }
            </CommonCustomModal>

            <CommonCustomModal modal={addExamModal}  title='' toggler={toggleForAddExamModal} heading='Add Exam for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddVerifierExam toggler={toggleForAddExamModal}/>
            </CommonCustomModal>

            <CommonCustomModal modal={editExamModal}  title='' toggler={toggleForEditExamModal} heading='Edit Exam for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <EditVerifierExam toggler={toggleForEditExamModal}/>
            </CommonCustomModal>

            <CommonCustomModal modal={addInvoiceModal}  title='' toggler={toggleForAddInvoiceModal} heading='Add Verifier Exam Invoice'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddInvoice toggler={toggleForAddInvoiceModal}/>
            </CommonCustomModal>

            <CommonCustomModal modal={editExamModal}  title='' toggler={toggleForEditInvoiceModal} heading='Edit Verifier Exam Invoice'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <EditVerifierInvoice toggler={toggleForEditInvoiceModal}/>
            </CommonCustomModal>

            <CommonCustomModal modal={addCommentModal}  title='' toggler={toggleForAddCommentModal} heading='Add Notes for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddComment verifier={verifier} toggler={toggleForAddCommentModal} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>

            <CommonCustomModal modal={addCommunicationModal}  title='' toggler={toggleForAddCommunicationModal} heading='Add Communication for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddCommunication verifier={verifier} toggler={toggleForAddCommunicationModal} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>

            <CommonCustomModal modal={editCommunicationModal}  title='' toggler={toggleForEditCommunicationModal} heading='Edit Communication for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <EditCommunication toggler={toggleForEditCommunicationModal}/>
            </CommonCustomModal>

            <CommonCustomModal modal={addDocumentModal}  title='' toggler={toggleForAddDocumentModal} heading='Add Document for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddDocument verifier={verifier} toggler={toggleForAddDocumentModal} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>

            <CommonCustomModal modal={archiveVerifier} title='' toggler={toggleForArchiveVerifierModal} heading='Archive Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <ArchiveUser toggler={toggleForArchiveVerifierModal}/>
            </CommonCustomModal>

            <CommonCustomModal modal={addSponsorModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAddSponsorModal} heading='Add Sponsor'  buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddSponsorsToVerifier  verifier={verifier} toggler={toggleForAddSponsorModal} refetchs={refetch} setRefetchs={setRefetch}  />
            </CommonCustomModal>
            <CommonCustomModal modal={reqNotMet}  title='' toggler={toggleForReqNotmetModal} heading='Requirements Not Met'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <RequirementNotMet verifier={verifier} toggler={toggleForReqNotmetModal} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>

            <CommonCustomModal modal={requirementMetModal} title='' toggler={toggleForRequirementMetModal} heading='Requirement Met'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <RequirementMet toggler={toggleForRequirementMetModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            
            <CommonCustomModal modal={removedModal} title='' toggler={toggleForRemovedModal} heading='Remove Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <RemovedVerifier toggler={toggleForRemovedModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            {/* <CommonModalWithImage modal={activateModal} title='' toggler={toggleForActivateModal} heading='Activate Verifier' triggerMethodOnSaveButtonClick={activateVerifier} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to Activate the Verifier '}{'?'}</Label>
            </CommonModalWithImage> */}
            <CommonCustomModal modal={activateModal} title='' toggler={toggleForActivateModal} heading='Activate Verifier' buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                    <ActivateVerifier toggler={toggleForActivateModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <CommonCustomModal modal={assignedVCModal} title='' toggler={toggleForAssignedVCModal} heading='Assign VC to a Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AssignedVC toggler={toggleForAssignedVCModal} verifier={verifier} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <CommonModalWithImage modal={removedVCModal} title='' toggler={toggleForRemovedVCModal} heading='Remove VC from a Verifier' triggerMethodOnSaveButtonClick={removeVC} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to Remove VC '}{'?'}</Label>
            </CommonModalWithImage>

              {/* MODAL FOR CONFIRMATION */}
              <CommonModalWithImage modal={confirmModal}  title='' toggler={toggleForConfirmationModal} heading='' >
                <VerifierConfirmation/>
            </CommonModalWithImage>
            <CommonCustomModal modal={upGradeReqNotMetModal} title='' toggler={toggleForUpGradeReqNotMetModal} heading='Upgrade Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <UpGradeReqNotMetVerifier verifier={verifier} toggler={toggleForUpGradeReqNotMetModal} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
        </>
    );
};

export default Verifier;