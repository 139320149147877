import React from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import ConvertToRelativeDateString from '../../Helper/ConvertToRelativeDateString';

const UsersEvent = ({ usersEvent }) => {

    let activityTime = ConvertToRelativeDateString(usersEvent.eventDate);

    return (
        <tr className="alt-row">
            <td className="user-avatar">
                <img src={require('../../../img/avatar6.png')} alt="Avatar 6" />
                {/* <Link to={`/UserDetails/${usersEvent.id}`}>
                    {usersEvent.displayName}
                </Link> */}
                 <a  href={`/UserDetails/${usersEvent.id}`}>{usersEvent.displayName}</a>
                {' : ' + usersEvent.name}
            </td>
            <td>{usersEvent.description}</td>
            <td style={{ textAlign: 'center' }}><BiTimeFive />&nbsp;&nbsp;{activityTime}</td>
        </tr>
    );

};

export default UsersEvent;