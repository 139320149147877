import React from 'react';

const Permission = ({ permission, searchName }) => {

    if (permission.name.toLowerCase().includes(searchName.trim())) {
        return (
            <>
                <tr className="alt-row">
                    <td>{permission.name}</td>
                    <td>{permission.description}</td>
                </tr>
            </>
        );
    }

};

export default Permission;