import React, { useState, useEffect } from "react";
import config from "../../../api/config";
import apiconfig from "../../../api/apiconfig";
import { useNavigate } from "react-router-dom";







const AirportApplicationEvents  = ({ accAirport }) => {

  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const getEventList = () => {
    fetch(`${config.apiUrl}${apiconfig.getAirportEventList}${accAirport.airportApplicationID}&ReferenceType=${'Application'}`, {
      method: "GET",
      headers: {

          Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setEventList(data);
      })
      .catch((err) => {
        console.log("Error: ", err);
        navigate("/error-page");
      });
  };

useEffect(() => {
  getEventList();
}, []);


        return (

          <div>
            <div className="card card-contrast card-table mb-0">
                <div className="card-body card-body-contrast">

                    <table className="table table-striped table-borderless">
                        <tbody className="no-border-x">
                        <tr>
                        <th style={{width:'40%'}}>Event Description</th>
                        <th style={{width:'20%'}}>Event Type</th>
                        <th style={{width:'20%'}}>Created By</th>
                        <th style={{width:'20%'}}>Date Created</th>
                        </tr> 
                        {!eventList.length  ?
                            (
                            <tr className="alt-row">
                            <td colSpan="4">&nbsp; Event does not exists.</td>
                            </tr>
                            ):
                            (eventList.map((event, index) => (
                                <tr key={index}>
                                    <td>{event.description}</td>
                                    <td>{event.eventType}</td>
                                    <td>{event.createdUser}</td>
                                    <td>{event.createdDate}</td>
                                </tr>
                            )))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
          </div>



        );
};

export default AirportApplicationEvents ;