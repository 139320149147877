import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import { TableHeaderCells } from './TableHeaderCells'
import AdvancedSearchByIDNew from '../../Common/AdvancedSearchByIDNew'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import {ToastContainer} from 'react-toastify'
import CommonCustomModal from '../../Common/CommonCustomModal'
import Airport from './Airport'
import AddAirportInformation from './AddAirportInformation'
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination'
import Loader from '../../Common/Loader'

const Airports = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [airportList, setAirportList] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [sortColumn, setSortColumn] = useState('')
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('IATACode')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('IATA Code')
    const [searchType, setSearchType] = useState('text')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [searchText, setSearchText] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [addAirportModal, setAddAirportModal] = useState(false)
    const [countryList, setCountryList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [cityList, setCityList] = useState([])
    const [airportGpList, setAirportGpList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const isFullAccess = IsAccessForAccreditationCombination()
    const searchOptions = [
        {
            value: 'IATA Code', 
            text: 'IATA Code',
            type: 'text',
            query: '&IATACode='
        },
        {
            value: 'Region',
             text: 'Region', 
            type: 'dropdownbyID',
            query: '&RegionID=',
            dropdownOptions: regionList
        },
        {
            value: 'Country', 
            text: 'Country',
            type: 'dropdownbyID',
            query: '&CountryID=',
            dropdownOptions: countryList
        },
        {
            value: 'City', 
            text: 'City',
            type: 'dropdownbyID',
            query: '&CityID=',
            dropdownOptions: cityList
        },
        {
            value: 'Airport Name(Official Local)', 
            text: 'Airport Name(Official Local)',
            type: 'text',
            query: '&AirportName_Local='
        },
        {
            value: 'Airport Name(Official English)', 
            text: 'Airport Name(Official English)',
            type: 'text',
            query: '&AirportName_English='
        },
        {
            value: 'Airport Group', 
            text: 'Airport Group',
            type: 'dropdownbyID',
            query: '&AirportGroupID=',
            dropdownOptions: airportGpList
        },
        {
            value: 'General Aviation Airport?', 
            text: 'General Aviation Airport?',
            type: 'text',
            query: '&IsGeneralAviationAirport='
        },
        {
            value: 'Year Joined Programme', 
            text: 'Year Joined Programme',
            type: 'text',
            query: '&YearJoinedACA='
        },
        {
            value: 'Left Programme?', 
            text: 'Left Programme?',
            type: 'dropdownbyID',
            query: '&LeftProgramme=',
            dropdownOptions: [
            {text: 'Yes', value: '1'},
            {text: 'No', value: '0'},
        ]
        },
    ]

    /* Methods */
    const fetchData = async () => {
        try {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            console.log('executing getAirportList()...')
            setIsLoading(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportList}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getAirportList()')
                const result = await response.json()
                let fetchedReporting = []
                for (let i = 0; i < result.length; i++) {
                    fetchedReporting.push(result[i])
                }
                setAirportList(fetchedReporting)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAirportList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoading(false)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const getRegionList = async () => {
        try{
            console.log('executing getRegionList()...')
            const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getRegionList()')
                var result = await response.json()
                result = result.slice(1)
                setRegionList(result)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getRegion}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const getCountryList = async () => {
        try{
            console.log('executing getCountryList()...')
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryL}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getCountryList()')
                var result = await response.json()
                result = result.slice(1)
                setCountryList(result)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getCountryL}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const getCityList = async () => {
        try{
            console.log('executing getCityList()...')
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCityList}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getCityList()')
                var result = await response.json()
                result = result.slice(1)
                setCityList(result)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getCityList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`) 
        }
    }  
    const getAirportGpList = async () => {
        try{
            console.log('executing getAirportGpList()...')
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAirportGpList}${'Term'}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getAirportGpList()')
                var result = await response.json()
                const option = result.map((iata) => ({
                    text: iata.name,
                    value: iata.id,
                }))
                setAirportGpList(option)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAirportGpList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`) 
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }
    const toggleForAddAirportModal = () => {
        setAddAirportModal(!addAirportModal)
    }   

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria])
    useEffect(() => {
        if(airportList.length > 0){
            setPageCount(Math.ceil(airportList[0].totalCount / itemsPerPage))
        }
    }, [airportList])
    useEffect(()=>{
        getRegionList()
        getCountryList()
        getCityList()
        getAirportGpList()
    },[])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(airportList.length !== 0){
            if(airportList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])


    return (
        <Layout>
            {
                isLoading
                    ?
                    <Loader />
                    :
                    <React.Fragment></React.Fragment>
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                <div className="page-header ph-lightgreen mb-4">
                    <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">Airports</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">
                                {isFullAccess === 'AccFullAccess' && (
                                <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddAirportModal(); }}>Airport Information <i className="icon icon-left mdi mdi-plus"></i></button>
                                )}
                            </div>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb mb-0 mt-4">
                                <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                <li className="breadcrumb-item active">Airports</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                
                <div className="container-header">
                    <h3 className="page-header-title">Search Airport</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            <p className="mb-2 font-weight-normal">
                                {/* <strong>Search</strong>
                                <br /> */}
                                Use the filter categories and search field below to find airport, expand the preview to see more information.
                            </p>
                            <AdvancedSearchByIDNew searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} searchText={searchText} setSearchText={setSearchText} setPageNumber={setPageNumber} />
                            {
                                airportList.length > 0
                                    ?
                                    <div className="align-self-center font-weight-bold">{airportList[0].totalCount} items found</div>
                                    :
                                    <div className="align-self-center font-weight-bold">No items found</div>
                            }
                        </div>
                        <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                            <nav>
                                {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                    <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                    <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                                </div> */}
                                <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                            </nav>
                        </div>
                    </div>
                    <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                        {airportList.map(airport => <Airport key={airportList.indexOf(airport)} airport={airport} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                    </CustomTable>
                    <div className="d-flex justify-content-end">
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </div>
                    </div>
                </div>
            </div>
            <CommonCustomModal modal={addAirportModal}  modalWidth='100%' title='' toggler={toggleForAddAirportModal} heading='Add Airport Information' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddAirportInformation toggler={toggleForAddAirportModal}  refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <ToastContainer/>
        </Layout>
    )
}

export default Airports