import React, { useState, useEffect } from "react";
import LoginNavBar from "../../Login/LoginNavBar";
import {loadCaptchaEnginge,LoadCanvasTemplate,LoadCanvasTemplateNoReload,validateCaptcha,} from "react-simple-captcha";
import Footer from "../../../Layout/Footer";
import {useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify'
import config from "../../../api/config";
import apiconfig from "../../../api/apiconfig";

const PasswordReset = () => {

  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const navigate  = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    loadCaptchaEnginge(6, "grey");
  },[]);


  const passwordReset = async () => {
    setErrorMessage("");
    const validationErrors = validateForm(email);
    const captchaErrors = CaptchaValidation();
    const combinedErrors = { ...validationErrors, ...captchaErrors };
    setFormErrors(combinedErrors);
    if (Object.keys(combinedErrors).length === 0 ) {
            const requestBody = {
                "email": email
            };
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.resetUserPassword}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    body: JSON.stringify(requestBody)
                });
                if(response.ok){  
                  var result = await response.json();
                  if(result.saveErrorMessage === 'Password has been reset successfully.'){ 
                    setEmail("");                     
                    toast.success('If you have a valid user account, an email containing your temporary password has been sent. This will be sent within the next 30 minutes and please also remember to check your junk folder. If you fail to receive an email, please contact: ACA@wsp.com.', {
                        position: "top-right",
                        autoClose: 8000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Slide
                    });  
                  loadCaptchaEnginge(6, "grey");
                  setTimeout(function(){
                    window.location.href = "/login";
                }, 8000); 
                  }else{
                    setErrorMessage(result.saveErrorMessage);
                  }               
                }else {
                  const errorText = await response.text();
                  const apiname = `${apiconfig.resetUserPassword}`;
                  navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
              }
            }
            catch (error) {
              console.log("Error: ", error);
              navigate("/error-page");
            }
          }

        }


  // handle Submit
  const CaptchaValidation = () => {
    const errors = {};
    let user_captcha_value = document.getElementById("captcha").value;
    if (user_captcha_value === "") {
      errors.captcha = "Captcha is required.";
    } else if (validateCaptcha(user_captcha_value) === true) {
      //loadCaptchaEnginge(6, "grey");
      //document.getElementById('captcha').value = "";
    } else {
      errors.captcha = "Captcha code is invalid.";
      document.getElementById("captcha").value = "";
      loadCaptchaEnginge(6, "grey");
    }
    return errors;
  };

  //form validation
  const validateForm = (email) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(email)) {
      errors.email = "Please enter a valid email address.";
    }
    return errors;
  };


  return (
    <>
    <div>
      <div className="be-wrapper be-offcanvas-menu be-fixed-sidebar be-color-header vh-100">
        <LoginNavBar />
        <div className="be-content">
          <div className="main-content container-fluid p-0">
            <div className="page-header">
              <div className="container-fluid d-flex justify-content-between align-items-baseline">
                <div>
                  <img src={require("../../../img/ACA-logo.png")} />
                </div>
                <div>
                  <h3 className="page-header-title">Reset Your Password</h3>
                </div>
                <div>
                  <div className="btn-group btn-block btn-space">
                    <button className="btn" type="button" style={{ background: '#333D47', color: '#FFFFFF'}} onClick={() => navigate("/login")}>Back To Login Page</button>                
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="splash-container">
                  <div className="card-body">
                    <form className="m-t">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          className="form-control form-control-sm"
                          id="email"
                          type="text"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <p style={{ color: "red" }}>{formErrors.email}</p>
                      </div>

                      <div className="form-group">
                        <label>Captcha</label>
                        <LoadCanvasTemplate reloadText="Reload Captcha" />
                      </div>
                      <div className="form-group">
                        <input
                          className="form-control form-control-sm"
                          id="captcha"
                          type="text"
                          placeholder="Enter Captcha"
                        />
                        <p style={{ color: "red" }}>{formErrors.captcha}</p>
                      </div>
                      {
                          errorMessage 
                              ? 
                              <p className="text-danger">{errorMessage}</p>
                              : 
                              <></>
                      }
                      <button
                        className="btn btn-lg btn-block btn-primary"
                        id="button-login"
                        type="button"
                        onClick={passwordReset}
                      >
                        Reset Password
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <ToastContainer />
    </>
  );
};

export default PasswordReset;
