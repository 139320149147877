import React from 'react'
import { htmlConverterReact } from 'html-converter-react'
import SubQuestionForVerificationSummary from './SubQuestionForVerificationSummary'

const VerificationSummary = ({ data, activeRightPane, setActiveRightPane, leftNavigationMenu, scrollToQuestionViaQuestionRef }) => {

    return(
        <React.Fragment>
            <div className="user-info-list card card-border card-contrast card-table" style={data.summaries.length !== 0 && activeRightPane === data.summaries[0].name ? {} : {display: 'none'}}>
                <div className="card-header card-header-contrast card-header-divider">
                    Verification Summary
                </div>
                <div className="card-body">
                    {
                        data.sections.map((section) => {
                            return <React.Fragment>
                                        <h4 className="px-3">{section.title}</h4>
                                        {
                                            section.subsections.map((subsection) => {
                                                return subsection.type === 'verifier' 
                                                    ?
                                                    <React.Fragment>
                                                        <div className="p-2 bg-teal-1">
                                                            <h5 className="m-0">{subsection.title}</h5>
                                                        </div>
                                                        <table className="table table-striped table-hover">
                                                            <tbody>
                                                                <tr>
                                                                    <th style={{ width: "45%" }}>Question</th>
                                                                    <th style={{ width: "2%" }}/>
                                                                    <th>Response</th>
                                                                </tr>
                                                                {
                                                                    subsection.content.questions.map((question) => {
                                                                        return  <React.Fragment>
                                                                                    {
                                                                                        question.hideQuestionInVerificationSummary
                                                                                            ?
                                                                                            <React.Fragment></React.Fragment>
                                                                                            :
                                                                                            question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown' || question.responseType === 'other' || question.responseType === 'matrix'
                                                                                                ?
                                                                                                <tr style={question.isVisible ? {} : {display: 'none'}} className={question.lastSuccessfullySavedResponse.length === 0 ? "task-uncomplete" : "task-complete"} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                                                    <td>{htmlConverterReact(question.question)}</td>
                                                                                                    <td></td>
                                                                                                    {
                                                                                                        question.lastSuccessfullySavedResponse.length === 0 
                                                                                                            ? 
                                                                                                            <td className="" style={{paddingLeft: 10}}>------</td>
                                                                                                            :
                                                                                                            question.responseType === 'text' || question.responseType === 'textarea' 
                                                                                                                ?
                                                                                                                <td className="" style={{paddingLeft: 10}}>{question.lastSuccessfullySavedResponse}</td>
                                                                                                                :
                                                                                                                question.responseType === 'dropdown' 
                                                                                                                    ? 
                                                                                                                    <td className="" style={{paddingLeft: 10}}>{question.options.find(option => option.value === question.lastSuccessfullySavedResponse).label}</td>
                                                                                                                    :
                                                                                                                    <React.Fragment></React.Fragment>
                                                                                                    }
                                                                                                </tr>
                                                                                                :
                                                                                                <tr style={question.isVisible ? {cursor: 'pointer'} : {display: 'none'}} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                                                    <td><i>{htmlConverterReact(question.question)}</i></td>
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                </tr> 
                                                                                    }
                                                                                    {
                                                                                        question.subquestions.map((subquestion) => {
                                                                                            return <SubQuestionForVerificationSummary question={subquestion} subsection={subsection} setActiveRightPane={setActiveRightPane} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} />
                                                                                        })
                                                                                    }
                                                                                </React.Fragment>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment></React.Fragment>
                                            })
                                        }
                                    </React.Fragment>
                        })
                    }
                </div>
            </div>     
            <div className="" style={data.summaries.length !== 0 && activeRightPane === data.summaries[0].name ? {display: 'flex', justifyContent: 'flex-end'} : {display: 'none'}}>
                <button className="btn btn-space btn-primary btn-xl" onClick={() => { if(leftNavigationMenu.indexOf(activeRightPane) !== 0){ setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1]); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) } }}>
                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Back
                </button>
                <button id="validation-btn" className="btn btn-space btn-success btn-xl" onClick={() => { if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1){ setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1]); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) } }}>Next&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
            </div>         
        </React.Fragment>
    )
}

export default VerificationSummary