import React, { useState, useEffect } from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import AirportList from './AirportList';
import CommonCustomModal from '../../Common/CommonCustomModal';
import AddAirport from './AddAirport';


const AirportGroupDetailsWithAirport = ({ airportgroupDetails, airportgroup, toggler, refetchs, setRefetchs}) => {

    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [airportGroupList, setAirportGroupList] = useState([]);
    const [addAirportModal, setAddAirportModal] = useState(false);

    const toggleForAddAirportModal = () => {
        setAddAirportModal(!addAirportModal);
    } 


    const getAirportList = async () => {
        try{
        const response = await fetch(`${config.apiUrl}${apiconfig.getAirportListAirportGroup}${airportgroup.id}`, {
          method: "GET",
          headers: {
  
              Authorization: `Bearer ${localStorage.getItem("token")}`
  
          }
        });
        if (response.ok) {
            const result = await response.json();
            setAirportGroupList(result);
        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getAirportListAirportGroup}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
      };

    useEffect(()=>{
        getAirportList();
    },[airportgroup.id,refetchs])



    return (
        <>
        <div>
            <form>
            <div className="row">
                <div className="form-row">
                    <div className="form-group col-md-3 mb-1">
                        <label htmlFor="airportgroup">Airport Group :</label>
                    </div>
                    <div className="form-group col-md-3 mb-1">
                        <label htmlFor="airportgroup">{airportgroupDetails.airportGroup}</label>
                    </div>
                    <div className="form-group col-md-3 mb-1">
                        <label htmlFor="createdBy">Created By User :</label>
                    </div>
                    <div className="form-group col-md-3 mb-1">
                        <label htmlFor="createdBy">{airportgroupDetails.createdUser}</label>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-3 mb-1">
                        <label htmlFor="createdDate">Created Date Time :</label>
                    </div>
                    <div className="form-group col-md-3 mb-1">
                        <label htmlFor="createdDate">{airportgroupDetails.createdDate}</label>
                    </div>
                </div>
                <div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-contrast card-table mb-0">
                                <div className="card-body card-body-contrast">
                                <div className="col d-flex align-items-center justify-content-end">
                                            <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={ () => { toggleForAddAirportModal(); }}>Add <i className="icon icon-left mdi mdi-plus"></i></button>
                                </div>
                                    <table className="table table-striped table-borderless">
                                        <tbody className="no-border-x">
                                            <tr>
                                                <th style={{width:'10%'}}>IATA Code</th>
                                                <th style={{width:'20%'}}>Airport Name (Official English)</th>
                                                <th style={{width:'20%'}}>Airport Name (Official Local)</th>
                                                <th style={{width:'10%'}}>Region</th>
                                                <th style={{width:'10%'}}>Country</th>
                                                <th style={{width:'10%'}}>City</th>
                                                <th style={{width:'5%'}}>Actions</th>
                                            </tr> 
                                            {
                                                !airportGroupList.length  
                                                ?
                                                <tr className="alt-row">
                                                    <td colSpan="7">&nbsp; Airport does not exist.</td>
                                                </tr>
                                                :
                                                airportGroupList.map((airport, index) => <AirportList key={index} airportgpID={airportgroup.id} airport={airport} refetchs={refetchs} setRefetchs={setRefetchs}/>)
                                            }   
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                {/* <button className="btn btn-success mt-auto" type="button" >Submit</button> */}
            </div>
        </div>

        
<CommonCustomModal modal={addAirportModal}  modalWidth='100%' title='' toggler={toggleForAddAirportModal} heading='Add Airport' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
<AddAirport toggler={toggleForAddAirportModal} airportgpID={airportgroup.id} refetchs={refetchs} setRefetchs={setRefetchs} />
</CommonCustomModal>
</>
    );
};

export default AirportGroupDetailsWithAirport;