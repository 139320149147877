import React, { useState, useEffect } from "react"
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { useNavigate} from "react-router-dom"
import UserStatus from "./UserStatus"
import DashboardAirportList from "./DashboardAirportList"
import ApplicationCard from "./ApplicationCard"
import DashboardApplicationLists from "./DashboardApplicationLists"
import AddUser from "../ManageAccount/AddUser"
import CommonCustomModal from "../../Common/CommonCustomModal"
import UpdateAirportUser from "./UpdateAirportUser"
import Loader from "../../Common/Loader"
import EditAirportDetails from "./EditAirportDetails"

const AirportUserGroup = () => {   

    /* States & Constants */
    const [totalAirportApplicationPendingWithAdmin, setTotalAirportApplicationPendingWithAdmin] = useState("")
    const [totalAirportApplicationPendingWithAirportUser, setTotalAirportApplicationPendingWithAirportUser] = useState("")
    const [totalAirportApplicationPendingWithVC, setTotalAirportApplicationPendingWithVC] = useState("")
    const navigate = useNavigate()
    const userID = localStorage.getItem("userID")
    const [refetch,setRefetch] = useState(false)
    const [dashboardAirportList, setDashboardAirportList] = useState([])
    const [selectedGroupDetails, setSelectedGroupDetails] = useState('')
    const [addUserModal, setAddUserModal] = useState(false)
    const [updateGroupAirportModal, setUpdateGroupAirportModal] = useState(false)
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const isPrimaryAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User' && userRoles[0].isPrimaryAirportUser === 'Yes'
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingParentData, setIsLoadingParentData] = useState(false)
    const [updateGroupAirportModal1, setUpdateGroupAirportModal1] = useState(false)
    const [processingAirportGroupApplicationList, setProcessingAirportGroupApplicationList] = useState(false)
    const [airportGroupApplicationListProcessed, setAirportGroupApplicationListProcessed] = useState(false)
    const [processingAirportDashboardData, setProcessingAirportDashboardData] = useState(false)
    const [airportDashboardDataProcessed, setAirportDashboardDataProcessed] = useState(false)

    /* Methods */
    const toggleForAddUserModal = () => {
      setAddUserModal(!addUserModal)
    }  
    const toggleForUpdateGroupAirportModal = () => {
        setUpdateGroupAirportModal(!updateGroupAirportModal)
    }  
    const toggleForUpdateGroupAirportModal1 = () => {
        setUpdateGroupAirportModal1(!updateGroupAirportModal1)
    } 
    const handleSearchQuery = ()=>{
      // setSearchQuery();
    }
    const getAirportGroupApplicationList = async () => {
        try {
            console.log('executing getAirportGroupApplicationList()...')
            setProcessingAirportGroupApplicationList(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportGroupApplicationList}${userID}`, {
                method: 'GET',
                headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getAirportGroupApplicationList()')
                const result = await response.json()
                let fetchedlist = []
                for (let i = 0; i < result.length; i++) {
                    fetchedlist.push(result[i])
                }
                setDashboardAirportList(fetchedlist)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAirportGroupApplicationList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setAirportGroupApplicationListProcessed(true)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }

    /* Effects */
    useEffect(() => {       
        getAirportGroupApplicationList()
    }, [refetch])
    useEffect(() => {
        const airportDashBoardData = async () => { 
            try {
                console.log('executing airportDashBoardData()...')
                setProcessingAirportDashboardData(true)
                const response = await  fetch(`${config.apiUrl}${apiconfig.accreditationAirportDashBoardData}?UserID=${userID}&UserType=${'Airport User'}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    console.log('fetched airportDashBoardData()')
                    var result = await response.json()
                    setTotalAirportApplicationPendingWithAdmin(result.totalAirportApplicationPendingWithAdmin)
                    setTotalAirportApplicationPendingWithAirportUser(result.totalAirportApplicationPendingWithAirportUser)
                    setTotalAirportApplicationPendingWithVC(result.totalAirportApplicationPendingWithVC)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.accreditationAirportDashBoardData}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
                setAirportDashboardDataProcessed(true)
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        airportDashBoardData()
    }, [])
    /* 
        the below effect basically controls the visibility of the loader that's been defined within the jsx below 
        there's a twist though, since the `isLoading` state is being utilized by the nested child components
        thus, the introduction of a new state (which would eventually control the same Loader) was a neccesity
        that being said, `isLoadingParentData` was introduced
        using the combination of `processing` and `processed` states for multiple api requests (two in this case)
        the correct behavior of Loader was achieved
    */
    useEffect(() => {
        if(processingAirportGroupApplicationList || processingAirportDashboardData){
            if(isLoadingParentData)
                setIsLoadingParentData(true)
            if(airportGroupApplicationListProcessed)
                setProcessingAirportGroupApplicationList(false)
            if(airportDashboardDataProcessed)
                setProcessingAirportDashboardData(false)
        }
        if(airportGroupApplicationListProcessed && airportDashboardDataProcessed){
            if(isLoadingParentData)
                setIsLoadingParentData(false)
            setAirportGroupApplicationListProcessed(false)
            setAirportDashboardDataProcessed(false)
        }
    }, [processingAirportGroupApplicationList, airportGroupApplicationListProcessed, processingAirportDashboardData, airportDashboardDataProcessed])

    /* Debugging */
    console.log('-> isLoading: ', isLoading)
    console.log('-> isLoadingParentData: ', isLoadingParentData)
    console.log('-> processingAirportGroupApplicationList: ', processingAirportGroupApplicationList)
    console.log('-> processingAirportDashboardData: ', processingAirportDashboardData)


    return (
        <React.Fragment>
            {
                isLoading || isLoadingParentData
                    ? 
                    <Loader /> 
                    : 
                    <React.Fragment></React.Fragment>
            }
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4"onClick={handleSearchQuery()}>
                        <ApplicationCard title={'Application Pending with Airport'} applicationPending={totalAirportApplicationPendingWithAirportUser} />
                    </div>
                    <div className="col-md-4"onClick={handleSearchQuery()}>
                        <ApplicationCard title={'Application pending with Verifier'} applicationPending={totalAirportApplicationPendingWithVC} />
                    </div>
                    <div className="col-md-4"onClick={handleSearchQuery()}>
                        <ApplicationCard title={'Application pending with Admin'} applicationPending={totalAirportApplicationPendingWithAdmin} />
                    </div>
                </div>
                {
                    dashboardAirportList.map((group, index) => (
                        <div className="row" key={index}>
                            <div className="col-lg-12">
                                <div className="accordion mb-4" id={`accordion-${index}`}>
                                    <div className="card" style={{border: '1px solid #d4d4d4'}}>
                                        <div className="card-header" id="headingOne">
                                            {
                                                isPrimaryAirportUser 
                                                    ?
                                                    <div className="tools dropdown" style={{position: 'absolute',right: '0px',top: '5px',zIndex: 99,}}>
                                                        {
                                                            group.airportGroup.substring(0, 2) === 'NA'
                                                                ?
                                                                <React.Fragment>
                                                                    <a className="dropdown-toggle mr-2" style={{cursor:'pointer'}} onClick={ () => { toggleForAddUserModal() }}>
                                                                        <span className="icon mdi mdi-accounts-add"></span>
                                                                    </a>
                                                                </React.Fragment>
                                                                : 
                                                                <React.Fragment>
                                                                    <a className="dropdown-toggle mr-2" style={{cursor:'pointer'}} onClick={ () => { toggleForAddUserModal() }}>
                                                                        <span className="icon mdi mdi-accounts-add"></span>
                                                                    </a>
                                                                </React.Fragment>
                                                        }
                                                        {
                                                            group.airportGroup.substring(0, 2) === 'NA'
                                                                ?
                                                                <React.Fragment>
                                                                    <a className="dropdown-toggle mr-2" style={{cursor:'pointer'}} onClick={ () => { setSelectedGroupDetails(group.airports[0]); toggleForUpdateGroupAirportModal1() }}>
                                                                        <span className="icon mdi mdi-edit" data-toggle="tooltip" data-original-title="Edit Details"></span>
                                                                    </a>
                                                                </React.Fragment>
                                                                : 
                                                                <React.Fragment>
                                                                    <a className="dropdown-toggle mr-2" style={{cursor:'pointer'}} onClick={ () => { setSelectedGroupDetails(group); toggleForUpdateGroupAirportModal() }}>
                                                                        <span className="icon mdi mdi-edit" data-toggle="tooltip" data-original-title="Edit Details"></span>
                                                                    </a>
                                                                </React.Fragment>
                                                        }
                                                    </div>
                                                    :
                                                    <React.Fragment></React.Fragment>
                                            }
                                            <button className="btn collapsed py-2" data-toggle="collapse" data-target={`#collapseOne-${index}`} aria-expanded="false" aria-controls={`collapseOne-${index}`} >
                                                <div className="row justify-content-between">
                                                    <div className="col-sm-5">
                                                        <div className="media">
                                                            <span className="avtar avtar-icon avtar-square "><img src={require('../../../img/airport-group.png')} alt="airport" className="img-fluid"/></span>
                                                            <div className="media-body ml-3 align-self-center">
                                                                <h5 className="mb-1 mt-0">{group.airportGroup.substring(0, 2)==='NA'?'Single':group.airportGroup}</h5>
                                                                <p className="mb-0">{group.airportGroup.substring(0, 2)==='NA'?'':'Group'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <h6 className="text-body text-uppercase f-12 mt-0">{group.airportGroup.substring(0, 2)==='NA'?'Users':'Group Users'}</h6>
                                                        {
                                                            group.users.map((user, userIndex) => (
                                                                <span key={userIndex} className={`position-relative avtar text-blue-2 bg-blue-1 avtar-xs mr-2`} data-toggle="tooltip" data-placement="top" title={user.lastName}>
                                                                    {user.firstName}
                                                                    <span className={`mdi mdi-circle text-success user-status`}></span>
                                                                </span>
                                                            ))
                                                        }
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        <div className="collapse" id={`collapseOne-${index}`} aria-labelledby="headingOne" data-parent={`#accordion-${index}`}>
                                            <div className="card-body p-0">
                                                <div className="table-responsive">
                                                    <table className="table table-borderless table-center mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Airport Name</th>
                                                                <th>Application History</th>
                                                                <th>IATA Code</th>
                                                                <th style={{width: '200px'}}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !group.airports.length  
                                                                ?
                                                                <tr className="alt-row">
                                                                    <td colSpan="2">&nbsp; Airport does not exist.</td>
                                                                </tr>
                                                                :
                                                                group.airports.map((airportList, index) => <DashboardAirportList key={index} airportList={airportList} />)
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                <div className="card card-table card-border">
                    <div className="card-header">
                        <div className="title">Application List</div>
                    </div>
                    <div className="card-body mr-2 ml-2">
                        <DashboardApplicationLists isLoading={isLoading} setIsLoading={setIsLoading} />
                    </div>
                </div>
            </div>
            
            <CommonCustomModal modal={addUserModal} title='' toggler={toggleForAddUserModal} heading='Add User'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddUser toggler={toggleForAddUserModal} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <CommonCustomModal modal={updateGroupAirportModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForUpdateGroupAirportModal} heading='Update Airport Details'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <UpdateAirportUser toggler={toggleForUpdateGroupAirportModal} groupDetails={selectedGroupDetails} refetch={refetch} setRefetch={setRefetch} />
            </CommonCustomModal>
            <CommonCustomModal modal={updateGroupAirportModal1}  title='' toggler={toggleForUpdateGroupAirportModal1} heading='Update Airport Details'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditAirportDetails toggler={toggleForUpdateGroupAirportModal1} airportDetails={selectedGroupDetails} refetch={refetch} setRefetch={setRefetch} />
            </CommonCustomModal>
       </React.Fragment>
    )
}

export default AirportUserGroup