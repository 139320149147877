import React from 'react';

const UserCategogy = (category, refetch, setRefetch) => {

        return (
            <>
                <tr className="alt-row">
                    <td>{category.category.name}</td>
                    <td>{category.category.description}</td>                   
                </tr>
            </>
        );
}

export default UserCategogy;