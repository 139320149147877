import React, { useState,} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, toast } from 'react-toastify';
import { Label } from 'reactstrap';

const RemoveVerifiersSponsor = ({ toggler, verifier,sponsor ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [comments, setcomments] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");


    {/*Validation*/}
    const validateForm = (comments) => {
        const errors = {};
        if(!comments){
            errors.comments = 'Please enter the notes.';
        }else if(comments.length > 1000){
            errors.comments = 'Maximum of 1000 characters allowed.';
        }
        return errors;
    }

    const removeSponsor = async () => {

        var primary = 0 ;
        if(sponsor.isPrimary === "True"){
            primary = 1;
        }
        const requestBody = {
            "userID" : userID,
            "isPrimary" : primary,
            "sponsorID" : sponsor.id,
            "veriferID": verifier,
            "notes" : comments.trim()
        };
        const validationErrors = validateForm(comments.trim());
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.removeSponsor}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if (response.saveErrorMessage === "Sponsor removed successfully.") {
                toast.success("Sponsor removed successfully.", {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Slide,
                });
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

    return (
        <div>
    <form>
                    <div className="form-row">
                    <label htmlFor="remove">{'Are you sure you want to remove sponsor '}{'?'}</label>
                    </div>
                    <div className="form-row">
                        <label htmlFor="input_comments">Notes</label>
                        <textarea type="text" placeholder='Please enter the notes...' className="form-control form-control-xs" id="input_comments" onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>No</button>
            <button className="btn btn-success mt-auto" type="button" onClick={removeSponsor}>Yes</button>
            </div>
            </div>
    );
};

export default RemoveVerifiersSponsor;