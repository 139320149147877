import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const DownGradeVerifier = ({ toggler, verifier ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [notes, setNotes] = useState("");
    const [reason, setReason] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [reasonCodeList, setReasonCodeList] = useState([]);
    const [reasonText, setReasonText] = useState("");

    {/*Validation*/}
    const validateForm = (reason, comments) => {
        const errors = {};
        if(!comments && (reason === "-1"  || reasonText==="Verifier request to Downgrade")){
            errors.comments = 'Please enter the notes.';
        }else if(comments.length > 1000 && (reason === "-1" || reasonText==="Verifier request to Downgrade")){
            errors.comments = 'Maximum of 1000 characters allowed.';
        }else if(comments.length < 10 && (reason === "-1" || reasonText==="Verifier request to Downgrade")){
          errors.comments = 'Please enter a minimum of 10 characters.';
        }
        if(reason === "0" || reason === ""){
            errors.reason = 'Please select a value.';
        }
        return errors;
    }

      const downgradeVerifier = async () => {
        let noteDescription = reason === "-1" ? notes.trim() : reasonText;
        if(reasonText==="Verifier request to Downgrade")
            noteDescription =reasonText+" - "+ notes.trim();
        const requestBody = {
          verifierID : verifier.verifierDetailID,
          comments :  noteDescription,
          userID : userID,
          actionType : 'Case 1',
          levelID : 0


      };
      const validationErrors = validateForm(reason, notes.trim());
      setFormErrors(validationErrors);
      if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.downgradeVerifier}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier has been downgraded successfully.')
            {
                toast.success(response.saveErrorMessage, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };
      useEffect(() => {
        function getReasonCodeList() {
            fetch(`${config.apiUrl}${apiconfig.getreasonCode}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                  const filteredData = verifier.verifierStatus === "Prospective" ? data : data.filter(item => item.name !== 'Failed Requirements' && item.name !== 'Approval Expired');
                  const dropdownOptions = filteredData.map(option => ({
                    value: option.id,
                    text: option.name,
                  }));
                  setReasonCodeList(dropdownOptions);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getReasonCodeList();
    }, []);


      const handleReason = (e)=>{
        const selectedOption = e.target.options[e.target.selectedIndex];
        const selectedText = selectedOption.text;
        setReason(e.target.value);
        setReasonText(selectedText)
      }
      

    return (
        <div>

                    <div>
                    <p className="col-form-label" style={{color:'red'}}><i>*Please note that downgrading the Verifier will mean they have to go back through the complete Upgrade process if they want to become a higher level again.</i></p>
                    {/* <h5 className="col-form-label"> Are you sure you want to Downgrade the Verifier?</h5> */}
                    </div>
                    <br></br>
                    <form>
                            <div className="form-row">
                                <label htmlFor="resit">Reason Code</label>
                                <select  className="form-control form-control-sm" value={reason}  onChange={handleReason}>
                                    {reasonCodeList.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                            </select>
                            <p style={{color:'red'}}>{formErrors.reason}</p>
                            </div>
                            {(reason === "-1" || reasonText==="Verifier request to Downgrade") && (
                            <div className="form-row">
                                <label htmlFor="input_comments">Notes</label>
                                <textarea type="text" placeholder='Please enter the notes...' className="form-control form-control-xs" id="input_comments" onChange={(e) => { setNotes(e.target.value) }} style={{ height: 120}}/>
                                <p style={{color:'red'}}>{formErrors.comments}</p>
                            </div>
                            )}
                          
                            
                    </form>
                    <div className="modal-footer">
                    <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                    <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                    <button className="btn btn-success mt-auto" type="button" onClick={downgradeVerifier}>Submit</button>
                    </div>
            </div>
    );
};

export default DownGradeVerifier;