import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import { TableHeaderCells } from './TableHeaderCells'
import CommonCustomModal from '../../Common/CommonCustomModal'
import AdvancedSearch from '../../Common/AdvancedSearch'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { TiArrowUnsorted } from 'react-icons/ti'
import { MdOutlineSearch, MdAddCircleOutline, MdClose, MdSearch, MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import AddVerifier from '../AddVerifier'
import AddSponsors from './AddSponsors'
import CommonModalWithImage from '../../Common/CommonModalWithImage'
import SponsorConfirmation from  './SponsorConfirmation'
import Sponsor from './Sponsor'
import InputText from '../../Common/InputText'
import InputDate from '../../Common/InputDate'
import InputDropdown from '../../Common/InputDropdown'
import SearchedTag from '../../Common/SearchedTag'
import { Slide, ToastContainer, toast } from 'react-toastify'
import IsAccessForVerifierCombination1 from '../../Common/IsAccessForVerifieCombination1'
import Loader from '../../Common/Loader'

const Sponsors = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [sponsors, setSponsors] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [addVerifierModal, setAddVerifierModal] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [sortColumn, setSortColumn] = useState('')
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const [selectedOption, setSelectedOption] = useState("")
    const [isNewColumnClicked, setIsNewColumnClicked] = useState(false)
    const [SponsorConfirmModal, setSponsorConfirmModal] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('SponsorName')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [addSponsorModal, setAddSponsorModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [searchFilter, setSearchFilter] = useState('Sponsor Name')
    const [searchType, setSearchType] = useState('text')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [countryList, setCountryList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [inactiveSponsors, setInActiveSponsors] = useState(true)
    const isReadOnlyAccess = IsAccessForVerifierCombination1()
    const [isLoading, setIsLoading] = useState(false)
    const [processingSponsors, setProcessingSponsors] = useState(false)
    const [sponsorsProcessed, setSponsorsProcessed] = useState(false)
    const [processingRegionList, setProcessingRegionList] = useState(false)
    const [regionListProcessed, setRegionListProcessed] = useState(false)
    const [processingCountryList, setProcessingCountryList] = useState(false)
    const [countryListProcessed, setCountryListProcessed] = useState(false)
    const searchOptions = [
        {
            value: 'Sponsor Name', text: 'Sponsor Name',
            type: 'text',
            query: '&SponsorName='
        },
        {
            value: 'Client ID', text: 'Client ID', 
            type: 'text',
            query: '&ClientID='
        },
        {
            value: 'Region', text: 'Region', 
            type: 'dropdown',
            query: '&Region=',
            dropdownOptions: regionList
        },
        {
            value: 'Country', text: 'Country', 
            type: 'dropdown',
            query: '&Country=',
            dropdownOptions: countryList
        },
        {
            value: 'Email', text: 'Email', 
            type: 'text',
            query: '&Email='
        },
        {
            value: 'Phone', text: 'Phone', 
            type: 'text',
            query: '&Phone='
        },   
        {
            value: 'Sponsor Status', text: 'Sponsor Status', 
            type: 'dropdown',
            query: '&SponsorStatus=',
            dropdownOptions: [
                {text: 'Active', value: 'Active'},
            {text: 'InActive', value: 'InActive'},]
        },
    ]

    /* Methods */
    const fetchData = async () => {
        let activeUser = 1
        if(inactiveSponsors=== true)
        {
            activeUser = 0
        }
        try {
            console.log('executing getSponsors()...')
            setProcessingSponsors(true)
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            const response = await fetch(`${config.apiUrl}${apiconfig.getSponsors}?ActiveUsers=${activeUser}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getSponsors()')
                const result = await response.json()
                let fetchedSponsors = []
                for (let i = 0; i < result.length; i++) {
                    fetchedSponsors.push(result[i])
                }
                setSponsors(fetchedSponsors)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getSponsors}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setSponsorsProcessed(true)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const getRegionList = async () => {
        try{
            console.log('executing getRegionList()...')
            setProcessingRegionList(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getRegionList()')
                var result = await response.json()
                result = result.slice(1)
                setRegionList(result)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getRegion}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setRegionListProcessed(true)
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const getCountryList = async () => {
        try{
            console.log('executing getCountryList()...')
            setProcessingCountryList(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryL}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getCountryList()')
                var result = await response.json()
                setCountryList(result)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getCountryL}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setCountryListProcessed(true)
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const addVerifier = async () => {      
        try {
            alert('submitting...')                               
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
        toggleForAddVerifierModal()
    }
    const addSponsor = async () => {      
        try {
            alert('submitting...')                               
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
        toggleForAddSponsorModal()
    }
    const toggleForAddVerifierModal = () => {
        setAddVerifierModal(!addVerifierModal)
    }  
    const toggleForAddSponsorModal = () => {
        setAddSponsorModal(!addSponsorModal)
    }  
    const toggleForSponsorConfirmationModal = () => {
        setSponsorConfirmModal(!SponsorConfirmModal)
    } 
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }
    const handleChangeForInActiveSponsors = () =>{        
        setInActiveSponsors(!inactiveSponsors)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria,inactiveSponsors])
    useEffect(() => {
        if(sponsors.length > 0){
            setPageCount(Math.ceil(sponsors[0].totalCount / itemsPerPage))
        }
    }, [sponsors])
    useEffect(() => {
        getRegionList()
    }, [refetch])
    useEffect(() => {
        getCountryList()
    }, [refetch])
    useEffect(() => {
        if(processingSponsors || processingRegionList || processingCountryList){
            if(!isLoading)
                setIsLoading(true)
        } 
        if(processingSponsors && processingRegionList && processingCountryList){
            if(sponsorsProcessed && regionListProcessed && countryListProcessed){
                setIsLoading(false)
                setProcessingSponsors(false)
                setProcessingRegionList(false)
                setProcessingCountryList(false)
                setSponsorsProcessed(false)
                setRegionListProcessed(false)
                setCountryListProcessed(false)
            }
        }
        if(processingSponsors && !processingRegionList && !processingCountryList){
            if(sponsorsProcessed){
                setIsLoading(false)
                setProcessingSponsors(false)
                setSponsorsProcessed(false)
            }
        }
        if(!processingSponsors && processingRegionList && !processingCountryList){
            if(regionListProcessed){
                setIsLoading(false)
                setProcessingRegionList(false)
                setRegionListProcessed(false)
            }
        }
        if(!processingSponsors && !processingRegionList && processingCountryList){
            if(countryListProcessed){
                setIsLoading(false)
                setProcessingCountryList(false)
                setCountryListProcessed(false)
            }
        }
        if(processingSponsors && processingRegionList && !processingCountryList){
            if(sponsorsProcessed && regionListProcessed){
                setIsLoading(false)
                setProcessingSponsors(false)
                setProcessingRegionList(false)
                setSponsorsProcessed(false)
                setRegionListProcessed(false)
            }
        }
        if(!processingSponsors && processingRegionList && processingCountryList){
            if(regionListProcessed && countryListProcessed){
                setIsLoading(false)
                setProcessingRegionList(false)
                setProcessingCountryList(false)
                setRegionListProcessed(false)
                setCountryListProcessed(false)
            }
        }
        if(processingSponsors && !processingRegionList && processingCountryList){
            if(sponsorsProcessed && countryListProcessed){
                setIsLoading(false)
                setProcessingSponsors(false)
                setProcessingCountryList(false)
                setSponsorsProcessed(false)
                setCountryListProcessed(false)
            }
        }
    }, [processingSponsors, processingRegionList, processingCountryList, sponsorsProcessed, regionListProcessed, countryListProcessed])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(sponsors.length !== 0){
            if(sponsors[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])


    return (
        <Layout>
            {
                isLoading
                  ?
                  <Loader />
                  :
                  <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-orange mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Sponsor List</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                {isReadOnlyAccess  === "FullAccess" ? 
                                    <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddSponsorModal(); }}>Sponsors <i className="icon icon-left mdi mdi-plus"></i></button>
                                    :
                                    <></>
                                }
                                    
                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                    <li className="breadcrumb-item active">Sponsor List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="container-header">
                        <h3 className="page-header-title">Search Sponsor</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    {/* <strong>Search</strong>
                                    <br /> */}
                                    Use the filter categories and search field below to find sponsors, expand the preview to see more information.
                                </p>
                                <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                                {
                                    sponsors.length > 0
                                        ?
                                        <div className="align-self-center font-weight-bold">{sponsors[0].totalCount} items found</div>
                                        :
                                        <div className="align-self-center font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    <div className="custom-control custom-checkbox custom-control-inline m-0">
                                        <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                        <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                                    </div>
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                        <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                            {sponsors.map(sponsor => <Sponsor key={sponsors.indexOf(sponsor)} sponsor={sponsor} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                        </CustomTable>
                        <div className="d-flex justify-content-end">
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </div>
                    </div>
                </div>
            </div>

                { /* MODAL FOR ADDING VERIFIER */}
                <CommonCustomModal modal={addVerifierModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAddVerifierModal} heading='Create New Verifier' triggerMethodOnSaveButtonClick={addVerifier} buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <AddVerifier toggler={toggleForAddVerifierModal} toggler1={toggleForSponsorConfirmationModal} refetchs={refetch} setRefetchs={setRefetch} />
                </CommonCustomModal>
                {/* MODAL FOR ADDING SPONSOR */}
                <CommonCustomModal modal={addSponsorModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAddSponsorModal} heading='Create New Sponsor' triggerMethodOnSaveButtonClick={addSponsor} buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <AddSponsors toggler={toggleForAddSponsorModal}  refetchs={refetch} setRefetchs={setRefetch} />

                </CommonCustomModal>

                 {/* MODAL FOR CONFIRMATION */}
                <CommonModalWithImage modal={SponsorConfirmModal}  title='' toggler={toggleForSponsorConfirmationModal} heading='' >
                    <SponsorConfirmation/>
                </CommonModalWithImage>
                <ToastContainer/>
        </Layout>
    )
}

export default Sponsors