import React from 'react'
import { Navigate } from 'react-router-dom'

const CustomAuthChecker = ({ allowedRoles, exemptedRoles, redirectPath, children }) => {

    const userRoles = JSON.parse(localStorage.getItem('roles'))
    const hasRequiredRole = allowedRoles.some((allowedRole) => userRoles.some((role) => role.name === allowedRole))
    const hasExemptedRole = exemptedRoles.some((exemptedRole) => userRoles.some((role) => role.name === exemptedRole))

    return(
        userRoles === null 
            ?
            <Navigate to={redirectPath} replace /> 
            :
            hasRequiredRole && !hasExemptedRole 
                ?
                children   
                :
                <Navigate to={redirectPath} replace />  
    )
}

export default CustomAuthChecker