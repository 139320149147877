import React, { useState, useEffect } from 'react'
import Layout from '../../Layout'
import Verifier from './Verifier'
import { TableHeaderCells } from './TableHeaderCells'
import CommonCustomModal from '../Common/CommonCustomModal'
import AdvancedSearch from '../Common/AdvancedSearch'
import Pagination from '../Common/Pagination'
import Selector from '../Common/Selector'
import CustomTable from '../Common/CustomTable'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { TiArrowUnsorted } from 'react-icons/ti'
import { MdOutlineSearch, MdAddCircleOutline, MdClose, MdSearch, MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import config from '../../api/config'
import apiconfig from '../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import AddVerifier from './AddVerifier'
import AddSponsors from './Sponsors/AddSponsors'
import CommonModalWithImage from '../Common/CommonModalWithImage'
import VerifierConfirmation from './VerifierConfirmation'
import { Slide, ToastContainer, toast } from 'react-toastify'
import AddVerifierAndSponsor from './AddVerifierAndSponsor'
import DownloadVerifier from './DownloadVerifier'
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { Button } from 'bootstrap'
import IsAccessForVerifierCombination1 from '../Common/IsAccessForVerifieCombination1'
import Loader from '../Common/Loader'

const Verifiers = () => {
    
    /* states & constants */
    const [searchName, setSearchName] = useState('')
    const [verifiers, setVerifiers] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [addVerifierModal, setAddVerifierModal] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('VerifierName')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [addSponsorModal, setAddSponsorModal] = useState(false)
    const [confirmModal, setConfirmModal] = useState(false)
    const [searchFilter, setSearchFilter] = useState('Verifier Name')
    const [searchType, setSearchType] = useState('text')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [vSList, setVSList] = useState([])
    const [countryList, setCountryList] = useState([])
    const [regionList, setRegionList] = useState([])
    const [removedVerifier, setRemovedVerifier] = useState(true)
    const [vcUserList, setVCUserList] = useState([])
    const [hasAnythingChangedInParentTable, setHasAnythingChangedInParentTable] = useState(false)
    const [searchText, setSearchText] = useState('')
    const [approvedVerifierList, setApprovedVerifierList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const isReadOnlyAccess = IsAccessForVerifierCombination1()
    const userID = localStorage.getItem("userID")
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const isAccessable = userRoles.some(role => role.name === "Administrator" || role.name === "Verifier Coordinator")
    const searchOptions = [
        {
            value: 'Verifier Name', 
            text: 'Verifier Name', 
            type: 'text',
            query: '&VerifierName='
        },
        {
            value: 'Sponsor', 
            text: 'Sponsor', 
            type: 'text',
            query: '&Sponsor='
        },
        {
            value: 'Sponsor Type', 
            text: 'Sponsor Type', 
            type: 'dropdown',
            query: '&SponsorType=',
            dropdownOptions: [
                {text: 'Not Set', value: 'Not Set'},
                {text: 'Independent', value: 'Independent'},
                {text: 'Non- Independent', value: 'Non- Independent'},
            ]
        },
        {
            value: 'Verifier Status', 
            text: 'Verifier Status', 
            type: 'dropdown',
            query: '&VerifierStatus=',
            dropdownOptions: vSList
        },
        {
            value: 'Accreditation Level', 
            text: 'Accreditation Level', 
            type: 'dropdown',
            query: '&AccreditationLevel=',
            dropdownOptions: [
                {text: 'NA', value: 'NA'},
                {text: 'Level 1-3 +', value: 'Level 1-3'},
                {text: 'Level 4-4 +', value: 'Level 4-4'},
            ]
        },
        {
            value: 'Assigned VC', 
            text: 'Assigned VC', 
            type: 'dropdown',
            query: '&AssignedVC=',
            dropdownOptions: vcUserList
        },
        {
            value: 'Date Approved', 
            text: 'Date Approved', 
            type: 'date',
            query: '&DateApproved='
        },   
        {
            value: 'Region', 
            text: 'Region', 
            type: 'dropdown',
            query: '&Region=',
            dropdownOptions: regionList
        },
        {
            value: 'Country', 
            text: 'Country', 
            type: 'dropdown',
            query: '&Country=',
            dropdownOptions: countryList
        },
        {
            value: 'Renewal Lapse', 
            text: 'Renewal Lapse', 
            type: 'dropdown',
            query: '&IsRenewalLapse=',
            dropdownOptions: [
                {text: 'Yes', value: 'Yes'},
                {text: 'No', value: 'No'},
            ]
        },
        {
            value: 'Suspension(s)?', 
            text: 'Suspension(s)?', 
            type: 'dropdown',
            query: '&IsSuspension=',
            dropdownOptions: [
                {text: 'Yes', value: 'Yes'},
                {text: 'No', value: 'No'},
            ]
        },
        {
            value: 'Eligible for Upgrade?', 
            text: 'Eligible for Upgrade?', 
            type: 'dropdown',
            query: '&IsUpgrade=',
            dropdownOptions: [
                {text: 'NA', value: 'NA'},
                {text: 'Yes', value: 'Yes'},
                {text: 'No', value: 'No'},
            ]
        },
    ]
    const currentDate = new Date()
    // Format the date as "YYYYMMDD-HHMMSS"
    const formattedDate =
        currentDate.getFullYear().toString().padStart(4, '0') +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') +
        '-' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0')
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileExtension = '.xlsx'
    const fileName = formattedDate + '-ApprovedVerifierList'
    const customHeaders = [
        'Verifier Name',
        'Level',
        'Company',
        'Country',
        'Email',
        'Phone Number',
        'Approved Date',
    ]

    /* methods */
    const fetchData = async () => {
        let removedVerifiers = 1
        if(removedVerifier)
            removedVerifiers = 0
        try {
            setIsLoading(true)
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            const response = await fetch(`${config.apiUrl}${apiconfig.getVerifiers}?RemoveUsers=${removedVerifiers}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                const result = await response.json()
                let fetchedVerifiers = []
                for (let i = 0; i < result.length; i++) {
                    fetchedVerifiers.push(result[i])
                }
                setVerifiers(fetchedVerifiers)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getVerifiers}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoading(false)
        }
        catch (error) {
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const getRegionList = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                var result = await response.json()
                result = result.slice(1)
                setRegionList(result)
            } 
            else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getRegion}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const getCountryList = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryL}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                var result = await response.json()
                setCountryList(result)
            } 
            else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getCountryL}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const addVerifier = async () => {      
        try {
            alert('submitting...')                               
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
        toggleForAddVerifierModal();     
    }
    const addSponsor = async () => {      
        try {
            alert('submitting...')                               
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
        toggleForAddSponsorModal()      
    }
    const toggleForAddVerifierModal = () => {
        setAddVerifierModal(!addVerifierModal)
    }  
    const toggleForAddSponsorModal = () => {
        setAddSponsorModal(!addSponsorModal)
    }  
    const toggleForConfirmationModal = () => {
        setConfirmModal(!confirmModal)
    } 
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }
    const handleChangeForRemovedVerifier = () =>{        
        setRemovedVerifier(!removedVerifier)
    }
    const exportToExcel = async () => {
        const formattedData = approvedVerifierList.map(item => ({
            'Verifier Name': item.verifierName,
            'Level': item.accredationLevel,
            'Company': item.company,
            'Country': item.country,
            'Email': item.email,
            'Phone Number': item.phoneNumber,
            'Approved Date':item.approvedDate,
        }))
        const ws = XLSX.utils.json_to_sheet(formattedData, { header: customHeaders })
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const data = new Blob([excelBuffer], { type: fileType })
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    /* effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria,removedVerifier])
    useEffect(() => {
        if(verifiers.length > 0){
            setPageCount(Math.ceil(verifiers[0].totalCount / itemsPerPage))
        }
    }, [verifiers])
    useEffect(() => {
        function getVerifierStatusList() {
            fetch(`${config.apiUrl}${apiconfig.getVerifierStatus}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then(res => res.json())
                .then((data) => {
                    setVSList(data)
                }).catch((err) => {
                    //console.log("Error: ", err);
                    navigate("/error-page")
                })
        }
        getVerifierStatusList()
    }, [refetch])
    useEffect(() => {
        const getVCList = async () => {
            try {
                const response =  await fetch(`${config.apiUrl}${apiconfig.getVCUsers}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    result = result.slice(1)
                    setVCUserList(result)
                } 
                else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getVCUsers}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getVCList()
    }, [refetch])
    useEffect(() => {
        getRegionList()
    }, [refetch])
    useEffect(() => {
        getCountryList()
    }, [refetch])
    // Download Approved Verifier list
    useEffect(() => {
        function getApprovedVerifierList() {
            fetch(`${config.apiUrl}${apiconfig.getApprovedVerifier}${userID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then(res => res.json())
                .then((data) => {
                    setApprovedVerifierList(data)
                }).catch((err) => {
                    console.log("Error: ", err)
                    //navigate("/error-page");
                })
        }
        getApprovedVerifierList()
    }, [])
    useEffect(() => {
        setHasAnythingChangedInParentTable(!hasAnythingChangedInParentTable)
    }, [searchCriteria, pageNumber, sortByColumnName, sortOrder, removedVerifier])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(verifiers.length !== 0){
            if(verifiers[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])

    /* Debugging */
    //console.log('pageCount: ', pageCount)
    //console.log('pageNumber: ', pageNumber)
    //console.log('itemsPerPage: ', itemsPerPage)

    return (
        <Layout>
            {
                isLoading
                    ?
                    <Loader />
                    :
                    <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-orange mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Verifier List</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end"> 
                                     {isReadOnlyAccess  === "FullAccess" ? 
                                     <>
                                    <button className="btn mr-1" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddVerifierModal(); }}>Verifiers <i className="icon icon-left mdi mdi-plus"></i></button>
                                    </>
                                    :
                                    isReadOnlyAccess  === "ReadOnlyAccess" ?
                                    <>
                                    <button className="btn mr-1" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddVerifierModal(); }} disabled={isReadOnlyAccess === "ReadOnlyAccess"}>Verifiers <i className="icon icon-left mdi mdi-plus"></i></button>
                                    </>
                                    :
                                    <></>
                                    }
                                    {isAccessable ? 
                                    <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={exportToExcel}>Download Approved Verifier List <i className="icon icon-left mdi mdi-download"></i></button>
                                    :
                                    <></>
                                    // <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={exportToExcel} disabled={isAccessable}>Download Approved Verifier List <i className="icon icon-left mdi mdi-download"></i></button>
                                    }

                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                    <li className="breadcrumb-item active">Verifier List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                   
                    <div className="container-header">
                            <h3 className="page-header-title">Search Verifiers</h3>
                            <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    {/* <strong>Search</strong>
                                    <br /> */}
                                    Use the filter categories and search field below to find verifiers, expand the preview to see more information.
                                </p>
                                <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} searchText={searchText} setSearchText={setSearchText} setPageNumber={setPageNumber} />
                                {
                                    verifiers.length > 0
                                        ?
                                        <div className="font-weight-bold">{verifiers[0].totalCount} items found</div>
                                        :
                                        <div className="font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    <div className="custom-control custom-checkbox custom-control-inline m-0">
                                        <input className="custom-control-input" type="checkbox" onChange={handleChangeForRemovedVerifier} checked={removedVerifier} id="ERemoved" />
                                        <label className="custom-control-label" htmlFor="ERemoved">Exclude Removed</label>
                                    </div>
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                            <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                                {verifiers.map((verifier, index) => <Verifier key={index} verifier={verifier} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} hasAnythingChangedInParentTable={hasAnythingChangedInParentTable} />)}
                            </CustomTable>
                            <div className="d-flex justify-content-end">
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                             </div>
                    </div>
                </div>
            </div>

                { /* MODAL FOR ADDING VERIFIER */}
                <CommonCustomModal modal={addVerifierModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAddVerifierModal} heading='Create New Verifier' triggerMethodOnSaveButtonClick={addVerifier} buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <AddVerifierAndSponsor toggler={toggleForAddVerifierModal} toggler1={toggleForConfirmationModal} refetchs={refetch} setRefetchs={setRefetch} />
                </CommonCustomModal>
                {/* MODAL FOR ADDING SPONSOR */}
                <CommonCustomModal modal={addSponsorModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAddSponsorModal} heading='Create New Sponsor' triggerMethodOnSaveButtonClick={addSponsor} buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <AddSponsors toggler={toggleForAddSponsorModal}  refetchs={refetch} setRefetchs={setRefetch} />
                </CommonCustomModal>
                 {/* MODAL FOR CONFIRMATION */}
                <CommonModalWithImage modal={confirmModal}  title='' toggler={toggleForConfirmationModal} heading='' >
                    <VerifierConfirmation />
                </CommonModalWithImage>
                {/* TOAST CONTAINER */}
                <ToastContainer />
        </Layout>
    )
}

export default Verifiers