import React, { useState, useEffect } from 'react';
import Layout from '../../../Layout';
import Card from '../../Common/Card';
import { AiOutlineUserAdd } from "react-icons/ai";
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate,useParams } from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import CommonCustomModal from '../../Common/CommonCustomModal';
import CustomTableWithoutSorting from '../../Common/CustomTableWithoutSorting';
import { TableHeaderCells } from './TableHeaderCells';
import AirportUser from './AirportUser';
import AddUser from './AddUser';
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination';
const AirportUsers = () => {
    const [airportUser, setAirportUser] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const navigate = useNavigate();
    const { refID, refType } = useParams()
    const isFullAccess = IsAccessForAccreditationCombination();
    const toggleForAddUserModal = () => {
      setAddUserModal(!addUserModal);
    } 
    
    useEffect(() => {
        fetchData();
    }, [refetch]);

    const fetchData = async () => {

        try {

            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportUserBasedOnAirport}?RefID=${refID}&RefType=${refType}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                let fetchedAirportUser = [];
                for (let i = 0; i < result.length; i++) {
                    fetchedAirportUser.push(result[i]);
                }
                setAirportUser(fetchedAirportUser);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAirportUserList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
         }
        catch (error) {
            console.log(error.message);
            navigate(`/error-page`);
        }
    };


    
    return (
        <Layout>
            <div className="main-content container-fluid p-0">
                <div className="page-header ph-blue mb-4">
                    <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">Airport / Airport Group - Manage Users</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">
                                {refType === 'Airport' ? 
                                <a href={`/AirportDetails/${refID}`}><button className="btn mr-2" type="button" style={{ background: '#184086', color: '#FFFFFF' }}><i className="icon icon-left mdi mdi-arrow-left"></i> Back</button></a>
                                :
                                <a href={`/AirportGroupDetail/${refID}`}><button className="btn mr-2" type="button" style={{ background: '#184086', color: '#FFFFFF' }}><i className="icon icon-left mdi mdi-arrow-left"></i> Back</button></a>
                                }
                                {isFullAccess === 'AccFullAccess' && (
                                <Card title='' hasButton={true} toggler={toggleForAddUserModal} icon={<AiOutlineUserAdd />} buttonText='Add User'/>
                                )}
                            </div>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb mb-0 mt-4">
                                <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                <li className="breadcrumb-item active">Airport / Airport Group - Manage Users</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="container-header">
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                        </div>
                        <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                            <nav>
                                {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                    <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveUsers} id="EArchived" />
                                    <label className="custom-control-label" htmlFor="EArchived">Exclude Archived</label>
                                </div> */}
                                {/* <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} /> */}
                            </nav>
                        </div>
                    </div>
                    <CustomTableWithoutSorting headerCells={TableHeaderCells}>
                    {
                    !airportUser.length  
                        ?
                        <tr className="alt-row">
                            <td colSpan="6">&nbsp; Airport User does not exist.</td>
                        </tr>
                        :
                        airportUser.map(user => <AirportUser key={user.userID} user={user} ReferenceID ={refID} ReferenceType = {refType} refetch={refetch} setRefetch={setRefetch} />)
                    }
                    </CustomTableWithoutSorting>
                </div>
            </div>
            <CommonCustomModal modal={addUserModal} title='' toggler={toggleForAddUserModal} heading='Add User'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddUser toggler={toggleForAddUserModal} airportList = {airportUser} ReferenceID ={refID} ReferenceType = {refType}  refetch={refetch} setRefetch={setRefetch}/>
            </CommonCustomModal>
            <ToastContainer />
        </Layout>
    );
};

export default AirportUsers;