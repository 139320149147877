import React, { useState, useEffect } from "react"
import { useNavigate, Link, useParams } from "react-router-dom"
import Layout from "../../../Layout"
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { Slide, ToastContainer, toast } from 'react-toastify'
import Loader from "../../Common/Loader"

const ViewExam = () => {
    
    /* States & Constants */
    const navigate = useNavigate()
    const [refetch, setRefetch] = useState(false)
    const [clientID, setClientID] = useState("")
    const [sponsorName, setSponsorName] = useState("")
    const [verifierName, setVerifierName] = useState("")
    const [verifierStatus, setVerifierStatus] = useState("")
    const [externalExamID, setExternalExamID] = useState("")
    const [examID, setExamID] = useState("")
    const [examType, setExamType] = useState("")
    const [examStatus, setExamStatus] = useState("")
    const [examFees, setExamFees] = useState("")
    const [attempt, setAttempt] = useState("")
    const [invoiceID, setInvoiceID] = useState("")
    const [invoiceStatus, setInvoiceStatus] = useState("")
    const [invoiceNumber, setInvoiceNumber] = useState("")
    const [createdDate, setCreatedDate] = useState("")
    const [modifiedDate, setModifiedDate] = useState("")
    const [sponsorID, setSponsorID] = useState("")
    const [verifierID, setVerifierID] = useState("")
    const { id } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const exam = { id: id }

    /* Effects */
    useEffect(() => {
        //get DetailsByID
        try {
            fetch(`${config.apiUrl}${apiconfig.getInvoiceDetailsInvoiceByID}${id}`,{
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                  },
            })
            .then((response) => response.json())
            .then((data) => {
                setClientID(data.clientID)
                setSponsorName(data.sponsorName)
                setVerifierName(data.verifierName)
                setVerifierStatus(data.verifierStatus)
                setExternalExamID(data.externalExamRef)
                setExamID(data.examID)
                setExamType(data.examType)
                setExamStatus(data.examStatus)
                setExamFees(data.examFee)
                setAttempt(data.resit)
                setInvoiceID(data.invoiceID)
                setInvoiceStatus(data.invoiceStatus)
                setInvoiceNumber(data.invoiceNo)
                setCreatedDate(data.createdDateTime)
                setModifiedDate(data.modifiedDateTime)
                setVerifierID(data.verifierID)
                setSponsorID(data.sponsorID)
                setIsLoading(false)
            })
        } 
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }, [id, refetch])


    return (
        <Layout>
            {
                isLoading
                  ?
                  <Loader />
                  :
                  <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-orange mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Invoice Details</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">

                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                    <li className="breadcrumb-item"><a href="/Invoices">Invoice List</a></li>
                                    <li className="breadcrumb-item active">Invoice Details</li>
                                </ol>
                            </nav>
                        </div>
                    </div>

                    <div className="container-header">
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-table">
                                    <div className="card-header">

                                    </div>
                                        <div className="row">
                                        <div className="form-row">
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">Invoice ID :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">{invoiceID}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Zip">Exam ID :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Zip"><a className="text-danger" href={`/viewexam/${examID}`}>{examID}</a></label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_SponsorName">Verifier Name :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_SponsorName"><a className="text-danger" href={`/viewverifier/${verifierID}`}>{verifierName}</a></label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">Invoice Number :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">{invoiceNumber}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_City">External Exam Reference :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_City">{externalExamID}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Client">Verifier Status :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Client">{verifierStatus}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                            <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">Invoice Status :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">{invoiceStatus}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_PhoneNumber">Exam Type :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_PhoneNumber">{examType}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Address1">Sponsor Name :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Address1"> <a className="text-danger" href={`/viewsponsor/${sponsorID}`}>{sponsorName}</a></label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">Created Date/Time :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">{createdDate}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Email">Exam Status :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Email">{examStatus}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Address2">Client ID :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Address2">{clientID}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">Last modified Date/Time :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierStatus">{modifiedDate}</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Region">Exam Fee :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_VerifierName">{examFees}</label>
                                                </div>

                                            </div>
                                            <div className="form-row">
                                            <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Country"></label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Country"></label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Country">Attempt :</label>
                                                </div>
                                                <div className="form-group col-md-2 mb-1">
                                                    <label htmlFor="input_Country">{attempt}</label>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ViewExam