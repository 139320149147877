import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SideMenuData } from './SideMenuData';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import config from '../api/config';
import apiconfig from '../api/apiconfig';


const SideMenu = ({ sideMenuToggler }) => {

    const signOut = useSignOut();
    const navigate = useNavigate();
    const onLogoutButtonClick = () => {
        signOut();
        //navigate('/login');
    };

    const [role, setRole] = useState('');
    const applicationID = 1;
    const userID = 1;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.getApplicationUserRole}?ApplicationID=${applicationID}&LoggedInUserID=${userID}`, {
                    method: 'GET',
                    headers: { 'Accept': 'application/json' }
                }).then((response) => {
                    return response.json();
                })
                for (let i = 0; i < response.length; i++) {
                    if (response[i].name === 'Administrator') {
                        setRole(response[i].name);
                    }
                }
            }
            catch (error) {
                console.log("Error: ", error);
            }
        };
        fetchData();
    }, []);

    return (
        <nav className='navbar navbar-expand fixed-top' style={{ backgroundColor: 'white', width: '16%', height: '100%', boxShadow: '0 0 0 10000px rgba(0,0,0,.50)', alignContent: 'start', alignItems: 'start', paddingTop: '80px', paddingLeft: '0px' }}>
            <ul className='nav-menu-items'>
                <div>
                    <h6 style={{ color: '#4f7fd1', fontStyle: 'normal', fontSize: '12px' }}>MENU</h6>
                </div>
                {SideMenuData.map((item, index) => {
                    return (
                        /* ROLE BASED ACCESS CONTROL (RBAC) OVER SIDE MENU ITEMS */
                        (item.title === 'Project Settings' || item.title === 'Roles' || item.title === 'Permissions') && role !== 'Administrator'
                            ?
                            <div key={index}></div>
                            :
                            <li key={index} className='nav-text' onClick={() => { sideMenuToggler(); }}>
                                {
                                    item.title === 'Logout'
                                        ?
                                        <Link onClick={() => { onLogoutButtonClick() }} to={item.path}>
                                            {item.icon}&nbsp;&nbsp;
                                            <span>{item.title}</span>
                                        </Link>
                                        :
                                        <Link to={item.path}>
                                            {item.icon}&nbsp;&nbsp;
                                            <span>{item.title}</span>
                                        </Link>
                                }
                            </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default SideMenu;