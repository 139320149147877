import React, { useState, useEffect } from 'react';
import Layout from '../../../Layout';
import { TableHeaderCells } from './TableHeaderCells';
import AdvancedSearch from '../../Common/AdvancedSearch';
import Pagination from '../../Common/Pagination';
import Selector from '../../Common/Selector';
import CustomTable from '../../Common/CustomTable';
import { TiArrowUnsorted } from 'react-icons/ti';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions';
import { useNavigate,useParams } from "react-router-dom";
import HistoricalApplicationList from './HistoricalApplicationList';

const HistoricalApplicationLists = () => {

    const userID = localStorage.getItem("userID");
    const [searchName, setSearchName] = useState('')
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('ReportingPeriod')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Reporting Period')
    const [searchType, setSearchType] = useState('text')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [historicalAppList, setHistoricalAppList] = useState([])
    const [searchText, setSearchText] = useState('');
    const { airportID } = useParams()
    const navigate = useNavigate()
    const AirportName = localStorage.getItem("AirportNameOnHistoricalList")
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const isAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User'
    const isSingleCheck = userRoles.length === 1 && userRoles[0].returnType === 'Single User'
    const isGroupCheck = userRoles.length === 1 && userRoles[0].returnType === 'Group User'
    const searchOptions = [
        {
            value: 'Reporting Period', 
            text: 'Reporting Period', 
            type: 'text',
            query: '&ReportingPeriod=',
        },
        {
            value: 'Application ID', 
            text: 'Application ID', 
            type: 'text',
            query: '&ApplicationID=',
        },
        {
            value: 'Application Type',
            text: 'Application Type', 
            type: 'text',
            query: '&ApplicationType=',
        },
    ]

    /* Methods */
    const fetchData = async () => {
        try {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportUserHistoricalApplicationList}?AirportID=${airportID}&LoggedInUserID=${userID}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            })
            if (response.ok) {
                const result = await response.json()
                let fetchedList = []
                for (let i = 0; i < result.length; i++) {
                fetchedList.push(result[i])
                }
                setHistoricalAppList(fetchedList)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAirportUserHistoricalApplicationList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => { 
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria])
    useEffect(() => {
        if(historicalAppList.length > 0){
            setPageCount(Math.ceil(historicalAppList[0].totalCount / itemsPerPage))
        }
    }, [historicalAppList])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(historicalAppList.length !== 0){
            if(historicalAppList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])
      



    return (
        <Layout>
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-orange mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">{AirportName}: Application List</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                    {/* <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddSponsorModal(); }}>Sponsors <i className="icon icon-left mdi mdi-plus"></i></button> */}
                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href = {isAirportUser  && isSingleCheck ? '/AirportGroupDashboard' : isAirportUser && isGroupCheck ? '/AirportGroupDashboard' : <></> }><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                    <li className="breadcrumb-item active">Application List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                   
                    <div className="container-header">
                        <h3 className="page-header-title">Search Application</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    {/* <strong>Search</strong>
                                    <br /> */}
                                    {/* Use the filter categories and search field below to find historical application list, expand the preview to see more information. */}
                                </p>
                                <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                                {
                                    historicalAppList.length > 0
                                        ?
                                        <div className="align-self-center font-weight-bold">{historicalAppList[0].totalCount} items found</div>
                                        :
                                        <div className="align-self-center font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                        <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                          {     !historicalAppList.length
                                ?
                                <tr className="alt-row">
                                    <td colSpan="6">&nbsp; Application does not exist.</td>
                                </tr>
                                :
                                historicalAppList.map((appList, index) => <HistoricalApplicationList key={index} appList={appList} searchName={searchName.toLowerCase()}/>)
                          }
                        </CustomTable>
                        <div className="d-flex justify-content-end">
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </div>
                        </div>
                    </div>
                </div>

        </Layout>
    );
};

export default HistoricalApplicationLists;