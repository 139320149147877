import React from 'react';
import '../../css/style-pagination.css';

const Selector = ({ hasPrefixLabel, hasSuffixLabel, hasIcon, prefixLabel, suffixLabel, icon, value, setter, options }) => {

    return (
        <div className='selector'>
            {
                hasPrefixLabel === true
                    ?
                    <span>
                        <label>Items per page &nbsp;</label>
                    </span>
                    :
                    <></>
            }
            <select className="" value={value} onChange={(e) => { setter(parseInt(e.target.value)) }}>
                {
                    options.map(option => {
                        return (
                            <option key={option.id}>{option.value}</option>
                        );
                    })
                }
            </select>
            
           
        </div>
    );

};

export default Selector;