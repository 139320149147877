import React from 'react';

export const TableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Expand/Collapse',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 2,
        isHidden: false,
        name: 'Verifier Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'VerifierName',
        type: 'text'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Sponsor',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '8%' },
        isSortable: true,
        sortByColumnName: 'Sponsor',
        type: 'text'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Sponsor Type',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '7%' },
        isSortable: true,
        sortByColumnName: 'SponsorType',
        type: 'text'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '8%' },
        isSortable: true,
        sortByColumnName: 'VerifierStatus',
        type: 'dropdown'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Accreditation Level',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '7%' },
        isSortable: true,
        sortByColumnName: 'AccreditationLevel',
        type: 'text'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Assigned VC',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'AssignedVC',
        type: 'text'
    },
    {
        id: 8,
        isHidden: false,
        name: 'Date Approved',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '7%' },
        isSortable: true,
        sortByColumnName: 'DateApproved',
        type: 'date'
    },
    
    {
        id: 9,
        isHidden: false,
        name: 'Region',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '7%' },
        isSortable: true,
        sortByColumnName: 'Region',
        type: 'dropdown'
    },
    {
        id: 10,
        isHidden: false,
        name: 'Country',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '7%' },
        isSortable: true,
        sortByColumnName: 'Country',
        type: 'dropdown'
    },
    {
        id: 11,
        isHidden: false,
        name: 'Renewal Lapse(s)?',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '8%' },
        isSortable: true,
        sortByColumnName: 'RenewalLapse',
        type: 'date'
    },
    {
        id: 11,
        isHidden: false,
        name: 'Suspension(s)?',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '7%' },
        isSortable: true,
        sortByColumnName: 'IsSuspended',
        type: 'date'
    },
    {
        id: 12,
        isHidden: false,
        name: 'Eligible for Upgrade?',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '8%' },
        isSortable: true,
        sortByColumnName: 'IsUpgrade',
        type: 'date'
    },
    {
        id: 13,
        isHidden: false,
        name: 'Last Updated',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer',width: '12%' },
        isSortable: true,
        sortByColumnName: 'LastUpdatedDate',
        type: 'dropdown'
    },
    
    {
        id: 14,
        isHidden: false,
        name: 'Actions',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { width: '3%' },
        isSortable: false,
        sortByColumnName: null
    }
];