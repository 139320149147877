import React from 'react';

export const InvoiceTableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Invoice ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'InvoiceID',
        type: 'text'
    },
    {
        id: 2,
        isHidden: false,
        name: 'Invoice Number',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'InvoiceNumber',
        type: 'text'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Invoice Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '13%' },
        isSortable: true,
        sortByColumnName: 'Invoicestatus',
        type: 'dropdown'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Exam ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '13%' },
        isSortable: true,
        sortByColumnName: 'ExamID',
        type: 'dropdown'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Sponsor Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '13%' },
        isSortable: true,
        sortByColumnName: 'SponsorName',
        type: 'dropdown'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Client ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '12%' },
        isSortable: true,
        sortByColumnName: 'ClientID',
        type: 'date'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Date Created',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '12%' },
        isSortable: true,
        sortByColumnName: 'CreatedDateTime',
        type: 'date'
    },
    {
        id: 8,
        isHidden: false,
        name: 'Actions',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { width: '3%' },
        isSortable: false,
        sortByColumnName: null
    }
];

export const InvoiceTableHeaderCellsWithOutActions = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Invoice ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'InvoiceID',
        type: 'text'
    },
    {
        id: 2,
        isHidden: false,
        name: 'Invoice Number',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'InvoiceNumber',
        type: 'text'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Invoice Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '13%' },
        isSortable: true,
        sortByColumnName: 'Invoicestatus',
        type: 'dropdown'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Exam ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '13%' },
        isSortable: true,
        sortByColumnName: 'ExamID',
        type: 'dropdown'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Sponsor Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '13%' },
        isSortable: true,
        sortByColumnName: 'SponsorName',
        type: 'dropdown'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Client ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '12%' },
        isSortable: true,
        sortByColumnName: 'ClientID',
        type: 'date'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Date Created',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '12%' },
        isSortable: true,
        sortByColumnName: 'CreatedDateTime',
        type: 'date'
    },
    // {
    //     id: 8,
    //     isHidden: false,
    //     name: 'Actions',
    //     colSpan: 1,
    //     hasChild: false,
    //     child: null,
    //     style: { width: '3%' },
    //     isSortable: false,
    //     sortByColumnName: null
    // }
];