import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom"
import Layout from '../../../Layout'
import CommonCustomModal from '../../Common/CommonCustomModal'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { Slide, ToastContainer, toast } from 'react-toastify'
import AirportsDetailsTab from './AirportsDetailsTab'
import EditAirportInformation from './EditAirportInformation'
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination'
import AddAirportDocument from './AddAirportDocument'
import Loader from '../../Common/Loader'


const AirportDetails = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const { id } = useParams()
    const [refetch, setRefetch] = useState(false)
    const [airportDetails, setAirportDetails] = useState([])
    const userID = localStorage.getItem("userID")
    const [editAirportGroupModal, setEditAirportGroupModal] = useState(false)
    const isFullAccess = IsAccessForAccreditationCombination()
    const [addDocumentModal, setAdddocumentModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingChildData, setIsLoadingChildData] = useState(true)
    const airport = { airportID: id }

    /* Methods */
    const toggleForEditAirportGroupModal = () => {
        setEditAirportGroupModal(!editAirportGroupModal)
    }  
    const toggleForAddDocumentModal = () => {
        setAdddocumentModal(!addDocumentModal)
    } 

    /* Effects */
    //get AirportDetailsByID
    useEffect(() => {
        setIsLoading(true)
        fetch(`${config.apiUrl}${apiconfig.getAirportDetailsByIdWithApplicationDetails}${id}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
        })
        .then((response) => response.json())
        .then((data) => {
            console.log(data)
            setAirportDetails(data)

        }).catch((err) => {
            navigate("/error-page")
        })
        setIsLoading(false)
    }, [id, refetch])


    return (
        <>
            <Layout>
                {
                    isLoading || isLoadingChildData
                        ?
                        <Loader />
                        :
                        <React.Fragment></React.Fragment>
                }
                <div className="be-content">
                    <div className="main-content container-fluid p-0">
                        <div className="page-header ph-orange mb-4">
                            <div className="container-header">
                                <div className="row">
                                    <div className="col">
                                        <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">Airport Details</h3>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end">

                                    </div>
                                </div>
                                <nav aria-label="breadcrumb" role="navigation">
                                    <ol className="breadcrumb mb-0 mt-4">
                                        <li className="breadcrumb-item"><a href={isFullAccess === 'AccAirportUserAccess' ? "/AirportGroupDashboard" : "/"}><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                            {isFullAccess === 'AccAirportUserAccess' ?
                                                <li className="breadcrumb-item"><a style={{ color: "grey", pointerEvents:'none'}}  href="/Airport">Airport List</a></li>
                                                :
                                                <li className="breadcrumb-item"><a href="/Airport">Airport List</a></li>
                                            }
                                        <li className="breadcrumb-item active">Airport Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="container-header">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card card-table">
                                        <div className="card-header">
                                            <div className="tools">
                                                {isFullAccess !== 'AccNoAccess' && (
                                                <>
                                                <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="mdi mdi-more"></i>
                                                    <span className="sr-only">Menu</span>
                                                </a>
                                                <div className="dropdown-menu">
                                                    {isFullAccess === 'AccFullAccess' ? 
                                                    <>
                                                    <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditAirportGroupModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} href={`/AirportUser/RefID/${id}/RefType/Airport`}><span className="icon mdi mdi-accounts-add"></span>Manage User</a>
                                                    <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                    </>
                                                    :
                                                    isFullAccess === 'AccReadOnlyAccess' || isFullAccess === 'AccAirportUserAccess'  ?
                                                    <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAddDocumentModal(); }}><span className="icon mdi mdi-file-plus"></span>Add Document</a>
                                                    :
                                                    <></>
                                                    }
                                                </div>
                                                </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div><label><strong>Airport Group Information</strong></label></div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="groupName">Group Name :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="groupName">{airportDetails.airportGroup}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="smallAirportGroup">Small Airport Group :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="smallAirportGroup">{airportDetails.isSmallAirportGroup}</label>
                                                </div>
                                            </div>
                                            <div><label><strong>Airport Information</strong></label></div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="iataCode">IATA Code :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="iataCode">{airportDetails.iataCode}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="region">Region :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="region">{airportDetails.region}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="country">Country :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="country">{airportDetails.country}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="city">City :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="city">{airportDetails.city}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="airportType">Airport type :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="airportType">{airportDetails.airportType}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="localName">Airport Name Local :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="localName">{airportDetails.airportName_Local}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="englishName">Airport Name English :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="englishName">{airportDetails.airportName_English}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="englishName">General Aviation :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="englishName">{airportDetails.isGeneralAviationAirport}</label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="englishName">Year joined Program :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="englishName">{airportDetails.yearJoinedProgram}</label>
                                                </div>
                                            </div>
                                            <div><label><strong>Accreditation Information</strong></label></div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="accLevel">Accreditation Level :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="accLevel">{airportDetails.accreditationLevel}</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="nextRenewalDate">Next Renewal Date :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="nextRenewalDate">{airportDetails.nextRenewalDate}</label>
                                                </div>
                                            </div>
                                            {/* <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="eligiblefor3Year">Eligible for 3 Year? :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label  htmlFor="eligiblefor3Year">{airportDetails.eligiblefor3Year}</label>
                                                </div>
                                            </div>    */}
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="eligiblefor3Year">3 - Yearly Status :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label  htmlFor="eligiblefor3Year">{airportDetails.year3Status}</label>
                                                </div>
                                           
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="eligiblefor3Year">3 - Yearly Year :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label  htmlFor="eligiblefor3Year">{airportDetails.year3Year}</label>
                                                </div>
                                            </div>                                     
                                        </div>
                                        
                                        <AirportsDetailsTab airportID={id} refetchs={refetch} setRefetchs={setRefetch} isLoadingChildData={isLoadingChildData} setIsLoadingChildData={setIsLoadingChildData} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Layout>
            <CommonCustomModal modal={editAirportGroupModal}  modalWidth='100%' title='' toggler={toggleForEditAirportGroupModal} heading='Edit Airport Information' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditAirportInformation toggler={toggleForEditAirportGroupModal} airport = {airport} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal> 
            <CommonCustomModal modal={addDocumentModal}  title='' toggler={toggleForAddDocumentModal} heading='Add Document for Airport'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AddAirportDocument toggler={toggleForAddDocumentModal} airport = {airport} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
        </>
    )
}

export default AirportDetails