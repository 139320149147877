import React, { useState, useEffect } from 'react'
import HoverableProgressBar from '../../Accreditation/Register/Progressbar';




const Verifications = ({aiportListForVerifier}) => {

console.log(aiportListForVerifier)

    return (
        <>
          <tr>
          <td>{aiportListForVerifier.airportApplicationID}</td>
          <td>{aiportListForVerifier.airportGroup}</td>
          <td>
            <div className="media align-items-center">
              <span className="avtar text-purple-2 bg-purple-1 avtar-xs mr-3"><span
                  className="mdi mdi-4x mdi-airplane"></span></span>
              <div className="media-body">
                <h6 className="mb-0 mt-0 font-weight-normal">{aiportListForVerifier.airport}</h6>
              </div>
            </div>
          </td>
          <td>{aiportListForVerifier.applicationType}</td>
          <td>{aiportListForVerifier.accreditationLevel}</td>
          {
                    aiportListForVerifier.applicationStatus === 'Approved' 
                    ? 
                    <td><span className="badge badge-success">{aiportListForVerifier.applicationStatus}</span></td>    
                        :
                    <td><span className="badge badge-warning">{aiportListForVerifier.applicationStatus}</span></td> 
          }
          <td>{aiportListForVerifier.createdDate}</td>
          {/* <td>{aiportListForVerifier.completionDate}</td> */}
          <td className="popover-item">
            <HoverableProgressBar OverAllPercentage={aiportListForVerifier.overallProgress} FootprintPercentage={aiportListForVerifier.footprintProgress} ManagementPercentage={aiportListForVerifier.managementProgress} id={aiportListForVerifier.airportApplicationID} />
          </td>
          <td className="text-right actions">
            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                <i className="mdi mdi-more"></i>
                <span className="sr-only">Menu</span>
            </a>
            <div className="dropdown-menu">
                {aiportListForVerifier.applicationStatus === 'In Verification' || aiportListForVerifier.applicationStatus ==='Airport resubmitted - In Verification' || aiportListForVerifier.applicationStatus === 'Admin comments - Verifier to address' ?
                <>
                <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${aiportListForVerifier.airportApplicationID}/AirportID/${aiportListForVerifier.airportID}`}><span className="icon mdi mdi-edit"></span>Edit</a>
                </>
                :
                <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${aiportListForVerifier.airportApplicationID}/AirportID/${aiportListForVerifier.airportID}`}><span className="icon mdi mdi-eye"></span>View</a>
               }
            </div>
        </td>
        </tr>
    
        </>
      );
}

export default Verifications