import React from 'react'
import { htmlConverterReact } from 'html-converter-react'

const SubQuestion = ({ index, data, subquestionIndexes, questionIndex, subSectionIndex, sectionIndex, state, setState, hasValueBasedToggling, parentValue, userType, questionRefs, calculateQuestionRefIndex, visibilityBasedOnType, setAlertDialogBoxProperties }) => {

    const updateState = (value) => {
        const updatedState = {...state}

        if(subquestionIndexes.length === 0){
            if(data.responseType === 'dropdown' && value === '0')
                updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].response = '' 
            else
                updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].response = value 
            
            /* if response type is `dropdown` then check if `hasAlert` is true. If true, then utilizing `alertProperties` set the `alertDialogBoxProperties` state via setAlertDialogBoxProperties */
            if(data.responseType === 'dropdown' && data.hasAlert && data.alertProperties !== null){
                for(const alertProperty of data.alertProperties){
                    if(alertProperty.alertValue === value)
                        setAlertDialogBoxProperties(prevState => ({ ...prevState, visibility: true, heading: alertProperty.alertHeading, alertText: alertProperty.alertText, buttonText: alertProperty.buttonText }))
                }
            }

            /* Logic for cross-question toggling at subquestion level: when current question is a toggle sender */
            if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].crossQuestionToggling !== null){
                if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].crossQuestionToggling.length !== 0){
                    for(const item of updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].crossQuestionToggling){
                        if((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].responseType === 'dropdown' && item.visibleOnValues.includes(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].response)) || ((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].responseType === 'text' || updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].responseType === 'textarea') && updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].response.length !== 0)){
                            for(const section of updatedState.sections){
                                if(section.id === item.sectionID){
                                    for(const subsection of section.subsections){
                                        if(subsection.id === item.subSectionID){
                                            for(const question of subsection.content.questions){
                                                if(question.id === item.questionID)
                                                    question.isVisible = true
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        else if((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].responseType === 'dropdown' && !item.visibleOnValues.includes(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].response)) || ((updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].responseType === 'text' || updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].responseType === 'textarea') && updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex].subquestions[index].response.length === 0)){
                            for(const section of updatedState.sections){
                                if(section.id === item.sectionID){
                                    for(const subsection of section.subsections){
                                        if(subsection.id === item.subSectionID){
                                            for(const question of subsection.content.questions){
                                                if(question.id === item.questionID)
                                                    question.isVisible = false
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            setState(updatedState)
        }
        else
            updateStateRecursively(value)
    }

    const updateStateRecursively = (value) => {
        setState((prevState) => {
            const updatedState = { ...prevState }
            let currentLevel = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[questionIndex]
            
            // Traverse the nested structure based on subquestionIndexes
            for (let i = 0; i < subquestionIndexes.length; i++)
                currentLevel = currentLevel.subquestions[subquestionIndexes[i]]
            
            currentLevel = currentLevel.subquestions[index]
            
            // Update the response for the current subquestion
            if(currentLevel.responseType === 'dropdown' && value === '0')
                currentLevel.response = ''
            else
                currentLevel.response = value

            /* if response type is `dropdown` then check if `hasAlert` is true. If true, then utilizing `alertProperties` set the `alertDialogBoxProperties` state via setAlertDialogBoxProperties */
            if(currentLevel.responseType === 'dropdown' && currentLevel.hasAlert && currentLevel.alertProperties !== null){
                for(const alertProperty of currentLevel.alertProperties){
                    if(alertProperty.alertValue === value)
                        setAlertDialogBoxProperties(prevState => ({ ...prevState, visibility: true, heading: alertProperty.alertHeading, alertText: alertProperty.alertText, buttonText: alertProperty.buttonText }))
                }
            }

            return updatedState
        })
    }

    return(
        <div className="form-group" style={data.isVisible ? (hasValueBasedToggling ? (parentValue === data.toggleValue ? {} : {display: 'none'}) : {}) : {display: 'none'}}>
            {
                data.helpText.length === 0 
                    ? 
                    <label htmlFor="sub-question">
                        <i>{htmlConverterReact(data.question)}</i>
                    </label>
                    :
                    <label htmlFor="sub-question" style={{ display: 'flex', alignItems: 'center', width: '98%', justifyContent: 'space-between', marginBottom: -10 }}>
                        <i>{htmlConverterReact(data.question)}</i>
                        <span className='tool' data-tip={htmlConverterReact(data.helpText)}>
                            <span className="icon mdi mdi-1x mdi-help-outline"></span>
                        </span>
                    </label>
            }
            <label htmlFor="sub-question">
                <i>{htmlConverterReact(data.description)}</i>
            </label>
            {
                data.responseType === 'dropdown' 
                    ?
                    <select id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} className="form-control form-control-sm col-xl-4" required={data.required} value={data.response} onChange={(e) => { updateState(e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? (data.readOnly ? true : false) : true}>
                        {
                            data.options.map((option, index) => {
                                return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                            })
                        }
                    </select>
                    :
                    data.responseType === 'textarea' 
                        ?
                        <textarea id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} className="form-control form-control-sm col-xl-6" placeholder={data.placeholder} value={data.response} onChange={(e) => { if(data.regex === null || (data.regex.length !== 0 && new RegExp(data.regex).test(e.target.value)) || data.regex.length === 0){ updateState(e.target.value) } }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} readOnly={data.readOnly} />
                        :
                        data.responseType === 'text' 
                            ?
                            <input id={`question#${data.id}`} ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]} className="form-control form-control-sm col-xl-5" type="text" placeholder={data.placeholder} value={data.response} onChange={(e) => { if(data.regex === null || (data.regex.length !== 0 && new RegExp(data.regex).test(e.target.value)) || data.regex.length === 0){ updateState(e.target.value) } }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} readOnly={data.readOnly} />
                            :
                            <React.Fragment></React.Fragment>
            }
            {
                data.required && data.response.length === 0 && state.sections[sectionIndex].subsections[subSectionIndex].isSubmitButtonClicked
                    ?
                    <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;{data.validationMessage}</span>
                    :
                    <React.Fragment></React.Fragment>
            }
            {
                data.subquestions.length === 0 
                    ? 
                    <React.Fragment></React.Fragment>
                    :
                    <br/>
            }
            {
                data.subquestions.map((subquestion, subquestion_index) => {
                    return <SubQuestion key={subquestion_index} index={subquestion_index} data={subquestion} subquestionIndexes={[...subquestionIndexes, index]} questionIndex={questionIndex} subSectionIndex={subSectionIndex} sectionIndex={sectionIndex} state={state} setState={setState} hasValueBasedToggling={data.hasValueBasedToggling} parentValue={data.response} userType={userType} questionRefs={questionRefs} calculateQuestionRefIndex={calculateQuestionRefIndex} visibilityBasedOnType={visibilityBasedOnType} setAlertDialogBoxProperties={setAlertDialogBoxProperties} />
                })
            }
        </div>
    )
}

export default SubQuestion