import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import AdvancedSearch from '../../Common/AdvancedSearch'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import {ToastContainer} from 'react-toastify'
import ReportingPeriod from './ReportingPeriod'
import { TableHeaderCells } from './TableHeaderCells'
import Loader from '../../Common/Loader'

const ReportingPeriods = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [reportingPeriodList, setReportingPeriodList] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [sortColumn, setSortColumn] = useState('')
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('CreatedDateTime')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Reporting Period Name')
    const [searchType, setSearchType] = useState('text')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const searchOptions = [
        {
            value: 'Reporting Period Name', 
            text: 'Reporting Period Name',
            type: 'text',
            query: '&ReportingPeriodName='
        },
        {
            value: 'Start Date-End Date', 
            text: 'Start Date-End Date', 
            type: 'text',
            query: '&Description='
        },
        {
            value: 'Created User',
             text: 'Created User', 
            type: 'text',
            query: '&CreatedUser=',
        },
        {
            value: 'Created Date Time', 
            text: 'Created Date Time', 
            type: 'date',
            query: '&CreatedDateTime=',
        },
    ]

    /* Methods */
    const fetchData = async () => {
        try {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            console.log('executing getReportingPeriodList()...')
            setIsLoading(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getReportingPeriodList}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getReportingPeriodList()')
                const result = await response.json()
                let fetchedReporting = []
                for (let i = 0; i < result.length; i++) {
                    fetchedReporting.push(result[i])
                }
                setReportingPeriodList(fetchedReporting)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getReportingPeriodList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoading(false)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria])
    useEffect(() => {
        if(reportingPeriodList.length > 0){
            setPageCount(Math.ceil(reportingPeriodList[0].totalCount / itemsPerPage))
        }
    }, [reportingPeriodList])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(reportingPeriodList.length !== 0){
            if(reportingPeriodList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])
    
    
    return (
        <Layout>
            {
                isLoading
                    ?
                    <Loader />
                    :
                    <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-lightgreen mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Reporting Period</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                    {/* <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForAddSponsorModal(); }}>Sponsors <i className="icon icon-left mdi mdi-plus"></i></button> */}
                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href="/"><span className="icon mdi mdi-home"><span className='sr-only'>Home</span></span></a></li>
                                    <li className="breadcrumb-item active">Reporting Period</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="container-header">
                        <h3 className="page-header-title">Search Reporting Period</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    {/* <strong>Search</strong>
                                    <br /> */}
                                    Use the filter categories and search field below to find reporting period, expand the preview to see more information.
                                </p>
                                <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                                {
                                    reportingPeriodList.length > 0
                                        ?
                                        <div className="align-self-center font-weight-bold">{reportingPeriodList[0].totalCount} items found</div>
                                        :
                                        <div className="align-self-center font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                        <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                        <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                                    </div> */}
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                        <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                            {reportingPeriodList.map(reportingperiod => <ReportingPeriod key={reportingPeriodList.indexOf(reportingperiod)} reportingperiod={reportingperiod} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                        </CustomTable>
                        <div className="d-flex justify-content-end">
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </Layout>
    )
}

export default ReportingPeriods