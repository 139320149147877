import React, { useState, useEffect } from 'react';
import Layout from '../../../Layout';
import Card from '../../Common/Card';
import { AiOutlineUserAdd } from "react-icons/ai";
import AddUser from './AddUser';
import CommonCustomModal from '../../Common/CommonCustomModal';
import AirportUser from './AirportUser';
import { TableHeaderCells } from './TableHeaderCells';
import AdvancedSearch from '../../Common/AdvancedSearch';
import Pagination from '../../Common/Pagination';
import Selector from '../../Common/Selector';
import CustomTable from '../../Common/CustomTable';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { TiArrowUnsorted } from 'react-icons/ti';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import CustomTableWithoutSorting from '../../Common/CustomTableWithoutSorting';


const AirportUsers = () => {
    const [searchName, setSearchName] = useState('');
    const [airportUser, setAirportUser] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [sortOrder, setSortOrder] = useState('ASC');
    const [sortByColumnName, setSortByColumnName] = useState('VerifierName');
    const [columnClickCounter, setColumnClickCounter] = useState(1);
    const [searchFilter, setSearchFilter] = useState('Verifier Name');
    const [searchType, setSearchType] = useState('text');
    const [searchCriteria, setSearchCriteria] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [addUserModal, setAddUserModal] = useState(false);
    const navigate = useNavigate();
    const userID = localStorage.getItem("userID");

    const toggleForAddUserModal = () => {
      setAddUserModal(!addUserModal);
    } 

    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const isPrimaryAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User' && userRoles[0].isPrimaryAirportUser === 'Yes';
    
    useEffect(() => {
        fetchData();
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria]);

    const fetchData = async () => {
        let loggedUser = 0;
        if(isPrimaryAirportUser === true){
            loggedUser = 1
        }

        try {

            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportUserList}?UserID=${userID}&IsLoggedPrimery=${loggedUser}&RequestFrom=${'List'}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                let fetchedAirportUser = [];
                for (let i = 0; i < result.length; i++) {
                    fetchedAirportUser.push(result[i]);
                }
                setAirportUser(fetchedAirportUser);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAirportUserList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
         }
        catch (error) {
            console.log(error.message);
            navigate(`/error-page`);
        }
    };

  const rolesListFromSession = JSON.parse(localStorage.getItem("roles"));
  const isSingleCheck = rolesListFromSession.length === 1 && rolesListFromSession[0].returnType === 'Single User';
  const isGroupCheck = rolesListFromSession.length === 1 && rolesListFromSession[0].returnType === 'Group User';

  const onPageClick = (e) => {
    setPageNumber(e.selected + 1);
};

const searchOptions = [
    {
        value: 'Verifier Name', 
        text: 'Verifier Name', 
        type: 'text',
        query: '&VerifierName='
    },
    {
        value: 'Sponsor', 
        text: 'Sponsor', 
        type: 'text',
        query: '&Sponsor='
    },
    {
        value: 'Sponsor Type', 
        text: 'Sponsor Type', 
        type: 'dropdown',
        query: '&SponsorType=',
        dropdownOptions: [
            {text: 'Not Set', value: 'Not Set'},
            {text: 'Independent', value: 'Independent'},
            {text: 'Non- Independent', value: 'Non- Independent'},
        ]
    },
    {
        value: 'Accreditation Level', 
        text: 'Accreditation Level', 
        type: 'dropdown',
        query: '&AccreditationLevel=',
        dropdownOptions: [
            {text: 'NA', value: 'NA'},
            {text: 'Level 1-3 +', value: 'Level 1-3'},
            {text: 'Level 4-4 +', value: 'Level 4-4'},
        ]
    },
    {
        value: 'Date Approved', 
        text: 'Date Approved', 
        type: 'date',
        query: '&DateApproved='
    },   
    {
        value: 'Renewal Lapse', 
        text: 'Renewal Lapse', 
        type: 'dropdown',
        query: '&IsRenewalLapse=',
        dropdownOptions: [
            {text: 'Yes', value: 'Yes'},
            {text: 'No', value: 'No'},
        ]
    },
    {
        value: 'Suspension?', 
        text: 'Suspension?', 
        type: 'dropdown',
        query: '&IsSuspension=',
        dropdownOptions: [
            {text: 'Yes', value: 'Yes'},
            {text: 'No', value: 'No'},
        ]
    },
    {
        value: 'Eligible for Upgrade?', 
        text: 'Eligible for Upgrade?', 
        type: 'dropdown',
        query: '&IsUpgrade=',
        dropdownOptions: [
            {text: 'Yes', value: 'Yes'},
            {text: 'No', value: 'No'},
        ]
    },
];
    
    return (
        <Layout>
            <div className="main-content container-fluid p-0">
                <div className="page-header ph-blue mb-4">
                    <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">Airport User List</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">
                                {/* <Card title='' hasButton={true} toggler={toggleForAddUserModal} icon={<AiOutlineUserAdd />} buttonText='Add User'/> */}
                            </div>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb mb-0 mt-4">
                                { isGroupCheck ? 
                                    <li className="breadcrumb-item"><a href="/AirportGroupDashboard"><span className="icon mdi mdi-home"></span></a></li>
                                    :
                                    isSingleCheck ?
                                    <li className="breadcrumb-item"><a href="/AirportGroupDashboard"><span className="icon mdi mdi-home"></span></a></li>
                                    :
                                    <></>
                                }
                                <li className="breadcrumb-item active">Airport User List</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="container-header">
                        {/* <h3 className="page-header-title">Search Users</h3> */}
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            {/* <p className="mb-2 font-weight-normal">
                            
                                   Use the filter categories and search field below to find users, expand the preview to see more information.
                                </p>
                            <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                            {
                                verifiers.length > 0
                                    ?
                                    <div className="align-self-center font-weight-bold">{verifiers[0].totalCount} items found</div>
                                    :
                                    <div className="align-self-center font-weight-bold">No items found</div>
                            } */}
                        </div>
                        <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                            <nav>
                                {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                    <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveUsers} id="EArchived" />
                                    <label className="custom-control-label" htmlFor="EArchived">Exclude Archived</label>
                                </div> */}
                                {/* <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} /> */}
                            </nav>
                        </div>
                    </div>
                    <CustomTableWithoutSorting headerCells={TableHeaderCells}>
                    {
                    !airportUser.length  
                        ?
                        <tr className="alt-row">
                            <td colSpan="5">&nbsp; Airport User does not exist.</td>
                        </tr>
                        :
                        airportUser.map(user => <AirportUser key={user.userID} user={user} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)
                    }
                    </CustomTableWithoutSorting>
                    {/* <div className="d-flex justify-content-end">
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </div> */}
                </div>
            </div>
            <CommonCustomModal modal={addUserModal} title='' toggler={toggleForAddUserModal} heading='Add User'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AddUser toggler={toggleForAddUserModal}  refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <ToastContainer />
        </Layout>
    );
};

export default AirportUsers;