import React, { useState, useEffect } from "react"
import Layout from "../../../Layout"
import CommonModal from "../../Common/CommonModal"
import SearchBar from "../../Common/SearchBar"
import Card from "../../Common/Card"
import Pagination from "../../Common/Pagination"
import Selector from "../../Common/Selector"
import Table from "../../Common/Table"
import { Form, FormGroup, Input, Label } from "reactstrap"
import { AiOutlineUserAdd } from "react-icons/ai"
import { TbArrowsSort } from "react-icons/tb"
import { TiArrowUnsorted } from "react-icons/ti"
import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import Role from "./Role"
import Loader from '../../Common/Loader'

const Roles = () => {
  
    /* States & Constants */
    const [searchName, setSearchName] = useState("")
    const [roles, setRoles] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [addRoleModal, setAddRoleModal] = useState(false)
    const [role, setRole] = useState("")
    const [currentItems, setCurrentItems] = useState([])
    const [pageNumber, setPageNumber] = useState(1)
    const [pageCount, setPageCount] = useState(0)
    const [itemOffset, setItemOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [sortOrder, setSortOrder] = useState(true)
    const [sortColumn, setSortColumn] = useState("")
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const tableHeaderCells = [
      {
        id: 1,
        isHidden: true,
        name: "ID",
        colSpan: 1,
        style: {},
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null,
      },
      {
        id: 2,
        isHidden: false,
        name: "Role Name",
        colSpan: 1,
        style: { cursor: "pointer" },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: "name",
      },
      // {
      //   id: 3,
      //   isHidden: false,
      //   name: "Hierarchy",
      //   colSpan: 1,
      //   style: { cursor: "pointer" },
      //   hasIcon: true,
      //   icon: <TbArrowsSort />,
      //   isSortable: true,
      //   sortByColumnName: "hierarchy",
      // },
    ]
    const numberOfEntriesPerPageOptions = [
      { id: 1, value: 5 },
      { id: 2, value: 10 },
      { id: 3, value: 20 },
      { id: 4, value: 50 },
      { id: 5, value: 100 },
    ]

    /* Methods */
    const SortArray = (array, property, direction) => {
        direction = direction || 1
        array.sort(function compare(a, b) {
            let comparison = 0
            const valueA = typeof a[property] === "string" ? a[property].toLowerCase() : a[property]
            const valueB = typeof b[property] === "string" ? b[property].toLowerCase() : b[property]
            if (valueA > valueB)
                comparison = 1 * direction
            else if (valueA < valueB)
                comparison = -1 * direction
            return comparison
        })
        return array
    }
    const addRole = async () => {
        const requestBody = {
            roleName: role,
        }
        const validationErrors = validateForm(role)
        setFormErrors(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.createRole}`,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    body: JSON.stringify(requestBody),
                }).then((response) => {
                    return response
                })
                setRefetch(!refetch)
            } 
            catch (error) {
                console.log("Error: ", error)
            }
            toggleForAddRoleModal()
        }
    }
    const toggleForAddRoleModal = () => {
        setAddRoleModal(!addRoleModal)
        formErrors.role=''
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
        const newOffset = (e.selected * itemsPerPage) % roles.length
        setItemOffset(newOffset)
    }
    const validateForm = (role) => {
        //Add form validation
        const errors = {}
        if (!role) {
            errors.role = "Role Name is required!"
        }
        return errors
    }

    /* Effects */
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const response = await fetch(`${config.apiUrl}${apiconfig.getRoleList}`,{
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }).then((response) => {
                  return response.json()
                })
                let fetchedUsers = []
                for (let i = 0; i < response.length; i++) {
                  fetchedUsers.push(response[i])
                }
                setRoles(fetchedUsers)
                setIsLoading(false)
            } 
            catch (error) {
                console.log("Error: ", error)
            }
        }
        fetchData()
    }, [refetch])
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage
        let searchedUsers = []
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].name.toLowerCase().includes(searchName.toLowerCase().trim())){
                searchedUsers.push(roles[i])
            }
        }
        if (sortColumn !== "") {
            let sortedUsers = []
            if (sortOrder) {
                sortedUsers = SortArray(searchedUsers, sortColumn, -1)
            } else if (!sortOrder) {
                sortedUsers = SortArray(searchedUsers, sortColumn)
            }
            searchedUsers = sortedUsers
        }
        setCurrentItems(searchedUsers.slice(itemOffset, endOffset))
        setPageCount(Math.ceil(searchedUsers.length / itemsPerPage))
    }, [itemOffset, itemsPerPage, roles, searchName, isSortTriggered])


    return (
        <Layout>
            {
                isLoading
                    ?
                    <Loader />
                    :
                    <React.Fragment></React.Fragment>  
            }
            <div className="page-header blue mb-4">
                <div className="container-header">
                    <div className="row">
                        <div className="col">
                            <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center">
                            <h3 className="page-header-title">Roles</h3>
                        </div>
                        <div className="col d-flex align-items-center justify-content-end">
                            
                        </div>
                    </div>
                    <nav aria-label="breadcrumb" role="navigation">
                        <ol className="breadcrumb mb-0 mt-4">
                            <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                            <li className="breadcrumb-item active">Roles</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container-header">
                <h3 className="page-header-title">Search Roles</h3>
                <Card
                    //title="Roles"
                    //hasButton={true}
                    toggler={toggleForAddRoleModal}
                    icon={<AiOutlineUserAdd />}
                    buttonText="Add Role"
                >
                <div className="row">
                    <div className="col-md-6 col-lg-7">
                        <SearchBar
                            searchState={searchName}
                            searchStateSetter={setSearchName}
                        />
                    </div>
                    <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                        <nav>
                            <Selector
                                hasPrefixLabel={true}
                                hasSuffixLabel={true}
                                hasIcon={true}
                                prefixLabel="Show"
                                suffixLabel="Entries"
                                icon={<TiArrowUnsorted />}
                                value={itemsPerPage}
                                setter={setItemsPerPage}
                                options={numberOfEntriesPerPageOptions}
                            />
                        </nav>
                    </div>
                </div>
                <Table
                    headerCells={tableHeaderCells}
                    setSortColumn={setSortColumn}
                    setIsSortTriggered={setIsSortTriggered}
                    isSortTriggered={isSortTriggered}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                >
                    {currentItems.map((role, index) => (
                        <Role key={index} role={role} />
                    ))}
                </Table>
                <div className="d-flex justify-content-end">
                    <Pagination
                        handlePageClick={onPageClick}
                        pageCount={pageCount}
                        pageRange={3}
                        pageNumber={pageNumber}
                    />
                    </div>
                </Card>
            </div>
            {/* MODAL FOR ADDING USER */}
            <CommonModal
              modal={addRoleModal}
              title="Add Role"
              toggler={toggleForAddRoleModal}
              heading="ADD Role"
              triggerMethodOnSaveButtonClick={addRole}
              buttonText="Save"
            >
              <Form>
                <FormGroup>
                  <Label className="col-form-label">{"Role Name:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    id="role"
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                  />
                  <p style={{ color: "red" }}>{formErrors.role}</p>
                </FormGroup>
              </Form>
            </CommonModal>
        </Layout>
    )
}

export default Roles