import React from "react";
import { MdClose } from 'react-icons/md';

const SearchedTag = ({ searchedCriteria, removeSearchCriteria }) => {

    return (
        searchedCriteria.map((item, index) => {
            return (    
                item.searchValue === 'default'  ?
                <span></span> :  
                <span key={index} className="badge badge-pill badge-chips" style={{paddingRight: 10}}>            
                {item.searchKey}: {item.searchType === 'dropdownbyID' ? item.searchText : item.searchValue}
                &nbsp; <MdClose style={{cursor: 'pointer', marginBottom: 1}} onClick={() => { removeSearchCriteria(index); }} />
                </span>)
        })
    )
}

export default SearchedTag;