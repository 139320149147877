
const SortArray = (array, property, direction) => {
    direction = direction || 1;
    array.sort(function compare(a, b) {
        let comparison = 0;
        if (typeof (a[property]) === 'string' && typeof (b[property]) === 'string') {
            if (a[property].toLowerCase() > b[property].toLowerCase()) {
                comparison = 1 * direction;
            } else if (a[property].toLowerCase() < b[property].toLowerCase()) {
                comparison = -1 * direction;
            } 
        } else {
            if (a[property] > b[property]) {
                comparison = 1 * direction;
            } else if (a[property] < b[property]) {
                comparison = -1 * direction;
            }
        }
        return comparison;
    });
    return array;
};

export default SortArray;