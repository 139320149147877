import React, { useState} from 'react';
import { Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';
import CommonCustomModal from '../../Common/CommonCustomModal';
import ApproveAirport from './ApproveAirport';
import RejectAirport from './RejectAirport';
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination';



const AccreditationAirportforAirportType = ({ accAirport, searchName, refetch, setRefetch }) => {

    const navigate = useNavigate();
    const isFullAccess = IsAccessForAccreditationCombination();



        return (
            <>
                <tr>
                    {isFullAccess  !== 'AccNoAccess' ?
                    <td><a className="text-info" href={`/AirportDetails/${accAirport.airportID}`}>{accAirport.airport}</a></td>
                    :
                    <td>{accAirport.airport}</td>
                    }
                    <td>{accAirport.iataCode}</td>
                    {isFullAccess !== 'AccNoAccess' ?
                    <td><a className="text-info" href={`/AirportGroupDetail/${accAirport.airportGroupID}`}>{accAirport.airportGroup}</a></td>
                    :
                    <td>{accAirport.airportGroup}</td>
                    }
                    <td>{accAirport.region}</td>    
                    <td>{accAirport.country}</td>
                    {
                    accAirport.status === 'Draft' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: 'rgb(251, 188, 5)',color:'black' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'Verifier comments - Airport to address' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#AB47BC' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'Admin comments - Airport to address' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#F06292', color:'#000' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'In Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#FDD835', color:'#000' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'Airport resubmitted - In Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#64B5F6', color:'#000' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'Admin comments - Verifier to address' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#8BC34A', color:'#000' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'In Admin Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#303F9F' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'Airport resubmitted - In Admin Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#DD2C00' }}>{accAirport.status}</span></td> 
                    :
                    accAirport.status === 'Verifier resubmitted - In Admin Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#B2102F' }}>{accAirport.status}</span></td> 
                    :
                    <td><span className="badge" style={{ backgroundColor: '#198754' }}>{accAirport.status}</span></td> 
                    }
                    <td>{accAirport.accreditationLevel}</td>
                    <td>{accAirport.nextRenewalDate}</td>
                    <td>{accAirport.eligiblefor3Year}</td>
                    <td>{accAirport.leftProgramme}</td>
                </tr>
            </>
        );
};

export default AccreditationAirportforAirportType;