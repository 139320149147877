import React from "react";


const SponsorConfirmation = () => {


  return  (
  
  <div>
  <h5 className="form-heading text-center"> Thanks for creating a new sponsor your application is now being processed</h5>
  </div>
  
  
  );
};

export default SponsorConfirmation;
