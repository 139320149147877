import { useState } from "react";
import CommonModalWithImage from "../../Common/CommonModalWithImage";
import CommonCustomModal from "../../Common/CommonCustomModal";
import { Label } from 'reactstrap';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Navigate, useNavigate } from "react-router-dom";
import EditDocument from "./EditDocument";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";

const DocumentList = ({ verifier, document, refetch, setRefetch,isExternalVerifierUser }) => {

    const [editdocumentModal, setDocumentCommentModal] = useState(false);
    const [deletedocumentModal, setDeleteDocumentModal] = useState(false);
    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    
  const toggleForEditDocumentModal = () => {
    setDocumentCommentModal(!editdocumentModal);
}  
const toggleForDeleteDocumentModal = () => {
    setDeleteDocumentModal(!deletedocumentModal);
}
const downloadFile = (url, filename) => {
    window.open(url);
    // fetch(url)
    // .then(response => response.blob())
    // .then(blob => {
    //   const downloadLink = document.createElement('a');
    //   downloadLink.href = window.URL.createObjectURL(blob);
    //   downloadLink.download = filename;
    //   downloadLink.style.display = 'none';
    //   document.body.appendChild(downloadLink);
    //   downloadLink.click();
    //   document.body.removeChild(downloadLink);
    // })
    // .catch(error => {
    //   console.error('Error downloading file:', error);
    // });
}
const deleteDocument = async () => {

    const requestBody = {
        "iD": document.id,
        "userID": userID,
    };
    try {
        const response = await fetch(`${config.apiUrl}${apiconfig.deleteDocument}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody)
        }).then((response) => {
            return response.json();
        })

        if(response.saveErrorMessage === 'Document deleted successfully.')
        {
            toast.success('Document deleted successfully.', {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide
            }); 
        setRefetch(!refetch);      
        }
    }
    catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
    toggleForDeleteDocumentModal();
};

return (
    <>
        <tr>
        <td>{document.documentType}</td>
        {isReadOnlyAccess1  === "FullAccess" || isExternalVerifierUser ?   
        <td><a style={{color:'blue',cursor:'pointer'}} onClick={() => { downloadFile(document.filePath,document.name); }}>{document.name}</a></td>
        :
        <td>{document.name}</td>
        }
        <td>{document.description}</td>
        <td>{document.createdBy}</td>
        <td>{document.createdDate}</td>
        {!isExternalVerifierUser ?
        isReadOnlyAccess1  === "FullAccess" ?
        <td className="text-right actions">
            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
            <i className="mdi mdi-more"></i>
            <span className="sr-only">Menu</span>
            </a>
            <div className="dropdown-menu">          
            <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditDocumentModal(); }} ><span className="icon mdi mdi-edit"></span>Edit</a>    
            <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForDeleteDocumentModal(); }} ><span className="icon mdi mdi-delete"></span>Delete</a>                          
            </div>
        </td>
        :
        <td></td> 
        :
        <></>
        }
        </tr>

<CommonCustomModal modal={editdocumentModal}  title='' toggler={toggleForEditDocumentModal} heading='Edit Document for Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
<EditDocument toggler={toggleForEditDocumentModal} verifier = {verifier} document= {document} refetchs={refetch} setRefetchs={setRefetch}/>
</CommonCustomModal>
<CommonModalWithImage modal={deletedocumentModal} title='' toggler={toggleForDeleteDocumentModal} heading='Delete Document' triggerMethodOnSaveButtonClick={deleteDocument}  buttonText='Yes'>
<Label className="col-form-label">{'Are you sure you want to delete document '}{'?'}</Label>
</CommonModalWithImage>

</>
);

};

export default DocumentList;
