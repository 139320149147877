import React, { useState, useEffect } from 'react';
import axios from '../../../api/AxiosConfig';
import apiconfig from '../../../api/apiconfig';
import Table from '../../Common/Table';
import { TbArrowsSort } from 'react-icons/tb';
import UserCategogy from './UserCategory';
import Card from '../../Common/Card';
import { AiOutlineUserAdd } from "react-icons/ai";
import SearchBar from '../../Common/SearchBar';
import Selector from '../../Common/Selector';
import { TiArrowUnsorted } from 'react-icons/ti';
import Pagination from '../../Common/Pagination';
import Layout from '../../../Layout';
import CommonModal from '../../Common/CommonModal';
import { Form, FormGroup, Input, Label } from 'reactstrap';

const UsersCategory = () => {    

    {/* STATES */ }
    const [searchName, setSearchName] = useState('');
    const [userCategory, setUserCategory] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [addCategoryModal, setAddCategoryModal] = useState(false);
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState(true);
    const [sortColumn, setSortColumn] = useState('');
    const [isSortTriggered, setIsSortTriggered] = useState(false);

    const tableHeaderCells = [
        {
            id: 1,
            isHidden: true,
            name: 'ID',
            colSpan: 1,
            style: {},
            hasIcon: false,
            icon: null,
            isSortable: false,
            sortByColumnName: null
        },
        {
            id: 2,
            isHidden: false,
            name: 'Category',
            colSpan: 1,
            style: { cursor: 'pointer' },
            hasIcon: true,
            icon: <TbArrowsSort />,
            isSortable: true,
            sortByColumnName: 'name'
        },
        {
            id: 3,
            isHidden: false,
            name: 'Description',
            colSpan: 1,
            style: { cursor: 'pointer' },
            hasIcon: true,
            icon: <TbArrowsSort />,
            isSortable: true,
            sortByColumnName: 'description'
        }
    ];

    const numberOfEntriesPerPageOptions = [{ id: 1, value: 5 }, { id: 2, value: 10 }, { id: 3, value: 20 }, { id: 4, value: 50 }, { id: 5, value: 100 }];

    const fetchData = async () => {
        try{
            await axios.get(`${apiconfig.getUserCategory}`,{
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            })
                       .then((response) => {

                setUserCategory(response.data);
            })
        } 
        catch(error) {
            console.error(error)
        }
    }

    const createUserCategory = async () =>{
        try {
            await axios.post(`${apiconfig.createUserCategory}`,
                {
                    Name: category, 
                    Description: description 
                },
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                })
                .then((response) => {

                    if(response.status === 200){
                        fetchData();
                    }
                })            
        } 
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {         
        fetchData();
    }, [])
    
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        let searchedCategories = [];
        for (let i = 0; i < userCategory.length; i++) {
            if (userCategory[i].name.toLowerCase().includes(searchName.toLowerCase().trim())) {
                searchedCategories.push(userCategory[i]);
            }
        }
        if (sortColumn !== '') {
            let sortedCategories = [];
            if (sortOrder) {
                sortedCategories = SortArray(searchedCategories, sortColumn, -1);
            }
            else if (!sortOrder) {
                sortedCategories = SortArray(searchedCategories, sortColumn);
            }
            searchedCategories = sortedCategories;
        }
        // console.log(searchedCategories);
        setCurrentItems(searchedCategories.slice(itemOffset, endOffset));
        // console.log(currentItems)
        setPageCount(Math.ceil(searchedCategories.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, userCategory, searchName, isSortTriggered]);

    {/* METHODS */ }
    const SortArray = (array, property, direction) => {
        direction = direction || 1;
        array.sort(function compare(a, b) {
            let comparison = 0;
            if (a[property].toLowerCase() > b[property].toLowerCase()) {
                comparison = 1 * direction;
            } else if (a[property].toLowerCase() < b[property].toLowerCase()) {
                comparison = -1 * direction;
            }
            return comparison;
        });
        return array;
    }

    
    const addUserCategory = async () => {
        try {
            if(category != '' ){                
                await createUserCategory();
            }            
        }
        catch (error) {
            console.log("Error: ", error);
        }
        toggleForAddUserCategoryModal();
    };

    const toggleForAddUserCategoryModal = () => setAddCategoryModal(!addCategoryModal);
    const onPageClick = (e) => {
        const newOffset = (e.selected * itemsPerPage) % userCategory.length;
        setItemOffset(newOffset);
    };

    return (
        <Layout>
            <div className="row">
                <div className="col-md-12">
                <Card title='User Categories' hasButton={true} toggler={toggleForAddUserCategoryModal} icon={<AiOutlineUserAdd />} buttonText='Add User Category'>
                        <SearchBar searchState={searchName} searchStateSetter={setSearchName} />
                        <Table headerCells={tableHeaderCells} setSortColumn={setSortColumn} setIsSortTriggered={setIsSortTriggered} isSortTriggered={isSortTriggered} setSortOrder={setSortOrder} sortOrder={sortOrder}>
                            {currentItems.map(c => <UserCategogy key={c.categoryID} category={c} refetch={refetch} setRefetch={setRefetch} /> )}
                            
                        </Table>
                        <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} />
                    </Card>
                </div>
            </div>
            { /* MODAL FOR ADDING USER CATEGORY */}
            <CommonModal modal={addCategoryModal} title='Add User Category' toggler={toggleForAddUserCategoryModal} heading='ADD USER CATEGORY' triggerMethodOnSaveButtonClick={addUserCategory} buttonText='Save'>
                <Form>
                    <FormGroup>
                        <Label className="col-form-label">{'Name:'}</Label>
                        <Input className="form-control" type="text" id="category" onChange={(e) => { setCategory(e.target.value) }} />
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">{'Description:'}</Label>
                        <Input className="form-control" type="text" id="description" onChange={(e) => { setDescription(e.target.value) }} />
                    </FormGroup>
                </Form>
            </CommonModal>
        </Layout>
    );
};

export default UsersCategory;