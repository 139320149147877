import React, { useState,useEffect } from 'react';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineCheckCircle, AiOutlineStop } from "react-icons/ai";
import CommonModal from '../../Common/CommonModal';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { Link } from 'react-router-dom'
import { Slide, ToastContainer, toast } from 'react-toastify';
import { MultiSelect } from "react-multi-select-component";
import { useNavigate } from "react-router-dom";
import IsAccessForVerifierCombination1 from '../../Common/IsAccessForVerifieCombination1';


const User = ({ user, searchName, refetch, setRefetch }) => {
     //console.log('User',user);
    //const firstName = user.name.substring(0, user.name.indexOf(" "));
    //const lastName = user.name.substring(user.name.indexOf(" ") + 1, user.name.length);

    const [editedEmail, setEditedEmail] = useState("");
    const [editedFirstName, setEditedFirstName] = useState("");
    const [editedLastName, setEditedLastName] = useState("");
    const [editSelectedOption, setEditSelectedOption] = useState("");
    const [catergoryList, setCategoryList] = useState([]);
    const [editCategoryName, setEditCategoryName] = useState("");

    const [editUserModal, setEditUserModal] = useState(false);
    const [archiveUserModal, setArchiveUserModal] = useState(false);
    const [activateUserModal, setActivateUserModal] = useState(false);
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [duplicateuser, setDuplicateuser] = useState('');
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [manageEditRolesModal, setManageEditRolesModal] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPasswords, setNewPassword] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    let UpdatedOption='';

    const toggleForEditUserModal = () => {
        setDuplicateuser('');
        setEditUserModal(!editUserModal);
        setFormErrors({});
        //setEditedEmail(user.email);
        //setEditedFirstName(firstName);
        //setEditedLastName(lastName);
    }
    const toggleForArchiveUserModal = () => setArchiveUserModal(!archiveUserModal);
    const toggleForActivateUserModal = () => setActivateUserModal(!activateUserModal);
    const toggleForDeleteUserModal = () => setDeleteUserModal(!deleteUserModal);
    const toggleForResetPasswordModal = () => {
        setNewPassword('');
        setConfirmPassword('');
          setFormErrors({});
          //setShowPasswordMismatchError(false);
          setResetPasswordModal(!resetPasswordModal);
          
      }

      const toggleForManageEditRolesModal = (userCatergory) => {
        // if(userCatergory){
        //     setUpdatedOption(userCatergory)
        // }
        UpdatedOption=userCatergory;
        getRoleList(UpdatedOption);
        fetchData();
       
        setManageEditRolesModal(!manageEditRolesModal);
      }; 

    const editUser = async (e) => {

        e.preventDefault();
        const requestBody = {
            "email": editedEmail,
            "firstName": editedFirstName,
            "lastName": editedLastName,
            "userID": user.id,
            "categoryID": editSelectedOption,
            "categoryName": editCategoryName,
            "loggedInUserID": userID
        };
        const validationErrors = validateForm(editedEmail,editedFirstName,editedLastName,editSelectedOption);

        setFormErrors(validationErrors);

        if(Object.keys(validationErrors).length === 0){
            try {
                    const response = await fetch(`${config.apiUrl}${apiconfig.updateUser}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody)
                    }).then((response) => {
                        return response;
                    })
                    if(response.status==200)
                    {
                        toast.success('User has been updated successfully.', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide
                        });
                        setRefetch(!refetch);
                        toggleForEditUserModal();
                    }
                    else
                    {
                        setDuplicateuser('This user already exists.')
                    }
            }
            catch (error) {
                console.log("Error: ", error);
                setDuplicateuser('This user already exists.')
            }

        }
    };
    const archiveUser = async () => {
        const requestBody = {
            "userID": user.id,
            "createdUserID": userID
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.archiveUser}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response;
            })
            if(response.status===200)
            {
                toast.success('User has been archived successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                });
                setRefetch(!refetch);
                toggleForArchiveUserModal();
            }
            // setRefetch(!refetch);
        }
        catch (error) {
            console.log("Error: ", error);
        }

    };
    const activateUser = async () => {
        const requestBody = {
            "userID": user.id,
            "createdUserID": userID
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.activateUser}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response;
            })
            if(response.status===200)
            {
                toast.success('User has been activated successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                });
                setRefetch(!refetch);
                toggleForActivateUserModal();
            }
            // setRefetch(!refetch);
        }
        catch (error) {
            console.log("Error: ", error);
        }

    };
    const deleteUser = async () => {
        const requestBody = {
            "selectedUserIDs": [
                user.id
            ],
            "loggedInUserID": userID
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.deleteUser}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response;
            })
            setRefetch(!refetch);
        }
        catch (error) {
            console.log("Error: ", error);
        }
        toggleForDeleteUserModal();
    };

    //categoryList
    function getCategoryList() {
        fetch(`${config.apiUrl}${apiconfig.getUserCategory}`, {
            method: 'GET',
            headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
        }).then(res => res.json())
            .then((data) => {
                setCategoryList(data);
            }).catch((err) => {

            });
    }

    // useEffect(() => {
    //     getCategoryList();
    // }, []);

    const handleDropD = (event) => {

        const name = event.target.options[event.target.selectedIndex].text;
        setEditSelectedOption(event.target.value);
        setEditCategoryName(name);
    }

    //get UserDetailsByID

    const isAlphaNumber = (event) => {
        const keyCode = event.keyCode || event.which;
          if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
            event.preventDefault();
          }
      };
       function getDetailByID(){
        fetch(`${config.apiUrl}${apiconfig.getUserDetailsByID}${user.id}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
            .then(res => res.json())
            .then((data) => {

                // const firstName = data.fi;
                // const lastName = data.name.substring(data.name.indexOf(" ") + 1, data.name.length);
                setEditedFirstName(data.firstName);
                setEditedLastName(data.lastName);
                setEditedEmail(data.email);
                setEditSelectedOption(data.category);
                setEditCategoryName(data.categoryName);
            }).catch((err) => {

            });
        }

    // useEffect(() => {
    //     getDetailByID()
    // }, [user.id])

const handleEdit = ()=>{
    getCategoryList();
    getDetailByID();
    toggleForEditUserModal();
}

  //All RoleList
//    useEffect(() => {
     let fetchedRoles = [];
    const getRoleList = async (UpdatedOption) => {
      try {
        const response = await fetch(
          `${config.apiUrl}${apiconfig.getRoleList}`,
          {
            method: "GET",
            headers: {

              Authorization: `Bearer ${localStorage.getItem("token")}`

          }
          }
        )
          .then((res) => res.json())

          .then((data) => {
            for (let i = 0; i < data.length; i++) {
              fetchedRoles.push(data[i]);
            }
            const option = fetchedRoles.map((role) => ({
                label: role.name,
                value: role.roleID,
                disabled : role.name === 'Airport User' || role.name === 'Verifier User',
              }));
            if(UpdatedOption === 'Airport'){

                  setOptions([option.find(option => option.label === 'Airport User')]);

            }else if (UpdatedOption === 'Verifier'){

                  setOptions([option.find(option => option.label === 'Verifier User')]);

            }else{

                  setOptions(option);

            }

          });
      } catch (error) {
        navigate("/error-page");
        console.log("Error: ", error);
      }
      return fetchedRoles;
    };
//     getRoleList();
//   }, [refetch,UpdatedOption]);

  // Roles List By User
//   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${config.apiUrl}${apiconfig.getRoleListByUser}${user.id}`,
          {
            method: "GET",
            headers: {

              Authorization: `Bearer ${localStorage.getItem("token")}`

          }
          }
        ).then((response) => {
          return response.json();
        });
        let fetchedUsers = [];
        for (let i = 0; i < response.length; i++) {
          fetchedUsers.push(response[i]);
        }
        setRoles(fetchedUsers);
        const option = fetchedUsers.map((role) => ({
          label: role.name,
          value: role.roleID,
        }));
        setSelectedOptions(option);
      } catch (error) {
        navigate("/error-page");
        console.log("Error: ", error);
      }
    };
//     fetchData();
//   }, [refetch]);

  // handle  Selected Role
  const handleSelectChange = (selected) => {
    setSelectedOptions(selected);
  };

const resetPassword = async () => {

    const validationErrors = validateResetForm(newPasswords, confirmPassword);
    setFormErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
        if (newPasswords === confirmPassword) {
            //setShowPasswordMismatchError(false);
            const requestBody = {
                "userID": user.id,
                "createdUserID": userID,
                "newPassword": newPasswords
            };
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.changeUserPassword}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                    body: JSON.stringify(requestBody)
                });
                if(response.status === 200){                        
                  toast.success('Password has been successfully reset.', {
                      position: "top-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      transition: Slide
                  });    
                  toggleForResetPasswordModal();
                  setRefetch(!refetch);                    
              }
              // else{
              //     return false;
              // }
                // .then((response) => {
                //     return response;
                // })
            }
            catch (error) {
                console.log("Error: ", error);
            }
        } 
        // else {
        //     setShowPasswordMismatchError(true);
        // }
    }

};

const setRolesInlocalStorage = async (userID) => {
    try {
        const response = await fetch(`${config.apiUrl}${apiconfig.getRoleListByUser}${userID}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        }).then((response) => response.json());

        //localStorage.setItem("roles", JSON.stringify(response));
        localStorage.setItem("roles", JSON.stringify(response));

    } catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
    }
};

const updateUserRoles = async () => {
    let rolesIDs = [];
    rolesIDs = selectedOptions.map((option) => option.value);

    const requestBody = {
      rolesIDs: rolesIDs,
      userID: user.id,
      createdUserID:userID,
      isSuccessful: true,
      errorMessage: "",
    };
    try {
        debugger;
        const myArray = rolesIDs;
        var checkExists=0;
        for(var i=0;i<myArray.length;i++)
        {
                //Check for Verifier
                // if(myArray[i]=="3" && myArray.length>1)
                //     checkExists=1;
                //Check for Airport/Verifier User
                if((myArray[i]=="9" || myArray[i]=="4") && myArray.length>1)
                    checkExists=1;
                //Check for ACI
                else if(myArray[i]=="10" && myArray.length>1)
                    checkExists=1;
                //Check for ACI-E
                else if(myArray[i]=="11" && myArray.length>1)
                    checkExists=1;
                //Check for VC
                // else if(myArray[i]=="7" && myArray.length>1)
                //     checkExists=1;
                //Check for PD
                // else if(myArray[i]=="13" && myArray.length>1)
                //     checkExists=1;
                // //Check for ACRC
                // else if(myArray[i]=="14" && myArray.length>1)
                //     checkExists=1;
                //Check for PM
                // else if(myArray[i]=="5" && myArray.length>1)
                //     checkExists=1;
                //Check for Administrator
                if((myArray[i]=="1" || myArray[i]=="7" || myArray[i]=="8" || myArray[i]=="12"))
                {
                    for(var j=0;j<myArray.length;j++)
                    {
                        if(myArray[j]!="1"&& myArray[j]!="7" && myArray[j]!="8" && myArray[j]!="12")
                            checkExists=1;
                    }
                }
        }
        if(checkExists>0)
        {
            toast.error('The combination of user roles is not supported.', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide
              }); 
        }
        else
        {
      const response = await fetch(
        `${config.apiUrl}${apiconfig.editUserRoles}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(requestBody),
        }
      ).then((response) => {
        if(userID===user.id)
        {
            setRolesInlocalStorage(user.id);
        }
        toast.success('The role assignment has been completed.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide
        }); 
        // setTimeout(function(){
        //    window.location.reload();
        // }, 3000);    
        return response;
      });
      toggleForManageEditRolesModal();
      setRefetch(!refetch);
    }
    } catch (error) {
      console.log("Error: ", error);
      navigate("/error-page");
    }

  };
  const isAllSelected = selectedOptions.length === options.length;
  const overrideStrings = {
    selectAll: isAllSelected ? 'Deselect All' : 'Select All',
  };

    //Edit User form validation
    const validateForm = (email, firstName, lastName, categoryId) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!firstName){
            errors.firstName = 'First Name is required.';
        }
        else if(firstName.length >   256){
            errors.firstName = 'First Name cannot exceed 256 characters.';
        }
        if(!lastName){
            errors.lastName = 'Last Name is required.';
        }
        else if(lastName.length > 256){
            errors.lastName = 'Last Name cannot exceed 256 characters.'
        }
        if(!email){
            errors.email = 'Email is required.';
        }
        else if(!emailRegex.test(email)){
            errors.email = 'Email is invalid.';
        }
        if(!categoryId){
            errors.category = 'Category is required.';
        }
        return errors;
    }

        //form validation
        const validateResetForm = (newPassword, confirmPassword) => {

            const errors = {};
            //const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
            const passwordRegex = new RegExp("^(?=.*[a-z])(?!.*[ @'?+:])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20}$)"); 
    
            if (!newPassword) {
                errors.newPassword = 'New password is required!';
            }
            else if (!passwordRegex.test(newPassword)) {
                errors.newPassword = 'New Password must contain one number between 0-9, one lower case character, one upper case character, one special character and be between 8-20 characters long.'
                errors.specialChars = `For New Password special characters: # ~ ! $ % ^ & * () _ = , . / ; [] " <> { } \ | -are allowed and Spaces, @, ', ?, +, : are not allowed.`
                //errors.newPassword = 'New Password must contain one number between 0-9, one lower case character, one upper case character and be at least 8 characters long!'
            }
            else if(newPassword != confirmPassword){
              errors.confirmPassword = 'New password and Confirm New password do not match.';
          }
            return errors;
        }

    if (user.name.toLowerCase().includes(searchName.trim()) && user.status !== 'IsInactive') {
        return (
            <>
                <tr className="alt-row">
                    {isReadOnlyAccess1 === 'FullAccess' || isReadOnlyAccess1 === 'FullAccessForAMSAMOnly'  ?
                    <td className="user-avatar"><img src={require('../../../img/avatar5.png')} alt="Avatar 2" /><a style={{ color: "blue" }} href={`/UserDetails/${user.id}`}>{user.firstName}</a></td>
                    :
                    <td>{user.firstName}</td>
                    }
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td style={{ textAlign: 'center' }}>{user.category}</td>
                    <td style={{ textAlign: 'center' }}>
                    {
                        user.status === 'IsActive'
                            ? <span className="badge badge-success">Active</span>
                            : user.status === 'IsArchived'
                            ? <span className="badge badge-warning">Archived</span>
                            : <span className="badge badge-secondary">Locked</span>
                    }
                    </td>
                    <td>{user.createdDate}</td>
                    <td>{user.lastUpdated}</td>

                    {isReadOnlyAccess1 === 'FullAccess' || isReadOnlyAccess1 === 'FullAccessForAMSAMOnly'  ?
                    <td className="text-right actions">
                    <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                    <i className="mdi mdi-more"></i>
                    <span className="sr-only">Menu</span>
                    </a>
                    <div className="dropdown-menu">          
                    <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { handleEdit(); }} ><span className="icon mdi mdi-edit"></span>Edit</a>    
                    {
                    user.status === 'IsLocked' ?

                                (<a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForActivateUserModal(); }} ><span className="icon mdi mdi-plus"></span>Activate</a> )
                                    : user.status === 'IsActive'  ?
                                ( <>
                                    <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForArchiveUserModal(); }} ><span className="icon mdi mdi-delete"></span>Archive</a> 
                                    <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForManageEditRolesModal(user.category); }} ><span className="icon mdi mdi-accounts-add"></span>Manage Roles</a> 
                                    <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForResetPasswordModal(); }} ><span className="icon mdi mdi-time-restore-setting"></span>Reset Password</a> 
                                    </>)
                                    :
                                ( <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForActivateUserModal(); }} ><span className="icon mdi mdi-plus"></span>Unarchive</a> )
                    }                        
                    </div>
                    </td>
                    :
                    <td></td>
                    }
                </tr>

                { /* MODAL FOR EDITING USER */}
                <CommonModal modal={editUserModal} title='Edit User' toggler={toggleForEditUserModal} heading='EDIT USER' triggerMethodOnSaveButtonClick={editUser} buttonText='Update'>
                    <Form>
                        <FormGroup>
                            <Label className="col-form-label">{'Email:'}</Label>
                            <Input className="form-control" type="text" id="email" value={editedEmail} onChange={(e) => { setEditedEmail(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.email}</p>
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label">{'First Name:'}</Label>
                            <Input className="form-control" type="text" id="first-name" value={editedFirstName}  onChange={(e) => { setEditedFirstName(e.target.value) }}></Input>
                            <p style={{color:'red'}}>{formErrors.firstName}</p>
                        </FormGroup>
                        <FormGroup>
                            <Label className="col-form-label">{'Last Name:'}</Label>
                            <Input className="form-control" type="text" id="last-name" value={editedLastName}  onChange={(e) => { setEditedLastName(e.target.value) }}></Input>
                            <p style={{color:'red'}}>{formErrors.lastName}</p>
                        </FormGroup>
                        <FormGroup>
                            <label className="col-form-label">{'Category'}</label>
                            <select id="dropdown" className="form-control" value={editSelectedOption} onChange={handleDropD}>
                                <option value="">-- Select --</option>
                                {catergoryList.map((option) => (
                                    <option key={option.categoryID} value={option.categoryID}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                            <p style={{color:'red'}}>{formErrors.category}</p>
                        </FormGroup>
                        <p style={{color:'red'}}>{duplicateuser}</p>
                    </Form>
                </CommonModal>

                { /* MODAL TO ARCHIVE USER */}
                <CommonModal modal={archiveUserModal} title='Archive User' toggler={toggleForArchiveUserModal} heading='ARCHIVE USER' triggerMethodOnSaveButtonClick={archiveUser} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to archive '}{user.name}{'?'}</Label>
                </CommonModal>

                { /* MODAL TO ACTIVATE USER */}
                <CommonModal modal={activateUserModal} title='Activate User' toggler={toggleForActivateUserModal} heading='ACTIVATE USER' triggerMethodOnSaveButtonClick={activateUser} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to activate '}{user.name}{'?'}</Label>
                </CommonModal>

                { /* MODAL TO DELETE USER */}
                <CommonModal modal={deleteUserModal} title='Delete User' toggler={toggleForDeleteUserModal} heading='DELETE USER' triggerMethodOnSaveButtonClick={deleteUser} buttonText='Yes'>
                    <Label className="col-form-label">{'Are you sure you want to delete '}{user.name}{'?'}</Label>
                </CommonModal>

                <CommonModal modal={resetPasswordModal} title='' toggler={toggleForResetPasswordModal} heading='RESET PASSWORD' triggerMethodOnSaveButtonClick={resetPassword} buttonText='Reset Password'>
              <Form>
                  <FormGroup>
                      <Label>New Password:</Label>
                      <Input type="password" id="text" placeholder='Enter New Password' onChange={(e) => { setNewPassword(e.target.value) }} />
                      <Label style={{ color: 'red', fontStyle: 'italic', fontSize: 12 }}>{formErrors.newPassword}</Label>
                      <Label style={{ color: 'red', fontStyle: 'italic', fontSize: 12 }}>{formErrors.specialChars}</Label>
                  </FormGroup>
                  <FormGroup>
                      <Label>Confirm Password:</Label>
                      <Input type="password" id="text" placeholder='Confirm New Password' onChange={(e) => { setConfirmPassword(e.target.value) }} />
                      <Label style={{ color: 'red', fontStyle: 'italic', fontSize: 12 }}>{formErrors.confirmPassword}</Label>
                  </FormGroup>
              </Form>
          </CommonModal>

      {/* MODAL FOR MANAGING Roles */}
      <CommonModal modal={manageEditRolesModal} title="" toggler={toggleForManageEditRolesModal} heading="Edit User Roles" triggerMethodOnSaveButtonClick={updateUserRoles} buttonText="Update Roles">
        <Form>
          <FormGroup style={{ height: 250, position: "relative" }}>
            <MultiSelect
              options={options}
              value={selectedOptions}
              labelledBy={"Select Roles"}
              onChange={handleSelectChange}
              overrideStrings={overrideStrings}
            />
          </FormGroup>
        </Form>
      </CommonModal>

            </>
        );
    }

};

export default User;