import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import '../../../css/app.css'
import { copyResponse } from "workbox-core"


const RenewAccreditationApplication = ({airportApplicationID,actionType,renewPeriod,isLoading,setIsLoading})=>{
    if(renewPeriod===undefined)
        renewPeriod="0";

    const userID = localStorage.getItem("userID");
    const navigate = useNavigate()
    const [applicationList, setApplicationList] = useState([]);
    console.log(renewPeriod);

    useEffect(()=>{
        getAirportApplicationListToRenewUpgradeDowngrade();
    },[])

    const getAirportApplicationListToRenewUpgradeDowngrade = async () => {
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportApplicationListToRenewUpgradeDowngrade}?ApplicationID=${airportApplicationID}&ActionType=${actionType}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            })
            if (response.ok) {
                const result = await response.json()
                let fetchedList = []
                for (let i = 0; i < result.length; i++) {
                fetchedList.push(result[i])
                }
                setApplicationList(fetchedList)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAirportApplicationListToRenewUpgradeDowngrade}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }


    const copyOrFreshAirportApplication = async (dataType,applicationID) => {

        const requestBody = {
            "applicationID": applicationID,
            "actionType": actionType,
            "dataAction": dataType,
            "userID": userID,
            "renewalPeriod" : renewPeriod,
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.copyOrFreshAirportApplication}`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
            })
            if (response.ok) {
                const result = await response.json()
                setIsLoading(false);
                window.location.href = `/ApplicationStage/ApplicationID/${result.applicationID}/AirportID/${result.airportID}`;
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.copyOrFreshAirportApplication}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }

    const handleLoading = ()=>{
        setIsLoading(true);
    }

    return(
        <>
        <div>
            <div className="row" style={ applicationList !== null && applicationList.length !== 0 && applicationList[0].isInterim === 1 ? { display: 'none' } : {} }>
                <span>You can choose to start an application from scratch or copy data from a previous application.</span>
            </div>
            <div className="row mt-3">
                <div className="col d-flex">
                        {
                            applicationList !== null && applicationList.length !== 0 && applicationList[0].isInterim === 1
                                ?
                                <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={()=>{copyOrFreshAirportApplication('Fresh',airportApplicationID);handleLoading()}}>
                                    Start an interim application
                                </button>
                                :
                                <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={()=>{copyOrFreshAirportApplication('Fresh',airportApplicationID);handleLoading()}}>
                                    Start New Application from Scratch
                                </button>
                        }
                </div>
            </div>
            <div className="row mt-3">
                    <div className="col-lg-12">
                        <div className="card card-contrast card-table mb-0">
                            <div className="card-body card-body-contrast">
                            {
                                applicationList.length  
                                ?
                                applicationList[0].isInterim==0
                                ?
                            (
                                <table className="table table-striped table-borderless">
                                    <tbody className="no-border-x">
                                    <tr>
                                        <th style={{width:'15%'}}>Application ID</th>
                                        <th style={{width:'15%'}}>Application Type</th>
                                        <th style={{width:'15%'}}>Accreditation level</th>
                                        <th style={{width:'15%'}}>Status</th>
                                        <th style={{width:'15%'}}>Date Created</th>
                                        <th style={{width:'15%'}}>Date Approved</th>
                                        <th style={{width:'10%'}}>Actions</th>
                                    </tr> 
                                    {
                                        !applicationList.length  
                                            ?
                                            <tr className="alt-row">
                                                <td colSpan="7">&nbsp; Application does not exist.</td>
                                            </tr>
                                            :
                                        applicationList.map((appList, index) => 
                                        <tr key ={index}>
                                            <td>{appList.airportApplicationID}</td>
                                            <td>{appList.airportType}</td>
                                            <td>{appList.accreditationLevel}</td>
                                            <td>{appList.airportStatus}</td>
                                            <td>{appList.createdDate}</td>
                                            <td>{appList.approvedDate}</td>
                                            <td className="text-right actions">
                                                {/* <a>
                                                    <i className="mdi mdi-more"></i>
                                                    <span className="sr-only">Menu</span>
                                                </a> */}
                                                <div className="row">
                                                    
                                                    <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${appList.airportApplicationID}/AirportID/${appList.airportID}`}><span className="icon mdi mdi-eye"></span>View</a>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => {copyOrFreshAirportApplication('Copy',appList.airportApplicationID);handleLoading()}} ><span className="icon mdi mdi-copy"></span>Copy</a>
                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    }
                                    </tbody>
                                </table>
                            )
                            :
                            <></>
                            :
                            <></>
                            }
                            </div>
                        </div>
                    </div>
            </div>    
        </div>
        </>
    );
}

export default RenewAccreditationApplication;