import React from 'react';
import Tab from '../../Verifiers/ExpandedListViewComponents/Tab';

const TabList = ({ activeTab, setActiveTab,isFullAccess }) => {
    return (
        <ul className="nav nav-tabs nav-tabs-classic" role="tablist">
             {isFullAccess === 'AccFullAccess' ?
            <Tab tabName="Applications" iconClassName="icon icon-left icon mdi mdi-account-box-mail" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
            {isFullAccess === 'AccFullAccess' || isFullAccess === 'AccReadOnlyAccess' ?
            <Tab tabName="Users" iconClassName="icon icon-left icon mdi mdi-account" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
            {isFullAccess !== 'AccNoAccess' ?
            <Tab tabName="Documents" iconClassName="icon icon-left icon mdi mdi-file-plus" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
            {isFullAccess === 'AccFullAccess' || isFullAccess === 'AccAirportUserAccess' ?
            <Tab tabName="Events" iconClassName="icon icon-left mdi mdi-file-text" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
        </ul>
    );
};

export default TabList;