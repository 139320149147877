import React from 'react'
import { htmlConverterReact } from 'html-converter-react'
 
const SelectAccreditationLevel = ({ data, state, setState, activeRightPane, setActiveRightPane, leftNavigationMenu, saveAccreditationLevel, userType, visibilityBasedOnType }) => {

    const updateState = (index) => {
        const updatedState = {...state}
        updatedState.default[1].value = index.toString()
        setState(updatedState)
    }
 
    const move = async (direction) => {
        const updatedState = {...state}
        updatedState.default[1].isSubmitButtonClicked = true
       
        if(updatedState.default[1].value.length !== 0)
            updatedState.default[1].completed = true
        
        setState(updatedState)
       
        if(updatedState.default[1].completed){
            await saveAccreditationLevel(updatedState.default[1].value)
            switch(direction){
                case 'forward':
                    if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1)
                        setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1])
                    break
                case 'backward':
                    if(leftNavigationMenu.indexOf(activeRightPane) !== 0)
                        setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1])
                    break
                default:
                    break
            }
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        }
    }

    const checkDisability = (level,lastestAccLevel) =>{
        if(userType === 'draft' && (state.applicationStatus === 'In Progress' || state.applicationStatus === 'Draft' || state.applicationStatus === 'Verifier comments - Airport to address' || state.applicationStatus === 'Admin comments - Airport to address')){
            // if(lastestAccLevel === 0){
            //     return  visibilityBasedOnType(state.default[1].type, true)
            // }
            switch(state.applicationType){
                case 'Renew' :            
                    return parseInt(level) !== parseInt(state.default[1].value)
                case 'Upgrade' :
                        return !(parseInt(level) > lastestAccLevel)
                case 'Downgrade':
                        return !(parseInt(level) < lastestAccLevel)
                default:
                    break
            }
            return false
        }else {
        return true
        }
    }
    const checkColorOnDisability = (level,lastestAccLevel) =>{
        if(userType === 'draft' && (state.applicationStatus === 'In Progress' || state.applicationStatus === 'Draft' || state.applicationStatus === 'Verifier comments - Airport to address' || state.applicationStatus === 'Admin comments - Airport to address')){
            switch(state.applicationType){
                case 'Renew' :            
                    return parseInt(level) !== parseInt(state.default[1].value)
                case 'Upgrade' :
                        return !(parseInt(level) > lastestAccLevel)
                case 'Downgrade':
                        return !(parseInt(level) < lastestAccLevel)
                default:
                    break
            }
            return false
        }else{
            if(parseInt(level) !== parseInt(state.default[1].value))
            return true

        }
        //return true
    }

    /* synchronous method to call when moving freely just to navigate forward or backward */
    const navigate = (direction) => {
        switch(direction){
            case 'forward':
                if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1)
                    setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1])
                break
            case 'backward':
                if(leftNavigationMenu.indexOf(activeRightPane) !== 0)
                    setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1])
                break
            default:
                break
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }

    return(
        data.default.length === 0 
            ? 
            <React.Fragment></React.Fragment>
            :
            <React.Fragment>
                <div className="card card-border card-contrast" style={activeRightPane === data.default[1].name ? {} : {display: 'none'}}>
                    <div className="card-header card-header-contrast card-header-divider">
                        Select Accreditation Level
                    </div>
                    <div className="card-body px-2">
                        {
                            data.default[1].levels.map((level, index) => {

                                return(
                                    <label key={level.id}  className={parseInt(level.id) % 2 === 0 ? "plan complete-plan" : "plan basic-plan"} htmlFor={level.id}>
                                         <input id={level.id} type="radio" name="level" checked={parseInt(data.default[1].value) === parseInt(level.id)} onChange={() => { updateState(level.id) }} disabled={checkDisability(level.id,level.latestAcclevel)} />
                                        <div style={{backgroundColor: checkColorOnDisability(level.id,level.latestAcclevel) ? '#f5f5f5' : ''}} className={parseInt(level.id) % 4 === 0 ? "plan-content plan-content4" : parseInt(level.id) % 3 === 0 ? "plan-content plan-content3" : parseInt(level.id) % 2 === 0 ? "plan-content plan-content2" : "plan-content plan-content1"}>
                                            <img src="dist/img/life-saver-img.svg" alt="level-icon" />
                                            <div className="plan-details">
                                                <span>{level.levelName}</span>
                                                <p className="mb-1">Requirements</p>
                                                <span>{htmlConverterReact(level.requirements)}</span>
                                            </div>
                                        </div>
                                    </label>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="" style={activeRightPane === data.default[1].name ? {display: 'flex', justifyContent: 'flex-end'} : {display: 'none'}}>
                    {
                        visibilityBasedOnType(state.default[1].type, true) 
                            ?
                            <React.Fragment>
                                <button className="btn btn-space btn-primary btn-xl" disabled={state.default[1].value.length !== 0 && state.default[1].value !== '0' ? false : true} onClick={() => { move('backward') }}>
                                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Save and go Back
                                </button>
                                <button id="validation-btn" className="btn btn-space btn-success btn-xl" disabled={state.default[1].value.length !== 0 && state.default[1].value !== '0' ? false : true} onClick={() => { move('forward') }}>Save and go Next&nbsp;
                                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                </button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <button className="btn btn-space btn-primary btn-xl" onClick={() => { navigate('backward') }}>
                                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Back
                                </button>
                                <button id="validation-btn" className="btn btn-space btn-success btn-xl" onClick={() => { navigate('forward') }}>Next&nbsp;
                                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                </button>
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
    )
}
 
export default SelectAccreditationLevel