import React, { useEffect, useState } from "react";
import config from "../../api/config";
import apiconfig from "../../api/apiconfig";
import { Link } from "react-router-dom";
import LoginNavBar from "./LoginNavBar";
import { useNavigate } from "react-router-dom";
import Footer from "../../Layout/Footer";

const AcceptableUsePolicy = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [date, setDate] = useState("");
  const navigate = useNavigate();
  const getUsePolicy = async () => {
    try {
      const response = await fetch(
        `${config.apiUrl}${apiconfig.acceptableUsePolicy}`,
        {
          auth: "No Auth",
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setHtmlContent(data.htmlContent);
          setDate(data.lastUpdatedPolicyDate);
        });
    } catch (error) {
      console.log("Error: ", error);
      navigate("/error-page");
    }
  };

  useEffect(() => {
    getUsePolicy();
  }, []);

  const lastUpdatedDate = new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
  return (
    <div>
      <div className="be-wrapper be-offcanvas-menu be-fixed-sidebar be-color-header vh-100">
        <LoginNavBar />
        <div className="be-content">
          <div className="main-content container-fluid p-0">
            <div className="page-header">
              <div className="container-fluid d-flex justify-content-between align-items-baseline">
                <div>
                  <img src={require("../../img/ACA-logo.png")} />
                </div>
                  <div className="col-6 d-flex align-items-center justify-content-center">
                    <h3 className="page-header-title">Terms and Conditions/Acceptable Use Policy</h3>
                  </div>
                  <div ></div>
                <div>
                  <Link to="/login"className="btn btn-lg btn-block btn-primary mt-5">Back</Link>
                </div>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="card">
                  <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    {/* {htmlContent && (<p>Last Updated: {lastUpdatedDate}</p>)} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AcceptableUsePolicy;
