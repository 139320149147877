import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate,Link } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import EditSponsors from './Sponsors/EditSponsor';
import CommonCustomModal from '../Common/CommonCustomModal';

const UpGradeVerifier = ({ toggler, verifier ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();
    const [examType, setExamType] = useState("");
    const [examTypeID, setExamTypeID] = useState("");
    const [fees, setFees] = useState("");
    const [resit, setResit] = useState("1st attempt");
    const [clientID, setClientID] = useState("");
    const [isConfirm, setIsConfirm] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [isSuspended,setIsSuspended] = useState("");
    const [suspendedDate,setSuspendedDate] = useState("");
    const userID = localStorage.getItem("userID");
    const [editSponsorModal, setEditSponsorModal] = useState(false);
    const [comments, setComments] = useState("");
    const [isButtonClicked, setButtonClicked] = useState(false);
    const toggleForEditSponsorModal = () => {
        setEditSponsorModal(!editSponsorModal);
    } 
    const sponsorID = {
        id: verifier.sponsorID,
    };


    {/*Validation*/}
    const validateForm = (clientID,comments) => {
        const errors = {};
        // if(!examType){
        //     errors.examType = 'Please select a value.';
        // }
        // if(!isConfirm){
        //     errors.isConfirm = 'In order to progress, please provide confirmation if the Prospective Verifier has the relevant experience. If they do not, please cancel this and remove them from the Programme.';
        // }
        if(clientID === 'Not Set'){
            errors.clientID = 'Please update the client ID and then try upgrade process.';
        }
        if(!comments && (isSuspended === 'Yes')){
            errors.comments = 'Please enter the notes.';
        }else if(comments.length > 1000 && (isSuspended === 'Yes')){
            errors.comments = 'Maximum of 1000 characters allowed.';
        }else if(comments.length < 10 && (isSuspended === 'Yes')){
          errors.comments = 'Please enter a minimum of 10 characters.';
        }
        return errors;
    }

    const upgrade = async () => {
        const requestBody = {
            verifierID : verifier.verifierDetailID,
            sponsorID : verifier.sponsorID ,
            exampTypeID : examTypeID,
            amount : fees,
            resist : resit,
            userID : userID,
            notes : comments !== '' ? comments : ''


        };
        const validationErrors = validateForm(clientID,comments);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
                setButtonClicked(true);
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.upgradeVerifier}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier has been Upgraded successfully.')
            {
                toast.success('Verifier has been Upgraded successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setButtonClicked(false);  
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };



    useEffect(() => {
        fetch(`${config.apiUrl}${apiconfig.getUpgradeDetails}${verifier.verifierDetailID}`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
        })
            .then((response) => response.json())
            .then((data) => {
                setExamType(data.examType);
                setResit(data.isResit);
                setFees(data.examFee);
                setExamTypeID(data.examTypeID);
                setSuspendedDate(data.suspensionDate);
                setIsSuspended(data.suspended);

            }).catch((err) => {
                navigate("/error-page");
            });
    }, [verifier.verifierDetailID])



      useEffect(() =>{
     
      if((verifier.sponsor !== 'NA' || verifier.sponsor !== '') && verifier.sponsorClientID !== "")
      {
        setClientID(verifier.sponsorClientID);
      } else if (verifier.sponsor !== 'NA'&& verifier.sponsorClientID === "" )
      {
        setClientID("Not Set");
      } 
    },[verifier.sponsorClientID,verifier.sponsor])

    const handleClick=()=>{
        toggleForEditSponsorModal();
    }


    return (
        <>
        <div>
    <form>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Verifier Name :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{verifier.verifierName}</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Status">Verifier Status :</label>      
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Status">{verifier.verifierStatus}</label>
                        </div>
                    </div>
                    <div className="form-row">                  
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="exam-type">Exam Type :</label>   
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="exam">Upgrade</label>
                        </div>
                    </div>
                    <div className="form-row">                  
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="exam-type">Exam Level :</label>   
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="exam">{examType}</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="resit">Exam Attempt :</label>   
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="resit">{resit}</label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="fees">Fees :</label>     
                        </div>
                        <div className="form-group col-md-9 mb-1">
                        <label htmlFor="fees"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 27 27">
<path id="Icon_material-euro-symbol" data-name="Icon material-euro-symbol" d="M22.5,27.75a9.72,9.72,0,0,1-8.64-5.25H22.5v-3H12.87a9.435,9.435,0,0,1,0-3H22.5v-3H13.86a9.731,9.731,0,0,1,14.985-2.9L31.5,7.95A13.48,13.48,0,0,0,9.78,13.5H4.5v3H9.09a12.545,12.545,0,0,0,0,3H4.5v3H9.78A13.48,13.48,0,0,0,31.5,28.05l-2.67-2.655A9.608,9.608,0,0,1,22.5,27.75Z" transform="translate(-4.5 -4.5)" fill="#333d47"/>
</svg> {fees} </label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="client_Name">Sponsor Name :</label>   
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="client_Name" onClick={handleClick} style={{cursor:'pointer', color: 'red'}}> 
                                {/* <Link className="text-danger" to={`/viewsponsor/${verifier.sponsorID}`} target='_blank'> */}
                                    {verifier.sponsor}
                                {/* </Link> */}
                            </label>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="client_ID">Client ID :</label> 
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="client_ID">{clientID}</label>
                        </div>
                        <p style={{color:'red'}}>{formErrors.clientID}</p>
                    </div>
                    { isSuspended ==='Yes' ?
                    <>
                    <div> 
                        <p className="col-form-label" style={{color:'red'}}>
                        Please note, the Verifier is currently Suspended from the next Level until {suspendedDate}. Justification will need to be provided below in order to upgrade them. The suspension(s) will then be removed from the Verifier from the current Level perspective. Are you sure you want to upgrade ?
                        </p>
                    </div> 
                    <br></br>
                    <div className="form-row">                 
                        <textarea type="text" className="form-control form-control-xs" id="input_comments" onChange={(e) => { setComments(e.target.value) }} style={{ height: 120}}/>
                        <i style={{color:'red'}}>{formErrors.comments}</i>
                    </div>
                    </>
                    :
                    <></>
                    }
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={upgrade} disabled={isButtonClicked}>Submit</button>
            </div>
            </div>

<CommonCustomModal modal={editSponsorModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForEditSponsorModal} heading='Edit Sponsor'  buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
<EditSponsors toggler={toggleForEditSponsorModal} sponsor = {sponsorID}  refetchs={refetchs} setRefetchs={setRefetchs} />
</CommonCustomModal>
</>
    );
};

export default UpGradeVerifier;