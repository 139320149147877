import React from 'react'
import SubSection from './SubSection'
import { htmlConverterReact } from 'html-converter-react'
import SubQuestionForSection from './SubQuestionForSection'

const Section = ({ index, data, state, setState, activeRightPane, setActiveRightPane, leftNavigationMenu, saveSubSection, userType, questionRefs, calculateQuestionRefIndex, scrollToQuestionViaQuestionRef, visibilityBasedOnType, commentsVisibility, setAlertDialogBoxProperties, reCalculateTotalAtRunTime, setReCalculateTotalAtRunTime }) => {

    return(
        <React.Fragment>
            <div className="card card-border card-contrast" style={activeRightPane === data.title ? {} : {display: 'none'}}>
                <div className="card-header card-header-contrast card-header-divider">
                    {htmlConverterReact(data.title)}
                </div>
                <div className="card-body px-2">
                    <p>{htmlConverterReact(data.description)}</p>
                    {
                        data.subsections.map((subsection) => {
                            return(
                                <React.Fragment>
                                    <div className="card card-border card-table">
                                        <div className="card-header">
                                            {htmlConverterReact(subsection.title)}
                                        </div>
                                        <div className="card-body">
                                            <p className="px-4">{htmlConverterReact(subsection.description)}</p>
                                                {
                                                    subsection.content.questions.map((question, question_index) => {
                                                        return question.hideQuestionInSection
                                                            ?
                                                            <React.Fragment></React.Fragment>
                                                            :
                                                            question.isVisible && question.responseType === 'other' && !question.hasExternalUIComponent
                                                                ?
                                                                <React.Fragment>
                                                                    <table className="table table-striped table-hover">
                                                                        <thead style={question.other !== null && question.other.headerCellsVisibility ? {} : {display: 'none'}}>
                                                                            <tr>
                                                                                {
                                                                                    question.other !== null && question.other.columns.map((column) => {
                                                                                        return <th style={{ width: column.width }}>{column.title}</th>
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr style={{cursor: 'pointer'}} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                                <td colSpan={question.other.columns.length}>{question.question}</td>
                                                                            </tr>
                                                                            {
                                                                                question.lastSuccessfullySavedResponseOther !== null && question.lastSuccessfullySavedResponseOther.length !== 0 
                                                                                    ? 
                                                                                    question.lastSuccessfullySavedResponseOther.map((response) => {
                                                                                        return  <tr className="task-complete" onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                                                    {
                                                                                                        response.inputs.map((input, input_index) => {
                                                                                                            return input.type === 'dropdown' && input.value.length !== 0 
                                                                                                                ?
                                                                                                                <td style={{ width: question.other.columns[input_index].width }}>{input.options.find(option => option.value === input.value).label}</td>
                                                                                                                :
                                                                                                                <td style={{ width: question.other.columns[input_index].width }}>{input.value}</td>
                                                                                                        })
                                                                                                    }
                                                                                                    <td>
                                                                                                        {
                                                                                                            subsection.isSubmitButtonClicked 
                                                                                                                ? 
                                                                                                                question.required 
                                                                                                                    ?
                                                                                                                    question.lastSuccessfullySavedResponseOther.length === 0 
                                                                                                                        ? 
                                                                                                                        <span className="mdi mdi-3x mdi-alert-circle text-danger" />
                                                                                                                        : 
                                                                                                                        <span className="mdi mdi-3x mdi-check-circle text-success" />
                                                                                                                    :
                                                                                                                    <React.Fragment></React.Fragment>
                                                                                                                :
                                                                                                                <React.Fragment></React.Fragment>
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                    })
                                                                                    :
                                                                                    <React.Fragment></React.Fragment>
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    { 
                                                                        question.subquestions.length === 0 
                                                                            ? 
                                                                            <React.Fragment></React.Fragment> 
                                                                            : 
                                                                            <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={true} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                                                                    }
                                                                </React.Fragment>
                                                                :
                                                                question.isVisible && question.responseType === 'matrix' 
                                                                    ?
                                                                    <React.Fragment>
                                                                        <h5 className="px-3 d-flex align-items-center justify-content-between">
                                                                            {question.question}
                                                                            {
                                                                                subsection.isSubmitButtonClicked 
                                                                                    ? 
                                                                                    question.required 
                                                                                        ?
                                                                                        question.lastSuccessfullySavedResponseMatrix !== null && question.lastSuccessfullySavedResponseMatrix.length === 0 
                                                                                            ? 
                                                                                            <span className="mdi mdi-3x mdi-alert-circle text-danger" />
                                                                                            : 
                                                                                            <span className="mdi mdi-3x mdi-check-circle text-success" />
                                                                                        :
                                                                                        <React.Fragment></React.Fragment>
                                                                                    :
                                                                                    <React.Fragment></React.Fragment>
                                                                            }
                                                                        </h5>
                                                                        <table className="table table-hover table-bordered task-complete" onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question)  }}>
                                                                            <thead>
                                                                                <tr>
                                                                                    {
                                                                                        question.matrix !== null && question.matrix.columns.map((column) => {
                                                                                            return <th style={{ width: column.width }}>{column.title}</th>
                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="no-border-x">
                                                                                {
                                                                                    question.matrix !== null && question.matrix.isValid 
                                                                                        ? 
                                                                                        question.lastSuccessfullySavedResponseMatrix !== null && question.lastSuccessfullySavedResponseMatrix.length !== 0 
                                                                                            ? 
                                                                                            question.lastSuccessfullySavedResponseMatrix.map((row) => {
                                                                                                return  <React.Fragment>
                                                                                                            <tr>
                                                                                                                <td colSpan={6}>"<i>{row.title}</i>"</td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                            {
                                                                                                                row.attributes.map((attribute, attribute_index) => {
                                                                                                                    return <td style={{width: question.matrix.columns[attribute_index].width }}>{attribute.value}</td>
                                                                                                                })
                                                                                                            }
                                                                                                            </tr>
                                                                                                        </React.Fragment>     
                                                                                            })
                                                                                            :
                                                                                            <tr>
                                                                                                <td colSpan={6}>No response provided yet</td>
                                                                                            </tr>
                                                                                        :
                                                                                        <tr>
                                                                                            <td colSpan={6}>
                                                                                                <b>{question.matrix !== null && question.matrix.validationMessage}</b>
                                                                                            </td>
                                                                                        </tr>
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                        { 
                                                                            question.subquestions.length === 0 
                                                                                ? 
                                                                                <React.Fragment></React.Fragment> 
                                                                                : 
                                                                                <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={true} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                                                                        }
                                                                    </React.Fragment>
                                                                    : 
                                                                    question.isVisible && (question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown')
                                                                        ?
                                                                        <React.Fragment>
                                                                            <table className="table table-striped table-hover">
                                                                                {
                                                                                    question_index === 0 || (question_index > 0 && (subsection.content.questions[question_index-1].responseType === 'other' || subsection.content.questions[question_index-1].responseType === 'matrix'))
                                                                                        ? 
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th style={{ width: "50%" }}>Question</th>
                                                                                                <th style={{ width: "45%" }}>Response</th>
                                                                                                <th style={{ width: "5%" }} />
                                                                                            </tr>
                                                                                        </thead>
                                                                                        :
                                                                                        <React.Fragment></React.Fragment>
                                                                                }
                                                                                <tbody>
                                                                                    <tr className="task-complete" onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                                        <td style={{ width: "50%" }}>{htmlConverterReact(question.question)}</td>
                                                                                        {
                                                                                            question.lastSuccessfullySavedResponse.length === 0 
                                                                                                ?
                                                                                                <td style={{ width: "45%" }}>No response provided yet</td>
                                                                                                :
                                                                                                question.responseType === 'text' || question.responseType === 'textarea' 
                                                                                                    ? 
                                                                                                    <td style={{ width: "45%" }}>{question.lastSuccessfullySavedResponse}</td>
                                                                                                    :
                                                                                                    question.responseType === 'dropdown'
                                                                                                        ? 
                                                                                                        <td style={{ width: "45%" }}>{question.options.find(option => option.value === question.lastSuccessfullySavedResponse).label}</td>
                                                                                                        :
                                                                                                        <React.Fragment></React.Fragment>
                                                                                        }
                                                                                        <td style={{ width: "5%" }}>
                                                                                            {
                                                                                                subsection.isSubmitButtonClicked 
                                                                                                    ?
                                                                                                    question.required 
                                                                                                        ?
                                                                                                        question.lastSuccessfullySavedResponse.length === 0 
                                                                                                            ? 
                                                                                                            <span className="mdi mdi-3x mdi-alert-circle text-danger" />
                                                                                                            : 
                                                                                                            <span className="mdi mdi-3x mdi-check-circle text-success" />
                                                                                                        : 
                                                                                                        <React.Fragment></React.Fragment>
                                                                                                    :
                                                                                                    <React.Fragment></React.Fragment>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            { 
                                                                                question.subquestions.length === 0 
                                                                                    ? 
                                                                                    <React.Fragment></React.Fragment> 
                                                                                    : 
                                                                                    <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={false} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                                                                            }
                                                                        </React.Fragment>
                                                                        :
                                                                        question.isVisible && (question.responseType === 'h5' || question.responseType === 'none')
                                                                            ?
                                                                            <React.Fragment>
                                                                                <table className="table table-striped table-hover">
                                                                                    {
                                                                                        question_index === 0 
                                                                                            ? 
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th style={{ width: "50%" }}>Question</th>
                                                                                                    <th style={{ width: "45%" }}>Response</th>
                                                                                                    <th style={{ width: "5%" }} />
                                                                                                </tr>
                                                                                            </thead>
                                                                                            :
                                                                                            <React.Fragment></React.Fragment>
                                                                                    }
                                                                                    <tbody>
                                                                                        <tr onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }} style={{cursor: 'pointer'}}>
                                                                                            <td colSpan={4}><i>{question.question}</i></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                                { 
                                                                                    question.subquestions.length === 0 
                                                                                        ? 
                                                                                        <React.Fragment></React.Fragment> 
                                                                                        : 
                                                                                        <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={false} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                                                                                }
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment></React.Fragment>
                                                    })
                                                }
                                        </div>
                                    </div>
                                    {
                                        subsection.content.questions.map((question, question_index) => { 
                                            return question.isVisible && question.responseType === 'other' && question.other !== null && question.hasExternalUIComponent 
                                                ? 
                                                <div className="card card-border card-table">
                                                    <div className="card-header">
                                                        { htmlConverterReact(question.other.title) }
                                                    </div>
                                                    <div className="card-body">
                                                        <p className="px-4">{htmlConverterReact(question.other.description)}</p>
                                                        <table className="table table-striped table-hover">
                                                            <thead style={question.other.headerCellsVisibility ? {} : {display: 'none'}}>
                                                                <tr>
                                                                    {
                                                                        question.other.columns.map((column) => {
                                                                            return <th style={{ width: column.width }}>{column.title}</th>
                                                                        })
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{cursor: 'pointer'}} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                    <td colSpan={4}>{question.question}</td>
                                                                </tr>
                                                                {
                                                                    question.lastSuccessfullySavedResponseOther !== null && question.lastSuccessfullySavedResponseOther.length !== 0 
                                                                        ? 
                                                                        question.lastSuccessfullySavedResponseOther.map((response) => {
                                                                            return  <tr className="task-complete" onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                                        {
                                                                                            response.inputs.map((input, input_index) => {
                                                                                                return <td style={{ width: question.other.columns[input_index].width }}>{input.value}</td>
                                                                                            })
                                                                                        }
                                                                                        <td>
                                                                                            {
                                                                                                subsection.isSubmitButtonClicked 
                                                                                                    ? 
                                                                                                    question.required 
                                                                                                        ?
                                                                                                        question.lastSuccessfullySavedResponseOther.length === 0 
                                                                                                            ? 
                                                                                                            <span className="mdi mdi-3x mdi-alert-circle text-danger" />
                                                                                                            : 
                                                                                                            <span className="mdi mdi-3x mdi-check-circle text-success" />
                                                                                                        :
                                                                                                        <React.Fragment></React.Fragment>
                                                                                                    :
                                                                                                    <React.Fragment></React.Fragment>
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                        })
                                                                        :
                                                                        <React.Fragment></React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                :
                                                <React.Fragment></React.Fragment>
                                        })
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </div>
            <div className="" style={activeRightPane === data.title ? {display: 'flex', justifyContent: 'flex-end'} : {display: 'none'}}>
                <button className="btn btn-space btn-primary btn-xl" onClick={() => { if(leftNavigationMenu.indexOf(activeRightPane) !== 0){ setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1]); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) } }}>
                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Back
                </button>
                <button id="validation-btn" className="btn btn-space btn-success btn-xl" onClick={() => { if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1){ setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1]); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) } }}>Next&nbsp;
                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                </button>
            </div>
            {
                data.subsections.map((subsection, subsection_index) => {
                    return <SubSection key={subsection_index} index={subsection_index} data={subsection} sectionIndex={index} state={state} setState={setState} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} leftNavigationMenu={leftNavigationMenu} saveSubSection={saveSubSection} userType={userType} questionRefs={questionRefs} calculateQuestionRefIndex={calculateQuestionRefIndex} visibilityBasedOnType={visibilityBasedOnType} commentsVisibility={commentsVisibility} setAlertDialogBoxProperties={setAlertDialogBoxProperties} reCalculateTotalAtRunTime={reCalculateTotalAtRunTime} setReCalculateTotalAtRunTime={setReCalculateTotalAtRunTime} />
                })
            }  
        </React.Fragment>       
    )
}

export default Section