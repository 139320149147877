import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import '../css/app.css';
import SideMenu from './SideMenu';

const Layout = ({ children }) => {

    const [sideMenu, setSideMenu] = useState(false);
    const toggleSideMenu = () => { setSideMenu(!sideMenu); }

    return (
        <div className="be-wrapper be-offcanvas-menu be-fixed-sidebar be-color-header vh-100">
            {
                sideMenu
                    ?
                    <SideMenu sideMenuToggler={toggleSideMenu} />
                    :
                    <></>
            }
            <Header sideMenuToggler={toggleSideMenu} sideMenuState={sideMenu} />
            <div className="be-content" style={{ backgroundColor: '#ffffff' }}>
                {/* <div className="main-content container-fluid"> */}
                    {children}
                {/* </div> */}
            </div>
            <Footer />
        </div>
    );
};

export default Layout;