import React from 'react'
import { htmlConverterReact } from 'html-converter-react'

const SubQuestionForVerificationSummary = ({ question, subsection, setActiveRightPane, scrollToQuestionViaQuestionRef }) => {
    return  <React.Fragment>
                {
                    question.hideQuestionInVerificationSummary
                        ?
                        <React.Fragment></React.Fragment>
                        :
                        question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown' || question.responseType === 'other' || question.responseType === 'matrix'
                            ?
                            <tr style={question.isVisible ? {} : {display: 'none'}} className={question.lastSuccessfullySavedResponse.length === 0 ? "task-uncomplete" : "task-complete"} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                <td>{htmlConverterReact(question.question)}</td>
                                <td></td>
                                {
                                    question.lastSuccessfullySavedResponse.length === 0 
                                        ? 
                                        <td className="" style={{paddingLeft: 10}}>------</td>
                                        :
                                        question.responseType === 'text' || question.responseType === 'textarea' 
                                            ?
                                            <td className="" style={{paddingLeft: 10}}>{question.lastSuccessfullySavedResponse}</td>
                                            :
                                            question.responseType === 'dropdown' 
                                                ? 
                                                <td className="" style={{paddingLeft: 10}}>{question.options.find(option => option.value === question.lastSuccessfullySavedResponse).label}</td>
                                                :
                                                <React.Fragment></React.Fragment>
                                }
                            </tr>
                            :
                            <tr style={question.isVisible ? {cursor: 'pointer'} : {display: 'none'}} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                <td><i>{htmlConverterReact(question.question)}</i></td>
                                <td></td>
                                <td></td>
                            </tr> 
                }
                {
                    question.subquestions.map((subquestion) => {
                        return <SubQuestionForVerificationSummary question={subquestion} subsection={subsection} setActiveRightPane={setActiveRightPane} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} />
                    })
                }
            </React.Fragment>
}

export default SubQuestionForVerificationSummary