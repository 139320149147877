import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const EditUserPrimary = ({user, toggler, refetchs, setRefetchs}) => {

  
    const navigate = useNavigate();
    const [emails, setEmails] = useState('');
    const [firstNames, setFirstNames] = useState('');
    const [lastNames, setLastNames] = useState('');
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [country, setcountry] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postcode, setPostcode] = useState("");
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [formErrors, setFormErrors] = useState({});

    const [documents, setDocuments] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [showInput, setShowInput] = useState(false);
    const [image, setimages] = useState("");
    const [isOldDocumentSelected, setIsOldDocumentSelected] = useState(true);
    const [fileSize, setFileSize] = useState(0);
    const [filePath, setFilePath] = useState("");

    const updateAirportUser = async () => {

      const requestBody = {
        "userID": user.userID,
        "firstName": firstNames,
        "address1": address1,
        "address2": address2,
        "state": state,
        "cityID": city !=='' ? city : 0,
        "countryID": country !== '' ? country : 0,
        "postalCode": postcode,
        "lastName": lastNames,
        "email": emails,
        "isPrimary":user.isPrimary,
        "loggedInUserId": userID,
        "aciMembershipDocument":image,
        "docContent" : documents,
      };
      const validationErrors = validateForm(emails,firstNames,lastNames,address1,address2,country,city,state,postcode,documents,isOldDocumentSelected,image,fileSize);
          setFormErrors(validationErrors);
          if(Object.keys(validationErrors).length === 0){
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.UpdateAirportUserDetails}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                    if (response.ok) {
                        var result = await response.json();

                        if(result.saveErrorMessage === 'User contact details updated successfully.')
                        {
                            toast.success('User contact details updated successfully.', {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Slide
                            }); 
                        setRefetchs(!refetchs);
                        toggler();
                        }else{
                            setErrorMessage(result.saveErrorMessage);
                        }
                    } else {
                        const errorText = await response.text();
                        const apiname = `${apiconfig.UpdateAirportUserDetails}`;
                        navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                    }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
          }
  }

    {/*Validation*/}
    //Add User form validation
    const validateForm = (email, firstName, lastName,address1, address2,country,city,state,postcode,documents,isOldDocumentSelected,image,fileSize) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

      if(!email){
        errors.email = 'Please enter a value.';
      }
      else if(!emailRegex.test(email)){
          errors.email = 'Please enter a valid email address.';
      }

      if(!firstName){
        errors.firstName = 'Please enter a value.';
      } else if(firstName.length > 256){
          errors.firstName = 'Maximum of 256 characters allowed.';
      }else if (!/^[a-zA-Z0-9\s]+$/.test(firstName)) {
          errors.firstName = 'Special characters are not allowed.';
      }

      if(!lastName){
          errors.lastName = 'Please enter a value.';
      } else if(lastName.length > 256){
          errors.lastName = 'Maximum of 256 characters allowed.';
      }else if (!/^[a-zA-Z0-9\s]+$/.test(lastName)) {
          errors.lastName = 'Special characters are not allowed.';
      }

      if(address1.length > 0){
        if(address1.length > 256){
            errors.address1 = 'Maximum of 256 characters allowed.';
        }
    }
    
    if(address2.length > 0){
        if(address2.length > 256){
            errors.address2 = 'Maximum of 256 characters allowed.';
        }
    }

    if(state.length > 0){
        if(state.length > 256){
            errors.state = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(state)) {
            errors.state = 'Special characters are not allowed.';
        }
    }
  
    if(postcode.length > 0){
        if(postcode.length > 256){
            errors.postcode = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(postcode)) {
            errors.postcode = 'Special characters are not allowed.';
        }
    }

    if(documents){
        const allowedExtensions = [
            '.bmp', '.csv', '.doc', '.docx', '.htm', '.html', '.jpg',
            '.jpeg', '.msg', '.pdf', '.png', '.ppt', '.pptx', '.rtf',
            '.sig', '.tif', '.tiff', '.txt', '.vsd', '.xls', '.xlsx', '.zip'
        ];

        const fileExtension = image.slice(((image.lastIndexOf(".") - 1) >>> 0) + 2);
        const fileSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes
        if (!isOldDocumentSelected && !allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
            errors.documents = 'This file format is not supported.';
        }
        else if (fileSize > fileSizeLimit) {
            errors.documents = 'File could not be uploaded as it is exceeding the maximum file size of 20 MB.';
        }
    }

      return errors;
  }

      //get DetailsByID
    const getDetailByID = async () => {
    try{
        const response =  await fetch(`${config.apiUrl}${apiconfig.getAirportUserDetails}?UserID=${user.userID}&RequestFrom=${'Primary'}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        });
        if (response.ok) {
            var data = await response.json();
                if (data.aciMembershipDocument) {
                    setIsOldDocumentSelected(true);
                    setShowInput(false);
                    setFilePath(data.aciMembershipDocument);
                } else {
                    setIsOldDocumentSelected(false);
                    setShowInput(true);
                }
                setEmails(data.email);
                setFirstNames(data.firstName);
                setLastNames(data.lastName);
                setaddress1(data.address1);
                setaddress2(data.address2);
                setcountry(data.countryID);
                setCity(data.cityID);
                setState(data.state);
                setPostcode(data.postalCode);
                setimages('Previous Data');

        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getAirportUserDetails}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }

    const getAllCountryList = async () => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryL}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountryL}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }
    const getCityList = async (country) => {
        try{
            const response =  await fetch(`${config.apiUrl}${apiconfig.getCityListByCountry}${country}`, {
                method: 'GET',
                // headers: {
                //     Authorization: `Bearer ${localStorage.getItem("token")}`
                // }
            });
            if (response.ok) {
                var result = await response.json();
                setCityList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCityListByCountry}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    } 
        
      
    useEffect(()=>{
        getDetailByID();
    },[user.userID])

    useEffect(()=>{
        getAllCountryList()
    },[])

    useEffect(()=>{
        if(country){
            getCityList(country);
        }
    },[country])

    const handleCountryChange = (e) => {
        setcountry(e.target.value);
        if(e.target.value ===''){
            setCity('');
        }
    };  
    const handleCityChange = (e) => {
        setCity(e.target.value);
    }; 

    const handleDeleteClick = () => {
        setDocumentName('');
        setimages('');
        setIsOldDocumentSelected(false);
        setShowInput(true);
      };

      function fileToBase64(file, callback) {
        const reader = new FileReader();
        reader.onload = function(event) {
          const base64String = event.target.result.split(',')[1]; // Extract the base64 data
          callback(base64String);
        };
        reader.readAsDataURL(file);
      }
      const handleFileChange = (event) => {
        setFormErrors({
            ...formErrors,
            documents: '',
        });
        // setLoading(true); 
        const selectedFile = event.target.files[0];
        const fileSize = selectedFile.size;
        setFileSize(fileSize);
        setimages(selectedFile.name);
        if (selectedFile) {
            fileToBase64(selectedFile, function(base64String) {
                setDocuments(base64String);
            });
          }
        //   setLoading(false); 
      };

      const downloadFile = (url) => {
        window.open(url);
    }

    return (
        <div>
              <form>
              <div className="form-row">
                  <label htmlFor="document">First Name <span className="text-danger font-weight-bold">*</span></label>
                  <input type="text" value={firstNames} className="form-control form-control-xs" placeholder='Please enter the first name...' id="first-name" onChange={(e) => { setFirstNames(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.firstName}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="input_comments">Last Name <span className="text-danger font-weight-bold">*</span></label>
                  <input type="text" value={lastNames} className="form-control form-control-xs" placeholder='Please enter the last name...' id="last-name" onChange={(e) => { setLastNames(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.lastName}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">Email <span className="text-danger font-weight-bold">*</span></label>
                  <input type="text" value={emails} className="form-control form-control-xs" placeholder='Please enter the email...' id="email" onChange={(e) => { setEmails(e.target.value) }} disabled/>
                  <p style={{color:'red'}}>{formErrors.email}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">Address Line 1</label>
                  <input type="text" value={address1} className="form-control form-control-xs" placeholder='Please enter the Address Line 1...' id="address1" onChange={(e) => { setaddress1(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.address1}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">Address Line 2</label>
                  <input type="text" value={address2} className="form-control form-control-xs" placeholder='Please enter the Address Line 2...' id="address2" onChange={(e) => { setaddress2(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.address2}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">Country</label>
                  <select id="country" className="form-control form-control-sm" value={country}  onChange={handleCountryChange} >
                    {
                        countryList.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))
                    }
                </select>
                  <p style={{color:'red'}}>{formErrors.country}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">City</label>
                  <select id="city" className="form-control form-control-sm" value={city}  onChange={handleCityChange} >
                    {
                        cityList.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))
                    }
                </select>
                  <p style={{color:'red'}}>{formErrors.city}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">State</label>
                  <input type="text" value={state} className="form-control form-control-xs" placeholder='Please enter the State...' id="state" onChange={(e) => { setState(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.state}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">Post Code</label>
                  <input type="text" value={postcode} className="form-control form-control-xs" placeholder='Please enter the Post Code...' id="postcode" onChange={(e) => { setPostcode(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.postcode}</p>
              </div>
              <div className="form-row mt-1">
                    <label htmlFor="document">Membership Certificate</label>
                    {showInput ?
                    (
                        <>
                        <input type="file" className="form-control form-control-xs"   onChange={handleFileChange}/>
                        <p style={{color:'red'}}>{formErrors.documents}</p>
                        </>
                    )
                    :
                    (
                        <>
                        <span><a style={{color:'blue',cursor:'pointer'}} onClick={() => { downloadFile(filePath); }}>&nbsp;  View Membership Document</a></span>
                        &nbsp; <span className="icon mdi mdi-close"  style={{color:'red', cursor:'pointer'}} onClick={handleDeleteClick}></span>
                        </>
                    )}
              </div>
              {errorMessage ? (
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  ) : (
                    <span></span>
                  )}      
              </form>

              <div className="modal-footer">
              <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
              <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
              <button className="btn btn-success mt-auto" type="button" onClick={updateAirportUser}>Submit</button>
              </div>
        </div>
    );
};

export default EditUserPrimary;