import { useState, useEffect } from 'react';

const IsAccessForVerifierCombination1 = () => {

  const [isReadOnlyAccess, setIsReadOnlyAccess] = useState("");

  useEffect(() => {
    const userRoles = JSON.parse(localStorage.getItem("roles")) || [];
    if(userRoles.length !== 0){
      if(userRoles.some(role => role.name === "Administrator" || role.name === "Verifier Coordinator")){
        setIsReadOnlyAccess("FullAccess");
      }else if (userRoles.some(role => role.name === "Account Manager" || role.name === "Senior Account Manager")){
        setIsReadOnlyAccess("FullAccessForAMSAMOnly");
      }else if (userRoles.some(role => role.name === "ACI" || role.name === "ACI-E")){
        setIsReadOnlyAccess("ReadOnlyAccess");
      }else {
        setIsReadOnlyAccess("NoAccess");
      }
    }

  }, []);

  return isReadOnlyAccess;
};

export default IsAccessForVerifierCombination1;

