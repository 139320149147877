import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import axios from '../../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";

const AddVerifierExam = ({ toggler,toggler1 }) => {

    const navigate = useNavigate();

    const [exam, setExam] = useState("");
    const [examList, setExamList] = useState([]);
    const [examType, setExamType] = useState("");
    const [examTypeList, setExamTypeList] = useState([]);
    const [examStatus, setExamStatus] = useState("");
    const [comments, setcomments] = useState("");
    const [formErrors, setFormErrors] = useState({});


    useEffect(() => {
        function getExamList() {
            fetch(`${config.apiUrl}${apiconfig.getCountry}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setExamTypeList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getExamList();
    }, []);

    useEffect(() => {
        function getExamTypeList() {
            fetch(`${config.apiUrl}${apiconfig.getCountry}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setExamList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getExamTypeList();
    }, []);


    {/*Validation*/}
    const validateForm = (exam,examType) => {
        const errors = {};
        if(!exam){
            errors.exam = 'Please select Exam!';
        }
        if(!examType){
            errors.examType = 'Please select Exam Type!';
        }
        return errors;
    }

    const upGrade = async () => {   
       
        try {
            const validationErrors = validateForm(exam,examType);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){ 
                toggler1();      
                try {
                    await axios.post(`${apiconfig.addVerifier}`,
                    {
                        Exam: exam, 
                        ExamType: examType,
                        ExamStatus: examStatus,
                        Comments: comments,
            
                    },
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                      }})
                    .then((response) => {

                        // if(response.status === 200){
                        //     fetchData();
                        // }
                    })                           
                }
                catch (error) {
                    console.log("Error: ", error);
                    navigate("/error-page");
                }
                //toggleForAddUserModal();        
            }                                         
        }
        catch (error) {
            console.log("Error: ", error);
        }
    };
    return (
        <div>
    <form>
                    <div className="form-row">
                        <label htmlFor="exam">Exam</label>
                        <select id="exam" className="form-control form-control-sm" value={exam} onChange={(e) => setExam(e.target.value)}>
                        <option>Please Select Exam...</option>
                                <option>Exam 1</option>
                                <option>Exam 2</option>                         
                        </select>
                        <p style={{color:'red'}}>{formErrors.exam}</p>
                    </div>
                    <div className="form-row">
                        <label htmlFor="exam-type">Exam Type</label>
                        <select id="exam-type" className="form-control form-control-sm" value={examType} onChange={(e) => setExamType(e.target.value)}>
                        <option>Please Select Exam Type...</option>
                                <option>UpGrade</option>
                                <option>DownGrade</option>
                                <option>Renew</option>                          
                        </select>
                        <p style={{color:'red'}}>{formErrors.examType}</p>
                    </div>
                    <div  className="form-row">
                        <label htmlFor="input_comments">Comments</label>
                        <textarea type="text" className="form-control form-control-xs" id="input_comments" onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={upGrade}>Submit</button>
            </div>
            </div>
    );
};

export default AddVerifierExam;