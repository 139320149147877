import React from 'react';

const Card = ({ children, title, hasButton, toggler, icon, buttonText }) => {

    return (
        <div className="">
            {
                hasButton === true
                    ?
                    <>
                        {title}
                       
                        <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={toggler}>{buttonText} <i className="icon icon-left mdi mdi-plus"></i>
                        </button>
                       
                    </>
                    :
                    <>
                        {title}
                    </>
            }
            {children}
        </div>
    );

};

export default Card;