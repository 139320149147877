import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import AdvancedSearchByIDNew from '../../Common/AdvancedSearchByIDNew'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import {ToastContainer} from 'react-toastify'
import AccreditationAirport from './AccreditationAirport'
import { ApplicationTableHeaderCells } from './TableHeaderCells'
import AccreditationApplication from './AccreditationApplication'

const AccreditationApplications = ({ setIsLoadingChildData }) => {
    
    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [accreditationAirportList, setAccreditationAirportList] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('DESC')
    const [sortByColumnName, setSortByColumnName] = useState('ApplicationID')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Start Date')
    const [searchType, setSearchType] = useState('date')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [applicationTypeList, setApplicationTypeList] = useState([])
    const [accLevelList, setAccLevelList] = useState([])
    const [searchText, setSearchText] = useState('')
    const [regionList, setRegionList] = useState([])
    const statusOptions =  [
        {text: 'Admin comments - Airport to address', value: 'Admin comments - Airport to address'},
        {text: 'Admin comments - Verifier to address', value: 'Admin comments - Verifier to address'},
        {text: 'Airport resubmitted - In Admin Verification', value: 'Airport resubmitted - In Admin Verification'},
        {text: 'Airport resubmitted - In Verification', value: 'Airport resubmitted - In Verification'},
        {text: 'Approved', value: 'Approved'},
        {text: 'Draft', value: 'Draft'},
        {text: 'In Admin Verification', value: 'In Admin Verification'},
        {text: 'In Verification', value: 'In Verification'},
        {text: 'Verifier comments - Airport to address', value: 'Verifier comments - Airport to address'},
        {text: 'Verifier resubmitted - In Admin Verification', value: 'Verifier resubmitted - In Admin Verification'},
    ]
    const searchOptions = [
        {
            value: 'Start Date', 
            text: 'Start Date',
            type: 'date',
            query: '&StartDate=',
        },
        {
            value: 'End Date', 
            text: 'End Date', 
            type: 'date',
            query: '&EndDate='
        },
        {
            value: 'Application ID',
             text: 'Application ID', 
            type: 'text',
            query: '&ApplicationID=',
        },
        {
            value: 'Airport Name',
             text: 'Airport Name', 
            type: 'text',
            query: '&AirportName=',
        },
        {
            value: 'Airport Group',
             text: 'Airport Group', 
            type: 'text',
            query: '&AirportGroup=',
        },
        {
            value: 'Region',
            text: 'Region',
            type: 'dropdownbyID',
            query: '&Region=',
            dropdownOptions: regionList,
        },
        {
            value: 'Application Type',
             text: 'Application Type', 
            type: 'dropdownbyID',
            query: '&AirportType=',
            dropdownOptions: [
                {text: 'Downgrade', value: 'Downgrade'},
                {text: 'Entry', value: 'Entry'},
                {text: 'Renew', value: 'Renew'},
                {text: 'Upgrade', value: 'Upgrade'},
            ],
        },
        {
            value: 'Reporting Period',
             text: 'Reporting Period', 
            type: 'text',
            query: '&ReportingPeriod=',
        },
        {
            value: 'Accreditation Level', 
            text: 'Accreditation Level', 
            type: 'dropdownbyID',
            query: '&AccreditationLevel=',
            dropdownOptions: accLevelList,
        },
        {
            value: 'Status', 
            text: 'Status', 
            type: 'dropdown',
            query: '&ApplicationStatus=',
            dropdownOptions: statusOptions
        },
        {
            value: 'Accredited Date',
             text: 'Accredited Date', 
            type: 'date',
            query: '&AccreditedDate=',
        },
        {
            value: 'Verifier Assigned',
             text: 'Verifier Assigned', 
            type: 'text',
            query: '&VerifierAssigned=',
        },
        {
            value: 'Last Updated',
             text: 'Last Updated', 
            type: 'date',
            query: '&UpdatedDate=',
        },




    ]

    /* Methods */
    const fetchData = async () => {
        try {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            console.log('executing getAccreditationApplicationList()...')
            setIsLoadingChildData(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getAccreditationApplicationList}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getAccreditationApplicationList()')
                const result = await response.json()
                let fetchedReporting = []
                for (let i = 0; i < result.length; i++) {
                    fetchedReporting.push(result[i])
                }
                setAccreditationAirportList(fetchedReporting)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAccreditationApplicationList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoadingChildData(false)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const getRegionList = async () => {
        try{
            console.log('executing getRegionList()...')
            const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getRegionList()')
                var result = await response.json()
                result = result.slice(1)
                setRegionList(result)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getRegion}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
        }
        catch(error){
            console.log(error.message)
            navigate(`/error-page`)
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria])
    useEffect(() => {
        if(accreditationAirportList.length > 0){
            setPageCount(Math.ceil(accreditationAirportList[0].totalCount / itemsPerPage))
        }
    }, [accreditationAirportList])
    useEffect(() => {
        const getAccLevelList = async () => { 
            try {
                console.log('executing getAccLevelList()...')
                const response = await  fetch(`${config.apiUrl}${apiconfig.getApplicationLevelList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    console.log('fetched getAccLevelList()')
                    var result = await response.json()
                    // result = result.slice(1);
                    const option  = result.map((level)=>({
                        text : level.title,
                        value : level.id
                    }))
                    setAccLevelList(option)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getApplicationLevelList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getAccLevelList()
    }, [refetch])
    useEffect(() => {
        const getApplicationTypeList = async () => { 
            try {
                console.log('executing getApplicationTypeList()...')
                const response = await  fetch(`${config.apiUrl}${apiconfig.getApplicationTypeList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    console.log('fetched getApplicationTypeList()')
                    var result = await response.json()
                    result = result.slice(1)
                    setApplicationTypeList(result)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getApplicationTypeList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getApplicationTypeList()
    }, [refetch])
    useEffect(() => {
        getRegionList()
    }, [refetch])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(accreditationAirportList.length !== 0){
            if(accreditationAirportList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])
    

    return (
        <>
            <div className="">
                <h3 className="page-header-title">Search Airport Applications</h3>
                <div className="row">
                    <div className="col-md-6 col-lg-7">
                        <AdvancedSearchByIDNew searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} searchText={searchText} setSearchText={setSearchText} setPageNumber={setPageNumber} />
                        {
                            accreditationAirportList.length > 0
                                ?
                                <div className="align-self-center font-weight-bold">{accreditationAirportList[0].totalCount} items found</div>
                                :
                                <div className="align-self-center font-weight-bold">No items found</div>
                        }
                    </div>
                    <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                        <nav>
                            {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                            </div> */}
                            <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </nav>
                    </div>
                </div>
                <CustomTable headerCells={ApplicationTableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                { !accreditationAirportList.length
                    ?
                    <tr className="alt-row">
                        <td colSpan="12">&nbsp; Airport Applications does not exist.</td>
                    </tr>
                    :
                    accreditationAirportList.map(accAirport => <AccreditationApplication key={accreditationAirportList.indexOf(accAirport)} accAirport={accAirport} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)
                }
                </CustomTable>
                <div className="d-flex justify-content-end">
                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}

export default AccreditationApplications