import React, { useState, useEffect } from 'react';
import config from '../../api/config';
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Select from "react-select";

const AddSponsorsToVerifier = ({verifier, toggler, refetchs, setRefetchs}) => {

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const [clientId, setClientId] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [country, setcountry] = useState("");
    const [region, setregion] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [allSponsorlist, setAllSponsorList] = useState([]);
    const [isPrimary, setIsPrimary] = useState(false);
    const [sponsor, setSponsor] = useState("");
    const userID = localStorage.getItem("userID");
    const [isSponsorSelected, setIsSponsorSelected] = useState(false);

    useEffect(() => {
        function getAllSponsorList() {
            fetch(`${config.apiUrl}${apiconfig.getAllSponsor}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    let fetchedSponsor = [];
                    for (let i = 0; i < data.length; i++) {
                        fetchedSponsor.push(data[i]);
                      }
                    const option = fetchedSponsor.map((spon) => ({
                        label: spon.text,
                        value: spon.value,
                      }));
                    setAllSponsorList(option);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getAllSponsorList();
    }, []);


    {/*Validation*/}
    const validateForm = (sponsorName) => {
        const errors = {};

        if(!sponsorName){
            errors.sponsorName = 'Please select a value.';
        }
        return errors;
    }


    const addSponsors = async () => {
        var primaryValue = 0;
        if(isPrimary === true) 
        {
            primaryValue = 1;
        }
        const requestBody = {
            userID : userID,
            isPrimary : primaryValue,
            sponsorID : sponsor,
            veriferID : verifier.verifierDetailID

        };
        const validationErrors = validateForm(sponsor);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.addSponsortoVerifier}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Sponsor added successfully.')
            {
                toast.success('Sponsor added successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }else{
                
                setErrorMessage(response.saveErrorMessage);
            }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

     //get DetailsByID
    const  getSponsorDetailsByID = (selID)=> {
    fetch(`${config.apiUrl}${apiconfig.getSponsorDetailsByID}${selID}`,
    {
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
    })
        .then((response) => response.json())
        .then((data) => {
            setClientId(data.clientID);
            setaddress1(data.addressLine1);
            setaddress2(data.addressLine2);
            setcity(data.city);
            setzipcode(data.zipcode);
            setphone(data.phoneNumber);
            setemail(data.email);
            setcountry(data.country);
            setregion(data.region);
  
        }).catch((err) => {
            navigate("/error-page");
        });
  }

  const handleSponsorChange = (e) => {
    setSponsor(e.value);
    getSponsorDetailsByID(e.value);
    setIsSponsorSelected(true);
    setErrorMessage("");
  };

    return (
        <div>
    <form>
                    
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="SponsorName">Sponsor Name <span className="text-danger font-weight-bold">*</span></label>
                         <Select id="SponsorName"
                            options={allSponsorlist}
                            onChange={handleSponsorChange}
                            placeholder="Select a sponsor"
                            isSearchable
                        />
                        <p style={{color:'red'}}>{formErrors.sponsorName}</p>
                    </div>
                    </div>
                    {isSponsorSelected && (
                        <>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1 d-flex align-items-end">                    
                    <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox" id="isPrimary" onChange={(e) => { setIsPrimary(e.target.checked) }}/>
                        <label className="custom-control-label" htmlFor="isPrimary">Is Primary?</label>
                    </div>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="ClientId">Client ID</label>
                        <input id="ClientId" type="text"  className="form-control form-control-xs" value={clientId}  disabled/>
                    </div>
                    </div> 
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address1">Address Line1</label>
                        <input id="input_Address1" type="text"  className="form-control form-control-xs" value={address1}  disabled/>
                    </div>

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address2">Address Line2(Opt)</label>
                        <input id="input_Address2" type="text"  className="form-control form-control-xs" value={address2}  disabled/>
                    </div>
                    </div>
                    <div className="form-row">

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_City">Town/City</label>
                        <input id="input_City" type="text"  className="form-control form-control-xs" value={city}  disabled/>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Zip">Post Code/ZIP</label>
                        <input id="input_Zip" type="text"  className="form-control form-control-xs" value={zipcode}  disabled/>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_PhoneNumber">Phone Number</label>
                        <input id="input_PhoneNumber" type="text"  className="form-control form-control-xs" value={phone}  disabled/>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Email">Email Address</label>
                        <input id="input_Email" type="email"  className="form-control form-control-xs" value={email}  disabled/>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Region">Region</label>
                        <input id="input_Region" type="text"  className="form-control form-control-xs" value={region}  disabled/>   
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Country">Country</label>
                        <input id="input_Country" type="text"  className="form-control form-control-xs" value={country}  disabled/>   
                    </div>
                    </div>
                    </>)}
                    {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )}  
                                     
                    </form>
                    <div className="modal-footer">
                    <img className="mr-auto" src="dist/img/ACA-logo.png" alt='ACA logo' width="150"/>
                    <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                    <button className="btn btn-success mt-auto" type="button" onClick={addSponsors}>Add</button>
                  </div>
                  </div>
    );
};

export default AddSponsorsToVerifier;