import React from 'react';
import LoginNavBar from '../../Login/LoginNavBar';
import Footer from '../../../Layout/Footer';
import VerifierRegistrationForm from './VerifierRegistrationForm';

const VerifierRegistration = () => {

    return (
        <div>
            <div className="be-wrapper be-offcanvas-menu be-fixed-sidebar be-color-header vh-100">
                <LoginNavBar />
                <div className="be-content">
                    <div className="main-content container-fluid p-0">
                        <VerifierRegistrationForm />
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );

};

export default VerifierRegistration;