import React from 'react'
import FormGroup from './FormGroup';

const FormRow = ({ formRow, state, setState, setHasRegionChanged, submitButtonClicked,isEvidenceCheck,setIsEvidenceCheck}) => {

    return (
        <div className="form-row">
        {
            formRow.map((item, index) => {
                return <FormGroup key={index} label={item.label} isRequired={item.isRequired} disabled={item.disabled} inputType={item.inputType} value={item.value} isValid={item.isValid} allowSpecialCharacters={item.allowSpecialCharacters} minLimit={item.minLimit} maxLimit={item.maxLimit} defaultValue={item.defaultValue} options={item.options} state={state} setState={setState} setHasRegionChanged={setHasRegionChanged} hasInvalidFileExtension={item.hasInvalidFileExtension} fileSizeLimitExceeded={item.fileSizeLimitExceeded} submitButtonClicked={submitButtonClicked} files={item.files} collectivefileSizeLimitExceeded ={item.collectivefileSizeLimitExceeded} isDuplicate={item.isDuplicate} isEvidenceCheck={isEvidenceCheck} setIsEvidenceCheck={setIsEvidenceCheck} isToolTipRequired={item.isToolTipRequired} customErrorMessage={item.customErrorMessage}/>
            })
        }
        </div>
    );

};

export default FormRow