import React, { useState, useEffect } from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import axios from '../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Select from "react-select";

const AddVerifierAndSponsor = ({ toggler,toggler1 ,refetchs, setRefetchs }) => {
    const navigate = useNavigate();
    {/* STATES */ }
    const [refetch, setRefetch] = useState(false);
    const [VcountryList, setVCountryList] = useState([]);
    const [ScountryList, setSCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [VerifierClientID, setVerifierClientID] = useState(0);
    const [verifierName, setverifierName] = useState("");
    const [Vaddress1, setVaddress1] = useState("");
    const [Vaddress2, setVaddress2] = useState("");
    const [Vcity, setVcity] = useState("");
    const [Vzipcode, setVzipcode] = useState("");
    const [Vphone, setVphone] = useState("");
    const [Vemail, setVemail] = useState("");
    const [Vcountry, setVcountry] = useState("");
    const [Vregion, setVregion] = useState("");
    const [sponsorType, setSponsorType] = useState("");
    const [dojp, setdojp] = useState("");
    const [dateJoinedP, setdateJoinedP] = useState();
    const [isSponsor, setIsSponsor] = useState(false);

    const [SponsorClientID, setSponsorClientID] = useState(0);
    const [sponsorName, setSponsorName] = useState("");
    const [Saddress1, setSaddress1] = useState("");
    const [Saddress2, setSaddress2] = useState("");
    const [Scity, setScity] = useState("");
    const [Szipcode, setSzipcode] = useState("");
    const [Sphone, setSphone] = useState("");
    const [Semail, setSemail] = useState("");
    const [Scountry, setScountry] = useState("");
    const [Sregion, setSregion] = useState("");

    const [clientId, setClientId] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [country, setcountry] = useState("");
    const [region, setregion] = useState("");
    const [selectKey, setSelectKey] = useState(0);
    

    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");

    const [isSponsorSelected, setIsSponsorSelected] = useState(false);
    const [allSponsorlist, setAllSponsorList] = useState([]);
    const [sponsor, setSponsor] = useState(null);



// CountryList by region to bind dropdown for verifier

const getVCountryList = async (Vregion) => {
    try{
    const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryList}${Vregion}`, {
            method: 'GET',
            headers: {

                Authorization: `Bearer ${localStorage.getItem("token")}`

            }
        });
        if (response.ok) {
            var result = await response.json();
            setVCountryList(result);
        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getCountryList}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    } 

              
// CountryList by region to bind dropdown for sponsor

const getSCountryList = async (Sregion) => {

    try{
      const response =  await fetch(`${config.apiUrl}${apiconfig.getCountryList}${Sregion}`, {
            method: 'GET',
            headers: {

                Authorization: `Bearer ${localStorage.getItem("token")}`

            }
        });
        if (response.ok) {
            var result = await response.json();
            setSCountryList(result);
        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getCountryList}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    } 
    
 // RegionList to bind dropdown

 const getVRegionList = async (Vcountry) => {

    try{
      const response =  await fetch(`${config.apiUrl}${apiconfig.getRegionList}${Vcountry}`, {
            method: 'GET',
            headers: {

                Authorization: `Bearer ${localStorage.getItem("token")}`

            }
        });
        if (response.ok) {
            var result = await response.json();
            console.log(result);
            if(result.length===2){
                setVregion(result[1].value);
                //setSregion(result[1].value);
            }
            //setRegionList(result);
        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getRegionList}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }

const getSRegionList = async (Scountry) => {

        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getRegionList}${Scountry}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                var result = await response.json();
                if(result.length===2){
                    // setVregion(result[1].value);
                    setSregion(result[1].value);
                }
                //setRegionList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getRegionList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }    

const getAllRegionList = async () => {

            try{
              const response =  await fetch(`${config.apiUrl}${apiconfig.getRegionList}${0}`, {
                    method: 'GET',
                    headers: {
    
                        Authorization: `Bearer ${localStorage.getItem("token")}`
    
                    }
                });
                if (response.ok) {
                    var result = await response.json();
                    setRegionList(result);
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.getRegionList}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
                }catch(error){
                    console.log(error.message);
                    navigate(`/error-page`); 
                }
            }     

    useEffect(() => {
        if(Vcountry===''){
        getAllRegionList();
        getVRegionList(0);
        }else{
        getAllRegionList();
        getVRegionList(Vcountry);
        }  

    }, [refetch,Vcountry]);

    useEffect(() => {
        if(Scountry===''){
        getAllRegionList();
        getSRegionList(0);
        }else{
        getAllRegionList();
        getSRegionList(Scountry);
        }  

    }, [refetch,Scountry]);

    useEffect(() => {
        if(Vregion===''){
            getVCountryList(0);
        }else{
            getVCountryList(Vregion);
        }
    }, [refetch,Vregion]);

    useEffect(() => {
        if(Sregion===''){
            getSCountryList(0);
        }else{
            getSCountryList(Sregion);
        }
    }, [refetch,Sregion]);


    
         

    {/*Validation*/}
    const validateForm = (verifierName, address1, address2, city,zipcode,email,phone,region,country,dojp,sponsorName, saddress1, saddress2, scity,szipcode,semail,sphone,sregion,scountry,selectSponsorName,SponsorClientID) => {

        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!verifierName){
            errors.verifierName = 'Please enter a value.';
        } else if(verifierName.length > 256){
            errors.verifierName = 'Maximum of 256 characters allowed.';
        }

        if(!address1){
            errors.address1 = 'Please enter a value.';
        }else if(address1.length > 256){
            errors.address1 = 'Maximum of 256 characters allowed.';
        }
        // else if (!/^[a-zA-Z0-9\s]+$/.test(address1)) {
        //     errors.address1 = 'Special characters are not allowed.';
        //   }

        if(address2.length > 0){
        if(address2.length > 256){
            errors.address2 = 'Maximum of 256 characters allowed.';
        }
        }

        if(!city){
            errors.city = 'Please enter a value.';
        }else if(city.length > 256){
            errors.city = 'Maximum of 256 characters allowed.';
        }

        if(!zipcode){
            errors.zipcode = 'Please enter a value.';
        }else if(zipcode.length > 256){
            errors.zipcode = 'Maximum of 256 characters allowed.';
        }

        if(!email){
            errors.email = 'Please enter a value.';
        }
        else if(!emailRegex.test(email)){
            errors.email = 'Please enter a valid email address.';
        }

        if(!phone){
            errors.phone = 'Please enter a value.';
        }else if(phone.length > 15){
            errors.phone = 'Maximum of 15 characters allowed.';
        }else {
            const phoneRegex = /^\+[0-9]+([\s-]?[0-9]+)*$/;
            if (!phoneRegex.test(phone)) {
              errors.phone = 'Please enter a valid phone number.';
            }
        }
        if(!country){
            errors.country = 'Please select a value.';
        }

        if(!region){
            errors.region = 'Please select a value.';
        }

        if(!dojp){
            errors.dojp = 'Please select a date.';
        }

        if (sponsorType === 'Independent') {

        if(SponsorClientID.length > 10){
            errors.SponsorClientID = 'Maximum of 10 characters allowed.';
        }
        if(!sponsorName){
            errors.sponsorName = 'Please enter a value.';
        } else if(sponsorName.length > 256){
            errors.sponsorName = 'Maximum of 256 characters allowed.';
        }

        if(!saddress1){
            errors.saddress1 = 'Please enter a value.';
        }else if(saddress1.length > 256){
            errors.saddress1 = 'Maximum of 256 characters allowed.';
        }
        // else if (!/^[a-zA-Z0-9\s]+$/.test(saddress1)) {
        //     errors.saddress1 = 'Special characters are not allowed.';
        //   }

        if(saddress2.length > 0){
        if(saddress2.length > 256){
            errors.saddress2 = 'Maximum of 256 characters allowed.';
        }
        }

        if(!scity){
            errors.scity = 'Please enter a value.';
        }else if(scity.length > 256){
            errors.scity = 'Maximum of 256 characters allowed.';
        }

        if(!szipcode){
            errors.szipcode = 'Please enter a value.';
        }else if(szipcode.length > 256){
            errors.szipcode = 'Maximum of 256 characters allowed.';
        }

        if(!semail){
            errors.semail = 'Please enter a value.';
        }
        else if(!emailRegex.test(semail)){
            errors.semail = 'Please enter a valid email address.';
        }

        if(!sphone){
            errors.sphone = 'Please enter a value.';
        }else {
            const phoneRegex = /^\+[0-9]+([\s-]?[0-9]+)*$/;
            if (!phoneRegex.test(sphone)) {
              errors.sphone = 'Please enter a valid phone number.';
            }
          }
        if(!scountry){
            errors.scountry = 'Please select a value.';
        }

        if(!sregion){
            errors.sregion = 'Please select a value.';
        }

    }else if(sponsorType === 'Non- Independent' && isSponsor === true){

        if(SponsorClientID.length > 10){
            errors.SponsorClientID = 'Maximum of 10 characters allowed.';
        }
        if(!sponsorName){
            errors.sponsorName = 'Please enter a value.';
        } else if(sponsorName.length > 256){
            errors.sponsorName = 'Maximum of 256 characters allowed.';
        }

        if(!saddress1){
            errors.saddress1 = 'Please enter a value.';
        }else if(saddress1.length > 256){
            errors.saddress1 = 'Maximum of 256 characters allowed.';
        }
        // else if (!/^[a-zA-Z0-9\s]+$/.test(saddress1)) {
        //     errors.saddress1 = 'Special characters are not allowed.';
        //   }

        if(saddress2.length > 0){
        if(saddress2.length > 256){
            errors.saddress2 = 'Maximum of 256 characters allowed.';
        }
        }

        if(!scity){
            errors.scity = 'Please enter a value.';
        }else if(scity.length > 256){
            errors.scity = 'Maximum of 256 characters allowed.';
        }

        if(!szipcode){
            errors.szipcode = 'Please enter a value.';
        }else if(szipcode.length > 256){
            errors.szipcode = 'Maximum of 256 characters allowed.';
        }

        if(!semail){
            errors.semail = 'Please enter a value.';
        }
        else if(!emailRegex.test(semail)){
            errors.semail = 'Please enter a valid email address.';
        }

        if(!sphone){
            errors.sphone = 'Please enter a value.';
        }else {
            const phoneRegex = /^\+[0-9]+([\s-]?[0-9]+)*$/;
            if (!phoneRegex.test(sphone)) {
              errors.sphone = 'Please enter a valid phone number.';
            }
          }
        if(!scountry){
            errors.scountry = 'Please select a value.';
        }

        if(!sregion){
            errors.sregion = 'Please select a value.';
        }

    }else if(sponsorType === 'Non- Independent' && isSponsor === false){
        if(!selectSponsorName){
            errors.selectSponsorName = 'Please select a value.';
        }
    }
        return errors;
    }


    // Add Verifier
    const createVerifiers = async () => {
        setErrorMessage("");
        const requestBody = {
            verifierDetailID : 0,
            verifierName: verifierName.trim(),
            clientID : 0,
            countryID:Vcountry,
            regionID:Vregion,
            email:Vemail.trim(),
            phoneNumber:Vphone.trim(),
            addressLine1: Vaddress1.trim(),
            addressLine2: Vaddress2.trim(),
            city: Vcity.trim(),
            zipcode:Vzipcode.trim(),
            verifierStatusID : 0,
            applicationReceiveDate:dojp,
            // dateJoinedProgram : dateJoinedP,
            userID : userID,
            message :'',
            sponsorType: sponsorType,
            sponsorName: sponsorName.trim(),
            sponsorID: sponsor ? sponsor : 0,
            sClientID: SponsorClientID ? SponsorClientID : 0,
            sCountryID: Scountry ? Scountry : 0,
            sRegionID: Sregion ? Sregion : 0,
            sEmail: Semail.trim(),
            sPhoneNumber: Sphone.trim(),
            sAddressLine1: Saddress1.trim(),
            sAddressLine2: Saddress2.trim(),
            sCity: Scity.trim(),
            sZipcode: Szipcode.trim(),

        };
        const validationErrors = validateForm(verifierName.trim(), Vaddress1.trim(), Vaddress2.trim(), Vcity.trim(),Vzipcode.trim(),Vemail.trim(),Vphone.trim(),Vregion,Vcountry,dojp,sponsorName.trim(), Saddress1.trim(), Saddress2.trim(), Scity.trim(),Szipcode.trim(),Semail.trim(),Sphone.trim(),Sregion,Scountry,sponsor,SponsorClientID);

            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.addVerifier}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              });
              if (response.ok) {
                var result = await response.json();
                if(result.saveErrorMessage === 'Verifier saved successfully.')
                {
                    toast.success('Verifier saved successfully.', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Slide
                    }); 
                setRefetchs(!refetchs);
                toggler();
                }else{
   
                    setErrorMessage(result.saveErrorMessage);
                }
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.addVerifier}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
              
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setdojp(value);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      };


      const handleVRegionChange = (e) => {
        setVregion(e.target.value);
        if(e.target.value ===''){
            setVcountry('');
        }
        if(sponsorType === 'Independent'){
            handleSRegionChange(e)
          }
      };

      const handleVCountryChange = (e) => {
        setVcountry(e.target.value);
        if(e.target.value ===''){
            setVregion('');
        }
        if(sponsorType === 'Independent'){
            //setScountry(e.target.value);
            handleSCountryChange(e);
          }
      };


      const handleSRegionChange = (e) => {
        setSregion(e.target.value);
        if(e.target.value ===''){
            setScountry('');
        }
      };

      const handleSCountryChange = (e) => {
        setScountry(e.target.value);
        if(e.target.value ===''){
            setSregion('');
        }
      };
      
      const isNumber = (event) => {
        const keyCode = event.keyCode || event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 9) {
          event.preventDefault();
        }
      };

      // SponsorType options
      const attemptOptions = [
        { text: 'Please Select Sponsor Type...', value: '' },
        { text: 'Independent', value: 'Independent'},
        { text: 'Non- Independent', value: 'Non- Independent'}
      ];

      // All Active Sponsor List
      function getAllSponsorList() {

        fetch(`${config.apiUrl}${apiconfig.getAllSponsor}`, {
            method: 'GET',
            headers: {

                Authorization: `Bearer ${localStorage.getItem("token")}`

            }
        }).then(res => res.json())
            .then((data) => {
                let fetchedSponsor = [];
                for (let i = 0; i < data.length; i++) {
                    fetchedSponsor.push(data[i]);
                  }
                const option = fetchedSponsor.map((spon) => ({
                    label: spon.text,
                    value: spon.value,
                  }));
                setAllSponsorList(option);
            }).catch((err) => {
                console.log("Error: ", err);
                navigate("/error-page");
            });
    }
      useEffect(() => {
        getAllSponsorList();
    }, []);

         //get SponsorDetailsByID
         const  getSponsorDetailsByID = (selID)=> {
            fetch(`${config.apiUrl}${apiconfig.getSponsorDetailsByID}${selID}`,{
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                  },
            })
                .then((response) => response.json())
                .then((data) => {
                    setClientId(data.clientID);
                    setaddress1(data.addressLine1);
                    setaddress2(data.addressLine2);
                    setcity(data.city);
                    setzipcode(data.zipcode);
                    setphone(data.phoneNumber);
                    setemail(data.email);
                    setcountry(data.country);
                    setregion(data.region);
          
                }).catch((err) => {
                    navigate("/error-page");
                });
          }

    // Handle on Sponsor Change
    const handleSponsorChange = (e) => {
        setIsSponsor(false);
        setSponsor(e.value);
        getSponsorDetailsByID(e.value);
        setIsSponsorSelected(true);
        setErrorMessage("");
        setSponsorClientID('');
        setSponsorName('');
        setSaddress1('');
        setSaddress2('');
        setScity('');
        setSzipcode('');
        setSphone('');
        setSemail('');
        setSregion('');
        setScountry('');
      };

// Handle on Add Sponsor in Non-Independent Case
    const handleAddSponsor = () => {
        setFormErrors({});
        setIsSponsorSelected(false)
        setIsSponsor(true);
        setSelectKey(selectKey + 1); 
        getAllSponsorList(); 
        setSponsorClientID(''); 
        setSponsorName('');
        setSaddress1('');
        setSaddress2('');
        setScity('');
        setSzipcode('');
        setSphone('');
        setSemail('');
        setSregion('');
        setScountry('');
        //setSCountryList([]);

    };

// Handle on Sponsor Type change
    const handleSponsorType = (e) => {
        const newSponsorType = e.target.value;
        setFormErrors({});
        setErrorMessage("");
        setSponsorType(e.target.value);
        setIsSponsorSelected(false);
        copyValue();
        setIsSponsor(false);
        if(newSponsorType === '' || newSponsorType === 'Non- Independent'){
            setSponsorClientID('');
            setSponsorName('');
            setSaddress1('');
            setSaddress2('');
            setScity('');
            setSzipcode('');
            setSphone('');
            setSemail('');
            setSregion('');
            setScountry('');
        }

    };

    const copyValue = () => {
        // VerifierClientID === 0 ? setSponsorClientID('') : setSponsorClientID(VerifierClientID);
        setSponsorName(verifierName);
        setSaddress1(Vaddress1);
        setSaddress2(Vaddress2);
        setScity(Vcity);
        setSzipcode(Vzipcode);
        setSphone(Vphone);
        setSemail(Vemail);
        setSregion(Vregion);
        setScountry(Vcountry);

      };

      useEffect(()=>{
        var todayDate = new Date();
        var formattedDate = todayDate.toISOString().split('T')[0];
        setdojp(formattedDate);
      },[])

    return (
        <div>
            <form>

                <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_VerifierName">Verifier Name <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Verifier Name...' className="form-control form-control-xs" id="input_VerifierName" onChange={(e) => { setverifierName(e.target.value); sponsorType === 'Independent' && (setSponsorName(e.target.value)) }} />
                        <p style={{ color: 'red' }}>{formErrors.verifierName}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address1">Address Line 1 <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Address Line 1...' className="form-control form-control-xs" id="input_Address1" onChange={(e) => { setVaddress1(e.target.value); sponsorType === 'Independent' && (setSaddress1(e.target.value)) }}/>
                        <p style={{color:'red'}}>{formErrors.address1}</p>
                    </div>

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address2">Address Line 2(Opt)</label>
                        <input type="text" placeholder='Please Enter Address Line 2...' className="form-control form-control-xs" id="input_Address2" onChange={(e) => { setVaddress2(e.target.value); sponsorType === 'Independent' && (setSaddress2(e.target.value)) }}/>
                        <p style={{color:'red'}}>{formErrors.address2}</p>
                    </div>
                    </div>
                    <div className="form-row">

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_City">Town/City <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Town/City...' className="form-control form-control-xs" id="input_City" onChange={(e) => { setVcity(e.target.value); sponsorType === 'Independent' && (setScity(e.target.value)) }}/>
                        <p style={{color:'red'}}>{formErrors.city}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Zip">Post Code/ZIP <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter PostCode/ZIP...' className="form-control form-control-xs" id="input_Zip" onChange={(e) => { setVzipcode(e.target.value); sponsorType === 'Independent' && (setSzipcode(e.target.value)) }}/>
                        <p style={{color:'red'}}>{formErrors.zipcode}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_PhoneNumber">Phone Number <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Phone Number...' className="form-control form-control-xs" id="input_PhoneNumber" onChange={(e) => { setVphone(e.target.value); sponsorType === 'Independent' && (setSphone(e.target.value)) }}/>
                        <p style={{color:'red'}}>{formErrors.phone}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Email">Email Address <span className="text-danger font-weight-bold">*</span></label>
                        <input type="email" placeholder='Please Enter Email Address...' className="form-control form-control-xs" id="input_Email" onChange={(e) => { setVemail(e.target.value); sponsorType === 'Independent' && (setSemail(e.target.value)) }}/>
                        <p style={{color:'red'}}>{formErrors.email}</p>
                        {errorMessage === 'This email address is already linked with an existing user account, please check the Admin section.' ? (
                            <p style={{ color: "red" }}>{errorMessage}</p>)
                            :
                            <></>
                        }
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                    <label htmlFor="input_Region">Region <span className="text-danger font-weight-bold">*</span></label>
                        <select  className="form-control form-control-sm" value={Vregion}  onChange={handleVRegionChange}>
                            {regionList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.region}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Country">Country <span className="text-danger font-weight-bold">*</span></label>
                        <select  className="form-control form-control-sm" value={Vcountry} onChange={handleVCountryChange}>
                            {VcountryList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.country}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_DJP">Application Received Date <span className="text-danger font-weight-bold">*</span></label>
                        <input type="date" className="form-control form-control-xs" max={new Date().toISOString().split('T')[0]}  name ='dojp' value ={dojp} id="input_DJP" onChange={handleInputChange}/>
                        <p style={{color:'red'}}>{formErrors.dojp}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="sponsor_Type">Sponsor Type <span className="text-danger font-weight-bold"></span></label>
                        <select  className="form-control form-control-sm" value={sponsorType}  onChange={handleSponsorType} >
                            {attemptOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                    </select>
                    </div>
                    </div>
                    {errorMessage === 'Verifier already exists.' ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    ) : (
                    <p></p>
                    )}
            </form>
            

            {sponsorType === 'Independent' && (
            <>
            <h5>Sponsor Details</h5>
            <hr/>
            <form>
                    
                    <div className="form-row">
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="ClientId">Client ID </label>
                            <input type="text" onKeyPress={isNumber} placeholder='Please Enter Client ID...'  className="form-control form-control-xs" id="ClientId" onChange={(e) => { setSponsorClientID(e.target.value) }}/>
                            <p style={{color:'red'}}>{formErrors.SponsorClientID}</p>
                        </div>
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="SponsorName">Sponsor Name <span className="text-danger font-weight-bold">*</span></label>
                            <input type="text" placeholder='Please Enter Sponsor Name...' value={sponsorName} className="form-control form-control-xs" id="SponsorName"  onChange={(e) => { setSponsorName(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.sponsorName}</p>
                        </div>
                        </div>
                        <div className="form-row">
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="input_Address1">Address Line 1 <span className="text-danger font-weight-bold">*</span></label>
                            <input type="text" placeholder='Please Enter Address Line 1...' value={Saddress1} className="form-control form-control-xs" id="input_Address1" onChange={(e) => { setSaddress1(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.saddress1}</p>
                        </div>
    
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="input_Address2">Address Line 2(Opt)</label>
                            <input type="text" placeholder='Please Enter Address Line 2...' value={Saddress2} className="form-control form-control-xs" id="input_Address2" onChange={(e) => { setSaddress2(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.saddress2}</p>
                        </div>
                        </div>
                        <div className="form-row">
    
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="input_City">Town/City <span className="text-danger font-weight-bold">*</span></label>
                            <input type="text" placeholder='Please Enter Town/City...' value={Scity} className="form-control form-control-xs" id="input_City" onChange={(e) => { setScity(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.scity}</p>
                        </div>
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="input_Zip">Post Code/ZIP <span className="text-danger font-weight-bold">*</span></label>
                            <input type="text" placeholder='Please Enter PostCode/ZIP...' value={Szipcode} className="form-control form-control-xs" id="input_Zip" onChange={(e) => { setSzipcode(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.szipcode}</p>
                        </div>
                        </div>
                        <div className="form-row">
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="input_PhoneNumber">Phone Number <span className="text-danger font-weight-bold">*</span></label>
                            <input type="text" placeholder='Please Enter Phone Number...' value={Sphone} className="form-control form-control-xs" id="input_PhoneNumber" onChange={(e) => { setSphone(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.sphone}</p>
                        </div>
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="input_Email">Email Address <span className="text-danger font-weight-bold">*</span></label>
                            <input type="email" placeholder='Please Enter Email Address...' value={Semail} className="form-control form-control-xs" id="input_Email" onChange={(e) => { setemail(e.target.value) }} />
                            <p style={{color:'red'}}>{formErrors.semail}</p>
                        </div>
                        </div>
                        <div className="form-row">
                        <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Region">Region <span className="text-danger font-weight-bold">*</span></label>
                            <select id="input_Region" className="form-control form-control-sm" value={Sregion}  onChange={handleSRegionChange} > 
                                {regionList.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.text}
                                    </option>
                                ))}
                            </select>
                            <p style={{color:'red'}}>{formErrors.sregion}</p>
                        </div>
                        <div className="form-group col-md-6 mb-1">
                            <label htmlFor="input_Country">Country <span className="text-danger font-weight-bold">*</span></label>
                            <select id="input_Country" className="form-control form-control-sm" value={Scountry} onChange={handleSCountryChange} >
                                {ScountryList.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.text}
                                    </option>
                                ))}
                            </select>
                            <p style={{color:'red'}}>{formErrors.scountry}</p>
                        </div>
                        </div>
                        {errorMessage ==='Sponsor already exists.' ? (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      ) : (
                        <p></p>
                      )}                     
            </form>
            </>
            )}

            {sponsorType === 'Non- Independent' && (
            <>
            <h5>Sponsor Details</h5>
            <hr/>
            <form>
                    
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="SponsorName">Sponsor Name <span className="text-danger font-weight-bold">*</span></label>
                         <Select
                            options={allSponsorlist}
                            onChange={handleSponsorChange}
                            placeholder="Select a sponsor"
                            isSearchable
                            key={selectKey}
                        />
                        <p style={{color:'red'}}>{formErrors.selectSponsorName}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1 d-flex align-items-center">
                        <span className='mr-2'><b>or</b></span>
                        <button className="btn btn-success" type="button" onClick={handleAddSponsor}>Add Sponsor</button>
                    </div>
                    </div>
                    {isSponsorSelected && (
                        <>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1 d-flex align-items-end">                    
                    <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox" id="isPrimary"  checked disabled/>
                        <label className="custom-control-label" htmlFor="isPrimary">Is Primary?</label>
                    </div>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="ClientId">Client ID</label>
                        <input type="text"  className="form-control form-control-xs" value={clientId}  disabled/>
                    </div>
                    </div> 
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address1">Address Line 1</label>
                        <input type="text"  className="form-control form-control-xs" value={address1}  disabled/>
                    </div>

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address2">Address Line 2(Opt)</label>
                        <input type="text"  className="form-control form-control-xs" value={address2}  disabled/>
                    </div>
                    </div>
                    <div className="form-row">

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_City">Town/City</label>
                        <input type="text"  className="form-control form-control-xs" value={city}  disabled/>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Zip">Post Code/ZIP</label>
                        <input type="text"  className="form-control form-control-xs" value={zipcode}  disabled/>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_PhoneNumber">Phone Number</label>
                        <input type="text"  className="form-control form-control-xs" value={phone}  disabled/>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Email">Email Address</label>
                        <input type="email"  className="form-control form-control-xs" value={email}  disabled/>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Region">Region</label>
                        <input type="text"  className="form-control form-control-xs" value={region}  disabled/>   
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Country">Country</label>
                        <input type="text"  className="form-control form-control-xs" value={country}  disabled/>   
                    </div>
                    </div>
                    </>)}
                    {errorMessage  ==='Sponsor already exists.'  ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )}  
                                     
            </form>
            {isSponsor && (
            <form>
                    
<div className="form-row">
    <div className="form-group col-md-6 mb-1">
        <label htmlFor="ClientId">Client ID </label>
        <input type="text" onKeyPress={isNumber} placeholder='Please Enter Client ID...'  className="form-control form-control-xs" id="ClientId" onChange={(e) => { setSponsorClientID(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.SponsorClientID}</p>
    </div>
    <div className="form-group col-md-6 mb-1">
        <label htmlFor="SponsorName">Sponsor Name <span className="text-danger font-weight-bold">*</span></label>
        <input type="text" placeholder='Please Enter Sponsor Name...' className="form-control form-control-xs" id="SponsorName" onChange={(e) => { setSponsorName(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.sponsorName}</p>
    </div>
    </div>
    <div className="form-row">
    <div className="form-group col-md-6 mb-1">
        <label htmlFor="input_Address1">Address Line 1 <span className="text-danger font-weight-bold">*</span></label>
        <input type="text" placeholder='Please Enter Address Line 1...' className="form-control form-control-xs" id="input_Address1" onChange={(e) => { setSaddress1(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.saddress1}</p>
    </div>

    <div className="form-group col-md-6 mb-1">
        <label htmlFor="input_Address2">Address Line 2(Opt)</label>
        <input type="text" placeholder='Please Enter Address Line 2...' className="form-control form-control-xs" id="input_Address2" onChange={(e) => { setSaddress2(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.saddress2}</p>
    </div>
    </div>
    <div className="form-row">

    <div className="form-group col-md-6 mb-1">
        <label htmlFor="input_City">Town/City <span className="text-danger font-weight-bold">*</span></label>
        <input type="text" placeholder='Please Enter Town/City...' className="form-control form-control-xs" id="input_City" onChange={(e) => { setScity(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.scity}</p>
    </div>
    <div className="form-group col-md-6 mb-1">
        <label htmlFor="input_Zip">Post Code/ZIP <span className="text-danger font-weight-bold">*</span></label>
        <input type="text" placeholder='Please Enter PostCode/ZIP...' className="form-control form-control-xs" id="input_Zip" onChange={(e) => { setSzipcode(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.szipcode}</p>
    </div>
    </div>
    <div className="form-row">
    <div className="form-group col-md-6 mb-1">
        <label htmlFor="input_PhoneNumber">Phone Number <span className="text-danger font-weight-bold">*</span></label>
        <input type="text" placeholder='Please Enter Phone Number...' className="form-control form-control-xs" id="input_PhoneNumber" onChange={(e) => { setSphone(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.sphone}</p>
    </div>
    <div className="form-group col-md-6 mb-1">
        <label htmlFor="input_Email">Email Address <span className="text-danger font-weight-bold">*</span></label>
        <input type="email" placeholder='Please Enter Email Address...' className="form-control form-control-xs" id="input_Email" onChange={(e) => { setSemail(e.target.value) }}/>
        <p style={{color:'red'}}>{formErrors.semail}</p>
    </div>
    </div>
    <div className="form-row">
    <div className="form-group col-md-6 mb-1">
    <label htmlFor="input_Region">Region <span className="text-danger font-weight-bold">*</span></label>
        <select id="input_Region" className="form-control form-control-sm" value={Sregion}  onChange={handleSRegionChange}>
            {regionList.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
        <p style={{color:'red'}}>{formErrors.sregion}</p>
    </div>
    <div className="form-group col-md-6 mb-1">
        <label htmlFor="input_Country">Country <span className="text-danger font-weight-bold">*</span></label>
        <select id="input_Country" className="form-control form-control-sm" value={Scountry} onChange={handleSCountryChange}>
            {ScountryList.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.text}
                </option>
            ))}
        </select>
        <p style={{color:'red'}}>{formErrors.scountry}</p>
    </div>
    </div>
    {isSponsor && (errorMessage ==='Sponsor already exists.') ? (
    <p style={{ color: "red" }}>{errorMessage}</p>
  ) : (
    <p></p>
  )}                     
            </form>
            )}
            </>
              )}    

        

            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt="ACA Logo"></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={createVerifiers}>Submit</button>
            </div>
            </div>
    );
};

export default AddVerifierAndSponsor;