import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import axios from '../../../api/AxiosConfig'
import apiconfig from '../../../api/apiconfig'
import { BsEyeSlash, BsEye } from "react-icons/bs"
import { Slide, ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useNavigate } from 'react-router-dom'
import { useSignOut } from 'react-auth-kit'

const ChangePassword = () => {
    const navigate = useNavigate()
    const signOut = useSignOut()
    const userRoles = JSON.parse(localStorage.getItem("roles"))


    let FirstLogin = 0
    if (userRoles.length === 1) {
        userRoles.map((role) => {
            // for what purpose?
            if ((role.name === "Airport User" || role.name === "Verifier User") && role.isfirstlogin === 0) {
                FirstLogin = 1
            }
        })
    }

    {/* STATES */ }
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [visible, setVisible] = useState(false)
    const [visibleNewPassword, setVisibleNewPassword] = useState(false)
    const [visibleConfirmNewPassword, setVisibleConfirmNewPassword] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    const [processingPasswordChangeRequest, setProcessingPasswordChangeRequest] = useState(false)
    const [passwordChangeRequestProcessed, setPasswordChangeRequestProcessed] = useState(false)

    const loggedInUserId = localStorage.getItem('userID')
    const email = localStorage.getItem('eMail')
    const password = localStorage.getItem('passString')

    const onLogoutButtonClick = () => {
        signOut()
        localStorage.removeItem("roles")
        localStorage.removeItem("userID")
        localStorage.removeItem("userName")
        localStorage.removeItem("userDisplayName")
        localStorage.removeItem("eMail")
        localStorage.removeItem("passString")
        localStorage.removeItem("token")
        navigate('/login')
    }

    // const changeUserPassword = async () =>{

    //     try {
    //         if(loggedInUserId > 0){ 
    //             const response = await axios.post(`${apiconfig.changeUserPassword}`,{
    //                     "userID": loggedInUserId, 
    //                     "createdUserID": loggedInUserId,
    //                     "newPassword": newPassword
    //             },
    //             {
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Authorization': `Bearer ${localStorage.getItem("token")}`
    //                   },

    //             })
    //             // .then((response) => {
    //                 if(response.status === 200){                    
    //                     toast.success('Password has been successfully updated.', {
    //                         position: "top-right",
    //                         autoClose: 3000,
    //                         hideProgressBar: false,
    //                         closeOnClick: true,
    //                         pauseOnHover: true,
    //                         draggable: true,
    //                         progress: undefined,
    //                         theme: "light",
    //                         transition: Slide
    //                     }); 
    //                     setTimeout(function(){
    //                         return true;
    //                     }, 3000);                                         
    //                 }
    //                 else{
    //                     return false;
    //                 }
    //             // })                            
    //         }
    //     } 
    //     catch (error) {
    //         console.log(error);
    //         return false;
    //     }
    // }

    const changePasswordButtonClick = async () => {
        
        // set the `processingPasswordChangeRequest` as true since password change request has initiated
        setProcessingPasswordChangeRequest(true)
        
        const validationErrors = validateForm(currentPassword, newPassword)
        setFormErrors(validationErrors)
        if (Object.keys(validationErrors).length === 0) {
            try {
                if (loggedInUserId > 0) {
                    const response = await axios.post(`${apiconfig.changeUserPassword}`, {
                            "userID": loggedInUserId,
                            "createdUserID": loggedInUserId,
                            "newPassword": newPassword
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${localStorage.getItem("token")}`
                            }
                        })
                    // .then((response) => {
                    if (response.status === 200) {
                        toast.success('Password has been successfully updated.', {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide
                        })
                        setTimeout(function () {
                            onLogoutButtonClick()
                            //return true;
                        }, 3000)
                    }
                    // else{
                    //     return false;
                    // }
                    // })                            
                }
            }
            catch (error) {
                console.log("Error: ", error)
                navigate("/error-page")
            }
            //   try {            
            //         const result = await changeUserPassword();               
            //         if(result){
            //             //navigate(-1);
            //             onLogoutButtonClick();
            //         }
            //   } catch (error) {
            //     console.log("Error: ", error);
            //     navigate("/error-page");
            //   }
        }

        // set the `passwordChangeRequestProcessed` as true since the password change request is done (regardless of the outcome)
        setPasswordChangeRequestProcessed(true)
    }

    //form validation
    const validateForm = (currentPassword, newPassword) => {

        const errors = {}
        const passwordRegex = new RegExp("^(?=.*[a-z])(?!.*[ @'?+:])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20}$)")

        if (email != '' && password != '' && loggedInUserId > 0) {
            if (!currentPassword) {
                errors.currentPassword = 'Please enter your current Password.'
            }
            if (!newPassword) {
                errors.newPassword = 'New password is required.'
            }
            else if (!passwordRegex.test(newPassword)) {
                errors.newPasswordCriteria = 'New Password must contain one number between 0-9, one lower case character, one upper case character, one special character and be between 8-20 characters long.'
                errors.newPasswordSpecialCharacters = `For New Password special characters: # ~ ! $ % ^ & * () _ = , . / ; [] " <> { } \ | -are allowed and Spaces, @, ', ?, +, : are not allowed.`
            }
            else if (newPassword == password) {
                errors.newPassword = 'New Password can not be same as Current Password.'
            }
            else if (currentPassword != password) {
                errors.currentPassword = 'Please enter your correct Password.'
            }
            if (newPassword != confirmNewPassword) {
                errors.confirmNewPassword = 'New password and Confirm New password do not match.'
            }
        } else {
            errors.sessionExpired = "Your session has expired please login again."
        }
        return errors
    }

    const onCancelButtonClick = () => {
        navigate(-1)
    }

    useEffect(() => {
        /* 
            this effect aids in the disability of form action buttons i.e. 'Cancel' and 'Change Password'
            the disability of action buttons has been made functional via utilizing these two states: `passwordChangeRequestProcessed` and `processingPasswordChangeRequest` 
            this ensures no user intervention to take place while the password change request is being processed
        */

        // check whether the password change request is processed (regardless of the outcome i.e. success or failure) 
        if(passwordChangeRequestProcessed){
            // check whether the `processingPasswordChangeRequest` is true or not
            if(processingPasswordChangeRequest){
                // since the password change request is processed, set the `processingPasswordChangeRequest` to false
                setProcessingPasswordChangeRequest(false)
                // re-set the `passwordChangeRequestProcessed` to true so that it be re-used again
                setPasswordChangeRequestProcessed(false)
            }
        }
    }, [passwordChangeRequestProcessed])

    return (
        <Layout>
            <div className="container-fluid p-0">
                <div className="page-header blue mb-4">
                    <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title"> To change your Password, you will need to confirm your current Password.</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">

                            </div>
                        </div>
                    </div>
                </div>
                <div className="splash-container" style={{ maxWidth:"500px"}}>
                    <div className="card">
                        <div className="card-body ">
                            <form>
                                <p style={{ color: "red", fontStyle: 'italic', fontSize: 15, fontWeight: 'bold' }}>
                                    {formErrors.newPasswordCriteria}
                                    <br />
                                    {formErrors.newPasswordSpecialCharacters}
                                </p>
                                <div className="form-group">
                                    <label className="sr-only" htmlFor="currentPassword">
                                        Current Password
                                    </label>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ position: "relative", flex: 1 }}>
                                            <input
                                                className="form-control form-control-sm"
                                                id="currentPassword"
                                                type={visible ? "text" : "password"}
                                                placeholder="Current Password"
                                                onChange={(e) => {
                                                    setCurrentPassword(e.target.value)
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    right: "10px",
                                                    transform: "translateY(-50%)",
                                                    fontSize: "25px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setVisible(!visible)}
                                            >
                                                {visible ? <BsEye /> : <BsEyeSlash />}
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ color: "red", fontStyle: 'italic', fontSize: 15, fontWeight: 'bold' }}>
                                        {formErrors.currentPassword}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label className="sr-only" htmlFor="newPassword">
                                        New Password
                                    </label>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ position: "relative", flex: 1 }}>
                                            <input
                                                className="form-control form-control-sm"
                                                id="newPassword"
                                                type={visibleNewPassword ? "text" : "password"}
                                                placeholder="New Password"
                                                onChange={(e) => {
                                                    setNewPassword(e.target.value)
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    right: "10px",
                                                    transform: "translateY(-50%)",
                                                    fontSize: "25px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setVisibleNewPassword(!visibleNewPassword)}
                                            >
                                                {visibleNewPassword ? <BsEye /> : <BsEyeSlash />}
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ color: "red", fontStyle: 'italic', fontSize: 15, fontWeight: 'bold' }}>{formErrors.newPassword}</p>
                                </div>
                                <div className="form-group">
                                    <label className="sr-only" htmlFor="confirmNewPassword">
                                        Confirm New Password
                                    </label>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{ position: "relative", flex: 1 }}>
                                            <input
                                                className="form-control form-control-sm"
                                                id="confirmNewPassword"
                                                type={visibleConfirmNewPassword ? "text" : "password"}
                                                placeholder="Confirm New Password"
                                                onChange={(e) => {
                                                    setConfirmNewPassword(e.target.value)
                                                }}
                                                style={{ width: "100%" }}
                                            />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    right: "10px",
                                                    transform: "translateY(-50%)",
                                                    fontSize: "25px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setVisibleConfirmNewPassword(!visibleConfirmNewPassword)}
                                            >
                                                {visibleConfirmNewPassword ? <BsEye /> : <BsEyeSlash />}
                                            </div>
                                        </div>
                                    </div>
                                    <p style={{ color: "red", fontStyle: 'italic', fontSize: 15, fontWeight: 'bold' }}>{formErrors.confirmNewPassword}</p>
                                </div>
                                <div class="form-group row login-submit">
                                    <div class="col-6"><button class="btn btn-secondary btn-lg" id="btnChangePasswordCancelButton"
                                            type="button"
                                            disabled={processingPasswordChangeRequest}
                                            onClick={onCancelButtonClick} >Cancel</button></div>
                                    <div class="col-6"><button class="btn btn-primary btn-lg" id="btnChangePassword"
                                            type="button"
                                            disabled={processingPasswordChangeRequest}
                                            onClick={() => { changePasswordButtonClick() }}>Change Password</button></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </Layout>
    )
}

export default ChangePassword