import React from 'react';

export const TableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Exam ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '08%' },
        isSortable: true,
        sortByColumnName: 'ExamID'
    },
    {
        id: 2,
        isHidden: false,
        name: 'External Exam Reference',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '15%' },
        isSortable: true,
        sortByColumnName: 'ExamExternalID'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Exam Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'ExamStatus'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Verifier Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'VerifierName'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Sponsor Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'SponsorName'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Invoice ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '08%' },
        isSortable: true,
        sortByColumnName: 'InvoiceID'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Invoice Number',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'InvoiceNo'
    },
    {
        id: 8,
        isHidden: false,
        name: 'Created Date',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '14%' },
        isSortable: true,
        sortByColumnName: 'ModifiedDateTime'
    },
    {
        id: 9,
        isHidden: false,
        name: 'Actions',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { width: '5%' },
        isSortable: false,
        sortByColumnName: null
    }
];