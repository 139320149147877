import React from 'react'
import { Slide, toast } from 'react-toastify'

const UploadDocuments = ({ data, subSectionIndex, sectionIndex, state, setState, userType, visibilityBasedOnType }) => {

    const allowedExtensions = ['.bmp', '.csv', '.doc', '.docx', '.htm', '.html', '.jpg', '.jpeg', '.msg', '.pdf', '.png', '.ppt', '.pptx', '.rtf', '.sig', '.tif', '.tiff', '.txt', '.vsd', '.xls', '.xlsx', '.zip']
    
    const updateState = async (action, value, index) => {
        let valid = true
        let content = ''
        if(action === 'add'){
            if (!value || !value.name){
                valid = false
                toast.error('Invalid File!', { position: "top-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "light", transition: Slide }); 
            }
            if (valid && !allowedExtensions.includes('.' + value.name.slice(((value.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase())){
                valid = false
                toast.error('File Format Not Supported', { position: "top-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "light", transition: Slide }); 
            }
            if (valid && value.size > 20 * 1024 * 1024){
                valid = false
                toast.error('File Size Limit Exceeded: Allowed upto 20 MB', { position: "top-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "light", transition: Slide }); 
            }
            if(valid)
                content = await fileToBase64(value).then(result => { return result })        
        }
        
        setState(prevState => {
            const updatedState = {...prevState}
            let documents = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.documents

            switch(action) {
                case 'add':
                    if (valid) {
                        
                        let enGBTime = new Date().toLocaleTimeString('en-GB', { timeZone: 'Europe/London', hour: 'numeric', minute: 'numeric', hour12: true })
                        if(parseInt(enGBTime.charAt(0)) === 0)
                            enGBTime = '12' + enGBTime.slice(1)

                        documents.push({ 
                            id: (documents.length + 1).toString(), 
                            fileName: value.name, 
                            fileSize: value.size.toString(), 
                            content: content, 
                            addedBy: localStorage.getItem("userDisplayName"),
                            addedByUserType: userType === 'draft' ? 'AIRPORT' : (userType === 'verifier' ? 'VERIFIER' : 'ADMIN'),
                            addedAtApplicationStage: state.applicationStatus === 'Draft' ? 'DRAFT STAGE' : (state.applicationStatus === 'Verifier comments - Airport to address' || state.applicationStatus === 'Admin comments - Airport to address' ? 'AIRPORT REVIEW STAGE' : (state.applicationStatus === 'In Verification' || state.applicationStatus === 'Airport resubmitted - In Verification' || state.applicationStatus === 'Admin comments - Verifier to address' ? 'VERIFIER REVIEW STAGE' : 'ADMIN REVIEW STAGE')), 
                            date: new Date().toLocaleDateString('en-GB', { timeZone: 'Europe/London', year: 'numeric', month: 'numeric', day: 'numeric' }), 
                            time: enGBTime, 
                            fileDownloadURL: '' 
                        })
                    }
                    break
    
                case 'remove':
                    documents.splice(index, 1)
                    // re-assign id(s)
                    documents = documents.map((document, document_index) => {
                        return {
                            ...document,
                            id: (document_index + 1).toString()
                        }
                    })
                    break
    
                default:
                    break
            }
            updatedState.sections[sectionIndex].subsections[subSectionIndex].content.documents = documents
            return updatedState
        })
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = function (event) {
                const base64String = event.target.result.split(',')[1] // Extract the base64 data
                resolve(base64String)
            }
            reader.onerror = function (error) {
                reject(error);
            }
            if (file && file.name) {
                reader.readAsDataURL(file)
            } else {
                reject(new Error('Invalid file'))
            }
        })
    }

    return(
        <div className="card card-contrast card-border">
            <div className="card-body px-2 card-body-contrast">
                <h5 className="mt-0">Upload Documents</h5>
                {
                    data.length === 0 
                        ?
                        <label className="text-info">No documents uploaded yet.</label>
                        :
                        data.map((document, index) => {
                            return(
                                <div key={index} className="p-1 mb-1 border rounded">
                                    <div className="media align-items-center">
                                        <span className="mdi mdi-4x mdi-file-text ml-2" />
                                        <div className="media-body ml-3">
                                            <h5 className="m-0">
                                            {
                                                document.fileDownloadURL.length === 0
                                                    ?
                                                    <a className="text-info">{document.fileName}</a>
                                                    :
                                                    <a className="text-info" href={document.fileDownloadURL} target='_blank'>{document.fileName}</a>
                                            }
                                            </h5>
                                            <p className="mb-0">Added by: {document.addedBy} on {document.date} at {document.time}&nbsp;</p>
                                        </div>
                                        <div className="card-option">
                                            <button type="button" className="btn shadow-none" onClick={() => { updateState('remove', '', index) }} style={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? {} : {display: 'none'}}>
                                                <i className="mdi mdi-3x mdi-delete" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                }
                <div className="form-group row" style={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? {} : {display: 'none'}}>
                    <label className="col-12 col-sm-3 col-form-label" htmlFor={`file-section-index#${sectionIndex}-subsection-index#${subSectionIndex}`}>Select File</label>
                    <div className="col-12 col-sm-6">
                        <input className="inputfile" id={`file-section-index#${sectionIndex}-subsection-index#${subSectionIndex}`} type="file" name={`file-section-index#${sectionIndex}-subsection-index#${subSectionIndex}`} onChange={(e) => { updateState('add', e.target.files[0], -1) }} />
                        <label className="btn-secondary" htmlFor={`file-section-index#${sectionIndex}-subsection-index#${subSectionIndex}`}>
                            &nbsp;
                            <i className="mdi mdi-upload" />
                            <span>Browse files...</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadDocuments