import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const EditUserSecondary = ({user, toggler, refetchs, setRefetchs}) => {

  
    const navigate = useNavigate();
    const [emails, setEmails] = useState('');
    const [firstNames, setFirstNames] = useState('');
    const [lastNames, setLastNames] = useState('');
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [formErrors, setFormErrors] = useState({});

    const updateAirportUser = async () => {
      const requestBody = {
        "userID": user.userID,
        "firstName": firstNames,
        "address1": '',
        "address2": '',
        "state": '',
        "cityID": 0,
        "countryID": 0,
        "postalCode": '',
        "lastName": lastNames,
        "email": emails,
        "isPrimary":user.isPrimary,
        "loggedInUserId": userID
      };
      const validationErrors = validateForm(emails,firstNames,lastNames);
          setFormErrors(validationErrors);
          if(Object.keys(validationErrors).length === 0){
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.UpdateAirportUserDetails}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                    if (response.ok) {
                        var result = await response.json();
                        if(result.saveErrorMessage === 'User contact details updated successfully.')
                        {
                            toast.success('User contact details updated successfully.', {
                                position: "top-right",
                                autoClose: 10000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Slide
                            }); 
                        setRefetchs(!refetchs);
                        toggler();
                        }else{
                            setErrorMessage(result.saveErrorMessage);
                        }
                    } else {
                        const errorText = await response.text();
                        const apiname = `${apiconfig.UpdateAirportUserDetails}`;
                        navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                    }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
          }
  }

    {/*Validation*/}
    //Add User form validation
    const validateForm = (email, firstName, lastName) => {
      const errors = {};
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

      if(!email){
        errors.email = 'Please enter a value.';
      }
      else if(!emailRegex.test(email)){
          errors.email = 'Please enter a valid email address.';
      }

      if(!firstName){
        errors.firstName = 'Please enter a value.';
      } else if(firstName.length > 256){
          errors.firstName = 'Maximum of 256 characters allowed.';
      }else if (!/^[a-zA-Z0-9\s]+$/.test(firstName)) {
          errors.firstName = 'Special characters are not allowed.';
      }

      if(!lastName){
          errors.lastName = 'Please enter a value.';
      } else if(lastName.length > 256){
          errors.lastName = 'Maximum of 256 characters allowed.';
      }else if (!/^[a-zA-Z0-9\s]+$/.test(lastName)) {
          errors.lastName = 'Special characters are not allowed.';
      }

      return errors;
  }

      //get DetailsByID
      const getDetailByID = async () => {
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getAirportUserDetails}?UserID=${user.userID}&RequestFrom=${'Secondary'}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
            });
            if (response.ok) {
                var data = await response.json();
                    setEmails(data.email);
                    setFirstNames(data.firstName);
                    setLastNames(data.lastName);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAirportUserDetails}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
      
        useEffect(()=>{
            getDetailByID();
        },[user.userID])

    return (
        <div>
              <form>
              <div className="form-row">
                  <label htmlFor="document">First Name <span className="text-danger font-weight-bold">*</span></label>
                  <input type="text" value={firstNames} className="form-control form-control-xs" placeholder='Please enter the first name...' id="first-name" onChange={(e) => { setFirstNames(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.firstName}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="input_comments">Last Name <span className="text-danger font-weight-bold">*</span></label>
                  <input type="text" value={lastNames} className="form-control form-control-xs" placeholder='Please enter the last name...' id="last-name" onChange={(e) => { setLastNames(e.target.value) }}/>
                  <p style={{color:'red'}}>{formErrors.lastName}</p>
              </div>
              <div className="form-row mt-1">
                  <label htmlFor="document-type">Email <span className="text-danger font-weight-bold">*</span></label>
                  <input type="text" value={emails} className="form-control form-control-xs" placeholder='Please enter the email...' id="email" onChange={(e) => { setEmails(e.target.value) }} disabled/>
                  <p style={{color:'red'}}>{formErrors.email}</p>
              </div>
              {errorMessage ? (
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  ) : (
                    <span></span>
                  )}      
              </form>

              <div className="modal-footer">
              <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
              <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
              <button className="btn btn-success mt-auto" type="button" onClick={updateAirportUser}>Submit</button>
              </div>
        </div>
    );
};

export default EditUserSecondary;