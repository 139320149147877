import React from "react";

const LoginNavBar = () => {
  return (
    // <nav className="navbar fixed-top be-top-header">
    //     <div className="container-fluid">
    //         <div className="be-navbar-header">
    //             <a className="navbar-brand" href="#">
    //                 <svg xmlns="http://www.w3.org/2000/svg" width="122" height="32" viewBox="0 0 122 32">
    //                     <text id="PinPoint" transform="translate(0 25)" fill="#fff" fontSize="26" fontFamily="Montserrat-Bold, Montserrat" fontWeight="700">
    //                         <tspan x="0" y="0" letterSpacing="-1px">PinPoint</tspan>
    //                     </text>
    //                 </svg>
    //             </a>
    //         </div>
    //         <div className="page-title"><span>Authentication</span></div>
    //     </div>
    // </nav>

    <nav className="navbar navbar-expand fixed-top be-top-header">
      <div className="container-fluid">
        <div className="be-navbar-header" style={{ height: 60 }}></div>
      </div>
    </nav>
  );
};

export default LoginNavBar;
