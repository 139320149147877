import React, { useState, useEffect } from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Select from "react-select";

const EditAirportGroup = ({ toggler,airportgroup, refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [airportGroup, setAirportGroup] = useState("");
    const [isSmall, setIsSmall] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [iataCodeList, setIataCodeList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);



    const editAirportGroup = async () => {

        let iataIDs = selectedOptions.map((option) => option.value).join(',');
        const requestBody = {
            "userID": userID,
            "airportGroup": airportGroup,
            "iataCode" : '',
            "isSmallGroup":isSmall === true ? 1 : 0,
            "id" : airportgroup.id,
            "message": '',

        };
        const validationErrors = validateForm(airportGroup);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.editAirportGroup}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                if (response.ok) {
                    var result = await response.json();
                    if(result.saveErrorMessage === 'Airport group updated.')
                    {
                        toast.success('Airport group updated successfully.', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide
                        }); 
                    setRefetchs(!refetchs);
                    toggler();
                    }else{
                        setErrorMessage(result.saveErrorMessage);
                    }
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.editAirportGroup}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
        }
    };


    {/*Validation*/ }
    const validateForm = (airportGroup) => {
        const errors = {};
        if(!airportGroup){
            errors.airportGroup = 'Please enter a value.';
          } else if(airportGroup.length > 256){
              errors.airportGroup = 'Maximum of 256 characters allowed.';
          }else if (!/^[a-zA-Z0-9\s]+$/.test(airportGroup)) {
              errors.airportGroup = 'Special characters are not allowed.';
          }
        // if (iataCode.length<=0) {
        //     errors.iataCode = 'Please select IATA code.';
        // }
        return errors;
    }

    useEffect(() => {
        const getIataCodeList = async () => { 
            try {
            const response = await  fetch(`${config.apiUrl}${apiconfig.getIataCode}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                result = result.slice(1);
                const option = result.map((iata) => ({
                    label: iata.text,
                    value: iata.value,
                  }));
                setIataCodeList(option);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getIataCode}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
        getIataCodeList();
    }, []);


    useEffect(() => {
        const getAirportGroupDetails= async () => { 
            try {
            const response = await  fetch(`${config.apiUrl}${apiconfig.getAirportGroupDetails}${airportgroup.id}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                setAirportGroup(result.airportGroup);
                if(result.isSmallGroup === 1){
                    setIsSmall(true);
                } else {
                    setIsSmall(false);
                }
                let fetchedIata = [];
                for (let i = 0; i < result.iataCodes.length; i++) {
                    fetchedIata.push(result.iataCodes[i]);
                  }
                const option = fetchedIata.map((iata) => ({
                    label: iata.name,
                    value: iata.id,
                  }));
                setSelectedOptions(option);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAirportGroupDetails}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
        getAirportGroupDetails();
    }, [airportgroup.id]);

    const handleIataCodeChange = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        setErrorMessage("");
      };

      useEffect(()=>{
        const optionValues = selectedOptions.map((item) => Number(item.value));
        const updatedIataCodeList = iataCodeList.filter((item) => !optionValues.includes(Number(item.value)));
        setIataCodeList(updatedIataCodeList);
      },[iataCodeList.length>0])
 

    return (
        <div>
            <form>
                <div className="form-group">
                    <label htmlFor="input_AirportGroupe">Airport Group<span className="text-danger font-weight-bold">*</span></label>
                    <input id="input_AirportGroupe" type="text" className="form-control form-control-xs"  placeholder='Please Enter Airport Group...' value = {airportGroup} onChange={(e) => { setAirportGroup(e.target.value) }} />
                    <p style={{ color: 'red' }}>{formErrors.airportGroup}</p>
                </div>
                {/* <div className="form-group">
                    <label htmlFor="iATACode">IATA Code<span className="text-danger font-weight-bold">*</span></label>
                    <Select id="iATACode"
                            options={iataCodeList}
                            value={selectedOptions}
                            onChange={handleIataCodeChange}
                            placeholder="Select a IATA Code"
                            isSearchable
                            isMulti
                        />
                    <p style={{ color: 'red' }}>{formErrors.iataCode}</p>
                </div> */}
                <div className="form-group" style={{ display: 'none' }}>
                    <div className="custom-control custom-checkbox">
                        <input className="custom-control-input" type="checkbox" id="isPrimary" value = {isSmall} onChange={(e) => { setIsSmall(e.target.checked) }} checked={isSmall === true}/>
                        <label className="custom-control-label" htmlFor="isPrimary">Is Small Airport Group ?</label>
                    </div>
                </div>
                {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )} 
            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                <button className="btn btn-success mt-auto" type="button" onClick={editAirportGroup}>Submit</button>
            </div>
        </div>
    );
};

export default EditAirportGroup;