import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Layout from "../../../Layout";
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import CommonCustomModal from "../../Common/CommonCustomModal";
import CommonModalWithImage from "../../Common/CommonModalWithImage";
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import EditSponsors from "./EditSponsor";
import CommonModalWithOutButton from "../../Common/CommonModalWithOutButton";
import SponsorDetailsTab from "./SponsorDetailsTab";
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1";


const ViewSponsor = () => {
    const navigate = useNavigate();
    const [refetch, setRefetch] = useState(false);
    const [clientId, setClientId] = useState(0);
    const [sponsorName, setSponsorName] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [country, setcountry] = useState("");
    const [region, setregion] = useState("");
    const [status, setstatus] = useState("");
    const { id } = useParams();

    const [editSponsorModal, setEditSponsorModal] = useState(false);
    const [archiveSponsorModal, setArchiveSponsorModal] = useState(false);
    const [activateSponsorModal, setActivateSponsorModal] = useState(false);
    const [primarySponsorModal, setPrimarySponsorModal] = useState(false);
    const userID = localStorage.getItem("userID");
    const [loading, setLoading] = useState(true);
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();


    const toggleForEditSponsorModal = () => {
        setEditSponsorModal(!editSponsorModal);
    }

    const toggleForArchiveSponsorModal = () => {
        setArchiveSponsorModal(!archiveSponsorModal);
    }
    const toggleForActivateSponsorModal = () => {
        setActivateSponsorModal(!activateSponsorModal);
    }

    const toggleForPrimarySponsorModal = () => {
        setPrimarySponsorModal(!primarySponsorModal);
    }

    // {/* HOOKS */ }
    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`${config.apiUrl}${apiconfig.getVerifierBySponsor}${id}`, {
    //                 method: 'GET',
    //                 headers: {

    //                     Authorization: `Bearer ${localStorage.getItem("token")}`

    //                 }
    //             }).then((response) => {
    //                 return response.json();
    //             })
    //             let fetchedVerifiers = [];
    //             for (let i = 0; i < response.length; i++) {
    //                 fetchedVerifiers.push(response[i]);
    //             }
    //             setVerifiers(fetchedVerifiers);
    //         }
    //         catch (error) {
    //             console.log("Error: ", error);
    //             navigate("/error-page");
    //         } finally {
    //             setLoading(false);
    //         }
    //     };
    //     fetchData();

    // }, [id]);


    //get DetailsByID
    useEffect(() => {
        try {
            fetch(`${config.apiUrl}${apiconfig.getSponsorDetailsByID}${id}`,{
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                  },
            })
                .then((response) => response.json())
                .then((data) => {
                    setClientId(data.clientID);
                    setSponsorName(data.sponsorName);
                    setaddress1(data.addressLine1);
                    setaddress2(data.addressLine2);
                    setcity(data.city);
                    setzipcode(data.zipcode);
                    setphone(data.phoneNumber);
                    setemail(data.email);
                    setcountry(data.country);
                    setregion(data.region);
                    setstatus(data.sponsorStatus);
                })
        } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    }, [id, refetch])

    const activateSponsor = async () => {
        const requestBody = {
            "userID": userID,
            "activeStatus": 1,
            "sponsorID": id
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.archiveUnarchiveSponsor}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response.json();
            })
            if (response.saveErrorMessage === 'Sponsor UnArchive successfully.') {
                toast.success('Sponsor UnArchived successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                });

            }
            setRefetch(!refetch);
        }
        catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
        toggleForActivateSponsorModal();
    };

    const archiveSponsor = async () => {
        const requestBody = {
            "userID": userID,
            "activeStatus": 0,
            "sponsorID": id
        };
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.archiveUnarchiveSponsor}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response.json();
            })
            if (response.saveErrorMessage === 'Sponsor Archive successfully.') {
                toast.success('Sponsor Archived successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                });

            } else if (response.saveErrorMessage === 'You can’t delete the primary sponsor.') {
                toggleForPrimarySponsorModal();

            }
            setRefetch(!refetch);
        }
        catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
        toggleForArchiveSponsorModal();
    };

    const sponsor = {
        id: id
    }

    return (
        <>
            <Layout>
                {/* {loading && <Loader />} */}
                <div className="be-content">
                    <div className="main-content container-fluid p-0">
                        <div className="page-header ph-orange mb-4">
                            <div className="container-header">
                                <div className="row">
                                    <div className="col">
                                        <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">Sponsor Details</h3>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end">

                                    </div>
                                </div>
                                <nav aria-label="breadcrumb" role="navigation">
                                    <ol className="breadcrumb mb-0 mt-4">
                                        <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                        <li className="breadcrumb-item"><a href="/Sponsors">Sponsor List</a></li>
                                        <li className="breadcrumb-item active">Sponsor Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                        <div className="container-header">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card card-table">
                                        <div className="card-header">
                                        {isReadOnlyAccess1 === 'FullAccess' ?
                                            <div className="tools">
                                                <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="mdi mdi-more"></i>
                                                    <span className="sr-only">Menu</span>
                                                </a>
                                                <div className="dropdown-menu">
                                                {isReadOnlyAccess1 === 'FullAccess' ?     
                                                <>
                                                    <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditSponsorModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                    {status === 'Active' ? (
                                                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForArchiveSponsorModal(); }}><span className="icon mdi mdi-delete"></span>Archive</a>
                                                    ) : (
                                                        <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForActivateSponsorModal(); }}><span className="icon mdi mdi-plus"></span>UnArchive</a>
                                                    )}
                                                    </>
                                                    :
                                                    <></>
                                                }

                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                            }
                                        </div>
                                            <div className="row">
                                                <div className="form-row">
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_SponsorName">Sponsor Name :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_SponsorName" className="text-break">{sponsorName}</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_Client">Client ID :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_Client" className="text-break">{clientId}</label>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_Address1">Address Line 1 :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_Address1" className="text-break">{address1}</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_Address2">Address Line 2(Opt) :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_Address2" className="text-break">{address2}</label>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_City">City :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_City" className="text-break">{city}</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_Zip">Zip/Postcode :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_Zip" className="text-break">{zipcode}</label>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_PhoneNumber">Phone Number :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_PhoneNumber" className="text-break">{phone}</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_Email">Email :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_Email">{email}</label>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_Region">Region :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_VerifierName">{region}</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_Country">Country :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_Country">{country}</label>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label htmlFor="input_VerifierStatus">Sponsor Status :</label>
                                                    </div>
                                                    <div className="form-group col-md-3 mb-1">
                                                        <label id="input_VerifierStatus">{status}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <SponsorDetailsTab sponsorID={sponsor.id} refetchs={refetch} setRefetchs={setRefetch} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Layout>;
            <CommonCustomModal modal={editSponsorModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForEditSponsorModal} heading='Edit Sponsor' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditSponsors toggler={toggleForEditSponsorModal} sponsor={sponsor} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <CommonModalWithImage modal={archiveSponsorModal} title='Archive Sponsor' toggler={toggleForArchiveSponsorModal} heading='Archive Sponsor' triggerMethodOnSaveButtonClick={archiveSponsor} buttonText='Yes'>
                <Label className="col-form-label">{'Are you sure you want to archive '}{'?'}</Label>
            </CommonModalWithImage>
            <CommonModalWithImage modal={activateSponsorModal} title='UnArchive Sponsor' toggler={toggleForActivateSponsorModal} heading='UnArchive Sponsor' triggerMethodOnSaveButtonClick={activateSponsor} buttonText='Yes'>
                <Label className="col-form-label">{'Are you sure you want to UnArchive '}{'?'}</Label>
            </CommonModalWithImage>
            <CommonModalWithOutButton modal={primarySponsorModal} title='' toggler={toggleForPrimarySponsorModal} heading='Archive Sponsor'>
                <Label className="col-form-label">{'You can’t delete the primary sponsor.'}</Label>
            </CommonModalWithOutButton>
        </>
    );
};

export default ViewSponsor;
