import React from 'react'
import { htmlConverterReact } from 'html-converter-react'

const SubQuestionForConsolidatedErrorList = ({ question, subsection, setActiveRightPane, scrollToQuestionViaQuestionRef }) => {
    return question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown' || question.responseType === 'other' || question.responseType === 'matrix' || question.responseType === 'none'
        ?
        question.required && question.isVisible
            && 
            (
                ((question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown') && question.lastSuccessfullySavedResponse.length === 0)
                ||
                (question.responseType === 'other' && (question.lastSuccessfullySavedResponseOther === null || question.lastSuccessfullySavedResponseOther.length === 0))
                ||
                (question.responseType === 'matrix' && ((question.lastSuccessfullySavedResponseMatrix === null || question.lastSuccessfullySavedResponseMatrix.length === 0)))
            )
            ?
            <React.Fragment>
                <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 5 }} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question)}}>
                    <table className="table table-hover" style={{ backgroundColor: '#ffeded', cursor: 'pointer' }}>
                        <tbody>
                            <tr>
                                <td style={{ borderRadius: 7, borderTop: 0, borderBottom: 0 }}>
                                    <span>
                                        <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                        &nbsp;&nbsp;
                                        <h7>{htmlConverterReact(question.question)}</h7>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    question.subquestions.map((subquestion) => {
                        return <SubQuestionForConsolidatedErrorList question={subquestion} subsection={subsection} setActiveRightPane={setActiveRightPane} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} />
                    })
                }
            </React.Fragment>
            :
            <React.Fragment>
                {
                    question.subquestions.map((subquestion) => {
                        return <SubQuestionForConsolidatedErrorList question={subquestion} subsection={subsection} setActiveRightPane={setActiveRightPane} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} />
                    })
                }
            </React.Fragment>
        :
        <React.Fragment></React.Fragment>
}

export default SubQuestionForConsolidatedErrorList