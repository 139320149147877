import React from 'react';
import { AiFillHome, AiOutlineAppstore } from 'react-icons/ai';
import { IoMdPeople } from 'react-icons/io';
import { BiCategory, BiLogOut } from 'react-icons/bi';
import { FiSettings } from 'react-icons/fi';
import { RiUserSettingsLine } from 'react-icons/ri';
import { MdOutlineAdminPanelSettings, MdOutlineEvent } from 'react-icons/md';
import { FaUsers } from 'react-icons/fa';

export const SideMenuData = [
    {
        title: 'Home',
        path: '/',
        icon: <AiFillHome />
    },
    {
        title: 'Applications',
        path: '/applications',
        icon: <AiOutlineAppstore />
    },
    {
        title: 'Users',
        path: '/users',
        icon: <IoMdPeople />
    },
    {
        title: 'Categories',
        path: '/usersCategory',
        icon: <BiCategory/>
    },
    {
        title: 'Events',
        path: '/usersEvents',
        icon: <MdOutlineEvent />
    },
    {
        title: 'Bulk Users',
        path: '/addBulkUsers',
        icon: <FaUsers />
    },
    {
        title: 'Permissions',
        path: '/permissions',
        icon: <MdOutlineAdminPanelSettings />
    },
    {
        title: 'Roles',
        path: '/roles',
        icon: <RiUserSettingsLine />
    },
    {
        title: 'Project Settings',
        path: '/project-settings',
        icon: <FiSettings />
    },
    {
        title: 'Logout',
        path: '/login',
        icon: <BiLogOut />
    }
];