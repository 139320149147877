import RouteList from './Routes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from 'react-auth-kit';
import UserDetails from "../src/Components/Admin/Users/UserDetails"
import RoleDetails from '../src/Components/Admin/Roles/RoleDetails'; 
import AuthChecker from './Components/Error/AuthChecker';
import { useIdleTimer } from 'react-idle-timer'
import { useSignOut } from "react-auth-kit";

const App = () => {
   

    const handleOnIdle = event => {
        if (window.location.pathname !== "/login" && window.location.pathname.toLocaleLowerCase() !== "/verifierregistration" && window.location.pathname.toLocaleLowerCase() !== "/accreditationairportregistration" && window.location.pathname.toLocaleLowerCase() !== "/acceptableusepolicy" && window.location.pathname.toLocaleLowerCase() !== "/passwordreset" && window.location.pathname.toLocaleLowerCase() !== "/unauthorizeduser") {
        handleLogOut();
        window.location.href = "/SessionTimeOut";
        }
      }

      const handleLogOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.removeItem("roles");
        localStorage.removeItem("userID");
        };
        
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 60,
        onIdle: handleOnIdle,
      })
    return (
        <div>
            <AuthProvider authType={'cookie'} authName={'_auth'} cookieDomain={window.location.hostname} cookieSecure={false}>
                <Router>
                    <RouteList />
                    <Routes>
                    </Routes>
                </Router>
            </AuthProvider>
        </div>
    );
};

export default App;