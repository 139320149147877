import { useState } from "react";
import { Label } from 'reactstrap';
import IsAccessForAccreditationCombination from "../../Common/IsAccessForAccreditationCombination";



const AirportGroupAirportList = ({ airports}) => {

const isFullAccess = IsAccessForAccreditationCombination();
return (

        <tr>
            <td>{airports.iataCode}</td>
            <td>{airports.region}</td> 
            <td>{airports.airportGroup}</td>  
            {isFullAccess === 'AccFullAccess' ?
            <td><a className="text-info" href={`/AirportDetails/${airports.airportID}`}>{airports.airportName_English}</a></td>
            :
            <td>{airports.airportName_English}</td>
            }
            <td>{airports.accreditationLevel}</td>
            <td>{airports.nextRenewalDate}</td>
            <td>{airports.eligiblefor3Year}</td>
            <td>{airports.leftProgrammeText}</td>
        </tr>

);

};

export default AirportGroupAirportList;
