import React, { useEffect, useState} from 'react'
import { Label } from 'reactstrap'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import Layout from '../../../Layout'
import AccreditationAirports from './AccreditationAirports'
import { useNavigate} from "react-router-dom"
import DoughnutChart from '../../Common/DoughnutChart'
import AccreditationApplications from './AccreditationApplications'
import AccreditationAirportsforAirportTypes from './AccreditationAirportsforAirportTypes'
import Loader from '../../Common/Loader'


const AccreditationDashboard = () => {

    /* States & Constants */
    const [refetchDash,setRefetchDash] = useState(false)
    const [isAirportRegistrationList,setAirportRegistrationList] = useState(false)
    const [isApplicationList,setApplicationList] = useState(false)
    const [isAirportsList,setAirportsList] = useState(false)
    const [totalAirportApplication,setTotalAirportApplication] = useState("")
    const [totalAirportRegistration,setTotalAirportRegistration] = useState("")
    const [totalAirportUser,setTotalAirportUser] = useState("")
    const [totalAirports,setTotalAirports] = useState("")
    const [airportDataDetails,setAirportDataDetails] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingChildData, setIsLoadingChildData] = useState(false)
    const navigate = useNavigate()
    const userID = localStorage.getItem("userID")
    const data1 = {
        labels: ['Approved','Pending','Rejected'],
        datasets: [{
        label: '',
        data: [airportDataDetails.totalAirportRegistrationApproved, airportDataDetails.totalAirportRegistrationPending, airportDataDetails.totalAirportRegistrationReject],
        backgroundColor: [
            'rgb(52, 168, 83)',
            'rgb(251, 188, 5)',
            'rgb(244, 66, 66)'
        ],
        }],
    }
    const data2 = {
        labels: ['Draft', 'Verifier comments - Airport to address', 'Admin comments - Airport to address', 'In Verification','Airport resubmitted - In Verification',
                'Admin comments - Verifier to address', 'In Admin Verification', 'Airport resubmitted - In Admin Verification', 'Verifier resubmitted - In Admin Verification', 'Approved',],
        datasets: [{
        label: '',
        data: [airportDataDetails.totalAirportApplicationInDraft, airportDataDetails.totalAirportApplicationInVerifierCommentsAirportToAddress, airportDataDetails.totalAirportApplicationInAdminCommentsAirportToAddress, airportDataDetails.totalAirportApplicationInVerification, 
                airportDataDetails.totalAirportApplicationInAirportResubmittedInVerification, airportDataDetails.totalAirportApplicationAdminCommentsVerifierToAddress, airportDataDetails.totalAirportApplicationInAdminVerification, 
                airportDataDetails.totalAirportApplicationInAirportResubmittedInAdminVerification, airportDataDetails.totalAirportApplicationVerifierResubmittedInAdminVerification, airportDataDetails.totalAirportApplicationApproved],
        backgroundColor: [
            'rgb(251, 188, 5)',
            'rgb(168, 52, 137)',
            'rgb(240, 96, 158)',
            'rgb(129, 158, 38)',
            'rgb(133, 136, 246)',
            'rgb(37, 219, 86)',
            'rgb(0, 156, 255)',
            'rgb(222, 121, 57)',
            'rgb(178, 16, 47)',
            'rgb(52, 168, 83)',
        ],
        }],
    }
    const data3 = {
        labels: ['Group Airports','Single Airports','Small Group Airports'],
        datasets: [{
        label: '',
        data: [airportDataDetails.totalGroupAirports, airportDataDetails.totalSingleAirports,airportDataDetails.totalSmallGroupAirports],
        backgroundColor: [
            'rgb(52, 168, 83)',
            'rgb(251, 188, 5)',
            'rgb(244, 66, 66)'
        ],
        }],
    }
    const options = {
        cutout: 100,
        radius:80,
        plugins: {
            legend: {
                display: false,
            },
        },
    }
    const textCenter1 = {
        id:'textCenter',
        beforeDraw(chart,args,pluginOptions) {
            const {ctx} = chart;
            ctx.save();
            ctx.font = '50px sans-serif';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(totalAirportRegistration, chart.width / 2, chart.height / 2);
        }
    }
    const textCenter2 = {
        id:'textCenter',
        beforeDraw(chart,args,pluginOptions) {
            const {ctx} = chart;
            ctx.save();
            ctx.font = '50px sans-serif';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(totalAirportApplication, chart.width / 2, chart.height / 2)
        }
    }
    const textCenter3 = {
        id:'textCenter',
        beforeDraw(chart,args,pluginOptions) {
            const {ctx} = chart;
            ctx.save();
            ctx.font = '50px sans-serif';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(totalAirports, chart.width / 2, chart.height / 2)
        }
    }

    /* Methods */
    const handleAirportChart = () => {
        setAirportRegistrationList(true)
        setApplicationList(false)
        setAirportsList(false)
    }
    const handleApplicationChart = () => {
        setApplicationList(true)
        setAirportRegistrationList(false)
        setAirportsList(false)
    }
    const handleUserChart = () => {
        setAirportsList(true)
        setAirportRegistrationList(false)
        setApplicationList(false)
    }

    /* Effects */
    useEffect(() => {
        const airportDashBoardData= async () => { 
            try {
                console.log('executing airportDashBoardData()...')
                setIsLoading(true)
                const response = await  fetch(`${config.apiUrl}${apiconfig.accreditationAirportDashBoardData}?UserID=${userID}&UserType=${'Administrator'}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    console.log('fetched airportDashBoardData()')
                    var result = await response.json()
                    setAirportDataDetails(result)
                    const totalAirportApp = result.totalAirportApplication ===  0 ? '' : result.totalAirportApplication
                    setTotalAirportApplication(totalAirportApp)
                    const totalAirport = result.totalAirportRegistration ===  0 ? '' : result.totalAirportRegistration
                    setTotalAirportRegistration(totalAirport)
                    const totalAirportUser = result.totalAirportUser ===  0 ? '' : result.totalAirportUser
                    setTotalAirportUser(totalAirportUser)
                    const totalAirports = result.totalAirports ===  0 ? '' : result.totalAirports
                    setTotalAirports(totalAirports)

                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.accreditationAirportDashBoardData}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
                setIsLoading(false)
            }
            catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        airportDashBoardData()
    }, [refetchDash])


    return (
        <Layout>
            {
                isLoading || isLoadingChildData
                  ?
                  <Loader />
                  :
                  <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header yallow mb-4">
                        <div className="container-fluid">
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">Accreditation Dashboard</h3>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end">
                                        
                                    </div>
                                </div>
                                <nav aria-label="breadcrumb" role="navigation">
                                    <ol className="breadcrumb mb-0 mt-4">
                                        <li className="breadcrumb-item"><a href="/"><span className="icon mdi mdi-home"><span className='sr-only'>Home</span></span></a></li>
                                        <li className="breadcrumb-item active">Accreditation Dashboard</li>
                                    </ol>
                                </nav>
                                <div className="row">
                                    <div className="d-flex align-items-center justify-content-center">
                                            <h4 className="page-header-title">Click on the relevant chart to see further detail</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6 col-lg-4 col-xl-4 d-flex align-items-stretch">
                                <a onClick={handleAirportChart} className="w-100 d-flex align-items-stretch">
                                    <div className="widget be-loading border1 w-100"  style={{ cursor: 'pointer',backgroundColor: isAirportRegistrationList ? '#E5E5E5' : ''}}>
                                        <div className="widget-head m-0">
                                            <div className="tools"><img src="dist/img/page_icon.svg" alt="" width="30px"/> </div>
                                            <div className="title">Airport Registrations</div>
                                        </div>
                                        {/* <div className="widget-chart-container">
                                            <div id="top-sales" style={{height: '178px'}}></div>
                                            <div className="chart-pie-counter">6</div>
                                        </div> */}
                                        <div  style={{height:'250px', width:'250px', margin:'auto'}}>
                                            {
                                                totalAirportRegistration !== '' && <DoughnutChart data={data1} options={options} plugins={textCenter1} />
                                            }
                                        </div>
                                        <div className="chart-legend">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="chart-legend-color"><span style={{ backgroundColor: '#198754' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#198754' }}>Approved</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportRegistrationApproved}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: 'rgb(251, 188, 5)' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: 'rgb(251, 188, 5)',color:'black' }}>Pending</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportRegistrationPending}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#DD2C00' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#DD2C00' }}>Rejected</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportRegistrationReject}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-4">
                                <a onClick={handleApplicationChart}  className="w-100 d-flex align-items-stretch">
                                    <div className="widget be-loading border1  w-100"  style={{ cursor: 'pointer',backgroundColor: isApplicationList ? '#E5E5E5' : '' }}>
                                        <div className="widget-head m-0">
                                            <div className="tools"><img src="dist/img/component_icon.svg" alt="" width="30px"/> </div>
                                            <div className="title">Airport Applications</div>
                                        </div>
                                        <div style={{height:'250px', width:'250px', margin:'auto'}}>
                                            {
                                                totalAirportApplication !== '' && <DoughnutChart data={data2} options={options} plugins={textCenter2} />
                                            }
                                        </div >
                                        {/* <div className="widget-chart-container">
                                            <div id="top-sales2" style={{height: '178px'}}></div>
                                            <div className="chart-pie-counter">6</div>
                                        </div> */}
                                        <div className="chart-legend">
                                            <table>
                                                <tbody>
                                                    {airportDataDetails.totalAirportApplicationInDraft !== null && airportDataDetails.totalAirportApplicationInDraft !== 0 && airportDataDetails.totalAirportApplicationInDraft !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span style={{ backgroundColor: 'rgb(251, 188, 5)' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: 'rgb(251, 188, 5)',color:'black' }}>Draft</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationInDraft}</td>
                                                    </tr>
                                                    )}
                                                    {airportDataDetails.totalAirportApplicationInVerifierCommentsAirportToAddress !== null && airportDataDetails.totalAirportApplicationInVerifierCommentsAirportToAddress !== 0 && airportDataDetails.totalAirportApplicationInVerifierCommentsAirportToAddress !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span style={{ backgroundColor: '#AB47BC' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#AB47BC' }}>Verifier comments - Airport to address</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationInVerifierCommentsAirportToAddress}</td>
                                                    </tr>
                                                        )}
                                                    {airportDataDetails.totalAirportApplicationInAdminCommentsAirportToAddress !== null && airportDataDetails.totalAirportApplicationInAdminCommentsAirportToAddress !== 0 && airportDataDetails.totalAirportApplicationInAdminCommentsAirportToAddress !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#F06292' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#F06292', color:'#000' }}>Admin comments - Airport to address</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationInAdminCommentsAirportToAddress}</td>
                                                    </tr>
                                                        )}
                                                    {airportDataDetails.totalAirportApplicationInVerification !== null && airportDataDetails.totalAirportApplicationInVerification !== 0 && airportDataDetails.totalAirportApplicationInVerification !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#FDD835' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#FDD835', color:'#000' }}>In Verification</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationInVerification}</td>
                                                    </tr>
                                                        )}
                                                    {airportDataDetails.totalAirportApplicationInAirportResubmittedInVerification !== null && airportDataDetails.totalAirportApplicationInAirportResubmittedInVerification !== 0 && airportDataDetails.totalAirportApplicationInAirportResubmittedInVerification !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#64B5F6' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#64B5F6', color:'#000' }}>Airport resubmitted - In Verification</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationInAirportResubmittedInVerification}</td>
                                                    </tr>
                                                        )}
                                                    {airportDataDetails.totalAirportApplicationAdminCommentsVerifierToAddress !== null && airportDataDetails.totalAirportApplicationAdminCommentsVerifierToAddress !== 0 && airportDataDetails.totalAirportApplicationAdminCommentsVerifierToAddress !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#8BC34A' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#8BC34A', color:'#000' }}>Admin comments - Verifier to address</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationAdminCommentsVerifierToAddress}</td>
                                                    </tr>
                                                        )}
                                                    {(airportDataDetails.totalAirportApplicationInAdminVerification !== null && airportDataDetails.totalAirportApplicationInAdminVerification !== 0 && airportDataDetails.totalAirportApplicationInAdminVerification !== "") && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#303F9F' }}></span></td>
                                                        <td><span className="badge"  style={{ backgroundColor: '#303F9F' }}>In Admin Verification</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationInAdminVerification}</td>
                                                    </tr>
                                                    )}
                                                    {airportDataDetails.totalAirportApplicationInAirportResubmittedInAdminVerification !== null && airportDataDetails.totalAirportApplicationInAirportResubmittedInAdminVerification !== 0 && airportDataDetails.totalAirportApplicationInAirportResubmittedInAdminVerification !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span style={{ backgroundColor: '#DD2C00' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#DD2C00' }}>Airport resubmitted - In Admin Verification</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationInAirportResubmittedInAdminVerification}</td>
                                                    </tr>
                                                    )}
                                                    {airportDataDetails.totalAirportApplicationVerifierResubmittedInAdminVerification !== null && airportDataDetails.totalAirportApplicationVerifierResubmittedInAdminVerification !== 0 && airportDataDetails.totalAirportApplicationVerifierResubmittedInAdminVerification !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#B2102F' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#B2102F' }}>Verifier resubmitted - In Admin Verification</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationVerifierResubmittedInAdminVerification}</td>
                                                    </tr>
                                                    )}
                                                    {airportDataDetails.totalAirportApplicationApproved !== null && airportDataDetails.totalAirportApplicationApproved !== 0 && airportDataDetails.totalAirportApplicationApproved !== "" && (
                                                    <tr>
                                                        <td className="chart-legend-color"><span style={{ backgroundColor: '#198754' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#198754' }}>Approved</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalAirportApplicationApproved}</td>
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-6 col-lg-4 col-xl-4 d-flex align-items-stretch">
                                <a onClick={handleUserChart} className="w-100 d-flex align-items-stretch">
                                    <div className="widget be-loading border1 w-100" style={{ cursor: 'pointer',backgroundColor: isAirportsList ? '#E5E5E5' : '' }}>
                                        <div className="widget-head m-0">
                                            <div className="tools"><img src="dist/img/image_icon.svg" alt="" width="30px"/> </div>
                                            <div className="title">Airports</div>
                                        </div>
                                        <div style={{height:'250px', width:'250px', margin:'auto'}}>
                                            {
                                                totalAirportUser !== '' && <DoughnutChart data={data3} options={options} plugins={textCenter3} />
                                            }
                                        </div>
                                        {/* <div className="widget-chart-container">
                                        <div id="top-sales3" style={{height: '178px'}}></div>
                                        <div className="chart-pie-counter">6</div>
                                        </div> */}
                                        <div className="chart-legend">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className="chart-legend-color"><span style={{ backgroundColor: '#198754' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#198754' }}>Group Airports</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalGroupAirports}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: 'rgb(251, 188, 5)' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: 'rgb(251, 188, 5)',color:'black' }}>Single Airports</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalSingleAirports}</td>
                                                    </tr>
                                                    {/* <tr>
                                                        <td className="chart-legend-color"><span  style={{ backgroundColor: '#DD2C00' }}></span></td>
                                                        <td><span className="badge" style={{ backgroundColor: '#DD2C00' }}>Small Group Airports</span></td>
                                                        <td className="chart-legend-value">{airportDataDetails.totalSmallGroupAirports}</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        {
                            isAirportRegistrationList 
                                ?
                                <AccreditationAirports refetchDash={refetchDash} setRefetchDash={setRefetchDash} setIsLoadingChildData={setIsLoadingChildData} />
                                :
                                isApplicationList 
                                    ? 
                                    <AccreditationApplications refetchDash={refetchDash} setRefetchDash={setRefetchDash} setIsLoadingChildData={setIsLoadingChildData} />
                                    : 
                                    isAirportsList 
                                        ?
                                        <AccreditationAirportsforAirportTypes refetchDash={refetchDash} setRefetchDash={setRefetchDash} setIsLoadingChildData={setIsLoadingChildData} />
                                        :
                                        <React.Fragment></React.Fragment>
                        }            
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AccreditationDashboard