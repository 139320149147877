import React from 'react'

const LeftNavigationMenu = ({ data, setState, activeRightPane, setActiveRightPane, userType }) => {

    return(
        <React.Fragment>
            {
                data.default.length === 0 
                    ? 
                    <a className="list-group-item d-flex list-group-item-action" style={{cursor: 'not-allowed'}}>
                        <span className="text" style={{ textAlign: 'center' }}>Nothing to show here</span>
                    </a>
                    :
                    data.default.map((item, index) => {
                        return(  
                            <a key={index} className={item.name === activeRightPane ? "list-group-item d-flex list-group-item-action task-default-selected" : "list-group-item d-flex list-group-item-action"} style={{cursor: 'pointer'}} onClick={() => { setActiveRightPane(item.name) }}>
                                <span className="mdi mdi-3x mdi-arrow-right mr-3" />
                                <span className="text">{item.name}</span>
                                {
                                    index !== 0 && item.isSubmitButtonClicked 
                                        ? 
                                        item.completed 
                                            ? 
                                            <span className="mdi mdi-3x text-success mdi-check-circle" /> 
                                            :  
                                            <span className="mdi mdi-3x text-danger mdi-alert-circle" />
                                        :
                                        <React.Fragment></React.Fragment>
                                }
                            </a>
                        )
                    })
            }
            {
                data.sections.map((section, section_index) => {
                    return(
                        <React.Fragment key={section_index}>
                            <a key={section_index} className={section.title === activeRightPane ? "list-group-item d-flex list-group-item-action task-default-selected" : "list-group-item d-flex list-group-item-action"} style={{cursor: 'pointer'}} onClick={() => { setActiveRightPane(section.title) }}>
                                <span className="text text-info">
                                    <strong>{section.title}</strong>
                                </span>
                            </a>
                            {
                                section.subsections.map((subsection, subsection_index) => {
                                    return(
                                        subsection.type === 'draft' 
                                            ? 
                                            <a key={subsection_index} className={subsection.title === activeRightPane ? "list-group-item d-flex list-group-item-action task-default-selected" : "list-group-item d-flex list-group-item-action"} style={{cursor: 'pointer'}} onClick={() => { setActiveRightPane(subsection.title) }}>
                                                <span className="mdi mdi-3x mdi-arrow-right mr-3" />
                                                <span className="text">{subsection.name}</span>
                                                {
                                                    subsection.isSubmitButtonClicked 
                                                        ? 
                                                        subsection.completed 
                                                            ? 
                                                            <span className="mdi mdi-3x text-success mdi-check-circle" /> 
                                                            :  
                                                            <span className="mdi mdi-3x text-danger mdi-alert-circle" />
                                                        :
                                                        <React.Fragment></React.Fragment>
                                                }
                                            </a>
                                            :
                                            subsection.type === 'verifier' 
                                                ? 
                                                <a key={subsection_index} className={subsection.isSubmitButtonClicked ? (subsection.completed ? (subsection.title === activeRightPane ? "list-group-item d-flex list-group-item-action task-complete-selected" : "list-group-item d-flex list-group-item-action task-complete") : (subsection.title === activeRightPane ? "list-group-item d-flex list-group-item-action task-uncomplete-selected" : "list-group-item d-flex list-group-item-action task-uncomplete")) : (subsection.title === activeRightPane ? "list-group-item d-flex list-group-item-action task-default-verifier-selected" : "list-group-item d-flex list-group-item-action task-default-verifier")} style={{cursor: 'pointer'}} onClick={() => { setActiveRightPane(subsection.title) }}>
                                                    <span className="mdi mdi-3x mdi mdi-3x mdi-accounts mr-3" />
                                                    <span className="text">{subsection.name}</span>
                                                    {
                                                        subsection.isSubmitButtonClicked 
                                                            ? 
                                                            subsection.completed 
                                                                ? 
                                                                <span className="mdi mdi-3x text-success mdi-check-circle" /> 
                                                                :  
                                                                <span className="mdi mdi-3x text-danger mdi-alert-circle" />
                                                            :
                                                            <React.Fragment></React.Fragment>
                                                    }
                                                </a>
                                                :
                                                <React.Fragment key={subsection_index}></React.Fragment>
                                    ) 
                                })
                            }
                        </React.Fragment>
                    ) 
              })
            }
            {
                data.summaries.map((item, index) => {
                    return(  
                        <a key={index} className="btn btn-space btn-success text-white btn-lg btn-block mt-4" style={item.type === userType ? {cursor: 'pointer', marginBottom: -10} : {display: 'none'}} onClick={() => { setActiveRightPane(item.name) }}>
                            <i className="icon icon-left mdi mdi-assignment" style={{marginBottom:"2px"}} />
                            &nbsp;&nbsp;{item.name}
                        </a>
                    )
                })  
            }
        </React.Fragment>
  )
}

export default LeftNavigationMenu