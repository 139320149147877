import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Form, FormGroup, Input, Label } from 'reactstrap';

const ApproveAirport = ({ toggler, accAirport ,refetchs, setRefetchs,refetchDash,setRefetchDash}) => {
  console.log(accAirport);

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [comment, setComment] = useState("");


    const approveAirport = async () => {

      const requestBody = {
        "userID":userID,
        "airportApplicationID": accAirport.airportApplicationID,
        "comment": comment,
        "actionType": "Approved",
        "airportType" : accAirport.airportType

      };
          try {
              const response = await fetch(`${config.apiUrl}${apiconfig.approveRejectAirport}`,{
                      method: "POST",
                      headers: {
                          "Content-Type": "application/json",
                          'Authorization': `Bearer ${localStorage.getItem("token")}`
                      },
                      body: JSON.stringify(requestBody),
                  });
                  if (response.ok) {
                      var result = await response.json();
                      if(result.saveErrorMessage === 'Airport has been approved successfully.')
                      {
                          toast.success(result.saveErrorMessage, {
                              position: "top-right",
                              autoClose: 10000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                              transition: Slide
                          }); 
                      setRefetchs(!refetchs);
                      if(refetchDash!==undefined)
                      {
                        setRefetchDash(!refetchDash);
                      }
                      toggler();
                      }else{
                          setErrorMessage(result.saveErrorMessage);
                      }
                  } else {
                      const errorText = await response.text();
                      const apiname = `${apiconfig.approveRejectAirport}`;
                      navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                  }
          } catch (error) {
              console.log("Error: ", error);
              navigate("/error-page");
          }
  };

      

    return (
        <div>
          <div className="form-row">
              <label htmlFor="input_comments">Description</label>
              <textarea type="text" placeholder='Please enter the text...' className="form-control form-control-xs" id="input_comments" onChange={(e) => { setComment(e.target.value) }} style={{ height: 120}}/>
          </div>
          <Label className="col-form-label">{'Are you sure '}{'?'}</Label>
          {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )} 
            <div className="modal-footer">
              <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
              <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
              <button className="btn btn-success mt-auto" type="button" onClick={approveAirport} >Confirm</button>
            </div>
        </div>
    );
};

export default ApproveAirport;