import React from 'react';

export const TableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Notification Details',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '50%' },
        isSortable: true,
        sortByColumnName: 'NotificationDetails',
        type: 'text'
    },

    {
        id: 2,
        isHidden: false,
        name: 'Created User',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer',width: '25%' },
        isSortable: true,
        sortByColumnName: 'CreatedUser',
        type: 'text'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Created Date',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '25%' },
        isSortable: true,
        sortByColumnName: 'CreatedDate',
        type: 'text'
    },
    

];