import React, { useState,useEffect} from 'react';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate,Link } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import CommonModalWithImageAndButton from '../../Common/CommonModalWithImageAndButton';
import { Label } from 'reactstrap';

const EditVerifierInvoice = ({ toggler, verifier, invoice, refetchs, setRefetchs,refetchd,setRefetchd }) => {

    const navigate = useNavigate();

    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceStatus, setInvoiceStatus] = useState("");
    const [invoiceStatusText, setInvoiceStatusText] = useState("");
    const [comments, setcomments] = useState("");
    const [invoiceID, setinvoiceID] = useState("");
    const [clientID, setClientID] = useState("");
    const [examTypeList, setExamTypeList] = useState([]);
    const [invoiceStatusList, setInvoiceStatusList] = useState([]);
    const [examType, setExamType] = useState("");
    const [resit, setResit] = useState("");
    const [examfees, setExamfees] = useState("");

    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");

    const [confirmModal, setConfirmModal] = useState(false);
    const [isClicked, setIsClicked] = useState(true);
    const toggleForConfirmModal = () => {
        const validationErrors = validateForm(invoiceStatus, comments, invoiceNumber);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setConfirmModal(!confirmModal);
          }
    } 

    const updateVerifierInvoice = async () => {
        setIsClicked(!isClicked);
        const requestBody = {
            "invoiceID": invoice.invoiceID,
            "statusID": invoiceStatus,
            "invoiceNo": invoiceNumber,
            "notes":comments.trim(),
            "userID": userID
        };
        const validationErrors = validateForm(invoiceStatus,comments,invoiceNumber);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.updateVerifierInvoice}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Invoice has been updated successfully.')
            {
                toast.success('Invoice has been updated successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            setRefetchd(!refetchd);
            toggler();
            }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }else{
            setIsClicked(true);
        }
      };


    {/*Validation*/}
    const validateForm = (invoiceStatus,comments,invoiceNumber) => {
        const errors = {};
        if(!invoiceStatus){
            errors.invoiceStatus = 'Please select at least one invoice status.';
        }
        if(!comments){
            errors.comments = 'Please enter the Comments.';
        }else if(comments.length > 1000){
            errors.comments = 'Maximum of 1000 characters allowed.';
        }
        if(!invoiceNumber && invoiceStatusText==='Invoice Paid'){
            errors.invoiceNumber = 'Please enter the Invoice number';
        }
        return errors;
    }

    useEffect(() => {
        function getexamTypeList() {
            fetch(`${config.apiUrl}${apiconfig.getExamTypes}${invoice.invoiceID}&Type=${'Invoice'}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setExamTypeList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getexamTypeList();
    }, []);

    useEffect(() => {
        function getInvoiceStatusList() {
            fetch(`${config.apiUrl}${apiconfig.getInvoiceStatus}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setInvoiceStatusList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getInvoiceStatusList();
    }, []);

    const attemptOptions = [
        { value: '1st attempt', text: '1st attempt' },
        { value: '2nd attempt', text: '2nd attempt' }
      ];

// //get DetailsByID
 useEffect(() => {
    fetch(`${config.apiUrl}${apiconfig.getInvoiceDetailsByID}${invoice.invoiceID}`,{
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }, 
    })
        .then((response) => response.json())
        .then((data) => {
            setInvoiceNumber(data.invoiceNo);
            setInvoiceStatus(data.invoiceStatusID);
            setinvoiceID(data.invoiceID);
            setClientID(data.sponsorClientID);
            setExamType(data.examType);
            setResit(data.resit);
            setExamfees(data.examFee)

        }).catch((err) => {
            navigate("/error-page");
        });
}, [])


const handleInvoiceStatus = (e)=>{
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedText = selectedOption.text;
    setInvoiceStatus(e.target.value);
    setInvoiceStatusText(selectedText)
  }

    return (
        <>
        <div>
    <form>
                    <div className="form-row">
                    <label htmlFor="input_InvoiceName">Invoice number/reference</label>
                        <input type="text" className="form-control form-control-xs" placeholder='Please Enter Invoice number/reference...' value = {invoiceNumber} onChange={(e) => { setInvoiceNumber(e.target.value) }} />
                        <p style={{color:'red'}}>{formErrors.invoiceNumber}</p>
                    </div>
                    <div className="form-row">
                    <label htmlFor="exam-type">Invoice Status <span className="text-danger font-weight-bold">*</span></label>
                    <select  className="form-control form-control-sm" value={invoiceStatus} onChange={handleInvoiceStatus}>
                            {invoiceStatusList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                    </select>
                    <p style={{color:'red'}}>{formErrors.invoiceStatus}</p>
                    </div>                  
                    <div className="form-row">
                        <label htmlFor="input_comments">Comments <span className="text-danger font-weight-bold">*</span></label>
                        <textarea type="text" className="form-control form-control-xs" placeholder='Please Enter Comments...' onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
                    <div className="form-group row">
                    <label className="col-sm-4 col-form-label mt-1" htmlFor="invoiceID">Invoice ID :</label>
                    <div className="col-sm-6">
                        <label className="form-control-plaintext" htmlFor="invoiceID">{invoiceID}</label>
                    </div>
                    </div>
                    <div className="form-group row">
                    <label className="col-sm-4 col-form-label mt-1" htmlFor="clientID">Client ID :</label>
                    <div className="col-sm-6">
                        <label className="form-control-plaintext" htmlFor="clientID">{clientID}</label>
                    </div>
                    </div>
                    <div className="form-row">
                    <label htmlFor="exam-type">Exam Type</label>
                    <select  className="form-control form-control-sm" value={examType} onChange={(e)=>{setExamType(e.target.value)}} disabled>
                            {examTypeList.map((option) => (
                                <option key={option.id} value={option.id}>
                                    {option.name}
                                </option>
                            ))}
                    </select>
                    </div>
                    <div className="form-row">
                    <label htmlFor="exam-type">Resit</label>
                    <select  className="form-control form-control-sm" value={resit} onChange={(e)=>{setResit(e.target.value)}} disabled>
                    {attemptOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                    </select>
                    </div>
                    <div className="form-group row">
                    <label className="col-sm-4 col-form-label mt-1" htmlFor="examfees">Exam Fee :</label>
                    <div className="col-sm-6">
                        <label className="form-control-plaintext" htmlFor="examfees"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 27 27">
<path id="Icon_material-euro-symbol" data-name="Icon material-euro-symbol" d="M22.5,27.75a9.72,9.72,0,0,1-8.64-5.25H22.5v-3H12.87a9.435,9.435,0,0,1,0-3H22.5v-3H13.86a9.731,9.731,0,0,1,14.985-2.9L31.5,7.95A13.48,13.48,0,0,0,9.78,13.5H4.5v3H9.09a12.545,12.545,0,0,0,0,3H4.5v3H9.78A13.48,13.48,0,0,0,31.5,28.05l-2.67-2.655A9.608,9.608,0,0,1,22.5,27.75Z" transform="translate(-4.5 -4.5)" fill="#333d47"/>
</svg> {examfees}</label>
                    </div>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={invoiceStatusText === 'Invoice Paid' ? toggleForConfirmModal : updateVerifierInvoice}>Submit</button>
            </div>
            </div>

<CommonModalWithImageAndButton modal={confirmModal} title='' toggler={toggleForConfirmModal} heading='' triggerMethodOnSaveButtonClick={updateVerifierInvoice} buttonText='Yes' isClicked = {isClicked}>
<Label className="col-form-label">{'Are you sure you want to set the Invoice Status as '}{invoiceStatusText}{' ?'}</Label>
</CommonModalWithImageAndButton>
</>
    );
};

export default EditVerifierInvoice;