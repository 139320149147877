import React, { useState, useEffect } from "react";
import config from "../../api/config";
import apiconfig from "../../api/apiconfig";
import { useNavigate, Link } from "react-router-dom";
import CommentList from "./Comment/CommentList";
import VerifierSponsorList from "./VerifiersSponsorList";
import TabContainer from "./ExpandedListViewComponents/TabContainer";
import TabList from "./ExpandedListViewComponents/TabList";
import TabContent from "./ExpandedListViewComponents/TabContent";
import TabPanel from "./ExpandedListViewComponents/TabPanel";
import Loader from "../Common/Loader";
import DocumentList from "./Document/DocumentList";
import VerifiersExamList from "./Exam/VerifiersExamList";
import VerifiersInvoiceList from "./Invoice/VerifiersInvoiceList";
import { InvoiceTableHeaderCells } from "./Invoice/InvoiceTableHeaderCells";
import { ExamTableHeaderCells } from "./Exam/ExamTableHeaderCells";
import CustomTable from "../Common/CustomTable";
import Selector from "../Common/Selector";
import Pagination from "../Common/Pagination";
import { TiArrowUnsorted } from 'react-icons/ti';
import { numberOfEntriesPerPageOptions } from "../Helper/NumberOfEntriesPerPageOptions";
import AdvancedSearch from "../Common/AdvancedSearch";
import CommunicationList from "./Communication/CommunicationList";
import AccreditationsList from "./Accreditations/AccreditationsList";
import { InvoiceTableHeaderCellsWithOutActions } from "./Invoice/InvoiceTableHeaderCells";
import { ExamTableHeaderCellsWithOutAction } from "./Exam/ExamTableHeaderCells";
import IsAccessForVerifierCombination1 from "../Common/IsAccessForVerifieCombination1";



const VerifierDetailsTab = ({ verifierID,verifierStatus, refetchs, setRefetchs,isExternalVerifierUser }) => {

    const [eventList, setEventList] = useState([]);
    const navigate = useNavigate();
    const [sponsors, setSponsors] = useState([]);
    const [notesList, setNotesList] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [activeTab, setActiveTab] = useState('Accreditations');
    const [loading, setLoading] = useState(true);
    const [documentList, setDocumentList] = useState([]);
    const [verifierExamList, setVerifierExamList] = useState([]);
    const [verifierInvoiceList, setVerifierInvoiceList] = useState([]);
    const [sortByColumnNameInvoice, setSortByColumnNameInvoice] = useState('CreatedDateTime');
    const [sortByColumnNameExam, setSortByColumnNameExam] = useState('CreatedDateTime');
    const [sortOrderInvoice, setSortOrderInvoice] = useState('DESC');
    const [sortOrderExam, setSortOrderExam] = useState('DESC');
    const [columnClickCounterInvoice, setColumnClickCounterInvoice] = useState(1);
    const [columnClickCounterExam, setColumnClickCounterExam] = useState(1);
    const [searchName, setSearchName] = useState('');
    const [itemsPerPageInvoice, setItemsPerPageInvoice] = useState(100);
    const [itemsPerPageExam, setItemsPerPageExam] = useState(100);
    const [pageNumberInvoice, setPageNumberInvoice] = useState(1);
    const [pageNumberExam, setPageNumberExam] = useState(1);
    const [pageCountExam, setPageCountExam] = useState(1);
    const [pageCountInvoice, setPageCountInvoice] = useState(1);
    const [searchFilterInvoice, setSearchFilterInvoice] = useState('Client ID');
    const [searchFilterExam, setSearchFilterExam] = useState('Client ID');
    const [searchTypeInvoice, setSearchTypeInvoice] = useState('text');
    const [searchTypeExam, setSearchTypeExam] = useState('text');
    const [searchCriteriaInvoice, setSearchCriteriaInvoice] = useState([]);
    const [searchCriteriaExam, setSearchCriteriaExam] = useState([]);
    const [searchValueInvoice, setSearchValueInvoice] = useState('');
    const [searchValueExam, setSearchValueExam] = useState('');
    const [eStatusList , setEStatusList] = useState([]);
    const [iStatusList , setIStatusList] = useState([]);
    const [eTypeList , setETypeList] = useState([]);
    const [dropdownOptionsExam, setDropdownOptionsExam] = useState([]);
    const [dropdownOptionsInvoice, setDropdownOptionsInvoice] = useState([]);
    const [communicationList, setCommunicationList] = useState([]);
    const [accreditationsList, setAccreditationsList] = useState([]);
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    
    const getNotesList = () => {
      fetch(`${config.apiUrl}${apiconfig.getVerifierNotesList}${verifierID}`, {
        method: "GET",
        headers: {

            Authorization: `Bearer ${localStorage.getItem("token")}`

        }
      })
        .then((res) => res.json())
        .then((data) => {
          setNotesList(data);
        })
        .catch((err) => {
          console.log("Error: ", err);
          navigate("/error-page");
        });
    };
    const getEventList = () => {
      fetch(`${config.apiUrl}${apiconfig.getVerifierEventsID}${verifierID}`, {
        method: "GET",
        headers: {

            Authorization: `Bearer ${localStorage.getItem("token")}`

        }
      })
        .then((res) => res.json())
        .then((data) => {
          setEventList(data);
        })
        .catch((err) => {
          console.log("Error: ", err);
          navigate("/error-page");
        });
    };
    const getDocumentList = () => {
        fetch(`${config.apiUrl}${apiconfig.getDocumentList}${verifierID}`, {
          method: "GET",
          headers: {

            Authorization: `Bearer ${localStorage.getItem("token")}`

        }
        })
          .then((res) => res.json())
          .then((data) => {
            setDocumentList(data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            navigate("/error-page");
          });
    };
    const fetchData = async () => {
      try {
          const response = await fetch(`${config.apiUrl}${apiconfig.getSponsorByVerifier}${verifierID}`, {
              method: 'GET',
              headers: {

                Authorization: `Bearer ${localStorage.getItem("token")}`

            }
          }).then((response) => {
              return response.json();
          })
          let fetchedSponsors = [];
          for (let i = 0; i < response.length; i++) {
              fetchedSponsors.push(response[i]);
          }
          setSponsors(fetchedSponsors);
      }
      catch (error) {
          console.log("Error: ", error);
          navigate("/error-page");
      }finally {
        setLoading(false); 
      }
    };

    const getCommunicationList = () => {
        fetch(`${config.apiUrl}${apiconfig.getVerifierCommunicationList}${verifierID}`, {
          method: "GET",
          headers: {
  
              Authorization: `Bearer ${localStorage.getItem("token")}`
  
          }
        })
          .then((res) => res.json())
          .then((data) => {
            setCommunicationList(data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            navigate("/error-page");
          });
      };
      const getAccreditationsList = () => {
        fetch(`${config.apiUrl}${apiconfig.getVerifierAccreditationsList}${verifierID}`, {
          method: "GET",
          headers: {
  
              Authorization: `Bearer ${localStorage.getItem("token")}`
  
          }
        })
          .then((res) => res.json())
          .then((data) => {
            setAccreditationsList(data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            navigate("/error-page");
          });
      };
    // const getExamTypeList = async () => {
    //     try{
    //       const response =  await fetch(`${config.apiUrl}${apiconfig.getExamTypes}`, {
    //             method: 'GET',
    //             headers: {
    //                 Authorization: `Bearer ${localStorage.getItem("token")}`
    //             }
    //         });
    //         if (response.ok) {
    //             var result = await response.json();
    //             // result = result.slice(1);
    //             const option = result.map((examType) => ({
    //                 text: examType.name,
    //                 value: examType.id,
    //               }));
    //             setETypeList(option);
    //         } else {
    //             const errorText = await response.text();
    //             const apiname = `${apiconfig.getExamTypes}`;
    //             navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
    //         }
    //         }catch(error){
    //             console.log(error.message);
    //             navigate(`/error-page`); 
    //         }
    //     }

    // const getExamStatusList = async () => {
    //         try{
    //           const response =  await fetch(`${config.apiUrl}${apiconfig.getExamStatus}`, {
    //                 method: 'GET',
    //                 headers: {
    //                     Authorization: `Bearer ${localStorage.getItem("token")}`
    //                 }
    //             });
    //             if (response.ok) {
    //                 var result = await response.json();
    //                 result = result.slice(1);
    //                 setEStatusList(result);
    //             } else {
    //                 const errorText = await response.text();
    //                 const apiname = `${apiconfig.getExamStatus}`;
    //                 navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
    //             }
    //             }catch(error){
    //                 console.log(error.message);
    //                 navigate(`/error-page`); 
    //             }
    //     }

    // const getInvoiceStatusList = async () => {
    //             try{
    //               const response =  await fetch(`${config.apiUrl}${apiconfig.getInvoiceStatus}`, {
    //                     method: 'GET',
    //                     headers: {
    //                         Authorization: `Bearer ${localStorage.getItem("token")}`
    //                     }
    //                 });
    //                 if (response.ok) {
    //                     var result = await response.json();
    //                     result = result.slice(1);
    //                     setIStatusList(result);
    //                 } else {
    //                     const errorText = await response.text();
    //                     const apiname = `${apiconfig.getInvoiceStatus}`;
    //                     navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
    //                 }
    //                 }catch(error){
    //                     console.log(error.message);
    //                     navigate(`/error-page`); 
    //                 }
    //     }





    // useEffect(() => {
    //     getExamTypeList();
    //     getExamStatusList();
    //     getInvoiceStatusList();
    // }, [refetch])

    const searchOptionsInvoice = [
        {
            value: 'Client ID', 
            text: 'Client ID', 
            type: 'text',
            query: '&ClientID='
        },
        {
            value: 'Invoice Number', 
            text: 'Invoice Number', 
            type: 'text',
            query: '&InvoiceNo='
        },
        {
            value: 'Invoice Status', 
            text: 'Invoice Status', 
            type: 'dropdown',
            query: '&InvoiceStatus=',
            dropdownOptions: iStatusList
        } 
    ];
    
    const searchOptionsExam = [
        {
            value: 'Client ID', 
            text: 'Client ID', 
            type: 'text',
            query: '&ClientID='
        }, 
        {
            value: 'Exam Type', 
            text: 'Exam Type', 
            type: 'dropdown',
            query: '&ExamType=',
            dropdownOptions: eTypeList
        },
        {
            value: 'Sponsor Name', 
            text: 'Sponsor Name', 
            type: 'text',
            query: '&ClientName='
        }, 
        {
            value: 'Exam Status', 
            text: 'Exam Status', 
            type: 'dropdown',
            query: '&ExamStatus=',
            dropdownOptions: eStatusList
        }
    ];


        {/* HOOKS */} 
        useEffect(() => {
            getVerifierExamList()
        }, [refetch, itemsPerPageExam, pageNumberExam, sortByColumnNameExam, sortOrderExam, searchCriteriaExam]);

        useEffect(() => {
            getVerifierInvoiceList();
        }, [refetch, itemsPerPageInvoice, pageNumberInvoice, sortByColumnNameInvoice, sortOrderInvoice, searchCriteriaInvoice]);
    
        const getVerifierInvoiceList = async () => {

            try {
                let filtersQuery = '';
                if(searchCriteriaInvoice.length > 0){
                    filtersQuery = '';
                    searchCriteriaInvoice.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`);
                }
                const response = await fetch(`${config.apiUrl}${apiconfig.getVerifierInvoiceList}?VerifierID=${verifierID}&PageSize=${itemsPerPageInvoice}&PageNumber=${pageNumberInvoice}&SortColumn=${sortByColumnNameInvoice}&SortOrder=${sortOrderInvoice}${filtersQuery}`, {
                    method: 'GET',
                    headers: {

                        Authorization: `Bearer ${localStorage.getItem("token")}`

                    }
                });
                if (response.ok) {
                    const result = await response.json();
                    let fetchedInvoice = [];
                    for (let i = 0; i < result.length; i++) {
                        fetchedInvoice.push(result[i]);
                    }
                    setVerifierInvoiceList(fetchedInvoice);
                    console.log('Inv',fetchedInvoice);
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.getVerifierInvoiceList}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
             }
            catch (error) {
                console.log(error.message);
                navigate(`/error-page`);
            }
        };  

        const getVerifierExamList = async () => {
            try {
                let filtersQuery = '';
                if(searchCriteriaExam.length > 0){
                    filtersQuery = '';
                    searchCriteriaExam.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`);
                }
                const response = await fetch(`${config.apiUrl}${apiconfig.getVerifierExamList}?VerifierID=${verifierID}&PageSize=${itemsPerPageExam}&PageNumber=${pageNumberExam}&SortColumn=${sortByColumnNameExam}&SortOrder=${sortOrderExam}${filtersQuery}`, {
                    method: 'GET',
                    headers: {

                        Authorization: `Bearer ${localStorage.getItem("token")}`

                    }
                });
                if (response.ok) {
                    const result = await response.json();
                    let fetchedExam = [];
                    for (let i = 0; i < result.length; i++) {
                        fetchedExam.push(result[i]);
                    }
                    setVerifierExamList(fetchedExam);
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.getVerifierExamList}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
             }
            catch (error) {
                console.log(error.message);
                navigate(`/error-page`);
            }
        };  
        useEffect(() => {
            if(verifierInvoiceList.length > 0){
                setPageCountInvoice(Math.ceil(verifierInvoiceList.length / itemsPerPageInvoice));
            }
        }, [verifierInvoiceList]);

        useEffect(() => {
            if(verifierExamList.length > 0){
                setPageCountExam(Math.ceil(verifierExamList.length / itemsPerPageExam));
            }
        }, [verifierExamList]);

    useEffect(() => {
      fetchData();
      getNotesList();
      getEventList();
      getDocumentList();
      getVerifierExamList();
      getVerifierInvoiceList();
      getCommunicationList();
      getAccreditationsList();
    }, [refetch, refetchs]);

    const onPageClick = (e) => {
        setPageNumberExam(e.selected + 1);
    };

    return (
        <>
        {loading && <Loader />}
            <TabContainer>
                <TabList activeTab={activeTab} setActiveTab={setActiveTab} isExternalVerifierUser={isExternalVerifierUser} />  
                <TabContent>
                <TabPanel activeTab={activeTab} tabName='Accreditations'>
                        <tr>
                            <th style={{width:'20%'}}>Level(s)</th>
                            <th style={{width:'15%'}}>Approved Date</th>
                            <th style={{width:'15%'}}>Renewal Date</th>
                            <th style={{width:'15%'}}>Renewal Extension Date</th>
                            <th style={{width:'10%'}}>Eligible for Upgrade</th>
                            <th style={{width:'10%'}}>Suspended</th>
                            <th style={{width:'15%'}}>Suspension Date</th>
                        </tr>
                        {!accreditationsList.length  ?
                        (
                          <tr className="alt-row">
                          <td colSpan="7">&nbsp; No accreditations exist.</td>
                          </tr>
                        ):
                        (accreditationsList.map(accreditations => <AccreditationsList key={accreditationsList.indexOf(accreditations)} verifier={verifierID} accreditations={accreditations} refetch={refetch} setRefetch={setRefetch}/>
                        ))}
                    </TabPanel>
                    <TabPanel activeTab={activeTab} tabName='Exams'>
                    {/* <AdvancedSearch searchOptions={searchOptionsExam} searchType={searchTypeExam} setSearchType={setSearchTypeExam} searchFilter={searchFilterExam} setSearchFilter={setSearchFilterExam} searchValue={searchValueExam} setSearchValue={setSearchValueExam} dropdownOptions={dropdownOptionsExam} setDropdownOptions={setDropdownOptionsExam}  searchCriteria={searchCriteriaExam} setSearchCriteria={setSearchCriteriaExam} /> */}
                    {/* <div className="card-footer d-flex justify-content-between">
                                        {
                                            verifierExamList.length > 0 
                                                ?
                                                    <div className="align-self-center font-weight-bold">{verifierExamList[0].totalCount} items found</div>
                                                :
                                                    <div className="align-self-center font-weight-bold">No items found</div>
                                        }
                                        <nav>
                                            <Pagination handlePageClick={onPageClick} pageCount={pageCountExam} pageRange={3} />
                                        </nav>
                    </div> */}
                    <CustomTable headerCells={!isExternalVerifierUser ? ExamTableHeaderCells : ExamTableHeaderCellsWithOutAction} sortByColumnName={sortByColumnNameExam} sortOrder={sortOrderExam} columnClickCounter={columnClickCounterExam} setSortByColumnName={setSortByColumnNameExam} setSortOrder={setSortOrderExam} setColumnClickCounter={setColumnClickCounterExam} isExternalVerifierUser={isExternalVerifierUser}>
                    {
                            !verifierExamList.length  
                                ?
                                <tr className="alt-row">
                                    <td colSpan="10">&nbsp; No Exam(s) exist.</td>
                                </tr>
                                :
                                verifierExamList.map((exam, index) => <VerifiersExamList key={index} verifier={verifierID} verifierStatus={verifierStatus} searchName={searchName.toLowerCase()} exam={exam} refetch={refetch} setRefetch={setRefetch} refetchs={refetchs} setRefetchs={setRefetchs} isExternalVerifierUser={isExternalVerifierUser} />)
                        }
                    </CustomTable>
                    </TabPanel>
                    <TabPanel activeTab={activeTab} tabName='Invoices'>
                    {/* <AdvancedSearch searchOptions={searchOptionsInvoice} searchType={searchTypeInvoice} setSearchType={setSearchTypeInvoice} searchFilter={searchFilterInvoice} setSearchFilter={setSearchFilterInvoice} searchValue={searchValueInvoice} setSearchValue={setSearchValueInvoice} dropdownOptions={dropdownOptionsInvoice} setDropdownOptions={setDropdownOptionsInvoice}   searchCriteria={searchCriteriaInvoice} setSearchCriteria={setSearchCriteriaInvoice} /> */}
                    {/* <div className="card-footer d-flex justify-content-between">
                                        {
                                            verifierInvoiceList.length > 0 
                                                ?
                                                    <div className="align-self-center font-weight-bold">{verifierInvoiceList[0].totalCount} items found</div>
                                                :
                                                    <div className="align-self-center font-weight-bold">No items found</div>
                                        }
                                        <nav>
                                            <Pagination handlePageClick={onPageClick} pageCount={pageCountInvoice} pageRange={3} />
                                        </nav>
                    </div> */}
                    <CustomTable headerCells = {!isExternalVerifierUser ?  InvoiceTableHeaderCells : InvoiceTableHeaderCellsWithOutActions } sortByColumnName={sortByColumnNameInvoice} sortOrder={sortOrderInvoice} columnClickCounter={columnClickCounterInvoice} setSortByColumnName={setSortByColumnNameInvoice} setSortOrder={setSortOrderInvoice} setColumnClickCounter={setColumnClickCounterInvoice}>
                        {
                            !verifierInvoiceList.length  
                                ?
                                <tr className="alt-row">
                                    <td colSpan="9">&nbsp; No Invoice(s) exist.</td>
                                </tr>
                                :
                                verifierInvoiceList.map((invoice, index) => <VerifiersInvoiceList key={index} verifier={verifierID} verifierStatus={verifierStatus} searchName={searchName.toLowerCase()} invoice={invoice} refetch={refetch} setRefetch={setRefetch} refetchs={refetchs} setRefetchs={setRefetchs} isExternalVerifierUser={isExternalVerifierUser}/>)
                        }
                    </CustomTable>
                    </TabPanel>
                    <TabPanel activeTab={activeTab} tabName='Verifications'>
                        <tr>
                            <th style={{width:'10%'}}>Current Exam Level</th>
                            <th style={{width:'10%'}}>Lapse Counter</th>
                            <th style={{width:'15%'}}>Verification No.</th>
                            <th style={{width:'10%'}}>Eligible for Next level</th>
                            <th style={{width:'10%'}}>Application Status</th>
                        </tr>
                        <tr>
                            <td>Level 3+</td>
                            <td>280 days</td>
                            <td>5</td>
                            <td>Yes</td>
                            <td>Open</td>
                        </tr> 
                        <tr>
                            <td>Level 2+</td>
                            <td>289 days</td>
                            <td>5</td>
                            <td>Yes</td>
                            <td>Open</td>
                        </tr>
                    </TabPanel>
                    <TabPanel activeTab={activeTab} tabName='Sponsors'>
                        <tr>
                            <th style={{width:'20%'}}>Sponsor Name</th>
                            <th style={{width:'10%'}}>Client ID</th>
                            <th style={{width:'15%'}}>Email Address</th>
                            <th style={{width:'10%'}}>Phone Number</th>
                            <th style={{width:'10%'}}>Region</th>
                            <th style={{width:'10%'}}>Country</th>
                            <th style={{width:'10%'}}>Added By</th>
                            <th style={{width:'10%'}}>Is Primary?</th>
                            { !isExternalVerifierUser ?
                            (<th style={{width:'5%'}}>Actions</th>)
                            :
                            (<></>)
                            }
                        </tr> 
                        {
                            !sponsors.length  
                                ?
                                <tr className="alt-row">
                                    <td colSpan="9">&nbsp; Sponsor does not exist</td>
                                </tr>
                                :
                                sponsors.map((sponsor, index) => <VerifierSponsorList key={index} verifier={verifierID} sponsor={sponsor} refetch={refetch} setRefetch={setRefetch} refetchs={refetchs} setRefetchs={setRefetchs} isExternalVerifierUser={isExternalVerifierUser} />)
                        }
                    </TabPanel>
                    <TabPanel activeTab={activeTab} tabName='Documents'>
                        <tr>
                            <th style={{width:'20%'}}>Document Type</th>
                            <th style={{width:'25%'}}>Document Name</th>
                            <th style={{width:'20%'}}>Comments</th>
                            <th style={{width:'15%'}}>Created By</th>
                            <th style={{width:'15%'}}>Date Created</th>
                            { !isExternalVerifierUser ?
                            (<th style={{width:'5%'}}>Actions</th>)
                            :
                            (<></>)
                            }
                        </tr>
                        {!documentList.length  ?
                        (
                          <tr className="alt-row">
                          <td colSpan="6">&nbsp; No documents exist.</td>
                          </tr>
                        ):
                        (documentList.map(document => <DocumentList key={documentList.indexOf(document)} verifier={verifierID} document={document} refetch={refetch} setRefetch={setRefetch} isExternalVerifierUser={isExternalVerifierUser}/>
                        ))}
                    </TabPanel>
                    {isReadOnlyAccess1  === "FullAccess" ?
                    <TabPanel activeTab={activeTab} tabName='Notes'>
                        <tr>
                        <th style={{width:'20%'}}>Notes Action</th>
                            <th style={{width:'45%'}}>Notes Description</th>
                            <th style={{width:'15%'}}>Created By</th>
                            <th style={{width:'15%'}}>Date Created</th>
                            <th style={{width:'5%'}}>Actions</th>
                        </tr>
                        {
                            !notesList.length  
                                ?
                                <tr className="alt-row">
                                    <td colSpan="4">&nbsp; Notes does not exist</td>
                                </tr>
                                :
                                notesList.map((note, index) => <CommentList key={index} verifier={verifierID} notes={note} refetch={refetch} setRefetch={setRefetch}/>)
                        }
                    </TabPanel>
                    :
                    <></>
                    }
                    {isReadOnlyAccess1  === "FullAccess" || isExternalVerifierUser ?
                    <TabPanel activeTab={activeTab} tabName='Communications'>
                        <tr>
                            <th style={{width:'65%'}}>Description</th>
                            <th style={{width:'15%'}}>Created By</th>
                            <th style={{width:'15%'}}>Date Created</th>
                            <th style={{width:'5%'}}>Actions</th>
                        </tr>
                        {
                            !communicationList.length  
                                ?
                                <tr className="alt-row">
                                    <td colSpan="4">&nbsp; Communications does not exist</td>
                                </tr>
                                :
                                communicationList.map((comm, index) => <CommunicationList key={index} verifier={verifierID} communication={comm} refetch={refetch} setRefetch={setRefetch} isExternalVerifierUser={isExternalVerifierUser}/>)
                        }
                    </TabPanel>
                    :
                    <></>
                    }
                    {isReadOnlyAccess1  === "FullAccess" ?
                    <TabPanel activeTab={activeTab} tabName='Events'>
                        <tr>
                            <th style={{width:'40%'}}>Event Description</th>
                            <th style={{width:'20%'}}>Event Type</th>
                            <th style={{width:'20%'}}>Created By</th>
                            <th style={{width:'20%'}}>Date Created</th>
                        </tr>
                        {
                            eventList.map((event, index) => (
                                <tr key={index}>
                                    <td className="text-break">{event.description}</td>
                                    <td>{event.eventType}</td>
                                    <td>{event.createdUser}</td>
                                    <td>{event.createdDate}</td>
                                </tr>
                            ))
                        }
                    </TabPanel>
                    :
                    <></>
                    }
                </TabContent>
            </TabContainer>
        </>
    );
};

export default VerifierDetailsTab;