import { useState } from "react";
import { Label } from "reactstrap";
import config from "../../api/config";
import apiconfig from "../../api/apiconfig";
import { Slide, ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CommonCustomModal from "../Common/CommonCustomModal";
import RemoveVerifiersSponsor from "./RemoveVerifiersSponsor";
import CommonModalWithOutButton from "../Common/CommonModalWithOutButton";
import CommonModalWithImage from "../Common/CommonModalWithImage";
import IsAccessForVerifierCombination1 from "../Common/IsAccessForVerifieCombination1";
import IsAccessForVerifierCombination2 from "../Common/IsAccessForAccreditationCombination";


const VerifierSponsorList = ({ verifier, sponsor, refetch, setRefetch,refetchs,setRefetchs,isExternalVerifierUser}) => {

  const [removeSPonsorModal, setRemoveSponsorModal] = useState(false);
  const userID = localStorage.getItem("userID");
  const [warningSponsorModal, setWarningSponsorModal] = useState(false);
  const [addPrimaryModal, setAddPrimaryModal] = useState(false);
  const [removePrimaryModal, setRemovePrimaryModal] = useState(false);
  const [isPrimaryClicked, setIsPrimaryClicked] = useState(false);
  const navigate = useNavigate();
  const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
  const isReadOnlyAccess2 = IsAccessForVerifierCombination2();

  const toggleForRemoveSponsorModal = () => {
    setRemoveSponsorModal(!removeSPonsorModal);
  };

  const toggleForAddPrimaryModal = () => {
    setAddPrimaryModal(!addPrimaryModal);
    setIsPrimaryClicked(true);
  } 
  const toggleForRemovePrimaryModal = () => {
    setRemovePrimaryModal(!removePrimaryModal);
    setIsPrimaryClicked(false);
  } 

  const toggleForWarningSponsorModal = () => {
    setWarningSponsorModal(!warningSponsorModal);
}
    const addRemovePrimarySponsor= async () => {
        var primary = 0 ;
        if(isPrimaryClicked){
            primary = 1;
        }
        const requestBody = {
            "userID" : userID,
            "isPrimary" : primary,
            "sponsorID" : sponsor.id,
            "veriferID": verifier,
            "notes" : ''
        };
        // const validationErrors = validateForm(comments.trim());
        //     setFormErrors(validationErrors);
        //     if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.addRemovePrimarySponsor}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if (response.saveErrorMessage === "Sponsor has been set as primary sponsor.") {
                toast.success("Sponsor has been set as primary sponsor.", {
                  position: "top-right",
                  autoClose: 10000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Slide,
                });
            setRefetch(!refetch);
            setRefetchs(!refetchs);  
            toggleForAddPrimaryModal();
            }else if(response.saveErrorMessage === "Sponsor has been removed from primary sponsor."){
              toast.success("Sponsor has been removed from primary sponsor.", {
                position: "top-right",
                autoClose: 10000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Slide,
              });
          setRefetch(!refetch);
          setRefetchs(!refetchs);  
          toggleForRemovePrimaryModal();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
       // }
      };

  return (
    <>
      <tr>
      {!isExternalVerifierUser?
      <>
        {isReadOnlyAccess1  === "FullAccess" ?   
        <td className="text-break"><a className="text-danger"  href={`/viewsponsor/${sponsor.id}`}>{sponsor.sponsorName}</a></td>
        :
        <td>{sponsor.sponsorName}</td>
        }
        <td className="text-break">{sponsor.clientID}</td>
        <td>{sponsor.email}</td>
        <td>{sponsor.phoneNumber}</td>
        <td>{sponsor.region}</td>
        <td>{sponsor.country}</td>
        <td>{sponsor.createdUser}</td>
        {sponsor.isPrimary === "True" ? (
          <td>
            <span className="badge badge-success">Yes</span>
          </td>
        ) : (
          <td>
            <span className="badge badge-danger">No</span>
          </td>
        )}
        {isReadOnlyAccess1  === "FullAccess" ? 
        <td className="text-right actions">
          <a
            className="dropdown-toggle icon"
            href="#"
            data-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="mdi mdi-more"></i>
            <span className="sr-only">Menu</span>
          </a>
          <div className="dropdown-menu">
            {/* {sponsor.isPrimary === "True" ? (
              <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {toggleForWarningSponsorModal();}}>
              <span className="icon mdi mdi-delete"></span>Archive
              </a>
            ):( */}
              <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {toggleForRemoveSponsorModal();}}><span className="icon mdi mdi-delete"></span>Remove</a>
              {sponsor.isPrimary === "True" ? (
                <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {toggleForRemovePrimaryModal();}}><span className="icon mdi mdi-close-circle"></span>Remove as Primary</a>

              ) : (
                <a className="dropdown-item" style={{ cursor: "pointer" }} onClick={() => {toggleForAddPrimaryModal();}}><span className="icon mdi mdi-collection-plus"></span>Set as Primary</a>
              )}
            {/* )} */}
           
          </div>
        </td>
        :
        <td></td>
        }
        </>
        :
        <>
        <td>{sponsor.sponsorName}</td>
        <td>{sponsor.clientID}</td>
        <td>{sponsor.email}</td>
        <td>{sponsor.phoneNumber}</td>
        <td>{sponsor.region}</td>
        <td>{sponsor.country}</td>
        <td>{sponsor.createdUser}</td>
        {sponsor.isPrimary === "True" ? (
          <td>
            <span className="badge badge-success">Yes</span>
          </td>
        ) : (
          <td>
            <span className="badge badge-danger">No</span>
          </td>
        )}
        </>
        }
      </tr>

      <CommonCustomModal modal={removeSPonsorModal}  title='' toggler={toggleForRemoveSponsorModal} heading='Remove Sponsor'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
      <RemoveVerifiersSponsor toggler={toggleForRemoveSponsorModal} verifier = {verifier} sponsor ={sponsor}  refetchs={refetch} setRefetchs={setRefetch}/>
      </CommonCustomModal>
      {/* <CommonModalWithOutButton modal={warningSponsorModal} title='Archive Sponsor' toggler={toggleForWarningSponsorModal} heading='Archive Sponsor'>
      <Label className="col-form-label">{'You can’t delete the primary sponsor'}</Label>
      </CommonModalWithOutButton> */}

      <CommonModalWithImage modal={addPrimaryModal} title='' toggler={toggleForAddPrimaryModal} heading='Add as Primary' triggerMethodOnSaveButtonClick={addRemovePrimarySponsor} buttonText='Yes'>
          <Label className="col-form-label">{'Are you sure you want to add the Client/Sponsor as Primary '}{'?'}</Label>
      </CommonModalWithImage>
      <CommonModalWithImage modal={removePrimaryModal} title='' toggler={toggleForRemovePrimaryModal} heading='Remove as Primary' triggerMethodOnSaveButtonClick={addRemovePrimarySponsor} buttonText='Yes'>
          <Label className="col-form-label">{'Are you sure you want to remove the Client/Sponsor as Primary ? In doing this, the Verifier will not be able to be Upgraded/Downgraded/Renewed until a new Primary Client/Sponsor has been set. Any current active Exams/Invoices will need to be Cancelled and set-up again with the new Primary Client/Sponsor.'}</Label>
      </CommonModalWithImage>
    </>
  );
};

export default VerifierSponsorList;
