import React, { useState, useEffect } from 'react'
import Layout from '../../Layout'
import Verifications from '../Verifiers/VerifierDashboard/Verifications'
import { ToastContainer} from 'react-toastify'
import Loader from '../Common/Loader'
import config from '../../api/config'
import apiconfig from '../../api/apiconfig'
import { useNavigate} from "react-router-dom";
import AdvancedSearchByIDNew from '../Common/AdvancedSearchByIDNew'
import CustomTable from '../Common/CustomTable'
import Pagination from '../Common/Pagination'
import Selector from '../Common/Selector'
import { TableHeaderCells } from './TableHeaderCells'
import { numberOfEntriesPerPageOptions } from '../Helper/NumberOfEntriesPerPageOptions'
import { TiArrowUnsorted } from 'react-icons/ti';

const VerifierDashboard = () => {

    /* States & Variables */
    const userID = localStorage.getItem('userID')
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const [isLoading, setIsLoading] = useState(false)
    const [aiportListForVerifier , setAirportListForVerifier] = useState([]);
    const navigate = useNavigate();
    const [searchName, setSearchName] = useState('');
    const [refetch, setRefetch] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [sortOrder, setSortOrder] = useState('DESC');
    const [sortByColumnName, setSortByColumnName] = useState('ApplicationID');
    const [columnClickCounter, setColumnClickCounter] = useState(1);
    const [searchFilter, setSearchFilter] = useState('Start Date');
    const [searchType, setSearchType] = useState('date');
    const [searchCriteria, setSearchCriteria] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [accLevelList, setAccLevelList] = useState([]);


    /* Logics */
    if(userRoles.length === 1){
        userRoles.map((role) => {
            // for what purpose?
            if(role.name === "Verifier User" && role.isfirstlogin === 0){
                window.location.href='/ChangePassword'
            }
        })
    }

        {/* HOOKS */} 
        useEffect(() => {
            fetchData();
        }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria]);
    
        const fetchData = async () => {
            try {
                let filtersQuery = '';
                if(searchCriteria.length > 0){
                    filtersQuery = '';
                    searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`);
                }
                const response = await fetch(`${config.apiUrl}${apiconfig.getAirportAccreditationListForVerifier}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}&LoggedInUserID=${userID}&${filtersQuery}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                });
                if (response.ok) {
                    const result = await response.json();
                    let fetchedList = [];
                    for (let i = 0; i < result.length; i++) {
                        fetchedList.push(result[i]);
                    }
                    setAirportListForVerifier(fetchedList);
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.getAirportAccreditationListForVerifier}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
             }
            catch (error) {
                console.log(error.message);
                navigate(`/error-page`);
            }
        };

        useEffect(() => {
            if(aiportListForVerifier.length > 0){
                setPageCount(Math.ceil(aiportListForVerifier[0].totalCount / itemsPerPage));
            }
        }, [aiportListForVerifier]);
    



    const onPageClick = (e) => {
        setPageNumber(e.selected + 1);
    };

    useEffect(() => {
        const getAccLevelList = async () => { 
            try {
            const response = await  fetch(`${config.apiUrl}${apiconfig.getApplicationLevelList}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                // result = result.slice(1);
                const option  = result.map((level)=>({
                text : level.title,
                value : level.id
                }));
                setAccLevelList(option);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getApplicationLevelList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
        getAccLevelList();
    }, [refetch]);

    const statusOptions =  [
        {text: 'Admin comments - Airport to address', value: 'Admin comments - Airport to address'},
        {text: 'Admin comments - Verifier to address', value: 'Admin comments - Verifier to address'},
        {text: 'Airport resubmitted - In Admin Verification', value: 'Airport resubmitted - In Admin Verification'},
        {text: 'Airport resubmitted - In Verification', value: 'Airport resubmitted - In Verification'},
        {text: 'Approved', value: 'Approved'},
        {text: 'Draft', value: 'Draft'},
        {text: 'In Admin Verification', value: 'In Admin Verification'},
        {text: 'In Verification', value: 'In Verification'},
        {text: 'Verifier comments - Airport to address', value: 'Verifier comments - Airport to address'},
        {text: 'Verifier resubmitted - In Admin Verification', value: 'Verifier resubmitted - In Admin Verification'},
    ]

    const searchOptions = [
        {
            value: 'Start Date', 
            text: 'Start Date',
            type: 'date',
            query: '&StartDate='
        },
        {
            value: 'End Date', 
            text: 'End Date',
            type: 'date',
            query: '&EndDate='
        },
        {
            value: 'Airport Group Name', 
            text: 'Airport Group Name', 
            type: 'text',
            query: '&AirportGroupName=',
        },
        {
            value: 'Airport Name', 
            text: 'Airport Name', 
            type: 'text',
            query: '&AirportName=',
        },
        {
            value: 'Accreditation Level', 
            text: 'Accreditation Level', 
            type: 'dropdownbyID',
            query: '&AccreditationLevel=',
            dropdownOptions: accLevelList,
        },
        // {
        //     value: 'Status',
        //     text: 'Status', 
        //     type: 'dropdown',
        //     query: '&Status=',
        //     dropdownOptions:statusOptions,
        // },
    ]

    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(aiportListForVerifier.length !== 0){
            if(aiportListForVerifier[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])
    

    return (
        <Layout>
            {
                isLoading 
                  ?
                  <Loader />
                  :
                  <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header yallow mb-4">
                        <div className="container-header">
                            <div className="row" style={{padding: '15px'}}>
                                <div className="col">
                                    <img src={require('../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">Verifier Dashboard</h3>
                                    </div>
                                <div className="col">
                                </div>
                           </div>
                        </div>
                    </div>
                    <div className="container-header">
                            <h3 className="page-header-title">Search Application</h3>
                            <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    Use the filter categories and search field below to find application, expand the preview to see more information.
                                </p>
                                <AdvancedSearchByIDNew searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} searchText={searchText} setSearchText={setSearchText} setPageNumber={setPageNumber} />
                                {
                                    aiportListForVerifier.length > 0
                                        ?
                                        <div className="font-weight-bold">{aiportListForVerifier[0].totalCount} items found</div>
                                        :
                                        <div className="font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                            <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                            {!aiportListForVerifier.length
                                ?
                                <tr className="alt-row">
                                    <td colSpan="9">&nbsp; Application does not exist.</td>
                                </tr>
                                :
                                aiportListForVerifier.map((applist, index) => <Verifications key={index} aiportListForVerifier={applist} searchName={searchName.toLowerCase()}/>)
                            }
                            </CustomTable>
                            <div className="d-flex justify-content-end">
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                             </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </Layout>
    )
}

export default VerifierDashboard