import React from 'react';

export const TableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Airport Group',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '33%' },
        isSortable: true,
        sortByColumnName: 'AirportGroup'
    },
    {
        id: 2,
        isHidden: false,
        name: 'Created User',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '33%' },
        isSortable: true,
        sortByColumnName: 'CreatedUser'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Created Date Time',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '33%' },
        isSortable: true,
        sortByColumnName: 'CreatedDate',
        type:'dropdown'
    },
    // {
    //     id: 4,
    //     isHidden: false,
    //     name: 'Is Small',
    //     colSpan: 1,
    //     hasChild: false,
    //     child: null,
    //     style:  { cursor: 'pointer', width: '25%' },
    //     isSortable: true,
    //     sortByColumnName: 'IsSmall',
    //     type:'dropdown'
    // },
    // {
    //     id: 8,
    //     isHidden: false,
    //     name: 'Actions',
    //     colSpan: 1,
    //     hasChild: false,
    //     child: null,
    //     style: { width: '1%' },
    //     isSortable: false,
    //     sortByColumnName: null
    // }
];