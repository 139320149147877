import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import { TableHeaderCells } from './TableHeaderCells'
import AdvancedSearch from '../../Common/AdvancedSearch'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { TiArrowUnsorted } from 'react-icons/ti'
import { MdOutlineSearch, MdAddCircleOutline, MdClose, MdSearch, MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import { Slide, ToastContainer, toast } from 'react-toastify'
import Exam from './Exam'
import Loader from '../../Common/Loader'

const Exams = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [examList, setExamList] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [sortColumn, setSortColumn] = useState('')
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const [selectedOption, setSelectedOption] = useState("")
    const [isNewColumnClicked, setIsNewColumnClicked] = useState(false)
    const [SponsorConfirmModal, setSponsorConfirmModal] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('DESC')
    const [sortByColumnName, setSortByColumnName] = useState('ModifiedDateTime')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Exam ID')
    const [searchType, setSearchType] = useState('text')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [examStatusList, setExamStatusList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [processingExamList, setProcessingExamList] = useState(false)
    const [examListProcessed, setExamListProcessed] = useState(false)
    const [processingExamStatusList, setProcessingExamStatusList] = useState(false)
    const [examStatusListProcessed, setExamStatusListProcessed] = useState(false)
    const searchOptions = [
        {
            value: 'Exam ID', 
            text: 'Exam ID',
            type: 'text',
            query: '&ExamID='
        },
        {
            value: 'External Exam Reference', 
            text: 'External Exam Reference',
            type: 'text',
            query: '&ExamExternalID='
        },
        {
            value: 'Exam Status', 
            text: 'Exam Status', 
            type: 'dropdown',
            query: '&ExamStatus=',
            dropdownOptions: examStatusList
        },
        {
            value: 'Verifier Name', 
            text: 'Verifier Name', 
            type: 'text',
            query: '&VerifierName=',
        },
        {
            value: 'Sponsor Name', 
            text: 'Sponsor Name', 
            type: 'text',
            query: '&SponsorName=',
        },
        {
            value: 'Invoice ID', 
            text: 'Invoice ID', 
            type: 'text',
            query: '&InvoiceID='
        },
        {
            value: 'Invoice Number', 
            text: 'Invoice Number', 
            type: 'text',
            query: '&InvoiceNo='
        },
    ]  

    /* Methods */
    const fetchData = async () => {
        try {
            let filtersQuery = '';
            if(searchCriteria.length > 0){
                filtersQuery = '';
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`);
            }
            console.log('executing getExams()...')
            setProcessingExamList(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getExams}?PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getExams()')
                const result = await response.json()
                let fetchedExams = []
                for (let i = 0; i < result.length; i++) {
                    fetchedExams.push(result[i])
                }
                setExamList(fetchedExams)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getExams}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setExamListProcessed(true)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const getExamStatusList = async () => {
        try{
            console.log('executing getExamStatus()...')
            setProcessingExamStatusList(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.getExamStatus}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getExamStatus()')
                var result = await response.json()
                result = result.slice(1)
                setExamStatusList(result)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getExamStatus}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setExamStatusListProcessed(true)
        }
        catch(error){
                console.log(error.message)
                navigate(`/error-page`)
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria])
    useEffect(() => {
        if(examList.length > 0){
            setPageCount(Math.ceil(examList[0].totalCount / itemsPerPage))
        }
    }, [examList])
    useEffect(() => {
        getExamStatusList()
    }, [refetch])
    useEffect(() => {
        if(processingExamList || processingExamStatusList){
            if(!isLoading)
                setIsLoading(true)
        }
        if(processingExamList && processingExamStatusList){
            if(examListProcessed && examStatusListProcessed){
                setIsLoading(false)
                setProcessingExamList(false)
                setExamListProcessed(false)
                setProcessingExamStatusList(false)
                setExamStatusListProcessed(false)
            }
        }
        if(processingExamList && !processingExamStatusList){
            if(examListProcessed){
                setIsLoading(false)
                setProcessingExamList(false)
                setExamListProcessed(false)
            }
        }
        if(!processingExamList && processingExamStatusList){
            if(examStatusListProcessed){
                setIsLoading(false)
                setProcessingExamStatusList(false)
                setExamStatusListProcessed(false)
            }
        }
    }, [processingExamList, processingExamStatusList, examListProcessed, examStatusListProcessed])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(examList.length !== 0){
            if(examList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage]) 

    return (
        <Layout>
            {
                isLoading
                  ?
                  <Loader />
                  :
                  <React.Fragment></React.Fragment>  
            }
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-orange mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Exam List</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                                <ol className="breadcrumb mb-0 mt-4">
                                    <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                    <li className="breadcrumb-item active">Exam List</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="container-header">
                        <h3 className="page-header-title">Search Exam</h3>
                        <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">
                                    {/* <strong>Search</strong>
                                    <br /> */}
                                    Use the filter categories and search field below to find exams.
                                </p>
                                <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                                {
                                    examList.length > 0
                                        ?
                                        <div className="align-self-center font-weight-bold">{examList[0].totalCount} items found</div>
                                        :
                                        <div className="align-self-center font-weight-bold">No items found</div>
                                }
                            </div>
                            <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                                <nav>
                                    {/* <div className="custom-control custom-checkbox custom-control-inline m-0">
                                        <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveSponsors} id="EInActive" />
                                        <label className="custom-control-label" htmlFor="EInActive">Exclude InActive</label>
                                    </div> */}
                                    <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                                </nav>
                            </div>
                        </div>
                        <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                            {examList.map(exam => <Exam key={examList.indexOf(exam)} exam={exam} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                        </CustomTable>
                        <div className="d-flex justify-content-end">
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </Layout>
    )
}

export default Exams