import React from 'react';

const TabContainer = ({ children }) => {
    return (
        <div className="tab-container mb-0"> 
            {children}
        </div>
    );
};

export default TabContainer;