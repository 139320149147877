import React from 'react'
import { htmlConverterReact } from 'html-converter-react'
import SubQuestionForConsolidatedErrorList from './SubQuestionForConsolidatedErrorList'

const ConsolidatedErrorList = ({ state, activeRightPane, setActiveRightPane, userType, scrollToQuestionViaQuestionRef, checkIfOtherDetailsIsIncomplete, checkIfSubSectionIsIncomplete, checkIfAnySubSectionOfSectionIsIncomplete, checkIfAnySubSectionIsIncomplete, isSubmitButtonClicked, isVerificationRequired, checkIncompletenessIfQuestionOfResponseTypeOtherHasPartiallyPreFilledResponses, checkWhetherAllRequiredAttributesWithinEachRowOfMatrixHasValue }) => {
    
    return (
        (isSubmitButtonClicked || (state.summaries.length !== 0 && activeRightPane === state.summaries[1].name)) && (checkIfAnySubSectionIsIncomplete() || checkIfOtherDetailsIsIncomplete())
            ?
            <div id="errors-list" style={{ margin: 10, padding: 15, backgroundColor: '#ffeded', borderStyle: 'solid', borderColor: 'red', borderWidth: 1, borderRadius: 6 }}>
                <label>Complete the missing information by clicking on the field concerned in the box below, or by navigating through the panel on the left.</label>
                {
                    checkIfOtherDetailsIsIncomplete() 
                        ? 
                        <h5>{state.default[2].name}</h5>
                        :
                        <React.Fragment></React.Fragment>
                }
                {
                    state.default[2].acaYear.required && (state.default[2].acaYear.value.length === 0 || state.default[2].acaYear.value === '0')
                        ?
                        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 5 }} onClick={() => { setActiveRightPane(state.default[2].name) }}>
                            <table className="table table-hover" style={{ backgroundColor: '#ffeded', cursor: 'pointer' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ borderRadius: 7, borderTop: 0, borderBottom: 0 }}>
                                            <span>
                                                <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                                &nbsp;&nbsp;
                                                <h7>ACA Year</h7>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                }
                {
                    !state.default[2].completed && state.default[2].airportBand.required
                        ?
                        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 5 }} onClick={() => { setActiveRightPane(state.default[2].name) }}>
                            <table className="table table-hover" style={{ backgroundColor: '#ffeded', cursor: 'pointer' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ borderRadius: 7, borderTop: 0, borderBottom: 0 }}>
                                            <span>
                                                <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                                &nbsp;&nbsp;
                                                <h7>Airport Band</h7>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                }
                {
                    !state.default[2].completed && state.default[2].timePeriodOfCarbonFootprint.required
                        ?
                        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 5 }} onClick={() => { setActiveRightPane(state.default[2].name) }}>
                            <table className="table table-hover" style={{ backgroundColor: '#ffeded', cursor: 'pointer' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ borderRadius: 7, borderTop: 0, borderBottom: 0 }}>
                                            <span>
                                                <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                                &nbsp;&nbsp;
                                                <h7>Time period for Carbon Footprint</h7>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                }
                {   
                    state.applicationType === 'Renew' && isVerificationRequired === 'No'
                        ?
                        <React.Fragment></React.Fragment>
                        :
                        !state.default[2].completed && state.default[2].verificationType.required
                            ?
                            <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 5 }} onClick={() => { setActiveRightPane(state.default[2].name) }}>
                                <table className="table table-hover" style={{ backgroundColor: '#ffeded', cursor: 'pointer' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ borderRadius: 7, borderTop: 0, borderBottom: 0 }}>
                                                <span>
                                                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                                    &nbsp;&nbsp;
                                                    <h7>Verification Type</h7>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            :
                            <React.Fragment></React.Fragment>
                }
                {
                    state.sections.map((section, section_index) => {
                        return checkIfAnySubSectionOfSectionIsIncomplete(section_index)
                            ?
                            <React.Fragment>
                                <h5>{section.title}</h5>
                                {
                                    section.subsections.map((subsection, subsection_index) => {
                                        return subsection.type === userType && checkIfSubSectionIsIncomplete(section_index, subsection_index)
                                            ?
                                            <React.Fragment>
                                                <h6 style={{ paddingLeft: 15 }}>{subsection.title}</h6>
                                                {
                                                    subsection.content.questions.map((question) => {
                                                        return !subsection.completed
                                                            ?
                                                            question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown' || question.responseType === 'other' || question.responseType === 'matrix' || question.responseType === 'none'
                                                                ?
                                                                question.required && question.isVisible
                                                                    && 
                                                                    (
                                                                        ((question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown') && question.lastSuccessfullySavedResponse.length === 0)
                                                                        ||
                                                                        (question.responseType === 'other' && ((question.lastSuccessfullySavedResponseOther === null || question.lastSuccessfullySavedResponseOther.length === 0) || checkIncompletenessIfQuestionOfResponseTypeOtherHasPartiallyPreFilledResponses(question)))
                                                                        ||
                                                                        (question.responseType === 'matrix' && ((question.lastSuccessfullySavedResponseMatrix === null || question.lastSuccessfullySavedResponseMatrix.length === 0) || !checkWhetherAllRequiredAttributesWithinEachRowOfMatrixHasValue(question)))
                                                                    )
                                                                    ?
                                                                    <React.Fragment>
                                                                        <div style={{ marginLeft: 20, marginRight: 20, marginBottom: 5 }} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                                            <table className="table table-hover" style={{ backgroundColor: '#ffeded', cursor: 'pointer' }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ borderRadius: 7, borderTop: 0, borderBottom: 0 }}>
                                                                                            <span>
                                                                                                <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                                                                                &nbsp;&nbsp;
                                                                                                <h7>{htmlConverterReact(question.question)}</h7>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {
                                                                            question.subquestions.map((subquestion) => {
                                                                                return <SubQuestionForConsolidatedErrorList question={subquestion} subsection={subsection} setActiveRightPane={setActiveRightPane} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} />
                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        {
                                                                            question.subquestions.map((subquestion) => {
                                                                                return <SubQuestionForConsolidatedErrorList question={subquestion} subsection={subsection} setActiveRightPane={setActiveRightPane} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} />
                                                                            })
                                                                        }
                                                                    </React.Fragment>
                                                                :
                                                                <React.Fragment></React.Fragment>
                                                            :
                                                            <React.Fragment></React.Fragment>
                                                    })
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment></React.Fragment>
                                    })
                                }
                            </React.Fragment>
                            :
                            <React.Fragment></React.Fragment> 
                    })
                }
            </div>
            :
            <React.Fragment></React.Fragment>
    )
}

export default ConsolidatedErrorList