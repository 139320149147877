import React from 'react'
import { htmlConverterReact } from 'html-converter-react'

const SubQuestionForSection = ({ questions, subsection, setActiveRightPane, doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix, scrollToQuestionViaQuestionRef }) => {
    return (
        questions.map((question, question_index) => {
            return question.hideQuestionInSection
                ?
                <React.Fragment></React.Fragment>
                :
                question.isVisible && question.responseType === 'other'
                    ?
                    <React.Fragment>
                        <table className="table table-striped table-hover">
                            <thead style={question.other.headerCellsVisibility ? {} : {display: 'none'}}>
                                <tr>
                                    {
                                        question.other.columns.map((column) => {
                                            return <th style={{ width: column.width }}>{column.title}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{cursor: 'pointer'}} onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                    <td colSpan={4}>{question.question}</td>
                                </tr>
                                {
                                    question.lastSuccessfullySavedResponseOther.map((response) => {
                                        return  <tr className="task-complete" onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                                    {
                                                        response.inputs.map((input, input_index) => {
                                                            return <td style={{ width: question.other.columns[input_index].width }}>{input.value}</td>
                                                        })
                                                    }
                                                    <td>
                                                        {
                                                            subsection.isSubmitButtonClicked 
                                                                ? 
                                                                question.required 
                                                                    ?
                                                                    question.lastSuccessfullySavedResponseOther.length === 0 
                                                                        ? 
                                                                        <span className="mdi mdi-3x mdi-alert-circle text-danger" />
                                                                        : 
                                                                        <span className="mdi mdi-3x mdi-check-circle text-success" />
                                                                    :
                                                                    <React.Fragment></React.Fragment>
                                                                :
                                                                <React.Fragment></React.Fragment>
                                                        }
                                                    </td>
                                                </tr>
                                    })
                                }
                            </tbody>
                        </table>
                        { 
                            question.subquestions.length == 0 
                                ? 
                                <React.Fragment></React.Fragment> 
                                : 
                                <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={true} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                        }
                    </React.Fragment>
                    :
                    question.isVisible && question.responseType === 'matrix' 
                        ?
                        <React.Fragment>
                            <h5 className="px-3 d-flex align-items-center justify-content-between">
                                {question.question}
                                {
                                    subsection.isSubmitButtonClicked 
                                        ? 
                                        question.required 
                                            ?
                                            question.lastSuccessfullySavedResponseMatrix !== null && question.lastSuccessfullySavedResponseMatrix.length === 0 
                                                ? 
                                                <span className="mdi mdi-3x mdi-alert-circle text-danger" />
                                                : 
                                                <span className="mdi mdi-3x mdi-check-circle text-success" />
                                            :
                                            <React.Fragment></React.Fragment>
                                        :
                                        <React.Fragment></React.Fragment>
                                }
                            </h5>
                            <table className="table table-hover table-bordered task-complete" onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                <thead>
                                    <tr>
                                        {
                                            question.matrix.columns.map((column) => {
                                                return <th style={{ width: column.width }}>{column.title}</th>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody className="no-border-x">
                                    {
                                        question.matrix.isValid 
                                            ? 
                                            question.lastSuccessfullySavedResponseMatrix !== null && question.lastSuccessfullySavedResponseMatrix.length !== 0 
                                                ? 
                                                question.lastSuccessfullySavedResponseMatrix.map((row) => {
                                                    return  <React.Fragment>
                                                                <tr>
                                                                    <td colSpan={6}>"<i>{row.title}</i>"</td>
                                                                </tr>
                                                                <tr>
                                                                {
                                                                    row.attributes.map((attribute, attribute_index) => {
                                                                        return <td style={{width: question.matrix.columns[attribute_index].width }}>{attribute.value}</td>
                                                                    })
                                                                }
                                                                </tr>
                                                            </React.Fragment>     
                                                })
                                                :
                                                <tr>
                                                    <td colSpan={6}>No response provided yet</td>
                                                </tr>
                                            :
                                            <tr>
                                                <td colSpan={6}>
                                                    <b>{question.matrix.validationMessage}</b>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                            { 
                                question.subquestions.length == 0 
                                    ? 
                                    <React.Fragment></React.Fragment> 
                                    : 
                                    <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={true} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                            }
                        </React.Fragment>
                        : 
                        question.isVisible && (question.responseType === 'text' || question.responseType === 'textarea' || question.responseType === 'dropdown')
                            ?
                            <React.Fragment>
                                <table className="table table-striped table-hover">
                                    {
                                        question_index === 0 && doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix || (question_index > 0 && (questions[question_index-1].responseType === 'other' || questions[question_index-1].responseType === 'matrix'))
                                            ? 
                                            <thead>
                                                <tr>
                                                    <th style={{ width: "50%" }}>Question</th>
                                                    <th style={{ width: "45%" }}>Response</th>
                                                    <th style={{ width: "5%" }} />
                                                </tr>
                                            </thead>
                                            :
                                            <React.Fragment></React.Fragment>
                                    }
                                    <tbody>
                                        <tr className="task-complete" onClick={() => { setActiveRightPane(subsection.title); scrollToQuestionViaQuestionRef(question.id, question.question) }}>
                                            <td style={{ width: "50%" }}>{htmlConverterReact(question.question)}</td>
                                            {
                                                question.lastSuccessfullySavedResponse.length === 0 
                                                    ?
                                                    <td style={{ width: "45%" }}>No response provided yet</td>
                                                    :
                                                    question.responseType === 'text' || question.responseType === 'textarea' 
                                                        ? 
                                                        <td style={{ width: "45%" }}>{question.lastSuccessfullySavedResponse}</td>
                                                        :
                                                        question.responseType === 'dropdown'
                                                            ? 
                                                            <td style={{ width: "45%" }}>{question.options.find(option => option.value === question.lastSuccessfullySavedResponse).label}</td>
                                                            :
                                                            <React.Fragment></React.Fragment>
                                            }
                                            <td style={{ width: "5%" }}>
                                                {
                                                    subsection.isSubmitButtonClicked 
                                                        ?
                                                        question.required 
                                                            ?
                                                            question.lastSuccessfullySavedResponse.length === 0 
                                                                ? 
                                                                <span className="mdi mdi-3x mdi-alert-circle text-danger" />
                                                                : 
                                                                <span className="mdi mdi-3x mdi-check-circle text-success" />
                                                            : 
                                                            <React.Fragment></React.Fragment>
                                                        :
                                                        <React.Fragment></React.Fragment>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                { 
                                    question.subquestions.length == 0 
                                        ? 
                                        <React.Fragment></React.Fragment> 
                                        : 
                                        <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={false} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                                }
                            </React.Fragment>
                            :
                            question.isVisible && (question.responseType === 'h5' || question.responseType === 'none')
                                ?
                                <React.Fragment>
                                    <table className="table table-striped table-hover">
                                        {
                                            question_index === 0 && doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix
                                                ? 
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "50%" }}>Question</th>
                                                        <th style={{ width: "45%" }}>Response</th>
                                                        <th style={{ width: "5%" }} />
                                                    </tr>
                                                </thead>
                                                :
                                                <React.Fragment></React.Fragment>
                                        }
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}><i>{question.question}</i></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    { 
                                        question.subquestions.length == 0 
                                            ? 
                                            <React.Fragment></React.Fragment> 
                                            : 
                                            <SubQuestionForSection questions={question.subquestions} subsection={subsection} setActiveRightPane={setActiveRightPane} doesParentQuestionHasResponseTypeAsEitherOtherOrMatrix={false} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} /> 
                                    }
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
        })
    )
}

export default SubQuestionForSection