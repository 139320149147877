import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import '../../../css/app.css'
import { copyResponse } from "workbox-core"
import CommonCustomModal from "../../Common/CommonCustomModal";
import RenewAccreditationApplication from "../Renew/RenewAccreditationApplication";


const Renew3YearApplication = ({airportApplicationID,actionType,isLoading,setIsLoading,setActionType})=>{

    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    const [selectedRenewalType, setselectedRenewalType] = useState('');
    const [renewAccModal, setRenewAccModal] = useState(false);
  
    const toggleForRenewAccModal = () => {
        setRenewAccModal(!renewAccModal);
        //toggler2(!toggler1);
        setActionType(actionType);
      };

    const handleRenewalRadioChange = (e) => {
        setselectedRenewalType(e.target.value);
        
        // if(e.target.value === 'Single'){
           
        // }else{
            
        // }
    };
    const moveToNextRenewPage = async (dataType,applicationID) => {

        try {
            
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    };
    return(
        <>
        <div>
        <div className="be-offcanvas-menu be-fixed-sidebar be-color-header">
        <div className="be-content">
                        <div className="main-content container-fluid p-0">
                        <div className="container-fluid">
            <div className="row">
            <div className="col-12">
            <div className="card card-border card-contrast">
            <div className="card-body">

                <p>Requirements<br></br><br></br>
                <ul>
                    <li>Meet all level 3 accreditation requirements.</li>
                    <li>Offset residual Scope 1 and 2 emissions as well as Scope 3.</li>
                </ul>
                <div className="form-group row pt-1 pb-1">
                                                                <label htmlFor="renewal-type" className="col-12 col-form-label">Please choose one of the preference of Renewal Cycle <span className="text-danger font-weight-bold">*</span></label>
                                                               
                                                                <div className="col-12 col-lg-6 form-check mt-1">
                                                                    <fieldset>
                                                                        <legend className="sr-only">Please choose one of the preference of Renewal Cycle</legend>
                                                                        <label className="custom-control custom-radio custom-control-inline">
                                                                            <input className="custom-control-input" type="radio" name="renewal-type"  value="1" checked={selectedRenewalType === '1'}  onChange={handleRenewalRadioChange}/><span className="custom-control-label">Do you wish to renew for one year? </span>
                                                                        </label>
                                                                        <label className="custom-control custom-radio custom-control-inline">
                                                                            <input className="custom-control-input" type="radio" name="renewal-type"  value="3"  checked={selectedRenewalType === '3'}  onChange={handleRenewalRadioChange} /><span className="custom-control-label">Do you wish to renew for three years? </span>
                                                                        </label>
                                                                    </fieldset>
                                                                </div>
                                                            </div>
{selectedRenewalType==="1" ?
<>
                    Requirements for Annual Renewal<br></br> <br></br>
                    <ul>
                    <li>In order to remain at Level 3+ an airport should fulfil the annual requirements of Level 3 with the addition of the following requirement:</li>
                    <li>Submission of evidence that offsets accepted under the programme have been purchased to cover residual emissions in scope 1 and 2 only.</li>
                </ul>
                <br></br> 
                </>
:
selectedRenewalType==="3"?
<>
                Requirements for 3 Year Renewal
                <br></br> <br></br>
                <ul>
                    <li>An airport already accredited at Level 3+ may also move to a three-yearly-renewal cycle provided that it can demonstrate emissions reductions for at least one Scope 3 emissions source. For further details, please refer to the ACA Guidance Document.</li>
                </ul>
              </>
              :
               <></>
    }
                </p>
                </div>
                </div>
            </div>
            </div>
            {(selectedRenewalType==="1" || selectedRenewalType==="3") && (
            <div className="row mt-3">
                <div className="col d-flex">
                    <button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }} onClick={() => { toggleForRenewAccModal(); }} >Next</button>
                </div>
            </div>
            )}
            </div>
            </div>
            </div>
            </div>
        </div>
        <CommonCustomModal modal={renewAccModal} modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForRenewAccModal} heading = {actionType} buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
        <RenewAccreditationApplication airportApplicationID={airportApplicationID} actionType={actionType} renewPeriod={selectedRenewalType} isLoading = {isLoading} setIsLoading={setIsLoading} />
    </CommonCustomModal>
        </>
    );
}

export default Renew3YearApplication;