import React, { useState } from 'react'
import { Slide, ToastContainer, toast } from 'react-toastify'

const UploadAssessmentDocuments = ({ state, setState, visibilityBasedOnType }) => {

    const allowedExtensions = ['.xls', '.xlsx', '.xlsm']
    const [key, setKey] = useState(0)
    
    const updateState = async (action, value, index) => {
        
        let valid = true
        let content = ''
        
        if(action === 'add'){
            if (!value || !value.name){
                valid = false
                toast.error('Invalid File!', { position: "top-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "light", transition: Slide }); 
            }
            if (valid && !allowedExtensions.includes('.' + value.name.slice(((value.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase())){
                valid = false
                toast.error('File Format Not Supported', { position: "top-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "light", transition: Slide }); 
            }
            if (valid && value.size > 20 * 1024 * 1024){
                valid = false
                toast.error('File Size Limit Exceeded: Allowed upto 20 MB', { position: "top-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: false, draggable: false, progress: undefined, theme: "light", transition: Slide }); 
            }
            if(valid)
                content = await fileToBase64(value).then(result => { return result })        
        }
        
        setState(prevState => {
            const updatedState = {...prevState}
            let documents = updatedState.summaries[1].adminSummary.documents

            switch(action) {
                case 'add':
                    if (valid) {

                        let enGBTime = new Date().toLocaleTimeString('en-GB', { timeZone: 'Europe/London', hour: 'numeric', minute: 'numeric', hour12: true })
                        if(parseInt(enGBTime.charAt(0)) === 0)
                            enGBTime = '12' + enGBTime.slice(1)

                        documents.push({    
                            id: (documents.length + 1).toString(), 
                            fileName: value.name, 
                            fileSize: value.size.toString(), 
                            content: content, 
                            addedBy: localStorage.getItem("userDisplayName"), 
                            date: new Date().toLocaleDateString('en-GB', { timeZone: 'Europe/London', year: 'numeric', month: 'numeric', day: 'numeric' }), 
                            time: enGBTime, 
                            fileDownloadURL: '' 
                        })
                    }
                    break
                case 'remove':
                    documents.splice(index, 1)
                    // re-assign id(s)
                    documents = documents.map((document, document_index) => {
                        return {
                            ...document,
                            id: (document_index + 1).toString()
                        }
                    })
                    break
                default:
                    break
            }

            updatedState.summaries[1].adminSummary.documents = documents
            return updatedState
        })

        /*
            This below one line of code may seem small but has a big impact on how the input file selection is handling multiple-file selection (including duplicacy)
            We use a key state to force re-render of the input element when we want to reset it via incrementing the key's value
            The setKey causes React to unmount the old input and mount a new one, effectively resetting it
            It will allow the user to select the same file multiple times if needed
        */
        setKey(key + 1)
    }

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = function (event) {
                const base64String = event.target.result.split(',')[1] // Extract the base64 data
                resolve(base64String)
            }
            reader.onerror = function (error) {
                reject(error);
            }
            if (file && file.name) {
                reader.readAsDataURL(file)
            } else {
                reject(new Error('Invalid file'))
            }
        })
    }

    return(
        <div className="card card-contrast card-border" style={{margin: 10}}>
            <div className="card-body px-2 card-body-contrast" style={{padding: 10}}>
                <h5 className="mt-0">Upload Assessment form here</h5>
                {
                    state.summaries[1].adminSummary.documents.length === 0 
                        ?
                        <label className="text-info">No documents uploaded yet.</label>
                        :
                        state.summaries[1].adminSummary.documents.map((document, index) => {
                            return(
                                <div key={index} className="p-1 mb-1 border rounded">
                                    <div className="media align-items-center">
                                        <span className="mdi mdi-4x mdi-file-text ml-2" />
                                        <div className="media-body ml-3">
                                            <h5 className="m-0">
                                                <a className="text-info" href={document.fileDownloadURL} target='_blank'>{document.fileName}</a>
                                            </h5>
                                            <p className="mb-0">Added by: {document.addedBy} on {document.date} at {document.time}&nbsp;</p>
                                        </div>
                                        <div className="card-option">
                                            <button type="button" className="btn shadow-none" onClick={() => { updateState('remove', '', index) }} style={visibilityBasedOnType(state.summaries[1].type, false) ? {} : {display: 'none'}}>
                                                <i className="mdi mdi-3x mdi-delete" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                }
                <div className="form-group row" style={visibilityBasedOnType(state.summaries[1].type, false) ? {} : {display: 'none'}}>
                    <label className="col-12 col-sm-3 col-form-label" htmlFor={`assessment-file`}>Select File</label>
                    <div className="col-12 col-sm-6">
                        <input key={key} className="inputfile" id={`assessment-file`} type="file" name={`assessment-file`} onChange={(e) => { updateState('add', e.target.files[0], -1) }} />
                        <label className="btn-secondary" htmlFor={`assessment-file`}>
                            &nbsp;
                            <i className="mdi mdi-upload" />
                            <span>Choose file to Upload...</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadAssessmentDocuments