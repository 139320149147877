import React from 'react'
import Layout from '../../../Layout'
import AirportUserGroup from './AirportUserGroup'
import { Slide, ToastContainer, toast } from 'react-toastify'

const AirportGroupDashboard = () => {

    const userID = localStorage.getItem('userID')
    const userDisplayName = localStorage.getItem('userDisplayName')
    const userRoles = JSON.parse(localStorage.getItem("roles"))

    if(userRoles.length === 1){
        userRoles.map((role) => {
            // for what purpose?
            if(role.name === "Airport User" && role.isfirstlogin === 0){
                window.location.href='/ChangePassword'
            }
        })
    }

    return (
        <Layout>
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-blue mb-4">
                        <div className="container-header">
                            <div className="row">
                                <div className="col">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">My Dashboard</h3>
                                    </div>
                                <div className="col">
                                </div>
                           </div>
                        </div>
                    </div>
                    <AirportUserGroup />
                </div>
            </div>
            <ToastContainer />
        </Layout>
    )
}

export default AirportGroupDashboard