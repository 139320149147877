import React, { useState, useEffect } from 'react';
import Layout from '../../../Layout';
import User from '../Users/User';
import { TableHeaderCells } from '../Users/TableHeaderCells';
import axios from '../../../api/AxiosConfig';
import CommonModal from '../../Common/CommonModal';
import SearchBar from '../../Common/SearchBar';
import Card from '../../Common/Card';
import Pagination from '../../Common/Pagination';
import Selector from '../../Common/Selector';
import Table from '../../Common/Table';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { AiOutlineUserAdd } from "react-icons/ai";
import { TiArrowUnsorted } from 'react-icons/ti';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import SortArray from "../../Helper/SortArray";
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions';
import { Slide, ToastContainer, toast } from 'react-toastify';
import AdvancedSearch from '../../Common/AdvancedSearch';
import CustomTable from '../../Common/CustomTable';
import { useNavigate } from "react-router-dom";
import IsAccessForVerifierCombination1 from '../../Common/IsAccessForVerifieCombination1';
import Loader from '../../Common/Loader';

const Users = () => {

    /* States & Constants */
    const [searchName, setSearchName] = useState('');
    const [users, setUsers] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [addUserModal, setAddUserModal] = useState(false);
    const [emails, setEmails] = useState('');
    const [firstNames, setFirstNames] = useState('');
    const [lastNames, setLastNames] = useState('');
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [sortOrder, setSortOrder] = useState("ASC");
    const [sortColumn, setSortColumn] = useState('');
    const [isSortTriggered, setIsSortTriggered] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [catergoryList, setCategoryList] = useState([]);
    const [archivedUsers, setArchivedUsers] = useState(true)
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [duplicateuser, setDuplicateuser] = useState('');
    const [searchFilter, setSearchFilter] = useState('First Name');
    const [searchType, setSearchType] = useState('text');
    const [searchCriteria, setSearchCriteria] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [dropdownOptions, setDropdownOptions] = useState([]);
    const params = new URLSearchParams([['IncludeArchivedUsers', archivedUsers]]);
    const [sortByColumnName, setSortByColumnName] = useState('FirstName');
    const [columnClickCounter, setColumnClickCounter] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const navigate = useNavigate();
    const [inactiveUsers, setInActiveUsers] = useState(true);
    const [catergoryListSearch, setCategoryListdSearch] = useState([]);
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    const [isLoading, setIsLoading] = useState(false)
    const searchOptions = [
        {
            value: 'First Name', 
            text: 'First Name', 
            type: 'text',
            query: '&FirstName='
        },
        {
            value: 'Last Name', 
            text: 'Last Name', 
            type: 'text',
            query: '&LastName='
        },
        {
            value: 'Email Address', 
            text: 'Email Address', 
            type: 'text',
            query: '&Email='
        },
        {
            value: 'Category', 
            text: 'Category', 
            type: 'dropdown',
            query: '&Category=',
            dropdownOptions: catergoryListSearch
        },
        {
            value: 'Status', 
            text: 'Status', 
            type: 'dropdown',
            query: '&Status=',
            dropdownOptions: [
                {text: 'Locked', value: 'Locked'},
                {text: 'Active', value: 'Active'},
                {text: 'Archived', value: 'Archived'},
            ]
        },

    ];

    /* Methods */
    const fetchData = async () => {
        setIsLoading(true);
        let activeUser = 1;
        if(inactiveUsers=== true)
        {
            activeUser = 0;
        }
        try {
            let filtersQuery = '';
            if(searchCriteria.length > 0){
                filtersQuery = '';
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`);
            }
            const response = await fetch(`${config.apiUrl}${apiconfig.getUsers}?ActiveUsers=${activeUser}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                const result = await response.json();
                let fetchedusers = [];
                for (let i = 0; i < result.length; i++) {
                    fetchedusers.push(result[i]);
                }
                setUsers(fetchedusers);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                const errorText = await response.text();
                const apiname = `${apiconfig.getUsers}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
         }
        catch (error) {
            console.log(error.message);
            navigate(`/error-page`);
        }
    }
    const handleChangeForInActiveSponsors = () =>{        
        setInActiveUsers(!inactiveUsers);
    }
    const createUser = async () =>{
        try {
            await axios.post(`${apiconfig.createUser}`,
                {
                    Email: emails, 
                    FirstName: firstNames,
                    LastName: lastNames,
                    CategoryID: selectedOption,
                    ID:userID
                },                
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${localStorage.getItem("token")}`
                    },
                })
                .then((response) => {
                    if(response.status === 200){
                        toast.success('New User has been created successfully.', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide
                        }); 
                        fetchData();
                        toggleForAddUserModal(); 
                    }
                })            
        } 
        catch (error) {
            //console.error(error);
            setDuplicateuser('This user already exists.')
        }
    }
    const addUser = async () => {      
        const validationErrors = validateForm(emails,firstNames,lastNames,selectedOption);
        setFormErrors(validationErrors);        
        if(Object.keys(validationErrors).length === 0){    
            try {
                        await createUser();                    
            }
            catch (error) {
                console.log("Error: ", error);
            }
        }        
    };
    const validateForm = (email, firstName, lastName, categoryId) => {
        //Add User form validation
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!firstName){
            errors.firstName = 'First Name is required.';
        }
        else if(firstName.length >   256){
            errors.firstName = 'First Name cannot exceed 256 characters.'
        }
        if(!lastName){  
            errors.lastName = 'Last Name is required.';
        }
        else if(lastName.length > 256){
            errors.lastName = 'Last Name cannot exceed 256 characters.'
        }
        if(!email){
            errors.email = 'Email is required.';
        }
        else if(!emailRegex.test(email)){
            errors.email = 'Email is invalid.';
        }
        if(!categoryId){
            errors.category = 'Category is required.';
        }
        return errors;
    }
    //const toggleForAddUserModal = () => setAddUserModal(!addUserModal);
    const toggleForAddUserModal = () => {
        getCategoryList();
        setDuplicateuser('');
        setAddUserModal(!addUserModal);
        setFormErrors({});
        setSelectedOption("");
    }  
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1);
    };
    //Category List
    // useEffect(() => {
    function getCategoryList() {
        fetch(`${config.apiUrl}${apiconfig.getUserCategory}`, {
            method: 'GET',
            headers: {

                Authorization: `Bearer ${localStorage.getItem("token")}`

            }
        }).then(res => res.json())
            .then((data) => {
                setCategoryList(data);
                const option = data.map((category) => ({
                    text: category.name,
                    value: category.categoryID,
                    }));
                    setCategoryListdSearch(option);
            }).catch((err) => {
                console.log(err)
            });
    }
    //     getCategoryList();
    // }, [refetch]);
    const handleChangeForArchivedUsers = () =>{        
        setArchivedUsers(!archivedUsers);
    }
    const isAlphaNumber = (event) => {
        const keyCode = event.keyCode || event.which;
        // if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 9) {
        //   event.preventDefault();
        // }
        // if ((keyCode < 65 || keyCode > 90) && keyCode !== 8 && keyCode !== 9) {
        //     event.preventDefault();
        //   }
        //   if ((keyCode < 97 || keyCode > 122) && keyCode !== 8 && keyCode !== 9) {
        //     event.preventDefault();
        //   }
          if (!/^[a-zA-Z0-9]+$/.test(event.key)) {
            event.preventDefault();
          }
    };

    /* Effects */
    useEffect(() => {
        fetchData();
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder, searchCriteria,inactiveUsers]);
    useEffect(() => {
        if(users.length > 0){
            setPageCount(Math.ceil(users[0].totalCount / itemsPerPage));
        }
    }, [users]);
    useEffect(() => {
        getCategoryList();
    }, []);
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(users.length !== 0){
            if(users[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])


    return (
        <Layout>
            <>
            {isLoading && <Loader />}
            <div className="main-content container-fluid p-0">
                <div className="page-header blue mb-4">
                    <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                            <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">User List</h3>
                            </div>
                            <div className="col d-flex align-items-center justify-content-end">
                            {isReadOnlyAccess1 === 'FullAccess' || isReadOnlyAccess1 === 'FullAccessForAMSAMOnly'  ?
                                <Card title='' hasButton={true} toggler={toggleForAddUserModal} icon={<AiOutlineUserAdd />} buttonText='Add User'/>
                                :
                                <Card title='' hasButton={false} toggler={toggleForAddUserModal} icon={<AiOutlineUserAdd />} buttonText='Add User'/>
                            }
                            </div>
                        </div>
                        <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb mb-0 mt-4">
                                <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                <li className="breadcrumb-item active">User List</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="container-header">
                        <h3 className="page-header-title">Search Users</h3>
                    <div className="row">
                        <div className="col-md-6 col-lg-7">
                            <p className="mb-2 font-weight-normal">
                            
                                   Use the filter categories and search field below to find users, expand the preview to see more information.
                                </p>
                            <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                            {
                                users.length > 0
                                    ?
                                    <div className="align-self-center font-weight-bold">{users[0].totalCount} items found</div>
                                    :
                                    <div className="align-self-center font-weight-bold">No items found</div>
                            }
                        </div>
                        <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                            <nav>
                                <div className="custom-control custom-checkbox custom-control-inline m-0">
                                    <input className="custom-control-input" type="checkbox" onChange={handleChangeForInActiveSponsors} checked={inactiveUsers} id="EArchived" />
                                    <label className="custom-control-label" htmlFor="EArchived">Exclude Archived</label>
                                </div>
                                <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                                <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                            </nav>
                        </div>
                    </div>
                    <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                        {users.map(user => <User key={user.id} user={user} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} />)}
                    </CustomTable>
                    <div className="d-flex justify-content-end">
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </div>
                </div>
            </div>
         
         
            { /* MODAL FOR ADDING USER */}
            <CommonModal modal={addUserModal} title='Add User' toggler={toggleForAddUserModal} heading='ADD USER' triggerMethodOnSaveButtonClick={addUser} buttonText='Save'>
                <Form>
                    <FormGroup>
                        <Label className="col-form-label">{'Email:'}</Label>
                        <Input className="form-control form-control-sm" type="text" id="email" onChange={(e) => { setEmails(e.target.value) }} />
                        <p style={{color:'red'}}>{formErrors.email}</p>
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">{'First Name:'}</Label>
                        <Input className="form-control form-control-sm" type="text" id="first-name"  onChange={(e) => { setFirstNames(e.target.value) }}></Input>
                        <p style={{color:'red'}}>{formErrors.firstName}</p>
                    </FormGroup>
                    <FormGroup>
                        <Label className="col-form-label">{'Last Name:'}</Label>
                        <Input className="form-control form-control-sm" type="text" id="last-name"  onChange={(e) => { setLastNames(e.target.value) }}></Input>
                        <p style={{color:'red'}}>{formErrors.lastName}</p>
                    </FormGroup>
                    <FormGroup>
                        <label className="col-form-label">{'Category:'}</label>
                        <select id="dropdown" className="form-control form-control-sm" value={selectedOption} onChange={(event) => setSelectedOption(event.target.value)}>
                            <option value="">-- Select --</option>
                            {catergoryList.map((option) => (
                                <option key={option.categoryID} value={option.categoryID}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.category}</p>
                    </FormGroup>
                    <p style={{color:'red'}}>{duplicateuser}</p>
                </Form>
            </CommonModal>
            <ToastContainer />
            </>
        </Layout>
    );
};

export default Users;