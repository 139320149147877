import React, { useState, useEffect } from 'react';

const InputDropdownID = ({ searchFilter, dropdownOptions, searchValueSetter, searchValue,searchTextSetter,searchText  }) => {
    const handleChange= (evt) =>{

        console.log(evt.target.name)
        const selectedOption = evt.target.options[evt.target.selectedIndex];   
        const selectedText = selectedOption.text;
        // var splitText=evt.target.value.toString().split('+');
        // searchValueSetter(splitText[0]);
        // searchTextSetter(splitText[1]);
        searchValueSetter(evt.target.value);
        searchTextSetter(selectedText);
      }
    return( 
        <select id="rSearch" className="form-control" style={{maxWidth: 600, cursor: 'pointer'}} onChange={handleChange} value={searchValue}>                        
            <option value='default' disabled>{`-- Please Select ${searchFilter} --`}</option>
            {
                dropdownOptions.map((option, index) => {
                    return (
                        <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.text}</option>
                    );
                })
            }
        </select>
    );    
}

export default InputDropdownID;