import React from 'react';
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination';



const AirportGroup = ({ airportgroup,refetch, setRefetch }) => {

  const isFullAccess = IsAccessForAccreditationCombination();
  const userID = localStorage.getItem("userID");
 

        return (
            <>
                <tr>
                    {/* <td><a className="text-danger"  style={{cursor:'pointer'}} onClick={ () => { toggleForAirportGroupDetailsModal(); }}>{airportgroup.airportGroup}</a></td> */}
                    {isFullAccess === 'AccFullAccess' ?
                    <td><a className="text-info" href={`/AirportGroupDetail/${airportgroup.id}`}>{airportgroup.airportGroup}</a></td>
                    :
                    <td>{airportgroup.airportGroup}</td>
                    }
                    <td>{airportgroup.createdUser}</td>
                    <td>{airportgroup.createdDate}</td>
                    {/* <td>{airportgroup.isSmall}</td> */}

                </tr>
                {/* <CommonCustomModal modal={editAirportGroupModal}  modalWidth='100%' title='' toggler={toggleForEditAirportGroupModal} heading='Edit Airport Group' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <EditAirportGroup toggler={toggleForEditAirportGroupModal} airportgroup = {airportgroup} refetchs={refetch} setRefetchs={setRefetch} />
                </CommonCustomModal>
                <CommonCustomModal modal={airportGroupDetailsModal}  modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAirportGroupDetailsModal} heading='Airport Group Details' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <AirportGroupDetail toggler={toggleForAirportGroupDetailsModal} airportgroup = {airportgroup} refetchs={refetch} setRefetchs={setRefetch} />
                </CommonCustomModal> */}
            </>
        );
};

export default AirportGroup;