import React, { useState,useEffect } from 'react';
import CommonCustomModal from '../../Common/CommonCustomModal';
import EditAirportDetails from './EditAirportDetails';



const EditAirportDetail = ({ airport, refetch, setRefetch }) => {


    const [updateGroupAirportModal, setUpdateGroupAirportModal] = useState(false);
    const toggleForUpdateGroupAirportModal = () => {
        setUpdateGroupAirportModal(!updateGroupAirportModal);
    } 
   
    return (
        <>
            <tr className="alt-row">
                <td>{airport.region}</td>
                <td>{airport.country}</td>
                <td>{airport.city}</td>
                <td>{airport.state}</td>
                <td>{airport.airport}</td>
                <td>{airport.iataCode}</td>
                <td className="actions"><a className="icon" style={{cursor:'pointer'}}  onClick={ () => { toggleForUpdateGroupAirportModal();}}>
                <i className="mdi mdi-edit"></i>
                <span className="sr-only">Save</span>
                </a>
                </td>
            </tr>

        <CommonCustomModal modal={updateGroupAirportModal}  title='' toggler={toggleForUpdateGroupAirportModal} heading='Update Airport Details'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
         <EditAirportDetails toggler={toggleForUpdateGroupAirportModal} airportDetails={airport} refetch={refetch} setRefetch={setRefetch} />
       </CommonCustomModal>

        </>
    );


};

export default EditAirportDetail;