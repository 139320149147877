import React, { useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import config from "../../api/config";
import apiconfig from "../../api/apiconfig";
//import { useFormik } from 'formik';
//import { loginSchema } from '../Schemas/ValidationSchemas';
import { Link } from "react-router-dom";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import Loader from "../Common/Loader";

const LoginForm = () => {

    const navigate = useNavigate();
    const signIn = useSignIn();

    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rolesList, setRolesList] = useState([]);


    const onLoginButtonClick = async () => {

        const formData = new URLSearchParams();
        formData.append("audience", "http://localhost:58500");
        formData.append("duration", "3000");
        formData.append("username", email);
        formData.append("password", password);
        formData.append("applicationID", "1");

        const errors = validateForm(email, password);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            try {
                setHasError(false);
                const response = await fetch(`${config.apiUrl}${apiconfig.loginUrl}`, {
                    auth: "No Auth",
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: formData,
                }).then((response) => {
                    return response.json();
                }).catch(err => {
                    console.log(err);
                })
                if (response == undefined) {
                    //console.log("Error: ", error);
                    setErrorMessage("ACI Api is not working. Please contact the administrator");
                }
                if (response.access_token === null) {
                    setHasError(true);
                    setErrorMessage(response.errors[0].description);
                }

                const token = response.access_token;
                const expiresIn = response.expires_in;
                const decoded = jwt_decode(token);
                const userGuid = decoded.UniqueIdentifier;
                const eMail = decoded.sub;
                const userName = decoded.sub;
                const userDisplayName = decoded.UserName;
                const userID = decoded.UserID;
                // console.log(decoded);
                // console.log(userId);
                // console.log(userGuid);
                // console.log(eMail);
                // console.log(expiresIn);
                /* ---------- Session Storage through Javascript ---------- */
                localStorage.setItem("userID", userID);
                localStorage.setItem("userName", userName);
                localStorage.setItem("userDisplayName", userDisplayName);
                localStorage.setItem("eMail", eMail);
                localStorage.setItem("passString", password);
                localStorage.setItem("token", token);
                sessionStorage.setItem("token", token);

                await setRolesInlocalStorage(userID);
                /* -------------------------------------------------------- */

                signIn({
                    token: token,
                    expiresIn: expiresIn,
                    tokenType: "Bearer",
                    authState: { email: eMail },
                });

                //navigate("/");
                
                const rolesListFromSession = JSON.parse(localStorage.getItem("roles"));

                const isAirportUser = rolesListFromSession.length === 1 && rolesListFromSession[0].name === 'Airport User';
                const isExternalUser = rolesListFromSession.length === 1 && rolesListFromSession[0].name === "Verifier User";
                const isSingleCheck = rolesListFromSession.length === 1 && rolesListFromSession[0].returnType === 'Single User';
                const isGroupCheck = rolesListFromSession.length === 1 && rolesListFromSession[0].returnType === 'Group User';
                
                if(isExternalUser){
                    window.location.href = "/VerifierDashboard"
                }else{
                    if(isAirportUser && isGroupCheck)
                        window.location.href = "/AirportGroupDashboard"
                    else if(isAirportUser && isSingleCheck)
                        window.location.href = "/AirportGroupDashboard"
                    else
                        window.location.href = "/";
                }   

                // window.location.href = "/";
            } catch (error) {
                console.log("Error: ", error);
                //navigate("/error-page");
            } finally {
                setLoading(false);
            }
        }
    };

    const setRolesInlocalStorage = async (UserID) => {
        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.getRoleListByUser}${UserID}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                var result = await response.json();
                localStorage.setItem("roles", JSON.stringify(result));
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getRoleListByUser}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }

        } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    }; 

    //form validation
    const validateForm = (email, password) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if (!password) {
            errors.password = "Password is required!";
        }
        if (!email) {
            errors.email = "Email is required!";
        } else if (!emailRegex.test(email)) {
            errors.email = "Email is invalid!";
        }
        return errors;
    };

    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <>
            {loading && <Loader />}
            <div className="page-header blue mb-4">
                <div className="container-header">
                    <div className="row">
                        <div className="col">
                            <img src={require('../../img/ACA-logo.png')} alt="ACALogo" />
                        </div>
                        <div className="col-6 d-flex align-items-center justify-content-center">
                            <h3 className="page-header-title">Welcome to the Airport Carbon Accreditation Portal</h3>
                        </div>
                        <div className="col d-flex align-items-center justify-content-end">
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="splash-container">
                        <div className="card">
                            <div className="card-body">
                                <form>
                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="email">
                                            User Name
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            id="email"
                                            type="text"
                                            placeholder="Username"
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                        <p style={{ color: "red" }}>{formErrors.email}</p>
                                    </div>
                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="password">
                                            Password
                                        </label>
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div style={{ position: "relative", flex: 1 }}>
                                                <input
                                                    className="form-control form-control-sm"
                                                    id="password"
                                                    type={visible ? "text" : "password"}
                                                    placeholder="Password"
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                    }}
                                                    style={{ width: "100%" }}
                                                />
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "50%",
                                                        right: "10px",
                                                        transform: "translateY(-50%)",
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => setVisible(!visible)}
                                                >
                                                    {visible ? <BsEye /> : <BsEyeSlash />}
                                                </div>
                                            </div>
                                        </div>
                                        <p style={{ color: "red" }}>{formErrors.password}</p>
                                    </div>
                                    {errorMessage ? (
                                        <p style={{ color: "red" }}>{errorMessage}</p>
                                    ) : (
                                        <p></p>
                                    )}
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <Link
                                                className="text-danger"
                                                to={`/PasswordReset`}
                                                // target="_blank"
                                                style={{ color: "red" }}
                                            >
                                                Forgot Password?
                                            </Link>
                                        </div>
                                        <div className="col-12 login-remember mt-2">
                                            <div className="custom-control custom-checkbox">
                                                <input className="custom-control-input" type="checkbox" id="checkbox1" checked={isChecked} onChange={handleCheckboxChange} />
                                                <label className="custom-control-label" htmlFor="checkbox1">
                                                    I have understood my responsibilities as set out in the <Link to={`/TermsAndConditionsAcceptableUsePolicy`} target="_blank" style={{ color: "blue" }}>Terms and Conditions/<br/>Acceptable Use Policy</Link>.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group login-submit">
                                        <button className="btn btn-lg btn-block btn-primary" disabled={!isChecked} id="button-login" type="button" onClick={onLoginButtonClick}>Login</button>
                                    </div>
                                </form>
                            </div>
                        <div className="splash-footer"><label>New/Prospecting Verifiers, <a className="text-info" href="/VerifierRegistration"> click here</a>.</label></div>
                        <div className="splash-footer"><label>New/Prospecting Airports, <a className="text-info" href="/AccreditationAirportRegistration"> click here</a>.</label></div>
                        </div>
                        {/* <div className="splash-footer"><span><a className="text-info" href="/AccreditationAirportRegistration">Click Here</a> to Register </span>
                        <label>‘New’ Airports should register, existing Airports (Active/Inactive) should contact the ACA Admin Team.</label>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginForm