import React from 'react';
import InputText from '../Common/InputText';
import InputDate from '../Common/InputDate';
import InputDropdown from '../Common/InputDropdown';
import InputDropdownID from './InputDropdownByIDNew';
import SearchedTag from '../Common/SearchedTag';
import { MdSearch } from 'react-icons/md';
import SearchedTagByID from './SearchedTagByID';

const AdvancedSearchByIDNew = ({ searchOptions, searchType, setSearchType, searchFilter, setSearchFilter, searchValue, setSearchValue, dropdownOptions, setDropdownOptions, searchCriteria, setSearchCriteria, searchText, setSearchText, setPageNumber }) => {    

    const handleSearchFilterChange = (event) =>{ 
        setSearchFilter(event.target.value);
        searchOptions.map((option, index) => { 
            if(option.value === event.target.value){
                setSearchType(option.type);
                if(option.type === 'dropdown'){
                    setDropdownOptions(option.dropdownOptions);
                    setSearchValue('default');
                }else if(option.type === 'dropdownbyID'){
                    setDropdownOptions(option.dropdownOptions);
                    setSearchValue('default');
                }else{
                    setSearchValue('');
                }
            } 
        });
    }

    const removeSearchCriteria = (indexToBeRemoved) => {
        let temporaryStorage = [];
        searchCriteria.map((criteria, index) => {
            if(indexToBeRemoved !== index)
                temporaryStorage.push(criteria);
        });
        setPageNumber(1);
        setSearchCriteria(temporaryStorage);
        temporaryStorage = [];
    };

    const updateSearchCriteria = () => {
        if(searchValue.length > 0){
            searchOptions.map(option => { 
                if(option.value === searchFilter){
                    if(searchCriteria.length > 0){
                        let temporaryStorage = [];
                        searchCriteria.map((criteria, index) => {
                            if(criteria.searchKey !== searchFilter)
                                temporaryStorage.push(criteria);
                        });
                        temporaryStorage.push({searchKey: searchFilter, searchValue: searchValue, searchQuery: option.query,searchText:searchText,searchType:searchType});
                        setPageNumber(1);
                        setSearchCriteria(temporaryStorage);
                        temporaryStorage = [];
                    }
                    else{
                        setPageNumber(1);
                        setSearchCriteria([{searchKey: searchFilter, searchValue: searchValue, searchQuery: option.query,searchText:searchText,searchType:searchType}]);
                    }            
                }
            });
        }
    }

    return (
            <div>
                <div className="input-group input-search input-group-sm">
                    <label htmlFor="rType" className="form-label sr-only">Select Type</label>
                    <select id="rType" className="form-control text-white" style={{ backgroundColor: '#184086', maxWidth: 200, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }} onChange={handleSearchFilterChange}>
                        {
                            searchOptions.map((option, index) => {
                                return(
                                    <option key={index} value={option.text} area-label={option.type}>{option.text}</option>
                                );
                            })
                        }
                    </select>
                    <label htmlFor="rSearch" className="form-label sr-only">Search</label>
                    {
                        searchType === 'dropdown' 
                            ?
                            <InputDropdown searchFilter={searchFilter} dropdownOptions={dropdownOptions} searchValueSetter={setSearchValue} searchValue={searchValue}/>
                            : 
                            searchType === 'dropdownbyID' 
                            ?
                            <InputDropdownID searchFilter={searchFilter} dropdownOptions={dropdownOptions} searchValueSetter={setSearchValue} searchValue={searchValue} searchTextSetter={setSearchText} searchText={searchText}/>
                            :     
                            searchType === 'date' 
                                ?
                                <InputDate searchFilter={searchFilter} searchValueSetter={setSearchValue} searchValue={searchValue}/>
                                :
                                <InputText searchFilter={searchFilter} searchValueSetter={setSearchValue} searchValue={searchValue}/>
                    }
                        <button className="btn btn-secondary" type="button" onClick={() => {updateSearchCriteria();}} style={{paddingBottom: 0}}>
                            <MdSearch style={{padding: 2, marginBottom: 4, fontSize: '25'}} />
                            <span className="sr-only">Search Icon</span>
                        </button>
                </div>
                <div className="">
                {/* {searchType === 'dropdownbyID' ? 
                
                    <SearchedTagByID searchedCriteria={searchCriteria} removeSearchCriteria={removeSearchCriteria} />
                : */}
                    <SearchedTag searchedCriteria={searchCriteria} removeSearchCriteria={removeSearchCriteria} />
                {/* } */}
                </div>
            </div>
    );

};

export default AdvancedSearchByIDNew;