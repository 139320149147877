import React, { useState, useEffect } from "react"
import Layout from "../../../Layout"
import RecentActivity from "../Users/RecentActivity"
import UserProfileCard from "../../Common/UserProfileCard"
import CommonModal from "../../Common/CommonModal"
import SearchBar from "../../Common/SearchBar"
import Card from "../../Common/CardWithMutipleButton"
import Pagination from "../../Common/Pagination"
import Selector from "../../Common/Selector"
import Table from "../../Common/Table"
import SortArray from "../../Helper/SortArray"
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { Form, FormGroup, Input, Label } from "reactstrap"
import { TbArrowsSort, TbCategory } from "react-icons/tb"
import { BiTimeFive } from "react-icons/bi"
import { TiArrowUnsorted } from "react-icons/ti"
import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import { useParams } from "react-router-dom"
import { MultiSelect } from "react-multi-select-component"
import { useNavigate } from "react-router-dom"
import RoleListByUser from "../../../Components/Admin/Roles/RoleListByUser"
import { Slide, ToastContainer, toast } from 'react-toastify'
import IsAccessForVerifierCombination1 from "../../Common/IsAccessForVerifieCombination1"

const UserDetails = () => {
  
    /* States & Constants */
    const [searchName, setSearchName] = useState("")
    const [roles, setRoles] = useState([])
    const [applications, setApplications] = useState([])
    const [userDetails, setUserDetails] = useState({})
    const [recentActivities, setRecentActivities] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [newPasswords, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showPasswordMismatchError, setShowPasswordMismatchError] = useState(false)
    const [resetPasswordModal, setResetPasswordModal] = useState(false)
    const [manageEditRolesModal, setManageEditRolesModal] = useState(false)
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState(true)
    const [sortColumn, setSortColumn] = useState("")
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const { id } = useParams()
    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState({})
    const userID = localStorage.getItem("userID")
    const [isExternalUser, setIsExternalUser] = useState(false)
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1()
    const isAllSelected = selectedOptions.length === options.length
    const overrideStrings = { selectAll: isAllSelected ? 'Deselect All' : 'Select All' }

    /* Data */
    const tableHeaderCells = [
      {
        id: 1,
        isHidden: true,
        name: "ID",
        colSpan: 1,
        style: {},
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null,
      },
      {
        id: 2,
        isHidden: false,
        name: "Role Name",
        colSpan: 1,
        style: { cursor: "pointer" },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: "name",
      },
      // {
      //   id: 3,
      //   isHidden: false,
      //   name: "Hierarchy",
      //   colSpan: 1,
      //   style: { cursor: "pointer" },
      //   hasIcon: true,
      //   icon: <TbArrowsSort />,
      //   isSortable: true,
      //   sortByColumnName: "hierarchy",
      // },
    ]

    /* Methods */
    const handleSelectChange = (selected) => {
        // handle  Selected Role
        setSelectedOptions(selected)
    }
    const toggleForResetPasswordModal = () => {
        setNewPassword('')
        setConfirmPassword('')
        setFormErrors({})
        //setShowPasswordMismatchError(false)
        setResetPasswordModal(!resetPasswordModal)
    }
    const resetPassword = async () => {

        const validationErrors = validateForm(newPasswords, confirmPassword);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            if (newPasswords === confirmPassword) {
                //setShowPasswordMismatchError(false);
                const requestBody = {
                    "userID": id,
                    "createdUserID": userID,
                    "newPassword": newPasswords
                };
                try {
                    const response = await fetch(`${config.apiUrl}${apiconfig.changeUserPassword}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody)
                    });
                    if(response.status === 200){                        
                      toast.success('Password has been successfully reset.', {
                          position: "top-right",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                          transition: Slide
                      });    
                      toggleForResetPasswordModal();
                      setRefetch(!refetch);                    
                  }
                  // else{
                  //     return false;
                  // }
                    // .then((response) => {
                    //     return response;
                    // })
                }
                catch (error) {
                    console.log("Error: ", error);
                }
            } 
            // else {
            //     setShowPasswordMismatchError(true);
            // }
        }

    }
    const toggleForManageEditRolesModal = () => {
        setManageEditRolesModal(!manageEditRolesModal);
    }
    const updateUserRoles = async () => {
      let rolesIDs = [];
      rolesIDs = selectedOptions.map((option) => option.value);

      const requestBody = {
        rolesIDs: rolesIDs,
        userID: id,
        createdUserID:userID,
        isSuccessful: true,
        errorMessage: "",
      };
      try {
        const response = await fetch(
          `${config.apiUrl}${apiconfig.editUserRoles}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody),
          }
        ).then((response) => {
          toast.success('The role assignment has been completed.', {
            position: "top-right",
            autoClose: 10000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Slide
        }); 
          return response;
        });
        toggleForManageEditRolesModal();
        setRefetch(!refetch);
      } catch (error) {
        console.log("Error: ", error);
        navigate("/error-page");
      }

    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
        const newOffset = (e.selected * itemsPerPage) % roles.length
        setItemOffset(newOffset)
    }
    const validateForm = (newPassword, confirmPassword) => {
        //form validation

        const errors = {}
        //const passwordRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        const passwordRegex = new RegExp("^(?=.*[a-z])(?!.*[ @'?+:])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20}$)")

        if (!newPassword) {
            errors.newPassword = 'New password is required!'
        }
        else if (!passwordRegex.test(newPassword)) {
            errors.newPassword = 'New Password must contain one number between 0-9, one lower case character, one upper case character, one special character and be between 8-20 characters long.'
            errors.specialChars = `For New Password special characters: # ~ ! $ % ^ & * () _ = , . / ; [] " <> { } \ | -are allowed and Spaces, @, ', ?, +, : are not allowed.`
            //errors.newPassword = 'New Password must contain one number between 0-9, one lower case character, one upper case character and be at least 8 characters long!'
        }
        else if(newPassword != confirmPassword){
            errors.confirmPassword = 'New password and Confirm New password do not match.'
        }
        return errors
    }

    /* Effects */
    useEffect(() => {
          //All RoleList
          let fetchedRoles = []
          const getRoleList = async () => {
              try {
                  const response = await fetch(`${config.apiUrl}${apiconfig.getRoleList}`, {
                          method: "GET",
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                          }
                      }
                  )
                  .then((res) => res.json())
                  .then((data) => {
                      for (let i = 0; i < data.length; i++)
                        fetchedRoles.push(data[i])
                      const option = fetchedRoles.map((role) => ({label: role.name, value: role.roleID}))
                      setOptions(option)
                  })
              } catch (error) {
                  navigate("/error-page")
                  console.log("Error: ", error)
              }
              return fetchedRoles
          }
          getRoleList()
    }, [refetch])
    useEffect(() => {
        // Roles List By User
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.getRoleListByUser}${id}`,
                    {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                      }
                    }
                ).then((response) => {
                  return response.json()
                })
                let fetchedUsers = []
                for (let i = 0; i < response.length; i++) 
                    fetchedUsers.push(response[i])
                setRoles(fetchedUsers)
                if(fetchedUsers.length === 1 && fetchedUsers[0].name === "Verifier User"){
                    setIsExternalUser(true)
                }
                const option = fetchedUsers.map((role) => ({
                  label: role.name,
                  value: role.roleID
                }))
                setSelectedOptions(option)
            } catch (error) {
                navigate("/error-page")
                console.log("Error: ", error)
            }
        }
        fetchData()
    }, [refetch])
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage
        let searchedUsers = []
        for (let i = 0; i < roles.length; i++) {
            if (roles[i].name.toLowerCase().includes(searchName.toLowerCase().trim())) 
              searchedUsers.push(roles[i])
        }
        if (sortColumn !== "") {
            let sortedUsers = []
            if (sortOrder) {
              sortedUsers = SortArray(searchedUsers, sortColumn, -1)
            } else if (!sortOrder) {
              sortedUsers = SortArray(searchedUsers, sortColumn)
            }
            searchedUsers = sortedUsers
        }
        setCurrentItems(searchedUsers.slice(itemOffset, endOffset))
        setPageCount(Math.ceil(searchedUsers.length / itemsPerPage))
    }, [itemOffset, itemsPerPage, roles, searchName, isSortTriggered])
    useEffect(() => {
        const fetchRecentActivities = async () => {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.getUserEvents}${id}`, {
                        method: "GET",
                        headers: {
                          Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    }
                ).then((response) => {
                  return response.json()
                })
                let fetchedActivities = []
                for (let i = 0; i < response.length; i++)
                  fetchedActivities.push(response[i])
                setRecentActivities(fetchedActivities)
            } catch (error) {
                console.log("Error: ", error)
                navigate("/error-page")
            }
        }
        fetchRecentActivities()
    }, [refetch])
    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
              const response = await fetch(`${config.apiUrl}${apiconfig.getUserDetailsByID}${id}`, {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                      }
                  }
              ).then((response) => {  
                return response.json()
              })
              setUserDetails(response)
            } catch (error) {
                console.log("Error: ", error)
                navigate("/error-page")
            }
        }
        fetchUserDetails()
    }, [])


    return (
        <Layout>
            <div className="row">
                <div className="col-md-12">
                    <UserProfileCard name={userDetails.name} email={userDetails.email} category={userDetails.categoryName} statusID={userDetails.statusID} hasNavigationBack={true} navigateBackTo="/users" togglerForPasswordReset={toggleForResetPasswordModal} toggleForManageApplications={toggleForManageEditRolesModal} verifierID={userDetails.externalID} isExternalUser={isExternalUser} />
                </div>
                <div className="col-md-8">
                    <Card title="Assigned Roles" hasLinkButton1={false} toggler1="/users" buttonText1="Back">
                        <SearchBar searchState={searchName} searchStateSetter={setSearchName} />
                        <Table headerCells={tableHeaderCells} setSortColumn={setSortColumn} setIsSortTriggered={setIsSortTriggered} isSortTriggered={isSortTriggered} setSortOrder={setSortOrder} sortOrder={sortOrder}>
                          {/* {currentItems.map(user => <RoleListByUser key={user.id} userapp={user} id={id} refetch={refetch} setRefetch={setRefetch} />)} */}
                          {
                            currentItems.length === 0 
                                ? 
                                <tr className="alt-row">
                                  <td colSpan="2">&nbsp; No roles assigned to the user</td>
                                </tr>
                                : 
                                currentItems.map((user, index) => (
                                  <RoleListByUser key={index} userapp={user} id={id} refetch={refetch} setRefetch={setRefetch} />
                                ))
                          }
                        </Table>
                        <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel="Show" suffixLabel="Entries" icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </Card>
                </div>
                {
                    isReadOnlyAccess1 === 'FullAccess' || isReadOnlyAccess1 === 'FullAccessForAMSAMOnly'  
                        ?
                        <div className="col-md-4">
                            <Card title="Recent User Activities" hasLinkButton1={false}>
                                {
                                    recentActivities.length === 0 
                                        ? 
                                        <div style={{ textAlign: "center", color: "red" }}>
                                          <BiTimeFive />
                                          &nbsp;No Recent Activity
                                        </div>
                                        : 
                                        <div className="login-activity-list ps ps-scroll" style={{ height: 500, position: "relative", overflow: "hidden", overflowY: "scroll" }}>
                                    {
                                        recentActivities.map((activity, index) => (
                                            <RecentActivity key={index} activity={activity} />
                                        ))
                                    }
                                  </div>
                                }
                            </Card>
                        </div>
                        :
                        <div></div>
                }
            </div>
            { /* MODAL FOR PASSWORD RESET */}
            <CommonModal modal={resetPasswordModal} title='' toggler={toggleForResetPasswordModal} heading='RESET PASSWORD' triggerMethodOnSaveButtonClick={resetPassword} buttonText='Reset Password'>
                <Form>
                    <FormGroup>
                        <Label>New Password:</Label>
                        <Input type="password" id="text" placeholder='Enter New Password' onChange={(e) => { setNewPassword(e.target.value) }} />
                        <Label style={{ color: 'red', fontStyle: 'italic', fontSize: 12 }}>{formErrors.newPassword}</Label>
                        <Label style={{ color: 'red', fontStyle: 'italic', fontSize: 12 }}>{formErrors.specialChars}</Label>
                    </FormGroup>
                    <FormGroup>
                        <Label>Confirm Password:</Label>
                        <Input type="password" id="text" placeholder='Confirm New Password' onChange={(e) => { setConfirmPassword(e.target.value) }} />
                        <Label style={{ color: 'red', fontStyle: 'italic', fontSize: 12 }}>{formErrors.confirmPassword}</Label>
                    </FormGroup>
                </Form>
            </CommonModal>
            {/* MODAL FOR MANAGING ROLES */}
            <CommonModal modal={manageEditRolesModal} title="" toggler={toggleForManageEditRolesModal} heading="Edit User Roles" triggerMethodOnSaveButtonClick={updateUserRoles} buttonText="Update Roles">
                <Form>
                    <FormGroup style={{ height: 250, position: "relative" }}>
                        <MultiSelect options={options} value={selectedOptions} labelledBy={"Select Roles"} onChange={handleSelectChange} overrideStrings={overrideStrings} />
                    </FormGroup>
                </Form>
            </CommonModal>
            <ToastContainer/>
        </Layout>
    )
}

export default UserDetails