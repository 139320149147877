import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import axios from '../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const RenewManual = ({ toggler, verifier ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();
    const [examType, setExamType] = useState("");
    const [examTypeID, setExamTypeID] = useState("");
    const [fees, setFees] = useState("");
    const [resit, setResit] = useState("1st attempt");
    const [formErrors, setFormErrors] = useState({});  
    const userID = localStorage.getItem("userID");
    const [isExtension, setIsExtension] = useState(false);
    const [reason, setReason] = useState("");
    const [reasonText, setReasonText] = useState("");
    const [notes, setNotes] = useState("");





    {/*Validation*/}
    const validateForm = (reason,comments) => {
        const errors = {};
        if(!reason){
            errors.reason = 'Please select a value.';
        }
        if(!comments && reason === "Other"){
            errors.comments = 'Please enter the notes.';
        }
        return errors;
    }
    
    useEffect(() => {
        const requestType = 'Renewal'
        fetch(`${config.apiUrl}${apiconfig.getUpgradeDetails}${verifier.verifierDetailID}&LevelID=${1}&RequestType=${requestType}`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
        })
            .then((response) => response.json())
            .then((data) => {
                setExamType(data.examType);
                setResit(data.isResit);
                setFees(data.examFee);
                setExamTypeID(data.examTypeID);

            }).catch((err) => {
                navigate("/error-page");
            });
    }, [verifier.verifierDetailID])

    const renewManual = async () => {
        const requestBody = {
            verifierID : verifier.verifierDetailID,
            sponsorID : verifier.sponsorID ,
            exampTypeID : examTypeID,
            amount : fees,
            resist : resit,
            userID : userID,
            notes : reason === 'Other' ? notes.trim() : reasonText,
            requestType : 'Renewal'


        };
        const validationErrors = validateForm(reason, notes.trim());
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.upgradeVerifier}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier has been renewed successfully.')
            {
                toast.success('Verifier has been renewed successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };


    const handleYes = ()=>{
        setIsExtension(true);
    }
    const handleCancel = ()=>{
        setFormErrors({});
        setIsExtension(false);
    }

    const documentTypeList = [
        { value: '', text: 'Please select Reason Code...' },
        { value: 'Verifier Performance', text: 'Verifier Performance' },
        { value: 'Other', text: 'Other' }
    ];

    const handleReason = (e)=>{
        const selectedOption = e.target.options[e.target.selectedIndex];
        const selectedText = selectedOption.text;
        setReason(e.target.value);
        setReasonText(selectedText)
      }

    return (
        <div>
    <form>
                {(isExtension===false) &&(<div className="form-row">                 
                            <label htmlFor="accreditation_level">The Renewal Date has not lapsed, are you sure you want to initiate the Renewal process for this Verifier ?</label>
                        </div>
                )}
               {isExtension &&(
               <div className="form-row">
                    <label htmlFor="reason-code">Reason Code</label>
                    <select  className="form-control form-control-sm" value={reason}  onChange={handleReason}>
                            {documentTypeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                    </select>
                    <p style={{color:'red'}}>{formErrors.reason}</p>
                </div>
                )}
                {reason === "Other" && (
                    <div className="form-row">
                        <label htmlFor="input_comments">Notes</label>
                        <textarea type="text" placeholder='Please enter the notes...' className="form-control form-control-xs" id="input_comments" onChange={(e) => { setNotes(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
                    )}

                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            {isExtension===false && (
            <>
            <button className="btn btn-success mt-auto" type="button" onClick={handleYes}>Yes</button>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            </>
            )}
            {isExtension &&(
            <>
            <button className="btn btn-success mt-auto" type="button" onClick={renewManual} >Save</button>
            <button className="btn btn-secondary mt-auto" type="button" onClick={handleCancel}>Cancel</button>
            </>
            )}
            </div>
            </div>
    );
};

export default RenewManual;