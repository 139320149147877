import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import CommonModal from '../../Common/CommonModal'
import SearchBar from '../../Common/SearchBar'
import Card from '../../Common/CardWithMutipleButton'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import Table from '../../Common/Table'
import SortArray from "../../Helper/SortArray"
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { TbArrowsSort, TbCategory } from 'react-icons/tb'
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { useParams } from 'react-router-dom'
import PermissionListByRole from './PermissionListByRole'
import { MdOutlineAdminPanelSettings } from "react-icons/md"
import { useLocation } from 'react-router-dom'
import { MultiSelect } from "react-multi-select-component"
import { useNavigate } from "react-router-dom"

const RoleDetails = () => {

    /* States & Constants */
    const [searchName, setSearchName] = useState('')
    const [permission, setPermission] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [currentItems, setCurrentItems] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState(true)
    const [sortColumn, setSortColumn] = useState('')
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const { roleid } = useParams()
    const [options, setOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [editPermissionModal, setEditPermissionModal] = useState(false)
    const location = useLocation()
    const RoleName = location.state?.name
    const navigate = useNavigate()
    const userID = localStorage.getItem("userID")
    const RoleUserID = location.state?.roleUserID
    const isAllSelected = selectedOptions.length === options.length
    const overrideStrings = { selectAll: isAllSelected ? 'Deselect All' : 'Select All' }

    /* Data */
    const tableHeaderCells = [
        {
            id: 1,
            isHidden: true,
            name: 'ID',
            colSpan: 1,
            style: {},
            hasIcon: false,
            icon: null,
            isSortable: false,
            sortByColumnName: null
        },
        {
            id: 2,
            isHidden: false,
            name: 'Permission Name',
            colSpan: 1,
            style: { cursor: 'pointer' },
            hasIcon: true,
            icon: <TbArrowsSort />,
            isSortable: true,
            sortByColumnName: 'name'
        }
    ]

    /* Methods */
    const toggleForEditPermissionModal = () => {
        setEditPermissionModal(!editPermissionModal)
        setSelectedOptions(permission)
    }
    const UpdateUserPermission = async () => {
        // Update Permission

        const filteredOptions = selectedOptions.filter((option) => option.value !== "*")
        const values = filteredOptions.map(items => ({ name: items.label, id: items.value }))
        const requestBody = {
            "roleID": roleid,
            "userID": userID,
            "permissionIDs": values,
            "isSuccessful": true,
            "errorMessage": ""
        }

        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.updatePermission}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody)
            }).then((response) => {
                return response
            })
            toggleForEditPermissionModal()
            setRefetch(!refetch)
        }
        catch (error) {
            navigate("/error-page")
            console.log("Error: ", error)
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
        const newOffset = (e.selected * itemsPerPage) % permission.length
        setItemOffset(newOffset)
    }
    const handleSelectChange = (selected) => {
        // handle  Selected Role
        setSelectedOptions(selected)
    }

    /* Effects */
    useEffect(() => {
        // All PermissionList
        let fetchedAllPermission = []
        function getPermissionList() {
            fetch(`${config.apiUrl}${apiconfig.getAllPermission}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then(res => res.json())
                .then((data) => {
                    for (let i = 0; i < data.length; i++) {
                        fetchedAllPermission.push({
                            label: data[i].name,
                            value: data[i].id
                        })
                    }
                    setOptions(fetchedAllPermission)

                }).catch((err) => {

                })
            return fetchedAllPermission
        }
        getPermissionList()
    }, [])
    useEffect(() => {
        // PermissionListByRole
        const fetchDataPermission = async () => {
            try {
                const response = await fetch((`${config.apiUrl}${apiconfig.getPermissionListByRole}${roleid}`), {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }).then((response) => {
                    return response.json()
                })
                let fetchedPermission = []
                for (let i = 0; i < response.length; i++)
                    fetchedPermission.push({ label: response[i].name, value: response[i].id })
                setPermission(fetchedPermission)
                setSelectedOptions(fetchedPermission)
            }
            catch (error) {
                navigate("/error-page")
                console.log("Error: ", error)
            }
        }
        fetchDataPermission()
    }, [refetch])
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage
        let searchedUsers = []
        for (let i = 0; i < permission.length; i++) {
            if (permission[i].label.toLowerCase().includes(searchName.toLowerCase().trim()))
                searchedUsers.push(permission[i])
        }
        if (sortColumn !== '') {
            let sortedUsers = []
            if (sortOrder) {
                sortedUsers = SortArray(searchedUsers, sortColumn, -1)
            }
            else if (!sortOrder) {
                sortedUsers = SortArray(searchedUsers, sortColumn)
            }
            searchedUsers = sortedUsers
        }
        setCurrentItems(searchedUsers.slice(itemOffset, endOffset))
        setPageCount(Math.ceil(searchedUsers.length / itemsPerPage))
    }, [itemOffset, itemsPerPage, permission, searchName, isSortTriggered])
    

    return (
        <Layout>
            <div className="row">
                <div className="col-md-12">
                    <Card title={RoleName} hasButton={true} buttonText='Permissions' toggler={toggleForEditPermissionModal} icon={<MdOutlineAdminPanelSettings />} hasLinkButton1={true} toggler1= {`/userDetails/${RoleUserID}`} buttonText1='Back'>
                        <SearchBar searchState={searchName} searchStateSetter={setSearchName} />
                        <Table headerCells={tableHeaderCells} setSortColumn={setSortColumn} setIsSortTriggered={setIsSortTriggered} isSortTriggered={isSortTriggered} setSortOrder={setSortOrder} sortOrder={sortOrder}>
                            {/*{currentItems.map(permission => <PermissionListByRole key={permission.id} permission={permission} id={roleid} refetch={refetch} setRefetch={setRefetch} />)}*/}
                            {
                                currentItems.length === 0 
                                    ?
                                    <tr className="alt-row">
                                        <td>&nbsp; No data available in table</td>
                                    </tr>
                                    :
                                    currentItems.map(permission => <PermissionListByRole key={permission.id} permission={permission} id={roleid} refetch={refetch} setRefetch={setRefetch} />)
                            }
                        </Table>
                        <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </Card>
                </div>
            </div>
            <CommonModal modal={editPermissionModal} title='Edit Permissions' toggler={toggleForEditPermissionModal} heading='Edit Permissions' triggerMethodOnSaveButtonClick={UpdateUserPermission} buttonText='Save' style={{ height: '350px' }}>
                <Form>
                    <MultiSelect  style={{ height: 250, position: "relative" }} options={options} value={selectedOptions} labelledBy={"Select Roles"} onChange={handleSelectChange} overrideStrings={overrideStrings} />
                </Form>
            </CommonModal>
        </Layout>
    )
}

export default RoleDetails