import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";
import { useEffect } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({data,options,plugins})=> {
//   useEffect(() => {
//     ChartJS.register(plugins);
//   }, [plugins]);

    return(
        <Doughnut data={data} options={options} plugins={[plugins]} />
    );
};

export default DoughnutChart;