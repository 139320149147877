import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const RejectAirport = ({ toggler, accAirport ,refetchs, setRefetchs,refetchDash,setRefetchDash}) => {

    const navigate = useNavigate();

    const [notes, setNotes] = useState("");
    const [reason, setReason] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [reasonCodeList, setReasonCodeList] = useState([]);
    const [reasonText, setReasonText] = useState("");


    const rejectAirport = async () => {

      const requestBody = {
        "userID":userID,
        "airportApplicationID": accAirport.airportApplicationID,
        "comment": reasonText === 'Others' ? notes.trim() : reasonText,
        "actionType": "Rejected",
        "airportType" : accAirport.airportType

      };
      const validationErrors = validateForm(reason, notes.trim());
      setFormErrors(validationErrors);
      if(Object.keys(validationErrors).length === 0){
          try {
              const response = await fetch(`${config.apiUrl}${apiconfig.approveRejectAirport}`,{
                      method: "POST",
                      headers: {
                          "Content-Type": "application/json",
                          'Authorization': `Bearer ${localStorage.getItem("token")}`
                      },
                      body: JSON.stringify(requestBody),
                  });
                  if (response.ok) {
                      var result = await response.json();
                      if(result.saveErrorMessage === 'Airport has been rejected successfully.')
                      {
                          toast.success('Airport has been rejected successfully.', {
                              position: "top-right",
                              autoClose: 10000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                              transition: Slide
                          }); 
                      setRefetchs(!refetchs);
                      if(refetchDash!==undefined)
                      {
                        setRefetchDash(!refetchDash);
                      }
                      toggler();
                      }else{
                          setErrorMessage(result.saveErrorMessage);
                      }
                  } else {
                      const errorText = await response.text();
                      const apiname = `${apiconfig.approveRejectAirport}`;
                      navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                  }
          } catch (error) {
              console.log("Error: ", error);
              navigate("/error-page");
          }
        }
  };

 const  dropdownOptions = [
    {text: 'Please Select Rejection Reason Codes...', value: 0},
    // {text: 'ACI is already member, and they already have account (duplicate)', value: 1},
    {text: 'Additional account of same airport', value: 1},
    {text: 'Dummy Airport account', value: 2},
    {text: 'Not a valid Airport', value: 3},
    {text: 'Not member of ACI', value: 4},
    {text: 'Others', value: -1},
]

    {/*Validation*/}
    const validateForm = (reason, comments) => {
        const errors = {};
        if(!comments && reason === "-1"){
            errors.comments = 'Please enter the reason.';
        }else if(comments.length > 1000 && reason === "-1"){
            errors.comments = 'Maximum of 1000 characters allowed.';
        }else if(comments.length < 10 && reason === "-1"){
          errors.comments = 'Please enter a minimum of 10 characters.';
        }
        if(reason === "0" || reason === ""){
            errors.reason = 'Please select a value.';
        }
        return errors;
    }


    //   useEffect(() => {
    //     function getReasonCodeList() {
    //         fetch(`${config.apiUrl}${apiconfig.getreasonCode}`, {
    //             method: 'GET',
    //             headers: {

    //                 Authorization: `Bearer ${localStorage.getItem("token")}`

    //             }
    //         }).then(res => res.json())
    //             .then((data) => {

    //               setReasonCodeList(data);
    //             }).catch((err) => {
    //                 console.log("Error: ", err);
    //                 navigate("/error-page");
    //             });
    //     }
    //     getReasonCodeList();
    // }, []);


      const handleReason = (e)=>{
        const selectedOption = e.target.options[e.target.selectedIndex];
        const selectedText = selectedOption.text;
        setReason(e.target.value);
        setReasonText(selectedText)
      }
      

    return (
        <div>
    <form>
                    <div className="form-row">
                        <label htmlFor="resit">Rejection Reason Code</label>
                        <select  className="form-control form-control-sm" value={reason}  onChange={handleReason}>
                            {dropdownOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                    </select>
                    <p style={{color:'red'}}>{formErrors.reason}</p>
                    </div>
                    {reason === "-1" && (
                    <div className="form-row">
                        <label htmlFor="input_comments">Reason</label>
                        <textarea type="text" placeholder='Please enter the text...' className="form-control form-control-xs" id="input_comments" onChange={(e) => { setNotes(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
                    )}
                   
                   {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )}  
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={rejectAirport}>Confirm</button>
            </div>
            </div>
    );
};

export default RejectAirport;