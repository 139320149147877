import React, { useState } from 'react'
import { Slide, toast } from 'react-toastify'

const ReviewComments = ({ data, subSectionIndex, sectionIndex, state, setState, userType, commentsVisibility }) => {

    const [comment, setComment] = useState('')

    const updateState = (action, index) => {
        const updatedState = {...state}
        let comments = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.comments
        switch(action){
            case 'add':
                let enGBTime = new Date().toLocaleTimeString('en-GB', { timeZone: 'Europe/London', hour: 'numeric', minute: 'numeric', hour12: true })
                if(parseInt(enGBTime.charAt(0)) === 0)
                    enGBTime = '12' + enGBTime.slice(1)

                comments.push({ 
                    id: (comments.length + 1).toString(), 
                    by: localStorage.getItem("userDisplayName"),
                    type: userType, 
                    date: new Date().toLocaleDateString('en-GB', { timeZone: 'Europe/London', year: 'numeric', month: 'numeric', day: 'numeric' }), 
                    time: enGBTime, 
                    comment: comment 
                })
                setComment('')
                break
            case 'remove':
                comments.splice(index, 1)
                // re-assign id(s)
                comments.map((comment, comment_index) => {
                    comments[comment_index].id = (comment_index + 1).toString()
                })
                break
            default:
                break
        }
        updatedState.sections[sectionIndex].subsections[subSectionIndex].content.comments = comments
        setState(updatedState)
    }

    return(
        <div className="card card-border card-contrast" style={userType === 'draft' && (state.applicationStatus === 'In Progress' || state.applicationStatus === 'Draft') ? {display: 'none'} : {}}>
            <div className="card-body card-body-contrast px-2">
                {
                    userType === 'draft' 
                        ? 
                        <h5 className="mt-0">Airport Review Comments</h5>
                        :
                        userType === 'verifier' 
                            ?
                            <h5 className="mt-0">Verifier Review Comments</h5>
                            :
                            userType === 'admin' 
                                ?
                                <h5 className="mt-0">Admin Review Comments</h5>
                                :
                                <React.Fragment></React.Fragment>
                }
                {
                    data.length === 0 
                        ?
                        <label className="text-info">No comments added yet.</label> 
                        :
                        data.map((comment, index) => {
                            return(
                                <div key={index} className="media mb-3 p-1 border rounded">
                                    <img src={require('../../../img/loggdin.png')} width="32" height="32" alt="Avatar"></img>
                                    <div className="media-body ml-3 align-self-center">
                                        <div className="float-right">
                                            <button type="button" className="btn shadow-none" style={commentsVisibility() && userType === comment.type ? {} : {display: 'none'}} onClick={() => { updateState('remove', index) }}><span className="mdi mdi-3x mdi-delete"/></button>
                                        </div>
                                        <h5 className="mb-1 mt-0 d-inline-block">by: {comment.by} - {comment.type === 'draft' ? 'Airport User' : (comment.type === 'verifier' ? 'Verifier User' : 'Admin User')}</h5>
                                        <h6 className="mb-3 mb-3 mt-0 font-weight-normal">{comment.date} at {comment.time}&nbsp;</h6>
                                        <p className="mb-2 font-weight-normal">{comment.comment}</p>
                                    </div>
                                </div>
                            )
                        })
                }
                <hr className="my-2"/>
                <div className="row align-items-center" style={commentsVisibility() ? {} : {display: 'none'}}>
                    <div className="col-md-12">
                        <div className="input-group">
                            <textarea className="form-control" id="inputTextArea" placeholder="Comment here..." value={comment} onChange={(e) => { setComment(e.target.value)}} />
                            <div className="input-group-append ml-3">
                                <button className="btn btn-sm btn-primary rounded" type="button" onClick={() => { if(comment.length !== 0) { updateState('add', -1) } else { toast.info('Please enter a comment.', { position: "top-right", autoClose: 3000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide }) } }}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewComments