import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import CommonCustomModal from '../Common/CommonCustomModal';
import RenewVerifier from './Renew';

const RenewLevelAndReason = ({ toggler, verifier ,refetchs, setRefetchs, isRenewalApplicableFor2Attempt}) => {

    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState({});  
    const userID = localStorage.getItem("userID");
    const [renewModal, setRenewModal] = useState(false);
    const [renewalLevel, setRenewalLevel] = useState("");
    const [renewalLevelText, setRenewalLevelText] = useState("");
    const [renewalReason, setRenewalReason] = useState("");
    const [renewalReasonText, setRenewalReasonText] = useState("");
    const [comments, setComments] = useState("");;

  const toggleForRenewVerifierModal = () => {
    setRenewModal(!renewModal);
  } 


  // let levelsOptions = []
  // if(verifier.accreditationLevel === 'Level 1-3+'){
  //      levelsOptions = [
  //     {  text: 'Please select Renewal Level…', value: ''},
  //     {  text: 'Level 1-3+', value: 'Level 1-3+'}
  //   ];
  // }else if(verifier.accreditationLevel === 'Level 4-4+'){
    //  levelsOptions = [
    //   {  text: 'Please select Renewal Level…', value: ''},
    //   {  text: 'Level 1-3+', value: 'Level 1-3+'},
    //   {  text: 'Level 4-4+', value: 'Level 4-4+'}
    // ];
  // }

     const levelsOptions = [
      {  text: 'Please select Renewal Level…', value: ''},
      {  text: 'Level 1-3+', value: '1'},
      {  text: 'Level 4-4+', value: '2'}
    ];



  const  renewalReasonOptions = [
    {text: 'Please select Renewal Reason Code…', value: ''},
    {text: 'ACI Request', value: 1},
    {text: 'New mandatory training/exam', value: 2},
    {text: 'Performance Related', value: 3},
    {text: 'Renewal Extension', value: 4},
    {text: 'Verifier Lapse/Inactivity', value: 5},
    {text: 'Other', value: 6},
]

const handleRenewalReason = (e)=>{
  const selectedOption = e.target.options[e.target.selectedIndex];
  const selectedText = selectedOption.text;
  setRenewalReason(e.target.value);
  setRenewalReasonText(selectedText)
}

const handleRenewalLevel = (e)=>{
  const selectedOption = e.target.options[e.target.selectedIndex];
  const selectedText = selectedOption.text;
  setRenewalLevel(e.target.value);
  setRenewalLevelText(selectedText)
}

  const saveLevelAndReason = async () => {

    const validationErrors = validateForm(renewalLevel,renewalReason,comments);
        setFormErrors(validationErrors);
        if(Object.keys(validationErrors).length === 0){
          try {
            toggleForRenewVerifierModal();

          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
    };


{/*Validation*/}
const validateForm = (renewalLevel,renewalReason,comments) => {
    const errors = {};
    if(!renewalLevel){
        errors.renewalLevel = 'Please select a value.';
    }
    if(!renewalReason){
      errors.renewalReason = 'Please select a value.';
    }
    if(!comments){
        errors.comments = 'Please enter the notes.';
    }else if(comments.length > 1000){
        errors.comments = 'Maximum of 1000 characters allowed.';
    }else if(comments.length < 10){
      errors.comments = 'Please enter a minimum of 10 characters.';
    }
    return errors;
}
    return (
      <>
        
        <div>
                <form>
                  <div className="form-row">
                  <label htmlFor="renewal-Level">Renewal Level(s):</label>
                  <select  className="form-control form-control-sm" value={renewalLevel} onChange={handleRenewalLevel} >
                   {verifier.accreditationLevel === 'Level 1-3+' ? 
                          levelsOptions.filter(option => option.text !== 'Level 4-4+').map((option) => (
                              <option key={option.value} value={option.value}>
                                  {option.text}
                              </option>
                          ))
                          :
                          levelsOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))
                    }
                  </select>
                  <p style={{color:'red'}}>{formErrors.renewalLevel}</p>
                  </div>
                  <div className="form-row mt-2">
                  <label htmlFor="renewal-Reason">Renewal Reason:</label>
                  <select  className="form-control form-control-sm" value={renewalReason} onChange={handleRenewalReason} >
                          {renewalReasonOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                  {option.text}
                              </option>
                          ))}
                  </select>
                  <p style={{color:'red'}}>{formErrors.renewalReason}</p>
                  </div>                  
                  <div className="form-row mt-2">
                      <label htmlFor="input_comments">Renewal Reason Notes:</label>
                      <textarea type="text" className="form-control form-control-xs" placeholder='Please Enter Notes...' onChange={(e) => { setComments(e.target.value) }}  style={{ height: 120}}/>
                      <p style={{color:'red'}}>{formErrors.comments}</p>
                  </div>                                
                </form>
                <div className="modal-footer">
                  <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                  <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                  <button className="btn btn-success mt-auto" type="button" onClick={saveLevelAndReason}>Submit</button>
                </div>
            </div>

         

          <CommonCustomModal modal={renewModal}  title='' toggler={toggleForRenewVerifierModal} heading='Renew Verifier'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
            <RenewVerifier toggler = {toggler} toggler1={toggleForRenewVerifierModal} verifier={verifier} refetchs={refetchs} setRefetchs={setRefetchs} isRenewalApplicableFor2Attempt = {isRenewalApplicableFor2Attempt} renewalLevel = {renewalLevel} renewalLevelText = {renewalLevelText} renewalReasonText = {renewalReasonText}  comments = {comments}/>
          </CommonCustomModal>
          </>
    );
};

export default RenewLevelAndReason;