import React, { useState, useEffect } from 'react';
import Layout from '../../../Layout';
import UsersEvent from './UsersEvent';
import { TableHeaderCells } from './TableHeaderCells';
import SearchBar from '../../Common/SearchBar';
import Card from '../../Common/Card';
import Pagination from '../../Common/Pagination';
import Selector from '../../Common/Selector';
import Table from '../../Common/Table';
import { TiArrowUnsorted } from 'react-icons/ti';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import SortArray from "../../Helper/SortArray";
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions';

const UsersEvents = () => {

    {/* STATES */ }
    const [searchName, setSearchName] = useState('');
    const [usersEvents, setUsersEvents] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState(true);
    const [sortColumn, setSortColumn] = useState('');
    const [isSortTriggered, setIsSortTriggered] = useState(false);

    {/* HOOKS */ }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.getUsersEvents}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                }).then((response) => {
                    return response.json();
                })
                let fetchedUsersEvents = [];
                for (let i = 0; i < response.length; i++) {
                    fetchedUsersEvents.push(response[i]);
                }
                setUsersEvents(fetchedUsersEvents);
            }
            catch (error) {
                console.log("Error: ", error);
            }
        };
        fetchData();
    }, [refetch]);
    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        let searchedUsersEvents = [];
        for (let i = 0; i < usersEvents.length; i++) {
            if (usersEvents[i].displayName.toLowerCase().includes(searchName.toLowerCase().trim()) || usersEvents[i].name.toLowerCase().includes(searchName.toLowerCase().trim())) {
                searchedUsersEvents.push(usersEvents[i]);
            }
        }
        if (sortColumn !== '') {
            let sortedUsersEvents = [];
            if (sortOrder) {
                sortedUsersEvents = SortArray(searchedUsersEvents, sortColumn, -1);
            }
            else if (!sortOrder) {
                sortedUsersEvents = SortArray(searchedUsersEvents, sortColumn);
            }
            searchedUsersEvents = sortedUsersEvents;
        }
        setCurrentItems(searchedUsersEvents.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(searchedUsersEvents.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, usersEvents, searchName, isSortTriggered]);

    {/* METHODS */ }
    const onPageClick = (e) => {
        const newOffset = (e.selected * itemsPerPage) % usersEvents.length;
        setItemOffset(newOffset);
    };

    return (
        <Layout>
            <div className="row">
                <div className="col-md-12">
                    <Card title='Users Events' hasButton={false}>
                        <SearchBar searchState={searchName} searchStateSetter={setSearchName} />
                        <Table headerCells={TableHeaderCells} setSortColumn={setSortColumn} setIsSortTriggered={setIsSortTriggered} isSortTriggered={isSortTriggered} setSortOrder={setSortOrder} sortOrder={sortOrder}>
                            {
                                usersEvents.length === 0
                                    ?
                                    <tr className="alt-row">
                                        <td colSpan={TableHeaderCells.length-1} style={{ textAlign: 'center' }}>No Records Found</td>
                                    </tr>
                                    :
                                    currentItems.map((usersEvent, index) => <UsersEvent key={index} usersEvent={usersEvent} searchName={searchName.toLowerCase()} />)
                            }
                        </Table>
                        <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} />
                    </Card>
                </div>
            </div>
        </Layout>
    );
};

export default UsersEvents;