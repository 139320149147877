import React, { useState} from 'react';
import { Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';



const ReportingPeriod = ({ reportingperiod, searchName, refetch, setRefetch }) => {
    const navigate = useNavigate();
    const userID = localStorage.getItem("userID");
  


        return (
            <>
                <tr>
                    <td>{reportingperiod.name}</td>
                    <td>{reportingperiod.description}</td>
                    <td>{reportingperiod.user}</td>
                    <td>{reportingperiod.createdDateTime}</td>
                    {/* <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu">                         
                          </div>
                    </td> */}
                </tr>

            </>
        );
};

export default ReportingPeriod;