import React from 'react'

const Tab = ({ iconClassName, tabName, activeTabName, setActiveTabName, disableOnClickEvent }) => {
    return (
        <li className={activeTabName === tabName ? "active" : ""} onClick={() => { if(!disableOnClickEvent){ setActiveTabName(tabName) } }} style={disableOnClickEvent ? {cursor: 'not-allowed'} : {cursor: 'pointer'}}>
            <div className="step">
                <span className="indicator">
                    <span className={iconClassName} />
                </span>
                <span className="description">{tabName}</span>
            </div>
        </li>
    )
}

export default Tab