import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import EditAirportDetail from './EditAirportDetail';

const UpdateAirportUser = ({ toggler, groupDetails, refetch, setRefetch}) => {

    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [groupName, setGroupName] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [viewAirportGroupList, setViewAirportGroupList] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);

   
    const editAirportGroup = async () => {

        setIsSubmit(true);
        setErrorMessage("");
        const requestBody = {
            "userID": userID,
            "airportGroup": groupName,
            "iataCode" : '',
            "isSmallGroup":0,
            "id" : groupDetails.airportGroupID,
            "message": '',

        };
        const validationErrors = validateForm(groupName);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(`${config.apiUrl}${apiconfig.editAirportGroup}`,{
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    });
                if (response.ok) {
                    var result = await response.json();
                    if(result.saveErrorMessage === 'Airport group updated.')
                    {
                        toast.success('Airport group updated successfully.', {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                            transition: Slide
                        }); 
                    setRefetch(!refetch);
                    }else{
                        setErrorMessage(result.saveErrorMessage);
                    }
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.editAirportGroup}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
        }
    };


    {/*Validation*/ }
    const validateForm = (airportGroup) => {
        const errors = {};
        if(!airportGroup){
            errors.airportGroup = 'Please enter a value.';
        }else if(airportGroup.length > 256){
            errors.airportGroup = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(airportGroup)) {
            errors.airportGroup = 'Special characters are not allowed.';
        }
        return errors;
    }

    const ViewGroupList = async () => {

        try {
            const response = await fetch(`${config.apiUrl}${apiconfig.getAccreditationViewAirportGroupList}${groupDetails.airportGroupID}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                const result = await response.json();
                let fetchedList = [];
                for (let i = 0; i < result.length; i++) {
                    fetchedList.push(result[i]);
                }
                setViewAirportGroupList(fetchedList);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAccreditationViewAirportGroupList}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
        }
        catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
        }
    };

    useEffect(()=>{
        if(!isSubmit){
            setGroupName(groupDetails.airportGroup);
        }
        ViewGroupList();
    },[refetch])
      

    return (
        <>
        <div>
              <form>
                <div className="form-group row">
                    <label htmlFor="GroupName" className="col-md-2 col-form-label">Group Name <span className="text-danger font-weight-bold">*</span></label>
                    <div className="col-md-6">
                    <input type="text" value={groupName} className="form-control form-control-sm" id="GroupName" placeholder='Please Enter Group Name...' onChange={(e) => { setGroupName(e.target.value) }}/>
                    <span style={{color:'red'}}>{formErrors.airportGroup}</span>
                    </div>
                    <div className="col-md-2">
                    <button className="btn btn-success mt-auto" type="button" onClick={editAirportGroup} >Save</button>
                    </div>
                    {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                    ) : (
                        <p></p>
                    )} 
                </div>
                <div className="card card-border card-contrast card-table">
                    <div className="card-header card-header-contrast">Airport Details
                    </div>
                        <div className="card-body table-responsive">
                                <table className="table table-striped table-borderless">
                                    <thead>
                                    <tr>
                                    <th>ACI Region </th>
                                    <th>Country</th>
                                    <th>City</th>
                                    <th>State</th>
                                    <th>Airport Name</th>
                                    <th>IATA Code</th>
                                    <th></th>
                                    </tr>
                                    </thead>
                                    <tbody className="no-border-x">
                                    {
                                        viewAirportGroupList.map(airport => <EditAirportDetail key={airport.ID} airport={airport} refetch={refetch} setRefetch={setRefetch}/>)
                                    }
                                    </tbody>
                                </table>
                        </div>
                </div>
              </form>

              <div className="modal-footer">
              <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
              </div>
        </div>

        </>
    );
};

export default UpdateAirportUser;