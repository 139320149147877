import React, { useState, useEffect } from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import axios from '../../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import CommonModalWithImageAndButton from '../../Common/CommonModalWithImageAndButton';
import { Label } from 'reactstrap';

const EditVerifierExam = ({ toggler, verifier, exam, refetchs, setRefetchs,refetchd,setRefetchd }) => {

    const navigate = useNavigate();

    const [examReference, setExamReference] = useState("");
    const [examStatus, setExamStatus] = useState("");
    const [comments, setcomments] = useState("");
    const [examVerifierID, setExamVerifierID] = useState("");
    const [examID, setExamID] = useState("");
    const [examTypeID, setExamTypeID] = useState("");
    const [examTypeList, setExamTypeList] = useState([]);
    const [examStatusList, setExamStatusList] = useState([]);
    const [resit, setResit] = useState("");
    const [sponsorName, setSponsorName] = useState("");
    const [clientID, setClientID] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [confirmModal, setConfirmModal] = useState(false);
    const [examStatusText, setExamStatusText] = useState("");
    const [examLevel, setExamLevel] = useState("");
    const [examType, setExamType] = useState("");
    const [isClicked, setIsClicked] = useState(true);
    const toggleForConfirmModal = () => {
        const validationErrors = validateForm(examStatus);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setConfirmModal(!confirmModal);
          }
    } 


    const updateVerifierExam = async () => {
        setIsClicked(!isClicked);
        const requestBody = {
            "examID": examVerifierID,
            "statusID": examStatus,
            "refNo": examReference,
            "notes": comments.trim(),
            "examTypeID": examTypeID,
            "resit": resit,
            "userID": userID,
            "examLevel":examLevel,
            "examStatusText": examStatusText,
            "examType": examType
        };
        const validationErrors = validateForm(examStatus);
        setFormErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            try {
                const response = await fetch(
                    `${config.apiUrl}${apiconfig.updateVerifierExam}`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem("token")}`
                        },
                        body: JSON.stringify(requestBody),
                    }
                ).then((response) => {
                    return response.json();
                });
                if (response.saveErrorMessage === 'Exam has been updated successfully.') {

                    toast.success('Exam details has been updated successfully.', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Slide
                    });
                    setRefetchs(!refetchs);
                    setRefetchd(!refetchd);
                    toggler();
                }
            } catch (error) {
                console.log("Error: ", error);
                navigate("/error-page");
            }
        }
        else{
            setIsClicked(true);
        }
    };



    useEffect(() => {
        function getExamTypeList() {
            fetch(`${config.apiUrl}${apiconfig.getExamTypes}${exam.examID}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setExamTypeList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getExamTypeList();
    }, []);

    useEffect(() => {
        function getExamStatusList() {
            fetch(`${config.apiUrl}${apiconfig.getExamStatus}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setExamStatusList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getExamStatusList();
    }, []);


    {/*Validation*/ }
    const validateForm = (examStatus) => {
        const errors = {};
        if (!examStatus) {
            errors.examStatus = 'Please select at least one Exam status.';
        }
        // if(!comments){
        //     errors.comments = 'Please enter the Comments.';
        // }else if(comments.length > 1000){
        //     errors.comments = 'Maximum of 1000 characters allowed.';
        // }
        return errors;
    }


    const attemptOptions = [
        { value: '1st attempt', text: '1st attempt' },
        { value: '2nd attempt', text: '2nd attempt' }
    ];

    //get DetailsByID
    useEffect(() => {
        fetch(`${config.apiUrl}${apiconfig.getExamDetailsByID}${exam.examID}&Type=${'Exam'}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
              }, 
        })
            .then((response) => response.json())
            .then((data) => {
                setExamReference(data.externalExamRef);
                setExamStatus(data.examStatusID);
                setExamID(data.id);
                setExamVerifierID(data.id);
                setExamTypeID(data.examTypeID);
                setResit(data.resit);
                setExamLevel(data.examType);
                setSponsorName(exam.sponsorName);
                setClientID(exam.sponsorClientID);
                setExamType(exam.examType);

            }).catch((err) => {
                navigate("/error-page");
            });
    }, [])


    const handleExamStatus = (e)=>{
        const selectedOption = e.target.options[e.target.selectedIndex];
        const selectedText = selectedOption.text;
        setExamStatus(e.target.value);
        setExamStatusText(selectedText)
      }

    return (
        <>
        <div>
            <form>
                <div className="form-group">
                    <label htmlFor="ExamReference">External Exam Reference</label>
                    <input id="ExamReference" type="text" className="form-control form-control-xs" placeholder='Please Enter External Exam Reference...' value={examReference} onChange={(e) => { setExamReference(e.target.value) }} />
                </div>
                <div className="form-group">
                    <label htmlFor="exam-Status">Exam Status <span className="text-danger font-weight-bold">*</span></label>
                    <select id="exam-Status" className="form-control form-control-sm" value={examStatus} onChange={handleExamStatus}>
                        {examStatusList.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                    <p style={{ color: 'red' }}>{formErrors.examStatus}</p>
                </div>
                <div className="form-group">
                    <label htmlFor="input_comments">Comments</label>
                    <textarea id="input_comments" type="text" className="form-control form-control-xs" placeholder='Please Enter Comments...' onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120 }} />
                    <p style={{ color: 'red' }}>{formErrors.comments}</p>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label mt-1" htmlFor="examID">Exam ID :</label>
                    <div className="col-sm-10">
                        <label className="form-control-plaintext" id="examID">{examID}</label>
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="exam-type">Exam Type</label>
                    <select id="exam-type" className="form-control form-control-sm" value={examTypeID} onChange={(e) => { setExamTypeID(e.target.value) }} disabled>
                        {examTypeList.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="exam-Resit">Resit</label>
                    <select id="exam-Resit" className="form-control form-control-sm" value={resit} onChange={(e) => { setResit(e.target.value) }} disabled>
                        {attemptOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label mt-1" htmlFor="sponsorName">Client/Sponsor Name :</label>
                    <div className="col-sm-6">
                        <label className="form-control-plaintext" id="sponsorName">{sponsorName}</label>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 col-form-label mt-1" htmlFor="clientID">Client/Sponsor ID :</label>
                    <div className="col-sm-6">
                        <label className="form-control-plaintext" id="clientID">{clientID}</label>
                    </div>
                </div>


            </form>
            <div className="modal-footer">
                <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
                <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                <button className="btn btn-success mt-auto" type="button" onClick={(examStatusText === 'Exam Passed' || examStatusText === 'Exam Failed') ? toggleForConfirmModal : updateVerifierExam}>Submit</button>
            </div>
        </div>

<CommonModalWithImageAndButton modal={confirmModal} title='' toggler={toggleForConfirmModal} heading='' triggerMethodOnSaveButtonClick={updateVerifierExam} buttonText='Yes' isClicked = {isClicked}>
<Label className="col-form-label">{'Are you sure you want to set the Exam Status as '}{examStatusText}{' ?'}</Label>
</CommonModalWithImageAndButton>
</>
    );
};

export default EditVerifierExam;