import React from 'react';
import { TbArrowsSort } from 'react-icons/tb';

export const TableHeaderCells = [
    {
        id: 1,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        style: {},
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 2,
        isHidden: false,
        name: 'Email Type',
        colSpan: 1,
        style: { cursor: 'pointer', width: '20%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'EmailType'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Sent Date',
        colSpan: 1,
        style: { cursor: 'pointer', width: '20%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'SentDate'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Email Recipient(s)',
        colSpan: 1,
        style: { cursor: 'pointer', width: '20%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'Recipient'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Email Subject',
        colSpan: 1,
        style: { cursor: 'pointer', textAlign: 'center', width: '20%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'Subject'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Email Sent Status',
        colSpan: 1,
        style: { cursor: 'pointer', textAlign: 'center', width: '20%' },
        hasIcon: true,
        icon: <TbArrowsSort />,
        isSortable: true,
        sortByColumnName: 'Status'
    }
];