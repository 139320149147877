import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CommonModalWithImageAndButton = ({ modal, title, toggler, heading,triggerMethodOnSaveButtonClick, children,buttonText,isClicked }) => {

    return (
        <Modal isOpen={modal} title={title} toggle={toggler} size={'100'} centered>
            <ModalHeader toggle={toggler}>
                {heading}
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
            <img className="mr-auto" src="dist/img/ACA-logo.png" alt='ACA logo' width="150"/>
            <button className="btn btn-secondary md-close" type="button" onClick={toggler}>No</button>
            <button className="btn btn-primary md-close" type="button" disabled={!isClicked} onClick={triggerMethodOnSaveButtonClick}>{buttonText}</button>
            </ModalFooter>
        </Modal>
    );

};

export default CommonModalWithImageAndButton;