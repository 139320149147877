import React, { useState, useEffect } from "react"
import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import { useNavigate, Link } from "react-router-dom"
import TabContainer from "../../Verifiers/ExpandedListViewComponents/TabContainer"
import TabList from "./TabList"
import TabContent from "../../Verifiers/ExpandedListViewComponents/TabContent"
import TabPanel from "../../Verifiers/ExpandedListViewComponents/TabPanel"
import Loader from "../../Common/Loader"
import AirportGroupApplicationsList from "./AirportGroupApplicationsList"
import AirportGroupUsersList from "./AirportGroupUsersList"
import AirportGroupAirportList from "./AirportGroupAirportList"
import { htmlConverterReact } from 'html-converter-react'
import IsAccessForAccreditationCombination from "../../Common/IsAccessForAccreditationCombination"

const AirportGroupDetailsTab = ({ airportGroupID, refetchs, setRefetchs, setIsLoadingChildData, isLoadingChildData }) => {

    /* States & Constants */
    const navigate = useNavigate()
    const [refetch, setRefetch] = useState(false)
    const [activeTab, setActiveTab] = useState('Airports')
    const [loading, setLoading] = useState(false)
    const [groupAirportList, setGroupAirportList] = useState([])
    const [groupapplicationList, setGroupApplicationList] = useState([])
    const [userList, setUserList] = useState([])
    const [eventList, setEventList] = useState([])
    const isFullAccess = IsAccessForAccreditationCombination()
    const [processedGroupAirportList, setProcessedGroupAirportList] = useState(false)
    const [processedApplicationList, setProcessedApplicationList] = useState(false)
    const [processedUserList, setProcessedUserList] = useState(false)
    const [processedEventList, setProcessedEventList] = useState(false)
    
    /* Methods */
    const getGroupAirportList = () => {
        console.log('executing getGroupAirportList()...')
        fetch(`${config.apiUrl}${apiconfig.getAirportGroupAirportList}${airportGroupID}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
        .then((res) => res.json())
        .then((data) => {
            console.log('fetched getGroupAirportList()')
            setGroupAirportList(data)
            setProcessedGroupAirportList(true)
        })
        .catch((err) => {
            console.log("Error: ", err)
            navigate("/error-page")
        })
    }
    const getApplicationsList = () => {
        console.log('executing getApplicationsList()...')
        fetch(`${config.apiUrl}${apiconfig.getAirportGroupAirportApplicationList}${airportGroupID}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
        .then((res) => res.json())
        .then((data) => {
            console.log('fetched getApplicationsList()')
            setGroupApplicationList(data)
            setProcessedApplicationList(true)
        })
        .catch((err) => {
            console.log("Error: ", err)
            navigate("/error-page")
        })
    }
    const getUserList = () => {
        console.log('executing getUserList()...')
        fetch(`${config.apiUrl}${apiconfig.getAirportApplicationUserList}${0}&AirportGroupID=${airportGroupID}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
        .then((res) => res.json())
        .then((data) => {
            console.log('fetched getUserList()')
            setUserList(data)
            setProcessedUserList(true)
        })
        .catch((err) => {
            console.log("Error: ", err)
            navigate("/error-page")
        })
    }
    const getEventList = () => {
        console.log('executing getEventList()...')
        fetch(`${config.apiUrl}${apiconfig.getAirportEventList}${airportGroupID}&ReferenceType=${'Airport Group'}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`
            }
        })
        .then((res) => res.json())
        .then((data) => {
            console.log('fetched getEventList()')
            setEventList(data)
            setProcessedEventList(true)
        })
        .catch((err) => {
            console.log("Error: ", err)
            navigate("/error-page")
        })
    }

    /* Effects */
    useEffect(() => {
        getGroupAirportList()
        getApplicationsList()
        getUserList()
        getEventList()
    }, [refetch, refetchs])
    useEffect(() => {
        if(processedGroupAirportList && processedApplicationList && processedUserList && processedEventList){
            if(isLoadingChildData)
                setIsLoadingChildData(false)
            setProcessedGroupAirportList(false)
            setProcessedApplicationList(false)
            setProcessedUserList(false)
            setProcessedEventList(false)
        }
    }, [processedGroupAirportList, processedApplicationList, processedUserList, processedEventList])


    return (
        <>
            {/* {loading && <Loader />} */}
            <TabContainer>
                <TabList activeTab={activeTab} setActiveTab={setActiveTab} isFullAccess={isFullAccess} />  
                <TabContent>
                    {isFullAccess === 'AccFullAccess' || isFullAccess === 'AccReadOnlyAccess' ?
                    <TabPanel activeTab={activeTab} tabName='Airports'>
                            <tr>
                                <th style={{width:'10%'}}>IATA Code</th>
                                <th style={{width:'10%'}}>Region</th>
                                <th style={{width:'10%'}}>Airport Group</th>
                                <th style={{width:'10%'}}>Airport Name</th> 
                                <th style={{width:'10%'}}>Accreditation Level</th>
                                <th style={{width:'10%'}}>Next Renewal Date</th>
                                <th style={{width:'10%'}}>Eligible for 3 Year?</th>
                                <th style={{width:'10%'}}>Left Programme?</th>
                            </tr>
                            {!groupAirportList.length  ?
                            (
                            <tr className="alt-row">
                            <td colSpan="08">&nbsp; Airport does not exists.</td>
                            </tr>
                            ):
                            (groupAirportList.map(airports => <AirportGroupAirportList key={groupAirportList.indexOf(airports)} airportGroupID={airportGroupID} airports={airports} refetch={refetch} setRefetch={setRefetch}/>
                            ))}
                    </TabPanel>
                    :
                    <></>
                    }
                     {isFullAccess === 'AccFullAccess' ?
                    <TabPanel activeTab={activeTab} tabName='Applications'>
                            <tr>
                                <th style={{width:'10%'}}>Application</th>
                                <th style={{width:'10%'}}>Reporting Period</th>
                                <th style={{width:'05%'}}>Region</th>
                                <th style={{width:'10%'}}>Airport Name</th>
                                <th style={{width:'10%'}}>Airport Group</th>
                                <th style={{width:'05%'}}>Application Type</th>
                                <th style={{width:'10%'}}>Accreditation Level</th>
                                <th style={{width:'10%'}}>Status</th>
                                <th style={{width:'10%'}}>Accredited Date</th>
                                <th style={{width:'05%'}}>Verifier Assigned</th>
                                <th style={{width:'05%'}}>Admin Assigned</th>
                                <th style={{width:'10%'}}>Last Updated</th>
                            </tr>
                            {!groupapplicationList.length  ?
                            (
                            <tr className="alt-row">
                            <td colSpan="12">&nbsp; Application does not exists.</td>
                            </tr>
                            ):
                            (groupapplicationList.map(applications => <AirportGroupApplicationsList key={groupapplicationList.indexOf(applications)} airportGroupID={airportGroupID} application={applications} refetch={refetch} setRefetch={setRefetch}/>
                            ))}
                    </TabPanel>
                    :
                    <></>
                    }
                     {isFullAccess === 'AccFullAccess' || isFullAccess === 'AccReadOnlyAccess' ?
                    <TabPanel activeTab={activeTab} tabName='Users'>
                        <tr>
                            <th style={{width:'10%'}}>Contact Name</th>
                            <th style={{width:'10%'}}>Address</th>
                            <th style={{width:'10%'}}>Email</th>
                            <th style={{width:'10%'}}>Is Primary?</th>
                            <th style={{width:'10%'}}>Status</th>
                        </tr>
                        {!userList.length  ?
                        (
                        <tr className="alt-row">
                        <td colSpan="5">&nbsp; User does not exists.</td>
                        </tr>
                        ):
                        (userList.map(airportUser => <AirportGroupUsersList key={userList.indexOf(airportUser)} airportGroupID={airportGroupID} airportUser={airportUser} refetch={refetch} setRefetch={setRefetch}/>
                        ))}
                    </TabPanel>
                    :
                    <></>
                    }
                    {isFullAccess === 'AccFullAccess' ?
                    <TabPanel activeTab={activeTab} tabName='Events'>
                        <tr>
                            <th style={{width:'40%'}}>Event Description</th>
                            <th style={{width:'20%'}}>Event Type</th>
                            <th style={{width:'20%'}}>Created By</th>
                            <th style={{width:'20%'}}>Date Created</th>
                        </tr>
                            {!eventList.length  ?
                                (
                                <tr className="alt-row">
                                <td colSpan="4">&nbsp; Event does not exists.</td>
                                </tr>
                                ):
                                (eventList.map((event, index) => (
                                    <tr key={index}>
                                        <td>{htmlConverterReact(event.description)}</td>
                                        <td>{event.eventType}</td>
                                        <td>{event.createdUser}</td>
                                        <td>{event.createdDate}</td>
                                    </tr>
                                )))
                            }
                    </TabPanel>
                    :
                    <></>
                    }
                </TabContent>
            </TabContainer>
        </>
    )
}

export default AirportGroupDetailsTab