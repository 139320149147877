import React, { useState,useEffect } from 'react';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import Layout from '../../Layout';

const Home = () => {

    const signOut = useSignOut();
    const navigate = useNavigate();

    const userID = localStorage.getItem('userID');
    const userDisplayName = localStorage.getItem('userDisplayName');
    const userRoles = JSON.parse(localStorage.getItem("roles"));
    const[showMenuItemsForAdmin,setShowMenuItemsForAdmin] = useState(false);
    const[showAccreditationMenu,setShowAccreditationMenu] = useState(false);
    const[showVerificationMenu,setShowVerificationMenu] = useState(false);

    // let showHamburgerMenuItemsForAdmin = false
    // let showHamburgerMenuItemsForVC = false
    useEffect(() => {
    if(userRoles.length !== 0){
        userRoles.map((role) => {
                    if (role.name === 'Administrator') {
                        setShowMenuItemsForAdmin(true);
                        setShowAccreditationMenu(true);
                        setShowVerificationMenu(true);
                    }else if (role.name  === 'Account Manager' || role.name  === 'Senior Account Manager') {
                        setShowAccreditationMenu(true);
                    } 
                    else if (role.name  === 'Verifier Coordinator') {
                        setShowVerificationMenu(true);                    }
                    else if (role.name  === 'ACI' || role.name  === 'ACI-E') {
                        setShowVerificationMenu(true);                     
                        setShowAccreditationMenu(true);

                    }
        })
    }
}, [])
    //console.log(userRoles);
    if(userRoles.length==1)
    {
        userRoles.forEach((role, index) => {
            if(role.name=="Airport User" && role.isfirstlogin==0)
            {
                //navigate('/PasswordReset');
                window.location.href='/ChangePassword';
            }
        });
    }
    const onLogoutButtonClick = () => {
        signOut();
        navigate('/login');
    };

    return (
        <Layout>
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-blue mb-4">
                        <div className="container-header">
                        <div className="row">
                            <div className="col">
                                <img src={require('../../img/ACA-logo.png')} alt="ACALogo" />
                            </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                <h3 className="page-header-title">My Dashboard</h3>
                            </div>
                            <div className="col">
                            </div>
                           </div>
                        </div>
                    </div>
                <div className="container">
                    <div className="row mt-100">
                        <div className="col-12 d-card-box">

                            {showAccreditationMenu && (
                                <div className="d-card yalow" style={{cursor: 'pointer'}} onClick={ () => { window.location.href='/AccreditationDashboard'; }}>
                                    <img src={require('../../img/icon-1.svg').default} alt="Icon"/>
                                    <h4 style={{color: '#000000'}}>Accreditation</h4>
                                </div>
                            )}
                            {showVerificationMenu &&(
                            <div className="d-card orange" style={{cursor: 'pointer'}} onClick={ () => { window.location.href='/verifiers'; }}>
                                <img src={require('../../img/icon-1.svg').default} alt="Icon"/>
                                <h4 style={{color: '#000000'}}>Verification</h4>
                            </div>
                            )}
                            {showMenuItemsForAdmin && (
                            <>
                            <div className="d-card lightgreen" style={{cursor: 'pointer'}}>
                                <img src={require('../../img/icon-1.svg').default} alt="Icon"/>
                                <h4 style={{color: '#000000'}}>Participation</h4>
                            </div>
                            <div className="d-card green" style={{cursor: 'pointer'}}>
                                <img src={require('../../img/icon-1.svg').default} alt="Icon"/>
                                <h4 style={{color: '#000000'}}>Annual Carbon Reporting</h4>
                            </div>
                            </>
                            )}
                            <div className="d-card yalow" style={{cursor: 'pointer'}} onClick={ () => { window.location.href='/programmeDocuments'; }}>
                                <img src={require('../../img/icon-1.svg').default} alt="Icon"/>
                                <h4 style={{color: '#000000'}}>Programme Documents</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            {/* Welcome {userDisplayName}!
            <button
                className="btn btn-lg btn-block btn-primary"
                onClick={onLogoutButtonClick}
                style={{ width: 200, marginTop: 50 }}>Logout</button> */}
        </Layout>
    );
};

export default Home;