import React, { useState } from 'react'

const FormGroup = ({ label, isRequired, disabled, inputType, value, isValid, allowSpecialCharacters, minLimit, maxLimit, defaultValue, options, state, setState, setHasRegionChanged, hasInvalidFileExtension, fileSizeLimitExceeded,collectivefileSizeLimitExceeded,isDuplicate, submitButtonClicked,isEvidenceCheck,setIsEvidenceCheck,isToolTipRequired,customErrorMessage }) => {

    const regexToCheckSpecialCharacters = /^[a-zA-Z0-9\s]+$/
    const regexForEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
    const regexForPhone = /^\+[0-9]+([\s-]?[0-9]+)*$/
    const allowedExtensions = ['.bmp', '.csv', '.doc', '.docx', '.htm', '.html', '.jpg', '.jpeg', '.msg', '.pdf', '.png', '.ppt', '.pptx', '.rtf', '.sig', '.tif', '.tiff', '.txt', '.vsd', '.xls', '.xlsx', '.zip']
    const [totalSize,setTotalSize] = useState(0);

    const updateState = (newValue) => {
        const updatedState = [...state]
        updatedState.map((row, row_index) => {
            row.map((item, item_index) => {
                if(item.label === label){
                    if(label === 'Verifier Evidence'){
                        setIsEvidenceCheck(false);
                        if (!allowedExtensions.includes('.' + newValue.name.slice(((newValue.name.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase()))
                            updatedState[row_index][item_index].hasInvalidFileExtension = true
                        else
                            updatedState[row_index][item_index].hasInvalidFileExtension = false
                        if(newValue.size > 20 * 1024 * 1024) // 20 MB file size limit
                            updatedState[row_index][item_index].fileSizeLimitExceeded = true
                        else
                            updatedState[row_index][item_index].fileSizeLimitExceeded = false
                        if(totalSize + newValue.size > 100 * 1024 * 1024) //100 MB file size limit
                            updatedState[row_index][item_index].collectivefileSizeLimitExceeded = true
                        else
                            updatedState[row_index][item_index].collectivefileSizeLimitExceeded = false

                        const fileName = newValue.name;
                        const isDuplicateFile = updatedState[row_index][item_index].files.some(
                              (file) => file.name === fileName
                            );
                        if(isDuplicateFile)
                            updatedState[row_index][item_index].isDuplicate = true
                        else
                            updatedState[row_index][item_index].isDuplicate = false

                        if(!updatedState[row_index][item_index].hasInvalidFileExtension && !updatedState[row_index][item_index].fileSizeLimitExceeded && !updatedState[row_index][item_index].collectivefileSizeLimitExceeded && !updatedState[row_index][item_index].isDuplicate){
                            updatedState[row_index][item_index].isDuplicate = false
                            updatedState[row_index][item_index].fileName = newValue.name
                            updatedState.forEach((row) => {
                                row.forEach((item) => {
                                  if (item.label === 'Verifier Evidence') {
                                    item.value = '';
                                    if(newValue){
                                        fileToBase64(newValue, function(base64String) {
                                             updatedState[row_index][item_index].value = base64String
                                             const newFile = { name: newValue.name, content: base64String,size: newValue.size };
                                             item.files = item.files ? [...item.files, newFile] : [newFile];
                                             setTotalSize(totalSize + newValue.size);
                                            })
                                            updatedState[row_index][item_index].isValid = true
                                    }
                                   
                                    // Add the uploaded file to the 'files' property in the state
                                    // const newFile = { name: newValue.name, content: item.value };
                                    // item.files = item.files ? [...item.files, newFile] : [newFile];
                                  }
                                });
                              });

                            // if(newValue){
                            //     fileToBase64(newValue, function(base64String) { updatedState[row_index][item_index].value = base64String })
                            //     updatedState[row_index][item_index].isValid = true
                            // }
                        }else{
                            updatedState[row_index][item_index].isValid = false
                        }

                    }
                    else if(label === 'Region'){
                        if(newValue === ""){
                            updatedState[3][1].disabled = true
                            updatedState[row_index][item_index].isValid = false
                        }      
                        else{
                            updatedState[3][1].disabled = false
                            updatedState[row_index][item_index].isValid = true
                        }
                        updatedState[3][1].value = ''
                        updatedState[3][1].options = []
                        setHasRegionChanged(true)
                        updatedState[row_index][item_index].value = newValue
                    }
                    else if(label === 'Country'){
                        if(newValue.length > 0)
                            updatedState[row_index][item_index].isValid = true    
                        updatedState[row_index][item_index].value = newValue
                    }
                    else if(label === 'Verifier Name' || label === 'Address Line 1' || label === 'Town/City' || label === 'Post Code/ZIP'){
                        if(newValue.trim().length >= minLimit && newValue.trim().length <= maxLimit)
                            updatedState[row_index][item_index].isValid = true
                        else
                            updatedState[row_index][item_index].isValid = false    
                        updatedState[row_index][item_index].value = newValue
                    }
                    else if(label === 'Email Address'){
                        updatedState[row_index][item_index].customErrorMessage = ''
                        if(newValue.trim().length !== 0 && regexForEmail.test(newValue))
                            updatedState[row_index][item_index].isValid = true
                        else
                            updatedState[row_index][item_index].isValid = false    
                        updatedState[row_index][item_index].value = newValue
                    }
                    else if(label === 'Phone Number'){
                        if(newValue.trim().length !== 0 && regexForPhone.test(newValue))
                            updatedState[row_index][item_index].isValid = true
                        else
                            updatedState[row_index][item_index].isValid = false    
                        updatedState[row_index][item_index].value = newValue
                    }
                    else{
                        updatedState[row_index][item_index].value = newValue
                    }
                }
            })
        })

        setState(updatedState)    
    }


    const fileToBase64 = (file, callback) => {
        const reader = new FileReader()
        reader.onload = function(event) {
            const base64String = event.target.result.split(',')[1] // Extract the base64 data
            callback(base64String)
        }
        reader.readAsDataURL(file)
    }
    const handleFileDelete = (index) => {
        let deletedFileSize = 0;
        const updatedState = [...state];
        updatedState.forEach((row) => {
          row.forEach((item) => {
            if (item.label === 'Verifier Evidence') {
            deletedFileSize = item.files[index].size;
              item.files.splice(index, 1);
            }
          });
        });
        setTotalSize(totalSize - deletedFileSize);
        setState(updatedState);
      };

    return (
        <div className="form-group col-md-6 mb-1">
            <label>
                {label}
                {
                    isRequired ? <span className="text-danger font-weight-bold">&nbsp;*</span> : <></>
                }
                {
                    isToolTipRequired && (
                    label === 'Address Line 1' ?   
                    <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title="Please enter the first line of address e.g. 123 Main Street."></span>
                    :
                    label === 'Email Address' ? 
                    <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title="Please enter a valid email address e.g. name@domain.com."></span>
                    :
                    label === 'Phone Number' ? 
                    <span className="icon mdi mdi-1x mdi-help-outline ml-4" data-toggle="tooltip" title="Please enter country code e.g. +44(0) 123 456 7890."></span>
                    :
                    <></>
                    )
                }  
            </label>
            {
                inputType === 'text' || inputType === 'phone' 
                    ?
                    <input type="text" disabled={disabled} placeholder={`Please enter ${label}...`} className="form-control form-control-xs" onChange={(e) => { updateState(e.target.value) }} /> 
                    :
                    inputType === 'dropdown' 
                        ? 
                        <select disabled={disabled} className="form-control form-control-sm" defaultValue={defaultValue} onChange={(e) => { updateState(e.target.value) }} style={{cursor: 'pointer'}}>
                            {
                                options.map((option, index) => {
                                    return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.text}</option>
                                })
                            }
                        </select>
                        :
                        inputType === 'email' 
                            ?
                            <input type="email" disabled={disabled} placeholder={`Please enter ${label}...`} className="form-control form-control-xs" onChange={(e) => { updateState(e.target.value) }}/>
                            :
                            inputType === 'file' 
                                ?
                                <>
                                <input type="file" className="form-control form-control-xs" onChange={(e) => { updateState(e.target.files[0]); e.target.value = '' }} />
                                {state[5][0].files.length > 0 && (
                                    <div>
                                      <ul className="list-group mt-2" style={{ listStyleType: 'none' }}>
                                        {state[5][0].files.map((file, index) => (
                                          <li key={index}>
                                            {file.name}{' '}
                                            <span onClick={() => handleFileDelete(index)}  style={{ cursor: 'pointer' }}>
                                              <i className="icon mdi mdi-close" style={{ color: 'red', fontSize: '20px',cursor:'pointer'}} />
                                            </span>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  )}
                                  </>
                                :
                                <></>
            }

            {
                inputType === 'text' 
                    ?
                    value.trim().length < minLimit && submitButtonClicked
                        ?
                        <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Please enter a value.`}</label> 
                        :    
                        value.trim().length > maxLimit
                            ?
                            <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Maximum of ${maxLimit} characters allowed.`}</label>
                            :
                            // !allowSpecialCharacters && !regexToCheckSpecialCharacters.test(value) && submitButtonClicked && value.trim().length !== 0
                            //     ?
                            //     <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Special characters are not allowed.`}</label>
                            //     :
                                <></> 
                    :
                    inputType === 'dropdown' 
                        ?
                        value === '' && submitButtonClicked
                            ?
                            <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Please select a value.`}</label> 
                            :
                            <></>
                        :
                        inputType === 'email' 
                            ?
                            value.trim().length === 0 && submitButtonClicked
                                ? 
                                <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Please enter a value.`}</label>
                                :
                                !regexForEmail.test(value) && submitButtonClicked
                                    ?
                                    <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Please enter a valid email address e.g. name@domain.com.`}</label>
                                    :
                                    customErrorMessage === 'This email address is already linked with an existing user account, please contact the ACA Admin Team (aca@wsp.com).' 
                                    ?
                                    <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`This email address is already linked with an existing user account, please contact the ACA Admin Team (aca@wsp.com).`}</label>
                                    :
                                    <></>
                            :
                            inputType === 'phone'
                                ?
                                value.trim().length === 0 && submitButtonClicked
                                    ?
                                    <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Please enter a value.`}</label> 
                                    :
                                    value.trim().length > 15 && submitButtonClicked
                                    ?
                                    <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Maximum of 15 characters allowed.`}</label> 
                                    :
                                    !regexForPhone.test(value) && submitButtonClicked
                                        ?
                                        <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Please enter country code e.g. +44(0) 123 456 7890.`}</label>
                                        :
                                        <></>
                                :
                                inputType === 'file' 
                                    ?
                                    hasInvalidFileExtension && !isEvidenceCheck
                                        ? 
                                        <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`This file format is not supported.`}</label>
                                        :
                                        fileSizeLimitExceeded && !isEvidenceCheck
                                            ?
                                            <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`File could not be uploaded as it is exceeding the maximum file size of 20 MB.`}</label>    
                                            :
                                            collectivefileSizeLimitExceeded && !isEvidenceCheck
                                            ?
                                            <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`File could not be uploaded as it is exceeding the collective maximum file size of 100 MB.`}</label>    
                                            :
                                            isDuplicate && !isEvidenceCheck
                                            ?
                                            <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`File could not be uploaded as it is already uploaded.`}</label>    
                                            :
                                            state[5][0].files.length === 0 && submitButtonClicked
                                                ?
                                                <label style={{ color: 'red', marginTop: 2 }}><i className="icon mdi mdi-alert-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`Please choose a Verifier Evidence.`}</label>
                                                :
                                                // value.trim().length !== 0 && isValid 
                                                //     ?
                                                //     <label style={{ color: 'green', marginTop: 2 }}><i className="icon mdi mdi-check-circle" style={{marginTop: 2, fontSize: 18}}></i>&nbsp;{`File Added Successfully.`}</label> 
                                                //     :
                                                    <></>
                                    :
                                    <></>                      
            }
        </div>
    );

};

export default FormGroup