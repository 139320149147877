import React, { useState,useEffect} from 'react';
import { Label } from 'reactstrap';

const TermAndCondition = ({toggler}) => {


    return (
        <div>
          <Label className="col-form-label">
          <p><strong>Airports wishing to participate in the programme must accept the Terms and Conditions available
                to download here.
                Please note that submitting an application will mean accepting these Terms and Conditions which
                represent a binding legal contract.</strong>
            </p>

            <table>
              <tbody>
                <tr>
                  <td>
                    <h4>Definitions
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td style ={{paddingLeft: '30px'}}>
                    <p><span className="fw-bold"><span className="fst-italic">Airport Carbon Accreditation</span> :</span>
                      A programme owned by ACI EUROPE which enables participant airports to demonstrate certain
                      activities associated with the measurement,
                      management and reduction of greenhouse gas emissions due to various airport activities, all as
                      defined in the
                      <span className="fw-bold">Guidance Document</span>. (Known hereafter as “the programme”).
                    </p>
                    <p><span className="fw-bold"><span className="fst-italic">Participant</span> :</span>
                      The individual airport which, through the acceptance of these terms and conditions and the payment
                      of participation fees, is recognised
                      by the programme, through the issue of a certificate and the entry onto a participation register,
                      as having satisfactorily demonstrated
                      to the <span className="fw-bold">programme Administrator</span> the requirements of the programme
                      <span className="fw-bold">level</span>
                      at which it is registered.
                    </p>
                    <p><span className="fw-bold"><span className="fst-italic">Programme Documentation</span> :</span>
                      A set of guidance documentation, worksheets, help-sheets and other associated documentation,
                      published by the programme administrator and
                      which may be amended in consultation with the Airport Carbon Accreditation Task Force and Advisory
                      Board.
                    </p>
                    <p><span className="fw-bold"><span className="fst-italic">Level</span> :</span>
                      A level is one of the “levels of participation” which is attained by an airport demonstrating it
                      is undertaking a number of activities, as
                      defined in the programme documentation.
                    </p>
                    <p><span className="fw-bold"><span className="fst-italic">Programme Administrator</span> :</span>
                      The independent body or company appointed by ACI EUROPE to administer the programme, to undertake
                      the activities and to the roles defined in
                      the programme documentation. (Known hereafter as “the Administrator”).
                    </p>
                    <p><span className="fw-bold"><span className="fst-italic">Advisory Board</span> :</span>
                      The committee of stakeholders, appointed by ACI EUROPE, to provide oversight of the programme and
                      the Administrator.
                    </p>
                    <p><span className="fw-bold"><span className="fst-italic">Programme Year</span> :</span>
                      The programme will run from 15th May to 14th May the following year.
                    </p>
                    <p><span className="fw-bold"><span className="fst-italic">Accreditation date</span> :</span>
                      The date on which the Administrator confirms to the applicant that the programme requirements have
                      been met.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h4>The Administrator
                    </h4>
                    <p>The Administrator is appointed by ACI EUROPE. The Administrator is WSP UK Ltd, a global
                      consultancy company headquartered in London, UK. ACI EUROPE
                      reserves the right to appoint a different Administrator at any time, subject to the terms of the
                      ACI EUROPE/Programme Administrator contract.
                    </p>
                    <h4>Participation
                    </h4>
                    <p>Individual airports shall become participants of the programme once the Administrator has
                      confirmed:
                    </p>
                    <ul>
                      <li>That their full documentation has passed the requirements of the programme at the level
                        applied for (as defined in the documentation); AND
                      </li>
                      <li>That the airport’s participation/application fees have been received and, in the case of
                        cheques, cleared at the Administrator’s bank.
                      </li>
                    </ul>
                    Accreditation is valid for a period of 12 months from the Accreditation date.
                    <p></p>
                    <h4>Obligations of the Participant
                    </h4>
                    <div>Registering an interest in the programme through www.aca-application.org and creating an
                      account therein does not mean that the airport registering such
                      interest ultimately intends to apply for accreditation. Only when an airport has completed and
                      submitted an application the contractual
                      obligations listed below come into force. <br/>By applying to join the programme the participant
                      (or applicant in the case of first-time applications) agrees to the obligations below.
                      <ol>
                        <li>The participant shall pay to the Administrator all programme participation fees as set by
                          ACI EUROPE and published by the Administrator.
                        </li>
                        <li>The participant shall pay to the Administrator all programme renewal fees as set by ACI
                          EUROPE and published by the Administrator.
                        </li>
                        <li>The participant shall submit all documentation and completed application forms as required
                          by the Administrator to enable the Administrator to confirm compliance with the level of the
                          programme for which the participant is applying.
                        </li>
                        <li>The participant shall pay all fees by the due date. An application will not be reviewed by
                          the Administrator until participation fees have been paid.
                        </li>
                      </ol>
                      The participant shall accept that late payment of fees shall put at risk the renewal of an
                      existing participation, the processing of a new application
                      and the issuance of any renewal certification
                    </div>
                    <h4>Obligations of the Administrator
                    </h4>
                    <div>
                      <ol>
                        <li>The Administrator shall process all participation applications and renewals within 30 days
                          of receipt of the completed application and payment of the participation fee.
                        </li>
                        <li>The Administrator shall maintain a register of participants and their fee payments.
                        </li>
                        <li>The Administrator shall notify ACI EUROPE and, for a non-European participant, the ACI
                          region to which it belongs, upon receipt and processing of documents from the participant
                          which fulfil the requirements of the corresponding level as detailed in the documentation, so
                          that the relevant ACI region may issue programme certification.
                        </li>
                        <li>The Administrator shall keep all data relating to each participant airport secure and
                          confidential.
                        </li>
                        <li>The Administrator shall not release any data to a third party (unless required to by law)
                          which will allow a third party to identify to which airport the data relates.
                        </li>
                        <li>The Administrator shall compile aggregate participation data and provide this to ACI EUROPE
                          as required by the ACI EUROPE/Programme Administrator contract.
                        </li>
                        <li>The Administrator shall issue programme renewal notices in line with the details outlined
                          below.
                        </li>
                      </ol>
                    </div>
                    <h4>Levels and Progress
                    </h4>
                    <p>Airports may apply to join the programme at any level, so long as they can demonstrate their
                      compliance with the requirements for that level as defined
                      in the documentation.<br/>Airports may remain in the programme at the same level indefinitely or
                      decide to continue their participation at a higher or lower level than previously
                      if desired. Airports are encouraged however to progress through the programme levels.<br/>If an
                      existing participant cannot provide sufficient evidence of ongoing compliance with the
                      requirements for the participation level they are currently
                      registered at, the Administrator reserves the right, at the time of participation renewal, to
                      advise that renewal can only be given at a lower level.
                      Upon receiving this information the participant has a period of one calendar month to demonstrate
                      compliance with the level it wishes to renew at.
                      If evidence of compliance is not made available, the Administrator will renew the participation at
                      the lower level, provided that the requirements for this
                      level are met.
                    </p>
                    <h4>Renewals
                    </h4>
                    <p>The Administrator will notify airports of the impending renewal deadline to maintain
                      accreditation 4 months before the end of the accreditation period
                      and will, as needed, also send reminders 2 month and 1 month before this deadline.<br/>Airports
                      must submit their renewal application at least one month before the expiry of their existing
                      accreditation, to allow enough time for the review
                      process to be completed before the expiry. Failing to do so may lead to a delay in accreditation
                      or to the airport losing its accreditation.<br/>If an existing participant is not able to comply
                      with the timeline described above, it must immediately notify the Administrator, provide the
                      reason for
                      the delay and request an extension of the renewal period. Extensions will be considered on a case
                      by case by the Administrator and ACI EUROPE and, for
                      non-European participants, the ACI region to which they belong, and granted based on the specific
                      circumstances. Airports must not assume that an extension
                      will be granted, as this will be at the discretion of the Administrator and ACI EUROPE and, for
                      non-European participants, the ACI region to which the
                      participant belongs.<br/>Airports which have not renewed their accreditation 6 months after the
                      expiry of their existing certification will automatically lose their accreditation.<br/>Where a
                      participant submits a late renewal application, its accreditation will be back-dated to the date
                      at which that airport’s renewal was due.
                    </p>
                    <h4>Cancellation of Participation
                    </h4>
                    <p>If a participant wishes to stop participation it may do so at any time. No refunds will be due.
                    </p>
                    <h4>Non-Renewal of Participation
                    </h4>
                    <p>A participant is under no obligation to renew participation of the programme. However, in
                      allowing a participation to lapse, through the non-renewal of
                      participation, an airport accepts that, should it wish to re-join in the future, it will have to
                      satisfy the full requirements and pay the full fees associated
                      with initial application to the programme.
                    </p>
                    <h4>Arbitration
                    </h4>
                    <p>In the case of dispute between the Administrator and a participant the two parties should
                      endeavour to agree a path forward together. In the case that
                      the two parties cannot reach an agreement, the matter shall be referred to the Advisory Board
                      which shall make the final ruling.
                    </p>
                    <h4>Internal Use of Data
                    </h4>
                    <p>In order for ACI EUROPE and the Advisory Board to be able to publish aggregate programme
                      performance data, individual airport carbon footprint data may
                      be made available to ACI EUROPE and members of the Advisory Board. All such airport-specific data
                      shall NOT be provided in electronic format and shall ONLY
                      be provided on paper and in a single meeting room, under the control of the Administrator. Upon
                      completion of inspection of that data the Administrator
                      shall collect back all copies of the data from the Advisory Board and the representatives of ACI
                      EUROPE and shall destroy then recycle all copies.
                    </p><a className="text-danger"
                      href="https://stag-acaonline.wspdigital.co.uk/static/common/doc/Terms_and_Conditions_of_Participation.pdf" rel='noreferrer'
                      target="_blank"><u>Download Terms and Conditions of Participation</u></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </Label>
            <div className="modal-footer">
              {/* <img className="mr-auto" src="dist/img/ACA-logo.png" width="150"></img>*/}
              {/* <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button> */}
            </div>
        </div>
    );
};

export default TermAndCondition;