import { useState } from "react";
import { Label } from 'reactstrap';
const AccreditationsList = ({ verifier, accreditations, refetch, setRefetch }) => {


return (
    <>
        <tr>
        <td>{accreditations.accreditationLevel}</td>
        <td>{accreditations.approvedDate}</td>
        {accreditations.renewalDate !== 'NA' ?
        <td><span className={accreditations.renewalLapseColor}>{accreditations.renewalDate}</span></td> 
        :
        <td>{accreditations.renewalDate}</td> 
        }
        <td>{accreditations.dueRenewalDate}</td>       
        <td>{accreditations.isUpgrade}</td>
        <td>{accreditations.isSuspended}</td>
        <td>{accreditations.suspensionDate}</td>
        </tr>

</>
);

};

export default AccreditationsList;
