import React, { useEffect } from 'react';
import LoginNavBar from './LoginNavBar';
import LoginForm from './LoginForm';
import Footer from '../../Layout/Footer';
import { RequireAuth, useIsAuthenticated } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const isAuthenticated = useIsAuthenticated()
    const navigate = useNavigate();

    return (
        <div>
            <div className="be-wrapper be-offcanvas-menu be-fixed-sidebar be-color-header vh-100">
                <LoginNavBar />
                <div className="be-content">
                    <div className="main-content container-fluid p-0">
                        <LoginForm />
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );

};

export default Login;