import React from 'react';
import Tab from './Tab';

const TabList = ({ data, activeTabName, setActiveTabName, disableOnClickEvent }) => {
    return (
        <ul className="sbs sbs--border">
            {
                data.map((item, index) => { return (<Tab key={index} iconClassName={item.iconClassName} tabName={item.tabName} activeTabName={activeTabName} setActiveTabName={setActiveTabName} disableOnClickEvent={disableOnClickEvent} />) }  )
            }
        </ul>
    )
}

export default TabList