import React, { useState, useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import { Label } from 'reactstrap';

const ActivateVerifier = ({ toggler, verifier ,refetchs, setRefetchs}) => {

    const navigate = useNavigate();

    const [notes, setNotes] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const userID = localStorage.getItem("userID");
    const [checkSuspensions, setCheckSuspensions] = useState(false);
    const [accreditationsList, setAccreditationsList] = useState([]);

    const CheckSuspensionExists = async () => {
       try{
        const response =  await fetch(`${config.apiUrl}${apiconfig.CheckSuspensionExists}${verifier.verifierDetailID}`, {
              method: 'GET',
              headers: {

                  Authorization: `Bearer ${localStorage.getItem("token")}`

              }
          });
          if (response.ok) {
            var result = await response.json();
            if(result.saveErrorMessage === 'Suspension Exists.')
            {
              setCheckSuspensions(true);
            }
          } else {
              const errorText = await response.text();
              const apiname = `${apiconfig.CheckSuspensionExists}`;
              navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
          }
          }catch(error){
              console.log(error.message);
              navigate(`/error-page`); 
          }
      }

      const getAccreditationsList = () => {
        fetch(`${config.apiUrl}${apiconfig.getVerifierAccreditationsList}${verifier.verifierDetailID}`, {
          method: "GET",
          headers: {
  
              Authorization: `Bearer ${localStorage.getItem("token")}`
  
          }
        })
          .then((res) => res.json())
          .then((data) => {
            setAccreditationsList(data);
          })
          .catch((err) => {
            console.log("Error: ", err);
            navigate("/error-page");
          });
      };

      useEffect(()=>{
        CheckSuspensionExists();
        getAccreditationsList();
      });

      {/*Validation*/}
    const validateForm = (comments) => {
      const errors = {};
      if(!comments){
          errors.comments = 'Comments is required!'
      }
      return errors;
  }
    // Activate Verifier
    const activateVerifier = async () => {
      const validationErrors = validateForm(notes.trim());
      setFormErrors(validationErrors);
      
      let requestBody =null;
      if(checkSuspensions)
      {
          requestBody= {
              verifierID : verifier.verifierDetailID,
              noteDescription : notes.trim(),
              userID : userID,
              actionType : "Activate"


          };
          if(Object.keys(validationErrors).length === 0){ 
            try {
              const response = await fetch(
                `${config.apiUrl}${apiconfig.updateVerifierStatus}`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                  },
                  body: JSON.stringify(requestBody),
                }
              ).then((response) => {
                return response.json();
              });
              if(response.saveErrorMessage === 'Verifier has been Activated successfully.')
              {
                  toast.success('Verifier has been Activated successfully.', {
                      position: "top-right",
                      autoClose: 10000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      transition: Slide
                  }); 
                  setRefetchs(!refetchs);
                  toggler();
              }
              // else{
              //     console.log(response.saveErrorMessage);
              //     setErrorMessage(response.saveErrorMessage);
              // }
            } catch (error) {
              console.log("Error: ", error);
              navigate("/error-page");
            }
          }
      }
    else
    {
          requestBody= {
            verifierID : verifier.verifierDetailID,
            noteDescription : "",
            userID : userID,
            actionType : "Activate"


        };
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.updateVerifierStatus}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Verifier has been Activated successfully.')
            {
                toast.success('Verifier has been Activated successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
                setRefetchs(!refetchs);
                toggler();
            }
            // else{
            //     console.log(response.saveErrorMessage);
            //     setErrorMessage(response.saveErrorMessage);
            // }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
   
    };
    return (
        <div>
    <form>
    {checkSuspensions ? (
      
                    <p style={{ color: "red" }}>
                      Please note, the Verifier is currently Suspended from the programme:
                      <br></br> <br></br>
                      <b>Accreditation Level | Suspension Date</b>
                      {
                      accreditationsList.map((accreditations) =>
                                <li>{accreditations.accreditationLevel} | {accreditations.suspensionDate}</li>
                              )
                      }
                      <br></br> <br></br>
                      Justification will need to be provided below in order to activate them. The suspension(s) will then be removed from the Verifier.<br></br> Are you sure you want to Activate the Verifier?
                      <br></br><br></br>
                      <div className="form-row">                 
                      <label htmlFor="input_comments">Comments</label>
                      <textarea type="text" className="form-control form-control-xs" id="input_comments" onChange={(e) => { setNotes(e.target.value) }} style={{ height: 120}}/>
                      <i style={{color:'red'}}>{formErrors.comments}</i>
                      </div>
{/* 
                      <br></br><br></br>
                      <Label className="col-form-label">{'Are you sure you want to Activate the Verifier '}{'?'}</Label> */}
                      </p>
                      
                  ) : (
                    <p>
                      <Label className="col-form-label">{'Are you sure you want to Activate the Verifier '}{'?'}</Label>
                    </p>
                  )}
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={activateVerifier}>Submit</button>
            </div>
            </div>
    );
};

export default ActivateVerifier;