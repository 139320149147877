import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import axios from 'axios';
// import config from './api/config';

// axios.defaults.baseURL = config.apiUrl;
// APP changes
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App />
);