import React from 'react'

const OtherDetails = ({ data, state, setState, activeRightPane, setActiveRightPane, leftNavigationMenu, userType, saveOtherDetails, visibilityBasedOnType, isACAYearValid, isVerificationRequired, isOtherDetailsSectionSavedInTheBackend, allowToMoveInOtherDetailsSection, setAllowToMoveInOtherDetailsSection }) => {

    const updateState = async (attribute, value) => {
        const updatedState = {...state}
        switch(attribute){
            case 'ACAYear':
                if(value === "0")
                    updatedState.default[2].acaYear.value = value
                else{
                    /*
                        if ACA Year is valid then continue to update the state as usual
                        if ACA Year is invalid then update the state with value "0"
                    */
                    if(await isACAYearValid(value))
                        updatedState.default[2].acaYear.value = value
                    else
                        updatedState.default[2].acaYear.value = "0"
                }
                break
            case 'AirportBand':
                updatedState.default[2].airportBand.value = value
                break
            case 'TimePeriodOfCarbonFootprint':
                updatedState.default[2].timePeriodOfCarbonFootprint.value = value
                break
            case 'VerificationType':
                updatedState.default[2].verificationType.value = value
                break
            default:
                break
        }

        if((updatedState.default[2].acaYear.value.length !== 0 && updatedState.default[2].acaYear.value !== '0')  && (updatedState.default[2].airportBand.value.length !== 0 && updatedState.default[2].airportBand.value !== '0') && (updatedState.default[2].timePeriodOfCarbonFootprint.value.length !== 0 && updatedState.default[2].timePeriodOfCarbonFootprint.value !== '0') && ((state.applicationType === 'Renew' && isVerificationRequired === 'No') || (updatedState.default[2].verificationType.value.length !== 0 && updatedState.default[2].verificationType.value !== '0')))
            setAllowToMoveInOtherDetailsSection(true)
        else
            setAllowToMoveInOtherDetailsSection(false)

        setState(updatedState)
    }

    const move = async (direction) => {
        const updatedState = {...state}
        updatedState.default[2].isSubmitButtonClicked = true
        
        if(
            (updatedState.default[2].acaYear.value.length !== 0 && updatedState.default[2].acaYear.value !== '0')  && 
            (updatedState.default[2].airportBand.value.length !== 0 && updatedState.default[2].airportBand.value !== '0') && 
            (updatedState.default[2].timePeriodOfCarbonFootprint.value.length !== 0 && updatedState.default[2].timePeriodOfCarbonFootprint.value !== '0') && 
            ((state.applicationType === 'Renew' && isVerificationRequired === 'No') || (updatedState.default[2].verificationType.value.length !== 0 && updatedState.default[2].verificationType.value !== '0'))
        )
            updatedState.default[2].completed = true
        else{
            if(!isOtherDetailsSectionSavedInTheBackend)
                updatedState.default[2].completed = false
        }

        if(allowToMoveInOtherDetailsSection){
            switch(direction){
                case 'forward':
                    if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1)
                        setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1])
                    break
                case 'backward':
                    if(leftNavigationMenu.indexOf(activeRightPane) !== 0) 
                        setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1])
                    break
                default:
                    break
            }
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            // call api endpoint here to save this details into the database
            await saveOtherDetails(state.default[2].acaYear.value, state.default[2].airportBand.value, state.default[2].timePeriodOfCarbonFootprint.value, state.default[2].verificationType.value,'')
        }
        
        setState(updatedState)
    }

    /* synchronous method to call when moving freely just to navigate forward or backward */
    const navigate = (direction) => {
        switch(direction){
            case 'forward':
                if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1)
                    setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1])
                break
            case 'backward':
                if(leftNavigationMenu.indexOf(activeRightPane) !== 0)
                    setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)-1])
                break
            default:
                break
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
    

    return(
        data.default.length === 0 
            ? 
            <React.Fragment></React.Fragment>
            :
            <React.Fragment>
                <div className="user-info-list card card-border card-contrast card-table" style={activeRightPane === data.default[2].name ? {} : {display: 'none'}}>
                    <div className="card-header card-header-contrast card-header-divider">
                        Other Details
                    </div>
                    <div className="card-body">
                        <table className="no-border no-strip skills">
                            <tbody className="no-border-x no-border-y">
                                <tr>
                                    <td>ACA Year</td>
                                    <td>
                                         <select id="id_verifier" name="verifier" disabled className="form-control form-control-sm" value={data.default[2].acaYear.value.length === 0 ? data.default[2].acaYear.defaultValue : data.default[2].acaYear.value} onChange={(e) => { updateState('ACAYear', e.target.value) }} style={{cursor: 'pointer'}} >{/*disabled={state.applicationType === 'Entry' ? true : (visibilityBasedOnType(state.default[2].type, true) ? false : true)}> */}
                                            {
                                                data.default[2].acaYear.options.map((option, index) => {
                                                    return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                })
                                            }
                                        </select>
                                        {
                                            data.default[2].isSubmitButtonClicked && data.default[2].acaYear.required && (data.default[2].acaYear.value.length === 0 || data.default[2].acaYear.value === '0')
                                                ?
                                                <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;{data.default[2].acaYear.validationMessage}</span> 
                                                :
                                                <React.Fragment></React.Fragment> 
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Airport Band
                                        <p style={{ fontSize: 12 }} className="text-info">
                                        ("Official passenger figures, two years before the time of application (e.g. for accreditation in 2024, the band is based on 2022 official passenger figures")
                                        </p>
                                    </td>
                                    <td>
                                        <select id="id_apt_group_size" name="apt_group_size" className="form-control form-control-sm" required="" value={data.default[2].airportBand.value.length === 0 ? data.default[2].airportBand.defaultValue : data.default[2].airportBand.value} onChange={(e) => { updateState('AirportBand', e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.default[2].type, true) ? false : true}>
                                            {
                                                data.default[2].airportBand.options.map((option, index) => {
                                                    return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                })
                                            }
                                        </select>
                                        {
                                            data.default[2].isSubmitButtonClicked && data.default[2].airportBand.required && (data.default[2].airportBand.value.length === 0 || data.default[2].airportBand.value === '0')
                                                ?
                                                <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;{data.default[2].airportBand.validationMessage}</span> 
                                                :
                                                <React.Fragment></React.Fragment> 
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Time period for Carbon Footprint
                                        <p style={{ fontSize: 12 }} className="text-info">
                                            It is advisable to use the most recent carbon footprint for application submission. The carbon footprint submitted should not be more than 2 years old from the time of application
                                        </p>
                                    </td>
                                    <td>
                                        <select id="id_apt_band" name="apt_band" className="form-control form-control-sm" required="" value={data.default[2].timePeriodOfCarbonFootprint.value.length === 0 ? data.default[2].timePeriodOfCarbonFootprint.defaultValue : data.default[2].timePeriodOfCarbonFootprint.value} onChange={(e) => { updateState('TimePeriodOfCarbonFootprint', e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.default[2].type, true) ? false : true}>
                                            {
                                                data.default[2].timePeriodOfCarbonFootprint.options.map((option, index) => {
                                                    return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                })
                                            }
                                        </select>
                                        {
                                            data.default[2].isSubmitButtonClicked && data.default[2].timePeriodOfCarbonFootprint.required && (data.default[2].timePeriodOfCarbonFootprint.value.length === 0 || data.default[2].timePeriodOfCarbonFootprint.value === '0')
                                                ?
                                                <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;{data.default[2].timePeriodOfCarbonFootprint.validationMessage}</span> 
                                                :
                                                <React.Fragment></React.Fragment> 
                                        }
                                    </td>
                                </tr>
                                {
                                    state.applicationType === 'Renew' && isVerificationRequired === 'No'
                                        ?
                                        <React.Fragment></React.Fragment>
                                        :
                                        <tr>
                                            <td>
                                                Verification Type
                                                <p style={{ fontSize: 12 }} className="text-info">
                                                    Please confirm the nature of verification for your application this year:
                                                    (please note that permission needs to be granted by the administrator prior to conducting verification off-site)
                                                </p>
                                            </td>
                                            <td>
                                                <select id="id_vrf_nature" name="vrf_nature" className="form-control form-control-sm" value={data.default[2].verificationType.value.length === 0 ? data.default[2].verificationType.defaultValue : data.default[2].verificationType.value} onChange={(e) => { updateState('VerificationType', e.target.value) }} style={{cursor: 'pointer'}} disabled={visibilityBasedOnType(state.default[2].type, true) ? false : true}>
                                                    {
                                                        data.default[2].verificationType.options.map((option, index) => {
                                                            return <option key={index} value={option.value} style={{cursor: 'pointer'}}>{option.label}</option>
                                                        })
                                                    }
                                                </select>
                                                {
                                                    data.default[2].isSubmitButtonClicked && data.default[2].verificationType.required && (data.default[2].verificationType.value.length === 0 || data.default[2].verificationType.value === '0')
                                                        ?
                                                        <span className="text-danger"><i className="mdi mdi-alert-triangle text-danger"></i>&nbsp;{data.default[2].verificationType.validationMessage}</span> 
                                                        :
                                                        <React.Fragment></React.Fragment> 
                                                }
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="" style={activeRightPane === data.default[2].name ? {display: 'flex', justifyContent: 'flex-end'} : {display: 'none'}}>
                    {
                        visibilityBasedOnType(state.default[2].type, true) 
                            ?
                            <React.Fragment>
                                <button className="btn btn-space btn-primary btn-xl" onClick={() => { move('backward') }}>
                                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Save and go Back
                                </button>
                                {
                                    state.sections.length === 0 
                                        ? 
                                        <React.Fragment></React.Fragment>
                                        :
                                        <button id="validation-btn" className="btn btn-space btn-success btn-xl" onClick={() => { move('forward') }}>Save and go Next&nbsp;
                                            <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                        </button>
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <button className="btn btn-space btn-primary btn-xl" onClick={() => { navigate('backward') }}>
                                    <i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Back
                                </button>
                                <button id="validation-btn" className="btn btn-space btn-success btn-xl" onClick={() => { navigate('forward') }}>Next&nbsp;
                                    <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                                </button>
                            </React.Fragment>
                    }
                </div>
            </React.Fragment>
    )
}

export default OtherDetails