import React from 'react';

const Tab = ({ tabName, iconClassName, activeTab, setActiveTab }) => {
    return (
        <li className="nav-item" style={{ cursor: 'pointer'}}>
            <a className={activeTab === tabName ? "nav-link active" : "nav-link"} role="tab" aria-selected={activeTab === tabName ? "true" : "false"} 
               onClick={() => { 
                    if(activeTab !== tabName){ 
                        setActiveTab(tabName);
                    }
                }}
            >
                <i className={iconClassName}></i>
                {tabName}
            </a>
        </li>
    );
};

export default Tab;