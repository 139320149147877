import React, { useState} from 'react';
import { Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from 'react-router-dom';
import CommonCustomModal from '../../Common/CommonCustomModal';
import ApproveAirport from './ApproveAirport';
import RejectAirport from './RejectAirport';
import AirportApplicationEvents from './AirportApplicationEvents ';
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination';



const AccreditationApplication = ({ accAirport, refetch, setRefetch }) => {

    const navigate = useNavigate();
    const userID = localStorage.getItem("userID");
    const [rejectModal, setRejectModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [auditTrailModal, setAuditTrailModal] = useState(false);
    const isFullAccess = IsAccessForAccreditationCombination();
    
  
      const toggleForRejectModal = () => {
          setRejectModal(!rejectModal);
        } 
      const toggleForApproveModal = () => {
        setApproveModal(!approveModal);
      } 
      const toggleForAuditTrailModal = () => {
        setAuditTrailModal(!auditTrailModal);
      } 


        return (
            <>
                <tr>
                    {isFullAccess === 'AccFullAccess' ? 
                    <td><a className="text-info" href={`/ApplicationStage/ApplicationID/${accAirport.airportApplicationID}/AirportID/${accAirport.airportID}`}>{accAirport.airportApplicationID}</a></td>
                    :
                    <td>{accAirport.airportApplicationID}</td>
                    }
                    <td>{accAirport.airport}</td>
                    <td>{accAirport.airportGroup}</td>
                    <td>{accAirport.region}</td>
                    <td>{accAirport.airportType}</td>
                    <td>{accAirport.reportingPeriod}</td>
                    <td>{accAirport.accreditationLevel}</td>
                    {
                    accAirport.airportStatus === 'Draft' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: 'rgb(251, 188, 5)',color:'black' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'Verifier comments - Airport to address' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#AB47BC' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'Admin comments - Airport to address' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#F06292', color:'#000' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'In Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#FDD835', color:'#000' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'Airport resubmitted - In Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#64B5F6', color:'#000' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'Admin comments - Verifier to address' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#8BC34A', color:'#000' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'In Admin Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#303F9F' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'Airport resubmitted - In Admin Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#DD2C00' }}>{accAirport.airportStatus}</span></td> 
                    :
                    accAirport.airportStatus === 'Verifier resubmitted - In Admin Verification' 
                    ? 
                    <td><span className="badge" style={{ backgroundColor: '#B2102F' }}>{accAirport.airportStatus}</span></td> 
                    :
                    <td><span className="badge" style={{ backgroundColor: '#198754' }}>{accAirport.airportStatus}</span></td> 
                    }
                    <td>{accAirport.approvedDate}</td>
                    <td>{accAirport.verifierAssigned}</td>
                    <td>{accAirport.updatedDate}</td>
                    {isFullAccess === 'AccFullAccess' ?  
                    <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu"> 
                            {accAirport.airportStatus === 'In Admin Verification' || accAirport.airportStatus === 'Airport resubmitted - In Admin Verification' || accAirport.airportStatus === 'Verifier resubmitted - In Admin Verification' ?
                            <>
                            <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${accAirport.airportApplicationID}/AirportID/${accAirport.airportID}`}><span className="icon mdi mdi-edit"></span>Edit</a>
                            </>
                            :
                            <a className="dropdown-item" href={`/ApplicationStage/ApplicationID/${accAirport.airportApplicationID}/AirportID/${accAirport.airportID}`}><span className="icon mdi mdi-eye"></span>View</a>
                            }
                              <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => { toggleForAuditTrailModal(); }}><span className="icon mdi mdi-comments"></span>View Audit Trail</a>
                          </div>
                    </td>
                    :
                    <td></td>
                    }
                </tr>
             <CommonCustomModal modal={rejectModal} title='' toggler={toggleForRejectModal} heading='Reject Airport'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <RejectAirport toggler={toggleForRejectModal} accAirport = {accAirport} refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <CommonCustomModal modal={approveModal} title='' toggler={toggleForApproveModal} heading='Approve Airport'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <ApproveAirport toggler={toggleForApproveModal} accAirport = {accAirport}  refetchs={refetch} setRefetchs={setRefetch}/>
            </CommonCustomModal>
            <CommonCustomModal modal={auditTrailModal} modalMaxWidth='1140px' modalWidth='100%'  title='' toggler={toggleForAuditTrailModal} heading='Airport Application Events'  buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
              <AirportApplicationEvents toggler={toggleForAuditTrailModal} accAirport = {accAirport} />
            </CommonCustomModal>
            </>
        );
};

export default AccreditationApplication;