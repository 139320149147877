import React, { useState} from 'react';
import { Label } from 'reactstrap';
import config from '../../../api/config';
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from 'react-router-dom';
import { Slide, ToastContainer, toast } from 'react-toastify';
import CommonCustomModal from '../../Common/CommonCustomModal';
import EditAirportInformation from './EditAirportInformation';
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination';




const Airport = ({ airport,refetch, setRefetch }) => {

    const navigate = useNavigate();
    const userID = localStorage.getItem("userID");
    const [editAirportGroupModal, setEditAirportGroupModal] = useState(false);
    const [airportGroupDetailsModal, setAirportGroupDetailsModal] = useState(false);
    const isFullAccess = IsAccessForAccreditationCombination();

const toggleForEditAirportGroupModal = () => {
    setEditAirportGroupModal(!editAirportGroupModal);
}  

const toggleForAirportGroupDetailsModal = () => {
    setAirportGroupDetailsModal(!airportGroupDetailsModal);
}  

        return (
            <>
                <tr>
                    <td>{airport.iataCode}</td>
                    <td>{airport.region}</td>
                    <td>{airport.country}</td>
                    <td>{airport.city}</td>
                    {isFullAccess === 'AccFullAccess'  ? 
                    <>
                    <td><a className="text-info" href={`/AirportDetails/${airport.airportID}`}>{airport.airportName_Local}</a></td>
                    <td><a className="text-info" href={`/AirportDetails/${airport.airportID}`}>{airport.airportName_English}</a></td>
                    <td><a className="text-info" href={`/AirportGroupDetail/${airport.airportGroupId}`}>{airport.airportGroup}</a></td>
                    </>
                    :
                    <>
                    <td>{airport.airportName_Local}</td>
                    <td>{airport.airportName_English}</td>
                    <td>{airport.airportGroup}</td>
                    </>
                    }
                    <td>{airport.generalAviationAirport}</td>
                    <td>{airport.yearJoinedProgram}</td>
                    <td>{airport.leftProgramme}</td>
                    {isFullAccess === 'AccFullAccess'  ? 
                    <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu">
        
                            <a className="dropdown-item"  style={{cursor:'pointer'}} onClick={ () => { toggleForEditAirportGroupModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>

                          </div>
                    </td>
                    :
                    <td></td>
                    }
                </tr>
                <CommonCustomModal modal={editAirportGroupModal}  modalWidth='100%' title='' toggler={toggleForEditAirportGroupModal} heading='Edit Airport Information' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <EditAirportInformation toggler={toggleForEditAirportGroupModal} airport = {airport} refetchs={refetch} setRefetchs={setRefetch} />
                </CommonCustomModal>
            </>
        );
};

export default Airport;