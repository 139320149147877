import React from 'react';
import { useNavigate } from "react-router-dom";
import Layout from '../../../Layout';

const ViewVerifierExamFee = () => {
    const navigate = useNavigate();
    const invoiceDetails = {
      name: 'Invoice 1',
      description: 'invoice 1'
  }

    return (
        <Layout>

<div className="be-content">
      <div className="main-content container-fluid p-0">
       <div className="page-header mb-4">
        <div className="container-fluid d-flex justify-content-between align-items-baseline"> 
          <div>
            <img src="dist/img/ACA-logo.png" alt='ACA Logo'/>
          </div>
          <div >
            <h3 className="page-header-title">Verifier Exam Fee Details</h3>
          </div>
          <div>
            {/* <div className="btn-group btn-block btn-space">
              <button className="btn btn-primary" type="button"><i className="icon icon-left mdi mdi-edit"></i>Edit Details</button>
            </div> */}
          </div>
        </div>
          <nav aria-label="breadcrumb" role="navigation">

          <ol className="breadcrumb mb-0 mt-4">

            <li className="breadcrumb-item"><a href="/"><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>

            <li className="breadcrumb-item"><a href="/verifierexamfees">Verifier Exam Fee List</a></li>

            <li className="breadcrumb-item active">Verifier Exam Fee Details</li>

          </ol>

        </nav>
        </div>
        <div className="container-fluid"> 
          <div className="row">
            <div className="col-12">
              <div className="card card-table">
                <div className="card-body">
                  <div className="row">
                  <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="input_InvoiceName">Verifier Exam Fee Name:</label>
                        </div>
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="input_InvoiceName">First Exam</label>
                        </div>
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="input_Description">Amount :</label>         
                        </div>
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="input_Description">1000</label>
                        </div>
                    </div>
                   
                  </div>
                  
                  
                </div>
              </div>
            </div>
          </div>       
      </div>
      </div>
    </div>

                  </Layout>
    );
};

export default ViewVerifierExamFee;