import React, { useState} from 'react';
import { useNavigate } from 'react-router-dom';




const Exam = ({ exam }) => {
;


        return (
            <>
                <tr>
                    <td>
                    <a className="text-danger" href={`/viewexam/${exam.examID}`}>{exam.examID}</a>    
                    </td>
                    <td>{exam.externalExamRef}</td>
                    <td>{exam.examStatus}</td>
                    <td><a className="text-danger" href={`/viewverifier/${exam.verifierID}`}>{exam.verifierName}</a></td>
                    <td><a className="text-danger" href={`/viewsponsor/${exam.sponsorID}`}>{exam.sponsorName}</a></td>
                    <td><a className="text-danger" href={`/viewinvoice/${exam.invoiceID}`}>{exam.invoiceID}</a></td>
                    <td>{exam.invoiceNo}</td>
                    <td>{exam.createdDateTime}</td>
                    
                    <td className="text-center actions">
                            <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                <i className="mdi mdi-more"></i>
                                <span className="sr-only">Menu</span>
                            </a>
                            <div className="dropdown-menu">
                            <a className="dropdown-item"  style={{cursor:'pointer'}} href={`/viewexam/${exam.examID}`}><span className="icon mdi mdi-eye"></span>View</a>                       
                              
                          </div>
                        </td>
                </tr>

            </>
        );
};

export default Exam;