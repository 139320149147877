import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, Link } from "react-router-dom"
import Layout from '../../../Layout'
import CommonCustomModal from '../../Common/CommonCustomModal'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { Slide, ToastContainer, toast } from 'react-toastify'
import AirportGroupDetailsTab from './AirportGroupDetailsTab'
import EditAirportGroup from './EditAirportGroup'
import AirportGroupDetailsWithAirport from './AirportGroupDetailsWithAirport'
import IsAccessForAccreditationCombination from '../../Common/IsAccessForAccreditationCombination'
import Loader from '../../Common/Loader'

const AirportGroupDetail = () => {

    /* States & Constants */
    const navigate = useNavigate()
    const { id } = useParams()
    const [refetch, setRefetch] = useState(false)
    const [airportGroupDetails, setAirportGroupDetails] = useState([])
    const userID = localStorage.getItem("userID")
    const [editAirportGroupModal, setEditAirportGroupModal] = useState(false)
    const [airportGroupDetailsModal, setAirportGroupDetailsModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingChildData, setIsLoadingChildData] = useState(true)
    const isFullAccess = IsAccessForAccreditationCombination()
    const airportgroupID = { id: id }

    /* Methods */
    const toggleForEditAirportGroupModal = () => {
        setEditAirportGroupModal(!editAirportGroupModal)
    }  
    const toggleForAirportGroupDetailsModal = () => {
        setAirportGroupDetailsModal(!airportGroupDetailsModal)
    }  

    /* Effects */
    //get AirportDetailsByID
    useEffect(() => {
        setIsLoading(true)
        fetch(`${config.apiUrl}${apiconfig.getAirportGroupDetailsByIdWithApplicationDetails}${id}`,{
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        })
        .then((response) => response.json())
        .then((data) => {
            setAirportGroupDetails(data)
        }).catch((err) => {
            navigate("/error-page")
        })
        setIsLoading(false)
    }, [id, refetch])


    return (
        <>
            <Layout>
                {
                    isLoading || isLoadingChildData
                        ?
                        <Loader />
                        :
                        <React.Fragment></React.Fragment>
                }
                <div className="be-content">
                    <div className="main-content container-fluid p-0">
                        <div className="page-header ph-orange mb-4">
                            <div className="container-header">
                                <div className="row">
                                    <div className="col">
                                        <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                    </div>
                                    <div className="col-6 d-flex align-items-center justify-content-center">
                                        <h3 className="page-header-title">Airport Group Details</h3>
                                    </div>
                                    <div className="col d-flex align-items-center justify-content-end">

                                    </div>
                                </div>
                                <nav aria-label="breadcrumb" role="navigation">
                                    <ol className="breadcrumb mb-0 mt-4">
                                        <li className="breadcrumb-item"><a href={isFullAccess === 'AccAirportUserAccess' ? "/AirportGroupDashboard" : "/"}><span class="icon mdi mdi-home"><span class="sr-only">Home</span></span></a></li>
                                        { isFullAccess === 'AccAirportUserAccess' ?
                                        <li className="breadcrumb-item"><a  style={{ color: "grey", pointerEvents:'none'}} href="/AirportGroups">Airport Group List</a></li>
                                        :
                                        <li className="breadcrumb-item"><a href="/AirportGroups">Airport Group List</a></li>
                                        }
                                        <li className="breadcrumb-item active">Airport Group Details</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="container-header">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card card-table">
                                        <div className="card-header">

                                            <div className="tools">
                                            {isFullAccess === 'AccFullAccess' && (
                                                <>
                                                <a className="dropdown-toggle icon" href="#" data-toggle="dropdown" aria-expanded="false">
                                                    <i className="mdi mdi-more"></i>
                                                    <span className="sr-only">Menu</span>
                                                </a>
                                                <div className="dropdown-menu">
                                                    <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForEditAirportGroupModal(); }}><span className="icon mdi mdi-edit"></span>Edit</a>
                                                    <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => { toggleForAirportGroupDetailsModal(); }}><span className="icon mdi mdi-edit"></span>Manage Airports</a>
                                                    <a className="dropdown-item" style={{cursor:'pointer'}} href={`/AirportUser/RefID/${id}/RefType/AirportGroup`}><span className="icon mdi mdi-accounts-add"></span>Manage User</a>
                                                </div>
                                                </>
                                             )}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div><label><strong>Airport Group Information</strong></label></div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="groupName">Group Name :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="groupName">{airportGroupDetails.airportGroup}</label>
                                                </div>
                                                {/* <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="smallAirportGroup">Small Airport Group :</label>
                                                </div>
                                                <div className="form-group col-md-3 mb-1">
                                                    <label htmlFor="smallAirportGroup">{airportGroupDetails.isSmallAirportGroup}</label>
                                                </div> */}
                                            </div>                                    
                                        </div>
                                        
                                        <AirportGroupDetailsTab airportGroupID={id} refetchs={refetch} setRefetchs={setRefetch} setIsLoadingChildData={setIsLoadingChildData} isLoadingChildData={isLoadingChildData} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </Layout>
            <CommonCustomModal modal={editAirportGroupModal}  modalWidth='100%' title='' toggler={toggleForEditAirportGroupModal} heading='Edit Airport Group' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <EditAirportGroup toggler={toggleForEditAirportGroupModal} airportgroup = {airportgroupID} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
            <CommonCustomModal modal={airportGroupDetailsModal}  modalMaxWidth='1140px' modalWidth='100%' title='' toggler={toggleForAirportGroupDetailsModal} heading='Airport Group Details' buttonText='Submit' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                <AirportGroupDetailsWithAirport toggler={toggleForAirportGroupDetailsModal} airportgroupDetails = {airportGroupDetails} airportgroup = {airportgroupID} refetchs={refetch} setRefetchs={setRefetch} />
            </CommonCustomModal>
        </>

    );
};

export default AirportGroupDetail;