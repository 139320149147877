import React from 'react'
 
const ApplicationOverview = ({ data, state, activeRightPane, setActiveRightPane, leftNavigationMenu, userType }) => {
 
    const doesApplicationHasDocuments = () => {
        for(const section of state.sections){
            for(const subsection of section.subsections){
                if(subsection.content.documents.length !== 0)
                    return true
            }
        }
        return false
    }

    const doesSectionHasDocuments = (sectionIndex) => {
        for(const subsection of state.sections[sectionIndex].subsections){
            if(subsection.content.documents.length !== 0){
                for(const document of subsection.content.documents){
                    if(document.fileDownloadURL !== null && document.fileDownloadURL.length !== 0)
                        return true
                }
            }
        }
        return false
    }

    const doesSubSectionHasDocuments = (sectionIndex, subSectionIndex) => {
        if(state.sections[sectionIndex].subsections[subSectionIndex].content.documents.length !== 0){
            for(const document of state.sections[sectionIndex].subsections[subSectionIndex].content.documents){
                if(document.fileDownloadURL !== null && document.fileDownloadURL.length !== 0)
                    return true
            }
        }
        return false
    }

    return(
        data.default.length === 0 
            ? 
            <h5 className="text" style={{ textAlign: 'center' }}>Nothing to show here</h5>
            :
            <React.Fragment>
                <div className="user-info-list card card-border card-contrast card-table" style={activeRightPane === data.default[0].name ? {} : {display: 'none'}}>
                    <div className="card-header card-header-contrast card-header-divider">
                        Application Overview
                    </div>
                    <div className="card-body">
                        <div className="p-2 bg-teal-1">
                            <h5 className="m-0">Application Details</h5>
                        </div>
                        <table className="no-border no-strip skills">
                            <tbody className="no-border-x no-border-y">
                                <tr>
                                    <td className="item icon font-weight-bold"><span className="mdi mdi-calendar-alt mr-2"/>&nbsp;Date Started</td>
                                    <td>{data.default[0].content.applicationDetails.dateStarted}</td>
                                </tr>
                                <tr>
                                    <td className="item icon font-weight-bold"><span className="mdi mdi-airplane mr-2"/>&nbsp;Airport</td>
                                    <td>{data.default[0].content.applicationDetails.airport}</td>
                                </tr>
                                <tr>
                                    <td className="item icon font-weight-bold"><span className="mdi mdi-flight-takeoff mr-2"/>&nbsp;Airport Band</td>
                                    <td>{data.default[0].content.applicationDetails.airportBand}</td>
                                </tr>
                                <tr>
                                    <td className="item icon font-weight-bold"><span className="mdi mdi-view-dashboard mr-2"/>&nbsp;Accreditation Level</td>
                                    <td><span className="badge badge-secondary">{data.default[0].content.applicationDetails.accreditationLevel}</span></td>
                                </tr>
                                <tr>
                                    <td className="item icon font-weight-bold"><span className="mdi mdi-assignment-check mr-2"/>&nbsp;Questions Answered</td>
                                    <td><span className="badge badge-pill badge-success">{data.default[0].content.applicationDetails.questionsAnswered}</span></td>
                                </tr>
                                <tr>
                                    <td className="item icon font-weight-bold"><span className="mdi mdi-assignment mr-2"/>&nbsp;Questions Remaining</td>
                                    <td><span className="badge badge-pill badge-danger">{data.default[0].content.applicationDetails.questionsRemaining}</span></td>
                                </tr>
                                {
                                    userType === 'draft'
                                        ?
                                        <tr key='draft-row'>
                                            {/* <td className="item icon font-weight-bold"><span className="mdi mdi-calendar-alt mr-2"/>&nbsp;Date Submitted to Admin</td>
                                            <td>{data.default[0].content.applicationDetails.dateSubmittedToAdmin}</td> */}
                                        </tr>
                                        :
                                        userType === 'verifier'
                                            ?
                                            <React.Fragment>
                                                <tr key='verifier-row-1'>
                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-account-circle mr-2"/>&nbsp;Verifier</td>
                                                    <td>{data.default[0].content.applicationDetails.verifier}</td>
                                                </tr>
                                                <tr key='verifier-row-2'>
                                                    <td className="item icon font-weight-bold"><span className="mdi mdi-accounts-alt mr-2"/>&nbsp;Verification Type</td>
                                                    <td>
                                                        {
                                                            data.default[0].content.applicationDetails.verificationType === '1' ? 'Verification completed on-site' : data.default[0].content.applicationDetails.verificationType === '2' ? 'Verification completed off-site' : ''
                                                        }
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                            :
                                            <React.Fragment></React.Fragment>
                                }
                            </tbody>
                        </table>
                        {
                            userType === 'verifier'
                                ?
                                <React.Fragment>
                                    <div className="p-2 bg-teal-1">
                                        <h5 className="m-0">Verification Review</h5>
                                    </div>
                                    <table className="no-border no-strip skills">
                                        <tbody className="no-border-x no-border-y">
                                            <tr>
                                                <td className="item icon font-weight-bold"><span className="mdi mdi-badge-check mr-2"/>&nbsp;Verifier Review Overall</td>
                                                <td>{data.default[0].content.verificationReview.overall}</td>
                                            </tr>
                                            <tr>
                                                <td className="item icon font-weight-bold"><span className="mdi mdi-badge-check mr-2"/>&nbsp;Verifier Review Footprint</td>
                                                <td>{data.default[0].content.verificationReview.footprint}</td>
                                            </tr>
                                            <tr>
                                                <td className="item icon font-weight-bold"><span className="mdi mdi-badge-check mr-2"/>&nbsp;Verifier Review Management</td>
                                                <td>{data.default[0].content.verificationReview.management}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                                :
                                <React.Fragment></React.Fragment>
                        }
                        <div className="p-2 bg-teal-1">
                            <h5 className="m-0">Uploaded Documents</h5>
                        </div>
                        {
                            doesApplicationHasDocuments()
                                ?
                                state.sections.map((section, sectionIndex) => {
                                    return doesSectionHasDocuments(sectionIndex)
                                        ? 
                                        <React.Fragment key={section.id}>
                                            <h5 className="ml-2">{section.title}</h5>
                                            <table className="no-border no-strip skills" style={{marginBottom: 15}}>
                                                <tbody className="no-border-x no-border-y">
                                                    {
                                                        section.subsections.map((subsection, subsectionIndex) => {
                                                            return doesSubSectionHasDocuments(sectionIndex, subsectionIndex)
                                                                ?
                                                                <React.Fragment key={subsection.id}>
                                                                    <tr>
                                                                        <th style={{paddingLeft: 0, paddingTop: 0, paddingBottom: 0, paddingRight: 5}}>
                                                                            <h5 className="m-0 mb-1 ml-4" style={subsectionIndex === 0 ? {} : {paddingTop: 10}}>{subsection.title}</h5>
                                                                        </th>
                                                                    </tr>
                                                                    {
                                                                        subsection.content.documents.map((document) => {
                                                                            return document.fileDownloadURL !== null && document.fileDownloadURL.length !== 0
                                                                                ?
                                                                                <tr key={document.id}>
                                                                                    <td style={{paddingTop: 3, paddingRight: 0, paddingBottom: 3, paddingLeft: 32}}>
                                                                                        <div className="media">
                                                                                            <div className="media-body align-self-center">
                                                                                                <a href={document.fileDownloadURL} target="_blank" className="text-info">{document.fileName}</a>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style={{paddingTop: 3, paddingRight: 20, paddingBottom: 3, paddingLeft: 0}}>
                                                                                        <span className="badge badge-pill badge-secondary f-12 text-uppercase">ADDED BY {document.addedByUserType}</span>
                                                                                        &nbsp;
                                                                                        <span className="badge badge-pill badge-secondary f-12 text-uppercase">ADDED AT {document.addedAtApplicationStage}</span>
                                                                                    </td>
                                                                                </tr>
                                                                                :
                                                                                <React.Fragment></React.Fragment>  
                                                                        })
                                                                    }
                                                                </React.Fragment>
                                                                : 
                                                                <React.Fragment></React.Fragment>
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                        :
                                        <React.Fragment></React.Fragment>
                                })
                                :
                                <label style={{paddingLeft: 20, paddingRight: 15, paddingTop: 15, paddingBottom: 15}}>No document is uploaded for this application.</label>
                        }
                        {/* <div className="p-2 bg-teal-1">
                            <h5 className="m-0">Application Report</h5>
                        </div>
                        <table className="no-border no-strip skills">
                            <tbody className="no-border-x no-border-y">
                                <tr>
                                    <td>
                                        <div className="media align-items-center">
                                            <span className="mdi mdi-3x mdi-file-text text-danger" />
                                            <div className="media-body ml-3">
                                                <p className="mb-0">Download PDF Report</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <button className="btn btn-space btn-rounded btn-secondary"><i className="icon icon-left mdi mdi-download" />Download</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table> */}
                    </div>
                </div>
                <div className="" style={activeRightPane === data.default[0].name ? {display: 'flex', justifyContent: 'flex-end'} : {display: 'none'}}>
                    <button id="validation-btn" className="btn btn-space btn-success btn-xl" onClick={() => { if(leftNavigationMenu.indexOf(activeRightPane) !== leftNavigationMenu.length-1 ){ setActiveRightPane(leftNavigationMenu[leftNavigationMenu.indexOf(activeRightPane)+1]); window.scrollTo({ top: 0, left: 0, behavior: 'smooth' }) } }}>Next&nbsp;
                        <i className="icon icon-left mdi mdi-arrow-right" style={{marginBottom: 3}} />
                    </button>
                </div>
            </React.Fragment>
    )
}
 
export default ApplicationOverview