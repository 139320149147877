import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";

const ViewUserPrimary = ({user, toggler,isPrimary}) => {

    const navigate = useNavigate();
    const [emails, setEmails] = useState('');
    const [firstNames, setFirstNames] = useState('');
    const [lastNames, setLastNames] = useState('');
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [country, setcountry] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [postcode, setPostcode] = useState("");
    const userID = localStorage.getItem("userID");
    const [isPrimaryUser,setIsPrimaryUser] = useState(false);
    const [filePath, setFilePath] = useState("");



      //get DetailsByID
    const getDetailByID = async () => {

        let userValue = 'Primary';
        if(user.isPrimary === 0){
            userValue = 'Secondary'
        }
    try{
        const response =  await fetch(`${config.apiUrl}${apiconfig.getAirportUserDetails}?UserID=${user.userID}&RequestFrom=${userValue}`, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
        });
        if (response.ok) {
            var data = await response.json();
                setEmails(data.email);
                setFirstNames(data.firstName);
                setLastNames(data.lastName);
                setaddress1(data.address1);
                setaddress2(data.address2);
                setcountry(data.country);
                setCity(data.city);
                setState(data.state);
                setPostcode(data.postalCode);
                setFilePath(data.aciMembershipDocument);
        } else {
            const errorText = await response.text();
            const apiname = `${apiconfig.getAirportUserDetails}`;
            navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
        }
        }catch(error){
            console.log(error.message);
            navigate(`/error-page`); 
        }
    }
 
      
    useEffect(()=>{
        if(user.isPrimary===1){
            setIsPrimaryUser(true)
        }
        getDetailByID();
    },[user.userID])




    return (
        <div>
                <form>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">First Name :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{firstNames}</label>
                        </div>
                    </div>    
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Last Name :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{lastNames}</label>
                        </div>
                    </div>   
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Email :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{emails}</label>
                        </div>
                    </div>   
                    {isPrimaryUser ?  
                    <>
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Address Line 1 :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{address1}</label>
                        </div>
                    </div>   
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Address Line 2 :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{address2}</label>
                        </div>
                    </div>   
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Country :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{country}</label>
                        </div>
                    </div>   
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">City :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{city}</label>
                        </div>
                    </div>   
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">State :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{state}</label>
                        </div>
                    </div>   
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Post Code :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                            <label htmlFor="verifier_Name">{postcode}</label>
                        </div>
                    </div>  
                    <div className="form-row">
                        <div className="form-group col-md-3 mb-1">
                            <label htmlFor="verifier_Name">Membership Certificate :</label>         
                        </div>
                        <div className="form-group col-md-9 mb-1">
                        <label className="form-control-plaintext "><a style={{cursor:'pointer', color: "blue"}} href={filePath} target='_blank' rel='noreferrer' >View Membership Document</a></label>
                        </div>
                    </div>  
                    </> 
                    :
                    <></>
                    }
                </form>

              <div className="modal-footer">
              <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
              <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
              {/* <button className="btn btn-success mt-auto" type="button">Submit</button> */}
              </div>
        </div>
    );
};

export default ViewUserPrimary;