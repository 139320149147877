import React from 'react'

const Matrix = ({ index, subSectionIndex, sectionIndex, data, state, setState, userType, questionRefs, calculateQuestionRefIndex, visibilityBasedOnType, handleMatrixChange }) => {

    const updateState = (value, row_index, column_index) => {
        const updatedState = {...state}
        
        // update value of attribute
        updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[row_index].attributes[column_index].value = value
        
        // update state by performing calculations
        updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows.map((row, row_index) => {
            row.attributes.map((attribute, attribute_index) => {
                if(attribute.calculated){
                    if(attribute.direction === 'horizontal'){
                        switch (attribute.operation){
                            case 'average':
                                let sum_for_average = 0
                                let valid_for_average = false
                                attribute.indexes.map((index) => {
                                    if(row.attributes[index].value.length !== 0){
                                        if(!valid_for_average)
                                            valid_for_average = true
                                        sum_for_average += parseInt(row.attributes[index].value)
                                    }
                                })
                                if(valid_for_average)
                                    attribute.value = Number((sum_for_average/attribute.indexes.length).toFixed(6)).toString()
                                else
                                    attribute.value = ''
                                break
                            case 'compare':
                                let valid_for_compare = true
                                if(row.attributes[attribute.indexes[0]].value.length === 0 || row.attributes[attribute.indexes[1]].value.length === 0)
                                    valid_for_compare = false
                                if(valid_for_compare){
                                    if(attribute.operationType === 'lesser-than')
                                        if(parseFloat(row.attributes[attribute.indexes[1]].value) < parseFloat(row.attributes[attribute.indexes[0]].value))
                                            attribute.value = 'Yes'
                                        else
                                            attribute.value = 'No'
                                    else if(attribute.operationType === 'greater-than')
                                        if(parseFloat(row.attributes[attribute.indexes[1]].value) > parseFloat(row.attributes[attribute.indexes[0]].value))
                                            attribute.value = 'Yes'
                                        else
                                            attribute.value = 'No'
                                    else if(attribute.operationType === 'equals-to')
                                        if(parseFloat(row.attributes[attribute.indexes[1]].value) === parseFloat(row.attributes[attribute.indexes[0]].value))
                                            attribute.value = 'Yes'
                                        else
                                            attribute.value = 'No'
                                }  
                                else
                                    attribute.value = ''
                                break
                            case 'sum':
                                let sum = 0
                                let valid = true
                                attribute.indexes.map((index) => {
                                    if(row.attributes[index].value.length === 0)
                                        valid = false
                                    else
                                        sum += parseFloat(row.attributes[index].value)
                                })
                                if(valid)
                                    attribute.value = Number(sum.toFixed(6)).toString()
                                else
                                    attribute.value = ''
                                break
                            default:
                                break
                        }
                    }else if(attribute.direction === 'vertical'){
                        switch (attribute.operation){
                            case 'divide':
                                let numerator = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[attribute.indexes[0]].attributes[attribute_index].value
                                let denominator = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[attribute.indexes[1]].attributes[attribute_index].value
                                let valid_for_divide = true

                                if(numerator.length === 0 || denominator.length === 0)
                                    valid_for_divide = false

                                if(valid_for_divide)
                                    attribute.value = Number((parseFloat(numerator)/parseFloat(denominator)).toFixed(6)).toString()
                                else
                                    attribute.value = ''    
                                break
                            case 'sum':
                                let sum = 0
                                let valid_for_sum = true
                                attribute.indexes.map((indexes_index) => {
                                    if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[indexes_index].attributes[attribute_index].value === '')
                                        valid_for_sum = false
                                    else
                                        sum += parseFloat(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[indexes_index].attributes[attribute_index].value)
                                })
                                if(valid_for_sum)
                                    attribute.value = Number(sum.toFixed(6)).toString()
                                else
                                    attribute.value = ''
                                break
                            default:
                                break
                        }
                    }
                }
            })
        })

        // copy value to a different cell (of same or differnt matrix) based on: `sectionID`, `subSectionID`, `questionID`, `rowID`, `attributeID`
        if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[row_index].attributes[column_index].copyTo !== null){
            if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[row_index].attributes[column_index].copyTo.length !== 0){
                for(const item of updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix.rows[row_index].attributes[column_index].copyTo){
                    for(const section of updatedState.sections){
                        if(section.id === item.sectionID){
                            for(const subsection of section.subsections){
                                if(subsection.id === item.subSectionID){
                                    for(const question of subsection.content.questions){
                                        if(question.id === item.questionID){
                                            if(question.matrix !== null){
                                                if(question.matrix.rows !== null && question.matrix.rows.length !== 0){
                                                    for(const row of question.matrix.rows){
                                                        if(row.id === item.rowID){
                                                            if(row.attributes !== null && row.attributes.length !== 0){
                                                                for(const attribute of row.attributes){
                                                                    if(attribute.id === item.attributeID){
                                                                        if(attribute.type !== 'span')
                                                                            attribute.value = value
                                                                    }   
                                                                }
                                                            }
                                                        }
                                                        /* copying the same code as above */
                                                        row.attributes.map((attribute, attribute_index) => {
                                                            if(attribute.calculated){
                                                                if(attribute.direction === 'horizontal'){
                                                                    switch (attribute.operation){
                                                                        case 'average':
                                                                            let sum_for_average = 0
                                                                            let valid_for_average = false
                                                                            attribute.indexes.map((index) => {
                                                                                if(row.attributes[index].value.length !== 0){
                                                                                    if(!valid_for_average)
                                                                                        valid_for_average = true
                                                                                    sum_for_average += parseInt(row.attributes[index].value)
                                                                                }
                                                                            })
                                                                            if(valid_for_average)
                                                                                attribute.value = Number((sum_for_average/attribute.indexes.length)).toFixed(6).toString()
                                                                            else
                                                                                attribute.value = ''
                                                                            break
                                                                        case 'compare':
                                                                            let valid_for_compare = true
                                                                            if(row.attributes[attribute.indexes[0]].value.length === 0 || row.attributes[attribute.indexes[1]].value.length === 0)
                                                                                valid_for_compare = false
                                                                            if(valid_for_compare){
                                                                                if(attribute.operationType === 'lesser-than')
                                                                                    if(parseFloat(row.attributes[attribute.indexes[1]].value) < parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                                        attribute.value = 'Yes'
                                                                                    else
                                                                                        attribute.value = 'No'
                                                                                else if(attribute.operationType === 'greater-than')
                                                                                    if(parseFloat(row.attributes[attribute.indexes[1]].value) > parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                                        attribute.value = 'Yes'
                                                                                    else
                                                                                        attribute.value = 'No'
                                                                                else if(attribute.operationType === 'equals-to')
                                                                                    if(parseFloat(row.attributes[attribute.indexes[1]].value) === parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                                        attribute.value = 'Yes'
                                                                                    else
                                                                                        attribute.value = 'No'
                                                                            }  
                                                                            else
                                                                                attribute.value = ''
                                                                            break
                                                                        case 'sum':
                                                                            let sum = 0
                                                                            let valid = true
                                                                            attribute.indexes.map((index) => {
                                                                                if(row.attributes[index].value.length === 0)
                                                                                    valid = false
                                                                                else
                                                                                    sum += parseFloat(row.attributes[index].value)
                                                                            })
                                                                            if(valid)
                                                                                attribute.value = Number(sum.toFixed(6)).toString()
                                                                            else
                                                                                attribute.value = ''
                                                                            break
                                                                        default:
                                                                            break
                                                                    }
                                                                }
                                                                else if(attribute.direction === 'vertical'){
                                                                    switch (attribute.operation){
                                                                        case 'divide':
                                                                            let numerator = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[subsection.content.questions.indexOf(question)].matrix.rows[attribute.indexes[0]].attributes[attribute_index].value
                                                                            let denominator = updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[subsection.content.questions.indexOf(question)].matrix.rows[attribute.indexes[1]].attributes[attribute_index].value
                                                                            let valid_for_divide = true
                                            
                                                                            if(numerator.length === 0 || denominator.length === 0)
                                                                                valid_for_divide = false
                                            
                                                                            if(valid_for_divide)
                                                                                attribute.value = Number((parseFloat(numerator)/parseFloat(denominator)).toFixed(6)).toString()
                                                                            else
                                                                                attribute.value = ''
                                                                            break
                                                                        case 'sum':
                                                                            let sum = 0
                                                                            let valid_for_sum = true
                                                                            attribute.indexes.map((indexes_index) => {
                                                                                if(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[subsection.content.questions.indexOf(question)].matrix.rows[indexes_index].attributes[attribute_index].value === '')
                                                                                    valid_for_sum = false
                                                                                else
                                                                                    sum += parseFloat(updatedState.sections[sectionIndex].subsections[subSectionIndex].content.questions[subsection.content.questions.indexOf(question)].matrix.rows[indexes_index].attributes[attribute_index].value)
                                                                            })
                                                                            if(valid_for_sum)
                                                                                attribute.value = Number(sum.toFixed(6)).toString()
                                                                            else
                                                                                attribute.value = ''
                                                                            break
                                                                        default:
                                                                            break
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        handleMatrixChange()
        setState(updatedState)
    }

    // console.log(state.sections[sectionIndex].subsections[subSectionIndex].content.questions[index].matrix)

    return(
        <React.Fragment>
            <label>
                <i>{data.matrix.helpText}</i>
            </label>
            <div className="card card-contrast card-table card-border" style={{marginBottom: 0}}>
                <div className="card-body card-body-contrast">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                {
                                    data.matrix.columns.map((column, column_index) => {
                                        return <th key={column_index} style={{ width: column.width }}>{column.title}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody className="no-border-x" ref={questionRefs.current[calculateQuestionRefIndex(data.id, data.question)]}>
                            {
                                !data.matrix.isValid 
                                    ? 
                                    <tr key={index}>
                                        <td colSpan={data.matrix.numberOfColumns} style={{backgroundColor: '#f8f9fa'}}>
                                            <b>{data.matrix.validationMessage}</b>
                                        </td>
                                    </tr>
                                    :
                                    <React.Fragment>
                                        {
                                            data.matrix.rows.map((row, row_index) => {
                                                return(
                                                    <React.Fragment>
                                                        <tr key={row_index}>
                                                            <td colSpan={data.matrix.numberOfColumns} style={{backgroundColor: '#f8f9fa'}}>
                                                                <i>{row.title}</i>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                        {
                                                            row.attributes.map((attribute, attribute_index) => {
                                                                return(
                                                                    <td key={attribute_index}>
                                                                        {
                                                                            attribute.type === 'text' 
                                                                                ? 
                                                                                attribute.calculated 
                                                                                    ? 
                                                                                    <input id={`matrix#${data.id}-row#${row.id}-attribute#${attribute.id}-calculated`} className="form-control form-control-sm" type="text" value={attribute.value} pattern="[0-9]*" readOnly={attribute.calculated} style={attribute.calculated ? {cursor: 'not-allowed'} : {}} />
                                                                                    :
                                                                                    <input id={`matrix#${data.id}-row#${row.id}-attribute#${attribute.id}`} className="form-control form-control-sm" type="text" value={attribute.value} pattern="[0-9]*" onChange={(e) => { if(/^\d*$/.test(e.target.value)){ updateState(e.target.value, row_index, attribute_index) } }} disabled={visibilityBasedOnType(state.sections[sectionIndex].subsections[subSectionIndex].type, false) ? false : true} />
                                                                                :
                                                                                attribute.type === 'empty'
                                                                                    ?
                                                                                    <span></span>
                                                                                    :
                                                                                    <span>{attribute.value}</span>
                                                                        }
                                                                    </td>
                                                                )
                                                            })
                                                        }
                                                        </tr>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Matrix