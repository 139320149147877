import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    
    return (
        <footer className="be-footer" style={{ backgroundColor: '#184086' }}>
            <div className="container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <img src={require('../img/logo-red.png')} width="58" alt="WSP Logo"/>
                        <p style={{ color: '#FFFFFF' }}><small>WSP UK &copy; {currentYear}</small></p>
                        <p>Get In <b>Touch</b></p>
                        <p><small>If you require further information about</small></p>
                        <p><small><i>Airport Carbon Accreditation</i></small> | <a className="text-white text-decoration-underline" href="mailto:aca@wsp.com">contact us</a></p>
                       

                    </div>
                    <div className="d-flex align-items-end justify-content-end">
                        <p style={{ color: '#FFFFFF' }}>
                            by: <img src={require('../img/logo-red.png')} width="35" alt="WSP Digital Solutions Team"/>
                            <strong>&nbsp;Digital Solutions Team</strong>
                        </p>
                    </div>
                </div>
            </div> 
        </footer>
    );
};

export default Footer;