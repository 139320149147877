import React from 'react';
import { TbArrowsSort } from 'react-icons/tb';

export const TableHeaderCells = [
    {
        id: 1,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        style: {},
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 2,
        isHidden: false,
        name: 'User Name',
        colSpan: 1,
        style: { cursor: 'pointer', width: '20%' },
        // hasIcon: true,
        // icon: <TbArrowsSort />,
        // isSortable: true,
        sortByColumnName: 'UserName'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Address',
        colSpan: 1,
        style: { cursor: 'pointer', width: '20%' },
        // hasIcon: true,
        // icon: <TbArrowsSort />,
        // isSortable: true,
        sortByColumnName: 'Address'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Email',
        colSpan: 1,
        style: { cursor: 'pointer', width: '20%' },
        // hasIcon: true,
        // icon: <TbArrowsSort />,
        // isSortable: true,
        sortByColumnName: 'Email'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Is Primary?',
        colSpan: 1,
        style: { cursor: 'pointer',width: '20%' },
        // hasIcon: true,
        // icon: <TbArrowsSort />,
        // isSortable: true,
        sortByColumnName: 'isPrimary'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        style: { cursor: 'pointer', width: '15%'},
        // hasIcon: true,
        // icon: <TbArrowsSort />,
        // isSortable: true,
        sortByColumnName: 'Status'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Actions',
        colSpan: 3,
        style: { textAlign: 'center', width: '5%' },
        hasIcon: false,
        icon: null,
        isSortable: false,
        sortByColumnName: null
    }
];