import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

const CommonCustomModal = ({ modal, modalMaxWidth, modalWidth, title, toggler, heading, triggerMethodOnSaveButtonClick, children, buttonText,style, buttonColor, hasFooterImage, footerImage, footerImageWidth,isFooter }) => {

    return (
        <Modal isOpen={modal} title={title} toggle={toggler} style={{maxWidth: modalMaxWidth, width: modalWidth}} centered>
            <ModalHeader toggle={toggler}>
                {heading}
            </ModalHeader>
            <ModalBody style={style}>
                {children}
            </ModalBody>
            {
            isFooter
                        ?
                        <ModalFooter>
                        {
                           hasFooterImage
                           ?
                               <img className="mr-auto" src={require('../../img/' + footerImage)} width={footerImageWidth}/>
                           :
                               <></>
                        }
                        <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
                        <button className="btn btn-success mt-auto" type="button" onClick={triggerMethodOnSaveButtonClick}>{buttonText}</button>
                        </ModalFooter>
                        :
                        <></>
}
        </Modal>
    );

};

export default CommonCustomModal;