import React, { useState, useEffect } from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import axios from '../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';

const AddVerifier = ({ toggler,toggler1 ,refetchs, setRefetchs }) => {
    const navigate = useNavigate();
    {/* STATES */ }
    const [refetch, setRefetch] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [vSList, setVSList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [clientId, setClientId] = useState(0);
    const [verifierName, setverifierName] = useState("");
    const [address1, setaddress1] = useState("");
    const [address2, setaddress2] = useState("");
    const [city, setcity] = useState("");
    const [zipcode, setzipcode] = useState("");
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [country, setcountry] = useState("");
    const [region, setregion] = useState("");
    const [dojp, setdojp] = useState("");
    const [verifierStatus, setverifierStatus] = useState("");
    const [dolv, setdolv] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");



    const getCountryList = async () => {
 
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getCountry}${region}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                var result = await response.json();
                setCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountry}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }
        useEffect(()=>{
            if(region){
            getCountryList();
        }
        },[region])

        const getRegionList = async () => {

            try{
              const response =  await fetch(`${config.apiUrl}${apiconfig.getRegion}`, {
                    method: 'GET',
                    headers: {

                        Authorization: `Bearer ${localStorage.getItem("token")}`

                    }
                });
                if (response.ok) {
                    var result = await response.json();
                    setRegionList(result);
                } else {
                    const errorText = await response.text();
                    const apiname = `${apiconfig.getRegion}`;
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
                }
                }catch(error){
                    console.log(error.message);
                    navigate(`/error-page`); 
                }
            }

    useEffect(() => {
        getRegionList();
    }, [refetch]);

    // useEffect(() => {
    //     function getVerifierStatusList() {
    //         fetch(`${config.apiUrl}${apiconfig.getVerifierStatus}`, {
    //             method: 'GET'
    //         }).then(res => res.json())
    //             .then((data) => {
    //                 setVSList(data);
    //             }).catch((err) => {
    //                 console.log("Error: ", err);
    //                 navigate("/error-page");
    //             });
    //     }
    //     getVerifierStatusList();
    // }, [refetch]);

    {/*Validation*/}
    const validateForm = (verifierName, address1, address2, city,zipcode,email,phone,region,country,dojp,dolv,verifierStatus) => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!verifierName){
            errors.verifierName = 'Please enter a value.';
        } else if(verifierName.length > 256){
            errors.verifierName = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(verifierName)) {
            errors.verifierName = 'Special characters are not allowed.';
          }

        if(!address1){
            errors.address1 = 'Please enter a value.';
        }else if(address1.length > 256){
            errors.address1 = 'Maximum of 256 characters allowed.';
        }
        // else if (!/^[a-zA-Z0-9\s]+$/.test(address1)) {
        //     errors.address1 = 'Special characters are not allowed.';
        //   }

        if(address2.length > 0){
        if(address2.length > 256){
            errors.address2 = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(address2)) {
            errors.address2 = 'Special characters are not allowed.';
        }
        }

        if(!city){
            errors.city = 'Please enter a value.';
        }else if(city.length > 256){
            errors.city = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(city)) {
            errors.city = 'Special characters are not allowed.';
          }

        if(!zipcode){
            errors.zipcode = 'Please enter a value.';
        }else if(zipcode.length > 256){
            errors.zipcode = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(zipcode)) {
            errors.zipcode = 'Special characters are not allowed.';
          }

        if(!email){
            errors.email = 'Please enter a value.';
        }
        else if(!emailRegex.test(email)){
            errors.email = 'Please enter a valid email address.';
        }

        if(!phone){
            errors.phone = 'Please enter a value.';
        }else {
            const phoneRegex = /^\+[0-9]+([\s-]?[0-9]+)*$/;
            if (!phoneRegex.test(phone)) {
              errors.phone = 'Please enter a valid phone number.';
            }
          }
        if(!country){
            errors.country = 'Please select a value.';
        }

        if(!region){
            errors.region = 'Please select a value.';
        }

        if(!dojp){
            errors.dojp = 'Please select a date.';
        }
        // if(!verifierStatus){
        //     errors.verifierStatus = 'Please select verifier status!';
        // }
        // if(!dolv){
        //     errors.dolv = 'Date of Last Verification/Date of Approval is required!';
        // }
        return errors;
    }


    const createVerifiers = async () => {

        const requestBody = {
            verifierDetailID : 0,
            verifierName: verifierName.trim(),
            clientID : clientId,
            countryID:country,
            regionID:region,
            email:email.trim(),
            phoneNumber:phone.trim(),
            addressLine1: address1.trim(),
            addressLine2: address2.trim(),
            city: city.trim(),
            zipcode:zipcode.trim(),
            // verifierStatusID : 0,
            applicationReceiveDate:dojp,
            userID : userID,
            message :'',
            //accreditationLevel:'NA',
            //dateApproved:'NA',
            //sponsor : 'NA',
        };
        const validationErrors = validateForm(verifierName.trim(), address1.trim(), address2.trim(), city.trim(),zipcode.trim(),email.trim(),phone.trim(),region,country,dojp);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.addVerifier}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              });
              if (response.ok) {
                var result = await response.json();
                if(result.saveErrorMessage === 'Verifier saved successfully.')
                {
                    toast.success('Verifier saved successfully.', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Slide
                    }); 
                setRefetchs(!refetchs);
                toggler();
                }else{
   
                    setErrorMessage(result.saveErrorMessage);
                }
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.addVerifier}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
              
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setdojp(value);
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [name]: '',
        }));
      };

      const handleRegionChange = (e) => {
        setregion(e.target.value);
        setcountry(''); 
      };
      
      const isNumber = (event) => {
        const keyCode = event.keyCode || event.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 8 && keyCode !== 9) {
          event.preventDefault();
        }
      };

    return (
        <div>
    <form>

                <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_VerifierName">Verifier Name <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Verifier Name...' className="form-control form-control-xs" id="input_VerifierName" onChange={(e) => { setverifierName(e.target.value) }} />
                        <p style={{ color: 'red' }}>{formErrors.verifierName}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="ClientId">Client ID </label>
                        <input type="text" onKeyPress={isNumber} placeholder='Please Enter Client ID...'  className="form-control form-control-xs" id="ClientId" onChange={(e) => { setClientId(e.target.value) }}/>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address1">Address Line1 <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Address Line1...' className="form-control form-control-xs" id="input_Address1" onChange={(e) => { setaddress1(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.address1}</p>
                    </div>

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Address2">Address Line2(Opt)</label>
                        <input type="text" placeholder='Please Enter Address Line2...' className="form-control form-control-xs" id="input_Address2" onChange={(e) => { setaddress2(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.address2}</p>
                    </div>
                    </div>
                    <div className="form-row">

                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_City">Town/City <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Town/City...' className="form-control form-control-xs" id="input_City" onChange={(e) => { setcity(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.city}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Zip">Post Code/ZIP <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter PostCode/ZIP...' className="form-control form-control-xs" id="input_Zip" onChange={(e) => { setzipcode(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.zipcode}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_PhoneNumber">Phone Number <span className="text-danger font-weight-bold">*</span></label>
                        <input type="text" placeholder='Please Enter Phone Number...' className="form-control form-control-xs" id="input_PhoneNumber" onChange={(e) => { setphone(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.phone}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Email">Email Address <span className="text-danger font-weight-bold">*</span></label>
                        <input type="email" placeholder='Please Enter Email Address...' className="form-control form-control-xs" id="input_Email" onChange={(e) => { setemail(e.target.value) }}/>
                        <p style={{color:'red'}}>{formErrors.email}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                    <label htmlFor="input_Region">Region <span className="text-danger font-weight-bold">*</span></label>
                        <select  className="form-control form-control-sm" value={region}  onChange={handleRegionChange}>
                            {regionList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.region}</p>
                    </div>
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_Country">Country <span className="text-danger font-weight-bold">*</span></label>
                        <select id="input_Country"  className="form-control form-control-sm" value={country} onChange={(e) => { setcountry(e.target.value) }}>
                            {countryList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.country}</p>
                    </div>
                    </div>
                    <div className="form-row">
                    <div className="form-group col-md-6 mb-1">
                        <label htmlFor="input_DJP">Application Received Date <span className="text-danger font-weight-bold">*</span></label>
                        <input type="date" className="form-control form-control-xs" max={new Date().toISOString().split('T')[0]}  name ='dojp' value ={dojp} id="input_DJP" onChange={handleInputChange}/>
                        <p style={{color:'red'}}>{formErrors.dojp}</p>
                    </div>
                    </div>
                    {errorMessage ? (
                    <p style={{ color: "red" }}>{errorMessage}</p>
                  ) : (
                    <p></p>
                  )}

            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt="ACA Logo"></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={createVerifiers}>Submit</button>
            </div>
            </div>
    );
};

export default AddVerifier;