import React, { useState,useEffect} from 'react';
import config from "../../api/config";
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Button } from 'bootstrap';


const DownloadVerifier = () => {


    const navigate = useNavigate();
    const userID = localStorage.getItem("userID");
    const [approvedVerifierList, setApprovedVerifierList] = useState([]);





      useEffect(() => {
        function getApprovedVerifierList() {
            fetch(`${config.apiUrl}${apiconfig.getApprovedVerifier}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setApprovedVerifierList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getApprovedVerifierList();
    }, []);

    const currentDate = new Date();
    // Format the date as "YYYYMMDD-HHMMSS"
    const formattedDate =
      currentDate.getFullYear().toString().padStart(4, '0') +
      (currentDate.getMonth() + 1).toString().padStart(2, '0') +
      currentDate.getDate().toString().padStart(2, '0') +
      '-' +
      currentDate.getHours().toString().padStart(2, '0') +
      currentDate.getMinutes().toString().padStart(2, '0') +
      currentDate.getSeconds().toString().padStart(2, '0');


      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
      const fileName = formattedDate + '-ApprovedVerifierList';
      const customHeaders = [
          'Verifier Name',
          'Level',
          'Company',
          'Country',
          'Email',
          'Phone Number',
          'Approved Date',
        ];
        
  
      const exportToExcel = async () => {
          const formattedData = approvedVerifierList.map(item => ({
              'Verifier Name': item.verifierName,
              'Level': item.accredationLevel,
              'Company': item.company,
              'Country': item.country,
              'Email': item.email,
              'Phone Number': item.phoneNumber,
              'Approved Date':item.approvedDate,
            }));
          const ws = XLSX.utils.json_to_sheet(formattedData, { header: customHeaders });
          const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
          const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
        };

      

    return (
        <div>
            <Button onclick ={ (e)=>exportToExcel()}>Download</Button>

        </div>

    );
};

export default DownloadVerifier;