const AlertDialogBox = ({ properties }) => {
    return (
        <div>
            <form>
                <div className="form-row">               
                    <label style={{ paddingLeft: 10 }}>{properties.alertText}</label>
                </div>  
            </form>
            <div className="modal-footer">
                <img style={{marginLeft: -10}} className="mr-auto" src="dist/img/ACA-logo.png" width="100" alt='ACA Logo'></img>
                <button className="btn btn-success mt-auto" type="button" onClick={() => { properties.toggle() }}>{properties.buttonText}</button>
            </div>
        </div>
    )
}

export default AlertDialogBox