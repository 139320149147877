import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import axios from '../../../api/AxiosConfig';
import { useNavigate } from "react-router-dom";

const AddInvoice = ({ toggler,toggler1 }) => {

    const navigate = useNavigate();

    const [verifierExam, setVerifierExam] = useState("");
    const [verifierClient, setverifierClent] = useState("");
    const [amount, setAmount] = useState("");
    const [comments, setcomments] = useState("");
    const [formErrors, setFormErrors] = useState({});





    {/*Validation*/}
    const validateForm = (verifierExam,verifierClient,amount) => {
        const errors = {};
        if(!verifierExam){
            errors.verifierExam = 'Please select Verifier Exam!';
        }
        if(!verifierClient){
            errors.verifierClient = 'Verifier Client is required!';
        }
        if(!amount){
            errors.amount = 'Amount is required!';
        }
        return errors;
    }

    const upGrade = async () => {   
       
        try {
            const validationErrors = validateForm(verifierExam,verifierClient,amount);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){ 
                toggler1();      
                try {
                    await axios.post(`${apiconfig.addVerifier}`,
                    {
                        VerifierExam: verifierExam, 
                        VerifierClient: verifierClient,
                        Amount: amount,
                        Comments: comments,
            
                    },
                    {
                        headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${localStorage.getItem("token")}`
                      }})
                    .then((response) => {

                        // if(response.status === 200){
                        //     fetchData();
                        // }
                    })                           
                }
                catch (error) {
                    console.log("Error: ", error);
                    navigate("/error-page");
                }
                //toggleForAddUserModal();        
            }                                         
        }
        catch (error) {
            console.log("Error: ", error);
        }
    };
    return (
        <div>
    <form>
                   
                    <div className="form-row">
                        <label htmlFor="verifier-exam">Verifier Exam</label>
                        <select id="verifier-exam" className="form-control form-control-sm" value={verifierExam} onChange={(e) => setVerifierExam(e.target.value)}>
                            <option>Please Select Verifier Exam...</option>
                                <option>Verifier Exam 1</option>
                                <option>Verifier Exam 2</option>                         
                        </select>
                        <p style={{color:'red'}}>{formErrors.exam}</p>
                    </div>
                    <div className="form-row">
                        <label htmlFor="verifier-client">Verifier Client</label>
                    </div>
                        <div className="form-row">
                       <div className='mr-2'>
                        <input type="radio" name="verifier-client" value="option1" checked />
                        <span> XXXXXXXX12</span>
                        </div>
                        <div>
                        <input type="radio" name="verifier-client" value="option2"/>
                        <span> XXXXXXXX42</span>
                        </div>
                        </div>
                        <p style={{color:'red'}}>{formErrors.examType}</p>
                    
  
                    <div className="form-row">                    
                    <label htmlFor="amount">Amount</label>
                    <label></label>
                    </div>
                    <div className="form-row">
                        <label htmlFor="input_comments">Comments</label>
                        <textarea type="text" className="form-control form-control-xs" id="input_comments" onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                    </div>
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" onClick={upGrade}>Submit</button>
            </div>
            </div>
    );
};

export default AddInvoice;