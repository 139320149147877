import React from 'react';

const Table = ({ children, headerCells, setSortColumn, setIsSortTriggered, isSortTriggered, setSortOrder, sortOrder }) => {

    return (
        <div className="table-responsive noSwipe">
            <table className="table table-striped table-bordered dt-responsive nowrap tbl-hover" width="100%" cellSpacing="0">
                <thead>
                    <tr>
                        {
                            headerCells.map(cell => {
                                return (
                                    cell.isHidden === true
                                        ?
                                        <th hidden colSpan={cell.colSpan} style={cell.style} key={cell.id}>{cell.name}</th>
                                        :
                                        cell.hasIcon === true
                                            ?
                                            cell.isSortable === true
                                                ?
                                                <th
                                                    colSpan={cell.colSpan}
                                                    style={cell.style}
                                                    onClick={() => {
                                                        setSortColumn(cell.sortByColumnName);
                                                        setIsSortTriggered(!isSortTriggered);
                                                        setSortOrder(!sortOrder);
                                                    }}
                                                    key={cell.id}
                                                >
                                                    {cell.name}&nbsp;{cell.icon}
                                                </th>
                                                :
                                                <th
                                                    colSpan={cell.colSpan}
                                                    style={cell.style}
                                                    key={cell.id}
                                                >
                                                    {cell.name}&nbsp;{cell.icon}
                                                </th>
                                            :
                                            <th
                                                colSpan={cell.colSpan}
                                                style={cell.style}
                                                key={cell.id}
                                            >
                                                {cell.name}
                                            </th>
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );

};

export default Table;