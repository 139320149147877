import React, { useState, useEffect } from 'react'
import Layout from '../../../Layout'
import AdvancedSearch from '../../Common/AdvancedSearch'
import Pagination from '../../Common/Pagination'
import Selector from '../../Common/Selector'
import CustomTable from '../../Common/CustomTable'
import { TiArrowUnsorted } from 'react-icons/ti'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { numberOfEntriesPerPageOptions } from '../../Helper/NumberOfEntriesPerPageOptions'
import { useNavigate } from "react-router-dom"
import {ToastContainer} from 'react-toastify'
import AccreditationAirport from './AccreditationAirport'
import { TableHeaderCells } from './TableHeaderCells'

const AccreditationAirports = ({ refetchDash, setRefetchDash, setIsLoadingChildData }) => {
    
    /* States & Constants */
    const navigate = useNavigate()
    const [searchName, setSearchName] = useState('')
    const [accreditationAirportList, setAccreditationAirportList] = useState([])
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemOffset, setItemOffset] = useState(0)
    const [sortColumn, setSortColumn] = useState('')
    const [isSortTriggered, setIsSortTriggered] = useState(false)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('AirportName')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Airport Type')
    const [searchType, setSearchType] = useState('dropdown')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('default')
    const [dropdownOptions, setDropdownOptions] = useState([
        {text: 'Single Airport', value: 'Single Airport'},
        {text: 'Group Airport', value: 'Group Airport'},
    ])
    const [rejectedAirport, setRejectedAirport] = useState(true)
    const searchOptions = [
        {
            value: 'Airport Type', 
            text: 'Airport Type',
            type: 'dropdown',
            query: '&AirportType=',
            dropdownOptions: [
                {text: 'Single Airport', value: 'Single Airport'},
                {text: 'Group Airport', value: 'Group Airport'},
            ]
        },
        {
            value: 'Airport Name', 
            text: 'Airport Name', 
            type: 'text',
            query: '&AirportName='
        },
        {
            value: 'Status',
             text: 'Status', 
            type: 'dropdown',
            query: '&AirportStatus=',
            dropdownOptions: [
                {text: 'Approved', value: 'Approved'},
                {text: 'Pending', value: 'Pending'},
                {text: 'Rejected', value: 'Rejected'},
            ]
        },
        {
            value: 'Created By', 
            text: 'Created By', 
            type: 'text',
            query: '&CreatedBy=',
        },
        {
            value: 'Created Date', 
            text: 'Created Date', 
            type: 'date',
            query: '&CreatedDate=',
        },

    ]

    /* Methods */
    const handleChangeForExcludeRejected = () =>{        
        setRejectedAirport(!rejectedAirport)
    }
    const fetchData = async () => {
        let rejected = 1
        if(rejectedAirport=== true)
        {
            rejected = 0
        }
        try {
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            console.log('executing getAccreditationAirportList()...')
            setIsLoadingChildData(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getAccreditationAirportList}?IsIncludeRejectedAirport=${rejected}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getAccreditationAirportList()')
                const result = await response.json()
                let fetchedReporting = []
                for (let i = 0; i < result.length; i++) {
                    fetchedReporting.push(result[i])
                }
                setAccreditationAirportList(fetchedReporting)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAccreditationAirportList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoadingChildData(false)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => {
        fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria,rejectedAirport]);
    useEffect(() => {
        if(accreditationAirportList.length > 0){
            setPageCount(Math.ceil(accreditationAirportList[0].totalCount / itemsPerPage))
        }
    }, [accreditationAirportList])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(accreditationAirportList.length !== 0){
            if(accreditationAirportList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])

    
    return (
        <>
            <div className="">
                <h3 className="page-header-title">Search Airport Registrations</h3>
                <div className="row">
                    <div className="col-md-6 col-lg-7">
                        <AdvancedSearch searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} setPageNumber={setPageNumber} />
                        {
                            accreditationAirportList.length > 0
                                ?
                                <div className="align-self-center font-weight-bold">{accreditationAirportList[0].totalCount} items found</div>
                                :
                                <div className="align-self-center font-weight-bold">No items found</div>
                        }
                    </div>
                    <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                        <nav>
                            <div className="custom-control custom-checkbox custom-control-inline m-0">
                                <input className="custom-control-input" type="checkbox" onChange={handleChangeForExcludeRejected} checked={rejectedAirport} id="Rejected" />
                                <label className="custom-control-label" htmlFor="Rejected">Exclude Rejected</label>
                            </div>
                            <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                            <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                        </nav>
                    </div>
                </div>
                <CustomTable headerCells={TableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
                    {accreditationAirportList.map(accAirport => <AccreditationAirport key={accreditationAirportList.indexOf(accAirport)} accAirport={accAirport} searchName={searchName.toLowerCase()} refetch={refetch} setRefetch={setRefetch} refetchDash={refetchDash} setRefetchDash={setRefetchDash}/>)}
                </CustomTable>
                <div className="d-flex justify-content-end">
                    <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                </div>
            </div>
            <ToastContainer/>
        </>
    )
}

export default AccreditationAirports