import React, { useState,useEffect} from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Loader from '../../Common/Loader';


const EditAirportDocument = ({ toggler, airportID, airportDocument, refetchs, setRefetchs }) => {

    const navigate = useNavigate();
    const [documents, setDocuments] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [documentTypeList, setDocumentTypeList] = useState([]);
    const [documentType, setDocumentType] = useState("");
    const [comments, setcomments] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [showInput, setShowInput] = useState(false);
    const [image, setimages] = useState("");
    const [loading, setLoading] = useState(false);
    const userID = localStorage.getItem("userID");
    const [isOldDocumentSelected, setIsOldDocumentSelected] = useState(true);
    const [fileSize, setFileSize] = useState(0);
    const [isClicked, setIsClicked] = useState(true);




    {/*Validation*/}
    const validateForm = (documents,image,documentType,comments,isOldDocumentSelected,fileSize) => {
        const errors = {};
        if(!isOldDocumentSelected && !documents){
            errors.documents = 'Please choose a document.';
        }else {
            const allowedExtensions = [
                '.bmp', '.csv', '.doc', '.docx', '.htm', '.html', '.jpg',
                '.jpeg', '.msg', '.pdf', '.png', '.ppt', '.pptx', '.rtf',
                '.sig', '.tif', '.tiff', '.txt', '.vsd', '.xls', '.xlsx', '.zip'
            ];
    
            const fileExtension = image.slice(((image.lastIndexOf(".") - 1) >>> 0) + 2);
            const fileSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes
            if (!isOldDocumentSelected && !allowedExtensions.includes('.' + fileExtension.toLowerCase())) {
                errors.documents = 'This file format is not supported.';
            }
            else if (fileSize > fileSizeLimit) {
                errors.documents = 'File could not be uploaded as it is exceeding the maximum file size of 20 MB.';
            }
        }
        if(!documentType){
            errors.documentType = 'Please select a value.';
        }
        if(!comments){
            errors.comments = 'Please enter a value.';
        }
        return errors;
    }

    //get DetailsByID
    useEffect(() => {
    fetch(`${config.apiUrl}${apiconfig.getAirportDocumentsByID}${airportDocument.id}`,{
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          }, 
    })
        .then((response) => response.json())
        .then((data) => {
            if (data.name) {
                setIsOldDocumentSelected(true);
            } else {
                setIsOldDocumentSelected(false);
            }
            setDocumentName(data.name);
            setDocumentType(data.documentTypeID);
            setcomments(data.description);

        }).catch((err) => {
            navigate("/error-page");
        });
    }, [airportDocument.id])

    const editDocument = async () => {
        setIsClicked(!isClicked);
        const requestBody = {
            "id": airportDocument.id,
            "airportID": airportID,
            "documentTypeID": documentType,
            "imageName":image,
            "docContent":documents,
            "description": comments.trim(),
            "userID": userID
        };
        const validationErrors = validateForm(documents,image,documentType,comments.trim(),isOldDocumentSelected,fileSize);
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.addEditAirportDocument}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              }
            ).then((response) => {
              return response.json();
            });
            if(response.saveErrorMessage === 'Document updated successfully.')
            {
                toast.success('Document updated successfully.', {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Slide
                }); 
            setRefetchs(!refetchs);
            toggler();
            }
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }else{
            setIsClicked(true);
        }
      };

    useEffect(() => {
        function getDocumentTypeList() {
            fetch(`${config.apiUrl}${apiconfig.getAirportDocumentTypes}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            }).then(res => res.json())
                .then((data) => {
                    setDocumentTypeList(data);
                }).catch((err) => {
                    console.log("Error: ", err);
                    navigate("/error-page");
                });
        }
        getDocumentTypeList();
    }, []);

    const documentTypeOptions = [
        { value: '', text: 'Please select Document Type...' },
        { value: 'Accreditation certificate', text: 'Accreditation certificate'},
        { value: 'Feedback form', text: 'Feedback form'}
    ]

    const handleDeleteClick = () => {
        setDocumentName('');
        setIsOldDocumentSelected(false);
        setShowInput(true);
      };

      function fileToBase64(file, callback) {
        const reader = new FileReader();
        reader.onload = function(event) {
          const base64String = event.target.result.split(',')[1]; // Extract the base64 data
          callback(base64String);
        };
        reader.readAsDataURL(file);
      }
      const handleFileChange = (event) => {
        setFormErrors({
            ...formErrors,
            documents: '',
        });
        setLoading(true); 
        const selectedFile = event.target.files[0];
        const fileSize = selectedFile.size;
        setFileSize(fileSize);
        setimages(selectedFile.name);
        if (selectedFile) {
            fileToBase64(selectedFile, function(base64String) {
                setDocuments(base64String);
            });
          }
          setLoading(false); 
      };

      const downloadFile = (url, filename) => {
        window.open(url);
    }
    return (
        <>
        {loading && <Loader />}
        <div>
    <form>
                   
                    <div className="form-row">
                    <label htmlFor="document-type">Document Type</label>
                    <select  className="form-control form-control-sm" value={documentType}  onChange={(e) => { setDocumentType(e.target.value) }}>
                            {documentTypeList.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                        <p style={{color:'red'}}>{formErrors.documentType}</p>
                    </div>
                    {showInput ?
                    (<div className="form-row">
                        <label htmlFor="document">Document</label>
                        <input type="file" className="form-control form-control-xs"   onChange={handleFileChange}/>
                        <p style={{color:'red'}}>{formErrors.documents}</p>
                    </div>
                    ) :
                    (<div className="form-row mt-1 d-flex align-items-center">
                            <label className='mb-0' htmlFor="saved_document">Document :</label>
                            <span><a style={{color:'blue',cursor:'pointer'}} onClick={() => { downloadFile(airportDocument.filePath,airportDocument.name); }}>&nbsp;{documentName}</a></span>
                             &nbsp; <span className="icon mdi mdi-close"  style={{color:'red', cursor:'pointer'}} onClick={handleDeleteClick}></span>
                    </div>
                    )}

                    <div className="form-row">
                        <label htmlFor="input_comments">Comments</label>
                        <textarea type="text" value={comments} placeholder='Please enter the comments...' className="form-control form-control-xs"  onChange={(e) => { setcomments(e.target.value) }} style={{ height: 120}}/>
                        <p style={{color:'red'}}>{formErrors.comments}</p>
                    </div>
   
                   
                    
            </form>
            <div className="modal-footer">
            <img className="mr-auto" src="dist/img/ACA-logo.png" width="150" alt='ACA Logo'></img>
            <button className="btn btn-secondary mt-auto" type="button" onClick={toggler}>Cancel</button>
            <button className="btn btn-success mt-auto" type="button" disabled={!isClicked} onClick={editDocument}>Submit</button>
            </div>
            </div>
            </>
    );
};

export default EditAirportDocument;