import { useState } from "react";
import {Tooltip as ReactTooltip} from 'react-tooltip';

const HoverableProgressBar = ({ OverAllPercentage,FootprintPercentage,ManagementPercentage,id }) => {

 
    return (
      <>
 <div  id={id}>
      <div className="d-flex align-items-center justify-content-between">
          <span className="text-success">{`${OverAllPercentage}%`}</span> <span>100%</span>
      </div>
      <div className="progress-sm">
        <div className="progress-bar-sm badge-success" role="progressbar" style={{ width: `${OverAllPercentage}%` }}>  
        </div>
      </div>
    <ReactTooltip
      anchorId={id}
      effect="solid"
      place="bottom"
      variant="info"
      borderColor="white"
      
      // multiline={true}
      // className="popover-content"
    >
      <table className="table table-borderless post-table table-center mb-0">
        <tbody>
          <tr>
            <td>
              <h6 className="mb-0">Footprint Progress</h6>
            </td>
            <td style={{width:'100px'}}>
              <div className="d-flex align-items-center justify-content-between">
                <span className="text-success"><small>{`${FootprintPercentage}%`}</small></span> <span><small>100%</small></span>
              </div>
              <div className="progress-sm">
                <div className="progress-bar-sm badge-success" role="progressbar" style={{ width: `${FootprintPercentage}%` }}></div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <h6 className="mb-0">Management Progress</h6>
            </td>
            <td style={{width:'100px'}}>
              <div className="d-flex align-items-center justify-content-between ">
                <span className="text-success"><small>{`${ManagementPercentage}%`}</small></span> <span><small>100%</small></span>
              </div>
              <div className="progress-sm"> 
                <div className="progress-bar-sm badge-success" role="progressbar" style={{ width: `${ManagementPercentage}%` }}>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </ReactTooltip>
  </div>
          </>

    );
  };

  export default HoverableProgressBar;
  