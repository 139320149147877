import React from 'react';
import Tab from './Tab';
import IsAccessForVerifierCombination1 from '../../Common/IsAccessForVerifieCombination1';

const TabList = ({ activeTab, setActiveTab ,isExternalVerifierUser}) => {
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    return (
        <ul className="nav nav-tabs nav-tabs-classic" role="tablist">
            <Tab tabName="Accreditations" iconClassName="icon icon-left icon mdi mdi-account-box-mail" activeTab={activeTab} setActiveTab={setActiveTab} />
            <Tab tabName="Exams" iconClassName="icon icon-left icon mdi mdi-graduation-cap" activeTab={activeTab} setActiveTab={setActiveTab} />
            <Tab tabName="Invoices" iconClassName="icon icon-lefticon mdi mdi-file" activeTab={activeTab} setActiveTab={setActiveTab} />
            {/* <Tab tabName="Verifications" iconClassName="icon icon-left mdi mdi-account-box-mail" activeTab={activeTab} setActiveTab={setActiveTab} /> */}
            <Tab tabName="Sponsors" iconClassName="icon icon-left mdi mdi-account-box-mail" activeTab={activeTab} setActiveTab={setActiveTab} />
            <Tab tabName="Documents" iconClassName="icon icon-left icon mdi mdi-file-plus" activeTab={activeTab} setActiveTab={setActiveTab} />
            {isReadOnlyAccess1  === "FullAccess" ? 
            <Tab tabName="Notes" iconClassName="icon icon-left icon icon mdi mdi-comment-alt-text" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
            {isReadOnlyAccess1  === "FullAccess" || isExternalVerifierUser? 
            <Tab tabName="Communications" iconClassName="icon icon-left icon mdi mdi-comments" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
            {isReadOnlyAccess1  === "FullAccess" ? 
            <Tab tabName="Events" iconClassName="icon icon-left mdi mdi-file-text" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
        </ul>
    );
};

export default TabList;