import React, { useState } from 'react'
import { AiOutlineMenu } from "react-icons/ai"
import { CgClose } from "react-icons/cg"
import { useSignOut } from 'react-auth-kit'
import { useNavigate } from 'react-router-dom'
import NotificationOnHeader from '../Components/Notification/NotificationOnHeader'

const Header = ({ sideMenuToggler, sideMenuState }) => {


    let showMenuItemsForAdmin = false
    let showMenuItemsForAccreditation = false
    let showMenuItemsForVC = false
    let showMenuForAdministrator = false
    let showMenuItemsForACI = false
    let showMenuItemsForVerifieronly = false

    const userRoles = JSON.parse(localStorage.getItem("roles"))

    const isAdminOrVerifierCoordinator = userRoles.some(role => role.name === "Administrator" || role.name === "Airport User" || role.name === "Verifier User" || role.name === "Account Manager" || role.name === "Senior Account Manager" || role.name === "ACI" || role.name === "ACI-E");
    


    if(userRoles.length !== 0){
        userRoles.map((role) => {
            if(role.name === "Administrator"){
                showMenuItemsForAdmin = true
                showMenuItemsForAccreditation = true
                showMenuItemsForVC = true
                showMenuForAdministrator =true
                showMenuItemsForACI = true
                showMenuItemsForVerifieronly = true
            }
            if(role.name  === 'Verifier Coordinator'){
                showMenuItemsForVC = true
                showMenuForAdministrator =true
                showMenuItemsForVerifieronly = true  
            }
            if (role.name  === 'Account Manager' || role.name  === 'Senior Account Manager') {
                showMenuItemsForAccreditation = true
                showMenuForAdministrator =true
                showMenuItemsForACI = true
            }
            if (role.name  === 'ACI' || role.name  === 'ACI-E') {
                showMenuItemsForVC = true  
                showMenuItemsForAccreditation = true  

            }
        })
    }
    const externalUserID=userRoles.length === 1?userRoles[0].internalUserID:0;
    
    const isAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User';
    const isPrimaryAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User';
    const isExternalVerifierUser = userRoles.length === 1 && userRoles[0].name === "Verifier User";
    const isSingleCheck = userRoles.length === 1 && userRoles[0].returnType === 'Single User';
    const isGroupCheck = userRoles.length === 1 && userRoles[0].returnType === 'Group User';

    const signOut = useSignOut()
    const navigate = useNavigate()

    const onLogoutButtonClick = () => {
        localStorage.clear()
        sessionStorage.clear()
        signOut()
        navigate('/login')
        localStorage.removeItem("roles")
        localStorage.removeItem("userID")
    }

    const userDisplayName = localStorage.getItem('userDisplayName')
    const rolesListFromSession = JSON.parse(localStorage.getItem("roles"))

    if(userDisplayName === null || userDisplayName === "null"){
        signOut()
        navigate('/login')
    }

    return (
        <header>
            <nav className="navbar navbar-expand fixed-top be-top-header">
                <div className="container-fluid">
                    <div className="be-navbar-header">
                        <a className="nav-link be-toggle-left-sidebar" href="#"><span className="icon mdi mdi-menu"> <span className='sr-only'>Menu</span></span>
                       </a>
                    </div>
                    <div className="be-right-navbar">
                        <ul className="nav navbar-nav float-right be-user-nav">
                            <li className="nav-item dropdown">
                                <a className="nav-link pr-0 dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false"><img src={require('../img/loggdin.png')} width="32" height="32" alt="Avatar"></img><span className="user-name">{userDisplayName}</span></a>
                                <div className="dropdown-menu">
                                    <div className="user-info">
                                        <div className="user-name">{userDisplayName}</div>
                                        {/* <div className="user-position online">Available</div> */}
                                    </div>
                                    <a className="dropdown-item" href="/ChangePassword"><span className="icon mdi mdi-settings"></span>Change Password</a>
                                    <a className="dropdown-item" style={{cursor:'pointer'}} onClick={() => { onLogoutButtonClick() }}><span className="icon mdi mdi-power"></span>Logout</a>
                                </div>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav float-right be-icons-nav">
                            {isAdminOrVerifierCoordinator &&
                                (
                                <NotificationOnHeader/>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="be-left-sidebar">
                <div className="left-sidebar-wrapper"><a className="left-sidebar-toggle" href="/">Dashboard</a>
                    <div className="left-sidebar-spacer">
                        <div className="left-sidebar-scroll">
                            <div className="left-sidebar-content">
                                <ul className="sidebar-elements">
                                  {isExternalVerifierUser ? 
                                    <li><a href='/VerifierDashboard'><i className="icon icon mdi mdi-home mdi-4x"></i><span>Home</span></a></li>
                                    :
                                    isAirportUser && isGroupCheck ?
                                    <li><a href='/AirportGroupDashboard'><i className="icon icon mdi mdi-home mdi-4x"></i><span>Home</span></a></li>
                                    : 
                                    isAirportUser && isSingleCheck ?
                                    <li><a href='/AirportGroupDashboard'><i className="icon icon mdi mdi-home mdi-4x"></i><span>Home</span></a></li>
                                    :
                                    <li><a href='/'><i className="icon icon mdi mdi-home mdi-4x"></i><span>Home</span></a></li>

                                  }
                                  { isExternalVerifierUser ?
                                    <li className="parent"><a href="#"><img src={require('../img/All IconsAsset 2@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span>Verification</span></a>
                                            <ul className="sub-menu">
                                                <li>
                                                  <a href={`/viewverifier/${externalUserID}`}>My Profile</a>
                                                </li>
                                            </ul>
                                        </li>
                                    :
                                    <></>
                                  }
                                   {showMenuItemsForAccreditation ?
                                        <li className="parent"><a href="#"><img src={require('../img/All IconsAsset 1@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span>Accreditation</span></a>
                                          <ul className="sub-menu">
                                            <li>
                                                { showMenuItemsForACI ?
                                                <>
                                                <a href="/AccreditationDashboard">Accreditation Dashboard</a>
                                                <a href="/Airport">Airports</a>
                                                <a href="/AirportGroups">Airport Groups</a>
                                                <a href="/ReportingPeriod">Reporting Period</a>
                                                <a href="/FeeDetails">Fee Details</a>
                                                </>
                                                :
                                                <>
                                                {/* <a href="/AirportGroups">Airport Group</a> */}
                                                <a href="/Airport">Airports</a>
                                                </>
                                                }
                                            </li>
                                          </ul>
                                        </li>
                                        :
                                        <></>
                                    }
                                    {showMenuItemsForVC ?
                                        <li className="parent"><a href="#"><img src={require('../img/All IconsAsset 2@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span>Verification</span></a>
                                            <ul className="sub-menu">
                                                <li>
                                                {showMenuItemsForVerifieronly ? 
                                                  <>
                                                  <a href="/verifiers">Verifiers</a>
                                                  <a href="/sponsors">Sponsors</a>
                                                  <a href="/exams">Exams</a>
                                                  <a href="/invoices">Invoices</a>
                                                  <a href="/verifierexamfees">Fees</a>
                                                  </>
                                                  :
                                                  isExternalVerifierUser ? 
                                                  <a href="/verifiers">Verifiers</a>
                                                  :
                                                  <a href="/verifiers">Verifiers</a>
                                                }
                                                </li>
                                            </ul>
                                        </li>
                                        :
                                        <></>
                                  }
                                  {showMenuItemsForAdmin ? 
                                          <>
                                              <li className="parent"><a href="#"><img src={require('../img/All IconsAsset 4@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span>Participation</span></a>
                                                  <ul className="sub-menu">
                                                      <li>
                                                          <a href="">Link</a>
                                                      </li>
                                                  </ul>
                                              </li>
                                              <li className="parent"><a href="#"><img src={require('../img/All IconsAsset 5@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span>Annual Carbon Reporting</span></a>
                                                  <ul className="sub-menu">
                                                      <li><a href="">Link</a>
                                                      </li>
                                                  </ul>
                                              </li>
                                          </>
                                          :
                                          <></>
                                  }
                                  {showMenuForAdministrator ?
                                          <li className="parent">
                                              <a href=""><img src={require('../img/All IconsAsset 6@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span>Administrator</span></a>
                                              <ul className="sub-menu">
                                                  <li>
                                                      <a href="/users">Users</a>
                                                  </li>
                                                  <li>
                                                      <a href="/roles">Roles</a>
                                                  </li>
                                                  <li>
                                                      <a href="/permissions">Permissions</a>
                                                  </li>
                                                  <li>
                                                      <a href="/emails">Email Log</a>
                                                  </li>
                                              </ul>
                                          </li>
                                          : 
                                          <></>
                                  }
                                  { isPrimaryAirportUser ? 
                                            <li className="parent"><a href="charts.html"><i className="icon mdi mdi-chart-donut"></i><span>Manage Account</span></a>
                                                  <ul className="sub-menu">
                                                      <li>
                                                      <a href="/AddAirportInvoice">Invoice Details</a>
                                                      </li>
                                                      <li>
                                                      <a href="/AirportUsers">View All Users</a>
                                                      </li>
                                                  </ul>
                                            </li>
                                            :
                                            <></>
                                  }
                                {/* { isExternalVerifierUser ? 
                                            <li className="parent"><a href="charts.html"><i className="icon mdi mdi-chart-donut"></i><span>Manage Account</span></a>
                                                  <ul className="sub-menu">
                                                      <li>
                                                      <a >Link</a>
                                                      </li>
                                                      <li>
                                                      <a >Link</a>
                                                      </li>
                                                  </ul>
                                            </li>
                                            :
                                            <></>
                                  } */}
                                 <li className="parent"><a href="#"><img src={require('../img/All IconsAsset 3@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span>Document Library</span></a>
                                      <ul className="sub-menu">
                                          <li>
                                              <a href="/programmeDocuments">Programme Documents</a>
                                          </li>
                                      </ul>
                                  </li>
                                  <li className="parent"><a href="#"><img src={require('../img/All IconsAsset 7@3x.png')} alt="ACALogo" style={{width:'20px',height:'20px'}} />&nbsp;&nbsp;<span> Help & Settings</span></a>
                                      <ul className="sub-menu">
                                          <li>
                                              <a href="/ChangePassword">Change Password</a>
                                          </li>
                                          <li>
                                              <a style={{cursor:'pointer'}} onClick={() => { onLogoutButtonClick() }}>Logout</a>
                                          </li>
                                      </ul>
                                  </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header