import React, { useState, useEffect } from 'react';
import config from "../../../api/config";
import apiconfig from '../../../api/apiconfig';
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Layout from '../../../Layout';

const AddAirportInvoice = () => {
    const navigate = useNavigate();
    {/* STATES */ }
    const [refetch, setRefetch] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [invoiceaddress1, setInvoiceaddress1] = useState("");
    const [invoiceaddress2, setInvoiceaddress2] = useState("");
    const [country, setcountry] = useState("");
    const [state, setState] = useState("");
    const [city, setcity] = useState("");
    const [postalcode, setPostalcode] = useState("");
    const [forAttention, setForAttention] = useState("");
    const [phone, setphone] = useState("");
    const [reference, setReference] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const userID = localStorage.getItem("userID");
    const [isUpdate, setIsUpdate] = useState(false);

    const rolesListFromSession = JSON.parse(localStorage.getItem("roles"));
    const isSingleCheck = rolesListFromSession.length === 1 && rolesListFromSession[0].returnType === 'Single User';
    const isGroupCheck = rolesListFromSession.length === 1 && rolesListFromSession[0].returnType === 'Group User';


    const getCountryList = async () => {
 
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getCountry}${0}`, {
                method: 'GET',
                headers: {

                    Authorization: `Bearer ${localStorage.getItem("token")}`

                }
            });
            if (response.ok) {
                var result = await response.json();
                setCountryList(result);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getCountry}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }

    //get DetailsByID
    const getDetailByID = async () => {
        try{
          const response =  await fetch(`${config.apiUrl}${apiconfig.getAirportInvoiceDetails}${userID}`, {
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
            });
            if (response.ok) {
                var data = await response.json();
                if(data.companyName !== '' || data.companyName !== null){
                    setIsUpdate(true);
                    }
                    setCompanyName(data.companyName);
                    setInvoiceaddress1(data.address1);
                    setInvoiceaddress2(data.address2);
                    setcountry(data.countryID);
                    setState(data.state);
                    setcity(data.city);
                    setPostalcode(data.postalCode);
                    setForAttention(data.attention);
                    setphone(data.phoneNo);
                    setReference(data.refNo);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getAirportInvoiceDetails}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }catch(error){
                console.log(error.message);
                navigate(`/error-page`); 
            }
        }

    useEffect(()=>{
        getCountryList();
        getDetailByID();
    },[])

    //get DetailsByID
    // useEffect(() => {
    //     fetch(`${config.apiUrl}${apiconfig.getAirportInvoiceDetails}${userID}`,
    //     {
    //         headers: {
    //             "Content-Type": "application/json",
    //             'Authorization': `Bearer ${localStorage.getItem("token")}`
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((data) => {
    //             if(data.companyName !== '' || data.companyName !== null){
    //             setIsUpdate(true);
    //             }
    //             setCompanyName(data.companyName);
    //             setInvoiceaddress1(data.address1);
    //             setInvoiceaddress2(data.address2);
    //             setcountry(data.countryID);
    //             setState(data.state);
    //             setcity(data.city);
    //             setPostalcode(data.postalCode);
    //             setForAttention(data.attention);
    //             setphone(data.phoneNo);
    //             setReference(data.refNo);
    //         }).catch((err) => {
    //             navigate("/error-page");
    //         });
    // }, [])

    {/*Validation*/}
    const validateForm = (company, address1, address2, country,state,city,postalcode,forAttention,phone,reference) => {
        const errors = {};
        if(!company){
            errors.companyName = 'Please enter a value.';
        } else if(company.length > 256){
            errors.companyName = 'Maximum of 256 characters allowed.';
        }

        if(!address1){
            errors.invoiceaddress1 = 'Please enter a value.';
        }else if(address1.length > 256){
            errors.invoiceaddress1 = 'Maximum of 256 characters allowed.';
        }

        if(address2.length > 0){
        if(address2.length > 256){
            errors.invoiceaddress2 = 'Maximum of 256 characters allowed.';
        }
        }
        // if(!country){
        //     errors.country = 'Please select a value.';
        // }

        if(state.length>0){
        if(state.length > 256){
            errors.state = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(state)) {
            errors.state = 'Special characters are not allowed.';
        }
        }
        if(city.length>0){
        if(city.length > 256){
            errors.city = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(city)) {
            errors.city = 'Special characters are not allowed.';
        }
        }

        if(!postalcode){
            errors.postalcode = 'Please enter a value.';
        }else if(postalcode.length > 256){
            errors.postalcode = 'Maximum of 256 characters allowed.';
        }else if (!/^[a-zA-Z0-9\s]+$/.test(postalcode)) {
            errors.postalcode = 'Special characters are not allowed.';
        }

        if(!forAttention){
            errors.forAttention = 'Please enter a value.';
        }else if(forAttention.length > 256){
            errors.forAttention = 'Maximum of 256 characters allowed.';
        }
        if(!phone){
            errors.phone = 'Please enter a value.';
        }else {
            const phoneRegex = /^\+[0-9]+([\s-]?[0-9]+)*$/;
            if (!phoneRegex.test(phone)) {
              errors.phone = 'Please enter a valid phone number.';
            }
        }
        if(reference.length>0){
        if(reference.length > 256){
            errors.reference = 'Maximum of 256 characters allowed.';
        }
        }


        return errors;
    }

    const handleCancel= ()=>{
        getDetailByID();
    }

    const addAirportInvoice = async () => {

        const requestBody = {
            "userID": userID,
            "applicationID": userID,
            "companyName": companyName.trim(),
            "address1": invoiceaddress1.trim(),
            "address2": invoiceaddress2.trim(),
            "state": state.trim(),
            "city": city.trim(),
            "countryID": country,
            "postalCode": postalcode.trim(),
            "phoneNo": phone.trim(),
            "refNo": reference.trim(),
            "attention": forAttention,
            "actionType": isUpdate ? "Edit" : "Add"
        };
        const validationErrors = validateForm(companyName.trim(), invoiceaddress1.trim(), invoiceaddress2.trim(),country,state.trim(),city.trim(),postalcode.trim(),forAttention,phone.trim(),reference.trim());
            setFormErrors(validationErrors);
            if(Object.keys(validationErrors).length === 0){
          try {
            const response = await fetch(
              `${config.apiUrl}${apiconfig.addAirportInvoice}`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
              });
              if (response.ok) {
                var result = await response.json();
                if(result.saveErrorMessage === 'Invoice Data saved successfully.')
                {
                    toast.success('Invoice Data saved successfully.', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Slide
                    }); 
                }else  if(result.saveErrorMessage === 'Invoice Data updated successfully.')
                {
                    toast.success('Invoice Data updated successfully.', {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Slide
                    }); 
                }
                else{
   
                    setErrorMessage(result.saveErrorMessage);
                }
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.addAirportInvoice}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
              
          } catch (error) {
            console.log("Error: ", error);
            navigate("/error-page");
          }
        }
      };

    return (
        <Layout>
            <div className="be-content">
                <div className="main-content container-fluid p-0">
                    <div className="page-header ph-orange mb-4">
                        <div className="container-header">
                            <div className="row" style={{padding : '15px'}}>
                                <div className="col-2">
                                    <img src={require('../../../img/ACA-logo.png')} alt="ACALogo" />
                                </div>
                                <div className="col-8 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Invoice Details</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                    {/* <a href="/login"><button className="btn" type="button" style={{ background: '#184086', color: '#FFFFFF' }}><i className="icon icon-left mdi mdi-arrow-left"></i>Back to Login</button></a> */}
                                </div>
                            </div>
                            <nav aria-label="breadcrumb" role="navigation">
                            <ol className="breadcrumb mb-0 mt-4">
                                { isGroupCheck ? 
                                    <li className="breadcrumb-item"><a href="/AirportGroupDashboard"><span className="icon mdi mdi-home"></span></a></li>
                                    :
                                    isSingleCheck ?
                                    <li className="breadcrumb-item"><a href="/AirportGroupDashboard"><span className="icon mdi mdi-home"></span></a></li>
                                    :
                                    <></>
                                }
                                <li className="breadcrumb-item active">Invoice Details</li>
                            </ol>
                        </nav>
                        </div>
                    </div>
                    <div className="container-header">
                        {/* <h3 className="page-header-title ml-4">Edit Invoice Details</h3> */}
                        {/* <div className="row">
                            <div className="col-md-6 col-lg-7">
                                <p className="mb-2 font-weight-normal">Only <strong>New</strong> Verifiers should register, existing Verifiers (Active/Removed) should contact the ACA Admin Team via address@domain.com</p>
                            </div>
                        </div> */}
                        <div className="modal-body">
                        <form>

                            <div className="form-row">
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_CompanyName">Company Name <span className="text-danger font-weight-bold">*</span></label>
                                <input type="text"  value ={companyName} placeholder='Please Enter Company Name...' className="form-control form-control-xs" id="input_CompanyName" onChange={(e) => { setCompanyName(e.target.value) }} />
                                <p style={{ color: 'red' }}>{formErrors.companyName}</p>
                            </div>
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_Address1">Invoice Address Line 1 <span className="text-danger font-weight-bold">*</span></label>
                                <input type="text" value ={invoiceaddress1} placeholder='Please Enter Invoice Address Line 1...' className="form-control form-control-xs" id="input_Address1" onChange={(e) => { setInvoiceaddress1(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.invoiceaddress1}</p>
                            </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_Address2">Invoice Address Line 2(Opt)</label>
                                <input type="text" value ={invoiceaddress2} placeholder='Please Enter Invoice Address Line 2...' className="form-control form-control-xs" id="input_Address2" onChange={(e) => { setInvoiceaddress2(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.invoiceaddress2}</p>
                            </div>
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_Country">Country</label>
                                <select  className="form-control form-control-sm" value={country} onChange={(e) => { setcountry(e.target.value) }}>
                                    {countryList.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.text}
                                        </option>
                                    ))}
                                </select>
                                <p style={{color:'red'}}>{formErrors.country}</p>
                            </div>
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_State">State</label>
                                <input type="text" value ={state} placeholder='Please Enter State...' className="form-control form-control-xs" id="input_State" onChange={(e) => { setState(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.state}</p>
                            </div>
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_City">City</label>
                                <input type="text" value ={city} placeholder='Please Enter City...' className="form-control form-control-xs" id="input_City" onChange={(e) => { setcity(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.city}</p>
                            </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_Zip">Postal Code <span className="text-danger font-weight-bold">*</span></label>
                                <input type="text" value ={postalcode} placeholder='Please Enter Postal Code...' className="form-control form-control-xs" id="input_Zip" onChange={(e) => { setPostalcode(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.postalcode}</p>
                            </div>
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_Attention">For the attention of <span className="text-danger font-weight-bold">*</span></label>
                                <input type="text" value ={forAttention} placeholder='Please Enter For the attention of...' className="form-control form-control-xs" id="input_Attention" onChange={(e) => { setForAttention(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.forAttention}</p>
                            </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_PhoneNumber">Phone Number <span className="text-danger font-weight-bold">*</span></label>
                                <input type="text" value ={phone} placeholder='Please Enter Phone Number...' className="form-control form-control-xs" id="input_PhoneNumber" onChange={(e) => { setphone(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.phone}</p>
                            </div>
                            <div className="form-group col-md-6 mb-1">
                                <label htmlFor="input_Reference">Reference / Purchase order number</label>
                                <input type="text" value ={reference} placeholder='Please Enter Reference / Purchase order number...' className="form-control form-control-xs" id="input_Reference" onChange={(e) => { setReference(e.target.value) }}/>
                                <p style={{color:'red'}}>{formErrors.reference}</p>
                            </div>

                            </div>
                            {errorMessage ? (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                        ) : (
                            <p></p>
                        )}

                        </form>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary mt-auto" type="button" onClick={handleCancel}>Cancel</button>
                            <button className="btn btn-success mt-auto" type="button" onClick={addAirportInvoice}>Submit</button>
                        </div>
                    </div>
                </div>
                <ToastContainer />  
            </div>
        </Layout>
    );
};

export default AddAirportInvoice;