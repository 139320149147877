import { useState } from "react";
import { Label } from 'reactstrap';



const AirportGroupUsersList = ({airportUser}) => {

return (
        <tr>
            <td>{airportUser.displayName}</td>
            <td>{airportUser.address1}</td>
            <td>{airportUser.email}</td>
            {airportUser.isPrimary === 0 ?
            <td>No</td>
            :
            <td>Yes</td>
            }
            <td>{airportUser.status}</td>
        </tr>

);

};

export default AirportGroupUsersList;
