
const ConvertToRelativeDateString = (usersEventDate) => {
    let relativeDateString = '';

    let currentDate = new Date();
    let currentYear = parseInt(currentDate.getUTCFullYear());
    let currentMonth = parseInt(currentDate.getUTCMonth() + 1); //remember that month in Javascript's Date object is 0 indexed, meaning january is 0 and so on...
    let currentDay = parseInt(currentDate.getUTCDate());
    let currentHours = parseInt(currentDate.getUTCHours());
    let currentMinutes = parseInt(currentDate.getUTCMinutes());
    let currentSeconds = parseInt(currentDate.getUTCSeconds());

    let eventDate = usersEventDate.substring(0, usersEventDate.indexOf('T'));
    let eventTime = usersEventDate.substring(usersEventDate.indexOf('T') + 1, usersEventDate.length);
    let eventYear = parseInt(eventDate.substring(0, 4));
    let eventMonth = parseInt(eventDate.substring(6, 7));
    let eventDay = parseInt(eventDate.substring(8,10));
    let eventHours = parseInt(eventTime.substring(0, 2));
    let eventMinutes = parseInt(eventTime.substring(3, 5));
    let eventSeconds = parseInt(eventTime.substring(6, 8));

    currentYear !== eventYear
        ?
        currentYear - eventYear > 1
            ?
            ((currentYear - eventYear) - 1) > 1
                ? 
                relativeDateString = ((currentYear - eventYear) - 1) + ' years ago'    
                :
                relativeDateString = ((currentYear - eventYear) - 1) + ' year ago'
            :
            relativeDateString = 'Last year'
        :
        currentMonth !== eventMonth
            ?
            currentMonth - eventMonth > 1
                ?
                ((currentMonth - eventMonth) - 1) > 1
                    ? 
                    relativeDateString = ((currentMonth - eventMonth) - 1) + ' months ago'
                    :
                    relativeDateString = ((currentMonth - eventMonth) - 1) + ' month ago'
                :
                relativeDateString = 'Previous month'
            :
            currentDay !== eventDay
                ?
                currentDay - eventDay > 1
                    ?
                    ((currentDay - eventDay) - 1) > 1
                        ? 
                        relativeDateString = ((currentDay - eventDay) - 1) + ' days ago'
                        :
                        relativeDateString = ((currentDay - eventDay) - 1) + ' day ago'
                    :
                    relativeDateString = 'Yesterday'
                :
                currentHours !== eventHours
                    ?
                    currentHours - eventHours > 1
                        ?
                        ((currentHours - eventHours) - 1) > 1
                            ? 
                            relativeDateString = ((currentHours - eventHours) - 1) + ' hours ago'     
                            :
                            relativeDateString = ((currentHours - eventHours) - 1) + ' hour ago'
                        :
                        relativeDateString = 'Last hour'
                    :
                    currentMinutes !== eventMinutes
                        ?
                        currentMinutes - eventMinutes > 1
                            ?
                            relativeDateString = (currentMinutes - eventMinutes) + ' minutes ago'
                            :
                            relativeDateString = (currentMinutes - eventMinutes) + ' minute ago'
                        :
                        currentSeconds !== eventSeconds
                            ?
                            currentSeconds - eventSeconds > 1
                                ?
                                relativeDateString = (currentSeconds - eventSeconds) + ' seconds ago'
                                :
                                relativeDateString = (currentSeconds - eventSeconds) + ' second ago'
                            :
                            relativeDateString = 'some moments ago'
    return relativeDateString;
};

export default ConvertToRelativeDateString;