import React, { useState, useEffect, useRef } from 'react'
import Layout from '../../../Layout'
import TabList from './TabList'
import LeftNavigationMenu from './LeftNavigationMenu'
import ApplicationOverview from './ApplicationOverview'
import SelectAccreditationLevel from './SelectAccreditationLevel'
import ApplicationSummary from './ApplicationSummary'
import OtherDetails from './OtherDetails'
import Section from './Section'
import { Slide, ToastContainer, toast } from 'react-toastify'
import Loader from '../../Common/Loader'
import VerificationSummary from './VerificationSummary'
import config from '../../../api/config'
import apiconfig from '../../../api/apiconfig'
import { useParams } from 'react-router-dom'
import CommonCustomModal from '../../Common/CommonCustomModal'
import AlertDialogBox from '../../Common/AlertDialogBox'
import '../../../css/perfect-scrollbar.css'

const ApplicationStage = () => {
    
    /* States & Constants */
    const { applicationID, airportID } = useParams()
    const [userType, setUserType] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingInternally, setIsLoadingInternally] = useState(false)
    const [activeTabName, setActiveTabName] = useState('')
    const [tabListData, setTabListData] = useState([{iconClassName: 'mdi mdi-3x mdi-case-check', tabName: 'Draft'}, {iconClassName: 'mdi mdi-3x mdi-accounts', tabName: 'Verifier Review'}, {iconClassName: 'mdi mdi-3x mdi-airplane', tabName: 'Airport Review'}, {iconClassName: 'mdi mdi-3x mdi mdi-3x mdi-account', tabName: 'Admin Review'}, {iconClassName: 'mdi mdi-3x mdi-check-all', tabName: 'Approved'}])
    const [state, setState] = useState({ applicationStatus: '', applicationType: '', default: [], sections: [], summaries: [] })
    const questionRefs = useRef([])
    const [leftNavigationMenu, setLeftNavigationMenu] = useState([])
    const [activeRightPane, setActiveRightPane] = useState('')
    const userID = localStorage.getItem('userID')
    const [refetchApplicationOverview, setRefetchApplicationOverview] = useState(false)
    const [refetchApplicationSummary, setRefetchApplicationSummary] = useState(false)
    const [subSectionUpdated, setSubSectionUpdated] = useState(false)
    const [fetchedDependentSubSectionIDs, setFetchedDependentSubSectionIDs] = useState([])
    const [refetchDependentSubSectionIDs, setRefetchDependentSubSectionIDs] = useState([])
    const [refetchVerificationSummary, setRefetchVerificationSummary] = useState(false)
    const [alertDialogBoxProperties, setAlertDialogBoxProperties] = useState({ visibility: false, heading: '', alertText: '', buttonText: '', toggle: () => { setAlertDialogBoxProperties(prevState => ({...prevState, visibility: !prevState.visibility})) } })
    const [isVerificationRequired, setIsVerificationRequired] = useState('')
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const isAirportUser = userRoles.length === 1 && userRoles[0].name === 'Airport User'
    const isExternalVerifierUser = userRoles.length === 1 && userRoles[0].name === "Verifier User"
    const isSingleCheck = userRoles.length === 1 && userRoles[0].returnType === 'Single User'
    const isGroupCheck = userRoles.length === 1 && userRoles[0].returnType === 'Group User'
    // the `reCalculateTotalAtRunTime` state would trigger to calculate total at intial run time: part of `calculateTotal` approach: which in turn is part of `calculateTargetViaEventListener` approach
    const [reCalculateTotalAtRunTime, setReCalculateTotalAtRunTime] = useState(true)
    const [isOtherDetailsSectionSavedInTheBackend, setIsOtherDetailsSectionSavedInTheBackend] = useState(false)
    const [allowToMoveInOtherDetailsSection, setAllowToMoveInOtherDetailsSection] = useState(false)

    /* Methods */
    const getDefaults = async (refetchApplicationStatus) => {
      try{
        setIsLoading(true)
        const response =  await fetch(`${config.apiUrl}${apiconfig.getAccreditationApplicationOverview}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        if (response.ok) {
          let result = await response.json()
          
          if(refetchApplicationStatus)
            updateState('status', result.applicationStatus)
          else{
            /* update the state without calling 'updateState()' method here */
            const updatedState = {...state}
            updatedState.applicationStatus = result.applicationStatus
            updatedState.applicationType = result.applicationType
            updatedState.default = result.defaults
            setState(updatedState)
            setIsOtherDetailsSectionSavedInTheBackend(result.defaults[2].completed)
            setAllowToMoveInOtherDetailsSection(result.defaults[2].completed)
          }
          
          console.log(result.defaults)

          /* if there's no existing value present in `activeRightPane` (i.e. it's empty) then set it as the initial right pane that would be visible once state get's updated */
          if(activeRightPane.length === 0)
            setActiveRightPane(result.defaults[0].name)
        }
        else{
          toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
        }
        setIsLoading(false)   
      }catch(error){
        console.log(error.message)
      }
    }
    const isACAYearValid = async (ACAYearID) => {
        try{
            setIsLoading(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.checkAirportACAYearAlreadyExists}${applicationID}&ACAYearID=${ACAYearID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                let result = await response.json()
                if(result.saveErrorMessage.length !== 0){
                    if(!toast.isActive('invalid-aca-year'))
                        toast.error(result.saveErrorMessage, { toastId: 'invalid-aca-year', position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
                    else
                        toast.update('invalid-aca-year', { autoClose: 10000 })
                }
                setIsLoading(false)
                return result.saveErrorMessage.length === 0
            }
            else
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            setIsLoading(false)
        }
        catch(error){
          console.log(error.message)
        }
    }
    const refetchApplicationOverviewDetails = async () => {
        try{
            setIsLoading(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAccreditationApplicationOverview}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                let result = await response.json()
                updateState('default', result.defaults[0], 0, -1)
            }
            else
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            setIsLoading(false)   
        }
        catch(error){
          console.log(error.message)
        }
    }
    const getSectionAndSubSections = async (selectedAccreditationLevelID) => {
      try{
        setIsLoading(true)
        const response =  await fetch(`${config.apiUrl}${apiconfig.getApplicationSecSubSecQuesDetails}${applicationID}&LevelID=${selectedAccreditationLevelID}&UserID=${userID}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        if (response.ok) {
          let result = await response.json()
          /* load-time copying of matrix cell's data to appropriate locations: to compensate for only run-time event driven `copyTo` scenario */
          result.sections = loadTimeMatrixCellDataCopyAlgorithm(result.sections)
          updateState('sections', result.sections, -1, -1)
        }else{
          toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
        }
        setIsLoading(false)
      }catch(error){
        console.log(error.message)
      }
    }
    const getSummaries = async () => {
      try{
        setIsLoading(true)
        const response =  await fetch(`${config.apiUrl}${apiconfig.getSummaries}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        if (response.ok) {
          let result = await response.json()
          console.log('summaries: ', result.summaries)
          updateState('summaries', result.summaries, -1, -1)
          /* check whether verifier is required or not, if not, then update the state of tabListData via `setTabListData` */
          if(result.summaries[1].summary.selectVerifier.value.length === 0)
              setTabListData([{iconClassName: 'mdi mdi-3x mdi-case-check', tabName: 'Draft'}, {iconClassName: 'mdi mdi-3x mdi-airplane', tabName: 'Airport Review'}, {iconClassName: 'mdi mdi-3x mdi mdi-3x mdi-account', tabName: 'Admin Review'}, {iconClassName: 'mdi mdi-3x mdi-check-all', tabName: 'Approved'}])
        }
        else{
          toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
        }
        setIsLoading(false)
      }catch(error){
        console.log(error.message)
      }
    }
    const getVerificationSummary = async () => {
      try{
        setIsLoading(true)
        const response =  await fetch(`${config.apiUrl}${apiconfig.getVerificationSummary}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        })
        if (response.ok) {
          let result = await response.json()
          console.log('summaries: ', result.summaries)
          /* 
            check whether verification summary is being re-fetched by checking if the `refetchVerificationSummary` is true or not  
            if true, then it means the verification details in application overview & application summary sub-sections needs to be up-to-date as well
            so, manually update `state` in this scenario avoiding calling the `updateState()` method directly
          */
          if(refetchVerificationSummary){
            const updatedState = {...state}
            /* first update the summaries */
            updatedState.summaries = result.summaries

            /* -------------------------internal api call------------------------- */
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAccreditationApplicationOverview}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                let result = await response.json()
                // application overview will be updated via this api call's result
                updatedState.default[0] = result.defaults[0]
            }
            else
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            /* ------------------------------------------------------------------- */

            /* now reflect the required changes to application summary fields */
            if(updatedState.summaries !== null){
              if(updatedState.summaries.length !== 0){
                updatedState.summaries[1].verificationReview.footprint = result.summaries[1].verificationReview.footprint
                updatedState.summaries[1].verificationReview.management = result.summaries[1].verificationReview.management
                updatedState.summaries[1].verificationReview.overall = result.summaries[1].verificationReview.overall
              }
            }
            /* finally update the state via `setState` */
            setState(updatedState)
          }
          else
            updateState('summaries', result.summaries, -1, -1)
        }
        else{
          toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
        }
        setIsLoading(false)
      }catch(error){
        console.log(error.message)
      }
    }
    const getAdminSummary = async () => {
        try{
            setIsLoading(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.getVerificationSummary}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                let result = await response.json()
                /* -----------inner call to another api endpoint--------- */
                    const inner_response =  await fetch(`${config.apiUrl}${apiconfig.getSummaries}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
                        method: 'GET',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`
                        }
                    })
                    if (inner_response.ok) {
                        let inner_result = await inner_response.json()
                        let adminUserOptions = await getAdminUserListOptions()
                        /* creating unique shallow copies as we'll be manipulating these and wouldn't want to refer to single object */
                        let adminUserOptionsForR1 = [...adminUserOptions]
                        let adminUserOptionsForR2 = [...adminUserOptions]
                        /* 
                            perform modifications based on combined responses to get desired result
                            update the state to reflect changes in the ui 
                        */
                        /* check if it's a scenario where verifier is not required */
                        inner_result.summaries[1].summary.selectVerifier.value.length === 0 ? result.summaries[0].type = '' : result.summaries[0].type = 'admin'
                        /* check whether verifier is required or not, if not, then update the state of tabListData via `setTabListData` */
                        if(inner_result.summaries[1].summary.selectVerifier.value.length === 0)
                            setTabListData([{iconClassName: 'mdi mdi-3x mdi-case-check', tabName: 'Draft'}, {iconClassName: 'mdi mdi-3x mdi-airplane', tabName: 'Airport Review'}, {iconClassName: 'mdi mdi-3x mdi mdi-3x mdi-account', tabName: 'Admin Review'}, {iconClassName: 'mdi mdi-3x mdi-check-all', tabName: 'Approved'}])
                        result.summaries[1].type = 'admin'
                        result.summaries[1].summary = inner_result.summaries[1].summary
                        result.summaries[1].adminSummary = { r1Approved: false, r1: { options: adminUserOptionsForR1, recycleOptions: [], value: '' }, r2Approved: false, r2: { options: adminUserOptionsForR2, recycleOptions: [], value: '' }, finalApproved: false, comment: '', documents: [] }
                        console.log('admin-summaries: ', result.summaries)
                        updateState('summaries', result.summaries, -1, -1)
                    }
                    else
                        toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
                /* ----------------------------------------------------- */
            }
            else
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            setIsLoading(false)
        }
        catch(error){
            console.log(error.message)
        }
    }
    const getAdminUserListOptions = async () => {
        let adminUserListOptions = []
        adminUserListOptions.push({ label: '---------------', value: '0' })
        try{
            setIsLoading(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.getAdminUserList}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                let result = await response.json()
                console.log('admin users: ', result)
                for(const item of result)
                    adminUserListOptions.push({ label: item.name, value: item.userID.toString() })
            }
            else
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            setIsLoading(false)
        }
        catch(error){
            console.log(error.message)
        }
        return adminUserListOptions
    }
    const refetchApplicationSummaryDetails = async () => {
        try{
            setIsLoading(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.getSummaries}${applicationID}&AirportID=${airportID}&LoggedInUserID=${userID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                let result = await response.json()
                console.log('refetched-summaries: ', result.summaries)
                /* update the state here */
                const updatedState = {...state}
                updatedState.summaries[1].summary.totalAmountDue = result.summaries[1].summary.totalAmountDue
                updatedState.summaries[1].summary.participationCosts.accreditationLevel = result.summaries[1].summary.participationCosts.accreditationLevel
                updatedState.summaries[1].summary.participationCosts.nextLevel = result.summaries[1].summary.participationCosts.nextLevel
                updatedState.summaries[1].summary.participationCosts.prevLevel = result.summaries[1].summary.participationCosts.prevLevel
                setState(updatedState)
            }
            else
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            setIsLoading(false)
        }
        catch(error){
          console.log(error.message)
        }
    }
    const getSubSectionDetailsByID = async (sectionIndex, subSectionIndex, subSectionID) => {
        /* 
          this method should be called each time a sub-section gets saved or submitted into the database
          the purpose of this method is to help other components which depends on live (value that exists in database) state of data via the property "lastSuccessfullySavedResponse" or "lastSuccessfullySavedResponseOther" or "lastSuccessfullySavedResponseMatrix"
        */
        try{
            setIsLoadingInternally(true)
            const response =  await fetch(`${config.apiUrl}${apiconfig.getSubSectionDetailsByID}${applicationID}&SubSectionID=${subSectionID}&LevelID=${state.default[1].value}&UserID=${userID}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                let result = await response.json()
                /* load-time copying of matrix cell's data to appropriate locations: to compensate for only run-time event driven `copyTo` scenario */
                result = loadTimeMatrixCellDataCopyAlgorithmForParticularSubSection(result)
                console.log('sub-section by id: ', result)
                updateState('subsections', result, subSectionIndex, sectionIndex)

                // check whether `refetchDependentSubSectionIDs` has id(s) or not
                if(refetchDependentSubSectionIDs !== null && refetchDependentSubSectionIDs.length !== 0){
                  // check whether the current subSectionID exists within `refetchDependentSubSectionIDs` meaning that this current subsection was fetched due to trigger caused by `refetchDependentSubSectionIDs`  
                  if(refetchDependentSubSectionIDs.includes(parseInt(subSectionID))){
                        // add the processed ID into the `fetchedDependentSubSectionIDs` state
                        setFetchedDependentSubSectionIDs(prev => [...prev, parseInt(subSectionID)])
                    }
                }
                  
                // set `reCalculateTotalAtRunTime` as true via `setReCalculateTotalAtRunTime`
                // this is done to make sure that the `total` gets re-calculated for all scenarios of run-time & post re-loading 
                // note that although we're setting this state as 'true' globally but it would only affect those question whose response type is `other` and also employs `calculateTargetViaEventListener` & `calculateTotal` approaches as well 
                setReCalculateTotalAtRunTime(true)
            }
            else{
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            }
            setIsLoadingInternally(false)  
        }catch(error){
            console.log(error.message)
        }    
    }
    const updateState = (type, result, index, section_index) => {
      /* 
        this method shall be called whenever there is an absolute change made in any of subsections, other details, accreditation level etc.
        utilize the parameters of this method to update the type specific parts of 'state'
        once updated, the state changes will reflect to all ui components wherever it's been used 
      */
      const updatedState = {...state}
      switch(type){
        case 'status':
          updatedState.applicationStatus = result
          break
        case 'defaults':
          updatedState.default = result
          break
        case 'default':
          updatedState.default[index] = result
          break
        case 'sections':
          updatedState.sections = result
          break
        case 'subsections':
          updatedState.sections[section_index].subsections[index] = result
          break
        case 'summaries':
          updatedState.summaries = result
          break
        case 'summary':
          updatedState.summaries[index] = result
          break
        default:
          break
      }

      setState(updatedState)
    }
    const saveAccreditationeLevel = async (accreditationLevelID) => {
      /* 
        this method should only be called upon submission of the accreditation level selected by the airport user
        upon success, another method should be called to fetch the quessionaire from the database corresponding to the submitted accreditation level
        once fetched, update the 'state' with fetched data via 'updateState' method 
      */
      const requestBody = {
        "userID": userID,
        "airportApplicationID": applicationID,
        "airportID": airportID,
        "accreditationLevelID": accreditationLevelID
      }
      try {
        setIsLoading(true)
        const response = await fetch(`${config.apiUrl}${apiconfig.saveAccreditationLevelToAirport}`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(requestBody),
        })
        if (response.ok) {
            var result = await response.json()
            if(result.saveErrorMessage === 'Accreditation Level saved successfully.')
              toast.success('Accreditation Level saved successfully.', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            await getSectionAndSubSections(accreditationLevelID)
            setRefetchApplicationOverview(true)
            setRefetchApplicationSummary(true)
        } 
        else{
          toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
        }
        setIsLoading(false)
      } catch (error) {
          console.log("Error: ", error)
      }
    } 
    const saveSubSection = async (sectionIndex, subSectionIndex) => {
      /* 
        send the section with all details to backend via api endpoint
        upon success, refetch that section from another api endpoint
        once fetched with latest data, update the 'state' with latest sub-section details via 'updateState' method 
      */
        const requestBody = {
          "airportApplicationID": applicationID,
          "airportID": airportID,
          "userID": userID,
          "jsonSubSection": state.sections[sectionIndex].subsections[subSectionIndex]
        }
        try {
          setIsLoading(true)
          const response = await fetch(`${config.apiUrl}${apiconfig.saveSectionResponse}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: JSON.stringify(requestBody),
          })
          if (response.ok) {
            var result = await response.json()
            if(result.saveErrorMessage === 'Data Saved Successfully.')
              toast.success('Details saved successfully!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            /* 
              refetching sub-section via 'getSubSectionDetailsByID' api endpoint to make sure the data is in sync with database 
              this is required from the point of view of field called "lastSuccessfullySavedResponse" or "lastSuccessfullySavedResponseOther" or "lastSuccessfullySavedResponseMatrix" depending on response type
            */
            await getSubSectionDetailsByID(sectionIndex, subSectionIndex, state.sections[sectionIndex].subsections[subSectionIndex].id)
            /* 
              check if this sub-section has any other sub-sections that depends on it's state 
              use the attribute namely "dependentSubSectionIDs" that is available within each sub-section
              if it's not empty/null, then update this state `refetchDependentSubSectionIDs` with that value
              an effect that's been defined below somewhere would get triggered and would re-fetch those particular sub-sections via calling the `getSubSectionDetailsByID` method iteratively 
            */
            if(state.sections[sectionIndex].subsections[subSectionIndex].dependentSubSectionIDs !== null && state.sections[sectionIndex].subsections[subSectionIndex].dependentSubSectionIDs.length !== 0)
              setRefetchDependentSubSectionIDs(state.sections[sectionIndex].subsections[subSectionIndex].dependentSubSectionIDs)
            /* 
              check whether the userType is `verifier`
              if yes, then refetch the verification summary by setting `refetchVerificationSummary` as true via `setRefetchVerificationSummary`  
              this state change would trigger an useEffect defined at the bottom which is responsible for refetching & updating verification summary in the actual state
              Note: the logic in the 'else' part of this 'if' statement is not required inside 'if' as re-fetching verification summary internally calls an api endpoint which updates the complete application overview subsection itself 
            */
            if(userType === 'verifier' && state.sections[sectionIndex].subsections[subSectionIndex].type === 'verifier')
                setRefetchVerificationSummary(true)
            else{
                /* 
                  In order to update the no. of questions answered and remaning, application overview must be re-fetched 
                  These two state update i.e. `subSectionUpdated` and `refetchApplicationOverview` would trigger an effect that will eventually update the application overview
                */
                setSubSectionUpdated(true)
                setRefetchApplicationOverview(true)
            }
          }else{
            const updatedState = {...state}
            updatedState.sections[sectionIndex].subsections[subSectionIndex].completed = false
            setState(updatedState)
            toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
          }
          setIsLoading(false)
        } catch (error) {
          console.log("Error: ", error)
        }
    }
    const saveOtherDetails = async (acaYear, airportBand, timePeriodOfCarbonFootprint, verificationType,status) => {
      const requestBody = {
        "userID": userID,
        "airportApplicationID": applicationID,
        "airportID": airportID,
        "reportingPeriodID": acaYear,
        "timePeriodOfCarbonFootPrint": timePeriodOfCarbonFootprint,
        "verifierID": 0,
        "verificationNature": verificationType,
        "isRenewal": 0,
        "bandID": airportBand,
        "groupDiscountID": 0,
        "feedback": "",
        "isSubmit": 0,
        "actionFromWhere": "Other Details",
        "status":status
      }
      try {
        setIsLoading(true)
        const response = await fetch(`${config.apiUrl}${apiconfig.saveOtherDetails}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(requestBody),
        })
        if (response.ok) {
          var result = await response.json()
          if(result.saveErrorMessage === 'The request has been submitted successfully.' || result.saveErrorMessage === 'Other details has been saved successfully.')
            toast.success('Details saved successfully!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
          setRefetchApplicationOverview(true)
          setRefetchApplicationSummary(true)
          setIsOtherDetailsSectionSavedInTheBackend(true)
        }else{
          const updatedState = {...state}
          updatedState.default[2].completed = false
          setState(updatedState)
          toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
        }
        setIsLoading(false)
      } catch (error) {
        console.log("Error: ", error)
      }
    }
    const checkIfVerificationIsRequired = async () => {
        /* 
          check if as an airport user selecting verifier is required or not required or optional by calling this api endpoint
        */
        try{
          setIsLoading(true)
          const response =  await fetch(`${config.apiUrl}${apiconfig.checkIfVerificationIsRequired}${applicationID}`, {
              method: 'GET',
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`
              }
          })
          if(response.ok) {
              let result = await response.json()
              setIsVerificationRequired(result.isVerificationRequired)    
          }
          else
              toast.error('Something went wrong!', { position: "top-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
          setIsLoading(false)  
        }
        catch(error){
            console.log(error.message)
        }    
    }
    const submitApplication = async (verifierID, groupDiscountID, feedback, refetchApplicationStatus, showSuccessToast,status) => {
      /* 
        this method should only be allowed to call once all subsections gets completed (saved back in backend) and the confirmation to do so must've been provided by the user
        upon successful submission of the application, airport user shall not be allowed to modify or add any responses (read-only mode should be triggered in this scenario) unless the application gets back to the airport during verifier review stage
        any save or submit buttons needs to be disabled or hidden 
      */
      const requestBody = {
        "userID": userID,
        "airportApplicationID": applicationID,
        "airportID": airportID,
        "reportingPeriodID": 0,
        "timePeriodOfCarbonFootPrint": '',
        "verifierID": verifierID,
        "verificationNature": '',
        "isRenewal": 0,
        "bandID": 0,
        "groupDiscountID": groupDiscountID,
        "feedback": feedback,
        "isSubmit": 1,
        "actionFromWhere": "Application Summary",
        "status":status
      }
      try {
        setIsLoading(true)
        const response = await fetch(`${config.apiUrl}${apiconfig.saveApplicationSummary}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(requestBody),
        })
        if (response.ok) {
          var result = await response.json()
          if(result.saveErrorMessage === 'The request has been submitted successfully.'){
            if(showSuccessToast){
              toast.success('Application Submitted!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
               setTimeout(function(){
                   if(isExternalVerifierUser){
                     window.location.href = "/VerifierDashboard"
                   }else{
                     if(isAirportUser && isGroupCheck)
                         window.location.href = "/AirportGroupDashboard"
                     else if(isAirportUser && isSingleCheck)
                         window.location.href = "/AirportGroupDashboard"
                     else
                         window.location.href = "/AccreditationDashboard";
                   }  
             }, 3000);   
            }
          }
          if(refetchApplicationStatus)
            await getDefaults(true)
        }else{
          toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
        }
        setIsLoading(false)
      } catch (error) {
        console.log("Error: ", error)
      }
    }
    const uploadDocuments = () => {
      /* 
        this method should be called once a subsection gets submitted by the user
        this method may run asynchronously in the background for better performance or could be run sequentially (still asynchronously) after submission as well
        maximum file size is 20 MB, so depending on the approach utilized along with files sizes and no. of files being uploaded, performance might vary
        should return the document structure along with blob url so as downloading option could be enabled 
      */
    }
    const sendBackApplication = async (statusChange, actionType) => {
      /*
        generic method to implement cross-user communication based on two parameters: `statusChange` & `actionType`
        where: 
            `statusChange` implies the change in the application status
            `actionType` implies the type of user who's performing the change in application status
      */
      const requestBody = {
          "userID": userID,
          "applicationID": applicationID,
          "statusChange": statusChange,
          "actionType": actionType
      }
      try {
          setIsLoading(true)
          const response = await fetch(`${config.apiUrl}${apiconfig.sendBackApplicationToAirportVerifierAdmin}`, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  'Authorization': `Bearer ${localStorage.getItem("token")}`
              },
              body: JSON.stringify(requestBody),
          })
          if (response.ok) {
              var result = await response.json()
              if(result.saveErrorMessage === 'Application sent back successfully.'){
                switch(statusChange){
                  case 'Airport resubmitted - In Verification':
                  case 'Airport resubmitted - In Admin Verification':
                  case 'Verifier resubmitted - In Admin Verification':
                  case 'In Admin Verification':
                    toast.success('Application submitted successfully.', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
                    setTimeout(function(){
                      if(isExternalVerifierUser){
                        window.location.href = "/VerifierDashboard"
                      }else{
                        if(isAirportUser && isGroupCheck)
                            window.location.href = "/AirportGroupDashboard"
                        else if(isAirportUser && isSingleCheck)
                            window.location.href = "/AirportGroupDashboard"
                        else
                            window.location.href = "/AccreditationDashboard";
                      }  
                }, 3000);   
                    break
                  default:
                    toast.success('Application sent back successfully.', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
                    break
                }
              }  
              await getDefaults(true)
          }
          else
              toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
          setIsLoading(false)
        } 
        catch (error) {
            console.log("Error: ", error)
        }
    }
    const approveApplication = async (r1, r2, feedback, documents) => {
        /*
          this method should only be called by the admin user once they have filled in the "Airport Approval Form" completely and wants to approve the airport application
        */
        const requestBody = {
            "userID": userID,
            "airportApplicationID": applicationID,
            "airportID": airportID,
            "r1Reviewer": r1,
            "r2Reviewer": r2,
            "feedback": feedback,
            "documents": documents
        }
        try {
            setIsLoading(true)
            const response = await fetch(`${config.apiUrl}${apiconfig.approveAirportApplication}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify(requestBody),
            })
            if (response.ok) {
                var result = await response.json()
                if(result.saveErrorMessage === 'Application has been approved successfully.'){
                    toast.success('Application has been approved successfully.', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })  
                    setTimeout(function(){
                            window.location.href = "/AccreditationDashboard"; 
                    }, 3000); 
                await getDefaults(true)
                }
                else if(result.saveErrorMessage === 'Selected ACA year is already linked with another approved application with this airport.'){
                  toast.error(result.saveErrorMessage, { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
                  setIsLoading(false);
                }
            }
            else
                toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
            setIsLoading(false)
        } 
        catch (error) {
            console.log("Error: ", error)
        }
    }
    const getApprovedApplicationDetails = async () => {
      try{
          setIsLoading(true)
          const response =  await fetch(`${config.apiUrl}${apiconfig.getApprovedApplicationDetails}?ApplicationID=${applicationID}&UserID=${userID}`, {
              method: 'GET',
              headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`
              }
          })
          if (response.ok) {
              let result = await response.json()
              //console.log('approved details: ', result)
              /* update the state here in order to update `adminSummary` */
              const updatedState = {...state}
              updatedState.summaries[1].adminSummary.comment = result.feedback
              if(result.reviewer1ID !== null){
                  updatedState.summaries[1].adminSummary.r1Approved = true
                  updatedState.summaries[1].adminSummary.r1.value = result.reviewer1ID.toString()
              }
              if(result.reviewer2ID !== null){
                  updatedState.summaries[1].adminSummary.r2Approved = true
                  updatedState.summaries[1].adminSummary.r2.value = result.reviewer2ID.toString()
              }
              if(result.reviewer1ID !== null && result.reviewer2ID !== null)
                  updatedState.summaries[1].adminSummary.finalApproved = true
              if(result.documents !== null & result.documents.length !== 0)
                  updatedState.summaries[1].adminSummary.documents = result.documents
              setState(updatedState)
          }
          else
              toast.error('Something went wrong!', { position: "top-right", autoClose: 10000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, theme: "light", transition: Slide })
          setIsLoading(false)
      }
      catch(error){
          console.log(error.message)
      }
    }
    const copyMatrixCellDataToTargetLocation = (sections, sectionID, subSectionID, questionID, rowID, attributeID, value) => {
        for(const section of sections){
            if(section.id === sectionID){
                for(const subsection of section.subsections){
                    if(subsection.id === subSectionID){
                        for(const question of subsection.content.questions){
                            if(question.id === questionID && question.responseType === 'matrix'){
                                if(question.matrix !== null){
                                    if(question.matrix.rows !== null && question.matrix.rows.length !== 0){
                                        for(const row of question.matrix.rows){
                                            if(row.id === rowID){
                                                if(row.attributes !== null && row.attributes.length !== 0){
                                                    for(const attribute of row.attributes){
                                                        if(attribute.id === attributeID){
                                                            if(attribute.type !== 'span' && value !== null && typeof(value) !== 'undefined')
                                                                attribute.value = value
                                                        }
                                                    }
                                                }
                                            }
                                            /* update the values of any cells having type as `calculated` whose value depends on this particular attribute that's been updated just above  */
                                            row.attributes.map((attribute, attribute_index) => {
                                                if(attribute.calculated){
                                                    if(attribute.direction === 'horizontal'){
                                                        switch (attribute.operation){
                                                            case 'average':
                                                                let sum_for_average = 0
                                                                let valid_for_average = false
                                                                attribute.indexes.map((index) => {
                                                                    if(row.attributes[index].value.length !== 0){
                                                                        if(!valid_for_average)
                                                                            valid_for_average = true
                                                                        sum_for_average += parseInt(row.attributes[index].value)
                                                                    }
                                                                })
                                                                if(valid_for_average)
                                                                    attribute.value = Number((sum_for_average/attribute.indexes.length)).toFixed(6).toString()
                                                                else
                                                                    attribute.value = ''
                                                                break
                                                            case 'compare':
                                                                let valid_for_compare = true
                                                                if(row.attributes[attribute.indexes[0]].value.length === 0 || row.attributes[attribute.indexes[1]].value.length === 0)
                                                                    valid_for_compare = false
                                                                if(valid_for_compare){
                                                                    if(attribute.operationType === 'lesser-than')
                                                                        if(parseFloat(row.attributes[attribute.indexes[1]].value) < parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                            attribute.value = 'Yes'
                                                                        else
                                                                            attribute.value = 'No'
                                                                    else if(attribute.operationType === 'greater-than')
                                                                        if(parseFloat(row.attributes[attribute.indexes[1]].value) > parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                            attribute.value = 'Yes'
                                                                        else
                                                                            attribute.value = 'No'
                                                                    else if(attribute.operationType === 'equals-to')
                                                                        if(parseFloat(row.attributes[attribute.indexes[1]].value) === parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                            attribute.value = 'Yes'
                                                                        else
                                                                            attribute.value = 'No'
                                                                }  
                                                                else
                                                                    attribute.value = ''
                                                                break
                                                            case 'sum':
                                                                let sum = 0
                                                                let valid = true
                                                                attribute.indexes.map((index) => {
                                                                    if(row.attributes[index].value.length === 0)
                                                                        valid = false
                                                                    else
                                                                        sum += parseFloat(row.attributes[index].value)
                                                                })
                                                                if(valid)
                                                                    attribute.value = Number(sum.toFixed(6)).toString()
                                                                else
                                                                    attribute.value = ''
                                                                break
                                                            default:
                                                                break
                                                        }
                                                    }
                                                    else if(attribute.direction === 'vertical'){
                                                        switch (attribute.operation){
                                                            case 'divide':
                                                                let numerator = sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[attribute.indexes[0]].attributes[attribute_index].value
                                                                let denominator = sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[attribute.indexes[1]].attributes[attribute_index].value
                                                                let valid_for_divide = true
                                
                                                                if(numerator.length === 0 || denominator.length === 0)
                                                                    valid_for_divide = false
                                
                                                                if(valid_for_divide)
                                                                    attribute.value = Number((parseFloat(numerator)/parseFloat(denominator)).toFixed(6)).toString()
                                                                else
                                                                    attribute.value = ''
                                                                break
                                                            case 'sum':
                                                                let sum = 0
                                                                let valid_for_sum = true
                                                                attribute.indexes.map((indexes_index) => {
                                                                    if(sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[indexes_index].attributes[attribute_index].value === '')
                                                                        valid_for_sum = false
                                                                    else
                                                                        sum += parseFloat(sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[indexes_index].attributes[attribute_index].value)
                                                                })
                                                                if(valid_for_sum)
                                                                    attribute.value = Number(sum.toFixed(6)).toString()
                                                                else
                                                                    attribute.value = ''
                                                                break
                                                            default:
                                                                break
                                                        }
                                                    }
                                                }
                                            }) 
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return sections
    }
    const loadTimeMatrixCellDataCopyAlgorithm = (sections) => {
        try{
            for(const section of sections){
                for(const subsection of section.subsections){
                    for(const question of subsection.content.questions){
                        if(question.responseType === 'matrix'){
                            if(question.matrix !== null){
                                if(question.matrix.rows !== null && question.matrix.rows.length !== 0){
                                    for(const row of question.matrix.rows){
                                        if(row.attributes !== null && row.attributes.length !== 0){
                                            for(const attribute of row.attributes){
                                                if(attribute.copyTo !== null && attribute.copyTo.length !== 0){
                                                    for(const item of attribute.copyTo){
                                                        sections = copyMatrixCellDataToTargetLocation(sections, item.sectionID, item.subSectionID, item.questionID, item.rowID, item.attributeID, attribute.value)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            return sections
        }
        catch(error){
            console.log('->', error.message)
        }
        return sections
    }
    const copyMatrixCellDataToTargetLocationForParticularSubSection = (subsection, sectionID, subSectionID, questionID, rowID, attributeID, value) => {
        if(subsection.id === subSectionID){
            for(const question of subsection.content.questions){
                if(question.id === questionID && question.responseType === 'matrix'){
                    if(question.matrix !== null){
                        if(question.matrix.rows !== null && question.matrix.rows.length !== 0){
                            for(const row of question.matrix.rows){
                                if(row.id === rowID){
                                    if(row.attributes !== null && row.attributes.length !== 0){
                                        for(const attribute of row.attributes){
                                            if(attribute.id === attributeID){
                                                if(attribute.type !== 'span' && value !== null && typeof(value) !== 'undefined')
                                                    attribute.value = value
                                            }
                                        }
                                    }
                                }
                                /* update the values of any cells having type as `calculated` whose value depends on this particular attribute that's been updated just above  */
                                row.attributes.map((attribute, attribute_index) => {
                                    if(attribute.calculated){
                                        if(attribute.direction === 'horizontal'){
                                            switch (attribute.operation){
                                                case 'average':
                                                    let sum_for_average = 0
                                                    let valid_for_average = false
                                                    attribute.indexes.map((index) => {
                                                        if(row.attributes[index].value.length !== 0){
                                                            if(!valid_for_average)
                                                                valid_for_average = true
                                                            sum_for_average += parseInt(row.attributes[index].value)
                                                        }
                                                    })
                                                    if(valid_for_average)
                                                        attribute.value = Number((sum_for_average/attribute.indexes.length)).toFixed(6).toString()
                                                    else
                                                        attribute.value = ''
                                                    break
                                                case 'compare':
                                                    let valid_for_compare = true
                                                    if(row.attributes[attribute.indexes[0]].value.length === 0 || row.attributes[attribute.indexes[1]].value.length === 0)
                                                        valid_for_compare = false
                                                    if(valid_for_compare){
                                                        if(attribute.operationType === 'lesser-than')
                                                            if(parseFloat(row.attributes[attribute.indexes[1]].value) < parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                attribute.value = 'Yes'
                                                            else
                                                                attribute.value = 'No'
                                                        else if(attribute.operationType === 'greater-than')
                                                            if(parseFloat(row.attributes[attribute.indexes[1]].value) > parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                attribute.value = 'Yes'
                                                            else
                                                                attribute.value = 'No'
                                                        else if(attribute.operationType === 'equals-to')
                                                            if(parseFloat(row.attributes[attribute.indexes[1]].value) === parseFloat(row.attributes[attribute.indexes[0]].value))
                                                                attribute.value = 'Yes'
                                                            else
                                                                attribute.value = 'No'
                                                    }  
                                                    else
                                                        attribute.value = ''
                                                    break
                                                case 'sum':
                                                    let sum = 0
                                                    let valid = true
                                                    attribute.indexes.map((index) => {
                                                        if(row.attributes[index].value.length === 0)
                                                            valid = false
                                                        else
                                                            sum += parseFloat(row.attributes[index].value)
                                                    })
                                                    if(valid)
                                                        attribute.value = Number(sum.toFixed(6)).toString()
                                                    else
                                                        attribute.value = ''
                                                    break
                                                default:
                                                    break
                                            }
                                        }
                                        else if(attribute.direction === 'vertical'){
                                            switch (attribute.operation){
                                                case 'divide':
                                                    let numerator = state.sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[attribute.indexes[0]].attributes[attribute_index].value
                                                    let denominator = state.sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[attribute.indexes[1]].attributes[attribute_index].value
                                                    let valid_for_divide = true
                    
                                                    if(numerator.length === 0 || denominator.length === 0)
                                                        valid_for_divide = false
                    
                                                    if(valid_for_divide)
                                                        attribute.value = Number((parseFloat(numerator)/parseFloat(denominator)).toFixed(6)).toString()
                                                    else
                                                        attribute.value = ''
                                                    break
                                                case 'sum':
                                                    let sum = 0
                                                    let valid_for_sum = true
                                                    attribute.indexes.map((indexes_index) => {
                                                        if(state.sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[indexes_index].attributes[attribute_index].value === '')
                                                            valid_for_sum = false
                                                        else
                                                            sum += parseFloat(state.sections.find(section => section.id === sectionID).subsections.find(subsection => subsection.id === subSectionID).content.questions[subsection.content.questions.indexOf(question)].matrix.rows[indexes_index].attributes[attribute_index].value)
                                                    })
                                                    if(valid_for_sum)
                                                        attribute.value = Number(sum.toFixed(6)).toString()
                                                    else
                                                        attribute.value = ''
                                                    break
                                                default:
                                                    break
                                            }
                                        }
                                    }
                                }) 
                            }
                        }
                    }
                }
            }
        }
        return subsection
    }
    const loadTimeMatrixCellDataCopyAlgorithmForParticularSubSection = (subsection) => {
      try{
          for(const question of subsection.content.questions){
              if(question.responseType === 'matrix'){
                  if(question.matrix !== null){
                      if(question.matrix.rows !== null && question.matrix.rows.length !== 0){
                          for(const row of question.matrix.rows){
                              if(row.attributes !== null && row.attributes.length !== 0){
                                  for(const attribute of row.attributes){
                                      if(attribute.copyTo !== null && attribute.copyTo.length !== 0){
                                          for(const item of attribute.copyTo){
                                              subsection = copyMatrixCellDataToTargetLocationForParticularSubSection(subsection, item.sectionID, item.subSectionID, item.questionID, item.rowID, item.attributeID, attribute.value)
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
          return subsection
      }
      catch(error){
          console.log('->', error.message)
      }
      return subsection
    }
    const refreshLeftNavigationMenu = () => {
      if(leftNavigationMenu.length === 0)
        return true
      
      let count = 0
      count += state.default.length
      count += state.sections.length
      
      for(const subsection of state.sections)
        count += subsection.length

      for(const summary of state.summaries){
        if(summary.type === userType)
          count++
      }
      
      return count !== leftNavigationMenu.length
    }
    const rebuildQuestionsRefs = () => {
      /* 
        since we need to re-build the questions ref array everytime no. of sections gets updated thus we can utilize the 'refreshLeftNavigationMenu()' method as it also implements exact logic 
      */
      return refreshLeftNavigationMenu()
    }
    const countQuestions = (question) => {
      let questionsCount = 0
      for(const subquestion of question.subquestions){
        questionsCount += countQuestions(subquestion)
      }
      return questionsCount + 1
    }
    const calculateQuestionIndex = (question, questionID, questionText) => {
      let questionIndex = 0
      for(const subquestion of question.subquestions){
          if(subquestion.id === questionID && subquestion.question === questionText)
              return { index: questionIndex + 1, found: true }
          questionIndex += 1
          if(subquestion.subquestions.length !== 0){
              let result = calculateQuestionIndex(subquestion, questionID, questionText)
              if(result.found)
                  return { index: questionIndex + result.index, found: true }
          }
      }
      return { index: 0, found: false }
    }
    const calculateQuestionRefIndex = (questionID, questionText) => {
        let questionIndex = 0
        for(const section of state.sections){
            for(const subsection of section.subsections){
                for(const question of subsection.content.questions){
                    if(question.id === questionID && question.question === questionText)
                        return questionIndex
                    if(question.subquestions.length !== 0){
                        let result = calculateQuestionIndex(question, questionID, questionText)
                        if(result.found)
                            return questionIndex + result.index
                    }
                    questionIndex += countQuestions(question)  
                }   
            }
        }
    }
    const scrollToQuestionViaQuestionRef = (questionID, questionText) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' })
        let questionRefIndex = calculateQuestionRefIndex(questionID, questionText)
        setTimeout(() => { 
            if (questionRefs.current[questionRefIndex] && questionRefs.current[questionRefIndex].current)
                questionRefs.current[questionRefIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }, 400)
    }
    const visibilityBasedOnType = (type, allowWhenInProgress) => {
        switch(type){
            case 'draft':
                if(userType === 'draft' && ((state.applicationStatus === 'In Progress' && allowWhenInProgress) || state.applicationStatus === 'Draft' || state.applicationStatus === 'Verifier comments - Airport to address' || state.applicationStatus === 'Admin comments - Airport to address'))
                    return true
                break
            case 'verifier':
                if(userType === 'verifier' && (state.applicationStatus === 'In Verification' || state.applicationStatus === 'Airport resubmitted - In Verification' || state.applicationStatus === 'Admin comments - Verifier to address'))
                    return true
                break
            case 'admin':
                if(userType === 'admin' && (state.applicationStatus === 'In Admin Verification' || state.applicationStatus === 'Airport resubmitted - In Admin Verification' || state.applicationStatus === 'Verifier resubmitted - In Admin Verification'))
                    return true
                break
            default:
                break
        }
        return false
    }
    const commentsVisibility = () => {
        switch(userType){
            case 'draft':
                return state.applicationStatus === 'Verifier comments - Airport to address' || state.applicationStatus === 'Admin comments - Airport to address'
            case 'verifier':
                return state.applicationStatus === 'In Verification' || state.applicationStatus === 'Airport resubmitted - In Verification' || state.applicationStatus === 'Admin comments - Verifier to address'
            case 'admin':
                return state.applicationStatus === 'In Admin Verification' || state.applicationStatus === 'Airport resubmitted - In Admin Verification' || state.applicationStatus === 'Verifier resubmitted - In Admin Verification'
            default:
                break
        }
        return false
    }
    const doesSubSectionHasNoVisibleQuestion = (questions) => {
        for(const question of questions){
            if(question.isVisible){
                /*
                    now check whether this question is the nth full renewal's general query question i.e. question(s) starting with `Have you changed your ...?`
                    if so, then return false only if it's response is empty
                    else, continue as usual 
                */  
                if(question.isGeneralQueryQuestionForNthRenewal){
                    if(question.required){
                        if(question.lastSuccessfullySavedResponse.length === 0)
                            return false
                    }
                }
                else
                    return false
            }
        }
        return true
    }
    const setIsSubmitButtonClickedTrueForAllSubSectionsBasedOnType = () => {
        const updatedState = {...state}
        switch(userType){
            case 'draft':
            case 'verifier':
                // for `other details`
                updatedState.default[2].isSubmitButtonClicked = true
                // for `subsections`
                for(const section of updatedState.sections){
                    for(const subsection of section.subsections){
                        if(subsection.type === userType)
                            subsection.isSubmitButtonClicked = true
                        if(subsection.type === 'draft'){  
                            if(subsection.isEnable && doesSubSectionHasNoVisibleQuestion(subsection.content.questions)){
                                subsection.completed = true
                                subsection.isSubmitButtonClicked = true
                            }
                        }
                    }
                }
                break
            case 'admin':
                // for `other details`
                updatedState.default[2].isSubmitButtonClicked = true
                // for `subsections`
                for(const section of updatedState.sections){
                    for(const subsection of section.subsections){
                        if(subsection.type === 'draft'){  
                            if(subsection.isEnable && doesSubSectionHasNoVisibleQuestion(subsection.content.questions)){
                                subsection.completed = true
                                subsection.isSubmitButtonClicked = true
                            }
                        }
                    }
                }
                break
            default: 
                break    
        }
        setState(updatedState)
    }

    /* Effects */
    useEffect(() => {
      const roles = JSON.parse(localStorage.getItem("roles"))
      for(const role of roles){
        if(userType.length === 0){
          switch(role.name){
            case 'Verifier Coordinator': 
            case 'Verifier User':
              setUserType('verifier')
              break
            case 'Airport User':
              setUserType('draft')
              break
            case 'Administrator':
            case 'Account Manager':
            case 'Senior Account Manager':
              setUserType('admin')
              break
            default:
              break
          }
        }
      }
    }, [])
    useEffect(() => {
      switch(state.applicationStatus){
        case 'In Progress':
        case 'Draft':
            setActiveTabName('Draft')
            break
        case 'Verifier comments - Airport to address':
        case 'Admin comments - Airport to address':
            setActiveTabName('Airport Review')
            break
        case 'In Verification':
        case 'Airport resubmitted - In Verification':
        case 'Admin comments - Verifier to address':
            setActiveTabName('Verifier Review')
            break
        case 'In Admin Verification':
        case 'Airport resubmitted - In Admin Verification':
        case 'Verifier resubmitted - In Admin Verification':
            setActiveTabName('Admin Review')
            break
        case 'Approved':
            setActiveTabName('Approved')
            if(userType === 'admin' && state.summaries != null && state.summaries[1] != null && state.summaries[1].adminSummary.r1.value.length === 0 && state.summaries[1].adminSummary.r2.value.length === 0)
                getApprovedApplicationDetails()
            break
        default:
            setActiveTabName('')
            break
      }
    }, [state])
    useEffect(() => {
      getDefaults(false)
    }, [])
    useEffect(() => {
      if(refetchApplicationOverview && state.default.length !== 0){
          if(subSectionUpdated || state.default[0].content.applicationDetails.accreditationLevel !== state.default[1].levels.find(level => level.id === state.default[1].value).levelName || state.default[0].content.applicationDetails.airportBand !== state.default[2].airportBand.options.find(band => band.value === state.default[2].airportBand.value).label)
              refetchApplicationOverviewDetails()
          if(subSectionUpdated)
              setSubSectionUpdated(false)
          setRefetchApplicationOverview(false)
      }
    }, [state])
    useEffect(() => {
        if(refetchApplicationSummary && userType === 'draft' && state.summaries !== null && state.summaries.length !== 0){
            refetchApplicationSummaryDetails()
            setRefetchApplicationSummary(false) 
        }
    }, [state])
    useEffect(() => {
        /* 
            since `useEffect` hook can not be decalred as `async` in itself 
            thus we need to define & call an internal function just for the sake of making it asynchronous in nature
        */
        const refetchSubSectionsByDependentSubSectionIDs = async () => {
            if(refetchDependentSubSectionIDs !== null && refetchDependentSubSectionIDs.length !== 0){
                // variable to keep track of current section (based on index)
                let section_index = 0
                // variable to keep track of current subsection (based on index)
                let subsection_index = 0
                
                for(const section of state.sections){
                    // reset subsection index for each section
                    subsection_index = 0
                    for(const subsection of section.subsections){
                        // check whether the current subsection id exists in the `fetchedDependentSubSectionIDs` state, only enter block if it does not exists
                        if(!fetchedDependentSubSectionIDs.includes(parseInt(subsection.id))){
                            if(refetchDependentSubSectionIDs.includes(parseInt(subsection.id)))
                                await getSubSectionDetailsByID(section_index, subsection_index, subsection.id)
                        }   
                        subsection_index++
                    }
                    section_index++
                }
                setFetchedDependentSubSectionIDs([])
                setRefetchDependentSubSectionIDs([])
            }
        }
        refetchSubSectionsByDependentSubSectionIDs()
    }, [refetchDependentSubSectionIDs])
    useEffect(() => {
      if(state.default.length !== 0 && (userType === 'admin' || userType === 'verifier' || (userType === 'draft' && state.default[1].value.length !== 0)) && state.default[1].value !== '0'  && state.sections.length === 0 && state.applicationStatus !== 'In Progress'){
        getSectionAndSubSections(state.default[1].value)
      }
    }, [userType, state])
    useEffect(() => {
      if(state.sections.length !== 0 && state.summaries.length === 0){
        if(userType === 'draft')
          getSummaries()
        else if(userType === 'verifier')
          getVerificationSummary()
        else if(userType === 'admin')
          getAdminSummary()
      }

      if(state.applicationStatus === 'In Progress' && state.sections.length !== 0)
        getDefaults(true)
    }, [state])
    useEffect(() => {
      /* 
        this peice of code will create/build the left navigation menu
        fetching data and updating the 'state' using fetched data must be performed before this effect executes (conditional flow of control required here) 
      */
      if(refreshLeftNavigationMenu()){
        let temporaryStorage = []
        state.default.map((item) => { 
          temporaryStorage.push(item.name) 
        })
        state.sections.map((section) => { 
          temporaryStorage.push(section.title)
          section.subsections.map((subsection) => {
            temporaryStorage.push(subsection.title)
          }) 
        })
        state.summaries.map((summary) => { 
          if(summary.type === userType)
            temporaryStorage.push(summary.name) 
        })
        setLeftNavigationMenu(temporaryStorage)
        temporaryStorage = []  
      } 
    }, [state])
    useEffect(() => {
      if(rebuildQuestionsRefs()){
        let questionsCount = 0
        for(const section of state.sections){
          for(const subsection of section.subsections){
            for(const question of subsection.content.questions)
              questionsCount += countQuestions(question) 
          }
        }
        questionRefs.current = Array(questionsCount).fill(null).map(() => React.createRef())
      }
    }, [state])
    useEffect(() => {
        if(refetchVerificationSummary){
            getVerificationSummary()
            setRefetchVerificationSummary(false)
        }
    }, [refetchVerificationSummary])
    useEffect(() => {
        /* 
            if application type is 'Renew' and isVerificationRequired is 'No' then this is the case of interim years 
            in case of interim years, verifier is not required
            thus, remove verifier from the tab list state 
        */
        if(state.applicationType === 'Renew' && userType !== 'verifier' && (isVerificationRequired === 'No' || (isVerificationRequired === 'Optional' && (state.summaries[1].summary.selectVerifier.value.length === 0 || state.summaries[1].summary.selectVerifier.value === '0') && state.applicationStatus !== 'Draft')))
            setTabListData([{iconClassName: 'mdi mdi-3x mdi-case-check', tabName: 'Draft'}, {iconClassName: 'mdi mdi-3x mdi-airplane', tabName: 'Airport Review'}, {iconClassName: 'mdi mdi-3x mdi mdi-3x mdi-account', tabName: 'Admin Review'}, {iconClassName: 'mdi mdi-3x mdi-check-all', tabName: 'Approved'}])
    }, [isVerificationRequired])
    useEffect(() => {
        if(userType === 'admin' && state.summaries.length !== 0 && state.applicationType === 'Renew'){
            if(isVerificationRequired === '')
                checkIfVerificationIsRequired()
            if((isVerificationRequired === 'No' || (isVerificationRequired === 'Optional' && (state.summaries[1].summary.selectVerifier.value.length === 0 || state.summaries[1].summary.selectVerifier.value === '0') && state.applicationStatus !== 'Draft')) && state.summaries[0].type === 'admin'){
                const updatedState = {...state}
                updatedState.summaries[0].type = ''
                setState(updatedState)
            }
        }
    }, [state, userType, isVerificationRequired])
    useEffect(() => {
        if(activeRightPane === 'Application Summary')
            setIsSubmitButtonClickedTrueForAllSubSectionsBasedOnType()
    }, [activeRightPane])

    /* Debugging */
    //console.log(userType)
    //console.log(state.summaries)
    console.log('state: ', state)
    //console.log('leftNavigationMenu: ', leftNavigationMenu)
    //console.log(questionRefs.current)
    console.log('isVerificationRequired: ', isVerificationRequired)
    //console.log('fetchedDependentSubSectionIDs: ', fetchedDependentSubSectionIDs)
    //console.log('refetchDependentSubSectionIDs: ', refetchDependentSubSectionIDs)

    return (
        <Layout>
            {
                isLoading || isLoadingInternally
                  ?
                  <Loader />
                  :
                  <React.Fragment></React.Fragment>  
            }
            <div className="main-content container-fluid p-0">
                <div className="page-header yallow mb-4">
                    <div className="container-header">
                        <div className="row" style={{padding: '15px'}}>
                            <div className="container-fluid d-flex justify-content-between align-items-baseline">
                                <div className="col">
                                    <img src="dist/img/ACA-logo.png" />
                                </div>
                                <div className="col-6 d-flex align-items-center justify-content-center">
                                    <h3 className="page-header-title">Application Stage</h3>
                                </div>
                                <div className="col d-flex align-items-center justify-content-end">
                                    <a href={isExternalVerifierUser ? '/VerifierDashboard' : (isAirportUser ? (isSingleCheck ? '/AirportGroupDashboard' : (isGroupCheck ? '/AirportGroupDashboard' : '/AccreditationDashboard')) : '/AccreditationDashboard')}><button className="btn" type="button" style={{background: '#184086', color: '#FFFFFF'}}><i className="icon icon-left mdi mdi-arrow-left" style={{marginBottom: 3}} />&nbsp;Back&nbsp;</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-4 col-xl-3">
                      <div className="mt-2 mb-4">
                        <div className="list-group d-flex" id="left-menu">
                          <LeftNavigationMenu data={state} setState={setState} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} userType={userType} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-9">
                      <TabList data={tabListData} activeTabName={activeTabName} setActiveTabName={setActiveTabName} disableOnClickEvent={true} />
                      <div className="card card-border card-contrast">
                        <div className="card-body">
                          <div>
                            <ApplicationOverview data={state} state={state} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} leftNavigationMenu={leftNavigationMenu} userType={userType} />
                            <SelectAccreditationLevel data={state} state={state} setState={setState} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} leftNavigationMenu={leftNavigationMenu} saveAccreditationLevel={saveAccreditationeLevel} userType={userType} visibilityBasedOnType={visibilityBasedOnType} />
                            <OtherDetails data={state} state={state} setState={setState} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} leftNavigationMenu={leftNavigationMenu} userType={userType} saveOtherDetails={saveOtherDetails} visibilityBasedOnType={visibilityBasedOnType} isACAYearValid={isACAYearValid} isVerificationRequired={isVerificationRequired} isOtherDetailsSectionSavedInTheBackend={isOtherDetailsSectionSavedInTheBackend} allowToMoveInOtherDetailsSection={allowToMoveInOtherDetailsSection} setAllowToMoveInOtherDetailsSection={setAllowToMoveInOtherDetailsSection} />
                            {
                              state.sections.map((section, index) => {
                                return <Section key={index} index={index} data={section} state={state} setState={setState} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} leftNavigationMenu={leftNavigationMenu} saveSubSection={saveSubSection} userType={userType} questionRefs={questionRefs} calculateQuestionRefIndex={calculateQuestionRefIndex} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} visibilityBasedOnType={visibilityBasedOnType} commentsVisibility={commentsVisibility} setAlertDialogBoxProperties={setAlertDialogBoxProperties} reCalculateTotalAtRunTime={reCalculateTotalAtRunTime} setReCalculateTotalAtRunTime={setReCalculateTotalAtRunTime} />
                              })
                            }
                            <VerificationSummary data={state} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} leftNavigationMenu={leftNavigationMenu} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} />
                            <ApplicationSummary data={state} state={state} setState={setState} activeRightPane={activeRightPane} setActiveRightPane={setActiveRightPane} leftNavigationMenu={leftNavigationMenu} userType={userType} checkIfVerificationIsRequired={checkIfVerificationIsRequired} isVerificationRequired={isVerificationRequired} submitApplication={submitApplication} sendBackApplication={sendBackApplication} approveApplication={approveApplication} scrollToQuestionViaQuestionRef={scrollToQuestionViaQuestionRef} visibilityBasedOnType={visibilityBasedOnType} setAlertDialogBoxProperties={setAlertDialogBoxProperties} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Helper Components */}
              <ToastContainer />
              <CommonCustomModal modal={alertDialogBoxProperties.visibility} title='' toggler={alertDialogBoxProperties.toggle} heading={alertDialogBoxProperties.heading} buttonText='' buttonColor='#198754' hasFooterImage={true} footerImage='ACA-logo.png' footerImageWidth='150' isFooter={false}>
                  <AlertDialogBox properties={alertDialogBoxProperties} />
              </CommonCustomModal> 
        </Layout>
    )
}

export default ApplicationStage