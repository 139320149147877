import React from 'react';
import Tab from './Tab';
import IsAccessForVerifierCombination1 from '../../Common/IsAccessForVerifieCombination1';

const SponsorTabList = ({ activeTab, setActiveTab }) => {
    const isReadOnlyAccess1 = IsAccessForVerifierCombination1();
    return (
        <ul className="nav nav-tabs nav-tabs-classic" role="tablist">
            <Tab tabName="Verifiers" iconClassName="icon icon-left mdi mdi-account-box-mail" activeTab={activeTab} setActiveTab={setActiveTab} />
            {isReadOnlyAccess1 === 'FullAccess' ?
            <Tab tabName="Events" iconClassName="icon icon-left mdi mdi-file-text" activeTab={activeTab} setActiveTab={setActiveTab} />
            :
            <></>
            }
        </ul>
    );
};

export default SponsorTabList;