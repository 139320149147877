import React from 'react';

export const TableHeaderCells = [
    {
        id: 0,
        isHidden: true,
        name: 'ID',
        colSpan: 1,
        hasChild: true,
        child: <span className="sr-only">Blank</span>,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    },
    {
        id: 1,
        isHidden: false,
        name: 'Sponsor Name',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'SponsorName'
    },
    {
        id: 2,
        isHidden: false,
        name: 'Client ID',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'clientID'
    },
    {
        id: 3,
        isHidden: false,
        name: 'Region',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'region',
        type:'dropdown'
    },
    {
        id: 4,
        isHidden: false,
        name: 'Country',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'country',
        type:'dropdown'
    },
    {
        id: 5,
        isHidden: false,
        name: 'Email',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '10%' },
        isSortable: true,
        sortByColumnName: 'email'
    },
    {
        id: 6,
        isHidden: false,
        name: 'Phone',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '5%' },
        isSortable: true,
        sortByColumnName: 'phone'
    },
    {
        id: 7,
        isHidden: false,
        name: 'Status',
        colSpan: 1,
        hasChild: false,
        child: null,
        style:  { cursor: 'pointer', width: '5%' },
        isSortable: true,
        sortByColumnName: 'sponsorStatus',
        type:'dropdown'
    },
    {
        id: 8,
        isHidden: false,
        name: 'Actions',
        colSpan: 1,
        hasChild: false,
        child: null,
        style: { width: '1%' },
        isSortable: false,
        sortByColumnName: null
    }
];