import React from 'react';

const SearchBar = ({ searchState, searchStateSetter }) => {    

    return (
        <>
            <div className="input-group input-search input-group-sm mb-2" style={{ width: 600 }}>            
                <label className="sr-only" htmlFor="Search">Search</label>
                <input className="form-control" id="Search" name="Search" type="text" placeholder="Type here to search..." onChange={(e) => { searchStateSetter(e.target.value); }} />
                <span className="input-group-btn" style={{ top: 4 }}>
                    <button className="btn btn-secondary" type="button" style={{ textDecorationColor: '#333D47' }}><i className="icon mdi mdi-search text-info"></i><span class="sr-only">Search</span></button>
                </span>
            </div>
            {
                searchState.length > 0
                    ?
                    <div>
                        <label>Search results found for: "{searchState}"</label>
                    </div>
                    :
                    <div></div>
            }
        </>
    );

};

export default SearchBar;