import React from 'react';
import { useSignOut } from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';


const SessionTimeOut = () => {

  const signOut = useSignOut();
  const navigate = useNavigate();

  const handleLogOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("roles");
    localStorage.removeItem("userID");
    signOut();
    navigate('/login');
};
    return (
      <h3  className ='mt-5' style={{ textAlign:'center' }}>
      <p>Session TimeOut.</p>
      <p><a className="text-info" style={{ cursor: 'pointer' }} onClick={() => { handleLogOut(); }}>Click here </a>to login again.</p>
    </h3>
    );
};

export default SessionTimeOut;