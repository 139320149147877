import React, { useState,useEffect} from 'react';
import config from '../../api/config';
import apiconfig from '../../api/apiconfig';
import { useNavigate } from "react-router-dom";



const NotificationOnHeader = () => {


    const [notifications, setNotifications] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const userID = localStorage.getItem("userID");
    const navigate = useNavigate();
    
    const userRoles = JSON.parse(localStorage.getItem("roles"))
    const isAdminOrVerifierCoordinator = userRoles.some(role => role.name === "Administrator" || role.name === "Airport User" || role.name === "Verifier User" || role.name === "Account Manager" || role.name === "Senior Account Manager" || role.name === "ACI-E");

    useEffect(() => {
        fetchData();
    }, []);
    
    const fetchData = async () => {

        try {

            const response = await fetch(`${config.apiUrl}${apiconfig.getNotificationDetails}?UserID=${userID}&RequestType=${'Dashboard'}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            if (response.ok) {
                const result = await response.json();
                let fetchedNotifications = [];
                for (let i = 0; i < result.length; i++) {
                    fetchedNotifications.push(result[i]);
                }
                setNotifications(fetchedNotifications);
            } else {
                const errorText = await response.text();
                const apiname = `${apiconfig.getNotificationDetails}`;
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`);
            }
            }
        catch (error) {
            console.log(error.message);
            navigate(`/error-page`);
        }
    };

    return (
        <>
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" role="button" aria-expanded="false">
                    <span className="icon mdi mdi-notifications"></span>
                    <span className="indicator"></span>
                    <span className="sr-only">notifications</span>
                    </a>
                <ul className="dropdown-menu be-notifications">
                    <li>
                        <div className="title">Notifications<span className="badge badge-pill">{notifications.length}</span></div>
                        <div className="list">
                            <div className="be-scroller-notifications ps">
                                <div className="content">
                                {notifications.length === 0 ? (
                                    <span>No new notifications at the moment.</span>
                                    ) : (
                                <ul>
                                    {notifications.map((notification,index) =>(
                                    <li className="notification" key={index}>
                                        <a>
                                        <div className="image"><img src={require('../../img/loggdin.png')} alt="Avatar"/></div>
                                        <div className="notification-info">
                                            { notification.linkUrl  && isAdminOrVerifierCoordinator ?
                                            <a  className="text-info" href={notification.linkUrl}> <div className="text">{notification.notification}</div></a>
                                            :
                                            <div className="text">{notification.notification}</div>
                                            }                                       
                                            <span className="date">{notification.createdDate}</span>
                                        </div>
                                        </a>
                                    </li>

                                    ))}
                                </ul>)}
                                </div>
                                <div className="ps__rail-x" style={{left: '0px', bottom: '0px'}}>
                                    <div className="ps__thumb-x" tabIndex="0" style={{left: '0px', width: '0px'}}>
                                    </div>
                                </div>
                                <div className="ps__rail-y" style={{top: '0px',right: '0px'}}>
                                        <div className="ps__thumb-y" tabIndex="0" style={{top: '0px',height: '0px'}}>
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            {notifications.length>0 && isAdminOrVerifierCoordinator ? 
                            <a className="text-info" href="/NotificationDetails">View all notifications</a>
                            :
                            <a>View all notifications</a>
                            }
                        </div>
                    </li>
                </ul>
            </li>
        </>
    );
};

export default NotificationOnHeader;