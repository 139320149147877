import { useState, useEffect } from "react"
import config from "../../../api/config"
import apiconfig from "../../../api/apiconfig"
import { useNavigate } from "react-router-dom"
import AdvancedSearchByIDNew from '../../Common/AdvancedSearchByIDNew'
import Selector from "../../Common/Selector"
import CustomTable from "../../Common/CustomTable"
import { AppTableHeaderCells } from "./AppTableHeaderCells"
import { numberOfEntriesPerPageOptions } from "../../Helper/NumberOfEntriesPerPageOptions"
import { TiArrowUnsorted } from 'react-icons/ti'
import Pagination from '../../Common/Pagination'
import DashboardApplicationList from "./DashboardApplicationList"

const DashboardApplicationLists = ({ searchDiv, isLoading, setIsLoading }) => {

    /* States & Constants */
    const userID = localStorage.getItem("userID")
    const [searchName, setSearchName] = useState('')
    const [refetch, setRefetch] = useState(false)
    const [pageCount, setPageCount] = useState(1)
    const [pageNumber, setPageNumber] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(20)
    const [sortOrder, setSortOrder] = useState('ASC')
    const [sortByColumnName, setSortByColumnName] = useState('AirportName')
    const [columnClickCounter, setColumnClickCounter] = useState(1)
    const [searchFilter, setSearchFilter] = useState('Start Date')
    const [searchType, setSearchType] = useState('date')
    const [searchCriteria, setSearchCriteria] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [dropdownOptions, setDropdownOptions] = useState([])
    const [dashboardAppList, setDashboardAppList] = useState([])
    const [accLevelList, setAccLevelList] = useState([])
    const [searchText, setSearchText] = useState('')
    const navigate = useNavigate()
    const statusOptions =  [
        {text: 'Admin comments - Airport to address', value: 'Admin comments - Airport to address'},
        {text: 'Admin comments - Verifier to address', value: 'Admin comments - Verifier to address'},
        {text: 'Airport resubmitted - In Admin Verification', value: 'Airport resubmitted - In Admin Verification'},
        {text: 'Airport resubmitted - In Verification', value: 'Airport resubmitted - In Verification'},
        {text: 'Approved', value: 'Approved'},
        {text: 'Draft', value: 'Draft'},
        {text: 'In Admin Verification', value: 'In Admin Verification'},
        {text: 'In Verification', value: 'In Verification'},
        {text: 'Verifier comments - Airport to address', value: 'Verifier comments - Airport to address'},
        {text: 'Verifier resubmitted - In Admin Verification', value: 'Verifier resubmitted - In Admin Verification'},
    ]
    const searchOptions = [
        {
            value: 'Start Date', 
            text: 'Start Date',
            type: 'date',
            query: '&StartDate='
        },
        {
            value: 'End Date', 
            text: 'End Date',
            type: 'date',
            query: '&EndDate='
        },
        {
            value: 'Airport Name', 
            text: 'Airport Name', 
            type: 'text',
            query: '&AirportName=',
        },
        {
            value: 'Accreditation Level', 
            text: 'Accreditation Level', 
            type: 'dropdownbyID',
            query: '&AccreditationLevel=',
            dropdownOptions: accLevelList,
        },
        {
            value: 'Status',
            text: 'Status', 
            type: 'dropdown',
            query: '&Status=',
            dropdownOptions:statusOptions,
        },
        {
            value: 'Accredited Date',
            text: 'Accredited Date', 
            type: 'date',
            query: '&AccreditedDate=',
        },
       
        {
            value: 'Next Renewal Due Date',
            text: 'Next Renewal Due Date', 
            type: 'date',
            query: '&NextRenewalDueDate=',
        },
        // {
        //     value: '3 - Yearly Status',
        //     text: '3 - Yearly Status', 
        //     type: 'text',
        //     query: '&Year3Status=',
        // },
        // {
        //     value: '3 - Yearly Year',
        //     text: '3 - Yearly Year', 
        //     type: 'text',
        //     query: '&Year3Year=',
        // },
        // {
        //     value: 'Eligible for 3-Year Renewal',
        //     text: 'Eligible for 3-Year Renewal', 
        //     type: 'dropdown',
        //     query: '&EligibleForThreeYearRenewal=',
        //     dropdownOptions:[
        //         {text: 'Yes', value: 'Yes'},
        //         {text: 'No', value: 'No'},
        //     ],
        // },
        
    ]

    /* Methods */
    const fetchData = async () => {
        try {
            console.log('executing getAirportUserApplicationList()...')
            setIsLoading(true)
            let filtersQuery = ''
            if(searchCriteria.length > 0){
                filtersQuery = ''
                searchCriteria.map(criteria => filtersQuery += `${criteria.searchQuery}${criteria.searchValue}`)
            }
            const response = await fetch(`${config.apiUrl}${apiconfig.getAirportUserApplicationList}?LoggedInUserID=${userID}&PageSize=${itemsPerPage}&PageNumber=${pageNumber}&SortColumn=${sortByColumnName}&SortOrder=${sortOrder}${filtersQuery}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            })
            if (response.ok) {
                console.log('fetched getAirportUserApplicationList()')
                const result = await response.json()
                let fetchedList = []
                for (let i = 0; i < result.length; i++) {
                    fetchedList.push(result[i])
                }
                setDashboardAppList(fetchedList)
            } else {
                const errorText = await response.text()
                const apiname = `${apiconfig.getAirportUserApplicationList}`
                navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
            }
            setIsLoading(false)
        }
        catch (error) {
            console.log("Error: ", error)
            navigate("/error-page")
        }
    }
    const onPageClick = (e) => {
        setPageNumber(e.selected + 1)
    }

    /* Effects */
    useEffect(() => { 
       fetchData()
    }, [refetch, itemsPerPage, pageNumber, sortByColumnName, sortOrder,searchCriteria])
    useEffect(() => {
        if(dashboardAppList.length > 0){
            setPageCount(Math.ceil(dashboardAppList[0].totalCount / itemsPerPage))
        }
    }, [dashboardAppList])
    useEffect(() => {
        const getAccLevelList = async () => { 
            try {
                const response = await  fetch(`${config.apiUrl}${apiconfig.getApplicationLevelList}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`
                    }
                })
                if (response.ok) {
                    var result = await response.json()
                    // result = result.slice(1);
                    const option  = result.map((level)=>({
                        text : level.title,
                        value : level.id
                    }))
                    setAccLevelList(option)
                } else {
                    const errorText = await response.text()
                    const apiname = `${apiconfig.getApplicationLevelList}`
                    navigate(`/error-page/${errorText} on ${apiname.split('/')[2]}`)
                }
            }catch(error){
                console.log(error.message)
                navigate(`/error-page`)
            }
        }
        getAccLevelList()
    }, [refetch])
    useEffect(() => {
        /* 
            This effect will ensure to land the user on the first page (based on pagination) to maintain consistency in order to avoid out of bound paging issues 
        */
        if(dashboardAppList.length !== 0){
            if(dashboardAppList[0].totalCount < (itemsPerPage * pageCount))
                setPageNumber(1)
        }
    }, [itemsPerPage])


    return (
        <>
        {/* <div className="container-header"> */}
            <h3 className="page-header-title">Search Application </h3>
            <div className="row">
                <div className="col-md-6 col-lg-7">
                    <p className="mb-2 font-weight-normal">
                        {/* <strong>Search</strong>
                        <br /> */}
                        Use the filter categories and search field below to find application , expand the preview to see more information.
                    </p>
                    <AdvancedSearchByIDNew searchOptions={searchOptions} searchType={searchType} setSearchType={setSearchType} searchFilter={searchFilter} setSearchFilter={setSearchFilter} searchValue={searchValue} setSearchValue={setSearchValue} dropdownOptions={dropdownOptions} setDropdownOptions={setDropdownOptions} searchCriteria={searchCriteria} setSearchCriteria={setSearchCriteria} searchText={searchText} setSearchText={setSearchText} setPageNumber={setPageNumber} />
                    {
                        dashboardAppList.length > 0
                            ?
                            <div className="align-self-center font-weight-bold">{dashboardAppList[0].totalCount} items found</div>
                            :
                            <div className="align-self-center font-weight-bold">No items found</div>
                    }
                </div>
                <div className="col-md-6 col-lg-5 d-flex justify-content-end align-items-end">
                    <nav>
                        <Selector hasPrefixLabel={true} hasSuffixLabel={true} hasIcon={true} prefixLabel='Show' suffixLabel='Entries' icon={<TiArrowUnsorted />} value={itemsPerPage} setter={setItemsPerPage} options={numberOfEntriesPerPageOptions} />
                        <Pagination handlePageClick={onPageClick} pageCount={pageCount} pageRange={3} pageNumber={pageNumber} />
                    </nav>
                </div>
            </div>
            <CustomTable headerCells={AppTableHeaderCells} sortByColumnName={sortByColumnName} sortOrder={sortOrder} columnClickCounter={columnClickCounter} setSortByColumnName={setSortByColumnName} setSortOrder={setSortOrder} setColumnClickCounter={setColumnClickCounter}>
            {
                !dashboardAppList.length
                    ?
                    <tr className="alt-row">
                        <td colSpan="14">&nbsp; Application does not exist.</td>
                    </tr>
                    :
                dashboardAppList.map((appList, index) => <DashboardApplicationList key={index} appList={appList} searchName={searchName.toLowerCase()} isLoading = {isLoading} setIsLoading={setIsLoading}/>)
            }
            </CustomTable>
        </>
    )
}

export default DashboardApplicationLists